import { Injectable } from "@angular/core";
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { RestorativeNursingProgram } from "@app/model";

export interface RestorativeNursingProgramState extends EntityState<RestorativeNursingProgram>, ActiveState {}

const initialState: RestorativeNursingProgramState = {
    active: null,
}

@Injectable({providedIn: 'root'})
@StoreConfig({ name: 'rnps'})
export class RestorativeNursingProgramStore extends EntityStore<RestorativeNursingProgramState> {
    constructor() {
        super(initialState);
    }
}