import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, finalize, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { BatchPrintFiltersResponse, BatchPrintSearchResponse, DocumentRecord, BatchPrintSearchResultItem, BatchPrintingSearchRequest, OperationResponse } from '@app/model';

@Injectable({
	providedIn: 'root',
})
export class BatchPrintingService {
    constructor(private httpClient: HttpClient,
        private urlConstantsService: UrlConstantsService) {

    }

    search(facilityId: string, dateFrom: Date, dateTo: Date, patientStatuses: Number[], disciplines: Number[], documentTypeIds: string[], payerIds: string[], practitionerIds: string[]): Observable<BatchPrintSearchResultItem[]> {
        const request: BatchPrintingSearchRequest = {
            facilityId: facilityId,
            startDate: dateFrom,
            endDate: dateTo,
            pageSize: 1000,
            patientStatuses: patientStatuses,
            disciplines: disciplines,
            documentTypeIds: documentTypeIds,
            payerIds: payerIds,
            practitionerIds: practitionerIds
        };

        return this.httpClient.post<OperationResponse<BatchPrintSearchResponse>>(`${this.urlConstantsService.BATCH_PRINTING_URL}/search`, request)
            .pipe(map(response => response.data.pagedResponse.items));
    }

    print(documentRecordIds: string[]) {
        return this.httpClient.put<OperationResponse<string>>(`${this.urlConstantsService.BATCH_PRINTING_URL}/print`, documentRecordIds)
          .pipe(map(response => response.data));
    }

    getFilters(facilityId: string): Observable<BatchPrintFiltersResponse> {
        return this.httpClient.get<OperationResponse<BatchPrintFiltersResponse>>(`${this.urlConstantsService.BATCH_PRINTING_URL}/filters?facilityId=${facilityId}`)
            .pipe(map(response => response.data));
    }
}