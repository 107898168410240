import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OnDestroyComponent } from '../on-destroy/on-destroy.component';

@Component({
  selector: 'tms-progress-bar-modal',
  templateUrl: './tms-progress-bar-modal.component.html',
  styleUrls: ['./tms-progress-bar-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TmsProgressBarModalComponent extends OnDestroyComponent implements OnInit, OnDestroy {
  @Input() arrayOfObservables: Observable<any>[] = [];
  @Input() progressBarTitle: string = null;
  @Input() title: string = '';

  maxValue: number = null;
  currentValue: number = null;
  currentValueSubject$: Subject<number> = new Subject();
  isError: boolean = false;
  errorSubject$: Subject<void> = new Subject();

  constructor(private cdr: ChangeDetectorRef,
    private activeModalService: NgbActiveModal) {
      super();
    }

  ngOnInit(): void {
    this.maxValue = this.arrayOfObservables.length;
    this.setUpProgressBarCounter(this.arrayOfObservables, this.maxValue)
        .subscribe(() => {});

    this.currentValueSubject$.asObservable()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        this.currentValue = value;
        this.cdr.detectChanges();
      }, error => {}, () => this.activeModalService.close());

    this.errorSubject$.asObservable()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.isError = true;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  onCloseProgressBar() {
    this.activeModalService.close();
  }

  format = (value) => {
    const progressBarVerbiage = this.progressBarTitle != null ? `${this.progressBarTitle}: ` : '';
    return `${progressBarVerbiage}${Math.round(value * 100)}%`;
  }

  private setUpProgressBarCounter(arrayOfObservables: Observable<any>[], maxValue: number): Observable<any[]> {
    let counter: number = 0;

    // update counter as each observable finishes 
    const modilefiedObservablesList: Observable<any>[] = arrayOfObservables.map(
      (item) => item.pipe(
        catchError(err => {
          this.errorSubject$.next();
          this.errorSubject$.complete();
          return of(err);
        }),
        finalize(() => {
          this.currentValueSubject$.next(++counter);
        })));

    const finalResult$: Observable<any[]> = forkJoin(modilefiedObservablesList)
    .pipe(tap(() => {
        this.currentValueSubject$.next(maxValue);
        this.currentValueSubject$.complete();
      }));

    return finalResult$;
  }

}
