import { HostListener, Directive } from '@angular/core';
import { Observable } from 'rxjs';
import { OnDestroyComponent } from '../on-destroy/on-destroy.component';


@Directive()
export abstract class CanDeactivateComponent extends OnDestroyComponent {
  abstract canDeactivate(fromHostListener: boolean): boolean | Observable<boolean>;

  constructor() {
    super();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
      if (!this.canDeactivate(true)) {
          $event.returnValue = '';
      }
  }

}
