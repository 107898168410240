import { TemplateObject } from '../../template-object';

export const PrognosticIndicatorsProperties = {
    AMB_PRIOR: 'ambPrior',
    AO4: 'ao4',
    DELAY_TO_COMMAND: 'delayToCommand',
    EVAL_ONLY_NO_SKILLED: 'evalOnlyNoSkilled',
    EVAL_ONLY_NOT_RESPONSIVE: 'evalOnlyNotResponsive',
    EVAL_ONLY_RECENT_ONSET: 'evalOnlyRecentOnset',
    FAMILY_SUPPORT: 'familySupport',
    FOLLOW_COMMANDS: 'followCommands',
    FOLLOW_ROUTINE: 'followRoutine',
    HIGHER_FX_LEVEL: 'higherFxLevel',
    IMP_MED_COND: 'impMedCond',
    IMITATE: 'imitate',
    IN_PLOF: 'inPLOF',
    MOT_HOME: 'motHome',
    MOT_RETURN_PLOF: 'motReturnPLOF',
    MOT_TO_PART: 'motToPart',
    ORIENTED_TO: 'orientedTo',
    PREV_THERAPY: 'prevTherapy',
    RESP_CUEING: 'respCueing',
    RESP_ENV_STIMULI: 'respEnvStimuli',
    SELF_MONITOR: 'selfMonitor',
    STABLE_MED_COND: 'stableMedCond',
    SUPPORT_SIG_OTHER: 'supportSigOther',
}

export const PROGNOSTIC_INDICATORS_TEMPLATE: string = `{{#if stringList}}{{/if}}{{stringList}}`

export class PrognosticIndicatorsTemplate implements TemplateObject {
    ambPrior: string = '';
    ao4: string = '';
    delayToCommand: string = '';
    evalOnlyNoSkilled: string = '';
    evalOnlyNotResponsive: string = '';
    evalOnlyRecentOnset: string = '';
    familySupport: string = '';
    followCommands: string = '';
    followRoutine: string = '';
    higherFxLevel: string = '';
    impMedCond: string = '';
    imitate: string = '';
    inPLOF: string = '';
    motHome: string = '';
    motReturnPLOF: string = '';
    motToPart: string = '';
    orientedTo: string = '';
    prevTherapy: string = '';
    respCueing: string = '';
    respEnvStimuli: string = '';
    selfMonitor: string = '';
    stableMedCond: string = '';
    supportSigOther: string = '';

    stringList: string[] = [];
    private toList = [
        'ambPrior', 'ao4', 'delayToCommand', 'evalOnlyNoSkilled', 'evalOnlyNotResponsive',
        'evalOnlyRecentOnset', 'familySupport', 'followCommands', 'followRoutine', 'higherFxLevel',
        'impMedCond', 'imitate', 'inPLOF', 'motHome', 'motReturnPLOF', 'motToPart', 'orientedTo',
        'prevTherapy', 'respCueing', 'respEnvStimuli', 'selfMonitor', 'stableMedCond', 'supportSigOther',
    ];

    preCompileTemplateObj(): void {
        this.stringList = [];
        this.toList.forEach(value => {
            if (this[value]) {
                this.stringList.push(' ' + this[value]);
            }
        });
    }
}