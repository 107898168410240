import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChargeCode, OperationResponse, PagedResult, ChargeCodeMutuallyExclusive } from '@app/model';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class ChargeCodesService {
    constructor(private http: HttpClient,
        private urlConstantsService: UrlConstantsService) { }


    createChargeCode(code: ChargeCode) {
        return this.http.post<OperationResponse<ChargeCode>>(`${this.urlConstantsService.CHARGE_CODES_URL}`, code)
            .pipe(map(response => response.data));
    }

    updateChargeCode(code: ChargeCode) {
        return this.http.put<OperationResponse<ChargeCode>>(`${this.urlConstantsService.CHARGE_CODES_URL}/${code.id}`, code)
            .pipe(map(response => response.data));
    }

    deleteChargeCode(codeId: string) {
        return this.http.delete<OperationResponse<ChargeCode>>(`${this.urlConstantsService.CHARGE_CODES_URL}/${codeId}`);
    }

    getByChargeCodeId(codeId: string) {
        return this.http.get<ChargeCode>(`${this.urlConstantsService.CHARGE_CODES_URL}/${codeId}`)
    }
    
    getByChargeCodeType(codeType: number) {
        return this.http.get<PagedResult<ChargeCode>>(`${this.urlConstantsService.CHARGE_CODES_URL}?codeType=${codeType}`)
            .pipe(map(episodePagedResult => episodePagedResult.items));
    }

    getMutuallyExclusiveRules(codeId: string) {
        return this.http.get<PagedResult<ChargeCodeMutuallyExclusive>>(`${this.urlConstantsService.CHARGE_CODES_URL}/mutuallyexclusive/${codeId}`)
        .pipe(map(episodePagedResult => episodePagedResult.items));
    }

    getMutuallyExclusiveRuleById(id: string) {
        return this.http.get<OperationResponse<ChargeCodeMutuallyExclusive>>(`${this.urlConstantsService.CHARGE_CODES_URL}/mutuallyexclusive/rule/${id}`)
            .pipe(map(response => response.data));
    }

    createMutuallyExclusiveRule(rule: ChargeCodeMutuallyExclusive) {
        return this.http.post<OperationResponse<ChargeCodeMutuallyExclusive>>(`${this.urlConstantsService.CHARGE_CODES_URL}/mutuallyexclusive`, rule)
            .pipe(map(response => response.data));
    }

    updateMutuallyExclusiveRule(rule: ChargeCodeMutuallyExclusive) {
        return this.http.put<OperationResponse<ChargeCodeMutuallyExclusive>>(`${this.urlConstantsService.CHARGE_CODES_URL}/mutuallyexclusive/${rule.id}`, rule)
            .pipe(map(response => response.data));
    }
}