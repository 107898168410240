import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private _bLoading: boolean = false;
  private _bLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  get loading() {
    return this._bLoading;
  }

  get loading$() {
    return this._bLoading$;
  }

  setLoading(value: boolean): void {
    if (this._bLoading != value) {
      this._bLoading = value;
      this._bLoading$.next(this._bLoading);
    }
  }
}
