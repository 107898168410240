import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { EntityDirtyCheckPlugin, arrayFind, arrayUpdate } from '@datorama/akita';
import { Observable, throwError, of, BehaviorSubject, combineLatest } from 'rxjs';
import { catchError, filter, map, tap, finalize, take, switchMap } from 'rxjs/operators';
import { difference, ceil, update } from 'lodash';
import { CacheManService } from './cacheman.service';
import { ClinicalDocumentAnswerFieldsConstants, ClinicalDocumentInterviewGroupConstants,
  eDocumentTabStepIndex,
  EVALUATION_STEPS,
  DISCHARGE_SUMMARY_STEPS,
  PROGRESS_NOTE_STEPS,
  RECERT_STEPS,
  DISCHARGE_TABS,
  EVALUATION_TABS,
  PROGRESS_NOTE_TABS,
  RECERT_TABS,
  RouteConstants, 
  ClincialDocumentInterviewTabConstants,
  DataListConstants } from '../constants';
import { UrlConstantsService } from './url-constants.service';
import {
  AddMeasurementResponse,
  ClinicalDocumentBase,
  ClinicalDocumentPathway,
  EvaluationPatientComplexity,
  eClinicalMeasurementType,
  eGoalResolution,
  eInterviewStatus,
  ePatientClinicalDocumentType,
  eTmsStepStage,
  Dictionary,
  EvaluationPlannedDischarge,
  EvaluationPreviousTherapy,
  EvaluationTemplate,
  GoalResolutionEvent,
  InterviewAnswer,
  InterviewGroup,
  InterviewPage,
  InterviewQuestion,
  InterviewTab,
  KeyValuePair,
  OperationResponse,
  PlanOfCare,
  TmsStepGroup,
  TmsStep,
  EvaluationDocument,
  ExtentOfProgress,
  RecertDocument,
  InterviewAnswerField,
  eDiscipline,
  ClinicalDocumentFinalize,
  NarrativeBuilder,
  EvaluationRehabPotentialGoals,
  RecertInfo,
  RecertDurations,
  DischargeInfo,
  eInterviewFieldEditMode,
  Payer,
  User,
  PayerClassification,
  SkilledPayerClassification,
  JbsMeasurement,
  InterviewSession,
  ClinicalDocumentModule,
  SignatureResponse,
  GetMeasurementHistorySummaryCommand,
  PatientClinicalMeasurement,
  GetMeasurementHistorySummaryResponse,
  MeasurementsByDocumentDate,
  CustomGoalStatement,
  DataListItem,
  IcdCode,
} from '@app/model';
import { ClinicalDocumentsQuery, ClinicalDocumentsStore } from '../../pages/patient/store';
import { ErrorMessageUtils, DeepCopyUtils, UrlStringUtils, DesignationUtils } from '@app/utils';
import { ClinicalDocumentPropertiesConstants } from '../constants';
import { InterviewService } from './interview.service';
import { ClinicalMeasurementInterviewTab } from '@app/model/documentation/clinical-documents/ClinicalMeasurementInterviewTab';
import { duration } from 'moment';
import { ClinicalDocumentValidator } from '../validators/clinical-document-validator';

@Injectable({
  providedIn: 'root'
})
export class ClinicalDocumentService {
  dirtyCheckCollection: EntityDirtyCheckPlugin;

  private _clinicalDocumentModuleChanged$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _clinicalDocumentMeasurementChanged$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _updateScrollLocation$: BehaviorSubject<void> = new BehaviorSubject<void>(null);

  constructor(private http: HttpClient,
    private cacheManService: CacheManService,
    private clinicalDocumentQuery: ClinicalDocumentsQuery,
    private clinicalDocumentStore: ClinicalDocumentsStore,
    private interviewService: InterviewService,
    private urlConstantsService: UrlConstantsService) { }

  get clinicalDocument() {
    return this.clinicalDocumentQuery.getActive();
  }

  clinicalDocument$(): Observable<ClinicalDocumentBase>;
  clinicalDocument$(property: string): Observable<any>;
  clinicalDocument$<T>(property?: string): Observable<T>;
  clinicalDocument$<T>(property?: string): Observable<ClinicalDocumentBase> | Observable<T> {
    if (property) {
      return this.clinicalDocumentQuery.selectActive(entity => entity[property]);
    } else {
      return this.clinicalDocumentQuery.selectActive();
    }
  }

  // fires when significant change has been made where a redraw is required on the UI
  get clinicalDocumentModulesChanged$(): Observable<string> {
    return this._clinicalDocumentModuleChanged$.asObservable();
  }

  get clinicalDocumentMeasurementsChanged$(): Observable<string> {
    return this._clinicalDocumentMeasurementChanged$.asObservable();
  }

  get isLocked$(): Observable<boolean> {
    return combineLatest([this.clinicalDocumentQuery.selectActive(entity => entity.interviewStatus), this.cacheManService.getIsUserClockedIn$()])
            .pipe(map(([status, isClockedIn]) => status >= eInterviewStatus.SignedPendingMore || !isClockedIn));
  }

  get updateScrollLocation$(): Observable<void> {
    return this._updateScrollLocation$.asObservable();
  }

  getCachedTemplates(): Observable<EvaluationTemplate[]> {
    return this.cacheManService.getEvaluationTemplates$();
  }

  cancelActiveDocumentChanges() {
    this.dirtyCheckCollection.reset();
  }

  resetActiveDocument() {
    this.clinicalDocumentStore.setActive(null);
  }

  clearClinicalDocumentStore() {
    this.clinicalDocumentStore.reset();
    this._clinicalDocumentModuleChanged$.next(null);
    this._clinicalDocumentMeasurementChanged$.next(null);
  }

  setStartOfCareForActive(socDate: Date) {
    this.clinicalDocumentStore.updateActive(entity => {
      return {
        ...entity,
        startOfCareDate: socDate,
      }
    });
  }

  updateScrollLocation(): void {
    this._updateScrollLocation$.next();
  }

  getNewClinicalDocument(patientCaseId: string, clinicalDocumentType: ePatientClinicalDocumentType, year: Number, month: Number, day: Number) {
    return this.http.get<ClinicalDocumentBase>(`${this.getClinicalDocumentUrl(clinicalDocumentType)}/${patientCaseId}?year=${year}&month=${month}&day=${day}`)
      .pipe(tap(response => {
        this.clinicalDocumentStore.upsert(response.interviewSessionId, entity => {
          return {
            ...response,
          }
        });
        this.clinicalDocumentStore.setActive(response.interviewSessionId);
        this.dirtyCheckCollection = new EntityDirtyCheckPlugin(this.clinicalDocumentQuery);
        this.dirtyCheckCollection.setHead();
      }));
  }

  getClinicalDocument(patientCaseId: string, interviewSessionId: string, clinicalDocumentType: ePatientClinicalDocumentType, force: boolean, navToPath: string) {
    if (!force && this.clinicalDocumentQuery.hasEntity(interviewSessionId)) {
      return this.clinicalDocumentQuery.selectEntity(interviewSessionId);
    } else {
      let getUrl = `${this.getClinicalDocumentUrl(clinicalDocumentType)}/${patientCaseId}?interviewSessionId=${interviewSessionId}`;

      if (navToPath && navToPath === RouteConstants.PAT_CLINICAL_DOC_FINALIZE) {
        getUrl = `${getUrl}&includeGoalStatements=1`
      }

      return this.http.get<ClinicalDocumentBase>(getUrl)
        .pipe(tap(response => {
          this.clinicalDocumentStore.upsert(interviewSessionId, entity => {
            return {
              ...response,
            }
          });
          this.clinicalDocumentStore.setActive(interviewSessionId);
          this.dirtyCheckCollection = new EntityDirtyCheckPlugin(this.clinicalDocumentQuery);
          this.dirtyCheckCollection.setHead();
        }));
    }
  }

  getClinicalDocumentTempModules(patientCaseId: string, pathway: ClinicalDocumentPathway, clinicalDocumentType: ePatientClinicalDocumentType) {
    const strModules = pathway.modules ? pathway.modules.reduce((acc, curr) => acc += `,${curr}`) : '';
    const template = pathway.templates[0];
    return this.http.get<ClinicalDocumentBase>(`${this.getClinicalDocumentUrl(clinicalDocumentType)}/${patientCaseId}?template=${template}&modules=${strModules}&isCustomTemplate=${pathway.customTemplate}`)
      .pipe(tap(response => {
        this.clinicalDocumentStore.upsert(response.interviewSessionId, entity => {
          return {
            ...response,
            pathway: {
              ...response.pathway,
              customTemplate: pathway.customTemplate,
            },
            // answers: [
            //   ...this.getClinicalDocumentAnswersFromInterviewPage(response.outcomes),
            // ],
          }
        });
        this.clinicalDocumentStore.setActive(response.interviewSessionId);
        this.dirtyCheckCollection = new EntityDirtyCheckPlugin(this.clinicalDocumentQuery);
        this.dirtyCheckCollection.setHead();
      }));
  }

  addClinicalModuleMeasurementAnswers(moduleShortName: string, measurementShortName: string, measurementAnswers: InterviewAnswer[], 
      clinicalDocumentType: ePatientClinicalDocumentType, patientCaseId: string): Observable<OperationResponse<AddMeasurementResponse>> {
    // save changes to doc before adding new measurement
    return this.submitClinicalDocument(patientCaseId, clinicalDocumentType, null)
        .pipe(switchMap(() => {
          const activeDocument = this.clinicalDocumentQuery.getActive();
          const interviewSessionId = activeDocument.interviewSessionId;
          const encodedUrl = UrlStringUtils.encodeUrl(`${this.getClinicalDocumentUrl(clinicalDocumentType)}/${interviewSessionId}/${moduleShortName}/measurements/${measurementShortName}`);
          return this.http.put<OperationResponse<AddMeasurementResponse>>(encodedUrl, measurementAnswers)
            .pipe(tap(operationResponse => {
              if (operationResponse.isSuccessful) {
                if (operationResponse.data.modules) {
                  this.updateClinicalDocument<KeyValuePair<string, InterviewPage>[]>(ClinicalDocumentPropertiesConstants.MODULES, operationResponse.data.modules);
                }
                if (operationResponse.data.contributingModules) {
                  this.updateClinicalDocument<KeyValuePair<string, InterviewPage>[]>(ClinicalDocumentPropertiesConstants.CONTRIBUTING_MODULES, operationResponse.data.contributingModules);
                }
                if (operationResponse.data.otherModules) {
                  this.updateClinicalDocument<KeyValuePair<string, InterviewPage>[]>(ClinicalDocumentPropertiesConstants.OTHER_MODULES, operationResponse.data.otherModules);
                }
                if (operationResponse.data.answers) {
                  this.updateClinicalDocument<InterviewAnswer[]>(ClinicalDocumentPropertiesConstants.ANSWERS, operationResponse.data.answers);
                }
                this.dirtyCheckCollection.setHead();
                this._clinicalDocumentMeasurementChanged$.next(moduleShortName);
              }
            }));
        }));
  }

  addModule(moduleShortName: string, clinicalDocumentType: ePatientClinicalDocumentType, patientCaseId: string): Observable<AddMeasurementResponse> {
    // save changes to document before adding new module 
    return this.submitClinicalDocument(patientCaseId, clinicalDocumentType, null)
      .pipe(switchMap(() => {
      const activeDocument = this.clinicalDocumentQuery.getActive();
      const interviewSessionId = activeDocument.interviewSessionId;
      return this.http.get<OperationResponse<AddMeasurementResponse>>(`${this.getClinicalDocumentUrl(clinicalDocumentType)}/${interviewSessionId}/addmodule?moduleShortName=${moduleShortName}`)
        .pipe(tap(operationResponse => {
          if (operationResponse.isSuccessful) {
            if (operationResponse.data.modules) {
              this.updateClinicalDocument<KeyValuePair<string, InterviewPage>[]>(ClinicalDocumentPropertiesConstants.MODULES, operationResponse.data.modules);
            }
            if (operationResponse.data.contributingModules) {
              this.updateClinicalDocument<KeyValuePair<string, InterviewPage>[]>(ClinicalDocumentPropertiesConstants.CONTRIBUTING_MODULES, operationResponse.data.contributingModules);
            }
            if (operationResponse.data.otherModules) {
              this.updateClinicalDocument<KeyValuePair<string, InterviewPage>[]>(ClinicalDocumentPropertiesConstants.OTHER_MODULES, operationResponse.data.otherModules);
            }
            if (operationResponse.data.answers) {
              this.updateClinicalDocument<InterviewAnswer[]>(ClinicalDocumentPropertiesConstants.ANSWERS, operationResponse.data.answers);
            }
            this.dirtyCheckCollection.setHead();
          }
        }), map(response => response.data), tap(() => this._clinicalDocumentModuleChanged$.next(moduleShortName)))
    }));
  }

  getClinicalModuleMeasurements(moduleShortName: string, clinicalDocumentType: ePatientClinicalDocumentType): Observable<InterviewGroup[]> {
    const activeDocument = this.clinicalDocumentQuery.getActive();
    const interviewSessionId = activeDocument.interviewSessionId;
    return this.http.get<InterviewGroup[]>(`${this.getClinicalDocumentUrl(clinicalDocumentType)}/${interviewSessionId}/${moduleShortName}/measurements`);
  }

  getClinicalModuleMeasurementsDistinctMods(moduleShortName: string, measurementShortName: string, clinicalDocumentType: ePatientClinicalDocumentType): Observable<InterviewGroup[]> {
    const activeDocument = this.clinicalDocumentQuery.getActive();
    const interviewSessionId = activeDocument.interviewSessionId;
    const encodedUrl = UrlStringUtils.encodeUrl(`${this.getClinicalDocumentUrl(clinicalDocumentType)}/${interviewSessionId}/${moduleShortName}/measurements/${measurementShortName}`);
    return this.http.get<InterviewGroup[]>(encodedUrl);
  }

  getClinicalModuleStepGroups(docType: ePatientClinicalDocumentType): TmsStepGroup[] {
    const activeDocument = this.clinicalDocumentQuery.getActive();

    const moduleSteps = this.getModuleSteps({
      [eClinicalMeasurementType.Functional]: activeDocument?.modules,
      [eClinicalMeasurementType.Physiological]: activeDocument?.contributingModules,
      [eClinicalMeasurementType.Other]: activeDocument?.otherModules,
    }, docType, activeDocument?.isJbsCase);

    return [
      {
        title: 'Functional',
        steps: moduleSteps[eClinicalMeasurementType.Functional],
      },
      {
        title: 'Physiological',
        steps: moduleSteps[eClinicalMeasurementType.Physiological],
      },
      {
        title: 'Other',
        steps: moduleSteps[eClinicalMeasurementType.Other],
      }
    ];
  }

  getModuleTabMeasurements(moduleShortName: string, clinicalMeasurementType: eClinicalMeasurementType): ClinicalMeasurementInterviewTab[] {
    let property = '';
    switch (clinicalMeasurementType) {
      case eClinicalMeasurementType.Functional:
        property = ClinicalDocumentPropertiesConstants.MODULES;
        break;
      case eClinicalMeasurementType.Physiological:
        property = ClinicalDocumentPropertiesConstants.CONTRIBUTING_MODULES;
        break;
      case eClinicalMeasurementType.Other:
        property = ClinicalDocumentPropertiesConstants.OTHER_MODULES;
        break;
    }

    if (property) {
      // find InterviewPage
      const activeDocument = this.clinicalDocumentQuery.getActive();
      const kvp = activeDocument[property].find(kvp => kvp.key === moduleShortName);
      //return kvp != null ? kvp.value : null;
      if (kvp == null) {
        return null;
      } else {
        const interviewPage = kvp.value as InterviewPage;
        return interviewPage.tabs.map(tab => {
          return {
            ...tab,
            needsAttention: this.doesMeasurementNeedsAttention(tab),
          }
        })
      }
    } else {
      return null;
    }
  }

  // determines if measurement needs to be addressed in UI. This will display a warning check mark next to measurement name and prevent collapsing of panel.
  // measurement needs attention if goal resolution is continue or percentage and a percertage value has not been selected
  private doesMeasurementNeedsAttention(tab: InterviewTab): boolean {
    return (tab.options.shortTermGoalResolution != null 
      && (tab.options.shortTermGoalResolution === eGoalResolution.continue || (tab.options.shortTermGoalResolution === eGoalResolution.percentage && !tab.options.stgPercentMet))) 
      && (tab.options.longTermGoalResolution != null 
      && (tab.options.longTermGoalResolution === eGoalResolution.continue || (tab.options.longTermGoalResolution === eGoalResolution.percentage && !tab.options.ltgPercentMet)))
  }

  getModuleTabModules(): ClinicalDocumentModule[] {
    const activeDocument = this.clinicalDocumentQuery.getActive();
    const modulesDictionary: Dictionary<KeyValuePair<string, InterviewPage>[]> = {
      [eClinicalMeasurementType.Functional]: activeDocument?.modules,
      [eClinicalMeasurementType.Physiological]: activeDocument?.contributingModules,
      [eClinicalMeasurementType.Other]: activeDocument?.otherModules,
    };

    let modulesKvp: ClinicalDocumentModule[] = [];
    Object.keys(modulesDictionary).forEach(key =>{
      modulesDictionary[key]
        .filter(kvp => kvp.value != null)
        .forEach((kvp, index) => {
          modulesKvp.push({
              moduleShortName: kvp.value.shortName,
              clinicalMeasurementType: +key,
              name: kvp.value.name,
              measurementShortNames: kvp.value.tabs.map(tab => tab.shortName),
              moduleTypeIndicatorName: index == 0 ? this.getModuleTypeIndicatorName(+key) : null
          });
      });
    });
  
    return modulesKvp;
  }

  private getModuleTypeIndicatorName(clinicalMeasurementType: eClinicalMeasurementType): string {
    switch (clinicalMeasurementType) {
      case eClinicalMeasurementType.Functional:
        return 'Functional Modules';
      case eClinicalMeasurementType.Physiological:
        return 'Physiological Modules';
      case eClinicalMeasurementType.Other:
        return 'Other Modules';
      default:
        return 'Other Modules';
    }
  }
  // TODO: can this be simplified since it's only used on readonly page?
  private getModuleSteps(modulesDictionary: Dictionary<KeyValuePair<string, InterviewPage>[]>, docType: ePatientClinicalDocumentType, isJbsCase: boolean): Dictionary<TmsStep[]> {
    let stepIndex = 0;
    let modulesStepDictionary: Dictionary<TmsStep[]> = {};

    Object.keys(modulesDictionary).forEach(key =>
      modulesStepDictionary[key] = modulesDictionary[key]
        .filter(kvp => kvp.value != null)
        .map((kvp, index) => {
          const bSubSteps = kvp.value.tabs.filter(tab => (tab.isVisible == null) || tab.isVisible).length > 0;
          let measurements = [];
          let subSteps = [];
          if (bSubSteps) {
            measurements = kvp.value.tabs.filter(tab => (tab.isVisible == null) || tab.isVisible);
            subSteps = kvp.value.tabs.filter(tab => (tab.isVisible == null) || tab.isVisible).map(tab => {
              return {
                name: tab.name,
                stage: !!tab.isValid ? eTmsStepStage.complete : eTmsStepStage.incomplete,
                step: stepIndex++
              }
            });
          }
          return {
            moduleShortName: kvp.value.shortName,
            measurements: measurements,
            clinicalMeasurementType: key,
            name: kvp.value.name,
            stage: this.isModuleMeasurementsComplete(measurements, docType, isJbsCase) ? eTmsStepStage.complete : eTmsStepStage.incomplete,
            step: bSubSteps ? index : stepIndex++,
            subSteps: subSteps,
          }
        }))

    return modulesStepDictionary;
  }

  private isModuleMeasurementsComplete(measurements: InterviewTab[], docType: ePatientClinicalDocumentType, isJbsCase: boolean): boolean {
    if (measurements.length == 0 && !!isJbsCase) {
      // jbs cases only need legacy modules to be considered complete 
      return true;
    } else if (measurements.length <= 0) {
      return false;
    } else if (docType == ePatientClinicalDocumentType.DISCHARGE_SUMMARY) {
      // for Discharge, every measurement needs to be valid 
      return measurements.every(tab => !!tab.isValid);
    } else {
      // for Eval, PN, Recert at least one measurement(tab) that is not a performance note needs to be valid 
      return measurements.some(tab => tab.shortName != ClincialDocumentInterviewTabConstants.DEFICIT_STRATEGY && !!tab.isValid);
    }
  }

  validateClinicalDocument(clinicalDocumentType: ePatientClinicalDocumentType, navToPath: string): Observable<ClinicalDocumentBase> {
    const activeSessionId = this.clinicalDocumentQuery.getActive().interviewSessionId;
    const patientCaseId: string = this.clinicalDocumentQuery.getActive().documentInfo.caseId;

    return this.submitClinicalDocument(patientCaseId, clinicalDocumentType, navToPath)
      .pipe(switchMap(() => this.http.get<OperationResponse<ClinicalDocumentBase>>(`${this.getClinicalDocumentUrl(clinicalDocumentType)}/${activeSessionId}/validate`)),
            map(() => this.clinicalDocumentQuery.getActive()));
  }

  signClinicalDocument(): Observable<SignatureResponse> {
    this.clinicalDocumentStore.updateActive(entity => {
      return {
        ...entity,
        interviewStatus: eInterviewStatus.SignedPendingMore,
      }
    });
    
    const document = this.clinicalDocumentQuery.getActive();
    if(document){
      return this.interviewService.signInterview(document.interviewSessionId, {pinCode: document.finalize.pinCode })
      .pipe(filter(signatureResponse => signatureResponse.interviewSessionId == this.clinicalDocumentQuery.getActiveId()),
            tap(signatureResponse => {
              this.clinicalDocumentStore.updateActive(entity => {
              return {
                ...entity,
                interviewStatus: signatureResponse.interviewStatus,
                documentRecordId: signatureResponse.documentRecordId,
                signatureRecords: signatureResponse.signatureRecords,
              }
          });
        }, catchError(error => {
        return throwError(error);
      })));
    }
  }

  updateClinicalDocumentOutcomes(patientCaseId: string, clinicalDocumentType: ePatientClinicalDocumentType): Observable<InterviewPage> {
    return this.submitClinicalDocument(patientCaseId, clinicalDocumentType, null).pipe(map(document => document.outcomes));
  }

  submitClinicalDocument(patientCaseId: string, clinicalDocumentType: ePatientClinicalDocumentType, navToPath: string) {
    return this.clinicalDocumentQuery.selectError()
      .pipe(take(1), switchMap(error => {
        const canSubmitDoc = error || this.dirtyCheckCollection.someDirty();
        if (this.clinicalDocumentQuery.getActiveId() && canSubmitDoc) {

          let updateUrl: string = `${this.getClinicalDocumentUrl(clinicalDocumentType)}/${patientCaseId}`;

          // if we're navigating to the finalize tab, add query param so we can get the goal statements
          if (navToPath && (navToPath === RouteConstants.PAT_CLINICAL_DOC_FINALIZE || navToPath === RouteConstants.PAT_CLINICAL_DOC_MODULES)) {
            updateUrl = `${updateUrl}?includeGoalStatements=1`;
          }

          return this.http.put<OperationResponse<ClinicalDocumentBase>>(updateUrl, this.clinicalDocumentQuery.getActive())
            .pipe(catchError(err => {
              if (!err.error.isSuccessful) {
                this.clinicalDocumentStore.setError(true);
                this.clinicalDocumentStore.updateActive(entity => {
                  return {
                    ...err.error.data,
                    finalize: {
                      ...err.error.data.finalize,
                      finalize: false,
                      pinCode: '',
                    }
                  }
                });
                return throwError(err);
              }
            }),
              map(response => response.data),
              tap(response => {
                this.clinicalDocumentStore.setError(false);
                this.clinicalDocumentStore.updateActive(entity => {
                  return {
                    ...entity,
                    documentRecordId: response.documentRecordId,
                    planOfCare: {
                      ...response.planOfCare,
                    },
                    contributingModules: [
                      ...response.contributingModules,
                    ],
                    modules: [
                      ...response.modules,
                    ],
                    otherModules: [
                      ...response.otherModules,
                    ],
                    outcomes: {
                      ...response.outcomes,
                    },
                    ...(response.stOutcomes != null && { stOutcomes: response.stOutcomes }),
                    answers: [
                      ...response.answers,
                    ],
                    goalStatements: {
                      ...response.goalStatements,
                    },
                    customGoalStatementDictionary: {
                      ...response.customGoalStatementDictionary,
                    },
                    modulesWithPerformanceNotesTracker: [
                      ...response.modulesWithPerformanceNotesTracker,
                    ]
                  }
                })
                this.dirtyCheckCollection.setHead();
              }), finalize(() => { }))
        } else {
          if (error) {
            return throwError('Error!!!!');
          } else {
            return of(this.clinicalDocumentQuery.getActive());
          }
        }
      }))
  }

  updateJbsMeasurement(value, property: string, jbsMeasurement: JbsMeasurement) {
    this.clinicalDocumentStore.updateActive(entity => {
      const existingJbsMeasureIndex = entity.jbsMeasurements.findIndex(jbsMeasure => jbsMeasure.moduleName == jbsMeasurement.moduleName 
                                                              && jbsMeasure.measurementName == jbsMeasurement.measurementName);                                                      
      if (existingJbsMeasureIndex >= 0) {
        const newJbsMeasurement = { ...entity.jbsMeasurements[existingJbsMeasureIndex], [property]: value };
        return {
          ...entity,
          jbsMeasurements: [
            ...entity.jbsMeasurements.slice(0, existingJbsMeasureIndex),
            newJbsMeasurement,
            ...entity.jbsMeasurements.slice(existingJbsMeasureIndex + 1),
          ]
        }
      } else {
        return entity;
      }
    })
  }

  updateClinicalModuleResolutions(moduleKey: string, tabKey: string, clinicalMeasurmentType: eClinicalMeasurementType, goalResolutionEvent: GoalResolutionEvent) {
    this.clinicalDocumentStore.updateActive(entity => {
      let property = '';
      switch (+clinicalMeasurmentType) {
        case eClinicalMeasurementType.Functional:
          property = ClinicalDocumentPropertiesConstants.MODULES;
          break;
        case eClinicalMeasurementType.Physiological:
          property = ClinicalDocumentPropertiesConstants.CONTRIBUTING_MODULES;
          break;
        case eClinicalMeasurementType.Other:
          property = ClinicalDocumentPropertiesConstants.OTHER_MODULES;
          break;
      }
      const modulesIndex = entity[property].findIndex(m => m.key == moduleKey);
      const tabIndex = entity[property][modulesIndex].value.tabs.findIndex(tab => tab.shortName === tabKey);

      const tab: InterviewTab = DeepCopyUtils.deepCopyObject(entity[property][modulesIndex].value.tabs[tabIndex]);
      tab.options.shortTermGoalResolution = goalResolutionEvent.stgResolution;
      tab.options.longTermGoalResolution = goalResolutionEvent.ltgResolution;
      tab.options.stgPercentMet = goalResolutionEvent.stgPercentageMet != null ? goalResolutionEvent.stgPercentageMet.toString() : '';
      tab.options.ltgPercentMet = goalResolutionEvent.ltgPercentageMet != null ? goalResolutionEvent.ltgPercentageMet.toString() : '';

      if (goalResolutionEvent.updatedField == ClinicalDocumentAnswerFieldsConstants.STG && (goalResolutionEvent.stgResolution == eGoalResolution.met || goalResolutionEvent.stgResolution == eGoalResolution.discharged)) {
        this.setGoalValuesNull(tab, ClinicalDocumentAnswerFieldsConstants.STG);
      }

      if (goalResolutionEvent.updatedField == ClinicalDocumentAnswerFieldsConstants.LTG && (goalResolutionEvent.ltgResolution == eGoalResolution.met || goalResolutionEvent.ltgResolution == eGoalResolution.discharged)) {
        this.setGoalValuesNull(tab, ClinicalDocumentAnswerFieldsConstants.LTG);
      }

      return {
        ...entity,
        [property]: [
          ...entity[property].slice(0, modulesIndex),
          ...[
            {
              key: moduleKey,
              value: {
                ...entity[property][modulesIndex].value,
                tabs: [
                  ...entity[property][modulesIndex].value.tabs.slice(0, tabIndex),
                  ...[tab],
                  ...entity[property][modulesIndex].value.tabs.slice(tabIndex + 1),
                ]
              }
            }
          ],
          ...entity[property].slice(modulesIndex + 1),
        ]
      }
    });
    this._clinicalDocumentMeasurementChanged$.next(moduleKey);
  }

  updateClinicalModuleMeasurementIsValid(moduleKey: string, tabKey: string, clinicalMeasurmentType: eClinicalMeasurementType, documentType: ePatientClinicalDocumentType) {
    if (documentType == ePatientClinicalDocumentType.DISCHARGE_SUMMARY) {
      this.clinicalDocumentStore.updateActive(entity => {
        let property = '';
        switch (+clinicalMeasurmentType) {
          case eClinicalMeasurementType.Functional:
            property = ClinicalDocumentPropertiesConstants.MODULES;
            break;
          case eClinicalMeasurementType.Physiological:
            property = ClinicalDocumentPropertiesConstants.CONTRIBUTING_MODULES;
            break;
          case eClinicalMeasurementType.Other:
            property = ClinicalDocumentPropertiesConstants.OTHER_MODULES;
            break;
        }
        const modulesIndex = entity[property].findIndex(m => m.key == moduleKey);
        const tabIndex = entity[property][modulesIndex].value.tabs.findIndex(tab => tab.shortName === tabKey);
  
        const updatedModule = DeepCopyUtils.deepCopyObject(entity[property]);
        updatedModule[modulesIndex].value.tabs[tabIndex] = {
          ...updatedModule[modulesIndex].value.tabs[tabIndex],
          isValid: true,
        };
        
        return {
          ...entity,
          [property]: updatedModule,
        }
      });
    }
  }

  private setGoalValuesNull(tab: InterviewTab, answerField: string) {
    // tab is pass by reference 
    // update primary group index 
    const interviewGroupIndex = tab.groups.findIndex(group => group.shortName == ClinicalDocumentInterviewGroupConstants.PRIMARY_GROUP_SHORTNAME);
    if (interviewGroupIndex >= 0) {
      // find answer field and set to null if goal resolution is MET or DC
      tab.groups[interviewGroupIndex].questions.forEach((question, index) => {
        const answerFieldIndex = question.answer.fields.findIndex(field => field.key == answerField);
        if (answerFieldIndex >= 0) {
          tab.groups[interviewGroupIndex].questions[index].answer.fields[answerFieldIndex].value.value = null;
        }
      });
    }

    // update ancillary group index
    const ancillaryGroupIndex = tab.groups.findIndex(group => group.shortName == ClinicalDocumentInterviewGroupConstants.ANCILLARY_GROUP_SHORTNAME);
    if (ancillaryGroupIndex >= 0) {
      // find answer field and set to null if goal resolution is MET or DC
      tab.groups[ancillaryGroupIndex].questions.forEach((question, index) => {
        const stgIndex = question.answer.fields.findIndex(field => field.key == answerField);
        if (stgIndex >= 0) {
          tab.groups[ancillaryGroupIndex].questions[index].answer.fields[stgIndex].value.value = null;
        }
      });
    }
  }

  deleteClinicalModuleMeasurement(mod: string, tabKey: string, clinicalMeasurmentType: eClinicalMeasurementType,
      patientCaseId: string, patientClinicalDocumentType: ePatientClinicalDocumentType): Observable<ClinicalDocumentBase> {
    this.clinicalDocumentStore.updateActive(entity => {
      let property = '';
      switch (+clinicalMeasurmentType) {
        case eClinicalMeasurementType.Functional:
          property = ClinicalDocumentPropertiesConstants.MODULES;
          break;
        case eClinicalMeasurementType.Physiological:
          property = ClinicalDocumentPropertiesConstants.CONTRIBUTING_MODULES;
          break;
        case eClinicalMeasurementType.Other:
          property = ClinicalDocumentPropertiesConstants.OTHER_MODULES;
          break;
      }
      const modulesIndex = entity[property].findIndex(m => m.key == mod);
      if (modulesIndex >= 0) {
        let newModule: InterviewPage = DeepCopyUtils.deepCopyObject(entity[property][modulesIndex].value);
        const tabIndex = entity[property][modulesIndex].value.tabs.findIndex(tab => tab.shortName === tabKey);

        if (tabIndex >= 0) {
          newModule = {
            ...newModule,
            tabs: [
              ...entity[property][modulesIndex].value.tabs.slice(0, tabIndex),
              ...entity[property][modulesIndex].value.tabs.slice(tabIndex + 1),
            ]
          }

          // update DeficitStratey Tab visibility if needed
          const dsTabIndex = newModule.tabs.findIndex(tab => tab.shortName === ClincialDocumentInterviewTabConstants.DEFICIT_STRATEGY);
          if (dsTabIndex >= 0) {
            const isDsTabVisible = newModule.tabs.filter(tab => tab.shortName !== ClincialDocumentInterviewTabConstants.DEFICIT_STRATEGY)
                                                  .some(tab => tab.groups.filter(group => group.shortName === ClinicalDocumentInterviewGroupConstants.PRIMARY_GROUP_SHORTNAME)
                                                                        .some(group => group.questions.some(question => question.answer.value != null || question.answer.values != null)));
            if (newModule.tabs[dsTabIndex].isVisible != isDsTabVisible) {
              newModule = {
                ...newModule,
                tabs: [
                  ...newModule.tabs.slice(0, dsTabIndex),
                  {
                    ...newModule.tabs[dsTabIndex],
                    isVisible: isDsTabVisible
                  },
                  ...newModule.tabs.slice(dsTabIndex + 1),
                ]
              }
            }
          }
        }

        return {
          ...entity,
          [property]: [
            ...entity[property].slice(0, modulesIndex),
            ...[
              {
                key: mod,
                value: newModule,
              }
            ],
            ...entity[property].slice(modulesIndex + 1),
          ],
          answers: [...entity.answers.filter(answer => answer.tab != tabKey)],
        }
      } else {
        return { ...entity };
      }
    });

    // PUT and notify UI of updates 
    return this.submitClinicalDocument(patientCaseId, patientClinicalDocumentType, null)
      .pipe(tap(() => this._clinicalDocumentMeasurementChanged$.next(mod)));
    
  }

  updateClinicalModuleMeasurementAnswer(interviewAnswer: InterviewAnswer, clinicalMeasurmentType: eClinicalMeasurementType) {
    let measurementsUpdated: boolean = false;
    let modulesUpdated: boolean = false;
    let moduleUpdatedShortName: string = null;
    this.clinicalDocumentStore.updateActive(entity => {
      let answerIndex = entity.answers.findIndex(answer => answer.shortName == interviewAnswer.shortName
        && answer.group == interviewAnswer.group
        && answer.tab == interviewAnswer.tab
        && answer.page == interviewAnswer.page);

      let fields: KeyValuePair<string, InterviewAnswerField>[] = [];
      let updatedAnswer: InterviewAnswer = { ...interviewAnswer };
      let currentAnswer: InterviewAnswer = null;

      if (answerIndex >= 0) {
        currentAnswer = entity.answers[answerIndex];

        // are we updating a field?
        if (interviewAnswer.fields.length > 0) {
          // are we updating an existing field?
          if (entity.answers[answerIndex].fields && entity.answers[answerIndex].fields.length > 0) {
            const fieldIndex = entity.answers[answerIndex].fields.findIndex(kvp => kvp.key === interviewAnswer.fields[0].key);
            // field exist, then override 
            if (fieldIndex >= 0) {
              fields = [
                ...entity.answers[answerIndex].fields.slice(0, fieldIndex),
                ...interviewAnswer.fields,
                ...entity.answers[answerIndex].fields.slice(fieldIndex + 1),
              ]
            } else {
              // field does not exist, append 
              fields = [
                ...entity.answers[answerIndex].fields,
                ...interviewAnswer.fields,
              ]
            }
          } else {
            // fields does not exist on answers, add 
            fields = [...interviewAnswer.fields];
          }
        } else {
          fields = entity.answers[answerIndex].fields;
        }

        updatedAnswer = {
          ...entity.answers[answerIndex],
          ...interviewAnswer,
          fields: fields,
        };
      }

      // do we need to update the measurement's interview question answer as well?
      let property = null;
      let modulesKvp = null;
      let updatedContributingModules = null;
      let updatedModulePerformanceNotesTrackerList = null;
      if (clinicalMeasurmentType) {
        switch (+clinicalMeasurmentType) {
          case eClinicalMeasurementType.Functional:
            property = ClinicalDocumentPropertiesConstants.MODULES;
            break;
          case eClinicalMeasurementType.Physiological:
            property = ClinicalDocumentPropertiesConstants.CONTRIBUTING_MODULES;
            break;
          case eClinicalMeasurementType.Other:
            property = ClinicalDocumentPropertiesConstants.OTHER_MODULES;
            break;
        }

        const updateMeasurementResult = this.updateMeasurementInterviewQuestionAnswer(updatedAnswer, property);
        modulesKvp = updateMeasurementResult[0];
        measurementsUpdated = updateMeasurementResult[1];

        // check if we're updating a performance note
        if (updatedAnswer.tab == ClincialDocumentInterviewTabConstants.DEFICIT_STRATEGY) {
          // update or add to our module performance notes tracker
          updatedModulePerformanceNotesTrackerList= this.updateModulePerformanceNotesTracker(updatedAnswer, property);
        }

        // update contributingModules property if needed 
        if (updatedAnswer.group == ClinicalDocumentInterviewGroupConstants.CONTRIBUTING_GROUP_SHORTNAME) {
          // does the current answer have module values? if it does, are we removing modules?
          if (currentAnswer?.values?.length > 0) {

            const modulesDiff = difference(currentAnswer.values, interviewAnswer.values);
            if (modulesDiff && modulesDiff.length == 1) {
              const moduleBeingRemoved = modulesDiff[0];

              // check if module is referenced in any other functional module
              const otherIndex = entity.answers.findIndex(answer => answer.tab !== updatedAnswer.tab
                && answer.page !== updatedAnswer.page
                && answer.shortName == updatedAnswer.shortName
                && answer.group == updatedAnswer.group
                && answer.values?.some(shortName => shortName == moduleBeingRemoved));
              
              // no other modules use this contributing factor, go ahead and remove it from list 
              if (otherIndex < 0) {
                updatedContributingModules = this.removeAndGetUpdatedContributingFactorModules(moduleBeingRemoved);
              }

            } else {
              // add to contributing modules list
              const contributingModulesResult = this.addAndGetUpdatedContributingFactorModules(updatedAnswer);
              updatedContributingModules = contributingModulesResult[0];
              moduleUpdatedShortName = contributingModulesResult[1];
            }
          } else {
            // add to contributing modules list 
            const contributingModulesResult = this.addAndGetUpdatedContributingFactorModules(updatedAnswer);
            updatedContributingModules = contributingModulesResult[0];
            moduleUpdatedShortName = contributingModulesResult[1];
          }
          
          modulesUpdated = true;
        }
      }

      if (answerIndex < 0) {
        // add new answer to collection
        return {
          ...entity,
          answers: [
            ...entity.answers,
            interviewAnswer,
          ],
          ...((property && modulesKvp) && { [property]: modulesKvp}),
          ...(updatedContributingModules != null && { contributingModules: updatedContributingModules }),
          ...(updatedModulePerformanceNotesTrackerList != null && { modulesWithPerformanceNotesTracker : updatedModulePerformanceNotesTrackerList })
        }
      } else {
        return {
          ...entity,
          answers: [
            ...entity.answers.slice(0, answerIndex),
            updatedAnswer,
            ...entity.answers.slice(answerIndex + 1),
          ],
          ...((property && modulesKvp) && { [property]: modulesKvp}),
          ...(updatedContributingModules != null && { contributingModules: updatedContributingModules }),
          ...(updatedModulePerformanceNotesTrackerList != null && { modulesWithPerformanceNotesTracker : updatedModulePerformanceNotesTrackerList })
        }
      }
    });
    if (modulesUpdated) {
      this._clinicalDocumentModuleChanged$.next(moduleUpdatedShortName);
    }
    if (measurementsUpdated && !modulesUpdated) {
      this._clinicalDocumentMeasurementChanged$.next(interviewAnswer.page);
    }
  }

  updateClinicalAnswerIsExcludedFlag(interviewAnswer: InterviewAnswer) {
    this.clinicalDocumentStore.updateActive(entity => {
      let answerIndex = entity.answers.findIndex(answer => answer.shortName == interviewAnswer.shortName
        && answer.group == interviewAnswer.group
        && answer.tab == interviewAnswer.tab
        && answer.page == interviewAnswer.page);

      const isExcluded = +interviewAnswer.value ? true : false;

      if (answerIndex < 0) {
        return {
          ...entity,
          answers: [
            ...entity.answers,
            {
              ...interviewAnswer,
              isExcluded: isExcluded,
            }
          ]
        }
      } else {
        return {
          ...entity,
          answers: [
            ...entity.answers.slice(0, answerIndex),
            {
              ...entity.answers[answerIndex],
              isExcluded: isExcluded,
            },
            ...entity.answers.slice(answerIndex + 1),
          ]
        }
      }
    });
  
  }

  updateClinicalOutcomesIsValidFlag(isStOutcomes: boolean) {
    const docProp = isStOutcomes ? ClinicalDocumentPropertiesConstants.ST_OUTCOMES : ClinicalDocumentPropertiesConstants.OUTCOMES;
    this.clinicalDocumentStore.updateActive(entity => {
      return {
        ...entity,
        [docProp]: {
          ...entity[docProp],
          pageOptions: {
            ...entity[docProp].pageOptions,
            isValid: true,
          }
        }
      }
    });
  }

  private updateMeasurementInterviewQuestionAnswer(answer: InterviewAnswer, property: string): ([KeyValuePair<string, InterviewPage>[], boolean]) {
    const entity = this.clinicalDocumentQuery.getActive();

    const modulesIndex = entity[property].findIndex(m => m.key == answer.page);
    if (modulesIndex >= 0) {
      const newModule: InterviewPage = DeepCopyUtils.deepCopyObject(entity[property][modulesIndex].value);
      const tabIndex = newModule.tabs.findIndex(tab => tab.shortName === answer.tab);
      const groupIndex = newModule.tabs[tabIndex].groups.findIndex(group => group.shortName === answer.group);
      const questionIndex = newModule.tabs[tabIndex].groups[groupIndex].questions.findIndex(question => question.shortName == answer.shortName);

      var uiUpdateNeeded: boolean = false;

      if (tabIndex >= 0 && groupIndex >= 0 && questionIndex >= 0) {
        newModule.tabs[tabIndex].groups[groupIndex].questions[questionIndex].answer = DeepCopyUtils.deepCopyObject(answer);

        // update DeficitStratey Tab to be visible if any tabs for this module has a current value 
        const dsTabIndex = newModule.tabs.findIndex(tab => tab.shortName === ClincialDocumentInterviewTabConstants.DEFICIT_STRATEGY);
        if (dsTabIndex >= 0) {
          const isDsTabVisible = newModule.tabs.filter(tab => tab.shortName !== ClincialDocumentInterviewTabConstants.DEFICIT_STRATEGY)
                                               .some(tab => tab.groups.filter(group => group.shortName === ClinicalDocumentInterviewGroupConstants.PRIMARY_GROUP_SHORTNAME)
                                                                      .some(group => group.questions.some(question => question.answer.value != null || question.answer.values != null)));
          if (newModule.tabs[dsTabIndex].isVisible != isDsTabVisible) {
            newModule.tabs[dsTabIndex].isVisible = isDsTabVisible;
            uiUpdateNeeded = true;
          }
        }

        return [
          [
          ...entity[property].slice(0, modulesIndex),
          {
            key: answer.page,
            value: newModule,
          },
          ...entity[property].slice(modulesIndex + 1),
          ],
          uiUpdateNeeded,
        ];
      }
    } else {
      console.error(`Unable to update measurement contributing factor/impact goal.`);
      return null;
    }
  }

  private updateModulePerformanceNotesTracker(updatedAnswer: InterviewAnswer, property: string): KeyValuePair<string, boolean>[] {
    const entity = this.clinicalDocumentQuery.getActive();

    if (entity.clinicalDocumentType != ePatientClinicalDocumentType.PROGRESS_NOTE && entity.clinicalDocumentType != ePatientClinicalDocumentType.RECERT) {
      return null;
    }
    
    // retrieve module
    const modulesIndex = entity[property].findIndex(m => m.key == updatedAnswer.page); 

    if (modulesIndex >= 0) {
      // find the deficit strategy tab
      var modulesPage: InterviewPage = (entity[property][modulesIndex]).value;
      var deficitStrategyTab = modulesPage.tabs.find(tab => tab.shortName == ClincialDocumentInterviewTabConstants.DEFICIT_STRATEGY);

      // check if all group questions have an answer 
      const allQuestionsAnswered = deficitStrategyTab.groups.every(group => group.questions.every(question => question.answer != null && question.answer.textArea != null && question.answer.textArea != ''));

      // update/add to tracker
      const kvpIndex = entity.modulesWithPerformanceNotesTracker.findIndex(p => p.key == updatedAnswer.page);

      if (kvpIndex >= 0) {
        return [
            ...entity.modulesWithPerformanceNotesTracker.slice(0, kvpIndex),
            {
              key: updatedAnswer.page,
              value: allQuestionsAnswered,
            },
            ...entity.modulesWithPerformanceNotesTracker.slice(kvpIndex + 1)
          ]
      } else {
        return [
            ...entity.modulesWithPerformanceNotesTracker,
            {
              key: updatedAnswer.page,
              value: allQuestionsAnswered,
            },
        ]
      }
    } else {
      console.error('Unable to update modules performance tracker. Module index was not found.');
      return null;
    }
  }

  private removeAndGetUpdatedContributingFactorModules(contributingModuleShortName: string): KeyValuePair<string, InterviewPage>[] {
    let contributingModules: KeyValuePair<string, InterviewPage>[] = null;
    const activeDocument = this.clinicalDocumentQuery.getActive();
    const cmIndex = activeDocument.contributingModules.findIndex(cm => cm.key == contributingModuleShortName);
    // only remove contributing module if it does not have measurements 
    if (cmIndex >= 0 
        && activeDocument.contributingModules[cmIndex].value.tabs?.length == 1
        && activeDocument.contributingModules[cmIndex].value.tabs[0].shortName == ClincialDocumentInterviewTabConstants.DEFICIT_STRATEGY) {
      contributingModules = [
        ...activeDocument.contributingModules.slice(0, cmIndex),
        ...activeDocument.contributingModules.slice(cmIndex + 1),
      ];
    }
    return contributingModules;
  }

  private addAndGetUpdatedContributingFactorModules(answer: InterviewAnswer): ([KeyValuePair<string, InterviewPage>[], string]) {
    let contributingModules: KeyValuePair<string, InterviewPage>[] = null;
    let addedModuleShortName: string = null;

    // add to contributing module to list if this is a functional module
      answer.values.forEach(contributingFactor => {
        // check if contributing factor already exists
        const existingContributingFactor = this.clinicalDocument.contributingModules.findIndex(cm => cm.value.shortName == contributingFactor);
        if (existingContributingFactor < 0) {
          // get physiological data list item 
          const physioModule = this.cacheManService.getClinicalModules()[contributingFactor];
          const activeDocument = this.clinicalDocumentQuery.getActive();
          if (physioModule) {
            addedModuleShortName = physioModule.shortName;
            contributingModules = [
              ...activeDocument.contributingModules,
              {
                key: contributingFactor,
                value: {
                  groups: [],
                  name: physioModule.name,
                  shortName: physioModule.shortName,
                  pageOptions: {
                    canMarkAsNA: false,
                    isMarkedAsNA: false,
                  },
                  tabs: []
                }
              }
            ];
          } else {
            console.error(`Physiological Module ${contributingFactor} does not exist in data list PhysiologicalModules`);
          }
        }
      });

    return [contributingModules, addedModuleShortName];
  }

  updateCustomGoalStatmentAnswers(key: string, measurementKeyIdentifier: string, timeFrame: number, templateVariable: string, 
      impactStatementDataList: DataListItem[], value?: string, values?: string[]): string {
      let impactTextValue: string = null;
      this.clinicalDocumentStore.updateActive(entity => {
        const customGoalStatementDictionaryCopy: Dictionary<CustomGoalStatement[]> = DeepCopyUtils.deepCopyObject(entity.customGoalStatementDictionary);
        const customGoalStatement = customGoalStatementDictionaryCopy[key].find(p => p.measurementIdentifierKey == measurementKeyIdentifier && p.timeFrame == timeFrame);
        
        const customGoalAnswer = customGoalStatement?.customGoalAnswers.find(p => p.key === templateVariable);
        customGoalAnswer.value = value != null ? value : values.join(',');
        
        const customGoalItem = customGoalStatement?.customGoalTextItems.find(p => p.customTextTemplateVariable === templateVariable);
        customGoalItem.value = value;
        customGoalItem.values = values;

        // update text area if we're updating drop down list
        if (templateVariable == ClinicalDocumentPropertiesConstants.GOAL_STATEMENT_IMPACT_STATEMENT) {
          impactTextValue = values.map(value => impactStatementDataList.find(dli => dli.shortName == value)?.description).join(', ');
          const impactTextGoalAnswer = customGoalStatement?.customGoalAnswers.find(p => p.key === ClinicalDocumentPropertiesConstants.GOAL_STATEMENT_IMPACT_STATEMENT_TEXT);
          impactTextGoalAnswer.value = impactTextValue;

          const impactTextGoalItem = customGoalStatement?.customGoalTextItems.find(p => p.customTextTemplateVariable === ClinicalDocumentPropertiesConstants.GOAL_STATEMENT_IMPACT_STATEMENT_TEXT);
          impactTextGoalItem.value = impactTextValue;
        }

        return {
          ...entity,
          customGoalStatementDictionary: customGoalStatementDictionaryCopy,
        }
      });

      return impactTextValue;
  }

  finalizeDocument(finalize: ClinicalDocumentFinalize, clinicalDocSteps: KeyValuePair<number, TmsStep>[], discipline: eDiscipline, documentType: ePatientClinicalDocumentType) {
    const isFinalized = this.getIsFinalizedValid(finalize, documentType) && this.allClinicalDocumentStepsComplete(clinicalDocSteps, discipline, documentType);
    this.updateClinicalDocument<ClinicalDocumentFinalize>(ClinicalDocumentPropertiesConstants.FINALIZE, { ...finalize, finalize: isFinalized });
  }

  resetFinalize() {
    let final = {};
    this.clinicalDocument$<ClinicalDocumentFinalize>(ClinicalDocumentPropertiesConstants.FINALIZE)
      .pipe(filter(finalized => finalized != null), take(1))
      .subscribe(finalized => {
        final = finalized;
        this.updateClinicalDocument<ClinicalDocumentFinalize>(ClinicalDocumentPropertiesConstants.FINALIZE, { ...final, pinCode: '', finalize: false });
      });
  }

  allClinicalDocumentStepsComplete(clinicalDocSteps: KeyValuePair<number, TmsStep>[], discipline: eDiscipline, documentType: ePatientClinicalDocumentType, excludeFinalize: boolean = true): boolean {
    const finalizedIndex = excludeFinalize ? clinicalDocSteps.findIndex(kvp => kvp.key === eDocumentTabStepIndex.FINALIZE) : -1;
    return this.getClinicalDocumentSteps(clinicalDocSteps, discipline, documentType).reduce((acc, curr, index) => {
      if (index != finalizedIndex) {
        return acc && curr.stage === eTmsStepStage.complete
      } else {
        return acc;
      }
    }, true);
  }

  updateEvaluationPathway(evalPathway: ClinicalDocumentPathway): void {
    this.clinicalDocumentStore.updateActive(entity => {
      return {
        ...entity,
        pathway: evalPathway,
      }
    });
  }

  updateClinicalDocument<T>(property: string, object: T) {
    this.clinicalDocumentStore.updateActive(entity => {
      return {
        ...entity,
        [property]: this.setIsValidFlag<T>(property, object, entity),
      }
    });
  }

  unsignDocument(interviewSessionId: string, caseId: string, documentType: ePatientClinicalDocumentType, navToPath: string): Observable<ClinicalDocumentBase> {
    return this.interviewService.unsignInterview(interviewSessionId, {})
      .pipe(switchMap(() => {
        this.clinicalDocumentStore.updateActive(entity => {
          return {
            ...entity,
            interviewStatus: eInterviewStatus.InProgress,
            documentRecordId: null,
            finalize: {
              ...entity.finalize,
              finalize: false,
            }
          }
        });
        return this.submitClinicalDocument(caseId, documentType, navToPath);
      }));
  }

  private setIsValidFlag<T>(property: string, object: T, currentDoc: ClinicalDocumentBase): T {
    const isValid = this.isPropertyValid(property, object, currentDoc);
    if (isValid == null) {
      return object;
    } else {
      return {
        ...object,
        isValid: isValid,
      }
    }
  }

  private isPropertyValid(property: string, object: any, currentDoc: ClinicalDocumentBase): boolean {
    switch (property) {
      case ClinicalDocumentPropertiesConstants.PREVIOUS_THERAPY_MODEL: {
        return this.getPreviousTherapiesValid(object);
      }
      case ClinicalDocumentPropertiesConstants.PRECAUTIONS:
      case ClinicalDocumentPropertiesConstants.PROGNOSTIC_INDICATORS:
      case ClinicalDocumentPropertiesConstants.REASON_FOR_REFERRAL: {
        return this.isNarrativeBuilderValid(object);
      }
      case ClinicalDocumentPropertiesConstants.PLOF: {
        return this.isEvalPlofValid(object);
      }
      case ClinicalDocumentPropertiesConstants.REHAB_POTENTIAL_GOALS: {
        return this.getRehabPotentialAndGoalsValid(object);
      }
      case ClinicalDocumentPropertiesConstants.PLANNED_DISCHARGE: {
        return this.getPlannedDCValid(object);
      }
      case ClinicalDocumentPropertiesConstants.PLAN_OF_CARE: {
        return this.getPlanOfCareValid(object, currentDoc.clinicalDocumentType);
      }
      case ClinicalDocumentPropertiesConstants.EXTENT_OF_PROGRESS: {
        return this.getExtentOfProgressValid(object, currentDoc.clinicalDocumentType);
      }
      case ClinicalDocumentPropertiesConstants.INTERVENTIONS:
      case ClinicalDocumentPropertiesConstants.DEFICITS: {
        return ClinicalDocumentValidator.isRecertInfoValid(object);
      }
      case ClinicalDocumentPropertiesConstants.DURATIONS: {
        return ClinicalDocumentValidator.isRecertDurationsValid(object);
      }
      case ClinicalDocumentPropertiesConstants.DISCHARGE_INFO: {
        return this.getDischargeInfoValid(object);
      }
      default:
        return null;
    }
  }

  private isStepValid(property: string): boolean {
    const activeDocument = this.clinicalDocumentQuery.getActive();
    if (activeDocument) {
      if (activeDocument[property] != null && activeDocument[property].isValid != null) {
        return activeDocument[property].isValid;
      } else {
        //console.warn('isValid property does not exist on ' + property);
        return false;
      }
    } else {
      return false;
    }
  }

  getEvaluationInfoSteps(): TmsStepGroup[] {
    const activeDocument = this.clinicalDocumentQuery.getActive() as EvaluationDocument;
    return [
      {
        title: 'Evaluation Info',
        steps: [
          {
            name: 'Template',
            stage: this.getTmsStepStage(this.getTemplateValid(activeDocument)),
            step: 0,
          },
          {
            name: 'Reason for Referral',
            stage: this.getTmsStepStage(this.isStepValid(ClinicalDocumentPropertiesConstants.REASON_FOR_REFERRAL)),
            step: 1,
          },
          {
            name: 'Rehab Potential and Goals',
            stage: this.getTmsStepStage(this.isStepValid(ClinicalDocumentPropertiesConstants.REHAB_POTENTIAL_GOALS)),
            step: 2,
          },
          {
            name: 'Prognostic Indicators',
            stage: this.getTmsStepStage(this.isStepValid(ClinicalDocumentPropertiesConstants.PROGNOSTIC_INDICATORS)),
            step: 3,
          },
          {
            name: 'Previous Therapies',
            stage: this.getTmsStepStage(this.isStepValid(ClinicalDocumentPropertiesConstants.PREVIOUS_THERAPY_MODEL)),
            step: 4,
          },
          {
            name: 'Precautions',
            stage: this.getTmsStepStage(this.isStepValid(ClinicalDocumentPropertiesConstants.PRECAUTIONS)),
            step: 5,
          },
          {
            name: 'Medical History',
            stage: eTmsStepStage.complete,
            step: 6,
          },
          {
            name: 'Prior Level of Function',
            stage: this.getTmsStepStage(this.isStepValid(ClinicalDocumentPropertiesConstants.PLOF)),
            step: 7,
          }
        ]
      }
    ]
  }

  getClinicalDocumentSteps(clinicalDocumentSteps: KeyValuePair<number, TmsStep>[], discipline: eDiscipline, documentType: ePatientClinicalDocumentType): TmsStep[] {
    const activeDocument = this.clinicalDocumentQuery.getActive();
    if (activeDocument) {
      return clinicalDocumentSteps.map(kvp => {
        let stepStage: eTmsStepStage;
        switch (kvp.key) {
          case eDocumentTabStepIndex.EVALUATION_INFO:
            stepStage = this.getTmsStepStage(this.getEvaluationValid(activeDocument as EvaluationDocument));
            break;
          case eDocumentTabStepIndex.MODULES:
            stepStage = this.getTmsStepStage(this.getModulesValid(activeDocument))
            break;
          case eDocumentTabStepIndex.OTHER_NOTES:
            stepStage = eTmsStepStage.complete;
            break;
          case eDocumentTabStepIndex.OUTCOMES:
            stepStage = this.getTmsStepStage(this.getOutcomesValid(activeDocument, documentType, false));
            break;
          case eDocumentTabStepIndex.ST_OUTCOMES:
            stepStage = this.getTmsStepStage(this.getOutcomesValid(activeDocument, documentType, true));
            break;
          case eDocumentTabStepIndex.PLANNED_DC:
            stepStage = this.getTmsStepStage(this.isStepValid(ClinicalDocumentPropertiesConstants.PLANNED_DISCHARGE));
            break;
          case eDocumentTabStepIndex.PATIENT_COMPLEXITY:
            stepStage = this.getTmsStepStage(this.getPatientComplexityValid(activeDocument as EvaluationDocument, discipline));
            break;
          case eDocumentTabStepIndex.PLAN_OF_CARE:
            stepStage = this.getTmsStepStage(this.isStepValid(ClinicalDocumentPropertiesConstants.PLAN_OF_CARE));
            break;
          case eDocumentTabStepIndex.EXTENT_OF_PROGRESS:
            stepStage = this.getTmsStepStage(this.isStepValid(ClinicalDocumentPropertiesConstants.EXTENT_OF_PROGRESS));
            break;
          case eDocumentTabStepIndex.RECERT_INFO:
            stepStage = this.getTmsStepStage(ClinicalDocumentValidator.isRecertDocumentValid(activeDocument as RecertDocument));
            break;
          case eDocumentTabStepIndex.DAILY_NOTES: // TODO
            stepStage = eTmsStepStage.complete;
            break;
          case eDocumentTabStepIndex.DISCHARGE_INFO:
            stepStage = this.getTmsStepStage(this.isStepValid(ClinicalDocumentPropertiesConstants.DISCHARGE_INFO));
            break;
          case eDocumentTabStepIndex.GOAL_STATEMENT:
            stepStage = eTmsStepStage.complete;
            break;
          case eDocumentTabStepIndex.FINALIZE:
            stepStage = this.getTmsStepStage(this.getFinalizeValid(activeDocument, documentType));
            break;
          case eDocumentTabStepIndex.LEGACY:
            stepStage = eTmsStepStage.complete;
            break;
          default:
            stepStage = eTmsStepStage.incomplete;
        }
        return {
          ...kvp.value,
          stage: stepStage,
        }
      })
    } else {
      return clinicalDocumentSteps.map(kvp => kvp.value);
    }
  }

  isClinicalDocumentTabComplete(route: string, discipline: eDiscipline, documentType: ePatientClinicalDocumentType): boolean {
    const activeDocument = this.clinicalDocumentQuery.getActive();
    
    // Check if this is a jbs document. If it is, then all tabs are valid
    if (activeDocument && activeDocument.isJbsCase && activeDocument.interviewStatus === eInterviewStatus.Signed && activeDocument.documentRecordId == null) {
      return true;
    }

    if (activeDocument) {
        switch (route) {
          case RouteConstants.PAT_CLINICAL_DOC_EVAL_INFO:
            return this.getEvaluationValid(activeDocument as EvaluationDocument);
          case RouteConstants.PAT_CLINICAL_DOC_MODULES:
            return this.getModulesValid(activeDocument);
          case RouteConstants.PAT_CLINICAL_DOC_OTHER_NOTES:
            return true;
          case RouteConstants.PAT_CLINICAL_DOC_OUTCOMES:
            return this.getOutcomesValid(activeDocument, documentType, false);
          case RouteConstants.PAT_CLINICAL_DOC_ST_OUTCOMES:
            return this.getOutcomesValid(activeDocument, documentType, true);
          case RouteConstants.PAT_CLINICAL_DOC_PLANNED_DC:
            return this.isStepValid(ClinicalDocumentPropertiesConstants.PLANNED_DISCHARGE);
          case RouteConstants.PAT_CLINICAL_DOC_COMPLEXITY:
            return this.getPatientComplexityValid(activeDocument as EvaluationDocument, discipline);
          case RouteConstants.PAT_CLINICAL_DOC_POC:
            return this.isStepValid(ClinicalDocumentPropertiesConstants.PLAN_OF_CARE);
          case RouteConstants.PAT_CLINICAL_DOC_EOP:
            return this.isStepValid(ClinicalDocumentPropertiesConstants.EXTENT_OF_PROGRESS);
          case RouteConstants.PAT_CLINICAL_DOC_RECERT_INFO:
            return ClinicalDocumentValidator.isRecertDocumentValid(activeDocument as RecertDocument);
          case RouteConstants.PAT_CLINICAL_DOC_DAILY_NOTES: // TODO
            return true;
          case RouteConstants.PAT_CLINICAL_DOC_DC_INFO:
            return this.isStepValid(ClinicalDocumentPropertiesConstants.DISCHARGE_INFO);
          case RouteConstants.PAT_CLINICAL_DOC_FINALIZE:
            return this.getFinalizeValid(activeDocument, documentType);
          case RouteConstants.PAT_CLINCIAL_DOC_LEGACY:
            return true;
          case RouteConstants.PAT_CLINICAL_DOC_WEEKLY_UPDATE:
            return true;
          default:
            return false;
        }      
    }
    return false;
  }

  private getDischargeInfoValid(dischargeInfo: DischargeInfo) {
    return dischargeInfo.dischargeDisposition && dischargeInfo.dischargeDisposition > 0
      && dischargeInfo.followupRecommended && dischargeInfo.followupRecommended > 0
      && dischargeInfo.plannedDischargeDestination && dischargeInfo.plannedDischargeDestination > 0
      && !!dischargeInfo.progressTherapyNotes
      && !!dischargeInfo.explanation
  }

  private getExtentOfProgressValid(extentOfProgress: ExtentOfProgress, documentType: ePatientClinicalDocumentType) {
    return !!extentOfProgress.extentOfProgress && (documentType == ePatientClinicalDocumentType.DISCHARGE_SUMMARY || !!extentOfProgress.continuedTherapyNeed);
  }

  private getFinalizeValid(document: ClinicalDocumentBase, documentType: ePatientClinicalDocumentType) {
    return document && document.finalize != null && this.getIsFinalizedValid(document.finalize, documentType);
  }

  private getIsFinalizedValid(finalized: ClinicalDocumentFinalize, documentType: ePatientClinicalDocumentType): boolean {
    if (documentType === ePatientClinicalDocumentType.EVALUATION) {
      return (finalized.assessment != null && finalized.assessment != '')
        && (finalized.pinCode != null && finalized.pinCode != '')
        && finalized.finalize;
    } else {
      return finalized.pinCode != null && finalized.pinCode != '' && finalized.finalize;
    }
  }

  private getPlanOfCareValid(planOfCare: PlanOfCare, clinicalDocumentType: ePatientClinicalDocumentType) {
    // at least one plan of care items needs to be selected 
    // if this is a Evaluation, at least one Eval Code needs to be selected. 
    return planOfCare.items
      && planOfCare.items.some(poc => poc.isSelected)
      && (clinicalDocumentType == ePatientClinicalDocumentType.RECERT || planOfCare.items.some(poc => poc.isSelected && poc.isEvaluation));
  }

  private getPatientComplexityValid(document: EvaluationDocument, discipline: eDiscipline) {
    return document && this.getPatientComplexityValidByPC(document.patientComplexity, discipline);
  }

  getPatientComplexityValidByPC(pc: EvaluationPatientComplexity, discipline: eDiscipline) {
    if (discipline == eDiscipline.SpeechTherapy) {
      return true; // patient complexity not required for ST
    } else {
      return pc && Object.keys(pc).reduce((acc, curr) => acc && (curr == 'id' || pc[curr] != null), true);
    }
  }

  private getPlannedDCValid(plannedDischargeModel: EvaluationPlannedDischarge): boolean {
    return plannedDischargeModel.dischargeDestination && plannedDischargeModel.dischargeDestination != '';
  }

  private getOutcomesValid(document: ClinicalDocumentBase, clinicalDocumentType: ePatientClinicalDocumentType, isStOutcomes: boolean): boolean {
    if (document) {
      const docProp = isStOutcomes ? ClinicalDocumentPropertiesConstants.ST_OUTCOMES : ClinicalDocumentPropertiesConstants.OUTCOMES;

      if (clinicalDocumentType == ePatientClinicalDocumentType.PROGRESS_NOTE || clinicalDocumentType == ePatientClinicalDocumentType.RECERT || clinicalDocumentType == ePatientClinicalDocumentType.DISCHARGE_SUMMARY) {
          const outcomes = document[docProp] as InterviewPage;
          if (outcomes.pageOptions.isValid == null || !outcomes.pageOptions.isValid) {
            return false;
          }
      }
      return document[docProp].tabs.every((tab: InterviewTab) => {
        return tab.groups.every((group) => {
          return group.questions.every(question => {
            if (!question.validationOptions.isRequired || !question.isVisible) {
              return true;
            }

            const answerIndex = document.answers.findIndex(answer => answer.shortName === question.shortName
              && answer.group == group.shortName && answer.tab == answer.tab);
            if (answerIndex < 0) {
              return false;
            } else {
              const answer = document.answers[answerIndex];
              return this.isOutcomesAnswerValid(question, answer, clinicalDocumentType, isStOutcomes);
            }
          })
        })
      })
    }
  }

  private isOutcomesAnswerValid(question: InterviewQuestion, answer: InterviewAnswer, clinicalDocumentType: ePatientClinicalDocumentType, isStOutcomes: boolean): boolean {
    if (clinicalDocumentType == ePatientClinicalDocumentType.EVALUATION && !isStOutcomes) {
      // Check for Section GG questions that have a hidden, non-required goal
      let isHiddenGoal = false;
      if (question && question.options && question.options.goal && question.options.goal === eInterviewFieldEditMode.hidden) {
        isHiddenGoal = true;
      }

      const goalValueIndex = answer.fields != null ? answer.fields.findIndex(field => field.key == ClinicalDocumentAnswerFieldsConstants.Goal) : null;
      return (answer.value != null
        && (isHiddenGoal || (answer.fields != null
          && answer.fields.length > 0
          && goalValueIndex >= 0
          && answer.fields[goalValueIndex].value.value != null)))
    } else {
      return answer.value != null || (answer.values != null && answer.values.length > 0)
    }
  }


  private getModulesValid(document: ClinicalDocumentBase): boolean {
    const isModulesValid = this.isModuleGroupValid(document.modules, document.clinicalDocumentType, document.isJbsCase);
    const isPhysioModulesValid = this.isModuleGroupValid(document.contributingModules, document.clinicalDocumentType, document.isJbsCase);
    const isOtherModulesValid = this.isModuleGroupValid(document.otherModules, document.clinicalDocumentType, document.isJbsCase);
    const isPerformanceNoteRequirementMet = this.isPerformanceNoteRequirementMet(document);

    if (document.clinicalDocumentType === ePatientClinicalDocumentType.DISCHARGE_SUMMARY) {
      return isModulesValid && isPhysioModulesValid && isOtherModulesValid && isPerformanceNoteRequirementMet;
    } else {
      return (isModulesValid || isPhysioModulesValid || isOtherModulesValid) && isPerformanceNoteRequirementMet;
    }
  }

  private isModuleGroupValid(modules: KeyValuePair<string, InterviewPage>[], docType: ePatientClinicalDocumentType, isJbsCase: boolean): boolean {
    if (modules.length == 0 && !!isJbsCase) {
      return true;
    } else {
      if (docType === ePatientClinicalDocumentType.DISCHARGE_SUMMARY) {
        return modules.every(mod => this.isModuleMeasurementsComplete(mod.value.tabs, docType, isJbsCase));
      } else {
        return modules.some(mod => this.isModuleMeasurementsComplete(mod.value.tabs, docType, isJbsCase));
      }
    }
  }

  // Atleast 50% of modules with LTG need to have performance notes
  private isPerformanceNoteRequirementMet(document: ClinicalDocumentBase): boolean {
    if (document.modulesWithPerformanceNotesTracker) {
      var moduleWithLtg = document.modulesWithPerformanceNotesTracker;
      var modulesWithPerformanceNotesCount = moduleWithLtg.filter(p => !!p.value).length;
      var performanceNotesNeeded = ceil(moduleWithLtg.length / 2);
      return modulesWithPerformanceNotesCount >= performanceNotesNeeded;
    } else {
      return true;
    }
  }

  private getEvaluationValid(document: EvaluationDocument): boolean {
    return this.getTemplateValid(document)
      && this.isStepValid(ClinicalDocumentPropertiesConstants.REASON_FOR_REFERRAL)
      && this.isStepValid(ClinicalDocumentPropertiesConstants.REHAB_POTENTIAL_GOALS)
      && this.isStepValid(ClinicalDocumentPropertiesConstants.PROGNOSTIC_INDICATORS)
      && this.isStepValid(ClinicalDocumentPropertiesConstants.PRECAUTIONS)
      && this.isStepValid(ClinicalDocumentPropertiesConstants.PREVIOUS_THERAPY_MODEL)
      && this.isStepValid(ClinicalDocumentPropertiesConstants.PLOF);
  }

  private getTemplateValid(document: EvaluationDocument): boolean {
    return document != null;
  }

  private getRehabPotentialAndGoalsValid(rehabPotentialGoals: EvaluationRehabPotentialGoals): boolean {
    return this.isNarrativeBuilderValid(rehabPotentialGoals.rehabPotential)
      && this.isNarrativeBuilderValid(rehabPotentialGoals.patientGoals)
      && rehabPotentialGoals.onsetDate != null
      && rehabPotentialGoals.frequency != null && rehabPotentialGoals.frequency >= 1
      && rehabPotentialGoals.medicalIcdCodes != null && rehabPotentialGoals.medicalIcdCodes.length > 0
      && rehabPotentialGoals.treatmentIcdCodes != null && rehabPotentialGoals.treatmentIcdCodes.length > 0;
  }

  private getPreviousTherapiesValid(previousTherapyModel: EvaluationPreviousTherapy): boolean {
    return previousTherapyModel.isPreviousTherapyReceived != null
      && previousTherapyModel.selectedOptions && previousTherapyModel.selectedOptions.length > 0;
  }

  private isNarrativeBuilderValid(narrativeBuilder: NarrativeBuilder): boolean {
    return !!narrativeBuilder.text;
  }

  private isEvalPlofValid(narrativeBuilder: NarrativeBuilder): boolean {
    return !!narrativeBuilder.text;
  }

  private getClinicalDocumentUrl(docType: ePatientClinicalDocumentType): string {
    return `${this.urlConstantsService.CLINICAL_DOCUMENTS_URL}/${docType}`;
  }

  private getClinicalDocumentAnswers(modules: KeyValuePair<string, InterviewPage>[]): InterviewAnswer[] {
    let answers: InterviewAnswer[] = [];
    modules.forEach((kvp: KeyValuePair<string, InterviewPage>) => {
      answers = answers.concat(this.getClinicalDocumentAnswersFromInterviewPage(kvp.value));
    });
    return answers;
  }

  private getClinicalDocumentAnswersFromInterviewPage(page: InterviewPage): InterviewAnswer[] {
    const answers: InterviewAnswer[] = [];
    if (page) {
      page.tabs.forEach(tab => {
        tab.groups.forEach(group => {
          group.questions.forEach(question => {
            if (question.isVisible && this.isAnswerNotNull(question.answer)) {
              answers.push(question.answer);
            }
          });
        });
      });
    }
    return answers;
  }

  private isAnswerNotNull(answer: InterviewAnswer): boolean {
    return answer.value != null
      || (answer.values != null && answer.values.length > 0)
      || (answer.fields != null && answer.fields.some(kvp => (kvp.value.value != null && kvp.value.value != '') || (kvp.value.values && kvp.value.values.length > 0)))

  }

  private getTmsStepStage(isComplete: boolean): eTmsStepStage {
    return isComplete ? eTmsStepStage.complete : eTmsStepStage.incomplete;
  }

  // for creating steps for clinical document wizard 
  getClinicalDocumentWizardSteps(documentType: ePatientClinicalDocumentType, user: User, payer: Payer): KeyValuePair<number, TmsStep>[] {
    let tabSteps: eDocumentTabStepIndex[] = [];
    switch (documentType) {
      case ePatientClinicalDocumentType.EVALUATION:
        tabSteps = EVALUATION_STEPS;
        break;
      case ePatientClinicalDocumentType.RECERT:
        tabSteps = RECERT_STEPS;
        break;
      case ePatientClinicalDocumentType.PROGRESS_NOTE:
        tabSteps = PROGRESS_NOTE_STEPS;
        break;
      case ePatientClinicalDocumentType.DISCHARGE_SUMMARY:
        tabSteps = DISCHARGE_SUMMARY_STEPS;
        break;
    };

    const visibleSteps = this.getClinicalDocumentVisibleSteps(payer, user);
    
    let steps: KeyValuePair<number, TmsStep>[] = [];
    let stepIndex = 0;
    tabSteps.forEach(tab => {
      let stepName: string = null;
      switch (tab) {
        case eDocumentTabStepIndex.EVALUATION_INFO: {
          stepName = 'Evaluation Info';
          break;
        } case eDocumentTabStepIndex.MODULES: {
          stepName = 'Modules';
          break;
        } case eDocumentTabStepIndex.OTHER_NOTES: {
          stepName = 'Other Notes';
          break;
        } case eDocumentTabStepIndex.OUTCOMES: {
          if (!visibleSteps.showStOutcomes) {
            stepName = 'Outcomes';
          }
          break;
        } case eDocumentTabStepIndex.ST_OUTCOMES: {
          if (visibleSteps.showStOutcomes) {
            stepName = 'ST Outcomes';
          }
          break;
        } case eDocumentTabStepIndex.PLANNED_DC: {
          stepName = 'Planned DC';
          break;
        } case eDocumentTabStepIndex.PATIENT_COMPLEXITY: {
          stepName = 'Patient Complexity';
          break;
        } case eDocumentTabStepIndex.PLAN_OF_CARE: {
          stepName = 'Plan of Care';
          break;
        } case eDocumentTabStepIndex.EXTENT_OF_PROGRESS: {
          if (visibleSteps.showExtentOfProgress) {
            stepName = 'Extent of Progress';
          }
          break;
        } case eDocumentTabStepIndex.RECERT_INFO: {
          if (visibleSteps.showRecertInfo) {
            stepName = 'Recert Info';
          }
          break;
        } case eDocumentTabStepIndex.DAILY_NOTES: {
          stepName = 'Daily Notes';
          break;
        } case eDocumentTabStepIndex.DISCHARGE_INFO: {
          stepName = 'DC Info';
          break;
        } case eDocumentTabStepIndex.FINALIZE: {
          if (visibleSteps.showFinalize) {
            stepName = 'Finalize';
          }
          break;
        } case eDocumentTabStepIndex.GOAL_STATEMENT: {
          stepName = 'Goal Statements';
          break;
        } case eDocumentTabStepIndex.LEGACY: {
          if (visibleSteps.showLegacy) {
            stepName = 'JBS Modules';
          }
          break;
        }
      }
      if (stepName) {
        steps.push({
          key: tab,
          value: {
            name: stepName,
            stage: eTmsStepStage.incomplete,
            step: stepIndex,
          }
        });
        stepIndex++;
      }
    });

    return steps;
  }

  getPatientClinicalDocumentTabs(documentType: ePatientClinicalDocumentType, user: User, payer: Payer, discipline: eDiscipline): Observable<any[]> {
    return this.clinicalDocumentQuery.selectActive()
      .pipe(map(clinicalDocument => {
        let tabs = null;
        switch (documentType) {
          case ePatientClinicalDocumentType.EVALUATION:
            tabs = EVALUATION_TABS;
            break;
          case ePatientClinicalDocumentType.RECERT:
            tabs = RECERT_TABS;
            break;
          case ePatientClinicalDocumentType.PROGRESS_NOTE:
            tabs = PROGRESS_NOTE_TABS;
            break;
          case ePatientClinicalDocumentType.DISCHARGE_SUMMARY:
            tabs = DISCHARGE_TABS;
            break;
        };
    
        const visibleSteps = this.getClinicalDocumentVisibleSteps(payer, user);
    
        if (tabs) {
          let tabRoutes = [];
          tabs.forEach(tab => {
            switch (tab) {
              case RouteConstants.PAT_CLINICAL_DOC_EVAL_INFO: {
                tabRoutes.push({
                  text: 'Evaluation Information',
                  route: RouteConstants.PAT_CLINICAL_DOC_EVAL_INFO,
                  completed: this.isClinicalDocumentTabComplete(RouteConstants.PAT_CLINICAL_DOC_EVAL_INFO, discipline, documentType)
                });
                break;
              } case RouteConstants.PAT_CLINICAL_DOC_MODULES: {
                tabRoutes.push({
                  text: 'Modules',
                  route: RouteConstants.PAT_CLINICAL_DOC_MODULES,
                  completed: this.isClinicalDocumentTabComplete(RouteConstants.PAT_CLINICAL_DOC_MODULES, discipline, documentType)
                });
                break;
              } case RouteConstants.PAT_CLINICAL_DOC_OTHER_NOTES: {
                tabRoutes.push({
                  text: 'Other Notes',
                  route: RouteConstants.PAT_CLINICAL_DOC_OTHER_NOTES,
                  completed: this.isClinicalDocumentTabComplete(RouteConstants.PAT_CLINICAL_DOC_OTHER_NOTES, discipline, documentType)
                });
                break;
              } case RouteConstants.PAT_CLINICAL_DOC_OUTCOMES: {
                if (!visibleSteps.showStOutcomes) {
                  tabRoutes.push({
                    text: 'Outcomes',
                    route: RouteConstants.PAT_CLINICAL_DOC_OUTCOMES,
                    completed: this.isClinicalDocumentTabComplete(RouteConstants.PAT_CLINICAL_DOC_OUTCOMES, discipline, documentType)
                  });
                }
                break;
              } case RouteConstants.PAT_CLINICAL_DOC_ST_OUTCOMES: {
                if (visibleSteps.showStOutcomes) {
                  tabRoutes.push({
                    text: 'ST Outcomes',
                    route: RouteConstants.PAT_CLINICAL_DOC_ST_OUTCOMES,
                    completed: this.isClinicalDocumentTabComplete(RouteConstants.PAT_CLINICAL_DOC_ST_OUTCOMES, discipline, documentType)
                  });
                }
                break;
              } case RouteConstants.PAT_CLINICAL_DOC_PLANNED_DC: {
                tabRoutes.push({
                  text: 'Planned DC',
                  route: RouteConstants.PAT_CLINICAL_DOC_PLANNED_DC,
                  completed: this.isClinicalDocumentTabComplete(RouteConstants.PAT_CLINICAL_DOC_PLANNED_DC, discipline, documentType)
                });
                break;
              } case RouteConstants.PAT_CLINICAL_DOC_COMPLEXITY: {
                tabRoutes.push({
                  text: 'Patient Complexity',
                  route: RouteConstants.PAT_CLINICAL_DOC_COMPLEXITY,
                  completed: this.isClinicalDocumentTabComplete(RouteConstants.PAT_CLINICAL_DOC_COMPLEXITY, discipline, documentType)
                });
                break;
              } case RouteConstants.PAT_CLINICAL_DOC_POC: {
                tabRoutes.push({
                  text: 'Plan of Care',
                  route: RouteConstants.PAT_CLINICAL_DOC_POC,
                  completed: this.isClinicalDocumentTabComplete(RouteConstants.PAT_CLINICAL_DOC_POC, discipline, documentType)
                });
                break;
              } case RouteConstants.PAT_CLINICAL_DOC_FINALIZE: {
                tabRoutes.push({
                  text: 'Finalize',
                  route: RouteConstants.PAT_CLINICAL_DOC_FINALIZE,
                  completed: this.isClinicalDocumentTabComplete(RouteConstants.PAT_CLINICAL_DOC_FINALIZE, discipline, documentType)
                });
                break;
              } case RouteConstants.PAT_CLINICAL_DOC_RECERT_INFO: {
                if (visibleSteps.showRecertInfo) {
                  tabRoutes.push({
                    text: 'Recert Info',
                    route: RouteConstants.PAT_CLINICAL_DOC_RECERT_INFO,
                    completed: this.isClinicalDocumentTabComplete(RouteConstants.PAT_CLINICAL_DOC_RECERT_INFO, discipline, documentType)
                  });
                }
                break;
              } case RouteConstants.PAT_CLINICAL_DOC_EOP: {
                if (visibleSteps.showExtentOfProgress) {
                tabRoutes.push({
                    text: 'Extent of Progress',
                    route: RouteConstants.PAT_CLINICAL_DOC_EOP,
                    completed: this.isClinicalDocumentTabComplete(RouteConstants.PAT_CLINICAL_DOC_EOP, discipline, documentType)
                  });
                }
                break;
              } case RouteConstants.PAT_CLINICAL_DOC_DAILY_NOTES: {
                tabRoutes.push({
                  text: 'Daily Notes',
                  route: RouteConstants.PAT_CLINICAL_DOC_DAILY_NOTES,
                  completed: this.isClinicalDocumentTabComplete(RouteConstants.PAT_CLINICAL_DOC_DAILY_NOTES, discipline, documentType)
                });
                break;
              } case RouteConstants.PAT_CLINICAL_DOC_DC_INFO: {
                tabRoutes.push({
                  text: 'DC Info',
                  route: RouteConstants.PAT_CLINICAL_DOC_DC_INFO,
                  completed: this.isClinicalDocumentTabComplete(RouteConstants.PAT_CLINICAL_DOC_DC_INFO, discipline, documentType)
                });
                break;
              } case RouteConstants.PAT_CLINCIAL_DOC_LEGACY: {
                if (visibleSteps.showLegacy) {
                  tabRoutes.push({
                    text: 'JBS Modules',
                    route: RouteConstants.PAT_CLINCIAL_DOC_LEGACY,
                    completed: this.isClinicalDocumentTabComplete(RouteConstants.PAT_CLINCIAL_DOC_LEGACY, discipline, documentType)
                  });
                }
                break;
              } case RouteConstants.PAT_CLINICAL_DOC_WEEKLY_UPDATE: {
                if (visibleSteps.showWeeklyUpdate) {
                  tabRoutes.push({
                    text: 'Weekly Update',
                    route: RouteConstants.PAT_CLINICAL_DOC_WEEKLY_UPDATE,
                    completed: this.isClinicalDocumentTabComplete(RouteConstants.PAT_CLINICAL_DOC_WEEKLY_UPDATE, discipline, documentType)
                  });
                }
                break;
              }
            }
          });
    
          return tabRoutes;
        }
      }))
  }

  private getClinicalDocumentVisibleSteps(payer: Payer, user: User): ClinicalDocumentVisibleSteps {
    const visibleSteps: ClinicalDocumentVisibleSteps = {
      showStOutcomes: false,
      showExtentOfProgress: false,
      showRecertInfo: false,
      showFinalize: false,
      showLegacy: false,
      showWeeklyUpdate: false,
    };
    const activeClinicalDocument = this.clinicalDocumentQuery.getActive();

    if(activeClinicalDocument){
      // show st Outcomes 
      visibleSteps.showStOutcomes = activeClinicalDocument.documentInfo.discipline == eDiscipline.SpeechTherapy && activeClinicalDocument.stOutcomes != null;

      // show recert if user is not an assistant
      visibleSteps.showRecertInfo = this.getShowRecertInfoTab(user);

      // show extent of progress if user is not an assistant or user is an assistant and payer is part A/Skilled
      visibleSteps.showExtentOfProgress = user && (DesignationUtils.isTherapist(user.employee.designation) 
        || (DesignationUtils.isAssistant(user.employee.designation) 
            && (payer.classification == PayerClassification.A || payer.skilledPayerClassification == SkilledPayerClassification.Pdpm || payer.skilledPayerClassification == SkilledPayerClassification.Rug)));

      // always show finalize for therapist. Don't show finalize on recert for an assistant 
      visibleSteps.showFinalize = activeClinicalDocument.clinicalDocumentType != ePatientClinicalDocumentType.RECERT || DesignationUtils.isTherapist(user.employee.designation);

      // show legacy tab and if this is a legacy jbs case
      visibleSteps.showLegacy = activeClinicalDocument.isJbsCase ?? false;

      // show weekly update if property is present 
      visibleSteps.showWeeklyUpdate = activeClinicalDocument.weeklyUpdate != null;
    }
    
    return visibleSteps;
  }

  /* 
   * Return true or false if recert info tab should be shown. 
   */
  public getShowRecertInfoTab(user: User): boolean {
    // show recert into if user is not an assistant
    return DesignationUtils.isTherapist(user.employee.designation);
  }

  public updateWeeklyUpdateAnswer(interviewAnswer: InterviewAnswer): void {
    this.clinicalDocumentStore.updateActive(entity => {
      const weeklyUpdateCopy: InterviewSession = DeepCopyUtils.deepCopyObject(entity.weeklyUpdate);

      const answerIndex = entity.weeklyUpdate.answers.findIndex(answer => answer.page === interviewAnswer.page
        && answer.group === interviewAnswer.group
        && answer.shortName === interviewAnswer.shortName);

      const weeklyUpdatePageIndex = entity.weeklyUpdate.template.pagesList.findIndex(page => page.shortName === interviewAnswer.page);
      const weeklyUpdateGroupIndex = weeklyUpdatePageIndex >= 0 ? entity.weeklyUpdate.template.pagesList[0].groups.findIndex(group => group.shortName === interviewAnswer.group) : -1;
      const weeklyUpdateQuestionIndex = weeklyUpdateGroupIndex >= 0 ? entity.weeklyUpdate.template.pagesList[0].groups[weeklyUpdateGroupIndex].questions.findIndex(question => question.shortName === interviewAnswer.shortName) : -1;

      // update weekly question answer
      if (weeklyUpdatePageIndex >= 0 && weeklyUpdateGroupIndex >= 0 && weeklyUpdateQuestionIndex >= 0) { 
        weeklyUpdateCopy.template.pagesList[weeklyUpdatePageIndex].groups[weeklyUpdateGroupIndex].questions[weeklyUpdateQuestionIndex].answer = DeepCopyUtils.deepCopyObject(interviewAnswer);
      }

      // delete interview answer 
      if (!interviewAnswer.value && (interviewAnswer.values == null || interviewAnswer.values.length === 0)) {
        if (answerIndex >= 0) {
          weeklyUpdateCopy.answers = [
            ...weeklyUpdateCopy.answers.slice(0, answerIndex),
            ...weeklyUpdateCopy.answers.slice(answerIndex + 1),
          ];
        }
      } else {
        // update existing?
        if (answerIndex >= 0) {
          weeklyUpdateCopy.answers = [
              ...weeklyUpdateCopy.answers.slice(0, answerIndex),
              interviewAnswer,
              ...weeklyUpdateCopy.answers.slice(answerIndex + 1),
            ];   
        } else {
          // add answer
          weeklyUpdateCopy.answers = [
            ...weeklyUpdateCopy.answers,
            interviewAnswer,
          ];
        }
      }
      
      return {
        ...entity,
        weeklyUpdate: weeklyUpdateCopy,
      }
    });
  }

  public getModuleMeasurementHistory(patientCaseId: string, moduleShortName: string): Observable<MeasurementsByDocumentDate[]> {
    const getMeasurementCommand: GetMeasurementHistorySummaryCommand = {
      patientCaseId: patientCaseId,
      moduleShortName: moduleShortName,
    };

    return this.http.post<OperationResponse<GetMeasurementHistorySummaryResponse>>(`${this.urlConstantsService.CLINICAL_DOCS_URL}/measurementhistory`, getMeasurementCommand)
      .pipe(map(response => response.data.measurementsByDocumentDate));
  }
}

interface ClinicalDocumentVisibleSteps {
  showStOutcomes: boolean;
  showExtentOfProgress: boolean;
  showRecertInfo: boolean;
  showFinalize: boolean;
  showLegacy: boolean;
  showWeeklyUpdate: boolean;
}




