import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, of, combineLatest, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { ChargesDashboard, ChargeCode, Intervention, PagedResult, Treatment, OperationResponse, InterventionAddRequest, TreatmentItem, TreatmentDailyDetails, ePermissions, ChargeEntry, AddChargeEntry, PatientIntervention, MinuteType, SaveTreatment, Mod59Specifier, TreatmentUpdate, UpdatePatientTreatmentDailyDetailsCommand, UpdatePatientTreatmentDailyDetailsResponse, ValidatePatientTreatmentCommand, ValidatePatientTreatmentResponse, ValidationErrorByCaseId, SavePatientTreatmentResponse, SavePatientTreatmentCommand } from '@app/model';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import * as moment from 'moment';
import { CacheManService } from './cacheman.service';
import { TreatmentsQuery } from '../stores/charges';
import { TreatmentsStore } from '../stores/charges/treatments.store';
import { ChargeCodesService } from './charge-codes.service';
import { EntityDirtyCheckPlugin } from '@datorama/akita';
import { FacilitiesService } from '..';
import { FunctionalStatusAnswer } from '@app/model/functional-statuses/FunctionalStatusAnswer';

@Injectable({
	providedIn: 'root',
})
export class ChargesService {
    private _activeChargesDashboard: ChargesDashboard = null;
    private _activeChargesDashboard$: BehaviorSubject<ChargesDashboard> = new BehaviorSubject<ChargesDashboard>(null);

    private _activeChargesDate: Date = null;
    private _activeChargesDate$: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);

    private _activeTreatmentsUpdated$: BehaviorSubject<Treatment[]> = new BehaviorSubject<Treatment[]>(null);

    private _dirtyCheckCollection: EntityDirtyCheckPlugin;

    private _treatmentItems$: BehaviorSubject<TreatmentItem[]> = new BehaviorSubject<TreatmentItem[]>(null);

    constructor(private cacheManService: CacheManService,
        private chargeCodesService: ChargeCodesService,
        private facilitiesService: FacilitiesService,
        private http: HttpClient,
        private treatmentsQuery: TreatmentsQuery,
        private treatmentsStore: TreatmentsStore,
        private urlConstantsService: UrlConstantsService) { }

    // Active Treatments
    get activeChargesDashboardTreatments$(): Observable<Treatment[]> {
        //return this.treatmentsQuery.selectAll();
        return this._activeTreatmentsUpdated$.asObservable();
    }

    // Active Charges Dashboard
    get activeChargesDashboard(): ChargesDashboard {
        return this._activeChargesDashboard;
    }

    get activeChargesDashboard$(): Observable<ChargesDashboard> {
        return this._activeChargesDashboard$.asObservable();

    }

    private set activeChargesDashboard(dashboard: ChargesDashboard) {
        this._activeChargesDashboard = dashboard;
        this._activeChargesDashboard$.next(this._activeChargesDashboard);
    }

    clearActiveChargesDashboard() {
        this.treatmentsStore.reset();
        //this._activeChargesDashboard = null;
        //this._activeChargesDashboard$.next(this._activeChargesDashboard);
    }

    // Active Charges Date
    get activeChargesDate(): Date {
        return this._activeChargesDate;
    }

    get activeChargesDate$(): Observable<Date> {
        return this._activeChargesDate$.asObservable();
    }

    set activeChargesDate(activeDate: Date) {
        this._activeChargesDate = activeDate;
        this._activeChargesDate$.next(this._activeChargesDate);
    }

    // Active Treatment 
    activeTreatment$(): Observable<Treatment>;
    activeTreatment$<T>(property?: string): Observable<T>;
    activeTreatment$<T>(property?: string): Observable<Treatment> | Observable<T> {
        if (property) {
            return this.treatmentsQuery.selectActive(entity => entity[property]);
        } else {
            return this.treatmentsQuery.selectActive();
        }
    }

    get chargeEntries$(): Observable<ChargeEntry[]> {
        return this._treatmentItems$.pipe(map(treatmentItems => this.flattenTreatmentItems(treatmentItems)));
    }

    getTreatmentByCaseId$(patientCaseId: string): Observable<Treatment> {
        return this.treatmentsQuery.selectEntity(patientCaseId);
    }
    
    getIsActiveEntityDirty(): boolean {
        if (!this._dirtyCheckCollection) {
            return false;
        }
        return this._dirtyCheckCollection.someDirty();
    }

    resetActiveEntity(): void {
        this._dirtyCheckCollection.reset();
    }

    resetChargesStore(): void {
        this.treatmentsStore.reset();
    }
 
    // Facility Charges
    getFacilityChargesDashboard(facilityId: string, chargesDate: Date, showAllPatients: boolean = false): Observable<ChargesDashboard> {
        chargesDate = moment(chargesDate).toDate();
        const day = chargesDate.getDate();
        const month = (chargesDate.getMonth() + 1);
        const year = chargesDate.getFullYear();

        return this.cacheManService.getCurrentUser()
                .pipe(switchMap(user => {
                    const userId = user.id;
                    const disciplines = user.employee.discipline;
                    const showAllDisciplines = (user.permissions.findIndex(p => p === ePermissions.CanViewChargesForAllDisciplines) > -1 ? true : false);
                    return this.http.get<OperationResponse<ChargesDashboard>>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/charges?day=${day}&month=${month}&year=${year}&disciplines=${disciplines}&userId=${userId}&showAllPatients=${showAllPatients}&showAllDisciplines=${showAllDisciplines}`)
                }), map(response => response.data),
                    tap(chargesDashboard => {
                        this.activeChargesDashboard = chargesDashboard;
                    }));

        
    }

    getFacilityPatientChargesDashboard(facilityId: string, chargesDate: Date, showAllPatients: boolean = false): Observable<ChargesDashboard> {
        if (this.treatmentsQuery.getCount() > 0) {
            this.clearActiveChargesDashboard();
        }

        chargesDate = moment(chargesDate).toDate();
        const day = chargesDate.getDate();
        const month = (chargesDate.getMonth() + 1);
        const year = chargesDate.getFullYear();

        return this.cacheManService.getCurrentUser()
                .pipe(switchMap(user => {
                    const userId = user.id;
                    const disciplines = user.employee.discipline;
                    const showAllDisciplines = (user.permissions.findIndex(p => p === ePermissions.CanViewChargesForAllDisciplines) > -1 ? true : false);
                    return this.http.get<OperationResponse<ChargesDashboard>>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/charges/treatments?day=${day}&month=${month}&year=${year}&disciplines=${disciplines}&userId=${userId}&showAllPatients=${showAllPatients}&showAllDisciplines=${showAllDisciplines}`)
                }), map(response => response.data),
                    tap(chargesDashboard => {
                        this._activeTreatmentsUpdated$.next(chargesDashboard.treatments);
                        this.treatmentsStore.upsertMany(chargesDashboard.treatments);
                        this._dirtyCheckCollection = new EntityDirtyCheckPlugin(this.treatmentsQuery);
                        this._dirtyCheckCollection.setHead();
                    }));

        
    }

    getPatientTreatment(facilityId: string, chargesDate: Date, caseId: string) {
        chargesDate = moment(chargesDate).toDate();
        const day = chargesDate.getDate();
        const month = (chargesDate.getMonth() + 1);
        const year = chargesDate.getFullYear();

        return this.http.get<OperationResponse<Treatment>>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/charges/${caseId}?day=${day}&month=${month}&year=${year}`)
            .pipe(map(response => response.data),
                  tap(treatment => {
                      this.treatmentsStore.upsert(treatment.patientCaseId, treatment);
                      this.treatmentsStore.setActive(treatment.patientCaseId);
                      this._dirtyCheckCollection = new EntityDirtyCheckPlugin(this.treatmentsQuery);
                      this._dirtyCheckCollection.setHead();
                      this._treatmentItems$.next(this.treatmentsQuery.getActive().treatmentItems);
                  }));
    }

    getValidChargeCodes(facilityId: string, caseId: string, chargesDate: Date) {
        chargesDate = moment(chargesDate).toDate();
        const day = chargesDate.getDate();
        const month = (chargesDate.getMonth() + 1);
        const year = chargesDate.getFullYear();
        const treatmentItems = this.treatmentsQuery.getActive().treatmentItems;
        return this.http.put<OperationResponse<PagedResult<ChargeCode>>>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/charges/${caseId}/validchargecodes?day=${day}&month=${month}&year=${year}`, treatmentItems)
                .pipe(map(chargeCodePagedResult => chargeCodePagedResult.data));
    }

    getValidInterventions(facilityId: string, caseId: string, cptCode: string) {
        return this.http.get<PagedResult<Intervention>>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/charges/${caseId}/validinterventions?cptCode=${cptCode}`)
                .pipe(map(interventionsPagedResult => interventionsPagedResult.items));
    }

    addIntervention(facilityId: string, caseId: string, cptCode: string, interventionShortName: string, chargesDate: Date) {
        chargesDate = moment(chargesDate).toDate();
        const day = chargesDate.getDate();
        const month = (chargesDate.getMonth() + 1);
        const year = chargesDate.getFullYear();
        return this.http.get<InterventionAddRequest>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/charges/${caseId}/addIntervention?cptCode=${cptCode}&interventionShortName=${interventionShortName}&day=${day}&month=${month}&year=${year}`);
    }

    createTreatmentItem(facilityId: string, caseId: string, treatmentItem: TreatmentItem, chargesDate: Date) {
        chargesDate = moment(chargesDate).toDate();
        const day = chargesDate.getDate();
        const month = (chargesDate.getMonth() + 1);
        const year = chargesDate.getFullYear();

        return this.http.post(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/charges/${caseId}?day=${day}&month=${month}&year=${year}`, treatmentItem);
    }

    updateTreatmentItem(facilityId: string, caseId: string, treatmentItem: TreatmentItem, chargesDate: Date) {
        chargesDate = moment(chargesDate).toDate();
        const day = chargesDate.getDate();
        const month = (chargesDate.getMonth() + 1);
        const year = chargesDate.getFullYear();

        return this.http.put(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/charges/${caseId}?day=${day}&month=${month}&year=${year}`, treatmentItem);
    }

    saveTreatmentItems(facilityId: string, caseId: string, chargesDate: Date, pinCode: string): Observable<Treatment> {
        chargesDate = moment(chargesDate).toDate();
        const day = chargesDate.getDate();
        const month = (chargesDate.getMonth() + 1);
        const year = chargesDate.getFullYear();

        const saveTreatment: SaveTreatment = {
            treatmentItems: this.treatmentsQuery.getActive().treatmentItems,
            pinCode: pinCode,
        };
        return this.http.put<OperationResponse<Treatment>>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/charges/${caseId}/alltreatments?day=${day}&month=${month}&year=${year}`, saveTreatment)
            .pipe(map(response => response.data), 
                  tap(treatment => {
                      this.treatmentsStore.upsert(treatment.patientCaseId, treatment);
                      this._dirtyCheckCollection.setHead();
                      this._treatmentItems$.next(this.treatmentsQuery.getActive().treatmentItems);

                      this.updatePatientPayerNames(caseId, treatment);
                  }));
    }

    validateTreatmentItems(facilityId: string, caseId: string, chargesDate: Date): Observable<TreatmentItem[]> {
        chargesDate = moment(chargesDate).toDate();
        const day = chargesDate.getDate();
        const month = (chargesDate.getMonth() + 1);
        const year = chargesDate.getFullYear();

        const treatmentItems = this.treatmentsQuery.getActive().treatmentItems;
        return this.http.put<OperationResponse<TreatmentItem[]>>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/charges/${caseId}/validate?day=${day}&month=${month}&year=${year}`, treatmentItems)
            .pipe(map(response => response.data),
                  tap(treatmentItems => {
                      this.treatmentsStore.updateActive(entity => {
                          return {
                              ...entity,
                              treatmentItems: treatmentItems,
                          }
                      })
                  }));
    }

    validateTreatments(): Observable<Treatment[]> {
        const chargesDate = moment(this.activeChargesDate).toDate();
        const day = chargesDate.getDate();
        const month = (chargesDate.getMonth() + 1);
        const year = chargesDate.getFullYear();

        return this.facilitiesService.activeFacilityId$
            .pipe(take(1), switchMap(facilityId => {
                const dirtyTreatments = this.getDirtyTreatments();

                const validateTreatments: ValidatePatientTreatmentCommand = {
                    month: month,
                    day: day,
                    year: year,
                    treatments: dirtyTreatments
                }
                return this.http.put<OperationResponse<ValidatePatientTreatmentResponse>>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/charges/validatetreatments`, validateTreatments)
                    .pipe(catchError(error => {
                            const validationResponse = error.error.data as ValidatePatientTreatmentResponse;
                            validationResponse.treatments.forEach(treatment => {
                                if (treatment.validationErrors != null && treatment.validationErrors.length > 0) {
                                    this.treatmentsStore.update(treatment.patientCaseId, entity => {
                                        return {
                                            ...entity,
                                            validationErrors: treatment.validationErrors
                                        }
                                    })
                                }
                            });
                            return throwError(this.treatmentsQuery.getAll());
                          }),
                          map(response => response.data),
                          map(validationResponse => {
                            validationResponse.treatments.forEach(treatment => {
                                this.treatmentsStore.update(treatment.patientCaseId, entity => {
                                    return {
                                        ...entity,
                                        treatmentItems: treatment.treatmentItems,
                                        validationErrors: treatment.validationErrors,
                                    }
                                });
                            });
                            return this.treatmentsQuery.getAll();
                          }));
            }));
    }

    saveTreatments(pinCode: string): Observable<SavePatientTreatmentResponse> {
        const chargesDate = moment(this.activeChargesDate).toDate();
        const day = chargesDate.getDate();
        const month = (chargesDate.getMonth() + 1);
        const year = chargesDate.getFullYear();

        return this.facilitiesService.activeFacilityId$
            .pipe(switchMap(facilityId => {
                const dirtyTreatments = this.getDirtyTreatments(); 

                const saveTreatments: SavePatientTreatmentCommand = {
                    month: month,
                    day: day,
                    year: year,
                    treatments: dirtyTreatments,
                    pinCode: pinCode,
                };
                return this.http.put<OperationResponse<SavePatientTreatmentResponse>>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/charges/treatments`, saveTreatments)
                    .pipe(catchError(error => {
                            return throwError(error);
                          }),
                          map(response => response.data),
                          tap(saveResponse => {
                            this.treatmentsStore.upsertMany(saveResponse.treatments);
                            this._activeTreatmentsUpdated$.next(this.treatmentsQuery.getAll());
                            this._dirtyCheckCollection.setHead();
                          }));
            }));
    }

    private getDirtyTreatments(): Treatment[] {
        const treatments = this.treatmentsQuery.getAll();
        const dirtyTreatments = treatments.filter(treatment => this._dirtyCheckCollection.isDirty(treatment.patientCaseId, false));

        return dirtyTreatments;
    }

    deleteTreatmentItem(facilityId: string, caseId: string, treatmentItemId: string, interventionShortName: string): Observable<Treatment> {
        return this.http.delete<OperationResponse<Treatment>>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/charges/${caseId}/treatmentitems/${treatmentItemId}/${interventionShortName}`)
            .pipe(map(response => response.data));
    }

    private updatePatientPayerNames(caseId: string, treatment: Treatment) {
        const patientIndex = this._activeChargesDashboard.patients.findIndex(p => p.caseId === caseId);
        if (patientIndex >= 0) {
          this.activeChargesDashboard = {
              ...this._activeChargesDashboard,
              patients: [
                  ...this._activeChargesDashboard.patients.slice(0, patientIndex),
                  {
                      ...this._activeChargesDashboard.patients[patientIndex],
                      visitsRemaining: treatment.dailyDetails.visitsRemaining,
                  },
                  ...this._activeChargesDashboard.patients.slice(patientIndex + 1),
              ]
          }
        }
    }

    // Daily Details
    updateDailyDetails(facilityId: string, caseId: string, dailyDetails: TreatmentDailyDetails, chargesDate: Date) {
        chargesDate = moment(chargesDate).toDate();
        const day = chargesDate.getDate();
        const month = (chargesDate.getMonth() + 1);
        const year = chargesDate.getFullYear();

        return this.http.put<OperationResponse<Treatment>>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/charges/${caseId}/dailydetails?day=${day}&month=${month}&year=${year}`, dailyDetails);
    }

    updateTreatmentDailyDetails(facilityId: string, caseId: string, treatmentId: string, dailyDetails: TreatmentDailyDetails, chargesDate: Date) {
        chargesDate = moment(chargesDate).toDate();
        const day = chargesDate.getDate();
        const month = (chargesDate.getMonth() + 1);
        const year = chargesDate.getFullYear();

        const command: UpdatePatientTreatmentDailyDetailsCommand = {
            caseId: caseId,
            treatmentId: treatmentId,
            month: month,
            day: day,
            year: year,
            dailyDetailsDto: dailyDetails,
        };
        return this.http.put<OperationResponse<UpdatePatientTreatmentDailyDetailsResponse>>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/charges/treatmentdailydetails`, command);
    }

    // charge re-work store operations
    checkIfConcurrenMinutesAreValid(patientCaseId: string, concurrentMins: number): boolean {
        const treatment = this.treatmentsQuery.getEntity(patientCaseId);

        // only add mins for codes that are not group or eval
        const totalMinsMinusGroup = treatment?.treatmentItems.filter(treatmentItem => !treatmentItem.chargeCodeObj.isGroup && !treatmentItem.chargeCodeObj.isEvaluationCode)
                                                             .reduce((prev, curr) => curr.minutes ? prev + curr.minutes : prev, 0);

        return concurrentMins <= totalMinsMinusGroup;
    }

    checkIfMinMinutesAreValid(minMins: number, mins: number): boolean {
        return minMins <= mins;
    }

    private getTreatmentItemIndex(treatment: Treatment, chargeCode: string): number {
        const index = treatment.treatmentItems.findIndex(treatmentItem => treatmentItem.chargeCode === chargeCode);

        if (index < 0) {
            console.error('Treatment Item - ChargeCode: ' + chargeCode + 'does not exist');
        }
        return index;
    }

    updateTreatmentItemMinutes(caseId: string, chargeCode: string, minutes: number) {
        this.treatmentsStore.update(caseId, entity => {
            const treatmentItemIndex = entity.treatmentItems.findIndex(treatmentItem => treatmentItem.chargeCode === chargeCode);

            if (treatmentItemIndex < 0) {
                console.error('Treatment Item - ChargeCode: ' + chargeCode + 'does not exist');
                return entity;
            } else {
                return {
                    ...entity,
                    treatmentItems: [
                        ...entity.treatmentItems.slice(0, treatmentItemIndex),
                        {
                            ...entity.treatmentItems[treatmentItemIndex],
                            //isEdited: true,
                            minutes: minutes,
                            groupDeliveryStatement: minutes <= 0 ? null : entity.treatmentItems[treatmentItemIndex].groupDeliveryStatement, // update group statement if needed
                        },
                        ...entity.treatmentItems.slice(treatmentItemIndex + 1),
                    ],
                }
            }
        });
    }

    updateTreatmentItemMod52(caseId: string, chargeCode: string, isMod52: boolean) {
        this.treatmentsStore.update(caseId, entity => {
            const treatmentItemIndex = entity.treatmentItems.findIndex(treatmentItem => treatmentItem.chargeCode === chargeCode);

            if (treatmentItemIndex < 0) {
                console.error('Treatment Item - ChargeCode: ' + chargeCode + 'does not exist');
                return entity;
            } else {
                return {
                    ...entity,
                    treatmentItems: [
                        ...entity.treatmentItems.slice(0, treatmentItemIndex),
                        {
                            ...entity.treatmentItems[treatmentItemIndex],
                            isMod52: isMod52
                        },
                        ...entity.treatmentItems.slice(treatmentItemIndex + 1),
                    ],
                }
            }
        });
    }

    updateTreatmentItemSelectedInterventionNotes(caseId: string, chargeCode: string, selectedInterventionNoteShortNames: string[]): string {
        this.treatmentsStore.update(caseId, entity => {
            const treatmentItemIndex = entity.treatmentItems.findIndex(treatmentItem => treatmentItem.chargeCode === chargeCode);

            if (treatmentItemIndex < 0) {
                console.error('Treatment Item - ChargeCode: ' + chargeCode + 'does not exist');
                return entity;
            } else {
                return {
                    ...entity,
                    treatmentItems: [
                        ...entity.treatmentItems.slice(0, treatmentItemIndex),
                        {
                            ...entity.treatmentItems[treatmentItemIndex],
                            interventionShortNamesUsed: selectedInterventionNoteShortNames,
                        },
                        ...entity.treatmentItems.slice(treatmentItemIndex + 1),
                    ],
                }
            }
        });

        // update generated intervention note 
        const treatment = this.treatmentsQuery.getEntity(caseId);
        const treatmentItem = treatment.treatmentItems.find(treatmentItem => treatmentItem.chargeCode === chargeCode);
        if (treatmentItem == null) {
            console.error('Treatment Item - ChargeCode: ' + chargeCode + 'does not exist');
            return; 
        }

        const interventionNote = selectedInterventionNoteShortNames.map(noteShortName => treatmentItem.interventionOptions.find(option => option.interventionNoteShortName === noteShortName)?.statement).join(' ');
        this.treatmentsStore.update(caseId, entity => {
            const treatmentItemIndex = entity.treatmentItems.findIndex(treatmentItem => treatmentItem.chargeCode === chargeCode);

            if (treatmentItemIndex < 0) {
                console.error('Treatment Item - ChargeCode: ' + chargeCode + 'does not exist');
                return entity;
            } else {
                return {
                    ...entity,
                    treatmentItems: [
                        ...entity.treatmentItems.slice(0, treatmentItemIndex),
                        {
                            ...entity.treatmentItems[treatmentItemIndex],
                            interventionNote: interventionNote,
                        },
                        ...entity.treatmentItems.slice(treatmentItemIndex + 1),
                    ],
                }
            }
        });

        return interventionNote;
    }

    updateTreatmentItemInterventionNote(caseId: string, chargeCode: string, interventionNote: string) {
        this.treatmentsStore.update(caseId, entity => {
            const treatmentItemIndex = this.getTreatmentItemIndex(entity, chargeCode);

            if (treatmentItemIndex < 0) {
                return entity;
            } else {
                return {
                    ...entity,
                    treatmentItems: [
                        ...entity.treatmentItems.slice(0, treatmentItemIndex),
                        {
                            ...entity.treatmentItems[treatmentItemIndex],
                            interventionNote: interventionNote,
                        },
                        ...entity.treatmentItems.slice(treatmentItemIndex + 1),
                    ],
                }
            }
        });
    }

    updateTreatmentConcurrentMinutes(caseId: string, concurrentMinutes: number) {
        this.treatmentsStore.update(caseId, entity => {
            return {
                ...entity,
                dailyDetails: {
                    ...entity.dailyDetails,
                    concurrentMinutes: concurrentMinutes != null ? concurrentMinutes : 0,
                }
            }
        });
    }

    updateTreatmentFunctionalStatusAnswers(caseId: string, functionalStatusAnswers: FunctionalStatusAnswer[]) {
        this.treatmentsStore.update(caseId, entity => {
            return {
                ...entity,
                functionalStatusAnswers: functionalStatusAnswers
            }
        });
    }

    updateTreatmentConcurrentStatement(caseId: string, concurrenStatement: string, concurrentDeliveryStatementSubstrings: string[]) {
        this.treatmentsStore.update(caseId, entity => {
            return {
                ...entity,
                dailyDetails: {
                    ...entity.dailyDetails,
                    concurrentDeliveryStatement: concurrenStatement,
                    concurrentDeliveryStatementSubstrings: concurrentDeliveryStatementSubstrings
                }
            };
        });
    }

    updateTreatmentGroupStatement(caseId: string, chargeCode: string, groupStatement: string, groupStatementSubstrings: string[]) {
        this.treatmentsStore.update(caseId, entity => {
            const treatmentItemIndex = this.getTreatmentItemIndex(entity, chargeCode)

            if (treatmentItemIndex < 0) {
                return entity;
            } else {
                return {
                    ...entity,
                    treatmentItems: [
                        ...entity.treatmentItems.slice(0, treatmentItemIndex),
                        {
                            ...entity.treatmentItems[treatmentItemIndex],
                            isEdited: true,
                            groupDeliveryStatement: groupStatement,
                            groupDeliveryStatementSubstrings: groupStatementSubstrings
                        },
                        ...entity.treatmentItems.slice(treatmentItemIndex + 1),
                    ],
                }
            }
        });   
    }

    private updateTreatmentCaregiverTrainingStatement(caseId: string, chargeCode: string, statement: string, caregiverTrainingStatementKey: string, statementSubstrings: string[]) : void {
        if (caregiverTrainingStatementKey === 'caregiverTrainingNonGroupStatement' || caregiverTrainingStatementKey  === 'caregiverTrainingGroupStatement') {
            this.treatmentsStore.update(caseId, entity => {
                const treatmentItemIndex = this.getTreatmentItemIndex(entity, chargeCode)

                if (treatmentItemIndex < 0) {
                    return entity;
                } else {
                    return {
                        ...entity,
                        treatmentItems: [
                            ...entity.treatmentItems.slice(0, treatmentItemIndex),
                            {
                                ...entity.treatmentItems[treatmentItemIndex],
                                isEdited: true,
                                statementSubstrings: statementSubstrings,
                                [caregiverTrainingStatementKey]: statement,
                            },
                            ...entity.treatmentItems.slice(treatmentItemIndex + 1),
                        ],
                    }
                }
            });   
        }
    }

    updateTreatmentCaregiverTrainingGroupStatement(caseId: string, chargeCode: string, caregiverTrainingGroupStatement: string, statementSubstrings: string[]) : void {
        this.updateTreatmentCaregiverTrainingStatement(caseId, chargeCode, caregiverTrainingGroupStatement, 'caregiverTrainingGroupStatement', statementSubstrings)
    }

    updateTreatmentCaregiverTrainingNonGroupStatement(caseId: string, chargeCode: string, caregiverTrainingNonGroupStatement: string, statementSubstrings: string[]) : void {
        this.updateTreatmentCaregiverTrainingStatement(caseId, chargeCode, caregiverTrainingNonGroupStatement, 'caregiverTrainingNonGroupStatement', statementSubstrings)
    }

    updateTreatmentItemModalityStatement(caseId: string, chargeCode: string, modalityStatement: string, statementSubstrings:string[]) {
        this.treatmentsStore.update(caseId, entity => {
            const treatmentItemIndex = entity.treatmentItems.findIndex(treatmentItem => treatmentItem.chargeCode === chargeCode);

            if (treatmentItemIndex < 0) {
                console.error('Error updating modality statement. Treatment Item with charge code: ' + chargeCode + ' does not exist');
                return entity;
            } else {
                return {
                    ...entity,
                    treatmentItems: [
                        ...entity.treatmentItems.slice(0, treatmentItemIndex),
                        {
                            ...entity.treatmentItems[treatmentItemIndex],
                            statementSubstrings: statementSubstrings,
                            isEdited: true,
                            modalityStatement: modalityStatement,
                        },
                        ...entity.treatmentItems.slice(treatmentItemIndex + 1),
                    ],
                }
            }
        });
    }

    updateTreatmentItemTelehealthFlag(caseId: string, isTelehealth: boolean) {
        this.treatmentsStore.update(caseId, entity => {
            return {
                ...entity,
                dailyDetails: {
                    ...entity.dailyDetails,
                    isTelehealth: isTelehealth,
                }
            }
        });
    }

    updateTreatmentTelehealthStatement(caseId: string, telehealthStatement: string, telehealthStatementSubstrings: string[]) {
        this.treatmentsStore.update(caseId, entity => {
            return {
                ...entity,
                dailyDetails: {
                    ...entity.dailyDetails,
                    telehealthStatement: telehealthStatement,
                    telehealthStatementSubstrings: telehealthStatementSubstrings
                }
            }
        })
    }


    // TODO: ensure that new fields are being returned after updating
    updateTreatmentActiveParticipationFlag(caseId: string, value: boolean): void {
        combineLatest([this.treatmentsQuery.selectEntity(caseId), this.facilitiesService.activeFacilityId$])
            .pipe(take(1), switchMap(([treatment, activeFacilityId]) => {
                    return this.updateTreatmentDailyDetails(activeFacilityId, caseId, treatment.id, { ...treatment.dailyDetails, isActiveParticipation: value}, this.activeChargesDate)
                }))
            .subscribe(response => {
                const treatment = response.data;
                 this.treatmentsStore.update(caseId, entity => {
                    return {
                        ...entity,
                        isActiveParticipationVisitNeeded: response.data.isActiveParticipationVisitNeeded,
                        dailyDetails: response.data.treatmentDailyDetails
                    }
                });
            });
    }

    updateTreatmentMissedVisitType(caseId: string, missedVisitType: string): void {
        this.treatmentsStore.update(caseId, entity => {
                return {
                    ...entity,
                    dailyDetails: {
                        ...entity.dailyDetails,
                        missedVisitReason: missedVisitType,
                    }
                }
            });

        if (missedVisitType == null) {
            this.treatmentsStore.update(caseId, entity => {
                return {
                    ...entity,
                    dailyDetails: {
                        ...entity.dailyDetails,
                        missedVisitReasonNote: null,
                    }
                }
            });
        }
    }

    updateTreatmentMissedVisitNote(caseId: string, missedVisitNote: string): void {
        this.treatmentsStore.update(caseId, entity => {
            return {
                ...entity,
                dailyDetails: {
                    ...entity.dailyDetails,
                    missedVisitReasonNote: missedVisitNote,
                }
            }
        });
    }

    updateTreatmentSupervisionFlag(caseId: string, isSupervision: boolean): void {
        this.treatmentsStore.update(caseId, entity => {
            return {
                ...entity,
                dailyDetails: {
                    ...entity.dailyDetails,
                    isSupervision: isSupervision,
                }
            }
        });
    }

    updateTreatmentSupervisionNote(caseId: string, supervisionNote: string): void {
        this.treatmentsStore.update(caseId, entity => {
            return {
                ...entity,
                dailyDetails: {
                    ...entity.dailyDetails,
                    supervisionNote: supervisionNote,
                }
            }
        });
    }

    updateTreatmentStudentNote(caseId: string, studentNote: string): void {
        this.treatmentsStore.update(caseId, entity => {
            return {
                ...entity,
                dailyDetails: {
                    ...entity.dailyDetails,
                    studentNote: studentNote,
                }
            }
        });
    }

    updateTreatmentStudentFlag(caseId: string, isStudent: boolean): void {
        this.treatmentsStore.update(caseId, entity => {
            return {
                ...entity,
                dailyDetails: {
                    ...entity.dailyDetails,
                    isStudent: isStudent,
                }
            }
        });
    }

    updateTreatmentIsolatedFlag(caseId: string, isIsolated: boolean): void {
        this.treatmentsStore.update(caseId, entity => {
            return {
                ...entity,
                dailyDetails: {
                    ...entity.dailyDetails,
                    isIsolated: isIsolated,
                }
            }
        });
    }

    updateTreatmentNotes(caseId: string, notes: string): void {
        this.treatmentsStore.update(caseId, entity => {
            return {
                ...entity,
                dailyDetails: {
                    ...entity.dailyDetails,
                    notes: notes
                }
            }
        });
    }
    
    updateTreatmentItemMod59Specifier(patientCaseId: string, treatmentItemId: string, mod59TriggerId: string,  mod59Specifier: Mod59Specifier) {
        this.treatmentsStore.update(patientCaseId, entity => {
            const treatmentItemIndex = entity.treatmentItems.findIndex(treatmentItem => treatmentItem.id === treatmentItemId);

            if (treatmentItemIndex >= 0) {
                const mod59TriggerIndex = entity.treatmentItems[treatmentItemIndex].triggeredMod59s.findIndex(mod59 => mod59.id === mod59TriggerId);
                if (mod59TriggerIndex >= 0) {
                    return {
                        ...entity,
                        treatmentItems: [
                            ...entity.treatmentItems.slice(0, treatmentItemIndex),
                            {
                                ...entity.treatmentItems[treatmentItemIndex],
                                triggeredMod59s: [
                                    ...entity.treatmentItems[treatmentItemIndex].triggeredMod59s.slice(0, mod59TriggerIndex),
                                    {
                                        ...entity.treatmentItems[treatmentItemIndex].triggeredMod59s[mod59TriggerIndex],
                                        specifier: mod59Specifier,
                                    },
                                    ...entity.treatmentItems[treatmentItemIndex].triggeredMod59s.slice(mod59TriggerIndex + 1),
                                ]
                            },
                            ...entity.treatmentItems.slice(treatmentItemIndex + 1)
                        ]
                    }
                } else {
                    console.error('updateTreatmentItemMod59Specifier: mod59triggeredId: ' + mod59TriggerId + ' was not found');
                }
            } else {
                console.error('updateTreatmentItemMod59Specifier: treatmentItemId: ' + treatmentItemId + ' was not found');
            }
        });
    }

    // store operations
    deleteChargeEntry(facilityId: string, caseId: string, chargeEntry: ChargeEntry): Observable<void> {
        return this.deleteTreatmentItem(facilityId, caseId, chargeEntry.id, chargeEntry.interventionShortName)
            .pipe(tap(response => {
                if (response) {
                    // update store
                    this.treatmentsStore.upsert(caseId, response);
                } else {
                    // treatment items were not present on server. delete from store          
                    // find treatmentItem
                    const treatmentItemIndex = this.treatmentsQuery.getActive().treatmentItems.findIndex(ti => ti.chargeCode === chargeEntry.chargeCode);

                    if (treatmentItemIndex >= 0) {
                        const treatmentItem = this.treatmentsQuery.getActive().treatmentItems[treatmentItemIndex];
                        // find intervention
                        const interventionIndex = treatmentItem.interventions.findIndex(i => i.shortName === chargeEntry.interventionShortName);
                        // if this is the last intervention in treatment item, then delete the treatment item
                        if (treatmentItem.interventions.length === 1 && treatmentItem.interventions[0].shortName === chargeEntry.interventionShortName) {
                            this.treatmentsStore.updateActive(entity => {
                                return {
                                    ...entity,
                                    treatmentItems: [
                                        ...entity.treatmentItems.slice(0, treatmentItemIndex),
                                        ...entity.treatmentItems.slice(treatmentItemIndex + 1),
                                    ]
                                }
                            });
                        } else {
                            // only remove intervention
                            this.treatmentsStore.updateActive(entity => { 
                                return {
                                    ...entity,
                                    treatmentItems: [
                                        ...entity.treatmentItems.slice(0, treatmentItemIndex),
                                        {
                                            ...entity.treatmentItems[treatmentItemIndex],
                                            interventions: [
                                                ...treatmentItem.interventions.slice(0, interventionIndex),
                                                ...treatmentItem.interventions.slice(interventionIndex + 1),
                                            ]
                                        },
                                        ...entity.treatmentItems.slice(treatmentItemIndex + 1),
                                    ]
                                }
                            });
                        }
                    }
                }
                this._treatmentItems$.next(this.treatmentsQuery.getActive().treatmentItems);
                this.updatePatientPayerNames(caseId, response);
            }), map(() => {}));
    }

    // updateActiveParticipationFlag(facilityId: string, caseId: string, treatmentDate: Date, value: boolean): Observable<Treatment> {
    //     const dailyDetails: TreatmentDailyDetails = { 
    //         ...this.treatmentsQuery.getActive().options,
    //         isActiveParticipation: value, 
    //     };
    //     return this.updateDailyDetails(facilityId, caseId, dailyDetails, treatmentDate)
    //         .pipe(map(response => response.data),
    //               tap(treatment => {
    //                 this.updatePatientPayerNames(caseId, treatment);
    //                 this.treatmentsStore.updateActive(entity => {
    //                   return {
    //                       ...entity,
    //                       options: treatment.options,
    //                   }
    //                 });
    //                 this._dirtyCheckCollection.setHead();
    //             }));
    // }

    // updateSupervisionFlag(facilityId: string, caseId: string, treatmentDate: Date, value: boolean): Observable<Treatment> {
    //     const dailyDetails: TreatmentDailyDetails = { 
    //         ...this.treatmentsQuery.getActive().options,
    //         isSupervision: value, 
    //     };
    //     return this.updateDailyDetails(facilityId, caseId, dailyDetails, treatmentDate)
    //         .pipe(map(response => response.data),
    //               tap(treatment => { 
    //                 this.treatmentsStore.updateActive(entity => {
    //                   return {
    //                       ...entity,
    //                       options: treatment.options,
    //                   }
    //               });
    //               this._dirtyCheckCollection.setHead();
    //             }));
    // }

    // addSupervisionNote(facilityId: string, caseId: string, treatmentDate: Date, supervisionNote: string): Observable<Treatment> {
    //     const dailyDetails: TreatmentDailyDetails = { 
    //         ...this.treatmentsQuery.getActive().options,
    //         supervisionNote: supervisionNote, 
    //     };
    //     return this.updateDailyDetails(facilityId, caseId, dailyDetails, treatmentDate)
    //         .pipe(map(response => response.data),
    //               tap(treatment => {
    //                 this.treatmentsStore.updateActive(entity => {
    //                   return {
    //                       ...entity,
    //                       options: treatment.options,
    //                   }
    //               });
    //               this._dirtyCheckCollection.setHead();
    //             }));
    // }

    // addMissedVisitNote(facilityId: string, caseId: string, treatmentDate: Date, missedVisitReason: string, missedVisitReasonNote: string): Observable<Treatment> {
    //     const dailyDetails: TreatmentDailyDetails = { 
    //         ...this.treatmentsQuery.getActive().options,
    //         missedVisitReason: missedVisitReason,
    //         missedVisitReasonNote: missedVisitReasonNote, 
    //     };
    //     return this.updateDailyDetails(facilityId, caseId, dailyDetails, treatmentDate)
    //         .pipe(map(response => response.data),
    //               tap(treatment => {
    //                 this.treatmentsStore.updateActive(entity => {
    //                   return {
    //                       ...entity,
    //                       options: treatment.options,
    //                   }
    //               });
    //               this._dirtyCheckCollection.setHead();
    //             }));
    // }

    // deleteMissedVisitNote(facilityId: string, caseId: string, treatmentDate: Date): Observable<Treatment> {
    //     const dailyDetails: TreatmentDailyDetails = { 
    //         ...this.treatmentsQuery.getActive().options,
    //         missedVisitReason: null,
    //         missedVisitReasonNote: null, 
    //     };
    //     return this.updateDailyDetails(facilityId, caseId, dailyDetails, treatmentDate)
    //         .pipe(map(response => response.data),
    //               tap(treatment => {
    //                 this.treatmentsStore.updateActive(entity => {
    //                   return {
    //                       ...entity,
    //                       options: treatment.options,
    //                   }
    //               });
    //               this._dirtyCheckCollection.setHead();
    //             }));
    // }

    updateStoredTreatmentItem(chargeEntry: ChargeEntry) {
        this.treatmentsStore.updateActive(entity => {
            //const treatmentItem = 
            const treatmentItemIndex = entity.treatmentItems.findIndex(t => t.chargeCodeId === chargeEntry.chargeCodeId);
            // treatment should alread exist. if it doesn't we have a serious issue
            if (treatmentItemIndex >= 0) {
                // find matching intervention
                const interventionIndex = entity.treatmentItems[treatmentItemIndex].interventions.findIndex(i => i.shortName === chargeEntry.interventionShortName);
                if (interventionIndex >= 0) {
                    // update intervention 
                    return {
                        ...entity,
                        treatmentItems: [
                            ...entity.treatmentItems.slice(0, treatmentItemIndex),
                            {
                                ...entity.treatmentItems[treatmentItemIndex],
                                isEdited: true,
                                telehealthStatement: chargeEntry.telehealthStatement,
                                modalityStatement: chargeEntry.modalityStatement,
                                interventions: [
                                    ...entity.treatmentItems[treatmentItemIndex].interventions.slice(0, interventionIndex),
                                    {
                                        ...entity.treatmentItems[treatmentItemIndex].interventions[interventionIndex],
                                        minutes: chargeEntry.minutes,
                                        deliveryStatement: chargeEntry.deliveryStatement,
                                        ...(chargeEntry.contributingFactors != null &&  { contributingFactors: [...chargeEntry.contributingFactors] }),
                                        ...(chargeEntry.contributingFactorShortNames != null && { contributingFactorShortNames: [...chargeEntry.contributingFactorShortNames] }),
                                        ...(chargeEntry.functionalImpactAreaShortNames != null && { functionalImpactAreaShortNames: [...chargeEntry.functionalImpactAreaShortNames] }),
                                        ...(chargeEntry.functionalImpactAreas != null && { functionalImpactAreas: [...chargeEntry.functionalImpactAreas] }),
                                        ...(chargeEntry.interventionNoteShortNames != null && { interventionNoteShortNames: [...chargeEntry.interventionNoteShortNames] }),
                                        ...(chargeEntry.interventionNote != null && { interventionNote: chargeEntry.interventionNote }),
                                    },
                                    ...entity.treatmentItems[treatmentItemIndex].interventions.slice(interventionIndex + 1),
                                ]
                            },
                            ...entity.treatmentItems.slice(treatmentItemIndex + 1),
                        ]
                    }
                } else {
                    console.error('Updating treatment item - Intervention ' + chargeEntry.interventionName + ' does not exist for charge code ' + chargeEntry.chargeCode);
                }

            } else {
                console.error('Updating treatment item - Treatment item ' + chargeEntry.chargeCode + 'does not exist store!')
            }
        })
    }

    addTreatmentItem(addChargeEntry: AddChargeEntry, isHomeHealth: boolean) {
        // get charge code information
        this.chargeCodesService.getByChargeCodeId(addChargeEntry.chargeCodeId)
            .subscribe(chargeCode => {
                this.treatmentsStore.updateActive(entity => {
                    if (isHomeHealth) {
                        // add treatment 
                        const treatmentItem = this.mapAddChargeEntryToHomeHealthTreatmentItem(addChargeEntry);
                        treatmentItem.chargeCodeObj = chargeCode;
                        return {
                            ...entity,
                            treatmentItems: [
                                ...entity.treatmentItems,
                                treatmentItem
                            ]
                        }
                    }

                    if (entity.treatmentItems && entity.treatmentItems.length === 0) {
                        const treatmentItem = this.mapAddChargeEntryToTreatmentItem(addChargeEntry);
                        treatmentItem.chargeCodeObj = chargeCode;

                        // add new treatment item
                        return {
                            ...entity,
                            treatmentItems: [treatmentItem]
                        }
                    } else {
                        // check if cpt already used
                        const cptIndex = entity.treatmentItems.findIndex(t => t.chargeCodeId === addChargeEntry.chargeCodeId);
        
                        // add intervention to it
                        if (cptIndex >= 0) {
                            var interventionExists = entity.treatmentItems[cptIndex].interventions.find(i => i.shortName == addChargeEntry.interventionShortName);
                            if (!interventionExists) { 
                                const patientIntervention = this.mapAddChargeEntryToTreatmentItemIntervention(addChargeEntry);
                                return {
                                    ...entity,
                                    treatmentItems: [
                                        ...entity.treatmentItems.slice(0, cptIndex),
                                        {
                                            ...entity.treatmentItems[cptIndex],
                                            interventions: [
                                                ...entity.treatmentItems[cptIndex].interventions,
                                                patientIntervention,
                                            ]
                                        },
                                        ...entity.treatmentItems.slice(cptIndex + 1)
                                    ]
                                }
                            }
                        }
                        else {
                            const treatmentItem = this.mapAddChargeEntryToTreatmentItem(addChargeEntry);
                            treatmentItem.chargeCodeObj = chargeCode;
                            return {
                                ...entity,
                                treatmentItems: [
                                    ...entity.treatmentItems,
                                    treatmentItem,
                                ]
                            }
                        }
                    }
                });
                this._treatmentItems$.next(this.treatmentsQuery.getActive().treatmentItems);
            });
    }

    private mapAddChargeEntryToTreatmentItem(entry: AddChargeEntry): TreatmentItem {
        const newItem: TreatmentItem = {
            chargeCode: entry.chargeCode,
            chargeCodeId: entry.chargeCodeId,
            isMod52: entry.isMod52,
            isTelehealth: entry.isTelehealth,
            interventions: [],
          };
      
          const newIntervention = this.mapAddChargeEntryToTreatmentItemIntervention(entry);
          newItem.interventions.push(newIntervention);
      
          return newItem;
    }

    private mapAddChargeEntryToTreatmentItemIntervention(entry: AddChargeEntry): PatientIntervention {
        const newIntervention: PatientIntervention = { 
            name: entry.interventionName,
            shortName: entry.interventionShortName,
            audience: entry.audience,
            contributingFactors: entry.contributingFactors,
            contributingFactorShortNames: [],
            functionalImpactAreas: entry.functionalImpactAreas,
            functionalImpactAreaShortNames: [],
            interventionNotes: entry.interventionNotes,
            interventionNoteShortNames: [], 
        };
    
        if (entry.audience === MinuteType.Concurrent || entry.audience === MinuteType.Group) {
          newIntervention.isGroupOrConcurrent = true;
          if (entry.audience == MinuteType.Group) {
            newIntervention.isGroup = true;
          }
        }
        
        return newIntervention;
    }

    private mapAddChargeEntryToHomeHealthTreatmentItem(entry: AddChargeEntry): TreatmentItem {
        const newItem: TreatmentItem = {
            chargeCodeId: entry.chargeCodeId,
            chargeCode: entry.chargeCode,
            interventions: [
                {
                    name: entry.chargeCode,
                    isGroup: false,
                    isGroupOrConcurrent: false,
                    deliveryStatement: '',
                    isDeliveryStatementValid: true,
                }
            ]
        };
        return newItem;
    }

    private flattenTreatmentItems(treatmentItems: TreatmentItem[]): ChargeEntry[] {
        const flattenedItems = [];
        if (treatmentItems) {
          treatmentItems.forEach(item => {
            item.interventions.forEach(intervention => {
              let flatItem: ChargeEntry = {
                id: item.id, 
                chargeCode: item.chargeCode,
                chargeCodeId: item.chargeCodeId,
                chargeCodeObj: item.chargeCodeObj,
                minutes: intervention.minutes,
                interventionName: intervention.name,
                interventionShortName: intervention.shortName,
                interventionNoteShortNames: intervention.interventionNoteShortNames,
                contributingFactorsString: intervention.contributingFactorsString,
                contributingFactorShortNames: intervention.contributingFactorShortNames,
                functionalImpactAreasString: intervention.functionalImpactAreasString,
                functionalImpactAreaShortNames: intervention.functionalImpactAreaShortNames,
                interventionNote: intervention.interventionNote,
                audience: intervention.audience,
                isTelehealth: item.isTelehealth,
                isEvaluationCode: item.isEvaluationCode,
                telehealthStatement: item.telehealthStatement,
                deliveryStatement: intervention.deliveryStatement,
                isModality: item.isModality,
                modalityId: item.modalityId,
                modalityStatement: item.modalityStatement,
                isGroupOrConcurrent: intervention.audience === MinuteType.Concurrent || intervention.audience === MinuteType.Group,
                isGroup: intervention.audience === MinuteType.Group,
              };
    
              flatItem.contributingFactors = intervention.contributingFactors;
              flatItem.functionalImpactAreas = intervention.functionalImpactAreas;
              flatItem.interventionNotes = intervention.interventionNotes;
    
              flattenedItems.push(flatItem);
            })
          });
        }
        return flattenedItems;
      }
}
