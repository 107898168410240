<dx-drop-down-box valueExpr="value" displayExpr="text" [dataSource]="dataListItems"
    [value]="selectedItems">
    <div *dxTemplate="let data of 'content'">
        <dx-list [dataSource]="dataListItems" [showSelectionControls]="true" [selectionMode]="selectionMode"
            itemTemplate="listItem" keyExpr="value" selectAllMode="allPages" [(selectedItemKeys)]="selectedItems"
            pageLoadMode="scrollBottom" (onSelectionChanged)="onSelectionChanged($event)">
            <div *dxTemplate="let item of 'listItem'">
                {{item.text}}
            </div>
        </dx-list>
    </div>
</dx-drop-down-box>