import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';
import { FacilityAdmin, OperationResponse, Region, IntegrationProvider, County, FacilityBasicInfoDataLists, FacilityBillingInfoDataLists, FacilityStaffAssignment, FacilityIntegrationPoint } from '@app/model';
import { FacilitiesAdminQuery, FacilitiesAdminStore } from '../stores';
import { tap, map, switchMap, take } from 'rxjs/operators';
import { ToastMessageService } from './toast-message.service';

@Injectable({
    providedIn: 'root'
})
export class FacilityAdminService {

    constructor(private http: HttpClient,
        private facilitiesAdminQuery: FacilitiesAdminQuery,
        private facilitiesAdminStore: FacilitiesAdminStore,
        private toastMessageService: ToastMessageService,
        private urlConstantsService: UrlConstantsService) { }


    get activeFacilityAdmin(): Observable<FacilityAdmin> {
        return this.facilitiesAdminQuery.selectActive();
    }

    getBasicInfoDataLists(id: string) {
        return this.http.get<FacilityBasicInfoDataLists>(`${this.urlConstantsService.FACILITIES_URL}/${id}/admin/basicInfo/datalists`);
    }

    getById(id: string): Observable<FacilityAdmin> {
        return this.http.get<FacilityAdmin>(`${this.urlConstantsService.FACILITIES_URL}/${id}/admin`)
            .pipe(tap(facility => {
                this.facilitiesAdminStore.add(facility);
                this.facilitiesAdminStore.setActive(facility.id);
            }))
    }

    updateFacilityAdminBasicInfo(id: string, facilityAdmin: FacilityAdmin) {
        return this.http.put<OperationResponse<FacilityAdmin>>(`${this.urlConstantsService.FACILITIES_URL}/${id}/admin/basicInfo`, facilityAdmin)
            .pipe(tap(facilityAdmin => {
                this.facilitiesAdminStore.update(id, entity => {
                    return {
                        ...entity,
                        basicInfo: {
                            ...facilityAdmin.data.basicInfo,
                        },
                    }
                });
        }, error => this.toastMessageService.errorNotification('Failed to update basic information.')));
    }

    getBillingInfoDataLists(id: string) {
        return this.http.get<FacilityBillingInfoDataLists>(`${this.urlConstantsService.FACILITIES_URL}/${id}/admin/billingInfo/datalists`);
    }

    updateFacilityAdminBillingInfo(id: string, facilityAdmin: FacilityAdmin) {
        return this.http.put<OperationResponse<FacilityAdmin>>(`${this.urlConstantsService.FACILITIES_URL}/${id}/admin/billingInfo`, facilityAdmin)
            .pipe(tap(facilityAdmin => {
                this.facilitiesAdminStore.update(id, entity => {
                    return {
                        ...entity,
                        billingInfo: {
                            ...facilityAdmin.data.billingInfo,
                        },
                    }
                });
        }, error => this.toastMessageService.errorNotification('Failed to update billing information.')));
    }


    getContactInfoDataLists(id: string) {
        return this.http.get<County[]>(`${this.urlConstantsService.FACILITIES_URL}/${id}/admin/contactInfo/datalist`);
    }

    updateFacilityAdminContactInfo(id: string, facilityAdmin: FacilityAdmin) {
        return this.http.put<OperationResponse<FacilityAdmin>>(`${this.urlConstantsService.FACILITIES_URL}/${id}/admin/contactInfo`, facilityAdmin)
            .pipe(tap(facilityAdmin => {
                this.facilitiesAdminStore.update(id, entity => {
                    return {
                        ...entity,
                        contactInfo: {
                            ...facilityAdmin.data.contactInfo,
                        },
                    }
                });
        }, error => this.toastMessageService.errorNotification('Failed to update contact information.')));
    }

    getClientInfoDataLists(id: string) {
        return this.http.get<Region[]>(`${this.urlConstantsService.FACILITIES_URL}/${id}/admin/clientInfo/datalist`);
    }

    updateFacilityAdminClientInfo(id: string, facilityAdmin: FacilityAdmin) {
        return this.http.put<OperationResponse<FacilityAdmin>>(`${this.urlConstantsService.FACILITIES_URL}/${id}/admin/clientInfo`, facilityAdmin)
            .pipe(tap(facilityAdmin => {
                this.facilitiesAdminStore.update(id, entity => {
                    return {
                        ...entity,
                        clientInfo: {
                            ...facilityAdmin.data.clientInfo,
                        },
                    }
                });
            }, error => this.toastMessageService.errorNotification('Failed to update client information.')));
    }

    getAdminOptionsDataLists(id: string) {
        return this.http.get<IntegrationProvider[]>(`${this.urlConstantsService.FACILITIES_URL}/${id}/admin/adminOptions/datalist`);
    }

    addFacilityAdminOptionsIntegrationPoint(integrationPoint: FacilityIntegrationPoint) {
        return this.facilitiesAdminQuery.selectActive()
            .pipe(take(1), switchMap(active => {
                return this.updateFacilityAdminOptions(active.id, {
                    ...active,
                    adminOptions: {
                        ...active.adminOptions,
                        integrationPoints: [
                            ...active.adminOptions.integrationPoints,
                            integrationPoint,
                        ]
                    }
                })
            }));
    }

    updateFacilityAdminOptionIntegrationPoint(integrationPoint: FacilityIntegrationPoint) {
        return this.facilitiesAdminQuery.selectActive()
        .pipe(take(1), switchMap(active => {
            const index = active.adminOptions.integrationPoints.findIndex(ip => ip.id == integrationPoint.id);
            return this.updateFacilityAdminOptions(active.id, {
                ...active,
                adminOptions: {
                    ...active.adminOptions,
                    integrationPoints: [
                        ...active.adminOptions.integrationPoints.slice(0, index),
                        integrationPoint,
                        ...active.adminOptions.integrationPoints.slice(index + 1),
                    ]
                }
            })
        }));
    }

    deleteFacilityAdminOptionIntegrationPoint(id: string) {
        return this.facilitiesAdminQuery.selectActive()
        .pipe(take(1), switchMap(active => {
            const index = active.adminOptions.integrationPoints.findIndex(ip => ip.id == id);
            return this.updateFacilityAdminOptions(active.id, {
                ...active,
                adminOptions: {
                    ...active.adminOptions,
                    integrationPoints: [
                        ...active.adminOptions.integrationPoints.slice(0, index),
                        ...active.adminOptions.integrationPoints.slice(index + 1),
                    ]
                }
            })
        }));
    }

    updateFacilityAdminOptions(id: string, facilityAdmin: FacilityAdmin) {
        return this.http.put<OperationResponse<FacilityAdmin>>(`${this.urlConstantsService.FACILITIES_URL}/${id}/admin/adminOptions`, facilityAdmin)
            .pipe(tap(facilityAdmin => {
                this.facilitiesAdminStore.update(id, entity => {
                    return {
                        ...entity,
                        adminOptions: {
                            ...facilityAdmin.data.adminOptions,
                        },
                    }
                });
            }, error => this.toastMessageService.errorNotification('Failed to update admin options.')));
    }

    updateFacilityInternetInfo(id: string, facilityAdmin: FacilityAdmin) {
        return this.http.put<OperationResponse<FacilityAdmin>>(`${this.urlConstantsService.FACILITIES_URL}/${id}/admin/internetInfo`, facilityAdmin)
            .pipe(tap(facilityAdmin => {
                this.facilitiesAdminStore.update(id, entity => {
                    return {
                        ...entity,
                        internetInfo: {
                            ...facilityAdmin.data.internetInfo,
                        },
                    }
                });
            }, error => this.toastMessageService.errorNotification('Failed to update internet info.')));
    }

    updateFacilityIntermediaryAssignments(id: string, facilityAdmin: FacilityAdmin) {
        return this.http.put<OperationResponse<FacilityAdmin>>(`${this.urlConstantsService.FACILITIES_URL}/${id}/admin/intermediaryAssignments`, facilityAdmin)
            .pipe(tap(facilityAdmin => {
                this.facilitiesAdminStore.update(id, entity => {
                    return {
                        ...entity,
                        intermediaryAssignments: [...facilityAdmin.data.intermediaryAssignments],
                    }
                });
            }, error => this.toastMessageService.errorNotification('Failed to update intermediary assignments.')));
    }

    deleteFacilityStaffAssignment(id: string, facilityStaffAssignmentId: string) {
        const facilityAdmin = this.facilitiesAdminQuery.getActive();
        const index = facilityAdmin.staffAssignments.findIndex(sa => sa.id == facilityStaffAssignmentId);
        let newFacilityAdmin = null;

        if (index >= 0) {
            newFacilityAdmin = {
                ...facilityAdmin,
                staffAssignments: [
                    ...facilityAdmin.staffAssignments.slice(0, index),
                    ...facilityAdmin.staffAssignments.slice(index + 1),
                ]
            };
            
            return this.updateFacilityStaffAssignments(id, newFacilityAdmin);
        }
    }

    updateFacilityCensus(id: string, facilityAdmin: FacilityAdmin) {
        return this.http.put<OperationResponse<FacilityAdmin>>(`${this.urlConstantsService.FACILITIES_URL}/${id}/admin/census`, facilityAdmin)
            .pipe(tap(facilityAdmin => {
                this.facilitiesAdminStore.update(id, entity => {
                    return {
                        ...entity,
                        basicInfo: {
                            ...facilityAdmin.data.basicInfo,
                        },
                    }
                });
            }, error => this.toastMessageService.errorNotification('Failed to update facility census.')));
    }

    updateFacilityStaffAssignment(id: string, staffAssignments: FacilityStaffAssignment) {
        const facilityAdmin = this.facilitiesAdminQuery.getActive();
        const index = facilityAdmin.staffAssignments?.findIndex(sa => sa.id == staffAssignments.id);
        let newFacilityAdmin = null;

        if (index && index >= 0) {
            // editing
            newFacilityAdmin = {
                ...facilityAdmin,
                staffAssignments: [
                    ...facilityAdmin.staffAssignments.slice(0, index),
                    staffAssignments,
                    ...facilityAdmin.staffAssignments.slice(index + 1),
                ]
            }
        } else {
            const staffAssignmentsCopy = facilityAdmin.staffAssignments ? facilityAdmin.staffAssignments : [];
            newFacilityAdmin = {
                ...facilityAdmin,
                staffAssignments: [
                    ...staffAssignmentsCopy,
                    staffAssignments,
                ],
            };
        }
        return this.updateFacilityStaffAssignments(id, newFacilityAdmin);
    }

   private updateFacilityStaffAssignments(id: string, facilityAdmin: FacilityAdmin) {
        return this.http.put<OperationResponse<FacilityAdmin>>(`${this.urlConstantsService.FACILITIES_URL}/${id}/admin/staffAssignments`, facilityAdmin)
                .pipe(map(response => response.data),
                    tap(facilityAdmin => {
                    this.facilitiesAdminStore.update(id, entity => {
                        return {
                            ...entity,
                            staffAssignments: facilityAdmin.staffAssignments,
                        }
                    })
                }, error => this.toastMessageService.errorNotification('Failed to update staff assignments.')));
   }

    validateFacilityStaffAssignment(staffAssignment: FacilityStaffAssignment): boolean {
        const facilityAdmin = this.facilitiesAdminQuery.getActive();
        const staffAssignmentsWithUserRoleId = facilityAdmin?.staffAssignments?.filter(sa =>sa.userRoleId == staffAssignment.userRoleId);
        if (staffAssignmentsWithUserRoleId && staffAssignmentsWithUserRoleId.length > 0) {
            return staffAssignmentsWithUserRoleId.every(value => value.endDate != null || value.id == staffAssignment.id );
        } else {
            return true;
        }
    }
}
