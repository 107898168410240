import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Area, OperationResponse, PagedResult, Region, GeneratedReportResponse } from '@app/model';
import { UrlConstantsService } from './url-constants.service';

@Injectable({
  providedIn: 'root'
})
export class RegionsService {

  constructor(private httpClient: HttpClient,
    private urlConstantsService: UrlConstantsService) { }

  getRegions(): Observable<Region[]> {
    return this.httpClient.get<PagedResult<Region>>(`${this.urlConstantsService.REGIONS_URL}`)
      .pipe(map(response => response.items));
  }

  getRegionById(regionId: string): Observable<Region> {
    return this.httpClient.get<Region>(`${this.urlConstantsService.REGIONS_URL}/${regionId}`);
  }

  getRegionReport() {
    return this.httpClient.get<OperationResponse<GeneratedReportResponse>>(`${this.urlConstantsService.REGIONS_URL}/report`)
      .pipe(map(response => response.data));
  }

  addRegion(region: Region): Observable<Region> {
    return this.httpClient.post<OperationResponse<Region>>(`${this.urlConstantsService.REGIONS_URL}`, region)
      .pipe(map(response => response.data));
  }

  deleteRegion(regionId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.urlConstantsService.REGIONS_URL}/${regionId}`);
  }

  updateRegion(region: Region): Observable<Region> {
    return this.httpClient.put<OperationResponse<Region>>(`${this.urlConstantsService.REGIONS_URL}/${region.id}`, region)
      .pipe(map(response => response.data));
  }

  addArea(regionId: string, area: Area): Observable<Region> {
    return this.httpClient.post<OperationResponse<Region>>(`${this.urlConstantsService.REGIONS_URL}/${regionId}/area`, area)
      .pipe(map(response => response.data));
  }

  updateArea(regionId: string, areaId: string, area: Area): Observable<Region> {
    return this.httpClient.put<OperationResponse<Region>>(`${this.urlConstantsService.REGIONS_URL}/${regionId}/area/${areaId}`, area)
      .pipe(map(response => response.data));
  }

  deleteArea(regionId: string, areaId: string): Observable<boolean> {
    return this.httpClient.delete<OperationResponse<boolean>>(`${this.urlConstantsService.REGIONS_URL}/${regionId}/area/${areaId}`)
      .pipe(map(response => response.data));
  }
}
