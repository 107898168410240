import {
    NarrativeBuilderOption,
    NarrativeBuilderList,
} from '@app/model';
import { RehabPotentialProperties } from '@app/model';
import { RehabPotentialConstants } from '../../constants/narrative-builder.constants';

import * as idb from './id-builder-utils';

export function getRehabPotentialNarrativeBuilder(): NarrativeBuilderList {
    const RP: string = 'RP';

    const STATED_GOALS = 'StatedGoals';
    const RPStatedGoalsBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, STATED_GOALS),
        text: 'Good for stated goals',
        values: [
            {
                id: idb.getNBOptionValueId(RP, 'StatedGoals'),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, 'StatedGoals', 1),
                        text: 'Good for stated goals',
                    }

                ],
            },
        ],
        selectedValue: null,
        property: RehabPotentialProperties.STATED_GOALS,
    }

    const nbl: NarrativeBuilderList = {
        options: {
            [RehabPotentialConstants.STATED_GOALS_OPTION]: RPStatedGoalsBuilderOption,
        }
    };

    return nbl;
}