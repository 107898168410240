import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared';
import { UserRoutingModule, routedComponents } from './user-routing.module';

@NgModule({
  imports: [
    UserRoutingModule,
    SharedModule,
  ],
  declarations: [
    ...routedComponents,
  ],
})
export class UserModule {
  constructor() {}
}
