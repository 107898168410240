import { Query } from '@datorama/akita';
import { CacheManStore, CacheManState } from './cacheman.store';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CacheManQuery extends Query<CacheManState> {
    constructor(protected store: CacheManStore) {
        super(store);
    }

    getDataListItems$(shortName: string) {
        return this.select(state => state ? state.dataLists[shortName].items : null);
    }
}