import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlConstantsService } from './url-constants.service';
import { eSupportIssueCategories, KeyValuePair, OperationResponse, SupportIssue, SupportIssues, SupportIssueMessage, SupportIssueAttachment, KnowledgeBaseItem, PagedResult } from '@app/model';


@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(private http: HttpClient,
    private urlConstantsService: UrlConstantsService) { }

  getAllSupportTickets(): Observable<SupportIssues> {
    return this.http.get<OperationResponse<SupportIssues>>(this.urlConstantsService.SUPPORT_TICKETS_URL)
      .pipe(map(response => response.data))
  }

  getSupportTicketById(id: string): Observable<SupportIssue> {
    return this.http.get<OperationResponse<SupportIssue>>(`${this.urlConstantsService.SUPPORT_TICKETS_URL}/${id}`)
      .pipe(map(response => response.data))
  }

  createSupportIssue(supportIssue: SupportIssue): Observable<SupportIssue> {
    return this.http.post<OperationResponse<SupportIssue>>(`${this.urlConstantsService.SUPPORT_TICKETS_URL}`, supportIssue)
      .pipe(map(response => response.data));
  }

  addSupportIssueReplyMessage(ticketId: string, supportIssueMessage: SupportIssueMessage): Observable<SupportIssueMessage> {
    return this.http.post<SupportIssueMessage>(`${this.urlConstantsService.SUPPORT_TICKETS_URL}/${ticketId}/reply`, supportIssueMessage);
  }

  addSupportIssueAttachment(ticketId: string, attachment: SupportIssueAttachment, file: any): Observable<OperationResponse<void>> {
    const formData = new FormData();
    formData.append('FileName', attachment.fileName);
    formData.append('FileHash', attachment.fileHash);
    formData.append('', file, attachment.fileName);
  
    return this.http.post<OperationResponse<void>>(`${this.urlConstantsService.SUPPORT_TICKETS_URL}/${ticketId}/attachmentsform`, formData);
  }

  updateSupportIssueReadMessageStatus(id: string): Observable<void> {
    return this.http.put<OperationResponse<void>>(`${this.urlConstantsService.SUPPORT_TICKETS_URL}/${id}/readmessage`, {})
              .pipe(map(response => response.data));
  }

  getSupportIssueCategories(): KeyValuePair<eSupportIssueCategories, string>[] {
    return [
      { key: eSupportIssueCategories.Documentation, value: 'Documentation' },
      { key: eSupportIssueCategories.Charges, value: 'Charges' },
      { key: eSupportIssueCategories.ElectronicCharting, value: 'Electronic Charting' },
      { key: eSupportIssueCategories.EpisodeCase, value: 'Episode/Case' },
      { key: eSupportIssueCategories.AddPhysician, value: 'Add Physician' },
      { key: eSupportIssueCategories.PatientPlanner, value: 'Patient Planner' },
      { key: eSupportIssueCategories.Reports, value: 'Reports' },
      { key: eSupportIssueCategories.Other, value: 'Other' }
    ];
  }

  getSupportIssueSubCategories(): KeyValuePair<eSupportIssueCategories, string[]>[] {
    return [
      {
        key: eSupportIssueCategories.Documentation,
        value: ['Signature Issue', 'Unable to Save/Complete', 'Other']
      },
      {
        key: eSupportIssueCategories.Charges,
        value: ['Add/Remove Charge', 'AP/Supervision', 'Missing CPTs', 'Other']
      },
      {
        key: eSupportIssueCategories.ElectronicCharting,
        value: ['Missing Documents', 'DocScan', 'Other']
      },
      {
        key: eSupportIssueCategories.EpisodeCase,
        value: ['Episode edit', 'PDPM', 'Case edit', 'Episode/Case Delete', 'Other']
      },
      {
        key: eSupportIssueCategories.AddPhysician,
        value: [],
      },
      {
        key: eSupportIssueCategories.PatientPlanner,
        value: ['Patient not shown', 'Edits will not save', 'Other']
      },
      {
        key: eSupportIssueCategories.Reports,
        value: ['Facility Report', 'Patient Report', 'User Report', 'Department Report', 'Other']
      }
    ];
  }

  getKnowledgeBaseItemSuggestions(supportIssue: SupportIssue): Observable<KnowledgeBaseItem[]> {
    return this.http.post<PagedResult<KnowledgeBaseItem>>(`${this.urlConstantsService.SUPPORT_TICKETS_URL}/suggestions`, supportIssue)
      .pipe(map(response => response.items));
  } 
}
