import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ClinicalScale } from '@app/model/measurements/ClinicalScale';

@Component({
  selector: 'tms-measurement-slider',
  templateUrl: './measurement-slider.component.html'
})
export class MeasurementSliderComponent implements OnInit {
  @ViewChild('arrowUp') arrowUp: ElementRef;
  @ViewChild('arrowDown') arrowDown: ElementRef;

  @Input() clinicalScale: ClinicalScale

  minimumValueString: string;
  maximumValueString: string;
  selectedValue: string;
  lastMeasurement: number;
  lastMeasurementString: string;
  arrowUpColor: string = "grey";
  arrowDownColor: string = "grey";

  constructor() {
  }

  ngOnInit() {
    this.minimumValueString = this.clinicalScale.items[0].value;
    this.maximumValueString = this.clinicalScale.upperLimit.toString();
    this.lastMeasurement = this.clinicalScale.lastMeasurement;
    this.lastMeasurementString = this.clinicalScale.lastMeasurement.toString();
    this.setDisplayValue(this.clinicalScale.lastMeasurement);
  }

  sliderValueChanged(e) {
    let selected = e;
    this.setDisplayValue(selected);
    this.setArrowColor(selected);
  }

  setDisplayValue(selected) {
    let displayValue = "";

    for (let item of this.clinicalScale.items) {
      if (selected === -1 && item.lowerLimit === -1) {
        displayValue = item.value;
        break;
      }
      else if (item.lowerLimit >= selected && selected <= item.upperLimit) {
        displayValue = item.value;
      }
    }

    this.selectedValue = displayValue;
  }

  setArrowColor(selected) {
    if (selected > this.lastMeasurement) {
      this.arrowUpColor = "lime";
      this.arrowDownColor = "grey";
    }
    else if (selected < this.lastMeasurement) {
      this.arrowUpColor = "grey";
      this.arrowDownColor = "red";
    }
    else {
      this.arrowUpColor = "grey";
      this.arrowDownColor = "grey";
    }
  }

}
