import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { CacheManService } from '@app/core';

@Pipe({
  name: 'dataListItem'
})
export class DataListItemPipe implements PipeTransform {
  constructor(private cachemanService: CacheManService) { }

  transform(value: string | number, dataListName: string, dataListProperty: string = null): Observable<string | number> {
    if (!dataListName) {
      return of(value);
    }
    
    if (value != null) {
      const dataListProp = dataListProperty ? dataListProperty : 'text';
      return this.cachemanService.getDataListItems$(dataListName, false)
        .pipe(filter(datalist => datalist !== null),
          take(1),
          map(dataListItems => {
            const index = dataListItems.findIndex(item => item.value == value);
            if (index >= 0) {
              return dataListItems[index][dataListProp];
            } else {
              return null;
            }
          }));
    } else {
      return null;
    }
  }
}
