import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { InterviewStoreAnswer } from '@app/model';

export interface InterviewsState extends EntityState<InterviewStoreAnswer>, ActiveState {}

const initialState: InterviewsState = {
  active: null,
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'interviews' })
export class InterviewsStore extends EntityStore<InterviewsState> {

  constructor() {
    super(initialState);
  }

}

