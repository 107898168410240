export class ClinicalDocumentPropertiesConstants {
    static ANSWERS: string = 'answers';
    static CONTRIBUTING_MODULES: string = 'contributingModules';
    static CUSTOM_GOAL_STATEMENT_DICT: string = 'customGoalStatementDictionary';
    static DAILY_NOTES: string = 'dailyNotes';
    static DEFICITS: string = 'deficits';
    static DISCHARGE_INFO: string = 'dischargeInfo';
    static DOCUMENT_INFO: string = 'documentInfo';
    static DOCUMENT_RECORD_ID: string = 'documentRecordId';
    static DURATIONS: string = 'durations';
    static EXTENT_OF_PROGRESS: string = 'extentOfProgress';
    static FINALIZE: string = 'finalize';
    static GOAL_STATEMENTS: string = 'goalStatements';
    static GOAL_STATEMENT_IMPACT_STATEMENT: string = 'ImpactStatement'; // part of custom goal statement template variable
    static GOAL_STATEMENT_IMPACT_STATEMENT_TEXT: string = 'ImpactStatementText'; // part of custom goal statement template variable
    static GOAL_SUMMARY_TEXT_ITEMS: string = 'goalSummaryTextItems';
    static INTERVENTIONS: string = 'interventions';
    static INTERVIEW_STATUS: string = 'interviewStatus';
    static IS_CONTRIBUTING_FACTORS_GOAL_IMPACT_GROUP_VISIBLE: string = 'isContributingFactorsGoalImpactGroupVisible';
    static IS_EVAL_ONLY: string = 'isEvalOnly';
    static IS_JBS_CASE: string = 'isJbsCase';
    static IS_READ_ONLY: string = 'isReadOnly';
    static JBS_MEASUREMENTS: string = 'jbsMeasurements';
    static LONG_TERM_GOAL_MAX: string = 'longTermGoalMaximum';
    static MEDICAL_HISTORY: string = 'medicalHistory';
    static MEDICAL_ICD_CODES: string = 'medicalIcdCodes';
    static MODULES: string = 'modules';
    static MODULES_MAP: string = 'modulesMap';
    static OTHER_MODULES: string = 'otherModules';
    static OTHER_NOTES: string = 'otherNotes';
    static OUTCOMES: string = 'outcomes';
    static PATHWAY: string = 'pathway';
    static PATIENT_COMPLEXITY: string = 'patientComplexity';
    static PLANNED_DISCHARGE: string = 'plannedDischargeModel';
    static PLAN_OF_CARE: string = 'planOfCare';
    static PLOF: string = 'plof';
    static PRECAUTIONS: string = 'precautions';
    static PREVIOUS_CLINICAL_DOCUMENT_RECORD: string = 'prevClinicalDocumentRecord';
    static PREVIOUS_THERAPY_MODEL: string = 'previousTherapyModel';
    static PROGNOSTIC_INDICATORS: string = 'prognosticIndicators';
    static REASON_FOR_REFERRAL: string = 'reasonForReferral';
    static REDO_INTERVIEWSESSION_ID: string = 'redoOfInterviewSessionId';
    static REHAB_POTENTIAL_GOALS: string = 'rehabPotentialGoals';
    static SIGNATURE_RECORDS: string = 'signatureRecords';
    static SHORT_TERM_GOAL_MAX: string = 'shortTermGoalMaximum';
    static ST_OUTCOMES: string = 'stOutcomes';
    static START_OF_CARE_DATE: string = 'startOfCareDate';
    static TREATMENT_ICD_CODES: string = 'treatmentIcdCodes';
    static WEEKLY_UPDATE: string = 'weeklyUpdate';
}