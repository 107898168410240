export enum DenialClaimStageDecision {
    Unspecified = 0,
    Paid = 1,
    PartiallyPaid = 2,
    Cancelled = 3,
    Denied = 4,
    Rejected = 5,
    TimedOut = 6,
    NoTherapyCaseload = 7,
    NursingSnfOnly = 8,
    UnableToAppeal = 9,
    NoResponse = 10,
    BillingErrors = 11,
}
