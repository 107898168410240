import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UrlConstantsService } from './url-constants.service';
import { FeatureFlagRecord, OperationResponse, PagedResult } from '@app/model';
import { CacheManService } from './cacheman.service';
import { FacilitiesService } from './facilities.service';
import { eFeatureName } from '@app/model/enum/eFeatureName';

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagsService {
    constructor(private cacheManService: CacheManService,
        private facilitiesService: FacilitiesService,
        private httpClient: HttpClient,
        private urlConstantsService: UrlConstantsService) { }

    getFeatureFlags(): Observable<FeatureFlagRecord[]> {
        return this.httpClient.get<PagedResult<FeatureFlagRecord>>(`${this.urlConstantsService.FEATURE_FLAGS_URL}`)
            .pipe(map(response => response.items));
    }

    updateFeatureFlag(featureFlagRecord: FeatureFlagRecord): Observable<FeatureFlagRecord> {
        return this.httpClient.put<OperationResponse<FeatureFlagRecord>>(`${this.urlConstantsService.FEATURE_FLAGS_URL}/${featureFlagRecord.id}`, featureFlagRecord)
            .pipe(map(response => response.data));
    }

    isFeatureAvailable$(featureName: eFeatureName): Observable<boolean> {
        return this.facilitiesService.activeFacilityId$
            .pipe(switchMap(activeFacilityId => this.cacheManService.getFeatureFlagsByFacility$(activeFacilityId)),
                  map(features => features != null && features.includes(featureName)));
    }
}