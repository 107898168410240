<div>
    <h2>Support Tickets</h2>
</div>
<div class="row">
    <div class="col">
        <dx-radio-group [items]="supportTicketFilter" [value]="supportTicketFilter[0]" layout="horizontal"
        (onValueChanged)="onSupportTicketFilterChanged($event)">
        </dx-radio-group>
    </div>
    <div class="col-2">
        <dx-button class="tms-primary-button" text="Create Ticket" (onClick)="onAddNewTicket()"></dx-button>
    </div>
</div>
<div class="row tms-row-divider">
    <dx-data-grid [dataSource]="supportIssues" [columnAutoWidth]="true" (onRowPrepared)="onSupportIssueRowPrepared($event)">
        <dxi-column dataField="id" [visible]="false"></dxi-column>
        <dxi-column dataField="ticketNumber"></dxi-column>
        <dxi-column dataField="department">
            <dxo-lookup [dataSource]="supportIssueDepartments" valueExpr="valueInt" displayExpr="text">
            </dxo-lookup>
        </dxi-column>
        <dxi-column dataField="title"></dxi-column>
        <dxi-column dataField="modified" caption="Last Updated" dataType="date">
            <dxo-format type="shortDateShortTime"></dxo-format>
        </dxi-column>
        <dxi-column dataField="status" [calculateDisplayValue]="calculateSupportIssueStatusValue">
        </dxi-column>
        <dxi-column dataField="pendingMessage" [visible]="false"></dxi-column>
        <dxi-column caption="" cellTemplate="supportCellTemplate">
            <div *dxTemplate="let data of 'supportCellTemplate'">
                <dx-button class="tms-edit-icon-button" icon="fas fa-eye" hint="Edit" (onClick)="onTicketDetailsClicked(data.data)"></dx-button>
            </div>
        </dxi-column>
    </dx-data-grid>
</div>