import { User, eDiscipline } from "@app/model";
import { DisciplineDesignation } from "@app/model/employee/DisciplineDesignation";

export class UsersFilterByDisciplines {
    static filterListByUserDisciplines(discipline: eDiscipline, users: User[]): User[] {
        return users.filter(user => this.disciplineAvailableIn(user.employee.availableDisciplines, discipline));
    }

    static disciplineAvailableIn(availableDisciplines: DisciplineDesignation[], discipline: eDiscipline): boolean {
        return availableDisciplines.some(designation => designation.discipline == discipline && designation.endDate == null);
    }

    static allDisciplinesAvailableIn(availableDisciplines: DisciplineDesignation[], disciplines: eDiscipline[]): boolean {
        return disciplines.every(discipline => {
            return availableDisciplines.find(designation => designation.discipline == discipline && designation.endDate == null);
        });
    }

    static getDisciplineDesignationByDiscipline(availableDisciplines: DisciplineDesignation[], discipline: eDiscipline): DisciplineDesignation {
        const disciplineDesignation = availableDisciplines.find(disc => disc.discipline === discipline && disc.endDate == null);

        return disciplineDesignation;
    }

}