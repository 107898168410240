import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Generic warning message modal 

@Component({
  selector: 'tms-warning-message-modal',
  templateUrl: './tms-warning-message-modal.component.html',
  styleUrls: ['./tms-warning-message-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmsWarningMessageModalComponent {
  @Input() title: string = '';
  @Input() message: string;

  constructor(public activeModal: NgbActiveModal) { }

  onClose() {
    this.activeModal.close();
  }

}
