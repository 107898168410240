export enum InterviewType {
    Unspecified = 0,
    ClinicalDocument = 1,
    SectionGg = 2,
    Admission = 3,
    Screen = 4,
    Coding = 5,
    Wellness = 6,
    WeeklyUpdatePhysicalTherapy = 7,
    WeeklyUpdateOccupationalTherapy = 8,
    WeeklyUpdateSpeechTherapy = 9,
    RestorativeNursing = 10,
    Multidisciplinary = 11,
    ALF = 13
}
