import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'tms-video-viewer-modal',
  templateUrl: './tms-video-viewer-modal.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TmsVideoViewerModalComponent implements OnInit {
  @Input() videoSrc: string = null;
  url;

  constructor(private activeService: NgbActiveModal,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoSrc);
  }

  onCloseVideoPlayer() {
    this.activeService.dismiss();
  }

}
