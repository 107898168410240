import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PayersStore, PayersState } from './payers.store';
import { Payer } from '@app/model';

@Injectable({
  providedIn: 'root'
})
export class PayersQuery extends QueryEntity<PayersState, Payer> {

  constructor(protected store: PayersStore) {
    super(store);
  }

}
