import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddFunctionBuilderNonTreatmentItemCommand, CompleteFunctionBuilderCommand, eFunctionBuilderPrograms, FunctionBuilder, FunctionBuilderNonTreatmentItem, FunctionBuildersChargesDashboardResponse, FunctionBuildersNonTreatmentItemsResponse, FunctionBuilderTreatmentItem, InterventionAudience, InterviewAnswer, InterviewGroup, InterviewQuestion, InterviewTab, OperationResponse, PatientSlim, ReopenFunctionBuilderCommand, RestorativeNursingPrograms, TreatmentItem, UpdateFunctionBuilderAssignedUserCommand, UpdateFunctionBuilderInterviewCommand, UpdateFunctionBuilderNonTreatmentItemCommand, UpdateFunctionBuildersTreatmentItemsCommand } from '@app/model';
import { AddFunctionBuilderCommand } from '@app/model/function-builders/AddFunctionBuilderCommand';
import { DeepCopyUtils } from '@app/utils';
import { EntityDirtyCheckPlugin } from '@datorama/akita';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { RestorativeNursingService, UrlConstantsService } from '.';
import { AncillaryProgramConstants } from '../constants';
import { FunctionBuildersQuery, FunctionBuildersStore, FunctionBuilderTreatmentsQuery, FunctionBuilderTreatmentsStore } from '../stores';

@Injectable({
  providedIn: 'root'
})
export class FunctionBuildersService {
  private _activeFbChargesDate: Date;
  private _activeFbChargesDate$: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);

  private _dirtyCheckCollection: EntityDirtyCheckPlugin;
  private _treatmentsDirtyCheckCollection: EntityDirtyCheckPlugin;
  
  get activeFunctionBuilder$(): Observable<FunctionBuilder> {
    return this.functionBuilderQuery.selectActive();
  }

  // Active charges date
  set activeFbChargesDate(activeDate: Date) {
    this._activeFbChargesDate = activeDate;
    this._activeFbChargesDate$.next(this._activeFbChargesDate);
  }

  get activeFbChargesDate$(): Observable<Date> {
    return this._activeFbChargesDate$.asObservable();
  }

  constructor(private functionBuilderQuery: FunctionBuildersQuery,
    private functionBuildersStore: FunctionBuildersStore,
    private fbTreatmentsQuery: FunctionBuilderTreatmentsQuery,
    private fbTreatmentsStore: FunctionBuilderTreatmentsStore,
    private httpClient: HttpClient,
    private urlConstantsService: UrlConstantsService) { }

  getIsFunctionBuilderDirty(): boolean {
    return this._dirtyCheckCollection.someDirty();
  }

  getIsFunctionBuildersTreatmentsDirty(): boolean {
    return this._treatmentsDirtyCheckCollection.someDirty();
  }

  getFunctionBuilders(facilityId: string, isActive: boolean): Observable<FunctionBuilder[]> {
    return this.httpClient.get<OperationResponse<any>>(`${this.urlConstantsService.FUNCTION_BUILDERS_URL}/${facilityId}/${isActive}`)
      .pipe(map(response => response.data.functionBuilders));
  }

  getFunctionBuilderById(id: string) {
    return this.httpClient.get<OperationResponse<any>>(`${this.urlConstantsService.FUNCTION_BUILDERS_URL}/${id}`)
      .pipe(map(response => response.data),
        tap(response => { 
        const program = response.functionBuilder;
        this.functionBuildersStore.upsert(program.id, program);
        this.functionBuildersStore.setActive(program.id);
        this._dirtyCheckCollection = new EntityDirtyCheckPlugin(this.functionBuilderQuery);
        this._dirtyCheckCollection.setHead();
      }));
  }

  getFunctionBuilderPatients(facilityId: string): Observable<PatientSlim[]> {
     return this.httpClient.get<OperationResponse<any>>(`${this.urlConstantsService.FUNCTION_BUILDERS_URL}/patients/${facilityId}`)
       .pipe(map(response => response.data.functionBuilderPatients));
  }

  addFunctionBuilderEntry(functionBuilder: FunctionBuilder): Observable<FunctionBuilder> {
    const addCommand: AddFunctionBuilderCommand = {
      functionBuilder: functionBuilder,
    };
    return this.httpClient.post<OperationResponse<FunctionBuilder>>(`${this.urlConstantsService.FUNCTION_BUILDERS_URL}`, addCommand)
            .pipe(map(response => response.data));
  }

  updateFunctionBuilderAssignedUser(functionBuilderId: string, assignedUserId: string) {
    const updateAssigneduUserCmd: UpdateFunctionBuilderAssignedUserCommand = {
      functionBuilderId: functionBuilderId,
      assignedUserId: assignedUserId,
    };
    return this.httpClient.put<OperationResponse<FunctionBuilder>>(`${this.urlConstantsService.FUNCTION_BUILDERS_URL}`, updateAssigneduUserCmd)
      .pipe(map(response => response.data));
  }

  deleteFunctionBuilder(functionBuilderId: string): Observable<boolean> {
    return this.httpClient.delete<OperationResponse<boolean>>(`${this.urlConstantsService.FUNCTION_BUILDERS_URL}/${functionBuilderId}`)
      .pipe(map(response => response.data));
  }

  updateFunctionBuilderInterviewAnswer(answer: InterviewAnswer): void {
    this.functionBuildersStore.updateActive(entity => {
      const tabIndex = entity.interviewPage.tabs.findIndex(tab => tab.shortName == answer.tab);
      const groupIndex = entity.interviewPage.tabs[tabIndex].groups.findIndex(group => group.shortName == answer.group);
      const questionIndex = entity.interviewPage.tabs[tabIndex].groups[groupIndex].questions.findIndex(question => question.shortName == answer.shortName);

      const newEntity: FunctionBuilder = DeepCopyUtils.deepCopyObject(entity);
      newEntity.interviewPage.tabs[tabIndex].groups[groupIndex].questions[questionIndex].answer = answer;

      return newEntity;
    });
  }

  updateFunctionBuilderInterviewGroupIsSelected(isSelected: boolean, tab: InterviewTab, group: InterviewGroup): void {
    this.functionBuildersStore.updateActive(entity => {
      const tabIndex = entity.interviewPage.tabs.findIndex(t => t.shortName == tab.shortName);
      const groupIndex = entity.interviewPage.tabs[tabIndex].groups.findIndex(g => g.shortName == group.shortName);

      const newEntity: FunctionBuilder = DeepCopyUtils.deepCopyObject(entity);
      newEntity.interviewPage.tabs[tabIndex].groups[groupIndex].isSelected = isSelected;

      newEntity.interviewPage.tabs[tabIndex].groups[groupIndex].questions.forEach(question => {
        if (question.shortName == AncillaryProgramConstants.FUNCTION_BUILDER_INTERVIEW_QUESTION_START_DATE || question.shortName == AncillaryProgramConstants.RESTORATIVE_PROGRAM_INTERVIEW_QUESTION_START_DATE) {
          question.validationOptions = {
            isRequired: isSelected,
          }
        }
      });

      return newEntity;
    });
  }

  updateFunctionBuilderInterviewTabIsVisible(isVisible: boolean, tab: InterviewTab) {
    this.functionBuildersStore.updateActive(entity => {
      const tabIndex = entity.interviewPage.tabs.findIndex(t => t.shortName == tab.shortName);
      const newEntity: FunctionBuilder = DeepCopyUtils.deepCopyObject(entity);
      newEntity.interviewPage.tabs[tabIndex].isVisible = isVisible;

      return newEntity;
    });
  }

  saveFunctionBuilderInterview(): Observable<FunctionBuilder> {
    const activeFb = this.functionBuilderQuery.getActive();
    const updateInterviewCommand: UpdateFunctionBuilderInterviewCommand = {
      functionBuilder: activeFb,
    };
    return this.httpClient.put<OperationResponse<FunctionBuilder>>(`${this.urlConstantsService.FUNCTION_BUILDERS_URL}/${activeFb.id}/interview`, updateInterviewCommand)
      .pipe(map(response => response.data));
  }

  closeFunctionBuilderInterview(id: string, endDate: Date): Observable<FunctionBuilder> {
    const cmd: CompleteFunctionBuilderCommand = {
      id: id,
      endDate: endDate,
    };
    return this.httpClient.put<OperationResponse<FunctionBuilder>>(`${this.urlConstantsService.FUNCTION_BUILDERS_URL}/complete/${id}`, cmd)
      .pipe(map(response => response.data));
  }

  reOpenFunctionBuilderInterview(id: string): Observable<FunctionBuilder> {
    const cmd: ReopenFunctionBuilderCommand = {
      id: id,
    };
    return this.httpClient.put<OperationResponse<FunctionBuilder>>(`${this.urlConstantsService.FUNCTION_BUILDERS_URL}/reopen/${id}`, cmd)
      .pipe(map(response => response.data));
  }

  getFunctionBuilderCharges(facilityId: string, date: Date, showAll: boolean): Observable<FunctionBuildersChargesDashboardResponse> {
    const dateCopy = new Date(date);
    const month: number = dateCopy.getMonth() + 1;
    const day: number = dateCopy.getDate();
    const year: number = dateCopy.getFullYear();
    return this.httpClient.get<OperationResponse<FunctionBuildersChargesDashboardResponse>>(`${this.urlConstantsService.FUNCTION_BUILDERS_URL}/treatments?facilityId=${facilityId}&showAllPatients=${showAll}&month=${month}&day=${day}&year=${year}`)
      .pipe(map(response => response.data),
        tap(response => {
        this.fbTreatmentsStore.upsertMany(response.treatments);
        this._treatmentsDirtyCheckCollection = new EntityDirtyCheckPlugin(this.fbTreatmentsQuery);
        this._treatmentsDirtyCheckCollection.setHead();
      }));
  }

  updateFunctionBuilderCharges(facilityId: string, date: Date): Observable<FunctionBuildersChargesDashboardResponse> {
    const dateCopy = new Date(date);
    const cmd: UpdateFunctionBuildersTreatmentItemsCommand = {
      month: dateCopy.getMonth() + 1,
      day: dateCopy.getDate(),
      year: dateCopy.getFullYear(),
      facilityId: facilityId,
      treatments: this.fbTreatmentsQuery.getAll(),
    }

    return this.httpClient.put<OperationResponse<FunctionBuildersChargesDashboardResponse>>(`${this.urlConstantsService.FUNCTION_BUILDERS_URL}/treatments`, cmd)
    .pipe(map(response => response.data),
      tap(response => {
        this._treatmentsDirtyCheckCollection.setHead();
    }));
  }

  getNonTreatmentItems(facilityId: string, date: Date): Observable<FunctionBuilderNonTreatmentItem[]> {
    const dateCopy = new Date(date);
    const month: number = dateCopy.getMonth() + 1;
    const day: number = dateCopy.getDate();
    const year: number = dateCopy.getFullYear();
    return this.httpClient.get<OperationResponse<FunctionBuildersNonTreatmentItemsResponse>>(`${this.urlConstantsService.FUNCTION_BUILDERS_URL}/nontreatments?facilityId=${facilityId}&month=${month}&year=${year}&day=${day}`)
      .pipe(map(response => response.data.nonTreatments));
  }

  addNonTreatmentItem(fbNonTreatmentItem: FunctionBuilderNonTreatmentItem): Observable<FunctionBuilderNonTreatmentItem> {
    const cmd: AddFunctionBuilderNonTreatmentItemCommand = {
      nonTreatmentItem: fbNonTreatmentItem,
    };
    return this.httpClient.post<OperationResponse<FunctionBuilderNonTreatmentItem>>(`${this.urlConstantsService.FUNCTION_BUILDERS_URL}/nontreatments`, cmd)
      .pipe(map(response => response.data));
  }

  updateNonTreatmentItem(fbNonTreatmentItem: FunctionBuilderNonTreatmentItem): Observable<FunctionBuilderNonTreatmentItem> {
    const cmd: UpdateFunctionBuilderNonTreatmentItemCommand = {
      nonTreatmentItem: fbNonTreatmentItem,
    };
    return this.httpClient.put<OperationResponse<FunctionBuilderNonTreatmentItem>>(`${this.urlConstantsService.FUNCTION_BUILDERS_URL}/nontreatments`, cmd)
      .pipe(map(response => response.data));
  }

  deleteFunctionBuilderNonTreatmentItem(functionBuilderNonTreatmentId: string): Observable<boolean> {
    return this.httpClient.delete<OperationResponse<boolean>>(`${this.urlConstantsService.FUNCTION_BUILDERS_URL}/nontreatments/${functionBuilderNonTreatmentId}`)
      .pipe(map(response => response.data));
  }


  // Store Operations

  updateFbProgramMinutes(patientId: string, program: eFunctionBuilderPrograms, minutes: number) {
    this.fbTreatmentsStore.update(patientId, entity => {
      const programIndex = this.getTreatmentItemIndex(entity.treatmentItems, program);

      if (programIndex >= 0) {
        return {
          ...entity,
          treatmentItems: [
            ...entity.treatmentItems.slice(0, programIndex),
            {
              ...entity.treatmentItems[programIndex],
              minutes: minutes,
            },
            ...entity.treatmentItems.slice(programIndex + 1)
          ]
        }
      } else {
        return entity;
      }
    });
  }

  updateFbAudienceType(patientId: string, program: eFunctionBuilderPrograms, audience: number) {
    this.fbTreatmentsStore.update(patientId, entity => {
      const programIndex = this.getTreatmentItemIndex(entity.treatmentItems, program);

      if (programIndex >= 0) {
        return {
          ...entity,
          treatmentItems: [
            ...entity.treatmentItems.slice(0, programIndex),
            {
              ...entity.treatmentItems[programIndex],
              audience: audience,
            },
            ...entity.treatmentItems.slice(programIndex + 1)
          ]
        }
      } else {
        return entity;
      }
    });
  }

  updateFunctionBuilderSelectedNotes(patientId: string, program: eFunctionBuilderPrograms, selectedNotes: string[], note: string) {
    this.fbTreatmentsStore.update(patientId, entity => {
      const programIndex = this.getTreatmentItemIndex(entity.treatmentItems, program);
      if (programIndex >= 0) {
        return {
          ...entity,
          treatmentItems: [
            ...entity.treatmentItems.slice(0, programIndex),
            {
              ...entity.treatmentItems[programIndex],
              selectedNotes: selectedNotes,
              notes: note,
            },
            ...entity.treatmentItems.slice(programIndex + 1)
          ]
        }
      } else {
        return entity;
      }
    });
  }

  updateFunctionBuilderNote(patientId: string, program: eFunctionBuilderPrograms, note: string) {
    this.fbTreatmentsStore.update(patientId, entity => {
      const programIndex = this.getTreatmentItemIndex(entity.treatmentItems, program);
      if (programIndex >= 0) {
        return {
          ...entity,
          treatmentItems: [
            ...entity.treatmentItems.slice(0, programIndex),
            {
              ...entity.treatmentItems[programIndex],
              notes: note,
            },
            ...entity.treatmentItems.slice(programIndex + 1)
          ]
        }
      } else {
        return entity;
      }
    });
  }

  updateFbMissedVisitType(patientId: string, missedVisitType: number) {
    this.fbTreatmentsStore.update(patientId, entity => {
      return {
        ...entity,
        missedVisitItems: {
          ...entity.missedVisitItems,
          missedVisit: missedVisitType,
        }
      }
    });
  }

  updateFbMissedVisitNote(patientId: string, missedVisitNote: string) {
    this.fbTreatmentsStore.update(patientId, entity => {
      return {
        ...entity,
        missedVisitItems: {
          ...entity.missedVisitItems,
          missedVisitNote: missedVisitNote,
        }
      }
    });
  }

  private getTreatmentItemIndex(treatmentItems: FunctionBuilderTreatmentItem[], program: number): number {
    return treatmentItems.findIndex(treatment => treatment.program == program);
  }

}
