import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlConstantsService } from './url-constants.service';
import { FacilityBiller, OperationResponse, PagedResult } from '@app/model';

@Injectable({
  providedIn: 'root'
})
export class FacilityBillersService {

  constructor(private http: HttpClient,
    private urlConstantsService: UrlConstantsService) { }

  getAllFacilityBillers(): Observable<FacilityBiller[]> {
    return this.http.get<PagedResult<FacilityBiller>>(`${this.urlConstantsService.FACILITY_BILLERS_URL}`)
      .pipe(map(response => response.items));
  }

  addFacilityBiller(facilityBiller: FacilityBiller): Observable<FacilityBiller> {
    return this.http.post<OperationResponse<FacilityBiller>>(`${this.urlConstantsService.FACILITY_BILLERS_URL}`, facilityBiller)
      .pipe(map(response => response.data));
  }

  updateFacilityBiller(facilityBiller: FacilityBiller): Observable<FacilityBiller> {
    return this.http.put<OperationResponse<FacilityBiller>>(`${this.urlConstantsService.FACILITY_BILLERS_URL}/${facilityBiller.id}`, facilityBiller)
      .pipe(map(response => response.data));
  }
}
