import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DxDataGridComponent } from '../../../../node_modules/devextreme-angular';

@Component({
    selector: 'tms-grid',
    template:
    `
    <dx-data-grid #tmsDataGrid [dataSource]="dataSource" [keyExpr]="keyExpression" (onSelectionChanged)="onSelectionChanged($event)">
        <dxo-filter-row
            [visible]="allowFiltering" >
        </dxo-filter-row>
        <dxo-search-panel
            [visible]="allowSearching" [width]="240" placeholder="Search..."></dxo-search-panel>
        <dxo-paging
            [enabled]="allowPaging" [pageSize]="pageSize">
        </dxo-paging>
        <dxo-pager
            [showInfo]="true">
        </dxo-pager>
        <dxo-selection
            [mode]="selectionMode" [showCheckBoxesMode]="showCheckBoxesMode" [allowSelectAll]="allowSelectAll">
        </dxo-selection>
    </dx-data-grid>
    `,
})
export class TmsGridComponent {
    @Input() dataSource: any[];
    @Input() keyExpression: string = '';
    @Input() allowFiltering: boolean = false;
    @Input() allowPaging: boolean = false;
    @Input() pageSize: number = 10;
    @Input() allowSearching: boolean = false;
    @Input() selectionMode: string = 'single'; // single or multiple
    @Input() allowSelectAll: boolean = false;
    @Input() showCheckBoxesMode: string = 'always'; // always, onClick, onLongTap, or none

    @Output() selectionChange = new EventEmitter<string>();
    @Output() dataGrid: DxDataGridComponent;

    onSelectionChanged(e) {
        this.selectionChange.emit(e);
    }
}

