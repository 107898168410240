import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of, BehaviorSubject, forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EntityDirtyCheckPlugin, EntityStateHistoryPlugin } from '@datorama/akita';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { PatientWeek, PatientWeekByDiscipline, ScheduleItem, ScheduleRoot, OperationResponse, ReconcilerSummaryEntry,  ReconcilerTherapist, PagedResult, KeyValuePair, SecondaryScheduleResponse, eProgramPlanType, UpdateSecondaryScheduleCommand, UpdateSecondaryScheduleNotesCommand, SecondaryScheduleNotesResponse } from '@app/model';
import { QueryStringUtils } from '@app/utils';
import { 
    PatientPlannerPatientsStore,
    PatientPlannerSummaryStore,
    PatientPlannerPatientsQuery,
    PatientPlannerPatientsState,
    PatientPlannerSummaryQuery,
    PatientPlannerSummaryState,
} from '@app/pages/schedule/store/patient';
import * as moment from 'moment';
import { SecondarySchedulePatientsQuery, SecondarySchedulePatientsStore } from '../stores';

@Injectable({
	providedIn: 'root',
})
export class ScheduleService {
    private dirtyCheckCollection: EntityDirtyCheckPlugin;
    private secondaryStoreDirtyCheck: EntityDirtyCheckPlugin;

    private _notifyToUpdatePatientPlanner$:  BehaviorSubject<ScheduleRoot> = new BehaviorSubject<ScheduleRoot>(null);
    private _patientPlannerLastUpdateTime: Date = null; // tracks last time patient planner was updated from server 

    constructor(private http: HttpClient,
                private pppStore: PatientPlannerPatientsStore,
                private pppQuery: PatientPlannerPatientsQuery,
                private ppsStore: PatientPlannerSummaryStore,
                private ppsQuery: PatientPlannerSummaryQuery,
                private secondaryScheduleStore: SecondarySchedulePatientsStore,
                private secondaryScheduleQuery: SecondarySchedulePatientsQuery,
                private urlConstantsService: UrlConstantsService) {}

    getPatientPlannerById(facilityId: string, month: number = null, day: number = null, year: number = null) {
        this.http.get<ScheduleRoot>(`${this.urlConstantsService.PATIENT_PLANNER_URL}/${facilityId}?${QueryStringUtils.queryStringBuilder({month, day, year})}`)
            .subscribe(response => {
                this.pppStore.set(response.patients);
                this.ppsStore.update({
                    dateRange: response.dateRange,
                    summary: response.summaryRecords,
                    previousSurplusDeficit: response.previousSurplusDeficit,
                    weeklyBudget: response.weeklyBudget,
                });
                this.dirtyCheckCollection = new EntityDirtyCheckPlugin(this.pppQuery);
                this.dirtyCheckCollection.setHead();
                this._notifyToUpdatePatientPlanner$.next(response);
                this._patientPlannerLastUpdateTime = response.serverTime;
            });
    }

    getPatientPlannerUpdateSinceTimestamp(facilityId: string, month: number = null, day: number = null, year: number = null) {
        const modifiedSince = this._patientPlannerLastUpdateTime;
        this.http.get<ScheduleRoot>(`${this.urlConstantsService.PATIENT_PLANNER_URL}/${facilityId}?${QueryStringUtils.queryStringBuilder({month, day, year, modifiedSince})}`)
            .subscribe(response => {
                this.pppStore.upsertMany(response.patients);
                this.dirtyCheckCollection = new EntityDirtyCheckPlugin(this.pppQuery);
                this.dirtyCheckCollection.setHead();
                this._patientPlannerLastUpdateTime = response.serverTime;
            });
    }

    getSecondaryPlannerById(facilityId: string, month: number = null, day: number = null, year: number = null, therapistUserId: string = null): Observable<SecondaryScheduleResponse> {
        return this.http.get<OperationResponse<SecondaryScheduleResponse>>(`${this.urlConstantsService.SCHEDULES_URL}/secondaryplanner/${facilityId}?${QueryStringUtils.queryStringBuilder({month, day, year})}`)
            .pipe(map(response => response.data),
                tap(response => {
                this.secondaryScheduleStore.set(response.patients);
                this.secondaryStoreDirtyCheck = new EntityDirtyCheckPlugin(this.secondaryScheduleQuery);
                this.secondaryStoreDirtyCheck.setHead();
           }));
    }

    updateSecondaryPlanner(date: Date, applyToFutureWeeks: boolean): Observable<SecondaryScheduleResponse> {
        if (this.secondaryStoreDirtyCheck.someDirty()) {
            const patientsToUpdate = this.secondaryScheduleQuery.getAll().filter(patient => this.secondaryStoreDirtyCheck.isDirty(patient.patientId, false));
            const newdate = new Date(date); 
            const cmd: UpdateSecondaryScheduleCommand = {
                month: newdate.getMonth() + 1,
                day: newdate.getDate(),
                year: newdate.getFullYear(),
                patients: patientsToUpdate,
                applyToFutureWeeks: applyToFutureWeeks
            };
            return this.http.put<OperationResponse<SecondaryScheduleResponse>>(`${this.urlConstantsService.SCHEDULES_URL}/secondaryplanner/savebatch`, cmd)
                .pipe(map(response => response.data),
                     tap(response => {
                        // TODO: update patients
                        this.secondaryStoreDirtyCheck.setHead();
                     }));
        } else {
            console.warn('Ancillary Planner store has no patients to update.');
            return of(null);
        }
    }

    updateSecondaryPlannerPatientNotes(patientId: string, notes: string): Observable<string> {
        const cmd: UpdateSecondaryScheduleNotesCommand = {
            patientId: patientId,
            notes: notes,
        };
        return this.http.put<OperationResponse<SecondaryScheduleNotesResponse>>(`${this.urlConstantsService.SCHEDULES_URL}/secondaryplanner/notes`, cmd)
            .pipe(map(response => response.data.notes));
    }

    updateActivePlanner(applyFutureWeeks: boolean): Observable<ScheduleRoot> {
        if (this.dirtyCheckCollection.someDirty()) {
            const updatePlannerRequests = [];
            this.pppQuery.getAll().forEach(ppp => {
                if (this.dirtyCheckCollection.isDirty(ppp.compositeKey, false)) {
                    updatePlannerRequests.push(ppp);
                }
            });
            const modifiedSince = this._patientPlannerLastUpdateTime;
            return this.http.put<OperationResponse<ScheduleRoot>>(`${this.urlConstantsService.PATIENT_PLANNER_URL}/savebatch?${QueryStringUtils.queryStringBuilder({applyFutureWeeks, modifiedSince})}`, updatePlannerRequests)
                .pipe(map(response => response.data),
                      tap(response => {
                        this.pppStore.upsertMany(response.patients);
                        this.dirtyCheckCollection = new EntityDirtyCheckPlugin(this.pppQuery);
                        this.dirtyCheckCollection.setHead();
                        this._patientPlannerLastUpdateTime = response.serverTime;
                      }));
        } else {
            console.warn('Planner store does not have patients to update.');
            return of(null);
        }
    }

    updatePlannerPatient(compositeKey: string, index: number, patientWeekByDiscipline: PatientWeekByDiscipline, isCurrentWeek: boolean) {
        this.pppStore.update(compositeKey, (entity) => {
            return {
                ...entity,
                isCurrentWeek: isCurrentWeek,
                disciplineSchedules: [
                    ...entity.disciplineSchedules.slice(0, index),
                    patientWeekByDiscipline,
                    ...entity.disciplineSchedules.slice(index + 1),
                ],
            }
        });
    }

    getSecondaryPlannerIsDirty(): boolean {
        return this.secondaryStoreDirtyCheck.someDirty();
    }

    getAllPlannerPatients(): Observable<PatientWeek[]> {
        return this.pppQuery.selectAll();
    }

    getPlannerSummary(): Observable<PatientPlannerSummaryState> {
        return this.ppsQuery.select();
    }

    clearPatientPlannerStore(): void {
        this.pppStore.reset();
        this.ppsStore.reset();
        this._notifyToUpdatePatientPlanner$.next(null);
    }

    getPatientPlanner$(): Observable<ScheduleRoot> {
        return this._notifyToUpdatePatientPlanner$.asObservable();
    }

    getPatientScheduleById(id: string) {
        return this.pppQuery.selectEntity(id);
    }

    getPlannerHistory(): EntityStateHistoryPlugin<PatientPlannerPatientsState> {
        return new EntityStateHistoryPlugin<PatientPlannerPatientsState>(this.pppQuery, { maxAge: 50});
    }

    getActivePlannerPatient(): PatientWeek {
        return null
    }

    setActivePlannerPatient(compositeKey: string) {
    }

    getTherapistPlannerById(facilityId: string, month: number = null, day: number = null, year: number = null, therapistUserId: string = null) {
        return this.http.get<ScheduleRoot>(`${this.urlConstantsService.SCHEDULES_URL}/therapistplanner/${facilityId}?${QueryStringUtils.queryStringBuilder({month, day, year, therapistUserId})}`);
    }

    getAll() {
        return this.http.get<ScheduleItem[]>(`${this.urlConstantsService.SCHEDULES_URL}/get`);
    }

    saveScheduleItem(scheduleItem: ScheduleItem) {
        return this.http.post<OperationResponse<ScheduleItem>>(`${this.urlConstantsService.SCHEDULES_URL}/save`, scheduleItem)
            .pipe(map(response => response.data));
    }

    // Reconciler
    getReconcilerSummary(facilityId: string, startDate: Date): Observable<ReconcilerSummaryEntry[]> {
        startDate = moment(startDate).toDate();
        const day = startDate.getDate();
        const month = (startDate.getMonth() + 1);
        const year = startDate.getFullYear();
        return this.http.get<PagedResult<ReconcilerSummaryEntry>>(`${this.urlConstantsService.SCHEDULES_URL}/reconciler/${facilityId}/summary?${QueryStringUtils.queryStringBuilder({month, day, year})}`).pipe(map(response => response.items));
    }

    getReconcilerTherapists(facilityId: string, selectedDate: Date) {
        selectedDate = moment(selectedDate).toDate();
        const day = selectedDate.getDate();
        const month = (selectedDate.getMonth() + 1);
        const year = selectedDate.getFullYear();
        return this.http.get<OperationResponse<ReconcilerTherapist[]>>(`${this.urlConstantsService.SCHEDULES_URL}/reconciler/${facilityId}/therapists?${QueryStringUtils.queryStringBuilder({month, day, year})}`).pipe(map(response => response.data));
    }

    reassignPatientCases(facilityId: string, assignedTherapistId: string, selectedDate: Date, scheduleItemIds: string[]) {
        selectedDate = moment(selectedDate).toDate();
        const day = selectedDate.getDate();
        const month = (selectedDate.getMonth() + 1);
        const year = selectedDate.getFullYear();
        return this.http.post<OperationResponse<any>>(`${this.urlConstantsService.SCHEDULES_URL}/reconciler/${facilityId}/patientCases/${assignedTherapistId}?${QueryStringUtils.queryStringBuilder({month, day, year})}`, scheduleItemIds)
            .pipe(map(response => response.data));
    }

    updateScheduleNotes(episodeId: string, week: PatientWeek) {
        return this.http.put<OperationResponse<string>>(`${this.urlConstantsService.SCHEDULES_URL}/notes/${episodeId}`, week)
            .pipe(map(response => response.data));
    }

    updateScheduleNotesStore(compositeKey: string, notes: string,) {
        this.pppStore.update(compositeKey, (entity) => {
            return {
                ...entity,
                scheduleNotes: notes,
            }
        });
    }

    // secondary planner store operations
    clearSecondaryPlannerStore(): void {
        this.secondaryScheduleStore.reset();
    }

    updateSecondaryPlannerPatientScheduleMinutes(patientId: string, minutes: number, programPlanType: eProgramPlanType): void {
        this.secondaryScheduleStore.update(patientId, entity => {
            if (programPlanType === eProgramPlanType.FunctionBuilder) {
                return {
                    ...entity,
                    functionBuilderSchedule: {
                        ...entity.functionBuilderSchedule,
                        minutes: minutes,
                    }
                }
            } else if (programPlanType === eProgramPlanType.RestorativeNursing) {
                return {
                    ...entity,
                    restorativeNursingProgramSchedule: {
                        ...entity.restorativeNursingProgramSchedule,
                        minutes: minutes,
                    }
                }
            } else {
                return {
                    ...entity,   
                }
            }
        })
    }

    updateSecondaryPlannerPatientScheduleSelectedDay(patientId: string, startDate: Date, isChecked: boolean, programPlanType: eProgramPlanType): void {
        this.secondaryScheduleStore.update(patientId, entity => {
            if (programPlanType === eProgramPlanType.FunctionBuilder) {
                const dayIndex = entity.functionBuilderSchedule.secondarySchedulePatientDays.findIndex(day => day.startDate == startDate);
                if (dayIndex >= 0) {
                    return {
                        ...entity,
                        functionBuilderSchedule: {
                            ...entity.functionBuilderSchedule,
                            secondarySchedulePatientDays: [
                                ...entity.functionBuilderSchedule.secondarySchedulePatientDays.slice(0, dayIndex),
                                {
                                    ...entity.functionBuilderSchedule.secondarySchedulePatientDays[dayIndex],
                                    isChecked: isChecked,
                                },
                                ...entity.functionBuilderSchedule.secondarySchedulePatientDays.slice(dayIndex + 1),
                            ]
                        }
                    }
                }
            } else if (programPlanType === eProgramPlanType.RestorativeNursing) {
                const dayIndex = entity.restorativeNursingProgramSchedule.secondarySchedulePatientDays.findIndex(day => day.startDate == startDate);
                if (dayIndex >= 0) {
                    return {
                        ...entity,
                        restorativeNursingProgramSchedule: {
                            ...entity.restorativeNursingProgramSchedule,
                            secondarySchedulePatientDays: [
                                ...entity.restorativeNursingProgramSchedule.secondarySchedulePatientDays.slice(0, dayIndex),
                                {
                                    ...entity.restorativeNursingProgramSchedule.secondarySchedulePatientDays[dayIndex],
                                    isChecked: isChecked,
                                },
                                ...entity.restorativeNursingProgramSchedule.secondarySchedulePatientDays.slice(dayIndex + 1),
                            ]
                        }
                    }
                }
            }
            
            return {
                ...entity,
            }
        });
    }

    updateSecondaryPlannerPatientNotesStore(patientId: string, notes: string) {
        this.secondaryScheduleStore.update(patientId, entity => {
            return {
                ...entity,
                secondaryScheduleNotes: notes,
            }
        });
        this.secondaryStoreDirtyCheck.setHead();
    }
}
