import { Directive, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, ActivationStart, Router } from '@angular/router';
import { takeUntil, filter } from 'rxjs/operators';
import { OnDestroyComponent } from '../on-destroy/on-destroy.component';
import { CanDeactivateComponent } from '../can-deactivate/can-deactivate.component';
import { Observable } from 'rxjs';

interface TmsRoutingDashboardTab {
  text: string;
  route?: string;
  disabled?: boolean;
  visible?: boolean;
}

@Directive()
export abstract class TmsRoutingDashboardComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
  activeTabIndex: number = 0;
  tabs: TmsRoutingDashboardTab[] = [];

  constructor(protected route: ActivatedRoute,
    protected router: Router,
    protected cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    // get tab data when navigating directly to url
    this.router.events
      .pipe(
        takeUntil(this.onDestroy$),
        filter(event => {
          return event instanceof ActivationStart
        }))
      .subscribe((event: ActivationStart) => {
        this.setActiveTab(event.snapshot.data['tabIndex']);
      });

    this.route.firstChild.data
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(data => {
        if (data['tabIndex'] != null) {
          this.setActiveTab(data['tabIndex']);
        }
      });
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  onTabItemClicked(e) {
    this.router.navigate([`./${e.itemData.route}`], {
      queryParamsHandling: 'preserve',
      relativeTo: this.route,
    });
  }

  setActiveTab(index: number) {
    this.activeTabIndex = index;
    this.cdr.detectChanges();
  }

  canDeactivate(fromHostListener: boolean): boolean | Observable<boolean> {
    return true;
  }
}
