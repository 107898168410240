import { Injectable } from '@angular/core';

const API_VERSION: string = '/api/v1';

@Injectable({
	providedIn: 'root',
})
export class UrlConstantsService {
    constructor() {}

    get ACTIONS_ITEMS_URL(): string { return this.getUrlString('actionitems') }
    get ANNOUNCEMENTS_URL(): string { return this.getUrlString('announcements') }
    get ANNOUNCEMENTS_PUBLIC_URL(): string { return this.getUrlString('public/announcements') }
    get AUDIT_REQUEST_URL(): string { return this.getUrlString('auditrequests') }
    get BATCH_PRINTING_URL(): string { return this.getUrlString('batch-print') }
    get CASES_URL(): string { return this.getUrlString('cases') }
    get CASPER_URL(): string { return this.getUrlString('casper')}
    get CLIENTS_URL(): string { return this.getUrlString('clients') }
    get CHARGES_URL(): string { return this.getUrlString('charges') }
    get CHARGE_CODES_URL(): string { return this.getUrlString('chargecodes') }
    get CLINICAL_DOCS_URL(): string {return this.getUrlString('clinicaldocs') }
    get CLINICAL_DOCUMENTS_URL(): string { return this.getUrlString('documents') }
    get CLOSEOUTS_URL(): string { return this.getUrlString('closeouts')}
    get CORPORATIONS_URL(): string { return this.getUrlString('corporations')}
    get COUNTY_URL(): string { return this.getUrlString('geography/county')}
    get CPT_OVERRIDE_URL(): string { return this.getUrlString('cptoverrides')}
    get CROSSWALKS_URL(): string {return this.getUrlString('crosswalks')};
    get DEFICIT_STRATEGY_URL(): string { return this.getUrlString('deficitstrategy')}
    get DENIAL_NOTICE_URL(): string { return this.getUrlString('denialnotices') }
    get DENIAL_CLAIMS_URL(): string { return this.getUrlString('denialclaims')}
    get DOCUMENTS_URL(): string { return this.getUrlString('documents') }
    get DOCUMENT_CATEGORIES_URL(): string { return this.getUrlString('documentCategories') }
    get ELECTRONIC_CHARTS_URL(): string { return this.getUrlString('electroniccharts')}
    get EMPLOYEES_URL(): string { return this.getUrlString('employees') }
    get EPISODES_URL(): string { return this.getUrlString('episodes') }
    get EXPORTS_URL(): string { return this.getUrlString('exports') }
    get EXPORT_FIELD_OVERRIDE_URL(): string { return this.getUrlString('exportfieldoverride') }
    get EXPORTS_PROFILE_URL(): string { return this.getUrlString('exportsprofile') }
    get EXPORTS_PROFILE_SCHEDULED_RUN_URL(): string { return this.getUrlString('exportprofilescheduledrun') }
    get FACILITIES_URL(): string { return this.getUrlString('facilities') }
    get FACILITY_BILLERS_URL(): string { return this.getUrlString('facilitybillers') }
    get FEATURE_FLAGS_URL(): string { return this.getUrlString('featureflags')}
    get FUNCTION_BUILDERS_URL(): string { return this.getUrlString('functionbuilders')}
    get FUNCTIONAL_STATUS_URL(): string { return this.getUrlString('functionalstatus')}
    get FUNCTIONAL_STATUS_QUESTION_URL(): string { return this.getUrlString('functionalstatusquestion')}
    get GOAL_STATEMENTS_URL(): string { return this.getUrlString('goalstatements')}
    get HOSPITALS_URL(): string { return this.getUrlString('hospitals') }
    get ICDCODES_URL(): string { return this.getUrlString('icdcodes') }
    get INSURANCE_REQUESTS_URL(): string { return this.getUrlString('insurancerequests')}
    get INTEGRATIONS_URL(): string { return this.getUrlString('integrations')}
    get INTERMEDIARIES_URL(): string { return this.getUrlString('intermediaries') }
    get INTERNAL_ALERTS_URL(): string { return this.getUrlString('internalalerts')}
    get INTERVENTIONS_URL(): string { return this.getUrlString('interventions')}
    get INTERVIEWS_URL(): string { return this.getUrlString('interviews') }
    get INVOICES_URL(): string { return this.getUrlString('invoices') }
    get KNOWLEDGE_BASE_URL(): string { return this.getUrlString('knowledgebase') }
    get MED_FEE_SCHEDULE_URL(): string { return this.getUrlString('medfeeschedules')}
    get MDS_UPLOAD_URL(): string { return this.getUrlString('mdsupload')}
    get MORNING_MEETINGS_URL(): string { return this.getUrlString('morningmeetings') }
    get NONCOVERED_COST_URL(): string { return this.getUrlString('noncovered')}
    get NONTREATMENT_URL(): string { return this.getUrlString('nontreatment') }
    get OFFERINGS_URL(): string { return this.getUrlString('offerings') }
    get PATIENT_PLANNER_URL(): string { return `${this.SCHEDULES_URL}/patientplanner` }
    get PATIENTS_URL(): string { return this.getUrlString('patients') }
    get PAYERS_URL(): string { return this.getUrlString('payers') }
    get PAYROLL_URL(): string { return this.getUrlString('payroll') }
    get POWER_BI_REPORTS(): string { return this.getUrlString('powerbireports') }
    get PRACTICES_URL(): string { return this.getUrlString('practices') }
    get PRACTITIONERS_URL(): string { return this.getUrlString('practitioners') }
    get PREAUTHORIZATION_REQUESTS_URL(): string { return this.getUrlString('preauthorizationrequests') }
    get PREMIER_CODING_URL(): string { return this.getUrlString('coding') }
    get QUICK_LINKS_URL(): string { return this.getUrlString('quicklinks') }
    get RATE_TABLES_URL(): string { return this.getUrlString('ratetables') }
    get REASSIGN_THERAPIST_URL(): string { return this.getUrlString('reassigntherapists') }
    get REGIONS_URL(): string { return this.getUrlString('regions') }
    get REPORTS_URL(): string { return this.getUrlString('reports') }
    get REPORT_ITEM_URL(): string { return this.getUrlString('reportitems') }
    get REPORT_SCHEDULE_URL(): string { return this.getUrlString('reportschedule') }
    get RESTORATIVE_NURSING_URL(): string { return this.getUrlString('rnp') }
    get SCANNED_ITEMS_URL(): string { return this.getUrlString('scanneditems')}
    get SCHEDULES_URL(): string { return this.getUrlString('schedules') }
    get SCREENS_URL(): string { return this.getUrlString('screens') }
    get SESSIONS_URL(): string { return this.getUrlString('sessions') }
    get STARTUPS_URL(): string { return this.getUrlString('startups')}
    get STATUS_URL(): string { return this.getUrlString('status') }
    get SUPPORT_TICKETS_URL(): string { return this.getUrlString('support/tickets')}
    get TIMECLOCK_URL(): string { return this.getUrlString('timeclock') }
    get UNLOCK_TREATMENTS_URL(): string { return this.getUrlString('unlocktreatments') }
    get USER_ROLES_URL(): string { return this.getUrlString('userroles') }
    get USERS_URL(): string { return this.getUrlString('users') }
    get WEEKLY_UPDATE_URL(): string { return this.getUrlString('weeklyupdates') }
    get SYSTEM_HEALTH_DASHBOARD_URL(): string { return this.getUrlString('systemhealth/dashboard') }

    private getUrlString(url: string): string {
        return `${API_VERSION}/${url}`;
    }
}
