import { IcdCode } from "@app/model/clinical/IcdCode";
import { NarrativeBuilder } from "@app/model/documentation/NarrativeBuilder";
import { RecertDocument, RecertDurations, RecertInfo } from "@app/model/documentation/clinical-documents/recert";

/**
 * A class that represents validation rules for front-end clinical-document processing.
 */
export class ClinicalDocumentValidator {
  /**
   * Enforces a narrative builder to contain text.
   * @param narrativeBuilder
   * @returns true if validation passes, false otherwise.
   */
  public static isNarrativeBuilderValid(narrativeBuilder?: NarrativeBuilder): boolean {
    return !!(narrativeBuilder?.text);
  }

  /**
   * Enforces a recert-infos narrative builder contains text.
   * @param recertInfo 
   * @returns true if validation passes, false otherwise.
   */
  public static isRecertInfoValid(recertInfo?: RecertInfo): boolean {
    return this.isNarrativeBuilderValid(recertInfo?.narrativeBuilder);
  }

  /**
   * Enforces a recerts durations to contain long-term and short-term durations, a frequency greater than 0,
   * and an on-set date.
   * @param durations 
   * @returns true if validation passes, false otherwise.
   */
  public static isRecertDurationsValid(durations?: RecertDurations): boolean {
    return durations?.longTermDuration != null && durations?.shortTermDuration != null && 
      durations?.frequency > 0 && durations?.onsetDate != null;
  }

  /**
   * Enforces a collection of icd codes to contain at least one icd code.
   * @param icdCodes 
   * @returns true if validation passes, false otherwise.
   */
  public static isRecertIcdCodesValid(icdCodes?: IcdCode[]): boolean {
    return icdCodes?.length > 0;
  }

  /**
   * Enforces a set of validation rules on a recert-document.
   * @see isRecertInfoValid
   * @see isRecertDurationsValid
   * @see isRecertIcdCodesValid
   * @see isNarrativeBuilderValid
   * @param document 
   * @returns true if validation passes, false otherwise.
   */
  public static isRecertDocumentValid(document?: RecertDocument): boolean {
    return document != null
      && this.isRecertInfoValid(document.deficits)
      && this.isRecertInfoValid(document.interventions)
      && this.isRecertDurationsValid(document.durations)
      && this.isRecertIcdCodesValid(document.medicalIcdCodes)
      && this.isRecertIcdCodesValid(document.treatmentIcdCodes)
      && this.isNarrativeBuilderValid(document.precautions)
  }
}