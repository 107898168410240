<dx-select-box displayExpr="text" valueExpr="value" 
              [items]="dataListItems"
              [value]="interviewAnswerField.value"
              [showClearButton]="showClearButton"
              (onValueChanged)="onSelectedValue($event)"
              [disabled]="disabled"
              [dropDownOptions] = "measurementDropDownOptions"
              [wrapItemText]="true">
              <dx-validator>
                <dxi-validation-rule type="custom" message="Value is required" 
                  [validationCallback]="onValidationCallback"
                  [reevaluate]="true">
                </dxi-validation-rule>
              </dx-validator>
</dx-select-box>