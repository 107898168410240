export enum ePreauthorizationRequestStatus {
  All = -1,
  Unspecified = 0,
  FacilityAdminApproval = 1,
  Submitted = 2,
  Pending = 3,
  UnderMedicalReview = 4,
  Approved = 7,
  Denied = 9,
  Closed = 10,
}