import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { OperationResponse, MedFeeSchedule, DocumentRecord } from '@app/model';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { ParallelHasher } from 'ts-md5/dist/parallel_hasher';
import { ErrorMessageUtils } from '@app/utils';
import { DocumentRecordsService } from './document-records.service';

@Injectable({
    providedIn: 'root',
})
export class MedFeeScheduleService {
    constructor(private http: HttpClient,
        private documentRecordsService: DocumentRecordsService,
        private urlConstantsService: UrlConstantsService) { }


    getAllSchedules() {
        return this.http.get<OperationResponse<MedFeeSchedule[]>>(`${this.urlConstantsService.MED_FEE_SCHEDULE_URL}`)
            .pipe(map(response => response.data));
    }

    createSchedule(schedule: MedFeeSchedule) {
        return this.http.post<OperationResponse<MedFeeSchedule>>(`${this.urlConstantsService.MED_FEE_SCHEDULE_URL}`, schedule)
            .pipe(map(response => response.data));
    }

    updateSchedule(schedule: MedFeeSchedule) {
        return this.http.put<OperationResponse<MedFeeSchedule>>(`${this.urlConstantsService.MED_FEE_SCHEDULE_URL}/${schedule.id}`, schedule)
            .pipe(map(response => response.data));
    }

    deleteSchedule(scheduleId: string) {
        return this.http.delete<OperationResponse<MedFeeSchedule>>(`${this.urlConstantsService.MED_FEE_SCHEDULE_URL}/${scheduleId}`);
    }

    getByScheduleId(scheduleId: string) {
        return this.http.get<OperationResponse<MedFeeSchedule>>(`${this.urlConstantsService.MED_FEE_SCHEDULE_URL}/${scheduleId}`)
            .pipe(map(response => response.data));
    }

    uploadScheduleImport(fileName: string, file: any, effectiveDate: Date, percentReduction: number) {
        this.documentRecordsService.uploadMedFeeScheduleImportDocument(fileName, file, effectiveDate, percentReduction);
    }
}