export function fileUploadValidation(params, fileUploaderValue): boolean {
    if (fileUploaderValue && fileUploaderValue.length > 0) {
        const file = fileUploaderValue[0];

        if (file.size > 130023424) {
            params.rule.isValid = false;
            params.rule.message = 'File cannot exceed 125 MB.';
            return false;
        } else {
            params.rule.isValid = true;
            return true;
        }
    } else {
        params.rule.isValid = false;
        params.rule.message = 'File is required.';
        return false;
    }
}