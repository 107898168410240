export enum ChargeCodeType {
    Unspecified = 0,
    ManagementContractItem = 1,
    CptCode = 2,
    HomeHealthCharge = 3,
    RespiratoryTherapyCharge = 4,
    GenericInvoiceItem = 5,
    PatientDrivePaymentModelComponentCoefficient = 6,
    RugLevel = 7,
    DesignationRate = 8
}