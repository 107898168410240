<tms-modal [submitButtonText]="submitButtonText" [cancelButtonText]="cancelButtonText"
    [submitButtonDisabled]="buttonsDisabled" [cancelButtonDisabled]="buttonsDisabled" [title]="title"
    (onSubmit)="onSubmit()" (onCancel)="onCancel()" (onClose)="onClose()">
    <dx-validation-group>
        <div class="interview-question-container" *ngFor="let group of interviewGroupQuestions$ | async; index as i">
            <div class="interview-group-title">{{ group?.name }}</div>
            <div class="interview-question" *ngFor="let question of group?.questions">
                <div class="tms-field" *ngIf="question.isVisible">
                    <div class="tms-field-label">
                        <tms-label [isRequired]="question.validationOptions?.isRequired" [text]="question.questionText">
                        </tms-label>
                    </div>
                    <div class="tms-field-value">
                        <tms-interview-question [groupShortName]="group.shortName" [question]="question"
                            (onInterviewValueChanged)="onAnswerChanged($event)"></tms-interview-question>
                    </div>
                </div>
            </div>
        </div>
    </dx-validation-group>
</tms-modal>