import { 
    PatientGoalsProperties, PrognosticIndicatorsProperties, ReasonForReferralProperties, RehabPotentialProperties
} from '@app/model';

export const PatientGoalConstants = {
    PLOF_OPTION: PatientGoalsProperties.RETURN_PLOF,
    RETURN_HOME_OPTION: PatientGoalsProperties.RETURN_HOME,
    SELF_CARE_OPTION: PatientGoalsProperties.SELF_CARE,
    WALK_SAFELY_OPTION: PatientGoalsProperties.WALK_SAFELY,
    NONVERBAL_OPTION: PatientGoalsProperties.NONVERBAL,
    UNCLEAR_OPTION: PatientGoalsProperties.UNCLEAR,
}

export const PrognosticIndicatorsConstants = {
    AMB_PRIOR_OPTION: PrognosticIndicatorsProperties.AMB_PRIOR,
    AO4_OPTION: PrognosticIndicatorsProperties.AO4,
    DELAY_TO_COMMAND_OPTION: PrognosticIndicatorsProperties.DELAY_TO_COMMAND,
    EVAL_ONLY_NO_SKILLED_OPTION: PrognosticIndicatorsProperties.EVAL_ONLY_NO_SKILLED,
    EVAL_ONLY_NOT_RESPONSIVE_OPTION: PrognosticIndicatorsProperties.EVAL_ONLY_NOT_RESPONSIVE,
    EVAL_ONLY_RECENT_ONSET_OPTION: PrognosticIndicatorsProperties.EVAL_ONLY_RECENT_ONSET,
    FAMILY_SUPPORT_OPTION: PrognosticIndicatorsProperties.FAMILY_SUPPORT,
    FOLLOW_COMMANDS_OPTION: PrognosticIndicatorsProperties.FOLLOW_COMMANDS,
    FOLLOW_ROUTINE_OPTION: PrognosticIndicatorsProperties.FOLLOW_ROUTINE,
    HIGHER_FX_LEVEL_OPTION: PrognosticIndicatorsProperties.HIGHER_FX_LEVEL,
    IMP_MED_COND_OPTION: PrognosticIndicatorsProperties.IMP_MED_COND,
    IMITATE_ROUTINE_OPTION: PrognosticIndicatorsProperties.IMITATE,
    IN_PLOF_OPTION: PrognosticIndicatorsProperties.IN_PLOF,
    MOT_HOME_OPTION: PrognosticIndicatorsProperties.MOT_HOME,
    MOT_RETURN_PLOF: PrognosticIndicatorsProperties.MOT_RETURN_PLOF,
    MOT_TO_PART_OPTION: PrognosticIndicatorsProperties.MOT_TO_PART,
    ORIENTED_TO_OPTION: PrognosticIndicatorsProperties.ORIENTED_TO,
    PREV_THERAPY_OPTION: PrognosticIndicatorsProperties.PREV_THERAPY,
    RESP_CUEING_OPTION: PrognosticIndicatorsProperties.RESP_CUEING,
    RESP_ENV_STIMULI_OPTION: PrognosticIndicatorsProperties.RESP_ENV_STIMULI,
    SELF_MONITOR_OPTION: PrognosticIndicatorsProperties.SELF_MONITOR,
    STABLE_MED_COND_OPTION: PrognosticIndicatorsProperties.STABLE_MED_COND,
    SUPPORT_SIG_OTHER_OPTION: PrognosticIndicatorsProperties.SUPPORT_SIG_OTHER,
}

export const ReasonForReferralConstants = {
    AGE_OPTION: ReasonForReferralProperties.AGE,
    BED_MOB_OPTION: ReasonForReferralProperties.BED_MOB,
    DUE_TO_OPTION: ReasonForReferralProperties.DUE_TO,
    GAIT_OPTION: ReasonForReferralProperties.GAIT,
    GENDER_OPTION: ReasonForReferralProperties.GENDER,
    POSITION_OPTION: ReasonForReferralProperties.POSITION, 
    RECENT_OPTION: ReasonForReferralProperties.REFERRED_FOLLOWING,
    RESULTING_IN_OPTION: ReasonForReferralProperties.RESULT_IN,
    ROM_OPTION: ReasonForReferralProperties.ROM,
    SAFETY_OPTION: ReasonForReferralProperties.SAFETY,
    SKIN_HEALTH_OPTION: ReasonForReferralProperties.SKIN_HEALTH,
    TRANSFER_OPTION: ReasonForReferralProperties.TRANSFER,
    // OT
    BATHING_OPTION: ReasonForReferralProperties.BATHING,
    DRESSING_OPTION: ReasonForReferralProperties.DRESSING,
    FUNC_ADLS_OPTION: ReasonForReferralProperties.FUNC_ADLS,
    FUNC_MOB_OPTION: ReasonForReferralProperties.FUNC_MOB,
    FUNC_ROM_OPTION: ReasonForReferralProperties.FUNC_ROM,
    GROOMING_OPTION: ReasonForReferralProperties.GROOMING,
    HOME_MANAGEMENT_OPTION: ReasonForReferralProperties.HOME_MANAGEMENT,
    HOME_SAFELY_OPTION: ReasonForReferralProperties.HOME_SAFETY,
    POSITIONING_OPTION: ReasonForReferralProperties.POSITIONING,
    SELF_FEED_OPTION: ReasonForReferralProperties.SELF_FEED,
    // ST
    COGNITION_OPTION: ReasonForReferralProperties.COGNITION,
    DIET_TOLERANCE_OPTION: ReasonForReferralProperties.DIET_TOLERANCE,
    COMMUNICATION_OPTION: ReasonForReferralProperties.COMMUNICATION,
    OM_FNX_OPTION: ReasonForReferralProperties.OM_FNX,
    COMPREHENSION_OPTION: ReasonForReferralProperties.COMPREHENSION,
    SAFETY_AWARE_OPTION: ReasonForReferralProperties.SAFETY_AWARE,
    SWALLOW_SAFETY_OPTION: ReasonForReferralProperties.SWALLOW_SAFETY,
    SPEECH_INTELL_OPTION: ReasonForReferralProperties.SPEECH_INTELL,
    SWALLOW_FNX_OPTION: ReasonForReferralProperties.SWALLOW_FNX,
}

export class RehabPotentialConstants {
    static STATED_GOALS_OPTION = RehabPotentialProperties.STATED_GOALS;
}