import { Injectable } from '@angular/core';

interface CachedItem {
    isPersisted: boolean;
    value: any;
}

interface CachedItems {
    [key: string]: CachedItem
}

@Injectable({
    providedIn: 'root'
  })
export class LocalStorageService {
    private cachedItems: CachedItems;

    constructor() {
        this.cachedItems = Object.create(null);
        window.addEventListener('storage', this.handleStorageEvent)
    }

    private handleStorageEvent = (event: StorageEvent): void => {
        console.warn('LocalStorage Event: [", event.key, "]');
        // Update the in-memory cache. Since the event contains the item itself, we can
        // move that item directly into the in-memory cache rather than having to go
        // back to the localStorage for a subsequent read.
        if (event.newValue === null && this.cachedItems[event.key]) {

            delete(this.cachedItems[event.key]);

        } else {

            this.cachedItems[event.key] = JSON.parse( event.newValue );

            console.table( this.cachedItems[ event.key ] );

        }
    }

    public getItem(key: string): any {
        if (key in this.cachedItems) {
            return this.cachedItems[key].value;
        }

        console.warn('Reading from underlying localStorage.');
        // CAUTION: Returning direct reference to cached value.
        const storedValue = JSON.parse(localStorage.getItem(key));

        if (storedValue) {
            this.cachedItems[key] = storedValue;
            return this.cachedItems[key].value
        } else {
            return null;
        }
    }

    /* 
        Sets local item in storage and cache
    */
    public setItem(key: string, value: any, isPersisted: boolean = false): void {
        const cachedItem: CachedItem = {
            value: value,
            isPersisted: isPersisted
        };
        this.cachedItems[key] = cachedItem;
        localStorage.setItem(key, JSON.stringify(cachedItem));
    }

    public clearLocalStorage() {
        // only clear items our app added
        Object.keys(this.cachedItems)
            .filter(key => !this.cachedItems[key].isPersisted)
            .forEach(key => {
                delete this.cachedItems[key];
                localStorage.removeItem(key);
            });
    }

    /* Returns concatenated key string from list of values */
    public getKey(keyValues: string[]) {
        return keyValues.join('_');
    }
}
