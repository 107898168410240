import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { DataListItem, InterviewQuestion, InterviewAnswerField } from '@app/model';

@Component({
    selector: 'tms-question-checkboxlist',
    styleUrls: [],
    template: `
    <dx-list [id]="id" [items]="dataListItems" [showSelectionControls]="true" [(selectedItems)]="selectedItems" [ngClass]="{'horizontal-list':orientation === 'Horizontal'}"
    [selectionMode]="'multiple'" itemTemplate="listItem" (onSelectionChanged)="onSelectionChanged($event)" [disabled]="disabled">
        <div *dxTemplate="let item of 'listItem'" style="white-space: 'normal';">
            {{item.text}}
        </div>
    </dx-list>
  `,
})
export class InterviewQuestionCheckboxListComponent implements OnInit, OnChanges {
    @Input() question: InterviewQuestion;
    @Input() dataListItems: DataListItem[] = [];
    @Input() interviewAnswerField: InterviewAnswerField;
    @Input() id: string;
    @Input() orientation: string = 'Vertical';
    @Input() disabled: boolean = false;
    @Output() onInterviewSelectionChanged: EventEmitter<string[]> = new EventEmitter<string[]>();

    selectedItems: DataListItem[] = [];

    constructor() { }

    ngOnInit() {   
    }

    ngOnChanges(changes: SimpleChanges) {
        // clear value if disabling field 
        if (this.interviewAnswerField && this.interviewAnswerField.values) {
            this.selectedItems = [];
            this.interviewAnswerField.values.forEach(value => {
                const index = this.dataListItems.findIndex(dataitem => dataitem.value === value);
                if (index >= 0) {
                    this.selectedItems.push(this.dataListItems[index]);
                }
            });
        }
    }

    onSelectionChanged($event) {
        this.onInterviewSelectionChanged.emit(this.selectedItems.map(item => item.value));
    }
}
