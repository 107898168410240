export enum eInvoiceAdjustmentStatus {
    /// <summary>
    ///     Line Item created by reconciliation, not yet accepted.
    /// </summary>
    Pending = 0,

    /// <summary>
    ///     Line item created by reconciliation, approved by billing
    /// </summary>
    Approved = 1,

    /// <summary>
    ///     Line item created by reconciliation, rejected by billing.
    /// </summary>
    Rejected = 2
}