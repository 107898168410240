import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { Episode, PagedResult, OperationResponse, EpisodeCategory, MoveCase, MoveCaseRequest, SkilledPayerClassification, EpisodeCategoryValidDates } from '@app/model';
import { PatientsService } from './patients.service';
import { EpisodeInterview } from '@app/model/episode/EpisodeInterview';

@Injectable({
	providedIn: 'root',
})
export class EpisodesService {
    private _moveCase: MoveCase = null;
    private _moveCase$: BehaviorSubject<MoveCase> = new BehaviorSubject<MoveCase>(null);

    get moveCase$(): Observable<MoveCase> {
        return this._moveCase$.asObservable();
    }

    constructor(private http: HttpClient,
                private patientsService: PatientsService, 
                private urlConstantsService: UrlConstantsService) {
    }

    createEpisode(episode: Episode) {
        return this.http.post<OperationResponse<Episode>>(`${this.urlConstantsService.EPISODES_URL}`, episode)
            .pipe(tap(response => this.patientsService.createPatientCareEpisode(response.data.patientId, response)),
                  map(response => response.data));
    }

    updateEpisode(episode: Episode) {
        return this.http.put<OperationResponse<Episode>>(`${this.urlConstantsService.EPISODES_URL}/${episode.id}`, episode)
            .pipe(tap(response => this.patientsService.updatePatientCareEpisode(response.data.patientId, response)),
                  map(response => response.data));
    }

    deleteEpisode(episodeId: string) {
        return this.http.delete<OperationResponse<Episode>>(`${this.urlConstantsService.EPISODES_URL}/delete/${episodeId}`);
    }

    getByPatientId(patientId: string) {
        return this.http.get<PagedResult<Episode>>(`${this.urlConstantsService.EPISODES_URL}?patientId=${patientId}`)
            .pipe(tap(episodePageResult => this.patientsService.updatePatientCareEpisodes(patientId, { isSuccessful: true, data: episodePageResult.items })),
                  map(episodePagedResult => episodePagedResult.items));
    }

    getEpisode(episodeId: string) {
        return this.http.get<Episode>(`${this.urlConstantsService.EPISODES_URL}?episodeId=${episodeId}`)
            .pipe(map(episodePagedResult => episodePagedResult));
    }

    getEpisodeInterviews(episodeId: string) {
        return this.http.get<OperationResponse<EpisodeInterview[]>>(`${this.urlConstantsService.EPISODES_URL}/${episodeId}/interviews`)
        .pipe(map(response => response.data));
    }

    getEpisodeCategoryValidDates(episodeId: string, classification: SkilledPayerClassification, payerStartDate: Date, payerEndDate: Date) {
        return this.http.get<OperationResponse<EpisodeCategoryValidDates>>(`${this.urlConstantsService.EPISODES_URL}/episodecategory/${episodeId}/validdates?classification=${classification}&payerStartDate=${payerStartDate}&payerEndDate=${payerEndDate}`)
        .pipe(map(response => response.data));
    }

    addEpisodeCategoryEntry(episodeId: string, category: EpisodeCategory, isSkilled: boolean = true) {
        return this.http.post<OperationResponse<EpisodeCategory[]>>(`${this.urlConstantsService.EPISODES_URL}/episodecategory/${episodeId}?isSkilled=${isSkilled}`, category)
        .pipe(map(response => response.data));
    }

    updateEpisodeCategoryEntry(episodeId: string, category: EpisodeCategory, isSkilled: boolean = true) {
        return this.http.put<OperationResponse<EpisodeCategory[]>>(`${this.urlConstantsService.EPISODES_URL}/episodecategory/${episodeId}?isSkilled=${isSkilled}`, category)
        .pipe(map(response => response.data));
    }

    deleteEpisodeCategoryEntry(episodeId: string, categoryEntryId: string, isSkilled: boolean = true) {
        return this.http.delete<OperationResponse<EpisodeCategory[]>>(`${this.urlConstantsService.EPISODES_URL}/episodecategory/${episodeId}/${categoryEntryId}?isSkilled=${isSkilled}`)
        .pipe(map(response => response.data));
    }

    setMoveCase(moveCase: MoveCase): void {
        this._moveCase = moveCase;
        this._moveCase$.next(this._moveCase);
    }

    getMoveCase(): MoveCase {
        return this._moveCase;
    }

    moveCaseToNewEpisode(targetEpisodeId: string): Observable<any> {
        const moveCaseRequest: MoveCaseRequest = { sourcePatientCaseId: this._moveCase.caseId, targetPatientEpisodeId: targetEpisodeId };
        return this.http.post<OperationResponse<any>>(`${this.urlConstantsService.EPISODES_URL}/paste`, moveCaseRequest)
            .pipe(map(response => response.data), finalize(() => this.setMoveCase(null)));
    }
}
