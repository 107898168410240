import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { TmsDateBoxService } from './tms-date-box.service';

@Component({
  selector: 'tms-date-box',
  templateUrl: './tms-date-box.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TmsDateBoxComponent implements OnInit {
  @Input() calendarOptions: any = {};
  @Input() dateSerializationFormat: string = 'MM/dd/yy';
  @Input() disabled: boolean = false;
  @Input() disabledDates: Date[] = null; 
  @Input() displayFormat: string = null;
  @Input() id: string;
  @Input() isCustomValidation: boolean = false;
  @Input() isCustomValidationMessage: string = '';
  @Input() isRequired: boolean = false;
  @Input() isRequiredMessage: string = 'Required';
  @Input() max: Date | number | string = null;
  @Input() min: Date | number | string = null;
  @Input() placeholder: string = null;
  @Input() readOnly: boolean = false;
  @Input() reevaluate: boolean = false;
  @Input() showClearButton: boolean = false;
  @Input() type: string = 'date';
  @Input() useMaskBehavior: boolean = false;
  @Input() validationCallback: any = null;
  @Input() value: any = null;
  @Input() width: any = null;
  @Input() ignoreTimezone: boolean = false;
  @Input() validationMessageMode: string = 'auto';
  @Output() onValueChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  private isOpen: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  onKeyDown(e) {
    if (e.event.originalEvent.key === '/') {
        e.component.field().dispatchEvent(new KeyboardEvent("keydown", { key: "ArrowRight" }));
    }
  }

  onDateBoxValueChanged(e) {
    TmsDateBoxService.AssertDateOn(e, this.ignoreTimezone);
    this.valueChange.emit(e.value);
    this.onValueChanged.emit(e);
  }

  onDateBoxOpened = (e) => {
    this.isOpen = true;
  }
  
  onDateBoxClosed = (e) => {
    this.isOpen = false;
  }

  isRequiredValidationCallback = (e) => {
    return this.isOpen || e.value != null;
  }
}
