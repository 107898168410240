<div *hasPermission="canAddQuickLinkItem">
    <dx-button style="float:right;" class="tms-primary-button" text="Add New Link" (onClick)="onAddItemClicked()"></dx-button>
</div>
<tms-document-dashboard *ngIf="items" [canEditItems]="canEditItems$ | async" [documentItems]="items"
    [searchBy]="searchByTerms"
    [showDescription]="true"
    (onDocumentDelete)="onItemDelete($event)"
    (onDocumentEdit)="onItemEdit($event)"
    (onDocumentValueChanged)="onItemValueChanged($event)"
    groupBy="categoryName">
</tms-document-dashboard>
