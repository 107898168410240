import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { PagedResult, OperationResponse, Intermediary } from '@app/model';

@Injectable({
	providedIn: 'root',
})
export class IntermediariesService {
    constructor(private http: HttpClient, private urlConstantsService: UrlConstantsService) {}

    getAll() {
        return this.http.get<PagedResult<Intermediary>>(`${this.urlConstantsService.INTERMEDIARIES_URL}`)
            .pipe(map(pagedResult => pagedResult.items));
    }

    getById(id: string) {
        return this.http.get<Intermediary>(`${this.urlConstantsService.INTERMEDIARIES_URL}/${id}`);
    }

    createIntermediary(intermediary: Intermediary) {
         return this.http.post<OperationResponse<Intermediary>>(`${this.urlConstantsService.INTERMEDIARIES_URL}`, intermediary)
            .pipe(map(response => response.data));
    }

    updateIntermediary(intermediary: Intermediary) {
        return this.http.put<OperationResponse<Intermediary>>(`${this.urlConstantsService.INTERMEDIARIES_URL}/${intermediary.id}`, intermediary)
        .pipe(map(response => response.data));
    }

    deleteIntermediary(intermediaryId: string) {
        return this.http.delete(`${this.urlConstantsService.INTERMEDIARIES_URL}/${intermediaryId}`);
    }
}
