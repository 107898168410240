<tms-modal title="Telehealth Statement Edit" (onSubmit)="onSubmitPressed()" (onCancel)="onClearPressed()" cancelButtonText="Clear" (onClose)="onClose()">
    The patient was seen for a telehealth visit and consented to the visit. During the telehealth visit, the patient was physically located at
    <dx-text-area [autoResizeEnabled]="true" [(value)]="txtPatientLocation" class="telehealth-text-box">
        <dx-validator>
            <dxi-validation-rule type="required" message="Value is required">
            </dxi-validation-rule>
        </dx-validator>
    </dx-text-area>, and the telehealth provider, 
    <dx-text-area [autoResizeEnabled]="true" [(value)]="txtConductingTherapist" class="telehealth-text-box">
        <dx-validator>
            <dxi-validation-rule type="required" message="Value is required">
            </dxi-validation-rule>
        </dx-validator>
    </dx-text-area>, conducting this visit was physically located at
    <dx-text-area [autoResizeEnabled]="true" [(value)]="txtConductingTherapistLocation" class="telehealth-text-box">
        <dx-validator>
            <dxi-validation-rule type="required" message="Value is required">
            </dxi-validation-rule>
        </dx-validator>
    </dx-text-area>. The onsite facilitator, 
    <dx-text-area [autoResizeEnabled]="true" [(value)]="txtAssistant" class="telehealth-text-box">
        <dx-validator>
            <dxi-validation-rule type="required" message="Value is required">
            </dxi-validation-rule>
        </dx-validator>
    </dx-text-area>, was present during the telehealth visit to assist the telehealth provider via secure two-way audio/video using HIPAA-compliant software. This telehealth visit began at 
    <dx-text-area [autoResizeEnabled]="true" [(value)]="txtStartTime" class="telehealth-text-box">
        <dx-validator>
            <dxi-validation-rule type="required" message="Value is required">
            </dxi-validation-rule>
        </dx-validator>
    </dx-text-area> and ended at
    <dx-text-area [autoResizeEnabled]="true" [(value)]="txtEndTime" class="telehealth-text-box">
        <dx-validator>
            <dxi-validation-rule type="required" message="Value is required">
            </dxi-validation-rule>
        </dx-validator>
    </dx-text-area> and was determined to be an appropriate, safe, and effective means of service delivery.
</tms-modal>