export class DisciplineUtils {
    static getDisciplineDisplayData() {
        return [
            {
                id: 0,
                displayName: 'Unspecified',
            }, {
                id: 1,
                displayName: 'Physical Therapy',
            }, {
                id: 2,
                displayName: 'Occupational Therapy',
            }, {
                id: 3,
                displayName: 'Speech Therapy',
            },
        ];
    }

    static getReferralResolutionDisplayData() {
        return [
            {
                id: 0,
                displayName: 'Unspecified',
            },
            {
                id: 1,
                displayName: 'Evaluated',
            },
            {
                id: 2,
                displayName: 'Approved',
            },
            {
                id: 3,
                displayName: 'Pending',
            },
            {
                id: 4,
                displayName: 'No Go',
            },
        ]
    }

    static getReferralResolutionSubtypeData() {
        return [
            {
                id: 0,
                displayName: 'Unspecified',
            },
            {
                id: 1,
                displayName: 'Adult Medicaid',
            },
            {
                id: 2,
                displayName: 'Cannot Afford',
            },
            {
                id: 3,
                displayName: 'Distance',
            },
            {
                id: 4,
                displayName: 'Does Not Want',
            },
            {
                id: 5,
                displayName: 'Dr/Insurance Recalled RX',
            },
            {
                id: 6,
                displayName: 'Going Elsewhere',
            },
            {
                id: 7,
                displayName: 'Home Health',
            },
            {
                id: 8,
                displayName: 'Insurance No Go',
            },
            {
                id: 9,
                displayName: 'Medical Issues',
            },
            {
                id: 10,
                displayName: 'Unable to Reach',
            },
            {
                id: 11,
                displayName: 'Other No Go',
            },
        ];
    }
}
