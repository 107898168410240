import { RouteConstants } from "./route.constants";

export const EVALUATION_TABS: string[] = [
    RouteConstants.PAT_CLINICAL_DOC_EVAL_INFO,
    RouteConstants.PAT_CLINCIAL_DOC_LEGACY,
    RouteConstants.PAT_CLINICAL_DOC_MODULES,
    RouteConstants.PAT_CLINICAL_DOC_WEEKLY_UPDATE,
    RouteConstants.PAT_CLINICAL_DOC_OTHER_NOTES,
    RouteConstants.PAT_CLINICAL_DOC_OUTCOMES,
    RouteConstants.PAT_CLINICAL_DOC_ST_OUTCOMES,
    RouteConstants.PAT_CLINICAL_DOC_PLANNED_DC,
    RouteConstants.PAT_CLINICAL_DOC_COMPLEXITY,
    RouteConstants.PAT_CLINICAL_DOC_POC,
    RouteConstants.PAT_CLINICAL_DOC_FINALIZE,
  ];
  
export const RECERT_TABS: string[] = [
    RouteConstants.PAT_CLINICAL_DOC_RECERT_INFO,
    RouteConstants.PAT_CLINCIAL_DOC_LEGACY,
    RouteConstants.PAT_CLINICAL_DOC_MODULES,
    RouteConstants.PAT_CLINICAL_DOC_WEEKLY_UPDATE,
    RouteConstants.PAT_CLINICAL_DOC_OTHER_NOTES,
    RouteConstants.PAT_CLINICAL_DOC_EOP,
    RouteConstants.PAT_CLINICAL_DOC_POC,
    RouteConstants.PAT_CLINICAL_DOC_FINALIZE,
  ];
  
export const PROGRESS_NOTE_TABS: string[] = [
    RouteConstants.PAT_CLINCIAL_DOC_LEGACY,
    RouteConstants.PAT_CLINICAL_DOC_MODULES,
    RouteConstants.PAT_CLINICAL_DOC_WEEKLY_UPDATE,
    RouteConstants.PAT_CLINICAL_DOC_DAILY_NOTES,
    RouteConstants.PAT_CLINICAL_DOC_OTHER_NOTES,
    RouteConstants.PAT_CLINICAL_DOC_EOP,
    RouteConstants.PAT_CLINICAL_DOC_FINALIZE,
  ];
  
export const DISCHARGE_TABS: string[] = [
    RouteConstants.PAT_CLINICAL_DOC_DC_INFO,
    RouteConstants.PAT_CLINCIAL_DOC_LEGACY,
    RouteConstants.PAT_CLINICAL_DOC_MODULES,
    RouteConstants.PAT_CLINICAL_DOC_WEEKLY_UPDATE,
    RouteConstants.PAT_CLINICAL_DOC_DAILY_NOTES,
    RouteConstants.PAT_CLINICAL_DOC_OTHER_NOTES,
    RouteConstants.PAT_CLINICAL_DOC_EOP,
    RouteConstants.PAT_CLINICAL_DOC_OUTCOMES,
    RouteConstants.PAT_CLINICAL_DOC_ST_OUTCOMES,
    RouteConstants.PAT_CLINICAL_DOC_FINALIZE,
  ];