<div class="row">
    <div class="col-12 feed-header">
        Activity Feed
    </div>
</div>
<hr>
<div class="row">
    <div class="col-12">
        <table class="feed-grid">
            <ng-container *ngFor="let feedItem of feedItems">
                <tr class="item-row">
                    <td class="feed-item-text">
                        {{ feedItem.item }}
                    </td>
                    <td class="badge-item-cell">
                        <span *ngIf="!feedItem.noDueDate">
                            <span *ngIf="feedItem.isDueToday" class="badge tasks-badge task-due-today">Due Today</span>
                            <span *ngIf="feedItem.isPastDue"
                                class="badge tasks-badge task-past-due">{{ feedItem.pastDueDays }} Days Past</span>
                            <span *ngIf="feedItem.isDueFuture" class="badge tasks-badge task-due-future">Due in
                                {{ feedItem.futureDueDays }} Days</span>
                        </span>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
</div>