import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlConstantsService } from './url-constants.service';
import { DeficitStrategyPill, DeficitStrategy, OperationResponse, PagedResult, DeficitStatement } from '@app/model';

@Injectable({
  providedIn: 'root'
})
export class DeficitStrategiesService {

  constructor(private httpClient: HttpClient,
    private urlConstantsService: UrlConstantsService) { }

    getDeficitStrategies(): Observable<DeficitStrategy[]> {
      return this.httpClient.get<PagedResult<DeficitStrategy>>(`${this.urlConstantsService.DEFICIT_STRATEGY_URL}`)
        .pipe(map(response => response.items));
    }

    addDeficitStrategy(deficitStrategy: DeficitStrategy): Observable<DeficitStrategy> {
      return this.httpClient.post<OperationResponse<DeficitStrategy>>(`${this.urlConstantsService.DEFICIT_STRATEGY_URL}`, deficitStrategy)
        .pipe(map(response => response.data));
    }

    deleteDeficitStrategy(id: string)  {
      return this.httpClient.delete<OperationResponse<void>>(`${this.urlConstantsService.DEFICIT_STRATEGY_URL}/${id}`);
    }

    editDeficitStrategy(deficitStrategy: DeficitStrategy): Observable<DeficitStrategy> {
      return this.httpClient.put<OperationResponse<DeficitStrategy>>(`${this.urlConstantsService.DEFICIT_STRATEGY_URL}/${deficitStrategy.id}`, deficitStrategy)
        .pipe(map(response => response.data));
    }

    addDeficitStrategyPill(deficitStrategyId: string, pill: DeficitStrategyPill): Observable<DeficitStrategy> {
      return this.httpClient.post<OperationResponse<DeficitStrategy>>(`${this.urlConstantsService.DEFICIT_STRATEGY_URL}/${deficitStrategyId}/pill`, pill)
        .pipe(map(response => response.data));
    }

    editDeficitStrategyPill(deficitStrategyId: string, pill: DeficitStrategyPill): Observable<DeficitStrategy> {
      return this.httpClient.put<OperationResponse<DeficitStrategy>>(`${this.urlConstantsService.DEFICIT_STRATEGY_URL}/${deficitStrategyId}/pill`, pill)
        .pipe(map(response => response.data));
    }

    deleteDeficitStratgyPill(deficitStratgyId: string, pillId: string): Observable<DeficitStrategy> {
      return this.httpClient.delete<OperationResponse<DeficitStrategy>>(`${this.urlConstantsService.DEFICIT_STRATEGY_URL}/${deficitStratgyId}/pill/${pillId}`)
        .pipe(map(response => response.data));
    }

    addDeficitStrategyPillStatement(deficitStrategyId: string, pillId: string, statement: DeficitStatement): Observable<DeficitStrategy> {
      return this.httpClient.post<OperationResponse<DeficitStrategy>>(`${this.urlConstantsService.DEFICIT_STRATEGY_URL}/${deficitStrategyId}/pill/${pillId}/statement`, statement)
        .pipe(map(response => response.data));
    }

    editDeficitStrategyPillStatement(deficitStrategyId: string, pillId: string, statement: DeficitStatement): Observable<DeficitStrategy> {
      return this.httpClient.put<OperationResponse<DeficitStrategy>>(`${this.urlConstantsService.DEFICIT_STRATEGY_URL}/${deficitStrategyId}/pill/${pillId}/statement`, statement)
        .pipe(map(response => response.data));
    }

    deleteDeficitStratgyPillStatement(deficitStratgyId: string, pillId: string, statementId: string): Observable<DeficitStrategy> {
      return this.httpClient.delete<OperationResponse<DeficitStrategy>>(`${this.urlConstantsService.DEFICIT_STRATEGY_URL}/${deficitStratgyId}/pill/${pillId}/statement/${statementId}`)
        .pipe(map(response => response.data));
    }
}
