    /// <summary>
    ///     Indicates how to evaluate the collection of InternalAlertConditions within an InternalAlert,
    ///     whether all conditions must pass or any one individual condition.
    /// </summary>
    
    export enum eInternalAlertConditionMatchType {
        /// <summary>
        ///     The Internal Alert rule is considered met if all of the conditions are met
        /// </summary>
        MatchAny = 0,

        /// <summary>
        ///     The Internal Alert is considered met if any of the conditions are met
        /// </summary>
        MatchAll = 1
    }