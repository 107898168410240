import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { CacheManService, DataListConstants, FacilitiesService } from '@app/core';
import { CrosswalksService } from '@app/core/services/crosswalks.service';
import { DataListItem, IcdCode, eDiscipline } from '@app/model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';

@Component({
  selector: 'supporting-codes-modal',
  templateUrl: './supporting-codes-modal.component.html',
  styleUrls: ['./supporting-codes-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupportingCodesModalComponent implements OnInit {
  @Input() alreadySelectedIcdCodes: IcdCode[]=[];
  @Input() selectedDiscipline: eDiscipline = eDiscipline.PhysicalTherapy;
  alreadySelectedIcdCodeStrings: string[]=[];
  selectedIcdCodes: string[] = [];
  
  disciplines: DataListItem[];
  
  cptList: string[];
  icdCodes: IcdCode[];
  modalTitle: string = 'Add Supporting Codes';
  currentCode: string;
  facilityId: string;
  constructor(private activeModal: NgbActiveModal,
    private cacheManService: CacheManService,
    private crosswalksService: CrosswalksService,
    private facilitiesService: FacilitiesService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.facilityId = this.facilitiesService.activeFacilityId;
    this.alreadySelectedIcdCodes.forEach(code => {
      this.alreadySelectedIcdCodeStrings.push(code.code)
    });
    this.cacheManService.getDataListItems$(DataListConstants.DISCIPLINES)
                        .pipe(map(dataItemList => dataItemList.filter(item => +item.value >= eDiscipline.PhysicalTherapy && +item.value <= eDiscipline.SpeechTherapy)))
                        .subscribe(disciplines => {
                            this.disciplines = disciplines;
                        });
    this.crosswalksService.getAllCptsByDiscipline(this.facilityId, this.selectedDiscipline)
                          .subscribe(cpts => {
                            this.cptList = cpts;
                            this.cdr.detectChanges();
                          });
  }

  onDoneButtonPressed(){
    this.activeModal.close(this.selectedIcdCodes)
  }

  onToolbarPreparing(e) {
    let toolbarItems = e.toolbarOptions.items;

    toolbarItems.forEach(item=>{
      if(item.name === "searchPanel"){
        item.location = "before";
      }
    });
  }

  onRowPrepared(e){
    if(e.rowType === "data"){
      if(e.rowIndex % 2 == 1){
        e.rowElement.style["backgroundColor"] = "#EDF1F7";
      }
      
    }
}

  onClose() {
    this.activeModal.dismiss();
  }

  onCptCodeSelected(e){
    this.getAllIcdCodesForCptCode(e.value)
  }

  getAllIcdCodesForCptCode(cptCode){
    this.crosswalksService.getAllIcdsByCptAndDiscipline(this.facilityId, this.selectedDiscipline, cptCode)
    .subscribe(icds => {
      this.currentCode = cptCode;
      this.icdCodes = icds.filter(i=> !this.selectedIcdCodes.includes(i.code) && !this.alreadySelectedIcdCodeStrings.includes(i.code));
      this.cdr.detectChanges();
    });
  }

  addIcdCodeButtonClicked = (e) => {
    this.selectedIcdCodes.push(e.code);
    this.getAllIcdCodesForCptCode(this.currentCode);
  }
}
