export enum eSignedDocumentStatus
{
    Unspecified = 0,

    /// <summary>
    ///     The default status of not being signed
    /// </summary>
    Unsigned = 1,

    /// <summary>
    ///     Document has been signed, but has not yet been transitioned to the executed status.  Perhaps waiting for more eyes on the document
    /// </summary>
    Signed = 2,

    /// <summary>
    ///     Document has been signed and more individuals need to sign until this document can be executed, likely due to role/compliance
    /// </summary>
    SignedPendingMore = 3,

    SignedPendingExternal = 4,

    /// <summary>
    ///     Document has been executed
    /// </summary>
    Executed = 10
}