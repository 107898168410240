import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { DocumentRecordsService } from './document-records.service';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { DenialClaimQuery, DenialClaimStore } from '../stores';
import { OperationResponse, DenialClaim, DenialClaimStage, PagedResult, DenialClaimSlim,
    DenialClaimNote, DenialClaimPayment, Dictionary, DocumentRecord, DenialPacketCreationRequest,
    PdfPacketModel, DenialLiabilityEstimationResponse, DenialReportParameters, GeneratedReportResponse, DenialClaimDocumentationTabRecord, DenialClaimPrepareDocItem, DenialClaimDocGridEvent, eDiscipline } from '@app/model';
import { DocumentCategoryConstants } from '..';

export class DenialClaimProperties {
    static CLAIM_INFO: string = 'claimInfo';
    static DOCUMENTATION: string = 'documentation';
    static STAGES: string = 'stages';
    static NOTES: string = 'notes';
    static POST_PAYMENT: string = 'postPayment';
    static LIABILITY: string = 'liability';
}

class DenialPrepareDocItemConstants {
    static PT: string = 'PT';
    static OT: string = 'OT';
    static ST: string = 'ST';
    static OTHER: string = 'OTHER';
    static TX_GRID_A: string = 'TxGridAByPatient';
    static TX_GRID_B: string = 'TxGridBByPatient';
    static CMR: string = 'cmr';
}

@Injectable({
    providedIn: 'root',
})
export class DenialClaimService {
    constructor(private http: HttpClient,
        private denialClaimsQuery: DenialClaimQuery,
        private denialClaimsStore: DenialClaimStore,
        private documentRecordsService: DocumentRecordsService,
        private urlConstantsService: UrlConstantsService) { }

    private _prepareDocItems: Dictionary<DenialClaimPrepareDocItem[]> = {}; // tracks order of selected documents

    get prepareDocItems(): DenialClaimPrepareDocItem[] {
        return Object.values(this._prepareDocItems).reduce((acc, curr) => acc.concat(curr), []);
    }

    activeDenialClaim$(): Observable<DenialClaim>;
    activeDenialClaim$(property: string): Observable<any>;
    activeDenialClaim$<T>(property: string): Observable<T>;
    activeDenialClaim$<T>(property?: string): Observable<DenialClaim> | Observable<T> {
        if (property) {
            return this.denialClaimsQuery.selectActive(entity => entity[property]);
        } else {
            return this.denialClaimsQuery.selectActive();
        }
    }

    getDenials(): Observable<DenialClaimSlim[]> {
        return this.http.get<PagedResult<DenialClaimSlim>>(`${this.urlConstantsService.DENIAL_CLAIMS_URL}`)
            .pipe(map(response => response.items));
    }

    addDenialClaim(denialClaim: DenialClaim, setAsActive: boolean): Observable<DenialClaim> {
        return this.http.post<OperationResponse<DenialClaim>>(`${this.urlConstantsService.DENIAL_CLAIMS_URL}`, denialClaim)
            .pipe(map(response => response.data),
                tap(response => {
                    if (setAsActive) {
                        this.denialClaimsStore.upsert(response.id, response);
                        this.denialClaimsStore.setActive(response.id);
                        this.setUpPrepareDocItems();
                    }
                }));
    }

    addDenialClaimNote(denialClaimNote: DenialClaimNote): void {
        this.denialClaimsStore.updateActive(entity => {
            return {
                ...entity,
                notes: [
                    ...entity.notes,
                    denialClaimNote,
                ]
            }
        });
    }

    addDenialClaimPayment(denialClaimPayment: DenialClaimPayment): void {
        this.denialClaimsStore.updateActive(entity => {
            const payments = entity.postPayment ? entity.postPayment.payments : [];
            return {
                ...entity,
                postPayment: {
                    ...entity.postPayment,
                    payments: [
                        ...payments,
                        denialClaimPayment,
                    ]
                }
            }
        });
    }

    editDenialClaimPayment(denialClaimPayment: DenialClaimPayment): void {
        this.denialClaimsStore.updateActive(entity => {
            const index = entity.postPayment.payments.findIndex(payment => payment.id == denialClaimPayment.id);
            if (index >= 0) {
                return {
                    ...entity,
                    postPayment: {
                        ...entity.postPayment,
                        payments: [
                            ...entity.postPayment.payments.slice(0, index),
                            denialClaimPayment,
                            ...entity.postPayment.payments.slice(index + 1),
                        ]
                    }
                }
            }
        });
    }

    deleteDenialClaim(denialClaimId: string): Observable<void> {
        return this.http.delete<void>(`${this.urlConstantsService.DENIAL_CLAIMS_URL}/${denialClaimId}`);
    }

    deleteDenialClaimNote(denialClaimNote: DenialClaimNote): void {
        this.denialClaimsStore.updateActive(entity => {
            const index = entity.notes.findIndex(note => note.id == denialClaimNote.id);
            if (index >= 0) {
                return {
                    ...entity,
                    notes: [
                        ...entity.notes.slice(0, index),
                        ...entity.notes.slice(index + 1),
                    ]
                }
            } else {
                return entity;
            }
        })
    }

    deleteDenialClaimPayment(denialClaimPayment: DenialClaimPayment): void {
        this.denialClaimsStore.updateActive(entity => {
            const index = entity.postPayment.payments.findIndex(payment => payment.id == denialClaimPayment.id);
            if (index >= 0) {
                return {
                    ...entity,
                    postPayment: {
                        ...entity.postPayment,
                        payments: [
                            ...entity.postPayment.payments.slice(0, index),
                            ...entity.postPayment.payments.slice(index + 1),
                        ]
                    }
                }
            } else {
                return entity;
            }
        })
    }

    getDenialClaimById(id: string): Observable<DenialClaim> {
        return this.http.get<DenialClaim>(`${this.urlConstantsService.DENIAL_CLAIMS_URL}/${id}`)
            .pipe(tap(response => {
                this.denialClaimsStore.upsert(id, response);
                this.denialClaimsStore.setActive(id);
                this.setUpPrepareDocItems();
            }));
    }

    updateDenialClaim(): Observable<DenialClaim> {
        const activeDenialClaim = this.denialClaimsQuery.getActive();
        if (activeDenialClaim) {
            return this.http.put<OperationResponse<DenialClaim>>(`${this.urlConstantsService.DENIAL_CLAIMS_URL}/${activeDenialClaim.id}`, activeDenialClaim)
                .pipe(map(response => response.data),
                    tap(denialClaim => {
                        this.denialClaimsStore.update(denialClaim.id, entity => {
                            return {
                                ...entity,
                                claimInfo: denialClaim.claimInfo,
                                stages: denialClaim.stages,
                                liability: denialClaim.liability,
                            }
                        });
                    }));
        } else {
            return of(null);
        }
    }

    updateActiveDenialClaim(denialClaim: DenialClaim): void {
        this.denialClaimsStore.updateActive(entity => {
            return {
                ...denialClaim,
            }
        });
    }

    updateActiveDenialClaimByProperty(property: string, object: any) {
        this.denialClaimsStore.updateActive(entity => {
            return {
                ...entity,
                [property]: object,
            }
        });
    }

    updateActiveDenialClaimStage(denialClaimStage: DenialClaimStage) {
        this.denialClaimsStore.updateActive(entity => {
            const index = entity.stages.findIndex(stage => stage.denialStage == denialClaimStage.denialStage);
            return {
                ...entity,
                stages: [
                    ...entity.stages.slice(0, index),
                    denialClaimStage,
                    ...entity.stages.slice(index + 1),
                ]
            }
        })
    }

    private setUpPrepareDocItems() {
        this._prepareDocItems = {
            [DocumentCategoryConstants.INTERMEDIARIES]: [],
            [DenialPrepareDocItemConstants.PT]: [],
            [DenialPrepareDocItemConstants.OT]: [],
            [DenialPrepareDocItemConstants.ST]: [],
            [DocumentCategoryConstants.ECHARTS]: [],
            [DenialPrepareDocItemConstants.TX_GRID_A]: [], 
            [DenialPrepareDocItemConstants.TX_GRID_B]: [],
            [DenialPrepareDocItemConstants.CMR]: [], 
            [DenialPrepareDocItemConstants.OTHER]: [],
        }
    }

    private removeDocumentRecordIdsFromPrepareDocItems(deselectedDocumentRecordIds: string[], useDocumentRecordProperty: boolean) {
        const property = useDocumentRecordProperty ? 'documentRecordId' : 'reportShortName';
        deselectedDocumentRecordIds.forEach(deselectedRecordId => {
            Object.keys(this._prepareDocItems).forEach(documentRecordCategoryKey => {
                const prepareDocIndex = this._prepareDocItems[documentRecordCategoryKey].findIndex(p => p[property] === deselectedRecordId);
                if (prepareDocIndex >= 0) {
                    this._prepareDocItems[documentRecordCategoryKey] = [
                        ...this._prepareDocItems[documentRecordCategoryKey].slice(0, prepareDocIndex),
                        ...this._prepareDocItems[documentRecordCategoryKey].slice(prepareDocIndex + 1)
                    ];
                }
            })
        });
    }

    updateActiveDenialClaimSelectedDocs(documentGridEvent: DenialClaimDocGridEvent) {
        this.denialClaimsStore.updateActive(entity => {
            // TODO: update with different property other than text??
            const index = entity.documentation.tabs.findIndex(tab => tab.text == documentGridEvent.text);
            if (index >= 0) {
                const documentRecordTab = { ...entity.documentation.tabs[index] };
    
                let isSelected = null;
                if (documentGridEvent.deselectedDocumentRecordIds) {
                    isSelected = false;
                    this.removeDocumentRecordIdsFromPrepareDocItems(documentGridEvent.deselectedDocumentRecordIds, true);
                }

                if (documentGridEvent.selectedDocumentRecordIds) {
                    isSelected = true;
                    documentGridEvent.selectedDocumentRecordIds.forEach(selectedRecordId => {
                        const documentRecord = documentRecordTab.documents.find(p => p.documentRecordId === selectedRecordId);
                        const prepareDocItem: DenialClaimPrepareDocItem = {
                            name: documentRecord?.name,
                            documentRecordId: documentRecord?.documentRecordId,
                            documentCategoryName: documentRecord?.documentCategoryName,
                            discipline: documentRecord?.discipline,
                        };
                        if (documentRecord.documentCategoryShortName === DocumentCategoryConstants.INTERMEDIARIES) {
                            this._prepareDocItems[DocumentCategoryConstants.INTERMEDIARIES].push(prepareDocItem);
                        } else if (documentRecord.documentCategoryShortName === DocumentCategoryConstants.ECHARTS) {
                            this._prepareDocItems[DocumentCategoryConstants.ECHARTS].push(prepareDocItem);
                        } else if (documentRecord.documentCategoryShortName === DocumentCategoryConstants.CLINICAL_DOCUMENTS) {
                            switch(documentRecord.discipline) {
                                case eDiscipline.PhysicalTherapy:
                                    this._prepareDocItems[DenialPrepareDocItemConstants.PT].push(prepareDocItem);
                                    break;
                                case eDiscipline.OccupationalTherapy:
                                    this._prepareDocItems[DenialPrepareDocItemConstants.OT].push(prepareDocItem);
                                    break;
                                case eDiscipline.SpeechTherapy:
                                    this._prepareDocItems[DenialPrepareDocItemConstants.ST].push(prepareDocItem);
                                    break;
                            }
                        } else {
                            // fall through docs
                            this._prepareDocItems[DenialPrepareDocItemConstants.OTHER].push(prepareDocItem);
                        }
                    })
                }

                const documentRecordIds = documentGridEvent.deselectedDocumentRecordIds != null ? documentGridEvent.deselectedDocumentRecordIds : documentGridEvent.selectedDocumentRecordIds;
                documentRecordTab.documents = documentRecordTab.documents.map(docRecord => {
                    return {
                        ...docRecord,
                        isSelected: documentRecordIds.findIndex(id => id === docRecord.documentRecordId) >= 0 ? isSelected : docRecord.isSelected,
                    };
                });


                return {
                    ...entity,
                    documentation: {
                        ...entity.documentation,
                        tabs: [
                            ...entity.documentation.tabs.slice(0, index),
                            documentRecordTab,
                            ...entity.documentation.tabs.slice(index + 1),
                        ],
                    }
                }
            }
        });
    }

    updateActiveDenialClaimSelectedReports(documentGridEvent: DenialClaimDocGridEvent) {
        this.denialClaimsStore.updateActive(entity => {
            // TODO: update with different property other than text??
            const index = entity.documentation.reportTabs.findIndex(tab => tab.text == documentGridEvent.text);
            if (index >= 0) {
                const documentReportTab = { ...entity.documentation.reportTabs[index] };
                let isSelected = null;

                // deselect records
                if (documentGridEvent.deselectedDocumentRecordIds) {
                    isSelected = false;
                    this.removeDocumentRecordIdsFromPrepareDocItems(documentGridEvent.deselectedDocumentRecordIds, false);
                }

                // select records
                if (documentGridEvent.selectedDocumentRecordIds) {
                    isSelected = true;
                    documentGridEvent.selectedDocumentRecordIds.forEach(selectedRecordId => {
                        const documentRecord = documentReportTab.documents.find(p => p.reportShortName === selectedRecordId);
                        const prepareDocItem: DenialClaimPrepareDocItem = {
                            name: documentRecord?.name,
                            reportShortName: documentRecord?.reportShortName,
                            documentCategoryName: 'Report',
                            discipline: documentRecord?.discipline,
                            reportRequest: documentRecord.reportRequest,
                        };
                        if (documentRecord.reportCategoryShortName === DenialPrepareDocItemConstants.TX_GRID_A) {
                            this._prepareDocItems[DenialPrepareDocItemConstants.TX_GRID_A].push(prepareDocItem);
                        } else if (documentRecord.reportCategoryShortName === DenialPrepareDocItemConstants.TX_GRID_B) {
                            this._prepareDocItems[DenialPrepareDocItemConstants.TX_GRID_B].push(prepareDocItem);
                        } else if (documentRecord.reportCategoryShortName === DenialPrepareDocItemConstants.CMR) {
                                this._prepareDocItems[DenialPrepareDocItemConstants.CMR].push(prepareDocItem);
                        } else {
                            // fall through docs
                            this._prepareDocItems[DenialPrepareDocItemConstants.OTHER].push(prepareDocItem);
                        }
                    })
                }

                const documentRecordIds = documentGridEvent.deselectedDocumentRecordIds != null ? documentGridEvent.deselectedDocumentRecordIds : documentGridEvent.selectedDocumentRecordIds;
                documentReportTab.documents = documentReportTab.documents.map(docRecord => {
                    return {
                        ...docRecord,
                        isSelected: documentRecordIds.findIndex(id => id === docRecord.reportShortName) >= 0 ? isSelected : docRecord.isSelected,
                    };
                });

                return {
                    ...entity,
                    documentation: {
                        ...entity.documentation,
                        reportTabs: [
                            ...entity.documentation.reportTabs.slice(0, index),
                            documentReportTab,
                            ...entity.documentation.reportTabs.slice(index + 1),
                        ],
                    }
                }
            }
        });
    }

    uploadDenialClaimDoc(fileName: string, documentCategoryId: string, documentSubCategory: string, documentDate: Date, facilityId: string, file: any): Observable<DocumentRecord> {
        const activeDenialClaim = this.denialClaimsQuery.getActive();
        if (activeDenialClaim && activeDenialClaim.claimInfo && activeDenialClaim.claimInfo.patientId) {
            return this.documentRecordsService.uploadDenialClaimDocument(activeDenialClaim.claimInfo.patientId, activeDenialClaim.id, fileName, documentCategoryId, documentSubCategory, documentDate, facilityId, file);
        } else {
            return null;
        }
    }

    deleteDenialClaimDoc(documentRecordId: string) {
        const activeDenialClaim = this.denialClaimsQuery.getActive();
        if (activeDenialClaim && activeDenialClaim.claimInfo && activeDenialClaim.claimInfo.patientId) {
           return this.documentRecordsService.deleteDocument(documentRecordId, activeDenialClaim.claimInfo.patientId);
        } else {
            return null;
        }
    }

    uploadDenialClaimPacket(createPacketRequest: DenialPacketCreationRequest): Observable<PdfPacketModel> {
        return this.http.post<OperationResponse<PdfPacketModel>>(`${this.urlConstantsService.DENIAL_CLAIMS_URL}/packet`, createPacketRequest)
            .pipe(map(response => response.data));
    }

    getDenialClaimUploadSubCategories(): Dictionary<string[]> {
        return {
            AppealTracking: [
                'Mail tracking receipt',
                'Fax receipt',
                'Electronic submission receipt',
                'ADR Sign-off form',
                'Appeal Sign-off form',
            ],
            AppealsLetters: [
                'ADR Letter',
                'Redetermination Letter',
                'Reconsideration Letter',
                'ALJ Letter',
                'Judiciary Letter',
                'Nursing Appeal Letter',
            ],
            ClientCommunication: [
                'Email',
                'Portal information',

            ],
            DenialIntermediaries: [
                'ADR Notice',
                'Redetermination Notice (Skipped ADR)',
                'Denied ADR Notice',
                'Denied Redetermination Notice',
                'Denied Reconsideration Notice',
                'Demand Letter-Redetermination',
                'Demand Letter-Reconsideration',
                'Judiciary Notice',
                'Other Notice',
                'CERT Request',
                'PERM Request',
                'ZPIC Request',
                'Unfavorable ALJ Decision',
                'Favorable ALJ Decision',
                'Favorable ADR Results',
                'Favorable Appeal Results',
                'Unfavorable Appeal Results',
                'Indemnification Document',
                'ALJ-Beneficiary Notice of Appeal',
                'ALJ- Request for Hearing',
                'ALJ-Filing New Evidence',
                'ALJ-Response Notice of hearing',
                'ALJ-Hearing Waiver Form',
                'ALJ-Transfer Appeal Rights',
                'ALJ-List of Attendees',
                'ALJ-Hearing Notice',

            ],
            //DLT 2024-09-05: This array should match the 
            //TextAttributes of the enum DenialClaimStageNames.
            DenialPackets: [
                'ADR',
                'RAC Audit',
                'Redetermination',
                'Reconsideration',
                'ALJ',
                'Judiciary Court'
            ],
            Denials: [
                'UB-04',
                'Pending ADR Packet',
                'Pending Redetermination Packet',
                'Pending Reconsideration Packet',
                'Pending ALJ Packet',
                'EOB/RA',
                'Licensure',
                'Other',

            ],
            ECharts: [
                'Certifications-700',
                'Certifications-Recert',
                'Clarification Orders',
                'Recertification Orders',
                'Evaluation Orders',
                'Discharge Order, Standardized Assessments',

            ],
            Nursing: [
                'Nursing Documentation',
                'Facility nursing appeal letter',
                'Other',

            ],
        }
    }

    getDenialClaimLiability(denialId: string) {
        return this.http.get<DenialLiabilityEstimationResponse>(`${this.urlConstantsService.DENIAL_CLAIMS_URL}/${denialId}/liability`);
    }

    getDenialClaimStageDueDate(stage: DenialClaimStage, dueDates: Dictionary<number>): Date {
        let momentDateOfNotice = null;
        if (stage.dateOnRequest) {
            momentDateOfNotice = moment(stage.dateOnRequest);
            const daysToAdd = dueDates[stage.denialStage];

            momentDateOfNotice = daysToAdd != null ? momentDateOfNotice.add(daysToAdd, 'days') : null;
        }

        return momentDateOfNotice ? momentDateOfNotice.toDate() : null;
    }

    getClientDenialClaimDashboard(facilityId: string) {
        return this.http.get<DenialClaimSlim[]>(`${this.urlConstantsService.DENIAL_CLAIMS_URL}/client?facilityId=${facilityId}`);
    }

    getClientDenialClaimPacketDownload(recordId: string, denialId: string) {
        return this.http.get<OperationResponse<string>>(`${this.urlConstantsService.DENIAL_CLAIMS_URL}/client/packet/${denialId}?recordId=${recordId}`)
            .pipe(map(response => response.data));
    }

    getDenialParameterReport(parameters: DenialReportParameters) {
        return this.http.post<OperationResponse<GeneratedReportResponse>>(`${this.urlConstantsService.DENIAL_CLAIMS_URL}/report`, parameters)
        .pipe(map(response => response.data));
    }
}