<div class="border border-primary" style="padding:10px;">
    <div class="row">
        <div class="col-sm-12">
            <h4>{{ clinicalScale.name }}</h4>
        </div>
    </div>
    <div class="row" style="padding-top:10px;">
        <div class="col-sm-2">
            {{ minimumValueString }}
        </div>
        <div class="col-sm-4">
        </div>
        <div class="col-sm-2">
            <div class="float-right">
                {{ maximumValueString }}
            </div>
        </div>
        <div class="col-sm-4">
        </div>
    </div>
    <div class="row">
        <div class="col-sm-8">
            <dx-slider #slider [min]=clinicalScale.lowerLimit [max]=clinicalScale.upperLimit [(value)]=clinicalScale.lastMeasurement
                (valueChange)="sliderValueChanged($event)">
                <!-- <dxo-label [visible]="true" position="top" [format]="format"></dxo-label> -->
            </dx-slider>
        </div>
        <div class="col-sm-2">
            <dx-number-box [min]=clinicalScale.lowerLimit [max]=clinicalScale.upperLimit [showSpinButtons]="true" [(value)]="slider.value"
                [width]=75></dx-number-box>
        </div>
        <div class="col-sm-2 text-center">
            Last Measurement: {{ lastMeasurementString }}
        </div>
    </div>
    <div class="row" style="padding-top:10px;">
        <div class="col-sm-10">
            Selected Value: {{ selectedValue }}
        </div>
        <div class="col-sm-2 text-center">
            <span class="dx-icon-arrowup icon" #arrowUp style="font-size: 28px;" [style.color]="arrowUpColor"></span>
            <span class="dx-icon-arrowdown icon" #arrowDown style="font-size: 28px;" [style.color]="arrowDownColor"></span>
        </div>
    </div>
    <hr>
    <div class="row" style="padding-top:10px;">
        <div class="col-sm-2">
            Equivalent:
        </div>
        <div class="col-sm-2">
            <strong>GCode:</strong>
        </div>
        <div class="col-sm-2">
            <strong>Nursing:</strong>
        </div>
        <div class="col-sm-2">
            <strong>Impairment:</strong>
        </div>
        <div class="col-sm-2">
        </div>
        <div class="col-sm-2">
        </div>
    </div>
</div>