import * as Moment from 'moment';
import 'moment-timezone';
import { extendMoment } from 'moment-range';
import { DateRange } from '@app/model';

export class MomentUtils {
    static overlaps(range: DateRange, rangeOfDates: DateRange[]): boolean {
        const moment = extendMoment(Moment);

        const rangeToCheck = moment.range(range.startDate, range.endDate);
        const rangesToCheckAgainst = rangeOfDates.map(range => moment.range(range.startDate, range.endDate));

        // check if range overlaps any rangeOfDates
        return rangesToCheckAgainst.some(rangeToCheckAgainst => rangeToCheck.overlaps(rangeToCheckAgainst))
    }

    static getLocalTime(timeStamp) {
        const moment = Moment; 

        if (timeStamp) {
            let localTime = moment.utc(timeStamp).local().format('L LT');
            let zone = moment.tz.guess();
            let timezone = moment.tz(zone).zoneAbbr()

            return `${localTime} (${timezone})`;
        }
        return '';
    }

    // Checks if given date is contained within range of dates. Includes start and end date
    static contains(date: Date, rangeOfDates: DateRange[]) {
        const moment = extendMoment(Moment);
        const dateToCheck = moment(date);

        return rangeOfDates.some(date => {
            const range = moment.range(date.startDate, date.endDate);
            return range.contains(dateToCheck);
        });
    }

    static isDateABeforeDateB(dateA: Date, dateB: Date): boolean {
        const moment = Moment; 
        return moment(dateA).isBefore(dateB, 'day');
    }

    static isDateABeforeOrSameDateB(dateA: Date, dateB: Date): boolean {
        const moment = Moment; 
        return moment(dateA).isSameOrBefore(dateB, 'day');
    }
}