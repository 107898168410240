import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlConstantsService } from './url-constants.service';
import { Intervention, InterventionNote, OperationResponse, PagedResult } from '@app/model';

@Injectable({
    providedIn: 'root'
  })
export class InterventionsService {
    constructor(private httpClient: HttpClient,
        private urlConstantsService: UrlConstantsService) {}

    getInterventions(): Observable<Intervention[]> {
        return this.httpClient.get<PagedResult<Intervention>>(`${this.urlConstantsService.INTERVENTIONS_URL}`)
            .pipe(map(response => response.items));
    }

    addIntervention(intervention: Intervention): Observable<Intervention> {
        return this.httpClient.post<OperationResponse<Intervention>>(`${this.urlConstantsService.INTERVENTIONS_URL}`, intervention)
            .pipe(map(response => response.data));
    }

    updateIntervention(intervention: Intervention): Observable<Intervention> {
        return this.httpClient.put<OperationResponse<Intervention>>(`${this.urlConstantsService.INTERVENTIONS_URL}/${intervention.id}`, intervention)
        .pipe(map(response => response.data));
    }

    deleteIntervention(id: string) {
        return this.httpClient.delete<OperationResponse<void>>(`${this.urlConstantsService.INTERVENTIONS_URL}/${id}`);
    }

    addInterventionNote(interventionId: string, interventionNote: InterventionNote) {
        return this.httpClient.post<OperationResponse<Intervention>>(`${this.urlConstantsService.INTERVENTIONS_URL}/${interventionId}/notes`, interventionNote)
        .pipe(map(response => response.data));
    }

    updateInterventionNote(interventionId: string, interventionNote: InterventionNote) {
        return this.httpClient.put<OperationResponse<Intervention>>(`${this.urlConstantsService.INTERVENTIONS_URL}/${interventionId}/notes/${interventionNote.id}`, interventionNote)
        .pipe(map(response => response.data));
    }

    deleteInterventionNote(interventionId: string, interventionNoteId: string) {
        return this.httpClient.delete<OperationResponse<Intervention>>(`${this.urlConstantsService.INTERVENTIONS_URL}/${interventionId}/notes/${interventionNoteId}`)
        .pipe(map(response => response.data));
    }
}