import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tms-collapse-panel',
  templateUrl: './tms-collapse-panel.component.html',
})
export class TmsCollapsePanelComponent {
  @Input() customPanelTitle: boolean = false;
  @Input() panelTitle: string = null;
  @Input() panelCollapsed: boolean = true;
  @Input() isRequired: boolean = false;
  @Output() onPanelClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  onPanelClick() {
    this.panelCollapsed = !this.panelCollapsed;
    this.onPanelClicked.emit(this.panelCollapsed);
  }

}
