import { Dictionary } from '../../../interfaces';
import { TemplateObject, templateObjToStringListUtil } from '../template-object';
import { TemplateTextOptions } from '../TemplateObjectText';

export const RecertOTDeficitProperties: Dictionary<TemplateTextOptions> = {
    POSITIONAL_ACCURACY: {
        templatePropertyName: 'positionalaccuracy',
        displayText: 'positional accuracy',
        displayValueText: 'positional accuracy',
    },
    SUSTAIN_POSITION: {
        templatePropertyName: 'sustainposition',
        displayText: 'sustain position',
        displayValueText: 'ability to sustain proper position',
    },
    WT_BEARING: {
        templatePropertyName: 'wtbearing',
        displayText: 'wt bearing',
        displayValueText: 'compliance with weight bearing precautions',
    },
    WT_BEARING_POTENTIAL: {
        templatePropertyName: 'wtbearingpotential',
        displayText: 'wt bearing potential',
        displayValueText: 'potential for weight bearing',
    },
    SAFETY: {
        templatePropertyName: 'safety',
        displayText: 'safety',
        displayValueText: 'safety',
    },
    FNXNL: {
        templatePropertyName: 'fnxnl',
        displayText: 'fnxnl (I)',
        displayValueText: 'functional independence',
    },
    SKIN_INTEGRITY: {
        templatePropertyName: 'skinintegrity',
        displayText: 'skin integrity',
        displayValueText: 'skin integrity',
    },
    DRESSING_QUALITY: {
        templatePropertyName: 'dressingquality',
        displayText: 'dressing quality',
        displayValueText: 'quality of performance of dressing tasks',
    },
    LB_DRESSING: {
        templatePropertyName: 'LBdressing',
        displayText: 'LB dressing',
        displayValueText: 'ability to perform lower body dressing',
    },
    UB_DRESSING: {
        templatePropertyName: 'UBdressing',
        displayText: 'UB dressing',
        displayValueText: '(I) with upper body dressing',
    },
    BATHING: {
        templatePropertyName: 'bathing',
        displayText: 'bathing',
        displayValueText: 'self bathing skills',
    },
    TOILETING: {
        templatePropertyName: 'toileting',
        displayText: 'toileting',
        displayValueText: 'performance of toileting',
    },
    GROOMING: {
        templatePropertyName: 'grooming',
        displayText: 'grooming',
        displayValueText: '(I) with grooming',
    },
    SAFETY_WITH_GROOMING: {
        templatePropertyName: 'safetywithgrooming',
        displayText: 'safety with grooming',
        displayValueText: 'safety with completion of grooming tasks',
    },
    GROOMING_QUALITY: {
        templatePropertyName: 'groomingquality',
        displayText: 'grooming quality',
        displayValueText: 'quality of performance of grooming',
    },
    ADL_EFFICIENCY: {
        templatePropertyName: 'ADLefficiency',
        displayText: 'ADL efficiency',
        displayValueText: 'ADL efficiency',
    },
    ADL_SAFETY: {
        templatePropertyName: 'ADLsafety',
        displayText: 'ADL safety',
        displayValueText: 'ADL safety',
    },
    ADL_I: {
        templatePropertyName: 'ADLI',
        displayText: 'ADL (I)',
        displayValueText: 'Independence with ADLs',
    },
    SELF_FEEDING: {
        templatePropertyName: 'self feeding',
        displayText: 'self feeding',
        displayValueText: '(I) with self feeding',
    },
    TRANSFER_I: {
        templatePropertyName: 'transferI',
        displayText: 'transfer (I)',
        displayValueText: 'independence with performance of functional transfers',
    },
    TRANSFER_PERFORMANCE: {
        templatePropertyName: 'transferperformance',
        displayText: 'transfer performance',
        displayValueText: 'functional transfer performance',
    },
    TRANSFER_SAFETY: {
        templatePropertyName: 'transfersafety',
        displayText: 'transfer safety',
        displayValueText: 'safety with performance of transfers',
    },
    BED_MOB_I: {
        templatePropertyName: 'bedmobI',
        displayText: 'bed mob (I)',
        displayValueText: 'independence with bed mobility',
    },
    WC_MOBILITY: {
        templatePropertyName: 'wcmobility',
        displayText: 'w/c mobility',
        displayValueText: 'ability to execute w/c mobility',
    },
    NORMALIZE_MVMT_PATTERNS: {
        templatePropertyName: 'normalizemovementpatterns',
        displayText: 'normalize movement patterns',
        displayValueText: 'normalization of movement patterns to allow for increased self care',
    },
    SPLIT_TOLERANCE: {
        templatePropertyName: 'splinttolerance',
        displayText: 'splint tolerance',
        displayValueText: 'splint tolerance',
    },
    THR_PRECAUTIONS: {
        templatePropertyName: 'THRprecautions',
        displayText: 'THR precautions',
        displayValueText: 'compliance with THR precautions',
    },
    SPLINTING: {
        templatePropertyName: 'splinting',
        displayText: 'splinting',
        displayValueText: 'compliance with splinting protocol',
    },
    TASK_EFFICIENY_HOME: {
        templatePropertyName: 'taskefficienyhome',
        displayText: 'task efficieny home',
        displayValueText: 'efficiency of completion of home care tasks',
    },
    AWARENESS_HOME_ENV: {
        templatePropertyName: 'awarenesshomeenv',
        displayText: 'awareness home env',
        displayValueText: 'awareness of home environment',
    },
    DC_HOME: {
        templatePropertyName: 'dchome',
        displayText: 'd/c home',
        displayValueText: 'ability to discharge home independently.',
    },
    HOME_BILLS_MEDS: {
        templatePropertyName: 'homebillsmeds',
        displayText: 'home- bills/meds',
        displayValueText: 'home management skills including bills/ medication',
    },
}

export const RecertDeficitsProperties = {
    // PT 
    WT_BEARING_POTENTIAL: 'wtBearingPotential',
    POSITIONAL_ACCUR: 'positionalAccuracy',
    PRESSURE_RELIEF: 'pressureRelief',
    TRANSFER_SAFETY: 'transferSafety',
    SUSTAIN_POS: 'sustainPosition',
    TRANSFERS: 'transfers',
    SKIN_INTEGRITY: 'skinIntegrity',
    GAIT: 'gait',
    BED_MOD: 'bedMod',
    HYGIENE: 'hygiene',
    GAIT_SAFETY: 'gaitSafety',
    // ST
    EFFECT_OF_COUGH: 'Effectiveness-of-cough',
    MASTICATION: 'Mastication',
    DIET_TOLERANCE: 'Diet-tolerance',
    ORAL_INTAKE_SAFETY: 'Oral-intake-safety',
    SWALLOW_SAFETY: 'Swallow-safety',
    ASPIRATION_RISK: 'Aspiration-risk',
    SWALLOW_COMP_STRATS: 'Swallow-comp-strats',
    BOLUS_FORM: 'Bolus-form-mastication',
    AIRWAY_PROTECT: 'Airway-protection',
    I_SAFETY_MEALS: 'I_safety-with-meals',
    SWALLOW_INIT: 'Swallow-initiation',
    ORAL_SENSATION: 'Oral-sensation',
    POCKETING: 'Pocketing',
    TONGUE_MOB_ROM: 'Tongue-mob_ROM',
    VC_CLOSURE: 'VC-closure',
    SPEECH_INTEL: 'Speech-intel',
    THOUGHT_ORG: 'Thought-organization',
    EXPRESSION: 'Expression',
    VERBAL_EXPRESSION: 'Verbal-expression',
    WORD_FINDING: 'Word-finding',
    QUALITY_OF_RESPONSE: 'Quality-of-response',
    COMMUNICATION: 'Communication',
    SAFETY_AWARENESS: 'Safety-awareness',
    COMPREHENSION: 'Comprehension',
    AUDITORY_COMPREHENSION: 'Auditory-comprehension',
    VOICE_MODULATION: 'Voice-modulation',
    DECISION_MAKING: 'Decision-making',
    REDUCE_CONFUSION: 'Reduce-confusion',
    INDEPENDENCE: 'Independence',
    ORIENTATION: 'Orientation',
    ENVIRONMENT: 'Environment',
}

export const RECERT_DEFICITS_TEMPLATE: string = `Skilled interventions are medically necessary to impact function deficits related to: {{#if stringList}}{{/if}}{{stringList}}`;

export class RecertDeficitsTemplate implements TemplateObject {
    wtBearingPotential: string = '';
    positionalAccuracy: string = '';
    pressureRelief: string = '';
    transferSafety: string = '';
    sustainPosition: string = '';
    transfers: string = '';
    skinIntegrity: string = '';
    gait: string = '';
    bedMod: string = '';
    hygiene: string = '';
    gaitSafety: string = '';

    stringList: string[] = [];

    private toList = Object.values(RecertDeficitsProperties).concat(Object.values(RecertOTDeficitProperties).map(value => value.templatePropertyName));

    preCompileTemplateObj(): void {
        this.stringList = templateObjToStringListUtil(this, this.toList);
    }
}