<tms-modal title="Concurrent Statement Edit" (onSubmit)="onSubmitPressed()" (onCancel)="onClearPressed()" cancelButtonText="Clear" (onClose)="onClose()">
    Patient participated in concurrent intervention of 2 people
    utilizing 
    <dx-text-area [autoResizeEnabled]="true" [(value)]="txtImprovement" class="concurrent-text-box">
        <dx-validator>
            <dxi-validation-rule type="required" message="Value is required">
            </dxi-validation-rule>
        </dx-validator>
    </dx-text-area> to improve 
    <dx-text-area [autoResizeEnabled]="true" [(value)]="txtIntervention" class="concurrent-text-box">
        <dx-validator>
            <dxi-validation-rule type="required" message="Value is required">
            </dxi-validation-rule>
        </dx-validator>
    </dx-text-area>.
</tms-modal>