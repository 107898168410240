<tms-modal [title]="title" (onSubmit)="onSubmitPressed()" (onCancel)="onClearPressed()" cancelButtonText="Clear" (onClose)="onClose()">
    <span>
        Caregiver training in strategies and techniques was completed without the patient present to facilitate the patient’s performance in 
    </span>
    <dx-text-box [(value)]="txtAreaOfEducation" class="caregiver-training-text-box">
        <dx-validator>
            <dxi-validation-rule type="required" message="Value is required">
            </dxi-validation-rule>
        </dx-validator>
    </dx-text-box> 
    <span>Training consisted of </span>
    <dx-text-box [(value)]="txtTrainingCompleted" class="caregiver-training-text-box">
        <dx-validator>
            <dxi-validation-rule type="required" message="Value is required">
            </dxi-validation-rule>
        </dx-validator>
    </dx-text-box>.
    <span>The caregiver(s) present for training were </span>
    <dx-text-box [(value)]="txtCaregivers" class="caregiver-training-text-box">
        <dx-validator>
            <dxi-validation-rule type="required" message="Value is required">
            </dxi-validation-rule>
        </dx-validator>
    </dx-text-box>.
    <span> Consent for training was obtained on </span>
    <dx-text-box [(value)]="txtDate" class="caregiver-training-text-box">
        <dx-validator>
            <dxi-validation-rule type="required" message="Value is required">
            </dxi-validation-rule>
        </dx-validator>
    </dx-text-box>.
     <span> from </span>
    <dx-text-box [(value)]="txtRepresentative" class="caregiver-training-text-box">
        <dx-validator>
            <dxi-validation-rule type="required" message="Value is required">
            </dxi-validation-rule>
        </dx-validator>
    </dx-text-box>.
</tms-modal>