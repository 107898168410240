import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'tms-mat-dialog',
  templateUrl: './tms-mat-dialog.component.html',
  styleUrls: ['./tms-mat-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TmsMatDialogComponent implements OnInit {
  @Input() title: string = null;
  @Input() submitButtonText: string = 'Submit';
  @Input() cancelButtonText: string = 'Cancel';
  @Input() submitButtonDisabled: boolean = false;
  @Input() cancelButtonDisabled: boolean = false;
  @Output() onSubmit: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() onCancel: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  onCloseClicked() {
    this.onClose.emit();
  }

  onCancelClicked(e) {
    this.onCancel.emit(e);
  }

  onSubmitClicked(e) {
    this.onSubmit.emit(e);
  }

}
