export class HtmlCharacterEntityUtils {
    public static convertEntitiesToLiterals(stringOfEntities: string): string {
        return stringOfEntities
            .replace(/&#34;/gi, '"').replace(/&quot;/gi, '"')
            .replace(/&#38;/gi, '&').replace(/&amp;/gi, '&')
            .replace(/&#39;/gi, "'").replace(/&#x27;/gi, "'").replace(/&apos;/gi, "'")
            .replace(/&60;/gi, '<').replace(/&lt;/gi, '<')
            .replace(/&#61;/gi, '=').replace(/&#x3d;/gi, '=')
            .replace(/&#62;/gi, '>').replace(/&gt;/gi, '>')
    }
}