import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { PremierCodingEntry, OperationResponse, PremierCodingQuery, PremierCodingSupplementalDocument } from '@app/model';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { CacheManService } from './cacheman.service';
import { LocalStorageConstants } from '../constants';
import { AppConfigService } from '@app/app-config.service';
import { AuthService } from './authentication';

@Injectable({
	providedIn: 'root',
})
export class PremierCodingService {
	constructor(private appConfigService: AppConfigService,
		private authService: AuthService,
		private cacheManService: CacheManService,
		private http: HttpClient,
		private localStorage: LocalStorageService,
		private urlConstantsService: UrlConstantsService) {
    }

    getAllCodingEntries(facilityId: string, mode: number, showMyItems: boolean = false): Observable<PremierCodingEntry[]> {
		return this.http.get<OperationResponse<PremierCodingEntry[]>>(`${this.urlConstantsService.PREMIER_CODING_URL}?facilityId=${facilityId}&mode=${mode}&showMyItems=${showMyItems}`)
			.pipe(map(response => response.data));
	}
	
	getCodingQuery(model: PremierCodingQuery): Observable<PremierCodingQuery> {
		return this.http.post<OperationResponse<PremierCodingQuery>>(`${this.urlConstantsService.PREMIER_CODING_URL}/query`, model)
			.pipe(map(response => response.data));
	}
	
	createCodingQuery(model: PremierCodingQuery): Observable<PremierCodingQuery> {
		return this.http.post<OperationResponse<PremierCodingQuery>>(`${this.urlConstantsService.PREMIER_CODING_URL}/create`, model)
			.pipe(map(response => response.data));
    }
    
    updateReviewedStatus(entry: PremierCodingEntry) {
		return this.http.put<OperationResponse<PremierCodingEntry>>(`${this.urlConstantsService.PREMIER_CODING_URL}/${entry.id}/reviewed`, entry)
			.pipe(map(response => response.data));
	}
	
	updateEntryStatus(entry: PremierCodingEntry) {
		return this.http.put<OperationResponse<PremierCodingEntry>>(`${this.urlConstantsService.PREMIER_CODING_URL}/${entry.id}/status`, entry)
			.pipe(map(response => response.data));
	}
	
	updateCodingAssignment(entry: PremierCodingEntry) {
		return this.http.put<OperationResponse<PremierCodingEntry>>(`${this.urlConstantsService.PREMIER_CODING_URL}/${entry.id}/assignment`, entry)
			.pipe(map(response => response.data));
    }

	updateCodingNotes(entry: PremierCodingEntry) {
		return this.http.put<OperationResponse<PremierCodingEntry>>(`${this.urlConstantsService.PREMIER_CODING_URL}/${entry.id}/notes`, entry)
			.pipe(map(response => response.data));
    }

	updateUrgency(entryId: string, isUrgent:boolean) {
		return this.http.put<OperationResponse<PremierCodingEntry>>(`${this.urlConstantsService.PREMIER_CODING_URL}/${entryId}/urgency`, isUrgent)
			.pipe(map(response => response.data));
    }

	deleteCodingEntry(id: string): Observable<boolean> {
		return this.http.delete<OperationResponse<boolean>>(`${this.urlConstantsService.PREMIER_CODING_URL}/${id}`)
			.pipe(map(response => response.data));
	}

	setPageSizeForCurrentUser(pageSize: number) {
		this.cacheManService.getCurrentUser()
			.pipe(take(1))
			.subscribe(user => {
				const key = this.localStorage.getKey([user.id, LocalStorageConstants.PREMIER_CODING, LocalStorageConstants.PREMIER_CODING_PAGE_SIZE]);
				this.localStorage.setItem(key, pageSize, true);
			});
	}

	getPageSizeForCurrentUser(): Observable<number> {
		return this.cacheManService.getCurrentUser()
			.pipe(filter(user => user != null), map(user => {
				const key = this.localStorage.getKey([user.id, LocalStorageConstants.PREMIER_CODING, LocalStorageConstants.PREMIER_CODING_PAGE_SIZE]);
				return +this.localStorage.getItem(key);
			}));
	}

	getDocumentUploadUrl(id: string): string {
		const url = `${this.urlConstantsService.PREMIER_CODING_URL}/${id}/upload`;
		return url;
	}

	getFullDocumentUploadUrl(url: string): string {
		const fullUrl = `${this.appConfigService.appConfig.hostUrl}${url}`;
		return fullUrl;
	}

	updateDocuments(id: string, documents: PremierCodingSupplementalDocument[]): Observable<PremierCodingSupplementalDocument[]> {
		return this.http.put<OperationResponse<PremierCodingSupplementalDocument[]>>(`${this.urlConstantsService.PREMIER_CODING_URL}/${id}/updatedocuments`, documents)
			.pipe(map(response => response.data));
	}

	getDocuments(id: string): Observable<PremierCodingSupplementalDocument[]> {
		return this.http.get<OperationResponse<PremierCodingSupplementalDocument[]>>(`${this.urlConstantsService.PREMIER_CODING_URL}/${id}/documents`)
			.pipe(map(response => response.data));
	}

	deleteDocument(id: string, documentRecordId: string): Observable<boolean> {
		return this.http.delete<OperationResponse<boolean>>(`${this.urlConstantsService.PREMIER_CODING_URL}/${id}/documents/${documentRecordId}`)
			.pipe(map(response => response.data));
	}
}