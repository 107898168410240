<div *ngIf="impersonatingUser" class="header-impersonate-banner">
  <span class="header-impersonate-text">
    <i class="fas fa-exclamation-circle header-impersonate-text-icon-left"></i>
      You are impersonating {{ impersonatingUser.name }}
    <i class="fas fa-exclamation-circle header-impersonate-text-icon-right"></i>
  </span>
</div>
<div class="row">
  <div class="col-1">
    <dx-button icon="fas fa-bars" (onClick)="toggleSideNav()" class="header-icons"></dx-button>
  </div>
  <div class="col" *ngIf="canViewPatientSearch">
    <dx-text-box id="headerPatientSearchTextBox" #headerPatientSearchTextBox placeholder="Patient Search"
      [(value)]="patientSearchValue" (onEnterKey)="onPatientSearchEnterKey($event)" mode="search" width="200">
    </dx-text-box>
    <dx-tooltip target="#headerPatientSearchTextBox" #headerAdvancedSearchTooltip showEvent="dxclick"
      [closeOnOutsideClick]="true" (onHidden)="onHiddenTooltip($event)" class="header-advanced-search">
      <div *dxTemplate="let data = data of 'content'" class="header-tooltip-div">
        <div class="header-tooltip-title">Search Criteria</div>
        <dx-text-box placeholder="First Name" [(value)]="patientSearchCriteria.firstNameContains"
          class="header-tooltip-control"></dx-text-box>
        <dx-text-box placeholder="Last Name" [(value)]="patientSearchCriteria.lastNameContains"
          class="header-tooltip-control"></dx-text-box>
        <tms-date-box placeholder="Date of Birth" width="300" [(value)]="patientSearchCriteria.dateOfBirth"
          class="header-tooltip-control"></tms-date-box>
        <dx-text-box placeholder="Social Security" [(value)]="patientSearchCriteria.socialSecurityNumber"
          class="header-tooltip-control"></dx-text-box>
        <dx-text-box placeholder="Unique Id" [(value)]="patientSearchCriteria.uniqueId" class="header-tooltip-control">
        </dx-text-box>
        <dx-button text="Search" class="header-tooltip-control header-tooltip-button tms-primary-button"
          (onClick)="advancedSearch($event)"></dx-button>
      </div>
    </dx-tooltip>
  </div>
  <div class="col">
    <dx-select-box #headerFacilitySelectBox [dataSource]="facilities$ | async" [value]="activeFacilityId"
      placeholder="Select Facility" (onOpened)="onFacilityOpened($event)" (onFocusOut)="onFacilityFocusOut($event)"
      (onValueChanged)="onFacilityValueChanged($event)" displayExpr="name" valueExpr="id" width="250">
    </dx-select-box>
  </div>
  <div class="col">
    <dx-select-box *hasPermission="jumpToNavigationPermission" [dataSource]="jumpToNavItem" displayExpr="text"
      valueExpr="route" [grouped]="true" placeholder="Jump To Navigation" width="200" [showClearButton]="true"
      (onValueChanged)="onJumpToNavValueChanged($event)">
    </dx-select-box>
  </div>
  <div class="col header-col">
    <div>
      <div>
        <span class="greeting" *ngIf="user != null">
          {{ greeting(user.employee?.designation | dataListItem: designationDataList:'shortName' | async) }}
        </span>
      </div>
      <div *ngIf="!isUserClockedIn">
        <span class="warning-alert">You are not clocked in.</span>
      </div>
    </div>
  </div>
  <div class="col-1 header-col">
    <div class="float-right">
      <div [matMenuTriggerFor]="menu" title="User Profile" class="header-icons header-icon-link">
        <i class="fas fa-user-circle fa-lg"></i>
      </div>
      <mat-menu #menu="matMenu" class="my-profile-container" (closed)="onHideSwitchDiscipline()">
        <div class="row gx-0">
          <div class="col-3 my-profile-icon">
            <svg-icon key="my-profile-user" fontSize="50px" color="#337AB7"></svg-icon>
          </div>
          <div class="col">
            <div class="my-profile-container-user-name">{{ user.firstName }} {{ user.lastName }}</div>
            <div *ngIf="isEmployee" class="my-profile-container-user-discipline">
                {{ user.employee?.designation | dataListItem: designationDataList | async }}
            </div>
            <div [ngClass]="{ 'my-profile-container-logout': !isEmployee }">
              <dx-button [visible]="isEmployee" class="tms-blue-theme-button" text="My Profile" height="23px" (onClick)="goToProfile()"></dx-button>
              <dx-button [ngClass]="{ 'tms-submit-button': isEmployee }" text="Logout" height="23px" (onClick)="onLogout()"></dx-button>
            </div>
          </div>
        </div>
        <hr>
        <button *hasPermission="timeClockViewPermission" class="my-profile-container-menu-button" mat-menu-item (click)="openTimeClockModal()">
          <div class="row gx-0">
            <div class="col-2 my-profile-container-icon">
              <i class="far fa-clock my-profile-container-icon-time-clock"></i>
            </div>
            <div class="col">
              Time clock
            </div>
          </div>
        </button>
        <button class="my-profile-container-menu-button" mat-menu-item (click)="onAddSupportTicket()">
          <div class="row gx-0">
            <div class="col-2 my-profile-container-icon">
              <svg-icon key="support-ticket" fontSize="18px" color="#337AB7"></svg-icon>
            </div>
            <div class="col">
              Create support ticket
            </div>
          </div>
        </button>
        <button *ngIf="isEmployee" class="my-profile-container-menu-button" mat-menu-item (click)="goToWorkHoursManagement()">
          <div class="row gx-0">
            <div class="col-2 my-profile-container-icon">
              <svg-icon key="work-hours" fontSize="18px" color="#337AB7"></svg-icon>
            </div>
            <div class="col">
              Work hours management
            </div>
          </div>
        </button>
        <button *ngIf="isEmployee" class="my-profile-container-menu-button" mat-menu-item (mouseover)="onShowSwitchDiscipline()" (click)="onSwitchDisciplineClicked($event)">
          <div class="row gx-0">
            <div class="col-2 my-profile-container-icon">
              <svg-icon key="user-refresh" fontSize="18px" color="#337AB7"></svg-icon>
            </div>
            <div class="col">
              Switch Discipline
            </div>
          </div>
        </button>
        <div *ngIf="showSwitchDisciplineSelections">
          <button mat-menu-item *ngFor="let discipline of user.employee?.availableDisciplines"  (click)="onSwitchDiscipline(discipline.discipline, discipline.designation, discipline.ecode)">
            <div class="row gx-0">
              <div class="col-1"></div>
              <div class="col-2 my-profile-container-icon">
                <svg-icon key="my-profile-user" fontSize="18px" color="#337AB7"></svg-icon>
              </div>
              <div class="col">
                {{ discipline.discipline | dataListItem: disciplineDataList | async }}
              </div>
            </div>
          </button>
        </div>
      </mat-menu>
    </div>
  </div>
</div>
