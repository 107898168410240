import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { ClinicalDocumentBase } from '@app/model';

export interface ClinicalDocumentsState extends EntityState<ClinicalDocumentBase>, ActiveState {
}

const initialState: ClinicalDocumentsState = {
    active: null,
}

@Injectable( { providedIn: 'root'})
@StoreConfig({ name: 'clinicalDocuments', idKey: 'interviewSessionId'})
export class ClinicalDocumentsStore extends EntityStore<ClinicalDocumentsState> {
    constructor() {
        super(initialState);
    }
}