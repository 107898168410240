import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tms-charge-telehealth-statement-edit',
  templateUrl: './charge-telehealth-statement-edit.component.html',
  styleUrls: [ './charge-telehealth-statement-edit.component.scss' ]
})
export class ChargeTelehealthStatementEditComponent implements OnInit{
    constructor(private activeModal: NgbActiveModal,
        private cdr: ChangeDetectorRef
      ) { }

    ngOnInit(): void {
        
    }
    
    @Input() txtPatientLocation: string;
    @Input() txtConductingTherapist: string;
    @Input() txtConductingTherapistLocation: string;
    @Input() txtAssistant: string;
    @Input() txtTherapistAssisted: string;
    @Input() txtStartTime: string;
    @Input() txtEndTime: string;

    onClearPressed(){
        this.txtPatientLocation = null;
        this.txtConductingTherapist = null;
        this.txtConductingTherapistLocation = null;
        this.txtAssistant = null;
        this.txtTherapistAssisted = null;
        this.txtStartTime = null;
        this.txtEndTime = null;
        this.cdr.detectChanges();
    }

    onClose() {
        this.activeModal.dismiss();
    }

    onSubmitPressed(){
        if(this.txtPatientLocation && this.txtConductingTherapist && this.txtConductingTherapistLocation && this.txtAssistant && this.txtStartTime && this.txtEndTime){
            let array = [this.txtPatientLocation, this.txtConductingTherapist, this.txtConductingTherapistLocation, this.txtAssistant, this.txtTherapistAssisted, this.txtStartTime, this.txtEndTime];
            this.activeModal.close(array);
        }
    }
}