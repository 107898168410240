import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'tms-month-year-picker',
  templateUrl: './month-year-picker.component.html',
  styleUrls: ['./month-year-picker.component.css']
})
export class MonthYearPickerComponent implements OnInit {
  @Input() value: Date = null;
  @Input() showClearButton: boolean = false;
  @Output() onMonthYearValuesChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() valueChange: EventEmitter<Date> = new EventEmitter<Date>();

  startDate: Date = null;
  minDate: Date = null;
  maxDate: Date = null;

  constructor() { }

  ngOnInit() {
    this.minDate = moment().subtract(2, 'years').toDate();
    if (this.value) {
      this.startDate = this.value;
      this.maxDate = new Date();
    } else {
      this.startDate = this.maxDate = new Date();
    }
  }

  onMonthYearChanged(e) {
    if (e.value == null) {
      this.onMonthYearValuesChanged.emit(null);
    } else {
      const momentDate = moment(e.value);
      this.valueChange.emit(momentDate.toDate());
      this.onMonthYearValuesChanged.emit({month:  momentDate.month() + 1, year: momentDate.year()});
    }
  }

}
