import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Payer } from '@app/model';

export interface PayersState extends EntityState<Payer> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'payers' })
export class PayersStore extends EntityStore<PayersState, Payer> {

  constructor() {
    super( { loading: false });
  }

}

