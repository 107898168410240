<tms-modal title="Sign multiple documents" submitButtonText="Done" 
    (onCancel)="onCancel()"
    (onSubmit)="onSubmit($event)"
    (onClose)="onCancel()">
    <div>Choose your documents and review before signing</div>
    <div class="tms-row-divider">
        <dx-data-grid [columnAutoWidth]="true" [dataSource]="unsignedDocumentsUI" (onRowClick)="onRowClicked($event)">
            <dxi-column dataField="errorSigning" [visible]="false"></dxi-column>
            <dxi-column dataField="signed" caption="signed" cellTemplate="signedTemplate"></dxi-column>
            <div *dxTemplate="let data of 'signedTemplate'">
                <i *ngIf="data.value" class="fas fa-check signed-icon"></i>
                <i *ngIf="data.data.errorSigning" class="fas fa-times-circle error-icon"></i>
                <span *ngIf="data.value" class="view-icon" title="View Document" (click)="onViewDocumentClicked(data.data)">
                    <i class="fas fa-eye"></i>
                </span>
            </div>
            <dxi-column dataField="facilityId" caption="Facility">
                <dxo-lookup [dataSource]="facilities$ | async" displayExpr="name" valueExpr="id"></dxo-lookup>
            </dxi-column>
            <dxi-column dataField="patientLastName" [visible]="false"></dxi-column>
            <dxi-column dataField="patientFirstName" [visible]="false"></dxi-column>
            <dxi-column caption="Patient Name" [calculateCellValue]="calculatePatientName"></dxi-column>
            <dxi-column dataField="assistantName"></dxi-column>
            <dxi-column dataField="documentDate" caption="Doc Date" dataType="date" format="MM/dd/yyyy"></dxi-column>
            <dxi-column dataField="documentType" caption="Doc Type" cellTemplate="documentTypeTemplate"></dxi-column>
            <div *dxTemplate="let data of 'documentTypeTemplate'">
                {{ calculateDocumentTypeName(data) }}
            </div>
            <dxi-column dataField="discipline">
                <dxo-lookup [dataSource]="disciplines$ | async" displayExpr="shortName" valueExpr="value"></dxo-lookup>
            </dxi-column>
            <dxi-column dataField="documentRecordId" [visible]="false"></dxi-column>
        </dx-data-grid>
    </div>
</tms-modal>
