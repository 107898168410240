import { Injectable } from "@angular/core";
import { FunctionBuilderTreatment } from "@app/model";
import { ActiveState, EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface FunctionBuilderTreatmentsState extends EntityState<FunctionBuilderTreatment>, ActiveState {}

const initialState: FunctionBuilderTreatmentsState = {
    active: null,
};

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'function-builders-treatments', idKey: 'patientId'})
export class FunctionBuilderTreatmentsStore extends EntityStore<FunctionBuilderTreatmentsState>{
    constructor() {
        super(initialState);
    }
}