import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpRequest, HttpHandler, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppSettingEnvironmentConstants, ToastMessageService } from '@app/core';
import { AppConfigService } from '@app/app-config.service';
import { IgniteStatusService, ImpersonateService, LoadingService, UrlConstantsService } from '../services';
import { OperationResponse, OperationResponseBehavior } from '@app/model';
import { TmsWarningMessageModalComponent } from '@app/shared';
import { ErrorMessageUtils, modalOptions } from '@app/utils';
import { NO_LOADING_SPINNER } from './http-context-tokens';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    private _totalRequests: number = 0;
    private _no_loading_screen_urls: RegExp[] = []; // regex expression

    constructor(private appConfigService: AppConfigService,
                private igniteStatusService: IgniteStatusService,
                private impersonateService: ImpersonateService,
                private loadingService: LoadingService,
                private modalService: NgbModal,
                private toastMessageService: ToastMessageService,
                private urlConstantsService: UrlConstantsService) {
                    // this._no_loading_screen_urls = [
                    //     new RegExp(this.urlConstantsService.STATUS_URL, 'g'),
                    //     new RegExp('\/api\/v1\/interviews\/([0-9a-zA-Z]+)\/sign', 'g'),
                    // ];
                }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.increaseRequest(req.context.get(NO_LOADING_SPINNER));

        let clientVersion = null;

        if (this.appConfigService.appConfig.environment && this.appConfigService.appConfig.environment.toLowerCase() !== AppSettingEnvironmentConstants.PRODUCTION) {
            clientVersion = this.appConfigService.appConfig.Version;
        } else if (this.igniteStatusService.igniteVersion) { // && this.appConfigService.appConfig.environment && this.appConfigService.appConfig.environment.toLowerCase() === AppSettingEnvironmentConstants.PRODUCTION) {
            // only set client version for prod if not null
            clientVersion = this.igniteStatusService.igniteVersion;
        } 

        req = req.clone({
           url: `${this.appConfigService.appConfig.hostUrl}${req.url}`,
           withCredentials: req.withCredentials ? req.withCredentials : this.impersonateService.isImpersonatingMode != null,
           ...(clientVersion != null && { headers: req.headers.append('IgniteClientVersion', clientVersion) })
        });
        return next.handle(req)
            .pipe(
                tap((response: HttpEvent<any>) => {
                    if (response instanceof HttpResponse) {
                        this.decreaseRequests(req.context.get(NO_LOADING_SPINNER));
                        if (req.method === 'PUT' || req.method === 'POST') {
                            if (response.body && response.body['isSuccessful']) {
                                this.toastMessageService.operationResponseNotification(response.body);
                            }
                        }
                    }
                }),
                catchError((httpError: HttpErrorResponse) => {
                    this.decreaseRequests(req.context.get(NO_LOADING_SPINNER));
                    if (httpError.error) {
                        const operationResponse: OperationResponse<null> = httpError.error;
                        if (!operationResponse.suppress && operationResponse.errors && operationResponse.errors.length > 0) {
                            if (operationResponse.responseBehavior == OperationResponseBehavior.ToastNotification) {
                                this.toastMessageService.errorNotification(ErrorMessageUtils.getDisplayErrorMessage(httpError, ''));
                            } else {
                                const modalRef = this.modalService.open(TmsWarningMessageModalComponent, { ...modalOptions });
                                modalRef.componentInstance.message = ErrorMessageUtils.getDisplayErrorMessage(httpError, '');
                            }
                        }
                    } else if (httpError.status == 403) {
                        this.toastMessageService.errorNotification('403 - Forbidden');
                    } else if (httpError.status == 401) {
                        this.toastMessageService.errorNotification('401 - Access Denied');
                    } else {
                        this.toastMessageService.errorNotification('An error occured making request ' + httpError.url);
                    }
                    console.error(`${httpError.message} - ${ErrorMessageUtils.getDisplayErrorMessage(httpError, '')}`);
                    return throwError(httpError);
                }));
        }

        private increaseRequest(loadingSpinner: boolean) {
            // check that request string does not match any urls where we don't want loading bar shown
            //if (this._no_loading_screen_urls.every(url => req.match(url) == null)) {
            if (loadingSpinner) {
                this._totalRequests++;
                this.loadingService.setLoading(true);
            } 
        }

        private decreaseRequests(loadingSpinner: boolean) {
            //if (this._no_loading_screen_urls.every(url => req.match(url) == null)) {
            if (loadingSpinner) {
                this._totalRequests--;
            }
            if (this._totalRequests === 0) {
               this.loadingService.setLoading(false);
            }
        }
}
