export class TemplatesConstants {
    static PATIENT_GOALS: string = 'PATIENT_GOALS';
    static PLOF: string = 'PLOF';
    static PROGNOSTIC_INDICATORS: string = 'PROGNOSTIC_INDICATORS';
    static REASON_FOR_REFERRAL: string = 'REASON_FOR_REFERRAL';
    static RECERT_DEFICITS: string = 'RECERT_DEFICITS';
    static RECERT_INTERVENTIONS: string = 'RECERT_INTERVENTIONS';
    static RECERT_PRECAUTIONS: string = 'RECERT_PRECAUTIONS';
    static REHAB_POTENTIAL: string = 'REHAB_POTENTIAL';
}
