import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NonCovered, PagedResult, eNonCoveredType, MedFeeSchedule, NonCoveredStatus, eDiscipline } from '@app/model';
import { UrlConstantsService } from './url-constants.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class NonCoveredCostService {

    constructor(private httpClient: HttpClient,
        private urlConstantsService: UrlConstantsService) { }

    getAllNoncovered(facilityId: string, year: number, type: eNonCoveredType) {
        return this.httpClient.get<PagedResult<NonCovered>>(`${this.urlConstantsService.NONCOVERED_COST_URL}/filtered?year=${year}&facilityId=${facilityId}&type=${type}`)
        .pipe(map(pagedResult => pagedResult.items));
    }

    getAllNoncoveredOperations(facilityId: string, status: string, month: number, year: number) {
        return this.httpClient.get<NonCovered[]>(`${this.urlConstantsService.NONCOVERED_COST_URL}/operations?month=${month}&year=${year}&facilityId=${facilityId}&status=${status}`);
    }

    getMedFeeSchedule(facilityId: string) {
        return this.httpClient.get<MedFeeSchedule>(`${this.urlConstantsService.NONCOVERED_COST_URL}/medFeeSchedule?facilityId=${facilityId}`);
    }

    getNoncoveredById(id: string) {
        return this.httpClient.get<NonCovered>(`${this.urlConstantsService.NONCOVERED_COST_URL}/${id}`);
    }

    getPatientLastDischargeDate(facilityId: string, patientId: string, discipline: eDiscipline) {
        return this.httpClient.get<Date>(`${this.urlConstantsService.NONCOVERED_COST_URL}/patient?facilityId=${facilityId}&patientId=${patientId}&discipline=${discipline}`);
    }

    approveNonCoveredRequest(requestId: string) {
        return this.httpClient.get<boolean>(`${this.urlConstantsService.NONCOVERED_COST_URL}/${requestId}/approve`);
    }

    denyNonCoveredRequest(requestId: string) {
        return this.httpClient.get<boolean>(`${this.urlConstantsService.NONCOVERED_COST_URL}/${requestId}/deny`);
    }

    addNonCovered(noncovered: NonCovered) {
        return this.httpClient.post<NonCovered>(`${this.urlConstantsService.NONCOVERED_COST_URL}`, noncovered);
    }

    updateNoncovered(noncovered: NonCovered) {
        return this.httpClient.put<NonCovered>(`${this.urlConstantsService.NONCOVERED_COST_URL}/${noncovered.id}`, noncovered);
    }

    deleteNoncovered(id: string) {
        return this.httpClient.delete<NonCovered>(`${this.urlConstantsService.NONCOVERED_COST_URL}/${id}`);
    }
}
