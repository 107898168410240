<div *hasPermission="canAddKnowledgeItem">
    <dx-button style="float:right;" class="tms-primary-button" text="Add Knowledge Base Item" (onClick)="onAddItemClicked()"></dx-button>
</div>
<tms-document-dashboard *ngIf="items" [canEditItems]="canEditItems$ | async" [documentItems]="items"
    [searchBy]="searchByTerms"
    [showDescription]="true"
    (onDocumentDelete)="onItemDelete($event)"
    (onDocumentEdit)="onItemEdit($event)"
    (onDocumentValueChanged)="onItemValueChanged($event)"
    groupBy="categoryName">
</tms-document-dashboard>
<div class="tms-row-divider">
    <span style="font-weight: bold;">Top 5 Knowledge Base Searches:</span>
    <div *ngFor="let item of topFiveKbItems">
        <a href="javascript:void(0)" (click)="onItemValueChanged(item)">{{ item.name }}</a>
    </div>
</div>
