import { Injectable } from "@angular/core";
import { RestorativeNursingProgram, RestorativeNursingTreatment } from "@app/model";
import { ActiveState, EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface RestorativeNursingTreatmentsState extends EntityState<RestorativeNursingTreatment>, ActiveState {}

const initialState: RestorativeNursingTreatmentsState = {
    active:  null,
};

@Injectable({ providedIn: 'root'})
@StoreConfig({ name: 'restorative-nursing-treatments', idKey: 'patientId'})
export class RestorativeNursingTreatmentsStore extends EntityStore<RestorativeNursingTreatmentsState>{
    constructor() {
        super(initialState);
    }
}