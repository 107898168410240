import { HttpClient } from "@angular/common/http";
import { Crosswalk, IcdCode, OperationResponse, eDiscipline } from "@app/model";
import { UrlConstantsService } from "./url-constants.service";
import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class CrosswalksService{
    constructor(private httpClient: HttpClient,
        private urlConstantsService: UrlConstantsService
    ){

    }
    getAllByDiscipline(discipline: eDiscipline){
        return this.httpClient.get<OperationResponse<Crosswalk[]>>(`${this.urlConstantsService.CROSSWALKS_URL}/${discipline}`)
        .pipe(map(response => response.data));
    }

    getAllCptsByDiscipline(facilityId:string, discipline: eDiscipline){
        return this.httpClient.get<OperationResponse<string[]>>(`${this.urlConstantsService.CROSSWALKS_URL}/${facilityId}/cpts/${discipline}`)
        .pipe(map(response => response.data));
    }

    getAllIcdsByCptAndDiscipline(facilityId:string, discipline: eDiscipline, cptCode: string){
        return this.httpClient.get<OperationResponse<IcdCode[]>>(`${this.urlConstantsService.CROSSWALKS_URL}/${facilityId}/icds/${discipline}/${cptCode}`)
        .pipe(map(response => response.data));
    }
}