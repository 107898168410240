import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, RouterStateSnapshot, Route, Router } from '@angular/router';
import { AdalGuard } from 'adal-angular4';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService  } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends AdalGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(public auth: AuthService,
    private router: Router) {
    super(auth.adalService);
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const bCanActivate = super.canActivate(next, state);
    if (bCanActivate) {
      return of(true).pipe(take(1));
    } else {
      this.router.navigate(['/public']);
      return of(false).pipe(take(1));
    }
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(next, state);
  }

  canLoad(route: Route): boolean {
    const isAuthenticated = this.auth.isAuthenticated;
    if (isAuthenticated) {
      return true;
    } else {
      this.router.navigate(['/public']);
      return false;
    }
  }

}
