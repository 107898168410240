import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { InitialSyncModel, UserSlim } from '@app/model';
import { UrlConstantsService } from './url-constants.service';
import { catchError, tap } from 'rxjs/operators';
import { CacheManService } from './cacheman.service';

@Injectable({
  providedIn: 'root'
})
export class ImpersonateService {
  private impersontingSubject$: BehaviorSubject<UserSlim> = new BehaviorSubject<UserSlim>(null);
  private impersonatingUser: UserSlim = null;

  get isImpersonatingMode$(): Observable<UserSlim> {
    return this.impersontingSubject$.asObservable();
  }

  get isImpersonatingMode(): UserSlim {
    return this.impersonatingUser;
  }

  set isImpersonatingMode(value: UserSlim) {
    this.impersonatingUser = value;
    this.impersontingSubject$.next(this.impersonatingUser);
  }

  constructor(private cacheManService: CacheManService,
    private http: HttpClient,
    private urlConstantsService: UrlConstantsService) { }

  setImpersonatingMode(user: UserSlim) {
    const httpOptions = { withCredentials: true };
    return this.http.post<InitialSyncModel>(`${this.urlConstantsService.USERS_URL}/impersonate?userEmail=${user.email}`, null, httpOptions)
      .pipe(tap(sessionStart => {
        if (sessionStart.impersonated) {
          this.cacheManService.setCacheStoreLoading(true);
          this.cacheManService.setSessionStartData(sessionStart);
          this.cacheManService.setCacheStoreLoading(false);
          this.isImpersonatingMode = user;
        }
      }), catchError(error => {
        return throwError(error.error.errorMessage);
      }));
  }
  
  cleanUp() {
    this.isImpersonatingMode = null;
  }
}
