import { Component, Input, OnInit, ChangeDetectionStrategy, ViewChild, Output, EventEmitter } from '@angular/core';
import { DxFileUploaderComponent } from 'devextreme-angular';

@Component({
  selector: 'tms-file-uploader',
  templateUrl: './file-uploader.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploaderComponent implements OnInit {
  @Input() accept: string = null;
  @Input() isRequired: boolean = false;
  @Input() width: number = null;
  @Input() multiple: boolean = true;
  @Output() onFileValueChanged: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild(DxFileUploaderComponent) fileuploader: DxFileUploaderComponent;

  selectButtonText: string = 'Select File(s)';
  
  get files(): File[] {
    return this.fileuploader.value;
  }

  constructor() { }

  ngOnInit(): void {
    if (!this.multiple) {
      this.selectButtonText = 'Select File';
    }
  }

  validateFile = (params) => {
    if (this.fileuploader.value && this.fileuploader.value.length > 0) {
      const totalFileSize = this.fileuploader.value.reduce((acc, curr) => acc + curr.size, 0);

      if (totalFileSize > 130023424) {
        params.rule.isValid = false;
        params.rule.message = 'File(s) cannot exceed 125 MB.';
        return false;
      } else {
        params.rule.isValid = true;
        return true;
      }
    } else {
      if (this.isRequired) {
        params.rule.isValid = false;
        params.rule.message = 'File(s) are required.';
        return false;
      } else {
        params.rule.isValid = true;
        return true;
      }
    }
  }

  onFileValueChange(e) {
    this.onFileValueChanged.emit();
  }

  resetFiles() {
    this.fileuploader.instance.reset();
  }

}
