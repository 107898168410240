import { TemplateObject } from '../../template-object';

export const PLOF_TEMPLATE: string = '{{#if gait}}Patient ambulated with {{gait}}.{{/if}}' +
'{{#if gaitDistance}} Patient ambulated with {{gaitDistance}} feet.{{/if}}' +
'{{#if bedmob}} Patient performed Bed Mobility with {{bedmob}}.{{/if}}' +
'{{#if transfers}} Patient transferred with {{transfers}}.{{/if}}' +
'{{#if ad}} AD {{ad}}.{{/if}}' +
'{{#if ubDressing}} Patient previously able to complete upper body dressing with {{ubDressing}} .{{/if}}' +
'{{#if lbDressing}} Patient previously able to complete lower body dressing with {{lbDressing}}.{{/if}}' +
'{{#if selfPos}} Patient previously able to complete self-positioning with {{selfPos}}.{{/if}}' +
'{{#if selfFeed}} Patient previously able to self feeding with {{selfFeed}}.{{/if}}' +
'{{#if toilet}} Patient previously able to complete toileting with {{toilet}}.{{/if}}' +
'{{#if bath}} Patient previously able to complete bathing with {{bath}}.{{/if}}' +
'{{#if closetMgmt}} Patient performed closet management with {{closetMgmt}}.{{/if}}' +
'{{#if adaptEquip}} AD {{adaptEquip}}.{{/if}}' +
'{{#if iadls}} Patient previously able to complete iADLs with {{iadls}}.{{/if}}' +
'{{#if swallow}} Patient previously demonstrated swallowing function with {{swallow}}.{{/if}}' +
'{{#if speech}} Patient previously demonstrated speech intelligibility with {{speech}}.{{/if}}' +
'{{#if expressive}} Patient previously demonstrated expressive language skills with {{expressive}}.{{/if}}' +
'{{#if receptive}} Patient previously demonstrated receptive language skills with {{receptive}}.{{/if}}' +
'{{#if cognitive}} Patient previously demonstrated cognitive tasks with {{cognitive}}.{{/if}}' +
'{{#if diet}} Patient previously diet was {{diet}}.{{/if}}' +
'{{#if liquids}} Patient previously liquids were {{liquids}}.{{/if}}' +
'{{#if setting}} Patient lived at {{setting}}.{{/if}}' + 
'{{#if homeOptions}}{{homeOptions}}.{{/if}}' + 
'{{#if careLevel}} Patient {{{careLevel}}}.{{/if}}';

export const PlofTemplateProperties = {
    // PT 
    GAIT: 'gait',
    GAIT_DISTANCE: 'gaitDistance',
    BED_MOBILITIY: 'bedmob',
    AD: 'ad',
    TRANSFERS: 'transfers',
    // OT
    UB_DRESSING: 'ubDressing',
    LB_DRESSING: 'lbDressing',
    SELF_POS: 'selfPos',
    SELF_FEED: 'selfFeed',
    TOILET: 'toilet',
    BATH: 'bath',
    CLOSET_MGMT: 'closetMgmt',
    ADAPT_EQUIP: 'adaptEquip',
    IADLS: 'iadls',
    // ST
    SWALLOW: 'swallow',
    SPEECH: 'speech',
    EXPRESSIVE: 'expressive',
    RECEPTIVE: 'receptive',
    COGNITIVE: 'cognitive',
    DIET: 'diet',
    LIQUIDS: 'liquids',

    SETTING: 'setting',
    HOME_OPTIONS: 'homeOptions',
    CARE_LEVEL: 'careLevel',
};

export class PLOFTemplate implements TemplateObject {
    gait: string = '';
    gaitDistance: string = '';
    bedmob: string = '';
    transfers: string = '';
    ad: string = '';
    ubDressing: string = '';
    lbDressing: string = '';
    selfPos: string = '';
    selfFeed: string = '';
    toilet: string = '';
    bath: string = '';
    closetMgmt: string = '';
    adaptEquip: string = '';
    iadls: string = '';
    // ST
    swallow: string = '';
    speech: string = '';
    expressive: string = '';
    receptive: string = '';
    cognitive: string = '';
    diet: string = '';
    liquids: string = '';
    setting: string = '';
    homeOptions: string = '';
    careLevel: string = '';

    constructor() {}

    preCompileTemplateObj() {}
}
