import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HtmlCharacterEntityUtils } from '@app/utils/html-character-entity.utils';

@Component({
    selector: 'tms-narrative-builder-container',
    templateUrl: './narrative-builder-container.component.html',
    styleUrls: ['./narrative-builder-container.component.scss'],
})
export class NarrativeBuilderContainerComponent {
    @Input() narrative: string;
    @Input() height: string = null;
    @Input() id?: string;
    @Output() onUserInput: EventEmitter<string> = new EventEmitter<string>();

    onTextAreaChange($event) {
        this.onUserInput.emit(this.narrative);
    }

    onValueChanged(_) {
        this.narrative = HtmlCharacterEntityUtils.convertEntitiesToLiterals(this.narrative);
    }
}
