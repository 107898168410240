import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EntityDirtyCheckPlugin, EntityStateHistoryPlugin } from '@datorama/akita';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap, take, switchMap } from 'rxjs/operators';
import {
  eTmsStepStage, OperationResponse, PagedResult, StartupSlim, Startup,
  StartupTeamMember, TmsStepGroup, StartupClientContact, StartupDistributee, CloseOut
} from '@app/model';
import { DeepCopyUtils, ErrorMessageUtils } from '@app/utils';
import { CacheManService } from './cacheman.service';
import { DocumentRecordsService } from './document-records.service';
import { UrlConstantsService } from './url-constants.service';
import { StartupsQuery, StartupsStore } from '../stores/startups';

@Injectable({
  providedIn: 'root'
})
export class StartupsService {
  collection: EntityStateHistoryPlugin;
  dirtyCheckCollection: EntityDirtyCheckPlugin;

  constructor(private cacheManService: CacheManService,
    private documentRecordsService: DocumentRecordsService,
    private httpClient: HttpClient,
    private startupsQuery: StartupsQuery,
    private startupsStore: StartupsStore,
    private urlConstantsService: UrlConstantsService) { }

  activeStartup$(): Observable<any>;
  activeStartup$(property: string): Observable<any>;
  activeStartup$<T>(property?: string): Observable<T>;
  activeStartup$<T>(property?: string): Observable<any> | Observable<T> {
    if (property) {
      return this.startupsQuery.selectActive(entity => entity[property]);
    } else {
      return this.startupsQuery.selectActive();
    }
  }

  cancelStartupUpdates() {
    this.collection.jumpToPast(this.startupsQuery.getActiveId(), 0);
    this.collection.clear(this.startupsQuery.getActiveId());
    this.dirtyCheckCollection.reset();
  }

  getStartups(isArchived: boolean): Observable<StartupSlim[]> {
    return this.httpClient.get<PagedResult<StartupSlim>>(`${this.urlConstantsService.STARTUPS_URL}?isArchived=${isArchived}`)
      .pipe(map(response => response.items));
  }

  getStartupById(id: string): Observable<Startup> {
    return this.httpClient.get<Startup>(`${this.urlConstantsService.STARTUPS_URL}/${id}`)
      .pipe(tap(startup => {
        this.collection = new EntityStateHistoryPlugin(this.startupsQuery, { entityIds: id, maxAge: 20 });
        this.startupsStore.upsert(id, () => startup);
        this.startupsStore.setActive(id);
        this.dirtyCheckCollection = new EntityDirtyCheckPlugin(this.startupsQuery);
        this.dirtyCheckCollection.setHead();
      }))
  }

  updateStartup(): Observable<Startup> {
    if (this.startupsQuery.getActiveId() && this.dirtyCheckCollection.someDirty()) {
      return this.httpClient.put<OperationResponse<Startup>>(`${this.urlConstantsService.STARTUPS_URL}/${this.startupsQuery.getActiveId()}`, this.startupsQuery.getActive())
        .pipe(catchError(err => throwError(ErrorMessageUtils.getDisplayErrorMessage(err, 'An error occurred saving startup'))),
          map(response => response.data),
          tap(data => {
            this.startupsStore.updateActive(entity => {
              return data;
            });
            this.collection = new EntityStateHistoryPlugin(this.startupsQuery, { entityIds: this.startupsQuery.getActiveId(), maxAge: 20 });
            this.dirtyCheckCollection.setHead();
          }));
    } else {
      return of(null);
    }
  }

  addStartupClientContact(clientContact: StartupClientContact) {
    this.startupsStore.updateActive(entity => {
      return {
        ...entity,
        clientInfo: {
          ...entity.clientInfo,
          clientContacts: [
            ...entity.clientInfo.clientContacts,
            clientContact,
          ],
        },
      }
    });
  }

  editStartupClientContact(clientContact: StartupClientContact) {
    this.startupsStore.updateActive(entity => {
      const memberIndex = entity.clientInfo.clientContacts.findIndex(c => c.id == clientContact.id);
      if (memberIndex >= 0) {
        return {
          ...entity,
          clientInfo: {
            ...entity.clientInfo,
            clientContacts: [
              ...entity.clientInfo.clientContacts.slice(0, memberIndex),
              clientContact,
              ...entity.clientInfo.clientContacts.slice(memberIndex + 1),
            ]
          }
        }
      }
    });
  }

  addStartupDistributee(distributee: StartupDistributee) {
    this.startupsStore.updateActive(entity => {
      return {
        ...entity,
        distributees: [
          ...entity.distributees,
          distributee,
        ],
      }
    });
  }

  editStartupDistributee(distributee: StartupDistributee) {
    this.startupsStore.updateActive(entity => {
      const index = entity.distributees.findIndex(d => d.id == distributee.id);
      if (index >= 0) {
        return {
          ...entity,
          distributees: [
            ...entity.distributees.slice(0, index),
            distributee,
            ...entity.distributees.slice(index + 1),
          ]
        }
      }
    });
  }

  deleteStartupDistributee(id: string) {
    this.startupsStore.updateActive(entity => {
      const index = entity.distributees.findIndex(d => d.id == id);
      if (index >= 0) {
        return {
          ...entity,
          distributees: [
            ...entity.distributees.slice(0, index),
            ...entity.distributees.slice(index + 1),
          ],
        }
      }
    })
  }

  addStartupInfoMember(member: StartupTeamMember) {
    this.startupsStore.updateActive(entity => {
      return {
        ...entity,
        startupInfo: {
          ...entity.startupInfo,
          teamMembers: [
            ...entity.startupInfo.teamMembers,
            member,
          ],
        },
      }
    });
  }

  editStartupInfoMember(member: StartupTeamMember) {
    this.startupsStore.updateActive(entity => {
      const memberIndex = entity.startupInfo.teamMembers.findIndex(tm => tm.id == member.id);
      if (memberIndex >= 0) {
        return {
          ...entity,
          startupInfo: {
            ...entity.startupInfo,
            teamMembers: [
              ...entity.startupInfo.teamMembers.slice(0, memberIndex),
              member,
              ...entity.startupInfo.teamMembers.slice(memberIndex + 1),
            ]
          }
        }
      }
    });
  }

  addStartupNote(note: string): Observable<Startup> {
    return this.cacheManService.getCurrentUser()
      .pipe(switchMap(user => {
        this.startupsStore.updateActive(entity => {
          return {
            ...entity,
            facilityDetails: {
              ...entity.facilityDetails,
              startupNotes: [
                ...entity.facilityDetails.startupNotes,
                {
                  note: note,
                  updatedOn: new Date(),
                  updatedByUserId: user?.id,
                  updatedByUserName: user ? `${user?.lastName}, ${user?.firstName}` : '',
                },
              ],
            }

          };
        });
        return this.updateStartup();
      }));
  }

  editStartupNote(id: string, note: string): Observable<Startup>  {
    return this.cacheManService.getCurrentUser()
      .pipe(switchMap(user => {
        this.startupsStore.updateActive(entity => {
          const memberIndex = entity.facilityDetails.startupNotes.findIndex(tm => tm.id == id);
          if (memberIndex >= 0) {
            return {
              ...entity,
              facilityDetails: {
                ...entity.facilityDetails,
                startupNotes: [
                  ...entity.facilityDetails.startupNotes.slice(0, memberIndex),
                  {
                    note: note,
                    updatedOn: new Date(),
                    updatedByUserId: user?.id,
                    updatedByUserName: user ? `${user?.lastName}, ${user?.firstName}` : '',
                  },
                  ...entity.facilityDetails.startupNotes.slice(memberIndex + 1),
                ],
              }
            };
          }
        });
        return this.updateStartup();
      }));
  }

  deleteStartupNote(id: string): Observable<Startup>  {
    this.startupsStore.updateActive(entity => {
      const memberIndex = entity.facilityDetails.startupNotes.findIndex(tm => tm.id == id);
      if (memberIndex >= 0) {
        return {
          ...entity,
          facilityDetails: {
            ...entity.facilityDetails,
            startupNotes: [
              ...entity.facilityDetails.startupNotes.slice(0, memberIndex),
              ...entity.facilityDetails.startupNotes.slice(memberIndex + 1),
            ]
          },
        }
      }
    });
    return this.updateStartup();
  }

  updateActiveStartup<T>(property: string, object: T) {
    this.cacheManService.getCurrentUser()
      .pipe(take(1))
      .subscribe(user => {
        this.startupsStore.updateActive(entity => {
          return {
            ...entity,
            [property]: {
              ...DeepCopyUtils.deepCopyObject(object),
              updatedByUserId: user?.id,
              updatedByUserName: user ? `${user?.firstName} ${user?.lastName}` : '',
              updatedOn: new Date(),
            },
          }
      })
    })
  }

  uploadStartupDocument(fileName: string, documentType: string, documentCategoryId: string, file: any) {
    const facilityId = this.startupsQuery.getActive().facilityId;
    return this.documentRecordsService.uploadDocumentByFacilityId(facilityId, fileName, documentType, documentCategoryId, file)
      .pipe(tap(documentRecord => {
        // add document record to store
        this.startupsStore.updateActive(entity => {
          return {
            ...entity,
            documents: [
              ...entity.documents,
              {
                id: documentRecord.id,
                documentName: documentRecord.friendlyName,
                documentDate: documentRecord.documentDate,
              }
            ]
          }
        })
      }))
  }

  deleteStartupDocument(documentRecordId: string) {
    return this.documentRecordsService.deleteDocument(documentRecordId, "0")
        .pipe(tap(response => {
          this.startupsStore.updateActive(entity => {
            const index = entity.documents.findIndex(doc => doc.id === documentRecordId);
            if (index >= 0) {
              return {
                ...entity,
                documents: [
                  ...entity.documents.slice(0, index),
                  ...entity.documents.slice(index+1),
                ]
              }
            }
          })
        }));
  }

  getStartupSteps(): TmsStepGroup[] {
    const activeStartup = this.startupsQuery.getActive();
    return [
      {
        title: 'Startup Details',
        steps: [
          {
            name: 'Facility',
            stage: this.getTmsStepStage(activeStartup.facilityDetails.isValid),
            step: 0,
          },
          {
            name: 'Staffing',
            stage: this.getTmsStepStage(activeStartup.staffing.isValid),
            step: 1,
          },
          {
            name: 'Startup Info',
            stage: this.getTmsStepStage(activeStartup.startupInfo.isValid),
            step: 2,
          },
          {
            name: 'Client Info',
            stage: this.getTmsStepStage(activeStartup.clientInfo.isValid),
            step: 3,
          },
          {
            name: 'IT',
            stage: this.getTmsStepStage(activeStartup.it.isValid),
            step: 4,
          },
          {
            name: 'Software',
            stage:  this.getTmsStepStage(activeStartup.software.isValid),
            step: 5,
          },
          {
            name: 'Billing',
            stage: this.getTmsStepStage(activeStartup.billing.isValid),
            step: 6,
          },
          {
            name: 'Admin',
            stage: this.getTmsStepStage(activeStartup.admin.isValid),
            step: 7,
          },
          {
            name: 'HR',
            stage: this.getTmsStepStage(activeStartup.hr.isValid),
            step: 8,
          },
        ]
      }
    ]
  }

  private getTmsStepStage(isComplete: boolean): eTmsStepStage {
    return isComplete ? eTmsStepStage.complete : eTmsStepStage.incomplete;
  }

}
