export enum Modality {
    IceHeat = 1,
    Estim = 2,
    ShortWaveDiathermy = 3,
    Traction = 4,
    Ionto = 5,
    Infrared = 6,
    Whirlpool = 7,
    Paraffin = 8,
    Ultrasound = 9,
    PTNS = 10,
    Ultraviolet = 11,
}
