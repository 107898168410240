import { TemplateObject } from '../../template-object';

export const PatientGoalsProperties = {
    RETURN_PLOF: 'returnPLOF',
    RETURN_HOME: 'returnHome',
    SELF_CARE: 'selfCare',
    WALK_SAFELY: 'walkSafely',
    NONVERBAL: 'nonverbal',
    UNCLEAR: 'unclear',
};

export const PATIENT_GOALS_TEMPLATE: string = '{{#if stringList}}{{/if}}{{stringList}}';

export class PatientGoalsTemplate implements TemplateObject {
    returnPLOF: string;
    returnHome: string;
    selfCare: string;
    walkSafely: string;
    nonverbal: string;
    unclear: string;
    stringList: string[] =[];

    private toList = ['returnPLOF', 'returnHome', 'selfCare', 'walkSafely', 'nonverbal', 'unclear'];

    constructor() {
        this.returnPLOF = '';
        this.returnHome = '';
        this.selfCare = '';
        this.walkSafely = '';
        this.nonverbal = '';
        this.unclear = '';
    }

    preCompileTemplateObj(): void {
        this.stringList = [];
        this.toList.forEach(value => {
            if (this[value]) {
                this.stringList.push(' ' + this[value]);
            }
        });
    }
}
