import { Subject } from 'rxjs';

export abstract class OnDestroyComponent {
    protected onDestroy$: Subject<void> = new Subject<void>();

    constructor() {}

    onDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}