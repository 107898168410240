import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { OperationResponse, DenialNotice, DocumentRecord } from '@app/model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentRecordsService } from './document-records.service';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class DenialNoticeService {
    constructor(private http: HttpClient,
        private documentRecordsService: DocumentRecordsService,
		private urlConstantsService: UrlConstantsService) {
    }

    getAll(facilityId: string): Observable<DenialNotice[]> {
		  return this.http.get<OperationResponse<DenialNotice[]>>(`${this.urlConstantsService.DENIAL_NOTICE_URL}?facilityId=${facilityId}`)
			    .pipe(map(response => response.data));
    }
    
    getById(denialNoticeId: string) {
      return this.http.get<OperationResponse<DenialNotice>>(`${this.urlConstantsService.DENIAL_NOTICE_URL}/${denialNoticeId}`)
			    .pipe(map(response => response.data));
    }

    addNew(notice: DenialNotice) {
		  return this.http.post<OperationResponse<DenialNotice>>(`${this.urlConstantsService.DENIAL_NOTICE_URL}`, notice)
            .pipe(map(response => response.data))
    }

    uploadDocument(fileName: string, documentRecordCategoryId: string, file: any, notice: DenialNotice): Observable<DocumentRecord> {
       return this.documentRecordsService.uploadDenialNoticeDocument(notice.patientId, notice.facilityId, fileName, documentRecordCategoryId, file);
    }

    getAllDenialNotices(): Observable<DenialNotice[]> {
		  return this.http.get<OperationResponse<DenialNotice[]>>(`${this.urlConstantsService.DENIAL_NOTICE_URL}/clientnotices`)
			    .pipe(map(response => response.data));
    }

    updateDenialNoticeInitiatedDate(id: string, dt: Date): Observable<boolean> {
      const date = dt != null ?  moment(dt).toDate() : null;
      const month =  date != null ? date.getMonth() + 1 : 0;
      const day = date != null ?  date.getDate() : 0;
      const year = date != null ? date.getFullYear() : 0;
      return this.http.put<OperationResponse<boolean>>(`${this.urlConstantsService.DENIAL_NOTICE_URL}/${id}/initiateddate?month=${month}&day=${day}&year=${year}`, null)
        .pipe(map(response => response.data));
    }
}