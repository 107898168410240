import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { map, take, filter } from 'rxjs/operators';
import { Facility, User, PersonalProfile, DataListItem } from '@app/model';
import { CacheManQuery } from '@app/store';
import { DeepCopyUtils } from '@app/utils';
import { CacheManService, DataListConstants, RegularExpressionConstants } from '@app/core';

@Component({
  selector: 'tms-user-basic-profile',
  templateUrl: './user-basic-profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserBasicProfileComponent implements OnChanges {
  @Input() user: User;
  @Output() updateUser: EventEmitter<User> = new EventEmitter();

  currentUser: User;
  profileForm: PersonalProfile = {};

  selectedPrimaryFacility: string;
  primaryFacilitySource: Facility[] = [];

  selectedFacilityValues: string[] = [];
  otherFacilitySource: any[] = [];

  disciplines: DataListItem[];
  bCanUpdateDiscipline: boolean = false;

  pinPattern: any = RegularExpressionConstants.pinPattern;
  pinComparison = () => {
    return this.profileForm.newPinCode;
  };

  constructor(private cacheManQuery: CacheManQuery,
    private cacheManService: CacheManService) {
    this.getFacilityItems();
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['user'].currentValue) {
      this.currentUser = DeepCopyUtils.deepCopyObject(this.user);

      if (!this.currentUser.employee) {
        this.currentUser.employee = { };
      }

      this.setFormFields();
    }
  }

  setFormFields() {
    this.profileForm.firstName = this.currentUser.firstName;
    this.profileForm.lastName = this.currentUser.lastName;
    this.profileForm.employee = {
      discipline: this.currentUser.employee?.discipline,
    };
    this.profileForm.pinCode = '';
    this.profileForm.newPinCode = '';
    this.profileForm.confirmPinCode = '';

    if (this.currentUser.facilityIds.length > 0) {
      this.selectedPrimaryFacility = this.currentUser.facilityIds[0];
      this.selectedFacilityValues = this.currentUser.facilityIds.slice(1);
    }

    this.bCanUpdateDiscipline = this.currentUser.employee.availableDisciplines?.length > 1;

    this.cacheManService.getDataListItems$(DataListConstants.DISCIPLINES)
      .pipe(take(1))
      .subscribe(disciplines => {
        this.disciplines = disciplines;
        // only disciplines from employee.availalbeDisciplines should be in list 
        if (this.bCanUpdateDiscipline) {
          this.disciplines = this.disciplines.filter(dli => this.currentUser.employee.availableDisciplines.findIndex(d => d.discipline == dli.valueInt) >= 0);
        }
      });
  }

  getFacilityItems() {
    this.cacheManQuery.select(state => state.facilities)
      .pipe(
        take(1),
      )
      .subscribe((response) => {
        this.primaryFacilitySource = response;

        response.forEach(facility => {
          this.otherFacilitySource.push({ name: facility.name, id: facility.id, disabled: false });
        });
      });
  }

  onSubmit() {
    this.currentUser.firstName = this.profileForm.firstName;
    this.currentUser.lastName = this.profileForm.lastName;
    this.currentUser.pinCode = this.profileForm.pinCode;

    if (this.profileForm.newPinCode !== '') {
      this.currentUser.updatedPinCode = this.profileForm.newPinCode;
    }

    if (this.bCanUpdateDiscipline) {
      this.currentUser.employee.discipline = this.profileForm.employee.discipline;
    }

    this.updateUser.emit(this.currentUser);
  }
}
