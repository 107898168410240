import { Dictionary, NarrativeBuilderList, NarrativeBuilderOption, TemplateTextOptions } from "@app/model";
import * as idb from './id-builder-utils';

export class NarrativeBuilderListUtils {
    static getNarrativeBuilderList(idConstant: string, templateTextOptionsDictionary: Dictionary<TemplateTextOptions>): NarrativeBuilderList {
        const dictionaryKeys = Object.keys(templateTextOptionsDictionary);
        let nbOptionDictionary: Dictionary<NarrativeBuilderOption> = {};
        dictionaryKeys.forEach(key => {
            nbOptionDictionary = {
                ...nbOptionDictionary,
                [templateTextOptionsDictionary[key].templatePropertyName]: {
                        id: key,
                        text: templateTextOptionsDictionary[key].displayText,
                        values: [
                            {
                                id: idb.getNBOptionValueId(idConstant, key),
                                optionValues: [
                                    {
                                        id: idb.getNBOptionValueTextId(idConstant, key, 1),
                                        text: templateTextOptionsDictionary[key].displayValueText,
                                    }
                                ]
                            }
                        ],
                        selectedValue: null,
                        property: templateTextOptionsDictionary[key].templatePropertyName,
                    },
            }
        });
        
        const nbl: NarrativeBuilderList = {
            options: nbOptionDictionary,
        };
        return nbl;
    }
}
