export enum ePreauthorizationObtainedBy {
    Unspecified = 0,
    
    // Facility obtained authorization
    Facility = 1,

    // Facility needs TMC department to obtain authorization
    Department = 2,

    // Facility is using a pre-auth payer, but authorization is not required
    NotRequired = 3,

    // Facility has not obtained authorization, but acknowledges they will still TMC for services rendered
    FacilityAdministratorApproved = 4
}