import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { HttpClient } from '@angular/common/http';
import { ParallelHasher } from 'ts-md5/dist/parallel_hasher';
import { Observable, from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { DocumentRecordsStore } from '@app/store';
import { DocumentRecord, OperationResponse, PagedResult, SignatureRequest, SignatureResponse, DocumentLinkResponse, DenialNotice } from '@app/model';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { ErrorMessageUtils } from '@app/utils';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class DocumentRecordsService {

  constructor(private documentRecordsStore: DocumentRecordsStore,
    private http: HttpClient,
    private toastMessageService: ToastMessageService,
    private urlConstantsService: UrlConstantsService) {
  }

  deletePatientDocument(patientId: string, documentId: string) {
    this.http.delete(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/documents/${documentId}`)
      .subscribe((response) => {
        this.remove(documentId);
        this.toastMessageService.successNotification('Document deleted successfully');
      },
        (error) => this.toastMessageService.errorNotification('An error occurred while trying to delete document')
      );
  }

  getAllPatientDocuments(patientId: string) {
    this.http.get<OperationResponse<DocumentRecord[]>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/documents`)
      .pipe(map(response => response.data))
      .subscribe((response: DocumentRecord[]) => {
        if (response) {
          this.documentRecordsStore.set(response);
        }
      });
  }

  getAllPatientDocumentsByCaseId(patientId: string, caseId: string = '') {
    return this.http.get<OperationResponse<DocumentRecord[]>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/documents/case/${caseId}`)
      .pipe(map(response => response.data));
  }

  getPatientDocument(patientId: string, documentId: string) {
    this.http.get<DocumentRecord>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/documents/${documentId}`)
      .subscribe((response: DocumentRecord) => {
        this.update(response.id, response);
        this.documentRecordsStore.setActive(response.id);
      });
  }

  uploadPatientDocument(patientId: string, fileName: string, friendlyName: string, documentCategoryId: string, documentDate: Date, 
    file: any, episodeId: string = '', caseId: string = '', preauthorizationRequestId: string = '') {
    const hasher = new ParallelHasher('assets/js/ts-md5/md5_worker.js');
    this.documentRecordsStore.setLoading(true);
    hasher.hash(file)
      .then(result => {
        const formData = new FormData();
        formData.append('Filename', fileName);
        formData.append('FriendlyName', friendlyName);
        formData.append('DocumentRecordCategoryId', documentCategoryId);
        formData.append('DocumentDate', documentDate.toDateString());
        formData.append('PatientEpisodeId', episodeId);
        formData.append('PatientCasedId', caseId);
        formData.append('PreauthorizationRequestId', preauthorizationRequestId);
        formData.append('FileHash', result);
        formData.append('', file, fileName);
        this.http.post<OperationResponse<DocumentRecord>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/documents`, formData)
          .subscribe((response) => {
            this.add(response.data);
          }, (error) => {
            let msg = ErrorMessageUtils.getDisplayErrorMessage(error, 'Error uploading file');
            this.documentRecordsStore.setError(msg);
          }, () => {
            this.documentRecordsStore.setLoading(false);
          });
      });
  }

  uploadDenialNoticeDocument(patientId: string, facilityId: string, fileName: string, documentCategoryId: string, file: any): Observable<DocumentRecord> {
    const hasher = new ParallelHasher('assets/js/ts-md5/md5_worker.js');
    const hasher$: Observable<any> = from(hasher.hash(file));
    return hasher$
      .pipe(map(result => {
        const formData = new FormData();
        formData.append('Filename', fileName);
        formData.append('FriendlyName', '');
        formData.append('DocumentRecordCategoryId', documentCategoryId);
        formData.append('FacilityId', facilityId);
        formData.append('PatientId', patientId);
        formData.append('FileHash', result);
        formData.append('', file, fileName);
        return formData
      }),
        switchMap(formData => this.http.post<OperationResponse<DocumentRecord>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/documents`, formData)),
        map(response => response.data));
  }

  uploadDenialClaimDocument(patientId: string, denialClaimId: string, fileName: string, documentCategoryId: string, documentSubCategory: string, documentDate: Date, facilityId: string, file: any): Observable<DocumentRecord> {
    const hasher = new ParallelHasher('assets/js/ts-md5/md5_worker.js');
    const hasher$: Observable<any> = from(hasher.hash(file));
    return hasher$
      .pipe(map(result => {
        const formData = new FormData();
        formData.append('Filename', fileName);
        formData.append('DocumentRecordCategoryId', documentCategoryId);
        formData.append('PatientId', patientId);
        formData.append('DocumentDate', documentDate.toDateString());
        formData.append('FriendlyName', documentSubCategory);
        formData.append('DenialClaimId', denialClaimId);
        formData.append('FileHash', result);
        formData.append('FacilityId', facilityId);
        formData.append('', file, fileName);
        return formData
      }),
        switchMap(formData => this.http.post<OperationResponse<DocumentRecord>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/documents`, formData)),
        map(response => response.data));
  }

  uploadDocumentByFacilityId(facilityId: string, fileName: string, documentType: string, documentCategoryId: string, file: any): Observable<DocumentRecord> {
    const hasher = new ParallelHasher('assets/js/ts-md5/md5_worker.js');
    const hasher$: Observable<any> = from(hasher.hash(file));
    return hasher$
      .pipe(map(result => {
        const formData = new FormData();
        formData.append('Filename', fileName);
        formData.append('FriendlyName', documentType);
        formData.append('DocumentRecordCategoryId', documentCategoryId);
        formData.append('FacilityId', facilityId);
        formData.append('FileHash', result);
        formData.append('', file, fileName);
        return formData
      }),
        switchMap(formData => this.http.post<OperationResponse<DocumentRecord>>(`${this.urlConstantsService.PATIENTS_URL}/0/documents`, formData)),
        map(response => response.data));
  }

  uploadPatientEChartDocument(patientId: string, fileName: string, friendlyName: string, documentCategoryId: string, 
    documentDate: Date, patientCaseId: string, file: any): Observable<DocumentRecord> {
    const hasher = new ParallelHasher('assets/js/ts-md5/md5_worker.js');
    const hasher$: Observable<any> = from(hasher.hash(file));
    return hasher$
      .pipe(map(result => {
        const formData = new FormData();
        formData.append('Filename', fileName);
        formData.append('FriendlyName', friendlyName);
        formData.append('DocumentRecordCategoryId', documentCategoryId);
        formData.append('DocumentDate', moment(documentDate).format('L'));
        formData.append('PatientId', patientId);
        formData.append('PatientCasedId', patientCaseId);
        formData.append('FileHash', result);
        formData.append('', file, fileName);
        formData.append('IsEChartFile', 'true');
        return formData
      }),
        switchMap(formData => this.http.post<OperationResponse<DocumentRecord>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/documents`, formData)),
        map(response => response.data));
  }

  uploadDocumentRecord(documentRecord: DocumentRecord, file: any): Observable<DocumentRecord> {
    const formData = this.documentRecordFormBuilder(documentRecord);
    const hasher = new ParallelHasher('assets/js/ts-md5/md5_worker.js');
    const hasher$: Observable<any> = from(hasher.hash(file));
    return hasher$
      .pipe(map(result => {
        formData.append('FileHash', result);
        formData.append('', file, documentRecord.fileName);
        return formData
        }),
        switchMap(formData => {
          const patientId = documentRecord.patientId != null ? documentRecord.patientId : 0;
          return this.http.post<OperationResponse<DocumentRecord>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/documents`, formData)
        }),
        map(response => response.data));
  }

  private documentRecordFormBuilder(documentRecord: DocumentRecord): FormData {
    const documentRecordKVs = Object.keys(documentRecord);
    const formData = new FormData();
    documentRecordKVs.forEach(key => {
      switch(key) {
        case 'denialClaimId': formData.append('DenialClaimId', documentRecord.denialClaimId); break;
        case 'documentDate': formData.append('DocumentDate', moment(documentRecord.documentDate).format('L')); break;
        case 'documentName': formData.append('DocumentName', documentRecord.documentName); break;
        case 'documentRecordCategoryId': formData.append('DocumentRecordCategoryId', documentRecord.documentRecordCategoryId); break;
        case 'facilityId': formData.append('FacilityId', documentRecord.facilityId); break;
        case 'fileName': formData.append('Filename', documentRecord.fileName); break;
        case 'friendlyName': formData.append('FriendlyName', documentRecord.friendlyName); break;
        case 'knowledgeBaseItemId': formData.append('KnowledgeBaseItemId', documentRecord.knowledgeBaseItemId); break;
        case 'patientId': formData.append('PatientId', documentRecord.patientId); break;
        case 'patientCasedId': formData.append('PatientCasedId', documentRecord.patientCasedId); break;
        case 'patientEpisodeId': formData.append('PatientEpisodeId', documentRecord.patientEpisodeId); break;
        case 'preauthorizationRequestId': formData.append('PreauthorizationRequestId', documentRecord.preauthorizationRequestId); break;
        case 'isEChartFile' : formData.append('IsEChartFile', documentRecord.isEChartFile ? 'true' : 'false'); break;
      }
    });
    return formData;
  }

  uploadMedFeeScheduleImportDocument(fileName: string, file: any, effectiveDate: Date, percentReduction: number) {
    const hasher = new ParallelHasher('assets/js/ts-md5/md5_worker.js');
    this.documentRecordsStore.setLoading(true);
    hasher.hash(file)
      .then(result => {
        const formData = new FormData();
        formData.append('Filename', fileName);
        formData.append('FileHash', result);
        formData.append('DocumentDate', moment(effectiveDate).format('L'));
        formData.append('PercentReduction', percentReduction.toString());
        formData.append('', file, fileName);
        this.http.post<OperationResponse<DocumentRecord>>(`${this.urlConstantsService.MED_FEE_SCHEDULE_URL}/import`, formData)
          .subscribe((response) => {
            this.add(response.data);
          }, (error) => {
            let msg = ErrorMessageUtils.getDisplayErrorMessage(error, 'Error uploading file');
            this.documentRecordsStore.setError(msg);
          }, () => {
            this.documentRecordsStore.setLoading(false);
          });
      });
  }

  deleteDocument(documentRecordId: string, patientId: string) {
    return this.http.delete(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/documents/${documentRecordId}`);
  }

  getDocument(documentRecordId: string): Observable<DocumentRecord> {
    return this.http.get<DocumentRecord>(`${this.urlConstantsService.DOCUMENTS_URL}/${documentRecordId}`);
  }

  getDocumentDownloadLink(documentRecordId: string) {
    return this.http.get<DocumentLinkResponse>(`${this.urlConstantsService.DOCUMENTS_URL}/${documentRecordId}/link`)
      .pipe(map(response => response.link));
  }

  getDocumentPacketDownloadLink(documentRecordIds: string[]) {
    return this.http.put<OperationResponse<string>>(`${this.urlConstantsService.DOCUMENTS_URL}/packet`, documentRecordIds)
      .pipe(map(response => response.data));
  }

  getDocumentPacketData(documentRecordIds: string[]) {
    return this.http.put<OperationResponse<DocumentRecord>>(`${this.urlConstantsService.DOCUMENTS_URL}/packetdata`, documentRecordIds)
      .pipe(map(response => response.data));
  }

  openDocument(documentRecordId: string) {
    return this.getDocumentDownloadLink(documentRecordId)
      .pipe(tap(response => window.open(response)));
  }

  signDocument(documentRecordId: string, signatureRequest: SignatureRequest): Observable<SignatureResponse> {
    return this.http.post<OperationResponse<SignatureResponse>>(`${this.urlConstantsService.DOCUMENTS_URL}/${documentRecordId}/sign`, signatureRequest)
      .pipe(map(response => response.data));
  }

  add(documentRecord: DocumentRecord) {
    this.documentRecordsStore.add(documentRecord);
  }

  update(id, documentRecord: Partial<DocumentRecord>) {
    this.documentRecordsStore.update(id, documentRecord);
  }

  remove(id: ID) {
    this.documentRecordsStore.remove(id);
  }
}
