import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { User } from '@app/model';

export interface UserState extends EntityState<User>, ActiveState { }

const initialState: UserState = {
  active: null,
  loading: false,
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user' })
export class UserStore extends EntityStore<UserState> {
  constructor() {
    super(initialState);
  }
}

