import { Component, ChangeDetectionStrategy, OnDestroy, OnInit } from '@angular/core';
import { OnDestroyComponent } from '@app/shared';

@Component({
  selector: 'tms-home-dashboard',
  styleUrls: ['./home-dashboard.component.scss'],
  templateUrl: './home-dashboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeDashboardComponent extends OnDestroyComponent implements OnInit, OnDestroy {
  activeTabIndex: number = 0;
  tabs: any[] = [
    {
      text: 'Home',
    },
    {
      text: 'Support',
    },
  ];

  constructor() {
    super();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.onDestroy();
  }

}
