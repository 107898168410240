import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { CacheManService} from './cacheman.service';
import { DocumentCategoryConstants } from '../constants';
import { DocumentRecordsService } from './document-records.service';
import { UrlConstantsService } from './url-constants.service';
import { KnowledgeBaseItem, DocumentRecord, OperationResponse, PagedResult } from '@app/model';

@Injectable({
  providedIn: 'root'
})
export class KnowledgeBaseService {

  constructor(private http: HttpClient,
    private cacheManService: CacheManService,
    private documentRecordsService: DocumentRecordsService,
    private urlConstantsService: UrlConstantsService) { }

  getKnowledgeBaseItems(): Observable<KnowledgeBaseItem[]> {
    return this.http.get<PagedResult<KnowledgeBaseItem>>(`${this.urlConstantsService.KNOWLEDGE_BASE_URL}`)
      .pipe(map(response => response.items));
  }

  addKnowledgeBaseItem(item: KnowledgeBaseItem, fileName: string, file: any) {
    return this.http.post<OperationResponse<KnowledgeBaseItem>>(`${this.urlConstantsService.KNOWLEDGE_BASE_URL}`, item)
      .pipe(switchMap(kbItem => this.uploadKnowledgeBaseItem(kbItem.data, fileName, file)));
  }

  addKnowledgeBaseItemVideo(item: KnowledgeBaseItem) {
    return this.http.post<OperationResponse<KnowledgeBaseItem>>(`${this.urlConstantsService.KNOWLEDGE_BASE_URL}`, item)
      .pipe(map(response => response.data));
  }

  updateKnowledgeBaseItem(item: KnowledgeBaseItem): Observable<KnowledgeBaseItem> {
    return this.http.put<OperationResponse<KnowledgeBaseItem>>(`${this.urlConstantsService.KNOWLEDGE_BASE_URL}/${item.id}`, item)
      .pipe(map(response => response.data));
  }

  deleteKnowledgeBaseItem(item: KnowledgeBaseItem) {
    return this.http.delete<OperationResponse<void>>(`${this.urlConstantsService.KNOWLEDGE_BASE_URL}/${item.id}`);
  }

  private uploadKnowledgeBaseItem(item: KnowledgeBaseItem, fileName: string, file: any): Observable<DocumentRecord> {
    return this.cacheManService.getDocumentCategories$()
      .pipe(switchMap(categories => {
        const index = categories.findIndex(category => category.shortName == DocumentCategoryConstants.KNOWLEDGE_BASE);
        if (index >= 0) {
          const docRecord: DocumentRecord = {
            fileName: fileName,
            friendlyName: item.name,
            documentRecordCategoryId: categories[index].id,
            fileHash: file,
            knowledgeBaseItemId: item.id,
          }
          return this.documentRecordsService.uploadDocumentRecord(docRecord, file)
        }
      }))
  }
}
