<tms-warning-modal 
  cancelText="Log Out" 
  actionText="Continue Session" 
  (onCancel)="onNoClicked()" 
  (onTakeAction)="onYesClicked()">
    <div warning-title>You're about to be logged out!</div>
    <div warning-content style="white-space: pre-line;">
        Your session will expire in {{ autoLogoutCountdown }}
    </div>
</tms-warning-modal>
