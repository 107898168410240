import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tms-confirmation-modal',
  templateUrl: './tms-confirmation-modal.component.html',
  styleUrls: ['./tms-confirmation-modal.component.scss'],
})
export class TmsConfirmationModalComponent implements OnInit {
  @Input() actionButtonVisible: boolean = true;
  @Input() actionText: string = 'Yes';
  @Input() cancelButtonVisible: boolean = true;
  @Input() cancelText: string = 'No';
  @Input() message: string;
  @Input() showWarningIcon: boolean = false;
  @Input() title: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  onYesClicked() {
    this.activeModal.close();
  }

  onNoClicked() {
    this.activeModal.dismiss();
  }

}
