import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CloseOut } from '@app/model';

export interface CloseOutState extends EntityState<CloseOut>, ActiveState {}

const initialState: CloseOutState = {
  active: null,
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'closeouts' })
export class CloseOutsStore extends EntityStore<CloseOutState> {

  constructor() {
    super(initialState);
  }

}