<tms-modal submitButtonText="Sign Document"
    [submitButtonDisabled]="signDocumentButtonDisabled"
    [submitButtonVisible]="!viewOnly"
    [cancelButtonText]="downloadButtonText"
    [cancelButtonVisible]="canDownload"
    (onClose)="onClose()" 
    (onCancel)="onDownloadDocument($event)"
    (onSubmit)="onSignDocument($event)">
    <div *ngIf="!viewOnly" class="tms-doc-viewer-confirmation">
        <div class="tms-doc-viewer-statement-text">
            I hereby certify that I have reviewed the documents and approve the content.
            <dx-check-box (onValueChanged)="onCheckBoxValueChanged($event)"></dx-check-box>
        </div>
    </div>
    <div>
        <pdf-viewer *ngIf="pdfSrc" [src]="pdfSrc" [original-size]="false" (error)="onError($event)">
        </pdf-viewer>
        <div *ngIf="imageSrc">
            <img [src]="imageSrc" alt="Image preview not available"/>
        </div>
        <div *ngIf="pdfError">
            <h3>Unable to display document in viewer. Please click the download button to retrieve document.</h3>
        </div>
        <div *ngIf="pinCodeRequired" class="tms-doc-viewer-pinCode">
            <div class="tms-field">
                <div class="tms-field-label">
                    PinCode
                </div>
                <div class="tms-field-value">
                    <dx-text-box mode="password" (onValueChanged)="onPinCodeChanged($event)"></dx-text-box>
                </div>
            </div>
        </div>
    </div>
</tms-modal>