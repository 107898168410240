<tms-warning-modal
  [cancelButtonVisible]="cancelButtonVisible"
  [actionButtonVisible]="actionButtonVisible"
  [cancelText]="cancelText" 
  [actionText]="actionText" 
  [showWarningIcon]="showWarningIcon" 
  (onCancel)="onNoClicked()" 
  (onTakeAction)="onYesClicked()">
    <div warning-title>{{ title }}</div>
    <div warning-content style="white-space: pre-line;">{{ message }}</div>
</tms-warning-modal>