import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PatientScreen } from '@app/model';

export interface ScreensState extends EntityState<PatientScreen> {}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'screens', idKey: 'interviewSessionId'})
export class ScreensStore extends EntityStore<ScreensState, PatientScreen> {
    constructor() {
        super();
    }
}