import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { DeepCopyUtils } from '@app/utils';

@Component({
  selector: 'tms-select-box',
  templateUrl: './tms-select-box.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TmsSelectBoxComponent implements OnInit {
  @Input() dataSource?: any[] = null;
  @Input() displayExpr: string | Function = null;
  @Input() required: boolean = false;
  @Input() requiredMessage: string = 'Value is required';
  @Input() value: any = null;
  @Input() valueExpr: string | Function = null;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  dataSourceCopy: any[] = null;
  valueCopy: any = null;

  constructor() { }

  ngOnInit(): void {
    this.valueCopy = DeepCopyUtils.deepCopyObject(this.value);
    this.dataSourceCopy = this.dataSource.map(item => {
      return {
        ...item,
        disabled: item.isActive != null ? !item.isActive : false,
      }
    })
  }

  onSelectBoxValueChanged(e) {
    this.valueCopy = e.value;
    this.valueChange.emit(this.valueCopy);
  }

  selectBoxCallback = (e) => {
    if (this.required) {
      return !!e.value;
    } else {
      return true;
    }
  }

}
