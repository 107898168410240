import {
    NarrativeBuilderOption,
    NarrativeBuilderList,
    RecertPrecautionsProperties,
} from '@app/model';

import * as idb from './id-builder-utils';

export function getRecertPrecautionsNarrativeBuilder(): NarrativeBuilderList {
    const RP: string = 'RP';

    const NOCONTRAINS = 'NOCONTRAINS';
    const noConBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, NOCONTRAINS),
        text: 'No contraindications',
        values: [
            {
                id: idb.getNBOptionValueId(RP, NOCONTRAINS),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, NOCONTRAINS, 1),
                        text: 'No contraindications',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.NO_CONTRAINDICATIONS,
    }

    const NOPRECAUTIONS = 'NOPRECAUTIONS';
    const noPreBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, NOPRECAUTIONS),
        text: 'No precautions',
        values: [
            {
                id: idb.getNBOptionValueId(RP, NOPRECAUTIONS),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, NOPRECAUTIONS, 1),
                        text: 'No precautions',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.NO_PRECAUTIONS,
    }

    const HIPPRECAUTIONS = 'HIPPRECAUTIONS';
    const hipPreBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, NOPRECAUTIONS),
        text: 'Hip Precautions',
        values: [
            {
                id: idb.getNBOptionValueId(RP, HIPPRECAUTIONS),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, HIPPRECAUTIONS, 1),
                        text: 'Hip Precautions',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.HIP_PRECAUTIONS,
    }

    const FALLRISK = 'FALLRISK';
    const fallRiskBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, FALLRISK),
        text: 'Fall Risk',
        values: [
            {
                id: idb.getNBOptionValueId(RP, FALLRISK),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, FALLRISK, 1),
                        text: 'Fall Risk',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.FALL_RISK,
    };

    const NWBRLE = 'NWBRLE';
    const nwbRLEBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, NWBRLE),
        text: 'NWB (R) LE',
        values: [
            {
                id: idb.getNBOptionValueId(RP, NWBRLE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, NWBRLE, 1),
                        text: 'NWB (R) LE',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.NWB_R_LE,
    };

    const NWBLLE = 'NWBLLE';
    const nwbLLEBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, NWBLLE),
        text: 'NWB (L) LE',
        values: [
            {
                id: idb.getNBOptionValueId(RP, NWBLLE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, NWBLLE, 1),
                        text: 'NWB (L) LE',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.NWB_L_LE,
    };

    const TTWBRLE = 'TTWBRLE';
    const ttwbRLEBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, TTWBRLE),
        text: 'TTWB (R) LE',
        values: [
            {
                id: idb.getNBOptionValueId(RP, TTWBRLE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, TTWBRLE, 1),
                        text: 'TTWB (R) LE',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.TTWB_R_LE,
    };

    const TTWBLLE = 'TTWBLLE';
    const ttwbLLEBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, TTWBLLE),
        text: 'TTWB (L) LE',
        values: [
            {
                id: idb.getNBOptionValueId(RP, TTWBLLE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, TTWBLLE, 1),
                        text: 'TTWB (L) LE',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.TTWB_L_LE,
    };

    const PWBRLE = 'PWBRLE';
    const pwbRLEBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, PWBRLE),
        text: 'PWB (R) LE',
        values: [
            {
                id: idb.getNBOptionValueId(RP, PWBRLE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, PWBRLE, 1),
                        text: 'PWB (R) LE',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.PWB_R_LE,
    };

    const PWBLLE = 'PWBLLE';
    const pwbLLEBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, PWBLLE),
        text: 'PWB (L) LE',
        values: [
            {
                id: idb.getNBOptionValueId(RP, PWBLLE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, PWBLLE, 1),
                        text: 'PWB (L) LE',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.PWB_L_LE,
    };

    const PWBRUE = 'PWBRUE';
    const pwbRUEBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, PWBRUE),
        text: 'PWB (R) UE',
        values: [
            {
                id: idb.getNBOptionValueId(RP, PWBRUE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, PWBRUE, 1),
                        text: 'PWB (R) UE',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.PWB_R_UE,
    };

    const PWBLUE = 'PWBLUE';
    const pwbLUEBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, PWBLUE),
        text: 'PWB (L) UE',
        values: [
            {
                id: idb.getNBOptionValueId(RP, PWBLUE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, PWBLUE, 1),
                        text: 'PWB (L) UE',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.PWB_L_UE,
    };

    const WBATRLE = 'WBATRLE';
    const wbatRLEBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, WBATRLE),
        text: 'WBAT (R) LE',
        values: [
            {
                id: idb.getNBOptionValueId(RP, WBATRLE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, WBATRLE, 1),
                        text: 'WBAT (R) LE',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.WBAT_R_LE,
    };

    const WBATLLE = 'WBATLLE';
    const wbatLLEBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, WBATLLE),
        text: 'WBAT (L) LE',
        values: [
            {
                id: idb.getNBOptionValueId(RP, WBATLLE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, WBATLLE, 1),
                        text: 'WBAT (L) LE',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.WBAT_L_LE,
    };

    const NWBRUE = 'NWBRUE';
    const nwbRUEBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, NWBRUE),
        text: 'NWB (R) UE',
        values: [
            {
                id: idb.getNBOptionValueId(RP, NWBRUE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, NWBRUE, 1),
                        text: 'NWB (R) UE',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.NWB_R_UE,
    };

    const NWBLUE = 'NWBLUE';
    const nwbLUEBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, NWBLUE),
        text: 'NWB (L) UE',
        values: [
            {
                id: idb.getNBOptionValueId(RP, NWBLUE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, NWBLUE, 1),
                        text: 'NWB (L) UE',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.NWB_L_UE,
    };

    const SWALLOWING = 'SWALLOWING';
    const swallowBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, SWALLOWING),
        text: 'Swallowing Precautions',
        values: [
            {
                id: idb.getNBOptionValueId(RP, SWALLOWING),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, SWALLOWING, 1),
                        text: 'Swallowing Precautions',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.SWALLOWING_PRECAUTIONS,
    };

    const FEEDA = 'FEEDA';
    const feedABuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, FEEDA),
        text: 'Feed (A)',
        values: [
            {
                id: idb.getNBOptionValueId(RP, FEEDA),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, FEEDA, 1),
                        text: 'Feed (A)',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.FEED_A,
    };

    const WEIGHTLOSS = 'WEIGHTLOSS';
    const weightLossBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, WEIGHTLOSS),
        text: 'Weight Loss Risk',
        values: [
            {
                id: idb.getNBOptionValueId(RP, WEIGHTLOSS),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, WEIGHTLOSS, 1),
                        text: 'Weight Loss Risk',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.WEIGHT_LOSS_RISK,
    };

    const DEHYDRATION = 'DEHYDRATION';
    const dehydrationBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, DEHYDRATION),
        text: 'Dehydration Risk',
        values: [
            {
                id: idb.getNBOptionValueId(RP, DEHYDRATION),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, DEHYDRATION, 1),
                        text: 'Dehydration Risk',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.DEHYDRATION_RISK,
    };

    const ASPIRATION = 'ASPIRATION';
    const aspirationBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, ASPIRATION),
        text: 'Aspiration Risk',
        values: [
            {
                id: idb.getNBOptionValueId(RP, ASPIRATION),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, ASPIRATION, 1),
                        text: 'Aspiration Risk',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.ASPIRATION_RISK,
    };

    const REFLUX = 'REFLUX';
    const refluxBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, REFLUX),
        text: 'Reflux',
        values: [
            {
                id: idb.getNBOptionValueId(RP, REFLUX),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, REFLUX, 1),
                        text: 'Reflux',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.REFLUX,
    };

    const O2PRN = 'O2PRN';
    const o2prnBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, O2PRN),
        text: 'O2 PRN',
        values: [
            {
                id: idb.getNBOptionValueId(RP, O2PRN),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, O2PRN, 1),
                        text: 'O2 PRN',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.O2_PRN,
    };

    const O2ALLTIMES = 'O2O2ALLTIMESPRN';
    const o2alltimesBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, O2PRN),
        text: 'O2 @ all times',
        values: [
            {
                id: idb.getNBOptionValueId(RP, O2ALLTIMES),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, O2ALLTIMES, 1),
                        text: 'O2 @ all times',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.O2_ALL_TIMES,
    };

    const SPLINTWACT = 'SPLINTWACT';
    const splintWActBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, O2PRN),
        text: 'Splint/Brace with activities',
        values: [
            {
                id: idb.getNBOptionValueId(RP, SPLINTWACT),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, SPLINTWACT, 1),
                        text: 'Splint/Brace with activities',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.SPLINT_W_ACTITIVIES,
    };

    const PACEMAKER = 'PACEMAKER';
    const pacemakerBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, PACEMAKER),
        text: 'Pacemaker',
        values: [
            {
                id: idb.getNBOptionValueId(RP, PACEMAKER),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, PACEMAKER, 1),
                        text: 'Pacemaker',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.PACEMAKER,
    };

    const SEIZURE = 'SEIZURE';
    const seizureBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, SEIZURE),
        text: 'seizure',
        values: [
            {
                id: idb.getNBOptionValueId(RP, SEIZURE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, SEIZURE, 1),
                        text: 'seizure',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.SEIZURE,
    };

    const DEFIBRILLATOR = 'DEFIBRILLATOR';
    const defibBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, DEFIBRILLATOR),
        text: 'defibrillator',
        values: [
            {
                id: idb.getNBOptionValueId(RP, DEFIBRILLATOR),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, DEFIBRILLATOR, 1),
                        text: 'defibrillator',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.DEFIBRILLATOR,
    };

    const STERNAL_PRECAUTIONS = 'STERNAL_PRECAUTIONS';
    const sternalPreBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, STERNAL_PRECAUTIONS),
        text: 'sternal precautions',
        values: [
            {
                id: idb.getNBOptionValueId(RP, STERNAL_PRECAUTIONS),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, STERNAL_PRECAUTIONS, 1),
                        text: 'sternal precautions',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.STERNAL_PRECAUTIONS,
    };

    const SPINAL_PRECAUTIONS = 'SPINAL_PRECAUTIONS';
    const spinalPreBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, SPINAL_PRECAUTIONS),
        text: 'spinal precautions',
        values: [
            {
                id: idb.getNBOptionValueId(RP, SPINAL_PRECAUTIONS),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, SPINAL_PRECAUTIONS, 1),
                        text: 'spinal precautions',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.SPINAL_PRECAUTIONS,
    };

    const ORTHO_HYDRO = 'ORTHO_HYDRO';
    const orthoHydroBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, ORTHO_HYDRO),
        text: 'Orthostatic hypotension',
        values: [
            {
                id: idb.getNBOptionValueId(RP, ORTHO_HYDRO),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, ORTHO_HYDRO, 1),
                        text: 'Orthostatic hypotension',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.ORTHOSTATIC_HYPO,
    };

    const DIALYSIS = 'DIALYSIS';
    const dialysisBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, DIALYSIS),
        text: 'dialysis',
        values: [
            {
                id: idb.getNBOptionValueId(RP, DIALYSIS),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, DIALYSIS, 1),
                        text: 'dialysis',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.DIALYSIS,
    };

    const WOUNDS = 'DIALYSIS';
    const woundsBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RP, WOUNDS),
        text: 'wounds',
        values: [
            {
                id: idb.getNBOptionValueId(RP, WOUNDS),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RP, WOUNDS, 1),
                        text: 'wounds',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertPrecautionsProperties.WOUNDS,
    };
    
    
    const nbl: NarrativeBuilderList = {
        options: {
            [RecertPrecautionsProperties.NO_CONTRAINDICATIONS]: noConBuilderOption,
            [RecertPrecautionsProperties.NO_PRECAUTIONS]: noPreBuilderOption,
            [RecertPrecautionsProperties.HIP_PRECAUTIONS]: hipPreBuilderOption,
            [RecertPrecautionsProperties.FALL_RISK]: fallRiskBuilderOption,
            [RecertPrecautionsProperties.NWB_R_LE]: nwbRLEBuilderOption,
            [RecertPrecautionsProperties.NWB_L_LE]: nwbLLEBuilderOption,
            [RecertPrecautionsProperties.TTWB_R_LE]: ttwbRLEBuilderOption,
            [RecertPrecautionsProperties.TTWB_L_LE]: ttwbLLEBuilderOption,
            [RecertPrecautionsProperties.PWB_R_LE]: pwbRLEBuilderOption,
            [RecertPrecautionsProperties.PWB_L_LE]: pwbLLEBuilderOption,
            [RecertPrecautionsProperties.PWB_R_UE]: pwbRUEBuilderOption,
            [RecertPrecautionsProperties.PWB_L_UE]: pwbLUEBuilderOption,
            [RecertPrecautionsProperties.WBAT_R_LE]: wbatRLEBuilderOption,
            [RecertPrecautionsProperties.WBAT_L_LE]: wbatLLEBuilderOption,
            [RecertPrecautionsProperties.NWB_R_UE]: nwbRUEBuilderOption,
            [RecertPrecautionsProperties.NWB_L_UE]: nwbLUEBuilderOption,
            [RecertPrecautionsProperties.SWALLOWING_PRECAUTIONS]: swallowBuilderOption,
            [RecertPrecautionsProperties.FEED_A]: feedABuilderOption,
            [RecertPrecautionsProperties.WEIGHT_LOSS_RISK]: weightLossBuilderOption,
            [RecertPrecautionsProperties.DEHYDRATION_RISK]: dehydrationBuilderOption,
            [RecertPrecautionsProperties.ASPIRATION_RISK]: aspirationBuilderOption,
            [RecertPrecautionsProperties.REFLUX]: refluxBuilderOption,
            [RecertPrecautionsProperties.O2_PRN]: o2prnBuilderOption,
            [RecertPrecautionsProperties.O2_ALL_TIMES]: o2alltimesBuilderOption,
            [RecertPrecautionsProperties.SPLINT_W_ACTITIVIES]: splintWActBuilderOption,
            [RecertPrecautionsProperties.PACEMAKER]: pacemakerBuilderOption,
            [RecertPrecautionsProperties.SEIZURE]: seizureBuilderOption,
            [RecertPrecautionsProperties.DEFIBRILLATOR]: defibBuilderOption,
            [RecertPrecautionsProperties.STERNAL_PRECAUTIONS]: sternalPreBuilderOption,
            [RecertPrecautionsProperties.SPINAL_PRECAUTIONS]: spinalPreBuilderOption,
            [RecertPrecautionsProperties.ORTHOSTATIC_HYPO]: orthoHydroBuilderOption,
            [RecertPrecautionsProperties.DIALYSIS]: dialysisBuilderOption,
            [RecertPrecautionsProperties.WOUNDS]: woundsBuilderOption,
        }
    }

    return nbl;
}