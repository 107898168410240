import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PatientPlannerPatientsStore, PatientPlannerPatientsState } from './patient-planner-patients.store';

@Injectable({
  providedIn: 'root',
})
export class PatientPlannerPatientsQuery extends QueryEntity<PatientPlannerPatientsState> {

  constructor(protected store: PatientPlannerPatientsStore) {
    super(store);
  }

}
