import { Pipe, PipeTransform } from '@angular/core';
import { CacheManService } from '@app/core';
import { Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Pipe({
    name: 'dataListStringList'
})
export class DataListStringListPipe implements PipeTransform {
    constructor(private cachemanService: CacheManService) { }

    transform(values: string[], dataListName: string, dataListProperty: string = null): Observable<string> {
        if (!dataListName) {
            return of('');
        }

        if (values != null) {
            const dataListProp = dataListProperty ? dataListProperty : 'text';
            return this.cachemanService.getDataListItems$(dataListName, false)
                .pipe(filter(datalist => datalist !== null),
                    take(1),
                    map(dataListItems => {
                        return values.map(value => {
                            const index = dataListItems.findIndex(item => item.value == value);
                            if (index >= 0) {
                                return dataListItems[index][dataListProp];
                            } else {
                                return null;
                            }
                        }).join(', ');

                    }));
        } else {
            return null;
        }
    }
}