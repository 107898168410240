import { orderBy, sortBy } from 'lodash';

export class SortUtils {
    static orderBy(array, sortField: string): any[] {
        if (sortField) {
            array = orderBy(array, [items => items[sortField]?.toLowerCase()], ['asc']);
        } else {
            array = orderBy(array, [items => items.toLowerCase()], ['asc']);
        }
        return array;
    }

    static orderByDateDesc(array, sortField: string): any[]  {
        array = orderBy(array, [items => items[sortField]], ['desc']);
        return array;
    }

    static orderByDateAsc(array, sortField: string): any[]  {
        array = orderBy(array, [items => items[sortField]], ['asc']);
        return array;
    }
}
