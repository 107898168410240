import {
    eDiscipline,
    NarrativeBuilderOption,
    NarrativeBuilderList,
    RecertInterventionProperties,
    RecertOtInterventionProperties,
} from '@app/model';

import * as idb from './id-builder-utils';
import { NarrativeBuilderListUtils } from './narrative-builer-list.utils';

const RI: string = 'RI';
export function getRecertInterventionsNarrativeBuilder(discipline: eDiscipline): NarrativeBuilderList {
    if (discipline == eDiscipline.SpeechTherapy) {
        return getSTRecertInterventions();
    } else if (discipline === eDiscipline.OccupationalTherapy) {
        return getOTRecertInterventions();
    } else {
        return getOtherRecertInterventionNB();
    }
}

function getOTRecertInterventions(): NarrativeBuilderList {
    return NarrativeBuilderListUtils.getNarrativeBuilderList(RI, RecertOtInterventionProperties);
}

function getOtherRecertInterventionNB(): NarrativeBuilderList {    
    const FACILITORY = 'facilitory';
    const facilitoryBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, FACILITORY),
        text: FACILITORY,
        values: [
            {
                id: idb.getNBOptionValueId(RI, FACILITORY),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, FACILITORY, 1),
                        text: 'facilitory techniques to increase voluntary recruitment',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertInterventionProperties.FACILITORY,
    }

    const INHIB = 'inhib';
    const inhibBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, INHIB),
        text: INHIB,
        values: [
            {
                id: idb.getNBOptionValueId(RI, INHIB),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, INHIB, 1),
                        text: 'inhibitory techniques to address tonal abnormalities',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertInterventionProperties.INHIB,
    }

    const MAN_ROM = 'man-ROM';
    const manRomBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, MAN_ROM),
        text: MAN_ROM,
        values: [
            {
                id: idb.getNBOptionValueId(RI, MAN_ROM),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, MAN_ROM, 1),
                        text: 'manual techniques to promote increased functional ROM',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertInterventionProperties.MAN_ROM,
    }

    const MAN_P = 'man-P!';
    const manPBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, MAN_P),
        text: MAN_P,
        values: [
            {
                id: idb.getNBOptionValueId(RI, MAN_P),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, MAN_P, 1),
                        text: 'manual techniques focusing on pain reduction',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertInterventionProperties.MAN_P,
    };

    const PED = 'ped';
    const pedBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, PED),
        text: 'p!ed',
        values: [
            {
                id: idb.getNBOptionValueId(RI, PED),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, PED, 1),
                        text: 'education related to proper task performance technique to reduce pain',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertInterventionProperties.P_ED,
    };

    const PCOMPSTART = 'pcomstrat';
    const pcompstartBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, PCOMPSTART),
        text: 'p! comp strat',
        values: [
            {
                id: idb.getNBOptionValueId(RI, PCOMPSTART),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, PCOMPSTART, 1),
                        text: 'compensatory strategy training related to painful limb during to functional task performance',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertInterventionProperties.P_COMP_START,
    };

    const ROM_LE = 'ROM-LE';
    const romLEBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, ROM_LE),
        text: ROM_LE,
        values: [
            {
                id: idb.getNBOptionValueId(RI, ROM_LE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, ROM_LE, 1),
                        text: 'skilled intervention to promote increased LE functional ROM',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertInterventionProperties.ROM_LE,
    };

    const ROM_TRUNK = 'ROM-trunk';
    const romtrunkBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, ROM_TRUNK),
        text: ROM_TRUNK,
        values: [
            {
                id: idb.getNBOptionValueId(RI, ROM_TRUNK),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, ROM_TRUNK, 1),
                        text: 'training focusing on increasing functional range of motion in trunk',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertInterventionProperties.ROM_TRUNK,
    };

    const MOTOR_CONTROL_LE = 'motorControlLE';
    const motorControlBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, MOTOR_CONTROL_LE),
        text: 'motor control-LE',
        values: [
            {
                id: idb.getNBOptionValueId(RI, MOTOR_CONTROL_LE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, MOTOR_CONTROL_LE, 1),
                        text: 'progressive training to promote increased LE motor control',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertInterventionProperties.MOTOR_CONTROL_LE,
    };

    const COMP_STRATS = 'compStrats';
    const compStratsBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, COMP_STRATS),
        text: 'comp strats',
        values: [
            {
                id: idb.getNBOptionValueId(RI, COMP_STRATS),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, COMP_STRATS, 1),
                        text: 'instruction on proper compensatory strategy utilization',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertInterventionProperties.COMP_STRATS,
    };

    const AD_TECH = 'ADTECH';
    const adTechBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, AD_TECH),
        text: 'AD Tech',
        values: [
            {
                id: idb.getNBOptionValueId(RI, AD_TECH),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, AD_TECH, 1),
                        text: 'training with focus on proper technique with AD utilization',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertInterventionProperties.AD_TECH,
    };

    const POSTURE = 'POSTURE';
    const postureBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, POSTURE),
        text: 'posture',
        values: [
            {
                id: idb.getNBOptionValueId(RI, POSTURE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, POSTURE, 1),
                        text: 'postural reeducation',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertInterventionProperties.POSTURE,
    };

    const BALANCE = 'BALANCE';
    const balanceBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, BALANCE),
        text: 'balance',
        values: [
            {
                id: idb.getNBOptionValueId(RI, BALANCE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, BALANCE, 1),
                        text: 'functional balance training',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertInterventionProperties.BALANCE,
    };
  
    const SEQUENCING = 'SEQUENCING';
    const sequencingBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, SEQUENCING),
        text: 'sequencing',
        values: [
            {
                id: idb.getNBOptionValueId(RI, SEQUENCING),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, SEQUENCING, 1),
                        text: 'training on proper task sequencing',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertInterventionProperties.SEQUENCING,
    };

    const COORDFTPLACEMENT = 'COORDFTPLACEMENT';
    const coorFtPlacementBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, COORDFTPLACEMENT),
        text: 'coord-ft placement',
        values: [
            {
                id: idb.getNBOptionValueId(RI, COORDFTPLACEMENT),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, COORDFTPLACEMENT, 1),
                        text: 'coordination training to increase specificity of foot placement',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertInterventionProperties.COORD_FT_PLACEMENT,
    };

    const COORD_INTERPLAY = 'COORD_INTERPLAY';
    const coordInterplayBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, COORD_INTERPLAY),
        text: 'coord-interplay',
        values: [
            {
                id: idb.getNBOptionValueId(RI, COORD_INTERPLAY),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, COORD_INTERPLAY, 1),
                        text: 'coordination training to increase LE interplay',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertInterventionProperties.COORD_INTERPLAY,
    };

    const NEURO_COORD = 'NEURO_COORD';
    const neuroCoordBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, NEURO_COORD),
        text: 'neuro-coord',
        values: [
            {
                id: idb.getNBOptionValueId(RI, NEURO_COORD),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, NEURO_COORD, 1),
                        text: 'neuromuscular reeducation to promote increased coordination',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertInterventionProperties.NEURO_COORD,
    };

    const EFFICIENCY_TASK = 'EFFICIENCY_TASK';
    const effTaskBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, EFFICIENCY_TASK),
        text: 'efficiency-task',
        values: [
            {
                id: idb.getNBOptionValueId(RI, EFFICIENCY_TASK),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, EFFICIENCY_TASK, 1),
                        text: 'task-training to promote increased efficiency with functional task performance',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertInterventionProperties.EFFICIENCY_TASK,
    };

    const ENDURANCE = 'ENDURANCE';
    const enduranceBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, ENDURANCE),
        text: 'endurance',
        values: [
            {
                id: idb.getNBOptionValueId(RI, ENDURANCE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, ENDURANCE, 1),
                        text: 'intervention to promote increased functional work capacity',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertInterventionProperties.ENDURANCE,
    };

    const SAFETY = 'SAFETY';
    const safetyBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, SAFETY),
        text: 'safely',
        values: [
            {
                id: idb.getNBOptionValueId(RI, SAFETY),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, SAFETY, 1),
                        text: 'skilled training to facilitate gains in safety awareness',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertInterventionProperties.SAFETY,
    };
    
    const SKILL_MAINT_CONT = 'SKILL_MAINT_CONT';
    const skilledMaintenanceContinuedOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, SKILL_MAINT_CONT),
        text: 'Skilled maintenance - continued',
        values: [
            {
                id: idb.getNBOptionValueId(RI, SKILL_MAINT_CONT),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, SKILL_MAINT_CONT, 1),
                        text: 'Patient to continue skilled therapy for skilled maintenance services to assist the patient in maintaining current function or to prevent or slow further deterioration due to disease or illness. The patient\'s clinical condition and complexity of required services is such that services can only be performed safely and effectively by skilled therapist or assistant.',
                    }
                ]
            }
        ],
        selectedValue: null,
        property: RecertInterventionProperties.SKILL_MAINT_CONT,
    };
    
    const SKILL_MAINT_NEW = 'SKILL_MAINT_NEW';
    const skilledMaintenanceNewOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RI, SKILL_MAINT_NEW),
        text: 'Skilled maintenance - new',
        values: [
            {
                id: idb.getNBOptionValueId(RI, SKILL_MAINT_NEW),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RI, SKILL_MAINT_NEW, 1),
                        text: 'Patient plan of care transitioning to focus on skilled maintenance services to assist the patient in maintaining current function or to prevent or slow further deterioration due to disease or illness. The patient\'s clinical condition and complexity of required services is such that services can only be performed safely and effectively by skilled therapist or assistant.',
                    }
                ]
            }
        ],
        selectedValue: null,
        property: RecertInterventionProperties.SKILL_MAINT_NEW,
    };
    
    const nbl: NarrativeBuilderList = {
        options: {
            [RecertInterventionProperties.FACILITORY]: facilitoryBuilderOption,
            [RecertInterventionProperties.INHIB]: inhibBuilderOption,
            [RecertInterventionProperties.MAN_ROM]: manRomBuilderOption,
            [RecertInterventionProperties.MAN_P]: manPBuilderOption,
            [RecertInterventionProperties.P_ED]: pedBuilderOption,
            [RecertInterventionProperties.P_COMP_START]: pcompstartBuilderOption,
            [RecertInterventionProperties.ROM_LE]: romLEBuilderOption,
            [RecertInterventionProperties.ROM_TRUNK]: romtrunkBuilderOption,
            [RecertInterventionProperties.MOTOR_CONTROL_LE]: motorControlBuilderOption,
            [RecertInterventionProperties.COMP_STRATS]: compStratsBuilderOption,
            [RecertInterventionProperties.AD_TECH]: adTechBuilderOption,
            [RecertInterventionProperties.POSTURE]: postureBuilderOption,
            [RecertInterventionProperties.BALANCE]: balanceBuilderOption,
            [RecertInterventionProperties.SEQUENCING]: sequencingBuilderOption,
            [RecertInterventionProperties.COORD_FT_PLACEMENT]: coorFtPlacementBuilderOption,
            [RecertInterventionProperties.COORD_INTERPLAY]: coordInterplayBuilderOption,
            [RecertInterventionProperties.NEURO_COORD]: neuroCoordBuilderOption,
            [RecertInterventionProperties.EFFICIENCY_TASK]: effTaskBuilderOption,
            [RecertInterventionProperties.ENDURANCE]: enduranceBuilderOption,
            [RecertInterventionProperties.SAFETY]: safetyBuilderOption,
            [RecertInterventionProperties.SKILL_MAINT_NEW]: skilledMaintenanceNewOption,
            [RecertInterventionProperties.SKILL_MAINT_CONT]: skilledMaintenanceContinuedOption,
            
        }
    }

    return nbl;
}

function getSTRecertInterventions(): NarrativeBuilderList {
    const nbl: NarrativeBuilderList = {
        options: {
            [RecertInterventionProperties.SWALLOW_ED]: {
                id: RecertInterventionProperties.SWALLOW_ED,
                text: 'Swallow-ed',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.SWALLOW_ED),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.SWALLOW_ED, 1),
                                text: 'education in proper safe swallow technique',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.SWALLOW_ED,
            },
            [RecertInterventionProperties.SWALLOW_COMP]: {
                id: RecertInterventionProperties.SWALLOW_COMP,
                text: 'Swallow: comp strat training',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.SWALLOW_COMP),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.SWALLOW_COMP, 1),
                                text: 'training in proper use of compensatory swallow strategies',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.SWALLOW_COMP,
            },
            [RecertInterventionProperties.SWALLOW_HARD]: {
                id: RecertInterventionProperties.SWALLOW_HARD,
                text: 'Swallow-hard',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.SWALLOW_HARD),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.SWALLOW_HARD, 1),
                                text: 'training in use of hard swallow',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.SWALLOW_HARD,
            },
            [RecertInterventionProperties.STAFF]: {
                id: RecertInterventionProperties.STAFF,
                text: 'Staff/family ed',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.STAFF),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.STAFF, 1),
                                text: 'staff/family education training in patient\'s compensatory strategies',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.STAFF,
            },
            [RecertInterventionProperties.CONT_DIET_ASSESS]: {
                id: RecertInterventionProperties.CONT_DIET_ASSESS,
                text: 'Cont diet assessment',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.CONT_DIET_ASSESS),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.CONT_DIET_ASSESS, 1),
                                text: 'continued assessment',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.CONT_DIET_ASSESS,
            },
            [RecertInterventionProperties.OME_GEN]: {
                id: RecertInterventionProperties.OME_GEN,
                text: 'OME-gen',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.OME_GEN),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.OME_GEN, 1),
                                text: 'training in oral motor exercises',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.OME_GEN,
            },
            [RecertInterventionProperties.OME_LING]: {
                id: RecertInterventionProperties.OME_LING,
                text: 'OME-ling',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.OME_LING),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.OME_LING, 1),
                                text: 'instruction in lingual exercises',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.OME_LING,
            },
            [RecertInterventionProperties.OME_LABIAL]: {
                id: RecertInterventionProperties.OME_LABIAL,
                text: 'OME-labial',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.OME_LABIAL),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.OME_LABIAL, 1),
                                text: 'OME targeting labial seal efficiency',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.OME_LABIAL,
            },
            [RecertInterventionProperties.THERMAL_STIM]: {
                id: RecertInterventionProperties.THERMAL_STIM,
                text: 'Thermal stim',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.THERMAL_STIM),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.THERMAL_STIM, 1),
                                text: 'thermal stimulation',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.THERMAL_STIM,
            },
            [RecertInterventionProperties.SENSATION]: {
                id: RecertInterventionProperties.SENSATION,
                text: 'Sensation',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.SENSATION),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.SENSATION, 1),
                                text: 'oral sensory reeducation',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.SENSATION,
            },
            [RecertInterventionProperties.DPNS]: {
                id: RecertInterventionProperties.DPNS,
                text: 'DPNS',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.DPNS),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.DPNS, 1),
                                text: 'DPNS',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.DPNS,
            },
            [RecertInterventionProperties.ICING]: {
                id: RecertInterventionProperties.ICING,
                text: 'Icing',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.ICING),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.ICING, 1),
                                text: 'icing techniques to affected side',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.ICING,
            },
            [RecertInterventionProperties.NMES_VITAL]: {
                id: RecertInterventionProperties.NMES_VITAL,
                text: 'NMES-Vital stim',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.NMES_VITAL),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.NMES_VITAL, 1),
                                text: 'application of NMES',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.NMES_VITAL,
            },
            [RecertInterventionProperties.VERBAL_ORG_TASK]: {
                id: RecertInterventionProperties.VERBAL_ORG_TASK,
                text: 'Verbal org task',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.VERBAL_ORG_TASK),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.VERBAL_ORG_TASK, 1),
                                text: 'training on verbal organization tasks',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.VERBAL_ORG_TASK,
            },
            [RecertInterventionProperties.NAMING]: {
                id: RecertInterventionProperties.NAMING,
                text: 'Naming: part/whole',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.NAMING),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.NAMING, 1),
                                text: 'direction naming tasks utilizing part/whole themes',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.NAMING,
            },
            [RecertInterventionProperties.QA_SIMPLE]: {
                id: RecertInterventionProperties.QA_SIMPLE,
                text: 'Q/A-simple',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.QA_SIMPLE),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.QA_SIMPLE, 1),
                                text: 'instruction in technique for proper simple question answering',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.QA_SIMPLE,
            },
            [RecertInterventionProperties.QA_COMPLEX]: {
                id: RecertInterventionProperties.QA_COMPLEX,
                text: 'Q/A-complex',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.QA_COMPLEX),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.QA_COMPLEX, 1),
                                text: 'skilled training targeting patient ability to answer complex questions',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.QA_COMPLEX,
            },
            [RecertInterventionProperties.VERBAL_EXP]: {
                id: RecertInterventionProperties.VERBAL_EXP,
                text: 'Verbal exp',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.VERBAL_EXP),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.VERBAL_EXP, 1),
                                text: 'training to improve patient verbal expression skills',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.VERBAL_EXP,
            },
            [RecertInterventionProperties.WRITING]: {
                id: RecertInterventionProperties.WRITING,
                text: 'Writing',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.WRITING),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.WRITING, 1),
                                text: 'training to impact written communication',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.WRITING,
            },
            [RecertInterventionProperties.SPEECH_INTEL_TECH]: {
                id: RecertInterventionProperties.SPEECH_INTEL_TECH,
                text: 'Speech intel-tech',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.SPEECH_INTEL_TECH),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.SPEECH_INTEL_TECH, 1),
                                text: 'treatment strategies including rate decrease, over exaggeration and repetition to impact speech intelligibility',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.SPEECH_INTEL_TECH,
            },
            [RecertInterventionProperties.SPEECH_INTEL_DYS]: {
                id: RecertInterventionProperties.SPEECH_INTEL_DYS,
                text: 'Speech intel: dysarthria ',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.SPEECH_INTEL_DYS),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.SPEECH_INTEL_DYS, 1),
                                text: 'intervention targeting dysarthria',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.SPEECH_INTEL_DYS,
            },
            [RecertInterventionProperties.FOLLOW_COMMANDS]: {
                id: RecertInterventionProperties.FOLLOW_COMMANDS,
                text: 'Follow commands',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.FOLLOW_COMMANDS),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.FOLLOW_COMMANDS, 1),
                                text: 'instruction to promote increased ability to follow directions/commands',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.FOLLOW_COMMANDS,
            },
            [RecertInterventionProperties.PROBLEM_SOLVING]: {
                id: RecertInterventionProperties.PROBLEM_SOLVING,
                text: 'Problem solving',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.PROBLEM_SOLVING),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.PROBLEM_SOLVING, 1),
                                text: 'training focusing on improvement of problem solving tasks',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.PROBLEM_SOLVING,
            },
            [RecertInterventionProperties.SEQUENCING]: {
                id: RecertInterventionProperties.SEQUENCING,
                text: 'Sequencing',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.SEQUENCING),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.SEQUENCING, 1),
                                text: 'implementation of progressive sequencing tasks',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.SEQUENCING,
            },
            [RecertInterventionProperties.REASONING]: {
                id: RecertInterventionProperties.REASONING,
                text: 'Reasoning',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.REASONING),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.REASONING, 1),
                                text: 'training focusing on improvement of reasoning skills',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.REASONING,
            },
            [RecertInterventionProperties.ALT_DEVICE]: {
                id: RecertInterventionProperties.ALT_DEVICE,
                text: 'Alternate device',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.ALT_DEVICE),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.ALT_DEVICE, 1),
                                text: 'training in use of alternate communication device',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.ALT_DEVICE,
            },
            [RecertInterventionProperties.COGNTIVE]: {
                id: RecertInterventionProperties.COGNTIVE,
                text: 'Cogntive',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.COGNTIVE),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.COGNTIVE, 1),
                                text: 'cognitive skills development',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.COGNTIVE,
            },
            [RecertInterventionProperties.STM]: {
                id: RecertInterventionProperties.STM,
                text: 'STM',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.STM),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.STM, 1),
                                text: 'faded cuing to promote increased short term memory skills',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.STM,
            },
            [RecertInterventionProperties.MEMORY_BOOK]: {
                id: RecertInterventionProperties.MEMORY_BOOK,
                text: 'Memory book',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.MEMORY_BOOK),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.MEMORY_BOOK, 1),
                                text: 'instruction in memory book',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.MEMORY_BOOK,
            },
            [RecertInterventionProperties.MEMORY_STRATS]: {
                id: RecertInterventionProperties.MEMORY_STRATS,
                text: 'Memory strats',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.MEMORY_STRATS),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.MEMORY_STRATS, 1),
                                text: 'education related to utilization of memory strategies',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.MEMORY_STRATS,
            },
            [RecertInterventionProperties.ORIENTATION]: {
                id: RecertInterventionProperties.ORIENTATION,
                text: 'Orientation',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.ORIENTATION),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.ORIENTATION, 1),
                                text: 'skilled intervention to promote gains in patient\'s orientation',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.ORIENTATION,
            },
            [RecertInterventionProperties.SKILL_MAINT_CONT]: {
                id: RecertInterventionProperties.SKILL_MAINT_CONT,
                text: 'Skilled maintenance - continued',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.SKILL_MAINT_CONT),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.SKILL_MAINT_CONT, 1),
                                text: 'Patient to continue skilled therapy for skilled maintenance services to assist the patient in maintaining current function or to prevent or slow further deterioration due to disease or illness. The patient\'s clinical condition and complexity of required services is such that services can only be performed safely and effectively by skilled therapist or assistant.',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.SKILL_MAINT_CONT,
            },
            [RecertInterventionProperties.SKILL_MAINT_NEW]: {
                id: RecertInterventionProperties.SKILL_MAINT_NEW,
                text: 'Skilled maintenance - new',
                values: [
                    {
                        id: idb.getNBOptionValueId(RI, RecertInterventionProperties.SKILL_MAINT_NEW),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RI, RecertInterventionProperties.SKILL_MAINT_NEW, 1),
                                text: 'Patient plan of care transitioning to focus on skilled maintenance services to assist the patient in maintaining current function or to prevent or slow further deterioration due to disease or illness. The patient\'s clinical condition and complexity of required services is such that services can only be performed safely and effectively by skilled therapist or assistant.',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertInterventionProperties.SKILL_MAINT_NEW,
            }
        }
    }
    
    return nbl;
    
}

