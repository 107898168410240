import { Injectable } from '@angular/core';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { flatMap, filter, map, switchMap, take, tap } from 'rxjs/operators';
import {
    ClinicalModule,
    DataList,
    DataListItem,
    Dictionary,
    DocumentCategory,
    ePermissions,
    EvaluationTemplate,
    User,
    Organization,
    Facility,
    InitialSyncModel,
    TimeClockEntry,
    PagedResult,
    eDiscipline,
    eDesignation
} from '@app/model';

import { CacheManQuery, CacheManStore } from '@app/store/cacheman';
import { SortUtils } from '@app/utils/sort.utils';
import { DeepCopyUtils } from '@app/utils';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from './url-constants.service';
import { eFeatureName } from '@app/model/enum/eFeatureName';

@Injectable({
    providedIn: 'root',
})
export class CacheManService {
    private _supportMessagesPending$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

    constructor(private cacheManStore: CacheManStore, 
        private cacheManQuery: CacheManQuery,
        private httpClient: HttpClient,
        private urlConstantsService: UrlConstantsService) {
    }

    get supportMessagesPending$(): Observable<number> {
        return this._supportMessagesPending$.asObservable();
    }

    setCacheStoreError(error: any) {
        this.cacheManStore.setError(error);
    }

    getCacheStoreLoading(): Observable<boolean> {
        return this.cacheManQuery.selectLoading();
    }

    setCacheStoreLoading(loading: boolean) {
        this.cacheManStore.setLoading(loading);
    }

    setSessionStartData(initialSync: InitialSyncModel) {
        // map datalist to dictionary
        const dict: Dictionary<DataList> = {};
        initialSync.dataLists.forEach(item => {
            dict[item.shortName] = item;
        });

        this.cacheManStore.update({
            ...initialSync,
            dataLists: dict,
        });

        if (initialSync.pendingMessages > 0) {
            this._supportMessagesPending$.next(initialSync.pendingMessages);
        }
    }

    setCurrentUser(user: User) {
        this.cacheManStore.update({ user });
    }

    updateCurrentUserDisciplineDesignation(discipline: eDiscipline, designation: eDesignation, timeClockEntries: TimeClockEntry[]): void {
        this.cacheManStore.update(state => {
            return {
                ...state,
                user: {
                    ...state.user,
                    employee: {
                        ...state.user.employee,
                        discipline: discipline,
                        designation: designation,
                    }
                },
                timeEntries: timeClockEntries,
            }
        });
    }

    getCurrentUser(): Observable<User> {
        return this.cacheManQuery.select(state => state.user);
    }

    getUserHasPermission$(permission: ePermissions): Observable<boolean> {
        return this.getUserPermissions$()
            .pipe(map(userPermissions => userPermissions.findIndex(value => value == permission) >= 0));
    }

    getUserPermissions$(): Observable<ePermissions[]> {
        return this.cacheManQuery.selectLoading()
            .pipe(
                filter(loading => !loading),
                switchMap(() => this.cacheManQuery.select(state => state && state.user ? state.user.permissions : [])));
    }

    setUserPermissions(permissions: ePermissions[]) {
        this.cacheManStore.update(state => {
            return {
                user: {
                    ...state.user,
                    permissions: permissions,
                    permission: permissions.reduce((acc, curr) => acc |= curr),
                },
            }
        });
    }

    setUserOrganization(organization: Organization) {
        this.cacheManStore.update({ organization });
    }

    getClinicalModules$(): Observable<Dictionary<ClinicalModule>> {
        if (this.cacheManQuery.getValue().clinicalModules == null) {
            this.getClinicalModulesFromServer();
        }
        return this.cacheManQuery.select(state => state.clinicalModules).pipe(filter(clinicalModules => clinicalModules != null));
    }

    getClinicalModules(): Dictionary<ClinicalModule> {
        return this.cacheManQuery.getValue().clinicalModules;
    }

    private getClinicalModulesFromServer() {
        this.httpClient.get<PagedResult<ClinicalModule>>(`${this.urlConstantsService.DOCUMENTS_URL}/clinicalModules`)
            .pipe(map(result => result.items), 
                  tap(modules => {
                      // map to dictionary
                      const clinicalModules: Dictionary<ClinicalModule> = {};
                      modules.forEach(m => clinicalModules[m.shortName] = m);
                      this.cacheManStore.update({ clinicalModules: clinicalModules});
                  }))
            .subscribe(() => {});
    }

    getDataListItems$(shortName: string, filterUnspecified: boolean = true, filterInActive: boolean = true): Observable<DataListItem[]> {
        return this.cacheManQuery.selectLoading()
            .pipe(filter(loading => !loading),
                switchMap(() => this.cacheManQuery.select(state => {
                    if(state && state.dataLists[shortName]) {
                        let datalist = state.dataLists[shortName].items;
                        if (filterUnspecified) {
                            datalist = datalist.filter(dli => dli.text != 'Unspecified');
                        }
                        if (filterInActive) {
                            datalist = datalist.filter(dli => !!dli.isActive);
                        }
                        return datalist;
                    } else {
                        return [];
                    }
                })));
    }

    getDataListValueTextDictionary$(dataListName: string, textProperty: string = 'text'): Observable<Dictionary<string>> {
        return this.getDataListItems$(dataListName)
            .pipe(filter(datalist => datalist !== null),
            take(1),
            map(dataListItems => {
                const dataListDictionary: Dictionary<string> = {};
                dataListItems.forEach(item => dataListDictionary[item.value] = item[textProperty]);
                return dataListDictionary;
            }));
    }

    getEvaluationTemplates$(): Observable<EvaluationTemplate[]> {
        if (this.cacheManQuery.getValue().evaluationTemplates && this.cacheManQuery.getValue().evaluationTemplates.length <= 0) {
            this.getEvaluationTemplatesFromServer();
        }
        return this.cacheManQuery.select(state => state.evaluationTemplates).pipe(filter(templates => templates.length > 0));
    }

    private getEvaluationTemplatesFromServer() {
        this.httpClient.get<PagedResult<EvaluationTemplate>>(`${this.urlConstantsService.DOCUMENTS_URL}/evaluationTemplates`)
            .pipe(map(result => result.items), 
                  tap(templates => {
                      this.cacheManStore.update({ evaluationTemplates: templates});
                  }))
            .subscribe(() => {});
    }

    // returns all facilities disregarding facility permissions
    getAllFacilities(): Observable<Facility[]> {
        if (this.cacheManQuery.getValue().allFacilities && this.cacheManQuery.getValue().allFacilities.length <= 0) {
            // cannot use facilities service because of circular dependency 
            this.httpClient.get<PagedResult<Facility>>(`${this.urlConstantsService.FACILITIES_URL}`)
                .pipe(map(facilityPagedResult => facilityPagedResult.items))
                .subscribe(facilities => this.cacheManStore.update({ allFacilities: facilities }));
        }
        return this.cacheManQuery.select(state => state.allFacilities).pipe(filter(facilities => facilities.length > 0));
    }

    // returns faciliites that user is allowed to access
    getUserFacilities(): Observable<Facility[]> {
        return this.cacheManQuery.select(state => state.facilities);
    }

    getFacility$(facilityId: string): Observable<Facility> {
        return this.cacheManQuery.select(state => state.facilities)
            .pipe(
                flatMap(facilities => facilities),
                filter(facility => facility.id === facilityId));
    }

    getFacilityName(facilityId: string): string {
        let facilityName = '';
        this.cacheManQuery.select(state => state.facilities).pipe(take(1))
            .pipe(
                flatMap(facilities => facilities),
                filter(facility => facility.id === facilityId))
            .subscribe(result => {
                facilityName = result ? result.name : '';
            });

        return facilityName;
    }

    getFacilityCode(facilityId: string): string {
        let facilityCode = '';
        this.cacheManQuery.select(state => state.facilities).pipe(take(1))
            .pipe(
                flatMap(facilities => facilities),
                filter(facility => facility.id === facilityId))
            .subscribe(result => {
                facilityCode = result ? result.facilityCode : '';
            });

        return facilityCode;
    }

    addNewFacility(newFacility: Facility) {
        this.cacheManStore.update(state => {
            return {
                ...state,
                facilities: SortUtils.orderBy([
                    ...state.facilities,
                    newFacility,
                ], 'name'),
            }
        });
    }

    updateFacility(updateFacility: Facility) {
        this.cacheManStore.update(state => {
            const updatedFacilities: Facility[] = DeepCopyUtils.deepCopyObject(state.facilities);
            const facility = updatedFacilities.find(fac => fac.id === updateFacility.id);
            if (facility) {
                facility.name = updateFacility.name;
            }
            return {
                ...state,
                facilities: SortUtils.orderBy([
                    ...updatedFacilities,
                ], 'name'),
            }
        })
    }

    getTimeClockEntries$(): Observable<TimeClockEntry[]> {
        return this.cacheManQuery.select(state => state.timeEntries);
    }

    getIsUserClockedIn$(): Observable<boolean> {
        return combineLatest([this.getCurrentUser(), this.getTimeClockEntries$()])
            .pipe(filter(([user, timeClockEntries]) => user != null),
                map(([user, timeClockEntries]) => {
                    if (!user.employee.isClockInEmployee) {
                        return true;
                    } else {
                        // check if user is clocked in
                        var todayEntries = timeClockEntries.filter(t => moment(t.clockIn).isSame(moment(), 'day'));
                        const length = todayEntries.length;
                        if (length > 0) {
                            // if clockOut is empty, then user is clocked in
                            return todayEntries.filter(entry => !entry.clockOut).length > 0;
                        } else {
                            return false;
                        }
                    }
                }))
    }

    addTimeClockEntry(entry: TimeClockEntry) {
        this.cacheManStore.update(state => {
            return {
                ...state,
                timeEntries: [
                    ...state.timeEntries,
                    entry,
                ]
            }
        })
    }

    getDocumentCategories$(): Observable<DocumentCategory[]> {
        if (this.cacheManQuery.getValue().documentCategories.length <= 0) {
            this.httpClient.get<DocumentCategory[]>(`${this.urlConstantsService.DOCUMENT_CATEGORIES_URL}`)
                .subscribe(documentRecordCategories => this.cacheManStore.update({ documentCategories: documentRecordCategories }));
        }

        return this.cacheManQuery.select(state => state.documentCategories).pipe(filter(documentCategories => documentCategories.length > 0));
    }

    getFeatureFlagsByFacility$(facilityId: string): Observable<eFeatureName[]> {
        return this.cacheManQuery.select(state => state.features).pipe(filter(features => features != null), map(features => features[facilityId]));
    }
}
