import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InterviewQuestion, InterviewAnswerField } from '@app/model';

@Component({
  selector: 'tms-question-textarea',
  styleUrls: [],
  template: `
    <dx-text-area [value]="interviewAnswerField.value" (onValueChanged)="onTextAreaValueChanged($event)"></dx-text-area>
  `,
})
export class InterviewQuestionTextAreaComponent {
    @Input() question: InterviewQuestion;
    @Input() interviewAnswerField: InterviewAnswerField;
    @Output() onInterviewValueChanged: EventEmitter<string> = new EventEmitter<string>();


    constructor() {

    }

    onTextAreaValueChanged($event) {
      this.onInterviewValueChanged.emit($event.value);
    }
}
