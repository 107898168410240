import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { OperationResponse, Practitioner, PagedResult, Contract } from '@app/model';

@Injectable({
	providedIn: 'root',
})
export class PractitionersService {
	constructor(private http: HttpClient,
		private urlConstantsService: UrlConstantsService) {
	}

	getById(id: string) {
		return this.http.get<OperationResponse<Practitioner>>(`${this.urlConstantsService.PRACTITIONERS_URL}/${id}`)
			.pipe(map(response => response.data));
	}

	updatePractitioner(practitioner: Practitioner) {
		return this.http.put<OperationResponse<Practitioner>>(`${this.urlConstantsService.PRACTITIONERS_URL}`, practitioner)
			.pipe(map(response => response.data));
	}

	getAllPractitioners(facilityId: string = ''): Observable<Practitioner[]> {
		return this.http.get<OperationResponse<Practitioner[]>>(`${this.urlConstantsService.PRACTITIONERS_URL}?facilityId=${facilityId}`)
			.pipe(map(response => response.data));
	}

	searchPractitioners(searchText: string, facilityId: string, isPhysician: boolean = true, limit: number = 1000, isActive: boolean = true) {
		return this.http.get<OperationResponse<Practitioner[]>>(`${this.urlConstantsService.PRACTITIONERS_URL}/search?searchText=${searchText}&facilityId=${facilityId}&isPhysician=${isPhysician}&isActive=${isActive}&limit=${limit}`)
			.pipe(map(response => response.data));
	}

	// Practitioner Portal
	getPortalPatientDashboard(facilityId: string, isActive: boolean, isDischarged: boolean) {
        return this.http.get<PagedResult<Contract>>(`${this.urlConstantsService.PRACTITIONERS_URL}/portal/dashboard?facilityId=${facilityId}&isActive=${isActive}&isDischarged=${isDischarged}`)
            .pipe(map(usersPagedResult => usersPagedResult.items))
    }
}
