import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AnalyticsService } from './analytics.service';
import { AnalyticsEvent } from '@app/model';
import { modalOptions, modalFullScreenOption } from '@app/utils';

@Injectable({
  providedIn: 'root'
})
export class TmsModalService {
  private modalRefs: NgbModalRef[] = [];

  constructor(private analyticsService: AnalyticsService,
    private modalService: NgbModal) { }

  open(content: any, additionalModalOptions: NgbModalOptions = {}, reportEvent: boolean = false, event: AnalyticsEvent = null): NgbModalRef {
    const modalRef = this.modalService.open(content, { ...modalOptions, ...additionalModalOptions });
    this.modalRefs.push(modalRef);
    if (reportEvent) {
      this.reportEvent(event);
    }
    return modalRef;
  }

  close(result?: any, reportEvent: boolean = false, event: AnalyticsEvent = null): void {
    if (reportEvent) {
      this.reportEvent(event);
    }
    const modalRef = this.getModalRef();
    if (modalRef) {
      modalRef.close(result);
    }
  }

  dismiss(result?: any, reportEvent: boolean = false, event: AnalyticsEvent = null): void {
    if (reportEvent) {
      this.reportEvent(event);
    }
    const modalRef = this.getModalRef();
    if (modalRef) {
      modalRef.dismiss(result);
    }
  }

  openFullScreenModal(content: any, reportEvent: boolean = false, event: AnalyticsEvent = null): NgbModalRef {
    return this.open(content, modalFullScreenOption, reportEvent);
  }

  private reportEvent(event: AnalyticsEvent) {
    if (event) {
      this.analyticsService.trackEvent(event.eventName, event.eventData);
    }
  }

  private getModalRef() {
    const modalRef = this.modalRefs.pop();
    if (modalRef == null) {
      console.error('ModalRef is null. Are you sure this modal was opened with the TmsModalService?');
    }
    return modalRef;
  }

}


