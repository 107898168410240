import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { OperationResponse, AuditRequest } from '@app/model';
import { UrlConstantsService } from '@app/core/services/url-constants.service';

@Injectable({
    providedIn: 'root',
})
export class AuditRequestService {
    constructor(private http: HttpClient,
        private urlConstantsService: UrlConstantsService) { }

    getAuditRequests(facilityId: string, month?: number, year?: number) {
        return this.http.get<OperationResponse<AuditRequest[]>>(`${this.urlConstantsService.AUDIT_REQUEST_URL}?facilityId=${facilityId}&month=${month}&year=${year}`)
            .pipe(map(response => response.data));
    }

    getByAuditRequestId(requestId: string) {
        return this.http.get<OperationResponse<AuditRequest>>(`${this.urlConstantsService.AUDIT_REQUEST_URL}/${requestId}`)
            .pipe(map(response => response.data));
    }

    getByAuditRequestDownloadUrl(requestId: string) {
        return this.http.get<OperationResponse<string>>(`${this.urlConstantsService.AUDIT_REQUEST_URL}/${requestId}/download`)
            .pipe(map(response => response.data));
    }

    addNewAuditRequest(request: AuditRequest) {
        return this.http.post<OperationResponse<AuditRequest>>(`${this.urlConstantsService.AUDIT_REQUEST_URL}`, request)
            .pipe(map(response => response.data));
    }

    updateAuditRequest(request: AuditRequest) {
        return this.http.put<OperationResponse<boolean>>(`${this.urlConstantsService.AUDIT_REQUEST_URL}`, request)
            .pipe(map(response => response.data));
    }

    deleteAuditRequest(requestId: string) {
        return this.http.delete<OperationResponse<boolean>>(`${this.urlConstantsService.AUDIT_REQUEST_URL}/${requestId}`)
        .pipe(map(response => response.data));
    }
}