import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { InterviewsStore, InterviewsState } from './interviews.store';

@Injectable({
  providedIn: 'root',
})
export class InterviewsQuery extends QueryEntity<InterviewsState> {

  constructor(protected store: InterviewsStore) {
    super(store);
  }

}
