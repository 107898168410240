import { Component, EventEmitter, Input, OnInit, OnChanges, SimpleChanges, Output, ChangeDetectorRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  DataListItem,
  eInterviewAnswerType,
  InterviewAnswer,
  InterviewAnswerField,
  InterviewQuestion,
  KeyValuePair,
  QuestionOrientation,
  CodingValueChangedEvent,
} from '@app/model';
import {
  CacheManService,
  ClinicalDocumentAnswerFieldsConstants,
} from '@app/core';

@Component({
  selector: 'tms-interview-question',
  styleUrls: [],
  templateUrl: './interview-question.component.html',
})
export class InterviewQuestionComponent implements OnInit, OnChanges {
  @Input() applyEasyButton: boolean = false;
  @Input() id: string;
  @Input() groupShortName: string = '';
  @Input() pageShortName: string = '';
  @Input() question: InterviewQuestion = null;
  @Input() tabShortName: string = '';
  @Input() answerType: eInterviewAnswerType = 0;
  @Input() startOfCareDate: Date = null;
  @Input() disabled: boolean = false;
  @Input() markedNA: boolean = false;
  @Input() answer: InterviewAnswer = null; // for overriding answer from interview question
  @Output() onInterviewValueChanged: EventEmitter<InterviewAnswer> = new EventEmitter<InterviewAnswer>();

  constructor(private cacheManService: CacheManService,
              private cdr: ChangeDetectorRef) {}

  dataListItems: DataListItem[] = [];
  interviewAnswerField: InterviewAnswerField;
  orientation: string = '';
  questionType: number = null;
  showComment: boolean = false;
  commentText: string = '';
  showAdditionalText: boolean = false;
  additionalText: string = '';

  currentValue: string = '';
  currentValues: string[] = [];

  ngOnInit() {
    if (this.question.options) {
      let orientationValue = 0;
      orientationValue = this.question.options.orientation;
      this.orientation = QuestionOrientation[orientationValue];

      if (this.question.options.showComment) {
        this.showComment = this.question.options.showComment;
        this.commentText = this.question.answer.textArea;
        this.cdr.detectChanges();
      }

      if (this.question.options.showAdditionalText) {
        this.showAdditionalText = this.question.options.showAdditionalText;
        this.additionalText = this.question.options.additionalText;
      }
    }

    if (this.answer) {
      this.interviewAnswerField = { value: this.answer.value, values: this.answer.values};
    } else {
      switch(this.answerType) {
        case eInterviewAnswerType.default:
          this.interviewAnswerField = this.getValueFromAnswer(this.question.answer);
          break;
        case eInterviewAnswerType.sectionggGoal:
          this.interviewAnswerField = this.getValueFromFields(this.question.answer.fields, ClinicalDocumentAnswerFieldsConstants.Goal);
          break;
        case eInterviewAnswerType.shortTermGoal:
          this.interviewAnswerField = this.getValueFromFields(this.question.answer.fields, ClinicalDocumentAnswerFieldsConstants.STG);
          break;
        case eInterviewAnswerType.longTermGoal:
          this.interviewAnswerField = this.getValueFromFields(this.question.answer.fields, ClinicalDocumentAnswerFieldsConstants.LTG);
          break;
        case eInterviewAnswerType.priorLevel:
          this.interviewAnswerField = this.getValueFromFields(this.question.answer.fields, ClinicalDocumentAnswerFieldsConstants.PLOF);
          break;
        case eInterviewAnswerType.primaryQuestion:
          this.interviewAnswerField = this.getValueFromFields(this.question.answer.fields, ClinicalDocumentAnswerFieldsConstants.PrimaryQuestion);
        default:
          this.interviewAnswerField = this.getValueFromAnswer(this.question.answer);
      }
    }

    this.currentValue = this.interviewAnswerField.value; // (this.question.answer?.value ?? false) ? this.question.answer?.value : '';
    this.currentValues = this.interviewAnswerField.values; // (this.question.answer?.values ?? false) ? this.question.answer?.values : [];

    this.getDataListItems(this.question, this.interviewAnswerField)
      .pipe(take(1))
      .subscribe(datalistitems => this.dataListItems = datalistitems);

    if (this.interviewAnswerField.questionType != null) {
      this.questionType = this.interviewAnswerField.questionType;
    } else {
      this.questionType = this.question.questionType;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['applyEasyButton'] && changes['applyEasyButton'].currentValue) {
      if (!this.interviewAnswerField?.value) {
        if (this.dataListItems.length > 0) {
          this.interviewAnswerField = {
            value: this.dataListItems[0].value,
          }
        }
      }
    }

    if (changes['disabled'] && changes['disabled'].currentValue) {
      // clear value if disabling field 
      if (this.interviewAnswerField && (!this.interviewAnswerField.value || !this.interviewAnswerField.values)) {
          this.interviewAnswerField = {
            value: null,
            values: [],
          }
      }
    }

    if (changes['markedNA'] && !changes['markedNA'].isFirstChange()) {
      if (changes['markedNA'] && changes['markedNA'].currentValue && changes['disabled'] && changes['disabled'].currentValue) {
        this.sendInterviewAnswer(null, []);
      } else if (changes['markedNA'] && !changes['markedNA'].currentValue) {
        const answer: InterviewAnswer = this.getInterviewAnswer();
        answer.value = this.currentValue;
        answer.values = this.currentValues;
        answer.textArea = this.commentText;
        this.onInterviewValueChanged.emit(answer);
      }
    }
  }

  onValueChanged($event) {
    this.currentValue = $event;
    this.currentValues = null;
    this.sendInterviewAnswer($event, null);
  }

  // for lists with multiple values
  onSelectionChanged($event) {
    this.currentValue = null;
    this.currentValues = $event;
    this.sendInterviewAnswer(null, $event);
  }

  onTextAreaValueChanged(e) {
    const answer: InterviewAnswer = this.getInterviewAnswer();
    answer.value = this.currentValue;
    answer.values = this.currentValues;
    answer.textArea = this.commentText;
    this.onInterviewValueChanged.emit(answer);
}

  onBasicNarrativeChanged(e) {
    const answer: InterviewAnswer = this.getInterviewAnswer();
    answer.values = e.dataListItems.map(dataitem => dataitem.value);
    answer.value = e.narrative;
    this.onInterviewValueChanged.emit(answer);
  }

  onICDCodeTypeAheadChanged(e: CodingValueChangedEvent) {
    const answer: InterviewAnswer = this.getInterviewAnswer();
    answer.value = e.value;
    answer.textArea = e.textArea;
    this.onInterviewValueChanged.emit(answer);
  }

  private getInterviewAnswer(): InterviewAnswer {
    return {
      id: this.question.id, // TODO: Will the answer.id be the same as question.id?
      shortName: this.question.shortName,
      page: this.pageShortName,
      tab: this.tabShortName,
      group: this.groupShortName,
      textArea: '',
      fields: [],
      userId: null
    }
  }

  private sendInterviewAnswer(value: string, values: string[]) {
    const answer = this.getInterviewAnswer();

    switch(this.answerType) {
      case eInterviewAnswerType.default:
        answer.value = value;
        answer.values = values;
        break;
      case eInterviewAnswerType.shortTermGoal:
        answer.fields = [this.getAnswerFieldValue(ClinicalDocumentAnswerFieldsConstants.STG, value, values)]
        break;
      case eInterviewAnswerType.longTermGoal:
        answer.fields = [this.getAnswerFieldValue(ClinicalDocumentAnswerFieldsConstants.LTG, value, values)];
        break;
      case eInterviewAnswerType.priorLevel:
        answer.fields = [this.getAnswerFieldValue(ClinicalDocumentAnswerFieldsConstants.PLOF, value, values)];
        break;
      case eInterviewAnswerType.sectionggGoal:
        answer.fields = [this.getAnswerFieldValue(ClinicalDocumentAnswerFieldsConstants.Goal, value, values)];
        break;
      case eInterviewAnswerType.primaryQuestion:
        answer.fields = [this.getAnswerFieldValue(ClinicalDocumentAnswerFieldsConstants.PrimaryQuestion, value, values)];
        break;
      default:
        answer.value = value;
        answer.values = values;
    }

    answer.textArea = this.commentText;

    this.onInterviewValueChanged.emit(answer);
  }

  private getValueFromAnswer(answer: InterviewAnswer): InterviewAnswerField {
    if (answer) {
      return { value: answer.value, values: answer.values };
    } else {
      return { value: null, values: null };
    }
  }

  private getValueFromFields(keyValueList: KeyValuePair<string, InterviewAnswerField>[], key: string): InterviewAnswerField {
    let index = -1;
    if (keyValueList) {
      index = keyValueList.findIndex(kvp => kvp.key === key);
    }
    if (index >= 0) {
      return keyValueList[index].value;
    } else {
      return { value: null, values: null }
    }
  }

  private getAnswerFieldValue(key: string, value: string, values: string[]): KeyValuePair<string, InterviewAnswerField> {
    return {
      key: key,
      value: {
        value: value,
        values: values,
        dataListShortName: this.interviewAnswerField.dataListShortName,
        customAnswerOptions: this.interviewAnswerField.customAnswerOptions,
      },
    }
  }


  private getDataListItems(question: InterviewQuestion, answerField: InterviewAnswerField): Observable<DataListItem[]> {
    if (answerField.dataListShortName) {
      return this.cacheManService.getDataListItems$(answerField.dataListShortName).pipe(map(dataListItems => {
        if (answerField.customAnswerOptions) {
          return [...dataListItems, ...answerField.customAnswerOptions];
        } else {
          return dataListItems;
        }
      }))
    } else if (answerField.customAnswerOptions) {
      if (answerField.customAnswerOptions) {
        return of(answerField.customAnswerOptions);
      }
    } else {
      if (question.dataListShortName) {
        return this.cacheManService.getDataListItems$(question.dataListShortName).pipe(map(dataListItems => {
          if (question.customAnswerOptions) {
            return [...dataListItems, ...question.customAnswerOptions];
          }
        }))
      } else {
        if (question.customAnswerOptions) {
          return of(question.customAnswerOptions);
        }
      }
    }
  }
}
