import { Component, Input, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { InterviewData } from '@app/model/interviews/interviewdata';
import { InterviewSession } from '@app/model/interviews/interviewsession';

@Component({
  selector: 'tms-tabbed-interview',
  styleUrls: [],
  templateUrl: 'tabbed-interview.component.html',
})
export class TabbedInterviewComponent implements AfterViewInit {
    @Input() context: InterviewData;
    @Input() session: InterviewSession;

    constructor(private cd: ChangeDetectorRef) {
    }

    ngAfterViewInit() {
        this.cd.detectChanges();
    }

    showContextValues() {
        // eslint-disable-next-line no-console
        console.log(this.context);
      }

}
