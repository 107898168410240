import { Injectable } from "@angular/core";
import { SecondarySchedulePatientWeek } from "@app/model";
import { ActiveState, EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface SecondarySchedulePatientsState extends EntityState<SecondarySchedulePatientWeek>, ActiveState {}

const initialState: SecondarySchedulePatientsState = {
    active: null,
}

@Injectable({ providedIn: 'root'})
@StoreConfig({ name: 'secondary-planner-patients', idKey: 'patientId' })
export class SecondarySchedulePatientsStore extends EntityStore<SecondarySchedulePatientsState> {
    constructor() {
        super(initialState);
    }
}