import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { User, WeeklyEmployeeAvailability, UserSlim } from '@app/model';
import { DeepCopyUtils } from '@app/utils';
import { CacheManService, UsersService, ToastMessageService } from '@app/core';
import { OnDestroyComponent } from '@app/shared';
import { takeUntil, take } from 'rxjs/operators';

@Component({
  selector: 'tms-user-work-hours-managment',
  templateUrl: './user-work-hours-management.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserWorkHoursManagementComponent extends OnDestroyComponent implements OnInit, OnDestroy {

  currentEmployee: User;
  users: UserSlim[] = null;
  selectedEmployee: string = '';

  facilityWorkHours: WeeklyEmployeeAvailability[] = [];
  availabilityForm: WeeklyEmployeeAvailability;

  primaryFacilityId: string;

  constructor(private cacheManService: CacheManService,
    private toastMessageService: ToastMessageService,
    private usersService: UsersService,
    private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
  }

  getFacilityName(facilityId: string) {
    return this.cacheManService.getFacilityName(facilityId);
  }

  employeeDisplayName(e) {
    return e ? `${e.name} (${e.ecode})` : null;
  }

  employeeTypeAhead = (e) => {
    return this.usersService.employeesSearch(e);
  }

  onEmployeeValueChanged(e) {
    if (e) {
      this.selectedEmployee = e.id;
    } else {
      this.selectedEmployee = '';
    }

    if (this.selectedEmployee && this.selectedEmployee !== '') {
      this.usersService.getUserById(this.selectedEmployee)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(employee => {
        this.currentEmployee = DeepCopyUtils.deepCopyObject(employee);
        this.facilityWorkHours = [];
        if (this.currentEmployee.employee && this.currentEmployee.employee.facilityAvailability && this.currentEmployee.employee.facilityAvailability.length > 0) {
          this.primaryFacilityId = this.currentEmployee.facilityIds[0];
          this.currentEmployee.facilityIds.forEach(fac => {
            const availability = this.currentEmployee.employee.facilityAvailability.find(f => f.facilityId === fac)
            if (availability) {
              this.facilityWorkHours.push(availability);
            } else {
              const wh: WeeklyEmployeeAvailability = {
                facilityId: fac, mondayAvailableMinutes: 0, tuesdayAvailableMinutes: 0, wednesdayAvailableMinutes: 0,
                thursdayAvailableMinutes: 0, fridayAvailableMinutes: 0, saturdayAvailableMinutes: 0, sundayAvailableMinutes: 0,
              };
              this.facilityWorkHours.push(wh);
            }
          });
        } else {
          if (this.currentEmployee.employee && this.currentEmployee.facilityIds && this.currentEmployee.facilityIds.length > 0) {
            this.primaryFacilityId = this.currentEmployee.facilityIds[0];
            this.currentEmployee.facilityIds.forEach(fac => {
              const wh: WeeklyEmployeeAvailability = {
                facilityId: fac, mondayAvailableMinutes: 0, tuesdayAvailableMinutes: 0, wednesdayAvailableMinutes: 0,
                thursdayAvailableMinutes: 0, fridayAvailableMinutes: 0, saturdayAvailableMinutes: 0, sundayAvailableMinutes: 0,
              };
              this.facilityWorkHours.push(wh);
            });
          }
        }
        this.cdr.detectChanges();
      });
    }
  }

  onSubmit() {
    this.facilityWorkHours.forEach(w => {
      if (!w.sundayAvailableMinutes) {
        w.sundayAvailableMinutes = 0;
      }

      if (!w.mondayAvailableMinutes) {
        w.mondayAvailableMinutes = 0;
      }

      if (!w.tuesdayAvailableMinutes) {
        w.tuesdayAvailableMinutes = 0;
      }

      if (!w.wednesdayAvailableMinutes) {
        w.wednesdayAvailableMinutes = 0;
      }

      if (!w.thursdayAvailableMinutes) {
        w.thursdayAvailableMinutes = 0;
      }

      if (!w.fridayAvailableMinutes) {
        w.fridayAvailableMinutes = 0;
      }

      if (!w.saturdayAvailableMinutes) {
        w.saturdayAvailableMinutes = 0;
      }
    });
    
    this.currentEmployee.employee.facilityAvailability = this.facilityWorkHours;
    this.usersService.updateBasicProfile(this.currentEmployee)
      .pipe(take(1))
      .subscribe(user => {
        this.toastMessageService.successNotification('Successfully updated work hours.');
        this.selectedEmployee = '';
        this.cdr.detectChanges();
      }, error => {
        this.toastMessageService.errorNotification('Error updating work hours.');
      });
  }

  ngOnDestroy() {
    super.onDestroy();
  }
}
