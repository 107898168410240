<div class="modal-body">
  <div class="warning-modal-layout">
    <div *ngIf="showWarningIcon">
      <i *ngIf="!showDeleteWarningIcon" class="fas fa-exclamation-triangle warning-icon"></i>
      <div *ngIf="showDeleteWarningIcon" class="warning-delete-icon-container">
        <i class="fas fa-trash warning-delete-icon "></i>
      </div>
    </div>
    <div class="warning-row-divider">
      <div class="warning-title">
        <ng-content select="[warning-title]"></ng-content>
      </div>

      <div class="warning-content">
        <ng-content select="[warning-content]"></ng-content>
      </div>
    </div>
    <div class="warning-row-divider">
      <dx-button *ngIf="cancelButtonVisible" [text]="cancelText" (onClick)="cancel()"></dx-button>
      <dx-button *ngIf="actionButtonVisible" [ngClass]="{ 'tms-primary-button': !showDeleteWarningIcon, 'warning-delete-primary-button': showDeleteWarningIcon }" class="tms-submit-button" [text]="actionText"
        (onClick)="takeAction()"></dx-button>
    </div>
  </div>
</div>