import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from './url-constants.service';
import { OperationResponse, ActiveParticipation, HotList, PartBFunds, PreauthorizationTrackingRequest, OutForSignature, OutForSignatureDashboard, Unfiled, HotListMatchItem } from '@app/model';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FacilityPatientTrackingService {
    constructor(private http: HttpClient,
        private urlConstantsService: UrlConstantsService) { }

    getActiveParticipation(id: string, disciplines: number[]) {
        return this.http.get<OperationResponse<ActiveParticipation[]>>(`${this.urlConstantsService.FACILITIES_URL}/${id}/tracker/active-participation?disciplines=${disciplines}`)
        .pipe(map(response => response.data));
    }

    getHotList(id: string, isRefreshed: boolean) {
        return this.http.get<OperationResponse<HotList[]>>(`${this.urlConstantsService.FACILITIES_URL}/${id}/tracker/hot-list?isRefreshed=${isRefreshed}`)
            .pipe(map(response => response.data));
    }

    deleteHotListItem(facilityId: string, hotListItem: HotList): Observable<void> {
        const hotListMatchItem: HotListMatchItem = {
            rule: hotListItem.rule,
            patientId: hotListItem.patientId,
            patientCaseId: hotListItem.patientCaseId,
            patientEpisodeId: hotListItem.patientEpisodeId,
            discipline: hotListItem.discipline,
            dateTime: hotListItem.dateTime,
            message: hotListItem.message,
            facilityId: hotListItem.facilityId,
            hash: hotListItem.hash,
        };
        return this.http.post<OperationResponse<void>>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/tracker/dismiss`, hotListMatchItem)
            .pipe(map(response => response.data))
    }

    getPartBFunds(id: string, year: number) {
        return this.http.get<OperationResponse<PartBFunds[]>>(`${this.urlConstantsService.FACILITIES_URL}/${id}/tracker/part-b-funds?year=${year}`)
        .pipe(map(response => response.data));
    }

    getOutForSignatureItems(facilityId: string) {
        return this.http.get<OperationResponse<OutForSignatureDashboard>>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/tracker/ofs`)
            .pipe(map(response => response.data));
    }

    updateOutForSignatureItems(facilityId: string, pcdIds: string[], markAsOfs: boolean) {
        return this.http.put<OperationResponse<void>>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/tracker/ofs?markAsOfs=${markAsOfs}`, pcdIds)
            .pipe(map(response => response.data));
    }

    getUnfiledItems(facilityId: string) {
        return this.http.get<OperationResponse<Unfiled[]>>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/tracker/unfiled`)
            .pipe(map(response => response.data));
    }

    updateUnfiledItems(facilityId: string, pcdIds: string[]) {
        return this.http.put<OperationResponse<void>>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/tracker/unfiled`, pcdIds)
            .pipe(map(response => response.data));
    }
}