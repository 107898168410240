<div *ngIf="user">
    <tms-page-heading title="User Profile">
    </tms-page-heading>
    <tms-page-card>
        <dx-tabs [dataSource]="userTabs" [(selectedIndex)]="activeTabIndex"></dx-tabs>
        <div [ngSwitch]="activeTabIndex">
            <tms-user-basic-profile *ngSwitchCase="0" [user]="user" (updateUser)="updateUser($event)">
            </tms-user-basic-profile>
            <tms-user-work-hours *ngSwitchCase="1" [user]="user" (updateUser)="updateUser($event)">
            </tms-user-work-hours>
            <tms-user-impersonate *ngSwitchCase="2">         
            </tms-user-impersonate>
        </div>
    </tms-page-card>
</div>