import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Patient } from '@app/model';

export interface PatientsState extends EntityState<Patient>, ActiveState {
    // can extend properties if needed
}

const initialState: PatientsState = {
    active: null,
    loading: false,
}

@Injectable({providedIn: 'root'})
@StoreConfig({ name: 'patients' })
export class PatientsStore extends EntityStore<PatientsState> {
    constructor() {
        super(initialState);
    }
}
