import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { FacilityAdmin } from '@app/model';

export interface FacilitiesAdminState extends EntityState<FacilityAdmin>, ActiveState {}

const initialState: FacilitiesAdminState = {
  active: null,
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'facilities-admin' })
export class FacilitiesAdminStore extends EntityStore<FacilitiesAdminState> {

  constructor() {
    super(initialState);
  }

}
