export interface TemplateObject {
    // called before sending template object to template builder
    preCompileTemplateObj(): void;
}

export function templateObjToStringListUtil(obj: TemplateObject, toList: string[]): string[] {
    const stringList = [];
    toList.forEach(value => {
        if (obj[value]) {
            stringList.push(' ' + obj[value]);
        }
    });
    return stringList;
}
