import { Component, EventEmitter, Output, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DataListItem, InterviewQuestion, InterviewAnswerField } from '@app/model';
import { DataListConstants } from '@app/core';

@Component({
  selector: 'tms-interview-question-drop-down-list',
  templateUrl: './interview-question-drop-down-list.component.html',
  styleUrls: ['./interview-question-drop-down-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InterviewQuestionDropDownListComponent implements OnInit {
  @Input() question: InterviewQuestion;
  @Input() dataListItems: DataListItem[] = [];
  @Input() id: string;
  @Input() interviewAnswerField: InterviewAnswerField;
  @Input() showClearButton: boolean = false;
  @Input() disabled: boolean = false;
  @Output() onInterviewValueChanged: EventEmitter<string> = new EventEmitter<string>();

  selectedValue: any = null;
  measurementDropDownOptions: any = '';

  constructor() { }

  ngOnInit() {
    this.selectedValue = this.interviewAnswerField.value;

    if (this.question.dataListShortName === DataListConstants.ASSIST_SCALE_SCORES) {
      this.measurementDropDownOptions = { maxHeight: 300, height: 300 };
    }
  }

  onSelectedValue($event) {
      this.onInterviewValueChanged.emit($event.value);
  }

  onValidationCallback = (options) => {
    if (this.question.validationOptions && this.question.validationOptions.isRequired) {
      return options.value != null;
    } else {
      return true;
    }
  }
}
