import { Directive, EventEmitter, Output, OnDestroy } from '@angular/core';
import { NarrativeBuilderService } from '@app/core/services/narrative-builder.service';
import { NarrativeBuilder, NarrativeBuilderList, NBComponentBoolean, NBComponentText, NBComponentValue, TemplateObject } from '@app/model';
import { DeepCopyUtils } from '@app/utils';
import { OnDestroyComponent } from '../../on-destroy/on-destroy.component';
import { HtmlCharacterEntityUtils } from '@app/utils/html-character-entity.utils';

export interface NarrativeBuilderBaseEvent {
    narrative: string;
    property: string;
    selectedOptionValue: string | number;
}

@Directive({
    selector: '[tms-narrative-builder]',
})
export class NarrativeBuilderBaseDirective extends OnDestroyComponent implements OnDestroy {
    @Output() onNarrativeValueChanged: EventEmitter<NarrativeBuilderBaseEvent> = new EventEmitter<NarrativeBuilderBaseEvent>();

    public alertUser: boolean = false;
    public nbl: NarrativeBuilderList;
    public narrative: string;
    protected narrativeBuilder: NarrativeBuilder = null;
    protected template: TemplateObject = null;
    protected templateName: string;

    constructor(private narrativeBuilderService: NarrativeBuilderService) {
        super();
    }

    ngOnDestroy() {
        super.onDestroy();
    }

    init(narrativeBuilder: NarrativeBuilder): void {
        this.narrativeBuilder = { ...narrativeBuilder };
        // populate narrative builder with existing values
        if (this.narrativeBuilder.selectedOptions) {
            this.narrativeBuilder.selectedOptions.forEach(kv => {
                this.nbl.options[kv.key].selectedValue = kv.value;
            });
        } else {
            this.narrativeBuilder.selectedOptions = [];
        }

        if (this.narrativeBuilder.text) {
            this.narrative = this.narrativeBuilder.text;
        }

        if (this.narrativeBuilder.isCustomized != null) {
            // if user makes a selection, inform them that the customized changes will be lost 
            this.alertUser = this.narrativeBuilder.isCustomized;
        }
    }

    onCheckBoxValueChanged($event: NBComponentBoolean): void {
        if (this.validate()) {
            // $event => { isChecked: e.value, optionValue: this.optionValue, optionValueTextIndex: nIndex, property: this.property, }
            this.template[$event.property] = $event.isChecked ? $event.optionValue.optionValues[$event.optionValueTextIndex].text : '';
            const selectedOptionValue = $event.isChecked ? $event.optionValue.id : null;
            this.generateNarrative(this.templateName, this.template, $event.property, selectedOptionValue, $event.overrideManualEdits);
        }
    }

    onDropdownValueChanged($event: NBComponentValue): void {
        if (this.validate()) {
            if ($event.optionValuesIndex == null) {
                // nothing to generate because option not selected
                this.updateNarrativeBuilder(true, $event.property, null);
                this.onNarrativeValueChanged.emit({ narrative: this.narrative, property: $event.property, selectedOptionValue: null });
            } else if ($event.optionValuesIndex >= 0 && $event.optionValueTextIndex >= 0) {
                this.template[$event.property] = $event.optionValues[$event.optionValuesIndex].optionValues[$event.optionValueTextIndex].text;
                this.generateNarrative(this.templateName, this.template, $event.property, $event.optionValues[$event.optionValuesIndex].id, $event.overrideManualEdits);
            } else if (!!$event.customValue) {
                // user added custom text
                this.template[$event.property] = $event.optionValues[0].optionValues[0].text;
                this.generateNarrative(this.templateName, this.template, $event.property, $event.optionValues[0].text, $event.overrideManualEdits);
            }
        }
    }

    onManualUserChange(e: string): void {
        this.alertUser = true;
        this.narrative = e;
        this.updateNarrativeBuilder(true);
        this.onNarrativeValueChanged.emit({ narrative: this.narrative, property: null, selectedOptionValue: null });
    }

    onTextBoxValueChanged($event: NBComponentText) {
        if (this.validate()) {
            // $event => {property: this.property, value: $event}
            this.template[$event.property] = $event.value;
            this.generateNarrative(this.templateName, this.template, $event.property, $event.value, $event.overrideManualEdits);
        }
    }

    private generateNarrative(templateName: string, obj: TemplateObject, property: string, selectedOptionValue: string | number, overrideManualEdits: boolean): void {
        if (obj == null) {
            console.error('NarrativeBuilderBaseComponent: Cannot generate narrative. Template is null!');
            return;
        }

        if (obj.preCompileTemplateObj !== undefined) {
            obj.preCompileTemplateObj();
        }

        if (!!overrideManualEdits) {
            this.alertUser = false;
        }

        // if overrideManualEdits == null, we are probably regenerating the narrative builder or user hasn't triggered warning message. 
        // We don't want to override the customized text with narrative builder changes if isCustomzied == true
        if (overrideManualEdits == null && (this.narrativeBuilder.isCustomized == null || !this.narrativeBuilder.isCustomized)) {
            this.narrative = this.narrativeBuilderService.generateNarrative(templateName, obj);
            this.updateNarrativeBuilder(false, property, selectedOptionValue);
        }

        // if overrideManualEdits == true, override whatever customized text changes the user made with narrative builder text
        if (overrideManualEdits) {
            this.narrative = this.narrativeBuilderService.generateNarrative(templateName, obj);
            this.updateNarrativeBuilder(false, property, selectedOptionValue);
        }

        // don't generate text, but still add selected options to narrativeBuilder for saving later on
        if (this.narrativeBuilder.isCustomized) {
            this.updateNarrativeBuilder(true, property, selectedOptionValue);
        }
        this.narrative = HtmlCharacterEntityUtils.convertEntitiesToLiterals(this.narrative ?? "");
        this.onNarrativeValueChanged.emit({ narrative: this.narrative, property: property, selectedOptionValue: selectedOptionValue });
    }

    private updateNarrativeBuilder(manualUserEdits: boolean, property?: string, selectedOptionValue?: any): void {
        if (property) {
            const index = this.narrativeBuilder.selectedOptions.findIndex(option => option.key === property);
            if (index >= 0) {
                // remove selected option
                if (selectedOptionValue == null) {
                    this.narrativeBuilder = {
                        ...this.narrativeBuilder,
                        selectedOptions: [
                            ...this.narrativeBuilder.selectedOptions.slice(0, index),
                            ...this.narrativeBuilder.selectedOptions.slice(index + 1),
                        ]
                    };
                    this.template[property] = null;
                } else {
                    this.narrativeBuilder = {
                        ...this.narrativeBuilder,
                        selectedOptions: [
                            ...this.narrativeBuilder.selectedOptions.slice(0, index),
                            {
                                key: this.narrativeBuilder.selectedOptions[index].key,
                                value: selectedOptionValue,
                            },
                            ...this.narrativeBuilder.selectedOptions.slice(index + 1),
                        ]
                    }
                }
            } else {
                this.narrativeBuilder = {
                    ...this.narrativeBuilder,
                    selectedOptions: [
                        ...this.narrativeBuilder.selectedOptions,
                        { key: property, value: selectedOptionValue },
                    ],
                }
            }
        }
        this.narrativeBuilder = {
            ...this.narrativeBuilder,
            text: this.narrative,
            isCustomized: manualUserEdits,
        }

    }

    private validate(): boolean {
        let valid = true;
        if (this.template == null) {
            console.error('NarrativeBuilderBaseComponent: Cannot generate narrative. Template is null! Please assign template in derived component');
            valid = false;
        }

        if (this.templateName == null) {
            console.error('NarrativeBuilderBaseComponent: Cannot generate narrative. TemplateName is null! Please assign templateName in derived component');
            valid = false;
        }

        if (this.narrativeBuilder == null) {
            console.error('NarrativeBuilderBaseComponent: Cannot generate narrative. NarrativeBuilder is null! Please call init() in derived component');
            valid = false;
        }

        return valid;
    }

}
