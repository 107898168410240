export enum ePermissions {
    None = 0,
    PatientsView = 1,
    PatientsAdd = 2,
    PatientsUpdate = 3,
    PatientsDelete = 4,
    TimeclockView = 5,
    TimeclockAdd = 6,
    TimeclockTravel = 7,
    ChargesView = 8,
    ChargesAdd = 9 ,
    ChargesUpdate = 10,
    ChargesDelete = 11,
    FacilityView = 12,
    FacilityAdminView = 13,
    FacilityAdminAdd = 14,
    FacilityAdminUpdate = 15,
    FacilityAdminDelete = 16,
    InterviewView = 17,
    InterviewAdd = 18,
    InterviewUpdate = 19,
    InterviewDelete = 20,
    PlannerView = 21,
    PlannerUpdate = 22,
    ReportsView = 23,
    CanViewChargesForAllDisciplines = 24,
    ScreensAdd = 25,
    ScreensDelete = 26,
    ScreensUpdate = 27,
    ScreensComplete = 28,
    ScreensView = 29,
    CasesAdd = 30,
    CasesDelete = 31,
    CasesUpdate = 32,
    CasesView = 33,
    EpisodesAdd = 34,
    EpisodesUpdate = 35,
    EpisodesDelete = 36,
    EpisodesView = 37,
    EChartsView = 38,
    EChartsAdd = 39,
    EChartsUpdate = 40,
    EChartsDelete = 41,
    UsersAdd = 42,
    UsersUpdate = 43,
    UsersView = 44,
    PhysiciansAdd = 45,
    PhysiciansUpdate = 46,
    PhysiciansView = 47,
    AnnouncementsAdd = 48,
    AnnouncementsUpdate = 49,
    AnnouncementsView = 50,
    ClientsAdd = 51,
    ClientsUpdate = 52,
    ClientsView = 53,
    ContractsAdd = 54,
    ContractsUpdate = 55,
    ContractsView = 56,
    IntermediaryAdd = 57,
    IntermediaryUpdate = 58,
    IntermediaryView = 59,
    MasterPayersAdd = 60,
    MasterPayersUpdate = 61,
    MasterPayersView = 62,
    Admin = 63,
    PractitionerPortalView = 64,
    DenialManagementView = 65,
    UnlockTreatmentRequestView = 66,
    ClientPortalView = 67,
    ClinicalDocumentView = 68,
    ClinicalDocumentEdit = 69,
    Startups = 70,
    Exports = 71,
    Billing = 72,
    PremierCoding = 73,
    Operations = 74,
    InsuranceVerification = 75,
    JumpToNavigation = 76,
    KnowledgeBase = 77,
    PowerBIReports = 78,
    ClinicalExcellenceAdmin = 79,
    MdsUpload = 80,
    CodingIntegrityUpdate = 81,
    CodingIntegrityAssignment = 82,
    IntegrationManagement = 83,
    BillingView = 84,
    HumanResources = 85,
    ChangePayer = 86,
    OperationsReports = 87,
    LinkEditor = 88,
    FunctionBuilders = 89,
    RestorativeNursing = 90,
    ChangePreviousPayer = 91,
    ComplianceView = 92,
    EditICDCodes = 93,
    AddArdAssessment = 94,
    PremierCodingUploadDocuments = 95,
    CanSeeAllEpisodesForPatients = 96,
    IcdAdmin = 97,
}