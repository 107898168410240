export enum eMissedVisitType{
    Unspecified = 0,

    Refusal = 1,

    Illness = 2,

    Unavailable = 3,

    OnHold = 4,

    Student = 5,

    Note = 6,

    Cancel = 7
}