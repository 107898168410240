import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, finalize, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { PagedResult, Payer, OperationResponse } from '@app/model';
import { PayersQuery, PayersStore } from '../stores';

@Injectable({
	providedIn: 'root',
})
export class PayersService {
    constructor(private http: HttpClient,
        private payersStore: PayersStore,
        private payersQuery: PayersQuery,
        private urlConstantsService: UrlConstantsService) { }

    getPayerById(payerId: string): Observable<Payer> {
        this.payersQuery.selectLoading()
            .subscribe(loading => {
                if (!loading) {
                    if (this.payersQuery.getCount() <= 0) {
                        this.getAllFromServer().subscribe(() => { });
                    }
                }
            });

        return this.payersQuery.selectEntity(payerId);
    }

    getAll() {
        this.payersQuery.selectLoading()
            .subscribe(loading => {
                if (!loading) {
                    if (this.payersQuery.getCount() <= 0) {
                        this.getAllFromServer().subscribe(() => { });
                    }
                }
            });

        return this.payersQuery.selectAll().pipe(filter(payers => payers.length > 0));
    }

    private getAllFromServer() {
        this.payersStore.setLoading(true);
        return this.http.get<PagedResult<Payer>>(`${this.urlConstantsService.PAYERS_URL}`)
            .pipe(
                map(payersPagedResult => payersPagedResult.items),
                tap(payers => {
                    this.payersStore.set(payers);
                }),
                finalize(() => this.payersStore.setLoading(false)));
    }

    getPayersForEpisode(facilityId: string, admitDate: Date) {
        const day = admitDate.getDate();
        const month = (admitDate.getMonth() + 1);
        const year = admitDate.getFullYear();

        return this.http.get<OperationResponse<Payer[]>>(`${this.urlConstantsService.PAYERS_URL}/episodes?facilityId=${facilityId}&day=${day}&month=${month}&year=${year}`)
            .pipe(map(response => response.data));
    }

    getPayersByClientAndFacility(clientId: string, facilityId: string) {
        return this.http.get<OperationResponse<Payer[]>>(`${this.urlConstantsService.PAYERS_URL}/facility?facilityId=${facilityId}&clientId=${clientId}`)
            .pipe(map(response => response.data));
    }

    addPayer(payer: Payer) {
        return this.http.post<OperationResponse<Payer>>(`${this.urlConstantsService.PAYERS_URL}`, payer)
            .pipe(
                map(response => response.data),
                tap(response => {
                    this.payersStore.add(response);
                }));
    }

    updatePayer(payer: Payer) {
        return this.http.put<OperationResponse<Payer>>(`${this.urlConstantsService.PAYERS_URL}/${payer.id}`, payer)
            .pipe(
                map(response => response.data),
                tap(response => {
                    this.payersStore.update(payer.id, entity => {
                        return {
                            ...response,
                        };
                    });
                }));
    }

    deletePayer(payerId: string) {
        return this.http.delete(`${this.urlConstantsService.PAYERS_URL}/${payerId}`)
            .pipe(tap(() => this.payersStore.remove(payerId)))
    }
}
