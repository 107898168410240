import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UrlConstantsService } from './url-constants.service';
import {
    GeneratedReportResponse,
    OperationResponse,
    ReportDashboard,
    ReportItem,
    ReportRequest,
 } from '@app/model';
 import * as moment from 'moment';
import { noLoadingSpinner } from '../interceptors';

@Injectable({
	providedIn: 'root',
})
export class ReportsService {

    constructor(private http: HttpClient,
                private urlConstantsService: UrlConstantsService) {
    }

    getReportsDashboard(facilityId: string = null) {
        return this.http.get<ReportDashboard>(`${this.urlConstantsService.REPORTS_URL}?facilityId=${facilityId}`)
    }

    getReportItem(facilityId: string, reportShortName: string, isSchedulingMode: boolean = false): Observable<ReportItem> {
        return this.http.get<OperationResponse<ReportItem>>(`${this.urlConstantsService.REPORTS_URL}/${facilityId}/${reportShortName}?isSchedulingMode=${isSchedulingMode ? 1 : 0}`)
                    .pipe(map(response => response.data));
    }

    sendReportItemInput(facilityId: string, reportShortName: string, reportItem: ReportRequest): Observable<ReportItem> {
        return this.http.put<OperationResponse<ReportItem>>(`${this.urlConstantsService.REPORTS_URL}/${facilityId}/${reportShortName}`, reportItem)
            .pipe(map(response => response.data));
    }

    generateReport(reportRequest: ReportRequest, isDownload: boolean = false): Observable<string> {
        const queryParams = isDownload ? `?isDownload=true` : '';
        return this.http.post<OperationResponse<string>>(`${this.urlConstantsService.REPORTS_URL}/generateReport${queryParams}`, reportRequest, { context: noLoadingSpinner() })
            .pipe(catchError(error => throwError(error)),
                    map(response => response.data));
    }

    downloadReport(guid: string, shortName: string = null): Observable<string> {
        return this.http.post<OperationResponse<GeneratedReportResponse>>(`${this.urlConstantsService.REPORTS_URL}/downloadReport?guid=${guid}&shortName=${shortName}`, null, { context: noLoadingSpinner() })
            .pipe(catchError(error => throwError(error)), map(response => response.data.downloadUrl));
    }

    getDocumentRecordUrl(guid: string): Observable<string> {
        return this.http.get<OperationResponse<GeneratedReportResponse>>(`${this.urlConstantsService.REPORTS_URL}/getDocumentRecordUrl/${guid}`, { context: noLoadingSpinner() })
            .pipe(map(response => response.data.downloadUrl));
    }

    getStartupReport(facilityId: string) {
        return this.http.get<OperationResponse<GeneratedReportResponse>>(`${this.urlConstantsService.REPORTS_URL}/startupReport?facilityId=${facilityId}`)
        .pipe(catchError(error => throwError(error)),
              map(response => response.data));
    }

    sendStartupReport(reportRequest: ReportRequest) {
        return this.http.post<OperationResponse<GeneratedReportResponse>>(`${this.urlConstantsService.REPORTS_URL}/startupReport`, reportRequest)
        .pipe(catchError(error => throwError(error)),
              map(response => response.data));
    }

    getCloseoutReport(facilityId: string) {
        return this.http.get<OperationResponse<GeneratedReportResponse>>(`${this.urlConstantsService.REPORTS_URL}/closeoutReport?facilityId=${facilityId}`)
        .pipe(catchError(error => throwError(error)),
              map(response => response.data));
    }

    sendCloseoutReport(reportRequest: ReportRequest) {
        return this.http.post<OperationResponse<GeneratedReportResponse>>(`${this.urlConstantsService.REPORTS_URL}/closeoutReport`, reportRequest)
        .pipe(catchError(error => throwError(error)),
              map(response => response.data));
    }

    getNonCoveredCostReport(id: string) {
        return this.http.get<OperationResponse<GeneratedReportResponse>>(`${this.urlConstantsService.REPORTS_URL}/noncoveredCostReport?id=${id}`)
        .pipe(catchError(error => throwError(error)),
              map(response => response.data));
    }

    getInvoiceReport(id: string) {
        return this.http.get<OperationResponse<GeneratedReportResponse>>(`${this.urlConstantsService.REPORTS_URL}/invoiceReport?id=${id}`)
        .pipe(catchError(error => throwError(error)),
              map(response => response.data));
    }

    getPrebillReport(id: string) {
        return this.http.get<OperationResponse<GeneratedReportResponse>>(`${this.urlConstantsService.REPORTS_URL}/prebillReport?id=${id}`)
        .pipe(catchError(error => throwError(error)),
              map(response => response.data));
    }

    getHighPriorityDenialsReport() {
        return this.http.get<OperationResponse<GeneratedReportResponse>>(`${this.urlConstantsService.REPORTS_URL}/hpdenialsReport`)
        .pipe(catchError(error => throwError(error)),
              map(response => response.data));
    }

    getUninitiatedDenialsReport() {
        return this.http.get<OperationResponse<GeneratedReportResponse>>(`${this.urlConstantsService.REPORTS_URL}/uninitiateddenialsReport`)
        .pipe(catchError(error => throwError(error)),
              map(response => response.data));
    }

    getDailyNotesReport(facilityId: string, patientCaseId: string) {
        return this.http.get<OperationResponse<GeneratedReportResponse>>(`${this.urlConstantsService.REPORTS_URL}/dailyNotesReport?facilityId=${facilityId}&patientCaseId=${patientCaseId}`)
        .pipe(catchError(error => throwError(error)),
              map(response => response.data));
    }

    getCmrReport(facilityId: string, episodeId: string) {
        return this.http.get<OperationResponse<GeneratedReportResponse>>(`${this.urlConstantsService.REPORTS_URL}/cMRReport?facilityId=${facilityId}&episodeId=${episodeId}`)
        .pipe(catchError(error => throwError(error)),
              map(response => response.data));
    }

    getDARDashboardReport(facilityId: string, reportDate: Date) {
        reportDate = moment(reportDate).toDate();
        const day = reportDate.getDate();
        const month = (reportDate.getMonth() + 1);
        const year = reportDate.getFullYear();
        return this.http.get<OperationResponse<GeneratedReportResponse>>(`${this.urlConstantsService.REPORTS_URL}/darDashboard?facilityId=${facilityId}&day=${day}&month=${month}&year=${year}`)
        .pipe(catchError(error => throwError(error)),
              map(response => response.data));
    }

    getFunctionBuilderPlanReportById(functionBuilderId: string) {
        return this.http.get<OperationResponse<GeneratedReportResponse>>(`${this.urlConstantsService.REPORTS_URL}/functionBuilderReport?functionBuilderId=${functionBuilderId}`)
        .pipe(catchError(error => throwError(error)),
              map(response => response.data));
    }

    getRestorativeNursingProgramReportById(restorativeNursingProgramId: string): Observable<GeneratedReportResponse>  {
        return this.http.get<OperationResponse<GeneratedReportResponse>>(`${this.urlConstantsService.REPORTS_URL}/restorativeNursingProgram?restorativeNursingProgramId=${restorativeNursingProgramId}`)
        .pipe(catchError(error => throwError(error)),
              map(response => response.data));
    }

    getPreauthorizationRequestReportById(preauthRequestId: string): Observable<GeneratedReportResponse>  {
        return this.http.get<OperationResponse<GeneratedReportResponse>>(`${this.urlConstantsService.REPORTS_URL}/preauthRequest?preauthRequestId=${preauthRequestId}`)
        .pipe(catchError(error => throwError(error)),
              map(response => response.data));
    }
}