
import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { AnnouncementsService } from '@app/core/services/announcements.service';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Announcement, ePermissions } from '@app/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OnDestroyComponent } from '@app/shared';
import * as moment from 'moment';
import { modalOptions, modalFullScreenOption } from '@app/utils';
import { CacheManService } from '@app/core';

@Component({
    selector: 'tms-announcements-grid',
    templateUrl: './announcements.component.html',
    styleUrls: ['./announcements.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementsGridComponent extends OnDestroyComponent implements OnInit, OnDestroy {
    authenticatedUser: boolean = true;

    announcements: Announcement[] = [];
    filteredAnnouncements: Announcement[] = [];
    modalImageUrl: string;
    modalTitle: string;

    constructor(private announcementsService: AnnouncementsService,
        private cacheManService: CacheManService,
        private modalService: NgbModal,
        private cdr: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.getAnnouncements();
    }

    getAnnouncements() {
        this.cacheManService.getUserHasPermission$(ePermissions.Admin)
            .pipe(filter(user => user != null), takeUntil(this.onDestroy$))
            .subscribe((hasPermission) => {
                this.announcementsService.getUserAnnouncements()
                    .pipe(
                        takeUntil(this.onDestroy$))
                    .subscribe(ann => {
                        this.announcements = ann;
                        
                        if (!hasPermission) {
                            this.filteredAnnouncements = this.announcements.filter(a => !a.isAdminOnly);
                        } else {
                            this.filteredAnnouncements = this.announcements;
                        }

                        this.cdr.detectChanges();
                    });
            });
    }

    openImageModal(content, imageUrl, title) {
        this.modalImageUrl = imageUrl;
        this.modalTitle = title;
        this.modalService.open(content, { ...modalOptions, ...modalFullScreenOption })
            .result.then((result) => {
            }, (reason) => {
            });
    }

    announcementDateValueChanged(e) {
        if (e.value === null) {
            this.filteredAnnouncements = this.announcements;
        } else {
            const filtered = this.announcements.filter(a => {
                if (a.startDate && a.endDate) {
                    return moment(e.value).isBetween(a.startDate, a.endDate, 'day', '[]');
                } else if (a.startDate && !a.endDate) {
                    return moment(a.startDate).isSameOrBefore(e.value, 'day');
                }
            });
            this.filteredAnnouncements = filtered;
        }

        this.cdr.detectChanges();
    }

    ngOnDestroy() {
        super.onDestroy();
    }
}
