<dx-validation-group>
    <div class="modal-header tms-modal-header">
        <div *ngIf="title" class="page-heading-font">{{title}}</div>
        <div *ngIf="!title" class="tms-modal-title-content">
            <ng-content select="[tms-modal-title]"></ng-content>
        </div>
        <dx-button class="tms-icon-button" icon="fas fa-times" [visible]="closeButtonVisible" (onClick)="close()"
            hint="Close"></dx-button>
    </div>
    <div class="modal-body">
        <ng-content></ng-content>
    </div>
    <div class="modal-footer tms-modal-footer">
        <div class="tms-modal-footer-group">
            <dx-button *ngIf="cancelButtonVisible" type="normal" (onClick)="cancel($event)"
                [disabled]="cancelButtonDisabled" [text]="cancelButtonText">
            </dx-button>
            <dx-button *ngIf="submitButtonVisible" class="tms-primary-button tms-submit-button"
                (onClick)="submit($event)" [disabled]="submitButtonDisabled" [text]="submitButtonText"
                [validationGroup]="validationGroup"></dx-button>
        </div>
    </div>
</dx-validation-group>