import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tms-patient-treatment-concurrent-statement-edit',
  templateUrl: './patient-treatment-concurrent-statement-edit.component.html',
  styleUrls: [ './patient-treatment-concurrent-statement-edit.component.scss' ]
})
export class PatientTreatmentConcurrentStatementEditComponent implements OnInit{
    constructor(private activeModal: NgbActiveModal,
        private cdr: ChangeDetectorRef
      ) { }

    ngOnInit(): void {
        
    }
    @Input() txtImprovement: string;
    @Input() txtIntervention: string;

    onClearPressed(){
        this.txtImprovement = null;
        this.txtIntervention = null;
        this.cdr.detectChanges();
    }

    onClose() {
        this.activeModal.dismiss();
    }

    onSubmitPressed(){
        if(this.txtImprovement && this.txtIntervention){
            let array = [this.txtImprovement, this.txtIntervention]
            this.activeModal.close(array);
        }
    }
}