import { Dictionary } from '../../../interfaces';
import { TemplateObject, templateObjToStringListUtil } from '../template-object';
import { TemplateTextOptions } from '../TemplateObjectText';

export const RecertOtInterventionProperties: Dictionary<TemplateTextOptions> = {
    //OT
    ROM: {
        templatePropertyName: 'ROM',
        displayText: 'ROM',
        displayValueText: 'skilled training to promote increased functional UE ROM',
    },
    MANUAL_STRETCH: {
        templatePropertyName: 'Manualstretch',
        displayText: 'Manual stretch',
        displayValueText: 'manual stretching techniques to UE',
    },
    MAN_P_RDXN: {
        templatePropertyName: 'manprdxn',
        displayText: 'man: p! rdxn',
        displayValueText: 'manual techniques for pain reduction',
    },
    STRENTHENING: {
        templatePropertyName: 'strengthening',
        displayText: 'strengthening',
        displayValueText: 'progressive training to facilitate gains in UE muscle efficiency',
    },
    MOTOR_CONTROL_UE: {
        templatePropertyName: 'motorcontrolUE',
        displayText: 'motor control UE',
        displayValueText: 'motor control training for improved upper extremity functional control',
    },
    MOTOR_CONTROL_TRUNK: {
        templatePropertyName: 'motorcontrol-trunk',
        displayText: 'motor control-trunk',
        displayValueText: 'skilled training to improved motor control of trunk',
    },
    COORD_FM_CONTROL: {
        templatePropertyName: 'coordFMcontrol',
        displayText: 'coord FM control',
        displayValueText: 'coordination training to impact fine motor control',
    },
    COORD_FM: {
        templatePropertyName: 'coordFM',
        displayText: 'coord FM',
        displayValueText: 'coordination training with focus on fine motor skills, manipulation and dexterity',
    },
    COORD_GM_CONTROL: {
        templatePropertyName: 'coordGMcontrol',
        displayText: 'coord GM control',
        displayValueText: 'upper extremity gross motor control training',
    },
    COORD_GM: {
        templatePropertyName: 'coordGM',
        displayText: 'coord GM',
        displayValueText: 'training to improve gross limb movement',
    },
    COORD_B: {
        templatePropertyName: 'coordB',
        displayText: 'coord (B)',
        displayValueText: 'coordination training to increase (B) UE integration',
    },
    COORD_GEN: {
        templatePropertyName: 'coordgen',
        displayText: 'coord gen',
        displayValueText: 'coordination training to promote gains in both gross and fine motor control',
    },
    BAL_SIT: {
        templatePropertyName: 'balsit',
        displayText: 'bal: sit',
        displayValueText: 'functional sitting balance training',
    },
    TECH_TASK_PERF: {
        templatePropertyName: 'techtaskperf',
        displayText: 'tech task perf',
        displayValueText: 'training to facilitate increased accuracy with functional task performance',
    },
    BAL_DYN_STAND: {
        templatePropertyName: 'bal-dynstand',
        displayText: 'bal-dyn stand',
        displayValueText: 'skilled intervention targeting improvement of dynamic standing balance',
    },
    TECH_HEMI: {
        templatePropertyName: 'tech-hemi',
        displayText: 'tech- hemi',
        displayValueText: 'mastery of hemi techniques',
    },
    TECH_THR: {
        templatePropertyName: 'tech-THR',
        displayText: 'tech- THR',
        displayValueText: 'training to increase mastery of THR techniques',
    },
    NEURO_SENSORY_AWARNESS: {
        templatePropertyName: 'neuro-sensoryawareness',
        displayText: 'neuro- sensory awareness',
        displayValueText: 'neuro reeducation to increase sensory awareness',
    },
    NEURO_POSTURE: {
        templatePropertyName: 'neruo-posture',
        displayText: 'neruo- posture',
        displayValueText: 'neuro muscular reeducation to promote increased postural control',
    },
    NEURO_QUALITY_MVMT: {
        templatePropertyName: 'neuro-qualitymvmt',
        displayText: 'neuro- quality mvmt',
        displayValueText: 'neuromuscular reeducation to improve quality of UE movement',
    },
    TECH_ITEM_MANIP: {
        templatePropertyName: 'tech-itemmanip',
        displayText: 'tech-item manip',
        displayValueText: 'proper technique for manipulation of items utilized during ADLs',
    },
    AE_USE: {
        templatePropertyName: 'AEuse',
        displayText: 'AE use',
        displayValueText: 'instruction in proper use of adaptive equipment',
    },
    SAFETY_HOME: {
        templatePropertyName: 'safety-home',
        displayText: 'safety-home',
        displayValueText: 'education in proper safety for home environment',
    },
    SAFETY_GEN: {
        templatePropertyName: 'safety-gen',
        displayText: 'safety-gen',
        displayValueText: 'skilled instruction to improve safety awareness',
    },
    TFER_TECH: {
        templatePropertyName: 'tfertech',
        displayText: 'tfer tech',
        displayValueText: 'transfer training with focus on proper technique',
    },
    TFER_TOILET: {
        templatePropertyName: 'tfer-toilet',
        displayText: 'tfer-toilet',
        displayValueText: 'training in performance of toilet transfers',
    },
    TFER_BATHING: {
        templatePropertyName: 'tfer-bathing',
        displayText: 'tfer-bathing',
        displayValueText: 'technique for execution of safe transfers for bathing',
    },
    ENRG_CON: {
        templatePropertyName: 'EnrgCon',
        displayText: 'Enrg Con',
        displayValueText: 'instruction in proper method of utilization of energy conservation techniques',
    },
    WORK_SIMP: {
        templatePropertyName: 'worksimp',
        displayText: 'work simp',
        displayValueText: 'instruction in work simplification techniques and emphasis on task analysis for safety',
    },
    BM_BRIDGING: {
        templatePropertyName: 'BM-bridging',
        displayText: 'BM-bridging',
        displayValueText: 'training in proper method of execution of bridging',
    },
    BM_ROLLING: {
        templatePropertyName: 'BM-rolling',
        displayText: 'BM-bridging',
        displayValueText: 'sequencing of execution of rolling',
    },
    POSITION_BED: {
        templatePropertyName: 'position-bed',
        displayText: 'position- bed',
        displayValueText: 'Training/instruction for proper positioning in bed',
    },
    POSITION_WC: {
        templatePropertyName: 'position-wc',
        displayText: 'position- w/c',
        displayValueText: 'positional training for increased accuracy in wheelchair',
    },
    SENSORY: {
        templatePropertyName: 'sensory',
        displayText: 'sensory',
        displayValueText: 'stimulation to sensory system impacting environmental awareness',
    },
    HOME_MGMGT: {
        templatePropertyName: 'homemgmgt',
        displayText: 'home mgmgt',
        displayValueText: 'home management instruction/training',
    },
    SKILL_MAINT_CONT_ST: {
        templatePropertyName: "skilledMaintenanceContinued",
        displayText: 'Skilled maintenance - continued',
        displayValueText: 'Patient to continue skilled therapy for skilled maintenance services to assist the patient in maintaining current function or to prevent or slow further deterioration due to disease or illness. The patient\'s clinical condition and complexity of required services is such that services can only be performed safely and effectively by skilled therapist or assistant.'
    },
    SKILL_MAINT_NEW_ST: {
        templatePropertyName: "skilledMaintenanceNew",
        displayText: 'Skilled maintenance - new',
        displayValueText: 'Patient plan of care transitioning to focus on skilled maintenance services to assist the patient in maintaining current function or to prevent or slow further deterioration due to disease or illness. The patient\'s clinical condition and complexity of required services is such that services can only be performed safely and effectively by skilled therapist or assistant.'
    }
}

export const RecertInterventionProperties = {
    // PT
    FACILITORY: 'facilitory',
    INHIB: 'inhib',
    MAN_ROM: 'manRom',
    MAN_P: 'manP',
    P_ED: 'ped',
    P_COMP_START: 'pcomstrat',
    ROM_LE: 'romLE',
    ROM_TRUNK: 'romTrunk',
    MOTOR_CONTROL_LE: 'motorControlTrunk',
    COMP_STRATS: 'compStrats',
    AD_TECH: 'adTech',
    POSTURE: 'posture',
    BALANCE: 'balance',
    SEQUENCING: 'sequencing',
    COORD_FT_PLACEMENT: 'coordFtPlacement',
    COORD_INTERPLAY: 'coordInterplay',
    NEURO_COORD: 'neuroCoord',
    EFFICIENCY_TASK: 'efficiencyTask',
    ENDURANCE: 'endurance',
    SAFETY: 'safety',
    // ST
    SWALLOW_ED: 'swallow-ed',
    SWALLOW_COMP: 'swallowCompStratTraining',
    SWALLOW_HARD: 'swallow-hard',
    STAFF: 'staffFamilyEd',
    CONT_DIET_ASSESS: 'ContDietAssessment',
    OME_GEN: 'ome-gen',
    OME_LING: 'ome-ling',
    OME_LABIAL: 'ome-labial',
    THERMAL_STIM: 'thermalStim',
    SENSATION: 'sensation',
    DPNS: 'DPNS',
    ICING: 'Icing',
    NMES_VITAL: 'NMES-VitalStim',
    VERBAL_ORG_TASK: 'VerbalOrgTask',
    SKILL_MAINT_CONT: 'skillMaintContinued',
    SKILL_MAINT_NEW: 'skillMaintNew',
    NAMING: 'Naming-part-whole',
    QA_SIMPLE: 'QA-simple',
	QA_COMPLEX: 'QA-complex',
    VERBAL_EXP: 'VerbalExp',
    WRITING: 'Writing',
    SPEECH_INTEL_TECH: 'Speech-intel-tech',
    SPEECH_INTEL_DYS: 'Speech-intel-dysarthria',
    FOLLOW_COMMANDS: 'FollowCommands',
    PROBLEM_SOLVING: 'ProblemSolving',
    REASONING: 'Reasoning',
    ALT_DEVICE: 'AlternateDevice',
    COGNTIVE: 'Cognitive',
    STM: 'STM',
    MEMORY_BOOK: 'MemoryBook',
    MEMORY_STRATS: 'MemoryStrats', 
    ORIENTATION: 'Orientation',
}

export const RECERT_INTERVENTIONS_TEMPLATE: string = `Patient continues to require skilled interventions including: {{#if stringList}}{{#each stringList}}{{{this}}}{{#unless @last}},{{/unless}}{{/each}}{{/if}}`;

export class RecertInterventionsTemplate implements TemplateObject {
    stringList: string[] = [];

    private otList = Object.values(RecertOtInterventionProperties).map(value => value.templatePropertyName);
    private toList = this.otList.concat(Object.values(RecertInterventionProperties));

    preCompileTemplateObj(): void {
        this.stringList = templateObjToStringListUtil(this, this.toList);
    }
}