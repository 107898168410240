import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { PatientsService } from './patients.service';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { Case, PagedResult, OperationResponse, CaseEarlyDocument } from '@app/model';

@Injectable({
	providedIn: 'root',
})
export class CasesService {
    constructor(private http: HttpClient,
        private patientsSerivce: PatientsService, 
        private urlConstantsService: UrlConstantsService) {}

    createCase(episodeId: string, patientCase: Case): Observable<Case> {
        return this.http.post<OperationResponse<Case>>(`${this.urlConstantsService.EPISODES_URL}/${episodeId}/cases`, patientCase)
            .pipe(tap(response => this.patientsSerivce.createPatientCareEpisodeCase(response.data.patientId, response)),
                  map(response => response.data));
    }

    updateCase(episodeId: string, patientCase: Case): Observable<Case> {
        return this.http.put<OperationResponse<Case>>(`${this.urlConstantsService.EPISODES_URL}/${episodeId}/cases/${patientCase.id}`, patientCase)
            .pipe(tap(response => this.patientsSerivce.updatePatientCaseEpisodeCase(response.data.patientId, response)),
                  map(response => response.data));
    }

    getByEpisodeId(episodeId: string, patientId: string): Observable<Case[]> {
        return this.http.get<PagedResult<Case>>(`${this.urlConstantsService.EPISODES_URL}/${episodeId}/cases?patientId=${patientId}`)
        .pipe(map(casesPagedResult => casesPagedResult.items));
    }

    deleteCase(episodeId: string, caseId: string) {
        return this.http.delete<OperationResponse<Case>>(`${this.urlConstantsService.EPISODES_URL}/${episodeId}/cases/delete/${caseId}`);
    }

    getById(episodeId: string, caseId: string) {
        return this.http.get<Case>(`${this.urlConstantsService.EPISODES_URL}/${episodeId}/cases/${caseId}`);
    }

    getEarlyDocument(caseId: string) {
        return this.http.get<OperationResponse<CaseEarlyDocument>>(`${this.urlConstantsService.CASES_URL}/${caseId}/earlydocument`)
                .pipe(map(response => response.data));
    }

    getEarliestDcDate(caseId: string) {
        return this.http.get<OperationResponse<Date>>(`${this.urlConstantsService.CASES_URL}/${caseId}/dcdate`)
                .pipe(map(response => response.data));
    }

    getStartOfCareDate(caseId: string) {
        return this.http.get<OperationResponse<Date>>(`${this.urlConstantsService.CASES_URL}/${caseId}/startofcaredate`)
                .pipe(map(response => response.data));
    }

    getByPatientId(patientId: string): Observable<Case[]> {
        return this.http.get<PagedResult<Case>>(`${this.urlConstantsService.CASES_URL}?patientId=${patientId}`)
            .pipe(map(response => response.items));
    }
}
