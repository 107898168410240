import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap, finalize } from 'rxjs/operators';
import { UrlConstantsService } from './url-constants.service';
import { noLoadingSpinner } from '../interceptors';

@Injectable({
  providedIn: 'root'
})
export class IgniteStatusService {
  private _igniteVersion: string = null;
  private _igniteVersion$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private http: HttpClient, private urlConstants: UrlConstantsService) { }

  get igniteVersion(): string {
    return this._igniteVersion;
  }

  set igniteVersion(value) {
    this._igniteVersion = value;
    this._igniteVersion$.next(this._igniteVersion);
  }

  get igniteVersion$(): Observable<string> {
    return this._igniteVersion$.asObservable();
  }

  getIgniteAppVersion(): Observable<string> {
    // set context this way because importing from ../interceptors breaks unit test for some reason
    const context = new HttpContext().set(new HttpContextToken<boolean>(() => true), false)
    return this.http.get(this.urlConstants.STATUS_URL, { context: context }).pipe(map((igniteStatus: any) => igniteStatus.Version), tap(version => this.igniteVersion = version));
  }
}
