import { ChangeDetectorRef, Component, Input, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { DxValidationGroupComponent } from 'devextreme-angular';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InterviewGroup, InterviewAnswer, InterviewQuestion, Dictionary, ReportItem, ReportRequest} from '@app/model';
import { ErrorMessageUtils, modalLargeOption, modalOptions } from '@app/utils';
import { ReportsService } from '@app/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TmsWarningMessageModalComponent } from '..';

@Component({
  selector: 'tms-report-generation-modal',
  templateUrl: './report-generation-modal.component.html',
  styleUrls: ['./report-generation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportGenerationModalComponent implements OnInit {
  @Input() facilityId: string = null;
  @Input() reportShortName: string = null;
  @ViewChild(DxValidationGroupComponent) validationGroup: DxValidationGroupComponent;

  title: string;
  interviewGroupQuestionsCopy: InterviewGroup[] = [];
  reportItemCopy: ReportItem = null;

  private reportGenerationAnswers: Dictionary<InterviewAnswer> = {};

  constructor(private activeModalService: NgbActiveModal,
              private cdr: ChangeDetectorRef,
              private modalService: NgbModal,
              private reportsService: ReportsService) { }

  ngOnInit() {
    this.reportsService.getReportItem(this.facilityId, this.reportShortName)
      .subscribe(reportItemResponse => {
        this.reportItemCopy = reportItemResponse;
        // get existing answers and add to answers dictionary
        this.reportItemCopy.interviewQuestionGroups.forEach(group => {
          group.questions.forEach(question => {
            if (!!question.answer.value) {
              this.reportGenerationAnswers[question.shortName] = question.answer;
            }
          });
        })
        this.title = `Generate Report: ${reportItemResponse.name}`;
        this.interviewGroupQuestionsCopy = reportItemResponse.interviewQuestionGroups;
        this.cdr.detectChanges();
      }, () => {
        const warningModalRef = this.modalService.open(TmsWarningMessageModalComponent, { ...modalOptions, ...modalLargeOption});
        warningModalRef.componentInstance.title = "Error Generating Report";
        warningModalRef.componentInstance.message = `Selected report could not be found.`;
      });
    
  }

  onAnswerChanged(e: InterviewAnswer, question: InterviewQuestion) {
    this.reportGenerationAnswers[e.shortName] = e;

    if (!!question.isPutOnChange) {
      const reportRequest: ReportRequest = {
        answers: Object.values(this.reportGenerationAnswers),
        facilityId: this.facilityId,
        shortName: this.reportShortName,
      };
      this.reportsService.sendReportItemInput(this.facilityId, this.reportShortName, reportRequest)
        .subscribe(reportItemResponse => {
          this.interviewGroupQuestionsCopy = reportItemResponse.interviewQuestionGroups;
          this.cdr.detectChanges();
        });
    }
  }

  onSubmit() {
    const answers: InterviewAnswer[] = Object.values(this.reportGenerationAnswers);
    this.reportsService.generateReport({
      facilityId: this.facilityId,
      shortName: this.reportShortName,
      answers: answers,
    }).subscribe(response => {
      this.activeModalService.close(response);
    }, (error: HttpErrorResponse) => {
      let warningMsg = ErrorMessageUtils.getDisplayErrorMessage(error, 'Unable to generate report.');
      const warningModalRef = this.modalService.open(TmsWarningMessageModalComponent, { ...modalOptions, ...modalLargeOption});
      warningModalRef.componentInstance.title = "Error Generating Report";
      warningModalRef.componentInstance.message = warningMsg;
    });
  }

  onCancel() {
    this.activeModalService.dismiss();
  }

  onClose() {
    this.activeModalService.dismiss();
  }
}
