import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { DataListItem, InterviewQuestion, InterviewAnswerField } from '@app/model';
import { CacheManService, DataListConstants } from '@app/core';

@Component({
    selector: 'tms-question-yes-no',
    styleUrls: [],
    template: `
    <dx-select-box displayExpr="text" valueExpr="value" 
              [id]="id"
              [dataSource]="dataListItems"
              [value]="interviewAnswerField.value"
              [disabled]="disabled"
              (onValueChanged)="onSelectedValue($event)">
              <dx-validator>
                <dxi-validation-rule type="custom" message="Value is required" [validationCallback]="onValidationCallback"></dxi-validation-rule>
              </dx-validator>
    </dx-select-box>
  `,
})
export class InterviewQuestionYesNoComponent implements OnInit {
    @Input() question: InterviewQuestion;
    @Input() interviewAnswerField: InterviewAnswerField;
    @Input() id: string;
    @Input() disabled: boolean = false;
    @Output() onInterviewValueChanged: EventEmitter<string> = new EventEmitter<string>();
  
    dataListItems: DataListItem[] = [];
    dataListItems$: Observable<DataListItem[]> = null;
  
    constructor(private cacheManService: CacheManService) { }
  
    ngOnInit() {
      this.cacheManService.getDataListItems$(DataListConstants.YES_NO)
        .pipe(take(1))
      . subscribe(datalist => {
          this.dataListItems = datalist;
        });
  
      if (this.question.customAnswerOptions) {
        this.dataListItems = [...this.dataListItems, ...this.question.customAnswerOptions];
      }
    }
  
    onSelectedValue($event) {
      this.onInterviewValueChanged.emit($event.value);
    }
  
    onValidationCallback = (options) => {
      if (this.question.validationOptions && this.question.validationOptions.isRequired) {
        return options.value != null;
      } else {
        return true;
      }
    }
}
