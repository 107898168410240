import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppConfigService } from '../../app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
  private appInsights: ApplicationInsights = null;

  constructor(private appConfigService: AppConfigService) {
    if (this.appConfigService.appConfig.appInsightsKey) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: this.appConfigService.appConfig.appInsightsKey,
        }
      })
      this.appInsights.loadAppInsights();
    }
  }

  setUserContext(userId: string) {
    if (this.appConfigService.appConfig.appInsightsKey) {
      this.appInsights.setAuthenticatedUserContext(userId, null, true);
    }
  }

  logPageView(
    name?: string,
    url?: string,
    properties?: any,
    measurements?: any) {
      if (this.appInsights) {
        this.appInsights.trackPageView( { name: name, uri: url, properties: properties, measurements: measurements });
      }
  }

  logEvent(name: string, properties?: any, measurements?: any) {
    if (this.appInsights) {
      this.appInsights.trackEvent({ name: name, properties: properties, measurements: measurements });
    }
  }

  logException(
    exception: Error,
    properties?: any,
    measurements?: any
  ) {
    if (this.appInsights) {
      this.appInsights.trackException({ exception: exception, properties: properties, measurements: measurements});
    }
  }

  logTrace(message: string, properties?: any, severityLevel?: any) {
    if (this.appInsights) {
      this.appInsights.trackTrace({ message: message, properties: properties, severityLevel: severityLevel });
    }
  }

}
