
import { Component, ChangeDetectorRef,  ChangeDetectionStrategy, OnDestroy, OnInit } from '@angular/core';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { CacheManService, UsersService } from '@app/core';
import { OnDestroyComponent } from '@app/shared';
import * as moment from 'moment';

interface ActionItemDisplay {
    item?: string;
    dueDate?: Date;
    noDueDate?: boolean;
    isPastDue?: boolean;
    pastDueDays?: number;
    isDueToday?: boolean;
    isDueFuture?: boolean;
    futureDueDays?: number;
}

@Component({
    selector: 'tms-activity-feed',
    templateUrl: './activity-feed.component.html',
    styleUrls: ['./activity-feed.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityFeedComponent extends OnDestroyComponent implements OnInit, OnDestroy {

    feedItems: ActionItemDisplay[] = [];

    constructor(private cacheManService: CacheManService,
        private cdr: ChangeDetectorRef,
        private usersService: UsersService) {
        super();
    }

    ngOnInit() {
        this.cacheManService.getCurrentUser()
            .pipe(takeUntil(this.onDestroy$),
                  filter(user => user != null),
                  switchMap(user => {
                      return this.usersService.getUserActivityFeed(user.id);
                  }))
            .subscribe(responseActionItems => {
                this.feedItems = [];
                responseActionItems.forEach(item => {
                    const actionItemDisplay: ActionItemDisplay = {
                        item: item.actionText,
                        dueDate: item.dueDate,
                    };

                    if (!item.dueDate) {
                        actionItemDisplay.noDueDate = true;
                    } else {
                        actionItemDisplay.noDueDate = false;
                        const dueDate = moment(item.dueDate);
                        const currentDate = moment(new Date());
    
                        if (dueDate.isSame(currentDate, 'day')) {
                            actionItemDisplay.isDueToday = true;
                        } else if (dueDate.isBefore(currentDate, 'day')) {
                            actionItemDisplay.isPastDue = true;
                            actionItemDisplay.pastDueDays = currentDate.diff(dueDate, 'day');
                        } else {
                            actionItemDisplay.isDueFuture = true;
                            actionItemDisplay.futureDueDays = dueDate.diff(currentDate, 'day') + 1;
                        }
                    }
                
                    this.feedItems.push(actionItemDisplay);
                });
                this.cdr.detectChanges();
            });   
    }

    ngOnDestroy() {
        super.onDestroy();
    }
}