import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { IcdCode } from '@app/model/clinical/IcdCode';
import { IcdCodeStatus } from '@app/model/clinical/IcdCodeStatus';
import { OperationResponse, IcdCodeAllowableType } from '@app/model';

@Injectable({
	providedIn: 'root',
})
export class IcdCodesService {
    constructor(private httpClient: HttpClient,
        private urlConstantsService: UrlConstantsService) {
    }

    getAllIcdCodes(dateConstraint?: Date, isProceduralAllowed: boolean = false, filterByAllowableType: IcdCodeAllowableType = IcdCodeAllowableType.BothMedicalAndTreatment) {
        
        let day: number = dateConstraint?.getDate();
        let month: number = (dateConstraint?.getMonth() ?? -1) + 1;
        let year: number = dateConstraint?.getFullYear();

        return this.httpClient.get<OperationResponse<IcdCode[]>>(`${this.urlConstantsService.ICDCODES_URL}?month=${month}&day=${day}&year=${year}&isProceduralAllowed=${isProceduralAllowed}&filterByAllowableType=${filterByAllowableType}`)
            .pipe(map(response => response.data));
    }

    searchIcdCodes(searchText: string, dateConstraint?: Date, isProceduralAllowed: boolean = false, filterByAllowableType: IcdCodeAllowableType = IcdCodeAllowableType.BothMedicalAndTreatment, isAdminPage: boolean = false) {
        let day: number = dateConstraint?.getDate();
        let month: number = (dateConstraint?.getMonth() ?? -1) + 1;
        let year: number = dateConstraint?.getFullYear();
        return this.httpClient.get<OperationResponse<IcdCode[]>>(`${this.urlConstantsService.ICDCODES_URL}/search?searchText=${searchText}&day=${day}&month=${month}&year=${year}&isProceduralAllowed=${isProceduralAllowed}&filterByAllowableType=${filterByAllowableType}&isAdminPage=${isAdminPage}`)
            .pipe(map(response => response.data));
    }

    getById(id: string) {
        return this.httpClient.get<OperationResponse<IcdCode>>(`${this.urlConstantsService.ICDCODES_URL}/${id}`)
            .pipe(map(response => response.data));
    }

    getByCode(code: string, dateConstraint: Date) {
        let day: number = dateConstraint.getDate();
        let month: number = (dateConstraint.getMonth() + 1);
        let year: number = dateConstraint.getFullYear();
        return this.httpClient.get<OperationResponse<IcdCode>>(`${this.urlConstantsService.ICDCODES_URL}/code?code=${code}&day=${day}&month=${month}&year=${year}`)
            .pipe(map(response => response.data));
    }

    getIcdCodeStatuses() {
        
        return this.httpClient.get<OperationResponse<IcdCodeStatus[]>>(`${this.urlConstantsService.ICDCODES_URL}/statuses`)
            .pipe(map(response => response.data));
    }

    getIcdCodeProceduralTypes() {
        
        return this.httpClient.get<OperationResponse<IcdCodeStatus[]>>(`${this.urlConstantsService.ICDCODES_URL}/proceduraltypes`)
            .pipe(map(response => response.data));
    }
    getIcdCodeValidityTypes() {
        return this.httpClient.get<OperationResponse<IcdCodeStatus[]>>(`${this.urlConstantsService.ICDCODES_URL}/validitytypes`)
            .pipe(map(response => response.data));
    }

    addIcdCode(request: IcdCode) {
        return this.httpClient.post<OperationResponse<IcdCode>>(`${this.urlConstantsService.ICDCODES_URL}`, request)
            .pipe(map(response => response.data));
    }

    updateIcdCode(request: IcdCode) {
        return this.httpClient.put<OperationResponse<IcdCode>>(`${this.urlConstantsService.ICDCODES_URL}`, request)
            .pipe(map(response => response.data));
    }
}
