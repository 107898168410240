export class DataListConstants {
    static ADJUSTMENT_TYPE: string = 'AdjustmentType';
    static ADVERSE_EVENT_TYPE: string = 'AdverseEventType';
    static ASSESSMENT_TYPE: string = 'AssessmentType';
    static ASSIST_SCALE_SCORES: string = 'AssistScaleScores';
    static CHARGE_CODE_TYPE: string = 'ChargeCodeType';
    static CHARGE_RUNNERS: string = 'ChargeRunnersDataList';
    static CLIENT_PAYMENT_TERM: string = 'ClientPaymentTerm';
    static CONTRACT_PAYER_AGREEMENT_TYPE: string = 'ContractPayerAgreementType';
    static COUNTRIES: string = 'Countries';
    static DAY_OF_MONTH: string = 'DayOfMonth';
    static DAY_OF_WEEK: string = 'DayOfWeek';
    static DENIAL_CLAIM_PACKET_SECTION: string = 'DenialClaimPacketSectionDataList';
    static DENIAL_CLAIM_STAGE_DECISION: string = 'DenialClaimStageDecision';
    static DENIAL_CLAIM_STAGE_NAMES: string = 'DenialClaimStageNames';
    static DENIAL_CLAIM_STAGE_REASON: string = 'DenialClaimStageReason';
    static DENIAL_CLAIM_TYPE: string = 'DenialClaimType';
    static DENIAL_ENTITY: string = 'DenialEntity';
    static DENIAL_NOTICE_TYPE: string  ='DenialNoticeType';
    static DENIAL_REASON: string = 'DenialReason';
    static DENIAL_REPORT_COLUMN: string = 'DenialReportColumn';
    static DESIGNATIONS: string = 'Designations';
    static DIET_TEXTURES: string = 'DietTextures';
    static DISCHARGE_DESTINATION: string = 'DischargeDestination';
    static DISCHARGE_DESTINATION_EPISODE: string = 'DischargeDestinationEpisode';
    static DISCHARGE_DISPOSITION: string = 'DischargeDisposition';
    static DISCIPLINES: string = 'Disciplines';
    static EMPLOYEE_ROLES: string = 'EmployeeRoles';
    static EMPLOYEE_STATUS_TYPE: string = 'EmployeeStatusType';
    static EVALUTATION_PLOF_ADAPTIVE_EQUIPMENT: string = 'EvaluationPlofAdaptiveEquipment';
    static EVALUATION_PLOF_ASSISTANCE: string = 'EvaluationPlofAssistance';
    static EVALUATION_PLOF_ASSISTIVE_DEVICE: string = 'EvaluationPlofAssistiveDevice';
    static EVALUATION_PLOF_CARE_LEVEL: string = 'EvaluationPlofCareLevel';
    static EVALUATION_PLOF_DIET: string = 'EvaluationPlofDiet';
    static EVALUATION_PLOF_HOME_OPTIONS: string = 'EvaluationPlofHomeOptions';
    static EVALUATION_PLOF_IMPAIRMENT: string =  'EvaluationPlofImpairment';
    static EVALUATION_PLOF_LIQUID: string = 'EvaluationPlofLiquid';
    static EVALUATION_PLOF_SETTING: string = 'EvaluationPlofSetting';
    static EXPORT_DATE_TIME_FORMAT: string = 'ExportDateTimeFormat';
    static EXPORT_FIELD_CATEGORIES: string = 'ExportFieldCategories';
    static EXPORT_FILE_DELIVERY_TYPES: string =  'ExportFileDeliveryType';
    static EXPORT_FILE_TYPES: string = 'ExportFileTypes';
    static EXPORT_MODIFIER_TYPES: string = 'ExportModifierTypes';
    static EXPORT_SELECT_DAY_OF_MONTH_BY: string = 'ExportSelectDayOfMonthBy';
    static EXPORT_SELECT_DAY_BY: string = 'ExportSelectDayBy';
    static EXPORT_PERIOD_OF_TIME: string = 'ExportPeriodOfTime';
    static FACILITY_CATEGORY: string = 'FacilityCategory';
    static FACILITY_CONTRACT_TYPE: string = 'FacilityContractType';
    static FACILITY_TYPE: string = 'FacilityType';
    static FEATURE_NAMES: string = 'FeatureNames';
    static FOLLOWUP_CARE_RECOMMENDED: string = 'FollowupCareRecommended';
    static FUNCTION_BUILDER_NON_TREATMENT_CATEGORY: string = 'FunctionBuilderNonTreatmentCategory';
    static GENDER: string = 'Gender';
    static GOAL_RESOLUTION: string = 'GoalResolution';
    static ICD_CODE_UPDATE_REASON: string = 'IcdCodeUpdateReason';
    static INTEGRATION_DIRECTION: string = 'IntegrationDirection';
    static INTEGRATION_PROVIDER_TYPE: string = 'IntegrationProviderType';
    static INTEGRATION_TYPE: string = 'IntegrationType';
    static INTERMEDIARY_LIMIT_TYPE: string = 'IntermediaryLimitType';
    static INTERVENTION_AUDIENCE: string = 'InterventionAudience';
    static INTERVIEW_TYPE: string = 'InterviewType';
    static INVOICE_ADJUSTMENT_STATUS: string = 'InvoiceAdjustmentStatus';
    static INVOICE_ADJUSTMENT_TYPE_PATIENT: string = 'InvoiceAdjustmentTypePatient';
    static INVOICE_ADJUDSTMENT_TYPE_NON_PATIENT: string = 'InvoiceAdjustmentTypeNonPatient';
    static LEVEL_OF_CARE: string = 'LevelOfCare';
    static MISSED_VISIT_TYPE: string = 'MissedVisitType';
    static MOD59_SPECIFIER: string = 'Mod59Specifier';
    static MONTHS: string = 'Months';
    static NONTREATMENT_CATEGORY: string = 'NonTreatmentCategory';
    static NURSING_NONTREATMENT_CATEGORY: string = 'NursingNonTreatmentCategory';
    static OCCUPATIONAL_THERAPY_IMPACT_STATEMENT: string = 'OccupationalTherapyImpactStatement';
    static PATIENT_CLINICAL_DOCUMENT_TYPE: string = 'PatientClinicalDocumentType';
    static PATIENT_CLINICAL_PRESENTATION: string = 'PatientClinicalPresentation';
    static PATIENT_COGNITIVE_LEVEL: string = 'PatientCognitiveLevel';
    static PATIENT_COMPLEXITY_DECISION: string = 'PatientComplexityOfDecision';
    static PATIENT_RISK_TYPES: string = 'PatientRiskType';
    static PATIENT_SETTING_TYPE: string = 'PatientSettingType';
    static PERCENTAGES: string = 'Percentages';
    static PERMISSIONS: string = 'Permissions';
    static PHYSICAL_THERAPY_IMPACT_STATEMENT: string = 'PhysicalTherapyImpactStatement';
    static PDPM_PT_OT_CATEGORIES: string = 'PdpmPtOtCategory';
    static PDPM_ST_CATEGORIES: string = 'PdpmStCategory';
    static PDPM_NURSING_CATEGORIES: string = 'PdpmNursingCategory';
    static PDPM_NTA_CATEGORIES: string = 'PdpmNtaCategory';
    static PREAUTHORIZATION_REQUEST_STATUS = 'PreauthorizationRequestStatus';
    static PREAUTHORIZATION_REQUEST_DOCUMENT_STATUS = 'PreauthorizationRequestDocumentStatus';
    static PREAUTHORIZATION_REQUEST_AUTHORIZED_BY = 'PreauthorizationRequestAuthorizedBy';
    static PREMIER_CODING_STATUS_FILTER = 'PremierCodingStatusFilter';
    static RUG_LEVELS: string = 'RugLevels';
    static PRIOR_SETTINGS: string = 'PriorSettingAnswers';
    static PHYSIOLOGICAL_MODULES: string = 'PhysiologicalModules';
    static QUALITY_OF_PERFORMANCE: string = 'QualityOfPerformance';
    static REFERRAL_SOURCE: string = 'ReferralSource';
    static REPORT_SCHEDULE_TARGET_MODE: string = 'ReportScheduleTargetMode';
    static RESTORATIVE_NURSING_WALKING_BED: string = 'RestorativeNursingWalkingBed';
    static RESTORATIVE_NURSING_ROM: string = 'RestorativeNursingROM';
    static RESTORATIVE_NURSING_TRANSFER: string = 'RestorativeNursingTransfer';
    static RESTORATIVE_NURSING_SPLINTING_BRACING: string = 'RestorativeNursingSplintingBracing';
    static RESTORATIVE_NURSING_BOWEL_BLADDER: string = 'RestorativeNursingBowelBladder';
    static RESTORATIVE_NURSING_AMPUTATION_PROSTHESIS: string = 'RestorativeNursingAmputationProsthesis';
    static RESTORATIVE_NURSING_EATING_SWALLOWING: string = 'RestorativeNursingEatingSwallowing';
    static RESTORATIVE_NURSING_DRESSING_GROOMING: string = 'RestorativeNursingDressingGrooming';
    static RESTORATIVE_NURSING_COMMUNICATION: string = 'RestorativeNursingCommunication';
    static SCREEN_CATEGORY: string = 'ScreenCategory';
    static SENSITIVITY: string = 'Sensitivity';
    static SPEECH_THERAPY_IMPACT_STATEMENT: string = 'SpeechTherapyImpactStatement';
    static SUPPORT_ISSUE_CATEGORY: string = 'SupportIssueCategory';
    static SUPPORT_ISSUE_URGENT: string = 'SupportIssueUrgent';
    static SUPPORT_ISSUE_DEPARTMENT: string = 'SupportIssueDepartment';
    static SUPPORT_ISSUE_STATUS: string = 'SupportIssueStatus';
    static SUPPORT_ISSUE_TYPE: string = 'SupportIssueType';
    static SWALLOW_LIQUID_CONSISTENCY_V2: string = 'SwallowLiquidConsistencyV2';
    static DISCHARGEMETLEVEL: string = "DischargeMetLevel";
    static TIME_ZONES: string = 'TimeZones';
    static TREATMENT_TIME_ASSESSMENT: string = 'TreatmentTimeAssessment';
    static STATES: string = 'States';
    static YES_NO: string = 'YesNo';
    static YES_NO_NOT_APPLICABLE = 'YesNoNotApplicable';
    static ZERO_TO_GREATER_THAN_FIVE: string = 'ZeroToGreaterThanFive';
}