import { DataListConstants } from "@app/core";

export class TmsDateBoxService {
    public static AssertDateOn(dateContainer: any, timezoneAgnostic: boolean): void {
        if (dateContainer && timezoneAgnostic && Date.parse(dateContainer.value)) {
            let date: Date = new Date(dateContainer.value);
            this.SetDateOnlyStringOn(dateContainer, date);
        }
    }

    private static SetDateOnlyStringOn(dateContainer: any, date: Date): void {
        if (date) {
            dateContainer.value = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
        }
    }
}