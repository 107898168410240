import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'tms-warning-modal',
  templateUrl: './tms-warning-modal.component.html',
  styleUrls: ['./tms-warning-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TmsWarningModalComponent implements OnInit {
  @Input() cancelButtonVisible: boolean = true;
  @Input() actionButtonVisible: boolean = true;
  @Input() cancelText: string = 'Cancel';
  @Input() actionText: string = 'Save';
  @Input() showWarningIcon: boolean = true;
  @Input() showDeleteWarningIcon: boolean = false;
  @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() onTakeAction: EventEmitter<void> = new EventEmitter<void>();
  constructor() { }

  ngOnInit() {
  }

  cancel() {
    this.onCancel.emit();
  }

  takeAction() {
    this.onTakeAction.emit();
  }
 
}
