import { Component, EventEmitter, Input, OnChanges, SimpleChanges, OnInit, Output, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { groupBy, uniqBy } from 'lodash';
import { Dictionary } from '@app/model';
import { SortUtils } from '@app/utils';

interface DocumentBaseItem {
  id: string;
  name: string;
  shortName: string;
  categoryName: string;
  categoryShortName: string;
  description?: string;
  isDeleted: boolean;
  isActive: boolean;
}

@Component({
  selector: 'tms-document-dashboard',
  templateUrl: './tms-document-dashboard.component.html',
  styleUrls: ['./tms-document-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TmsDocumentDashboardComponent implements OnInit, OnChanges {
  @Input() canEditItems: boolean = false;
  @Input() documentItems: DocumentBaseItem[] = [];
  @Input() filterByProp: string = null;
  @Input() groupBy: string = null;
  @Input() searchBy: string[] = null;
  @Input() showDescription: boolean = false;
  @Output() onDocumentDelete: EventEmitter<DocumentBaseItem> = new EventEmitter<DocumentBaseItem>();
  @Output() onDocumentEdit: EventEmitter<DocumentBaseItem> = new EventEmitter<DocumentBaseItem>();
  @Output() onDocumentValueChanged: EventEmitter<DocumentBaseItem> = new EventEmitter<DocumentBaseItem>();

  groupByName: string = 'categoryShortName';
  groupedItems: Dictionary<DocumentBaseItem[]> = null;
  keys: string[];
  searchKey: string = '';

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if (!this.searchBy) {
      this.searchBy = ['name'];
    }
    if (!this.filterByProp) {
      this.filterByProp = 'id';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['groupBy']?.firstChange) {
      this.groupByName = this.groupBy;
    }
    if (changes['documentItems']) {
      this.groupItems(this.documentItems, this.groupByName);
    }
  }

  onEditDocDashboardItem(e: DocumentBaseItem) {
    this.onDocumentEdit.emit(e);
  }

  onDeleteDocDashboardItem(e: DocumentBaseItem) {
    this.onDocumentDelete.emit(e);
  }

  searchValueChanged(e) {
    let filterItems: DocumentBaseItem[] = [];

    if (this.searchKey === '') {
      filterItems = this.documentItems;
    } else if (e.value && e.value.length >= 1) {
      this.searchBy.forEach(searchByKey => {
        filterItems = filterItems.concat(this.documentItems.filter(doc => doc[searchByKey] ? doc[searchByKey].toLowerCase().includes(e.value.toLowerCase()) : false));
      });
      filterItems = uniqBy(filterItems, this.filterByProp);
    }

    this.groupItems(filterItems, this.groupByName);
  }

  openDocumentItem(e: DocumentBaseItem) {
    this.onDocumentValueChanged.emit(e);
  }

  private groupItems(documentItems, groupByName) {
    this.groupedItems = groupBy(documentItems, groupByName);
    this.keys = SortUtils.orderBy(Object.keys(this.groupedItems), null);
    this.cdr.detectChanges();
  }

}
