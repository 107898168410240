import { Directive, EventEmitter, Input, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[tmsNarrativeAlert]',
})
export class NarrativeAlertDirective {
    @Input('tmsNarrativeAlert') alertUser: boolean = false;
    @Output() onOverrideManualEdits: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {}

    @HostListener('click', ['$event']) onClick(e) {
        if (this.alertUser) {
            if (!confirm('All manual edits will be lost. Are you sure you want to continue?')) {
                e.stopImmediatePropagation();
                this.onOverrideManualEdits.emit(false);
            } else {
                this.onOverrideManualEdits.emit(true);
            }
        }
    }
}
