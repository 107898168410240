import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PagedResult, NonTreatmentItem, OperationResponse } from '@app/model';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class NonTreatmentService {

    constructor(private http: HttpClient, private urlConstantsService: UrlConstantsService) {}

    getNonTreatmentItems(facilityId: string, userId: string, chargesDate: Date) {
        chargesDate = moment(chargesDate).toDate();
        const day = chargesDate.getDate();
        const month = (chargesDate.getMonth() + 1);
        const year = chargesDate.getFullYear();

        return this.http.get<PagedResult<NonTreatmentItem>>(`${this.urlConstantsService.NONTREATMENT_URL}?facilityId=${facilityId}&userId=${userId}&day=${day}&month=${month}&year=${year}`)
            .pipe(map(response => response.items));
    }

    addNonTreatmentItem(item: NonTreatmentItem) {
        return this.http.post<OperationResponse<NonTreatmentItem>>(`${this.urlConstantsService.NONTREATMENT_URL}`, item);
    }

    updatesNonTreatmentItem(item: NonTreatmentItem) {
        return this.http.put<OperationResponse<NonTreatmentItem>>(`${this.urlConstantsService.NONTREATMENT_URL}/${item.id}`, item);
    }

    deleteNonTreatmentItem(id: string) {
        return this.http.delete<OperationResponse<NonTreatmentItem>>(`${this.urlConstantsService.NONTREATMENT_URL}/${id}`);
    }
}