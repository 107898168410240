import { Injectable } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CacheManService, UrlConstantsService } from '@app/core';
import { PagedResult, OperationResponse, TimeClockEntry, ServerTime, TimeClockNote, eDiscipline, eDesignation, SwitchDisciplineResponse } from '@app/model';
import { SwitchDisciplineRequest } from '@app/model/time-clock/SwitchDisciplineRequest';
import { Observable } from 'knockout';

@Injectable({
	providedIn: 'root',
})
export class TimeClockEntryService {
    constructor(private cacheManService: CacheManService,
        private http: HttpClient, 
        private urlConstantsService: UrlConstantsService) {}

    getRecentEntries() {
        return this.http.get<PagedResult<TimeClockEntry>>(`${this.urlConstantsService.TIMECLOCK_URL}?numDays=1`)
            .pipe(map(entriesPagedResult => entriesPagedResult.items));
    }

    getMostRecentEntry() {
        return this.http.get<TimeClockEntry>(`${this.urlConstantsService.TIMECLOCK_URL}/latest`);
    }

    getCurrentNoteEntry() {
        return this.cacheManService.getCurrentUser()
            .pipe(switchMap(user => this.http.get<TimeClockNote>(`${this.urlConstantsService.TIMECLOCK_URL}/note/${user.id}`)));
    }

    getServerTimeUtc() {
        return this.http.get<ServerTime>(`${this.urlConstantsService.TIMECLOCK_URL}/servertime`);
    }

    createEntry(entry: TimeClockEntry) {
        return this.http.post<OperationResponse<TimeClockEntry>>(`${this.urlConstantsService.TIMECLOCK_URL}/punchentry`, entry)
            .pipe(tap(response => {
                this.cacheManService.addTimeClockEntry(response.data);
            }));
    }

    updateNoteEntry(note: TimeClockNote) {
        return this.http.post<OperationResponse<TimeClockNote>>(`${this.urlConstantsService.TIMECLOCK_URL}/note`, note)
            .pipe(map(response => response.data));
    }

    switchDiscipline(discipline: eDiscipline, designation: eDesignation, ecode: string, facilityId: string) {
        const switchDisciplineRequest: SwitchDisciplineRequest = {
            discipline: +discipline,
            designation: +designation,
            facilityId: facilityId,
            eCode: ecode
        };

        return this.http.post<OperationResponse<SwitchDisciplineResponse>>(`${this.urlConstantsService.TIMECLOCK_URL}/switch`, switchDisciplineRequest)
            .pipe(map(response => response.data),
                  tap(response => this.cacheManService.updateCurrentUserDisciplineDesignation(response.clockedInDiscipline, response.clockedInDesignation, response.timeEntries)));
    }
}
