<div id="stepper-top">
  <div class="row stepper-container">
    <div class="col-9">
      <div class="step-content">
        <dx-scroll-view #stepContent showScrollbar="always" [useNative]="true" (onScroll)="onScroll($event)">
          <ng-content select="[stepper-header-content]"></ng-content>
          <div>
            <ng-container [ngTemplateOutlet]="selected ? selected.content : null"></ng-container>
          </div>
        </dx-scroll-view>
      </div>
      <div class="row align-items-end progress-buttons-container">
        <div class="col-2 cancel-button">
          <a href="javascript:void(0)" (click)="onCancelClicked()"> Cancel </a>
        </div>
        <div class="col-10 progress-buttons">
          <dx-button class="tms-primary-button" *ngIf="showPreviousButton" text="Previous" [template]="'prevButtonTemplate'" (onClick)="onPreviousClicked()">
            <div *dxTemplate="let data of 'prevButtonTemplate'">
                <i class="fas fa-angle-left previous-button-icon"></i> Previous
            </div>
          </dx-button>
          <dx-button class="save-button" (onClick)="onSaveProgressClicked()" text="I'm Done For Now" *ngIf="showDoneForNowButton"></dx-button>
          <dx-button class="tms-primary-button next-button" *ngIf="totalSteps > (selectedIndex + 1)"
            [disabled]="disableNextButton" [text]="nextValue" [template]="'buttonTemplate'" (onClick)="onNextClicked()">
            <div *dxTemplate="let buttonData of 'buttonTemplate'">
              {{buttonData.text}} <i class="fas fa-angle-right next-button-icon"></i>
            </div>
          </dx-button>
          <dx-button class="tms-primary-button next-button" *ngIf="(showSubmit && totalSteps === (selectedIndex + 1))"
              [disabled]="submitButtonDisabled" [text]="submitButtonText" (onClick)="onSubmitClicked()">
            </dx-button> 
        </div>
      </div>
    </div>
    <div class="col-3 progress-region">
      <dx-scroll-view showScrollbar='always'>
        <ng-content select="[stepper-header-progress-region]"></ng-content>
        <div *ngFor="let group of tmsStepGroup">
          <div class="title">{{ group.title }}</div>
          <div class="progress-item" *ngFor="let step of group.steps; index as i">
            <tms-step-progress-item [tmsStep]="step" [selectedIndex]="selectedIndex" [showProgressIcon]="showProgressIcon"
              (onStepProgressItemSelected)="onStepProgressItemSelected($event)"></tms-step-progress-item>
          </div>
        </div>
      </dx-scroll-view>
    </div>
  </div>
</div>