import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { ClientsStore, ClientsState } from './clients.store';

@Injectable({
    providedIn: 'root',
})
export class ClientsQuery extends QueryEntity<ClientsState> {
    constructor(protected store: ClientsStore) {
        super(store);
    }
}
