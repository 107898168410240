import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OperationResponse, PagedResult, QuickLinkItem } from '@app/model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlConstantsService } from './url-constants.service';

@Injectable({
  providedIn: 'root'
})
export class QuickLinksService {

  constructor(private http: HttpClient,
    private urlConstantsService: UrlConstantsService) { }

  getQuickLinkItems(): Observable<QuickLinkItem[]> {
    return this.http.get<PagedResult<QuickLinkItem>>(`${this.urlConstantsService.QUICK_LINKS_URL}`)
      .pipe(map(response => response.items));
  }

  addQuickLinkItem(item: QuickLinkItem) {
    return this.http.post<OperationResponse<QuickLinkItem>>(`${this.urlConstantsService.QUICK_LINKS_URL}`, item)
      .pipe(map(response => response.data));
  }

  updateQuickLinkItem(item: QuickLinkItem) {
    return this.http.put<OperationResponse<QuickLinkItem>>(`${this.urlConstantsService.QUICK_LINKS_URL}/${item.id}`, item)
      .pipe(map(response => response.data));
  }

  deleteQuickLinkItem(item: QuickLinkItem) {
    return this.http.delete<OperationResponse<void>>(`${this.urlConstantsService.QUICK_LINKS_URL}/${item.id}`);
  }
}
