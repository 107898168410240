import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Company, PagedResult } from '@app/model';
import { UrlConstantsService } from './url-constants.service';

@Injectable({
  providedIn: 'root'
})
export class CorporationsService {
  private companies: Company[] = null;

  constructor(private http: HttpClient, private urlConstantsService: UrlConstantsService) { }

  getAllCompanies(): Observable<Company[]> {
    if (this.companies) {
      return of(this.companies);
    } else {
    return this.http.get<PagedResult<Company>>(`${this.urlConstantsService.CORPORATIONS_URL}/companies`)
      .pipe(map(response => response.items), tap(companies => this.companies = companies));
    }
  }
}
