import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { DocumentRecord } from '@app/model';

export interface DocumentRecordsState extends EntityState<DocumentRecord>, ActiveState {
}

export function createInitialState(): DocumentRecordsState {
  return {
    active: null,
    loading: false,
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'documents' })
export class DocumentRecordsStore extends EntityStore<DocumentRecordsState> {
  constructor() {
    super(createInitialState());
  }

}

