import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Dictionary, ScheduleSummaryEntry } from '@app/model';

export interface PatientPlannerSummaryState {
  dateRange: Date[],
  summary: Dictionary<ScheduleSummaryEntry[]>,
  previousSurplusDeficit: number,
  weeklyBudget: number,
}

export function createInitialState(): PatientPlannerSummaryState {
  return {
    dateRange: [],
    summary: {},
    previousSurplusDeficit: 0,
    weeklyBudget: 0,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'patient-planner-summary' })
export class PatientPlannerSummaryStore extends Store<PatientPlannerSummaryState> {

  constructor() {
    super(createInitialState());
  }

}

