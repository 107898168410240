import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { PatientsState, PatientsStore } from './patients.store';

@Injectable({
    providedIn: 'root',
})
export class PatientsQuery extends QueryEntity<PatientsState> {
    constructor(protected store: PatientsStore) {
        super(store);
    }
}
