import { Injectable } from "@angular/core";
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { FunctionBuilder } from "@app/model";

export interface FunctionBuilderState extends EntityState<FunctionBuilder>, ActiveState {}

const initialState: FunctionBuilderState = {
    active: null,
}

@Injectable({providedIn: 'root'})
@StoreConfig({ name: 'function-builders'})
export class FunctionBuildersStore extends EntityStore<FunctionBuilderState> {
    constructor() {
        super(initialState);
    }
}