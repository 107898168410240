import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { ClinicalDocumentsState, ClinicalDocumentsStore } from './clinical-documents.store';

@Injectable({
    providedIn: 'root',
})
export class ClinicalDocumentsQuery extends QueryEntity<ClinicalDocumentsState> {
    constructor(protected store: ClinicalDocumentsStore) {
        super(store);
    }
}