import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompleteRestorativeNursingProgramCommand, InterviewAnswer, InterviewGroup, InterviewTab, OperationResponse, PagedResult, PatientSlim, ReopenRestorativeNursingProgramCommand, RestorativeNursingDashboardResponse, RestorativeNursingNonTreatmentMinutes, RestorativeNursingProgram, RestorativeNursingProgramNonTreatmentMinutesResponse, RestorativeNursingProgramResponse, RestorativeNursingPrograms, RestorativeNursingProgramTreatmentsResponse, UpdateRestorativeNursingEntryInterviewCommand, UpdateRestorativeNursingProgramAssignedUserCommand, UpdateRestorativeNursingProgramTreatmentsCommand } from '@app/model';
import { RestorativeNursingTreatmentItem } from '@app/model/restorative-nursing/RestorativeNursingTreatmentItem';
import { DeepCopyUtils } from '@app/utils';
import { EntityDirtyCheckPlugin } from '@datorama/akita';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { AncillaryProgramConstants } from '../constants';
import { RestorativeNursingProgramsQuery, RestorativeNursingProgramStore, RestorativeNursingTreatmentsQuery, RestorativeNursingTreatmentsStore } from '../stores/restorative-nursing';
import { UrlConstantsService } from './url-constants.service';

@Injectable({
  providedIn: 'root'
})
export class RestorativeNursingService {
  private _activeRestorativeNursingChargesDate: Date = null;
  private _activeRestorativeNursingChargesDate$: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);

  private _dirtyCheckCollection: EntityDirtyCheckPlugin;
  private _treatmentsDirtyCheckCollection: EntityDirtyCheckPlugin;

  // for handling race condition 
  private _updateInProgress: boolean;
  private updateInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  set updateInProgress(value: boolean) {
    this._updateInProgress = value;
    this.updateInProgress$.next(this._updateInProgress);
  }

  set activeRestorativeNursingChargesDate(activeDate: Date) {
    this._activeRestorativeNursingChargesDate = activeDate;
    this._activeRestorativeNursingChargesDate$.next(this._activeRestorativeNursingChargesDate);
  }

  get activeRestorativeNursingChargesDate$(): Observable<Date> {
    return this._activeRestorativeNursingChargesDate$.asObservable();
  }

  getActiveRnp(): Observable<RestorativeNursingProgram> {
    return this.rnpQuery.selectActive();
  }

  constructor(private http: HttpClient,
              private rnpQuery: RestorativeNursingProgramsQuery,
              private rnpStore: RestorativeNursingProgramStore,
              private rnpTreatmentsQuery: RestorativeNursingTreatmentsQuery,
              private rnpTreatmentsStore: RestorativeNursingTreatmentsStore,
              private urlConstantsService: UrlConstantsService) { }

  getRnpEntries(facilityId: string, isActive: boolean): Observable<RestorativeNursingDashboardResponse> {
    return this.http.get<OperationResponse<RestorativeNursingDashboardResponse>>(`${this.urlConstantsService.RESTORATIVE_NURSING_URL}?facilityId=${facilityId}&isActive=${isActive}`)
      .pipe(map(response => response.data));
  }

  getRnpEntryById(id: string): Observable<RestorativeNursingProgram> {
    // return this.updateInProgress$
    //   .pipe(filter(updateInProgress => !updateInProgress),
    //         switchMap(() =>this.http.get<OperationResponse<RestorativeNursingProgramResponse>>(`${this.urlConstantsService.RESTORATIVE_NURSING_URL}/${id}`)),
    //         map(response => response.data.restorativeNursingProgram));
    return this.http.get<OperationResponse<RestorativeNursingProgramResponse>>(`${this.urlConstantsService.RESTORATIVE_NURSING_URL}/${id}`)
      .pipe(map(response => response.data.restorativeNursingProgram),
            tap(rnp => {
              this.rnpStore.upsert(rnp.id, rnp);
              this.rnpStore.setActive(rnp.id);
              this._dirtyCheckCollection = new EntityDirtyCheckPlugin(this.rnpQuery);
              this._dirtyCheckCollection.setHead();
            }));
  }

  getPatientsByFacilityId(facilityId: string): Observable<PatientSlim[]> {
    return this.http.get<PagedResult<PatientSlim>>(`${this.urlConstantsService.RESTORATIVE_NURSING_URL}/${facilityId}/patients`)
        .pipe(map(patientsPagedResult => patientsPagedResult.items))
  }

  addRnpEntry(rnpEntry: RestorativeNursingProgram): Observable<RestorativeNursingProgram>  {
    return this.http.post<OperationResponse<RestorativeNursingProgram>>(`${this.urlConstantsService.RESTORATIVE_NURSING_URL}`, rnpEntry)
      .pipe(map(response => response.data));
  }

  updateRnpAssignedUser(rnpId: string, assignedUserId: string): Observable<boolean> {
    const request: UpdateRestorativeNursingProgramAssignedUserCommand = {
      restorativeNursingProgramId: rnpId,
      assignedUserId: assignedUserId,
    };

    return this.http.put<OperationResponse<boolean>>(`${this.urlConstantsService.RESTORATIVE_NURSING_URL}`, request)
      .pipe(map(response => response.data));
  }

  completeRnpEntry(rnpId: string, endDate: Date) {
    const request: CompleteRestorativeNursingProgramCommand = {
      restorativeNursingProgramId: rnpId,
      endDate: endDate,
    };

    return this.http.put<OperationResponse<RestorativeNursingProgram>>(`${this.urlConstantsService.RESTORATIVE_NURSING_URL}/complete`, request)
      .pipe(map(response => response.data));
  }

  reopenRnpEntry(rnpId: string) {
    const request: ReopenRestorativeNursingProgramCommand = {
      restorativeNursingProgramId: rnpId,
    };

    return this.http.put<OperationResponse<RestorativeNursingProgram>>(`${this.urlConstantsService.RESTORATIVE_NURSING_URL}/reopen`, request)
      .pipe(map(response => response.data));
  }

  deleteRnpEntry(id: string) {
    return this.http.delete<OperationResponse<boolean>>(`${this.urlConstantsService.RESTORATIVE_NURSING_URL}/${id}`);
  }

  updateRnpInterviewAnswer(answer: InterviewAnswer): void {
    this.rnpStore.updateActive(entity => {
      const tabIndex = entity.interviewPage.tabs.findIndex(tab => tab.shortName == answer.tab);
      const groupIndex = entity.interviewPage.tabs[tabIndex].groups.findIndex(group => group.shortName == answer.group);
      const questionIndex = entity.interviewPage.tabs[tabIndex].groups[groupIndex].questions.findIndex(question => question.shortName == answer.shortName);

      const newEntity: RestorativeNursingProgram = DeepCopyUtils.deepCopyObject(entity);
      newEntity.interviewPage.tabs[tabIndex].groups[groupIndex].questions[questionIndex].answer = answer;

      return newEntity;
    });
  }

  updateRnpInterviewGroupIsSelected(isSelected: boolean, tab: InterviewTab, group: InterviewGroup): void {
    this.rnpStore.updateActive(entity => {
      const tabIndex = entity.interviewPage.tabs.findIndex(t => t.shortName == tab.shortName);
      const groupIndex = entity.interviewPage.tabs[tabIndex].groups.findIndex(g => g.shortName == group.shortName);

      const newEntity: RestorativeNursingProgram = DeepCopyUtils.deepCopyObject(entity);
      newEntity.interviewPage.tabs[tabIndex].groups[groupIndex].isSelected = isSelected;

      newEntity.interviewPage.tabs[tabIndex].groups[groupIndex].questions.forEach(question => {
        if (question.shortName == AncillaryProgramConstants.FUNCTION_BUILDER_INTERVIEW_QUESTION_START_DATE || question.shortName == AncillaryProgramConstants.RESTORATIVE_PROGRAM_INTERVIEW_QUESTION_START_DATE) {
          question.validationOptions = {
            isRequired: isSelected,
          }
        }
      });

      return newEntity;
    });
  }

  updateRnpInterviewTabIsVisible(isVisible: boolean, tab: InterviewTab): void {
    this.rnpStore.updateActive(entity => {
      const tabIndex = entity.interviewPage.tabs.findIndex(t => t.shortName == tab.shortName);
      const newEntity: RestorativeNursingProgram = DeepCopyUtils.deepCopyObject(entity);
      newEntity.interviewPage.tabs[tabIndex].isVisible = isVisible;

      return newEntity;
    });
  }

  selectedRnpProgramsCount(): number {
    const activeRnp = this.rnpQuery.getActive();
    const activeTabs = activeRnp.interviewPage.tabs.filter(tab => tab.isVisible);

    return activeTabs.length;
  }

  removeRnpEntryFromStore(id: string) {
    this.rnpStore.remove(id);
  }

  updateRnpEntryInterview(): Observable<RestorativeNursingProgram> {
    this.updateInProgress = true;
    const activeRnp = this.rnpQuery.getActive();

    if (!this._dirtyCheckCollection.someDirty()) {
      return of(activeRnp);
    }

    const updateInterviewCommand: UpdateRestorativeNursingEntryInterviewCommand = {
      dto: activeRnp,
    }
    return this.http.put<OperationResponse<RestorativeNursingProgram>>(`${this.urlConstantsService.RESTORATIVE_NURSING_URL}/${activeRnp.id}/interview`, updateInterviewCommand)
      .pipe(map(response => response.data),
            tap(response => this._dirtyCheckCollection.setHead()),
            finalize(() => this.updateInProgress = false));
  }

  getRnpTreatments(facilityId: string, treatmentDate: Date, showAll: boolean): Observable<RestorativeNursingProgramTreatmentsResponse> {
    treatmentDate = moment(treatmentDate).toDate();
    const day = treatmentDate.getDate();
    const month = (treatmentDate.getMonth() + 1);
    const year = treatmentDate.getFullYear();

    this.rnpTreatmentsStore.reset();

    return this.http.get<OperationResponse<RestorativeNursingProgramTreatmentsResponse>>(`${this.urlConstantsService.RESTORATIVE_NURSING_URL}/treatments?facilityId=${facilityId}&day=${day}&month=${month}&year=${year}&showAllPatients=${showAll}`)
      .pipe(map(response => response.data),
            tap(response => {
              this.rnpTreatmentsStore.upsertMany(response.treatments);
              this._treatmentsDirtyCheckCollection = new EntityDirtyCheckPlugin(this.rnpTreatmentsQuery);
              this._treatmentsDirtyCheckCollection.setHead();
            }));
  }

  updateRnpEntryTreatments(facilityId: string, treatmentDate: Date): Observable<RestorativeNursingProgramTreatmentsResponse> {
    var date = new Date(treatmentDate);
    const cmd: UpdateRestorativeNursingProgramTreatmentsCommand = {
      month: date.getMonth() + 1,
      day: date.getDate(),
      year: date.getFullYear(),
      facilityId: facilityId,
      treatments: this.rnpTreatmentsQuery.getAll(),
    };

    return this.http.put<OperationResponse<RestorativeNursingProgramTreatmentsResponse>>(`${this.urlConstantsService.RESTORATIVE_NURSING_URL}/treatments`, cmd)
      .pipe(map(response => response.data),
            tap(response => {
              this._treatmentsDirtyCheckCollection.setHead();
            }));
  }

  getIsRestorativeNursingTreatmentsDirty(): boolean {
    return this._treatmentsDirtyCheckCollection.someDirty();
  }

  getRnpNonTreatmentItems(nonTreatmentDate: Date, facilityId: string): Observable<RestorativeNursingNonTreatmentMinutes[]> {
    nonTreatmentDate = moment(nonTreatmentDate).toDate();
    const day = nonTreatmentDate.getDate();
    const month = (nonTreatmentDate.getMonth() + 1);
    const year = nonTreatmentDate.getFullYear();

    return this.http.get<OperationResponse<RestorativeNursingProgramNonTreatmentMinutesResponse>>(`${this.urlConstantsService.RESTORATIVE_NURSING_URL}/nontreatments?facilityId=${facilityId}&day=${day}&month=${month}&year=${year}`)
        .pipe(map(response => response.data.nonTreatmentItems));
  }

  addRnpNonTreatmentItem(item: RestorativeNursingNonTreatmentMinutes) {
    return this.http.post<OperationResponse<RestorativeNursingNonTreatmentMinutes>>(`${this.urlConstantsService.RESTORATIVE_NURSING_URL}/nontreatments`, item)
        .pipe(map(response => response.data));
  }

  updateRnpNonTreatmentItem(item: RestorativeNursingNonTreatmentMinutes) {
    return this.http.put<OperationResponse<RestorativeNursingNonTreatmentMinutes>>(`${this.urlConstantsService.RESTORATIVE_NURSING_URL}/nontreatments/`, item)
        .pipe(map(response => response.data));
  }

  deleteRnpNonTreatmentItem(id: string): Observable<boolean> {
    return this.http.delete<OperationResponse<boolean>>(`${this.urlConstantsService.RESTORATIVE_NURSING_URL}/nontreatments/${id}`)
        .pipe(map(response => response.data));
  }

  // Store Operations
  updateRnpMissedVisitType(patientId: string, missedVisitType: number) {
    this.rnpTreatmentsStore.update(patientId, entity => {
      return {
        ...entity,
        missedVisitItem: {
          ...entity.missedVisitItem,
          missedVisit: missedVisitType,
        }
      }
    });
  }

  updateRnpProgramMinutes(patientId: string, program: RestorativeNursingPrograms, minutes: number) {
    this.rnpTreatmentsStore.update(patientId, entity => {
      const programIndex = this.getTreatmentItemIndex(entity.treatmentItems, program);

      if (programIndex >= 0) {
        return {
          ...entity,
          treatmentItems: [
            ...entity.treatmentItems.slice(0, programIndex),
            {
              ...entity.treatmentItems[programIndex],
              minutes: minutes,
            },
            ...entity.treatmentItems.slice(programIndex + 1)
          ]
        }
      } else {
        return entity;
      }
    });
  }

  updateRnpAudienceType(patientId: string, program: RestorativeNursingPrograms, audience: number) {
    this.rnpTreatmentsStore.update(patientId, entity => {
      const programIndex = this.getTreatmentItemIndex(entity.treatmentItems, program);

      if (programIndex >= 0) {
        return {
          ...entity,
          treatmentItems: [
            ...entity.treatmentItems.slice(0, programIndex),
            {
              ...entity.treatmentItems[programIndex],
              audience: audience,
            },
            ...entity.treatmentItems.slice(programIndex + 1)
          ]
        }
      } else {
        return entity;
      }
    });
  }

  updateRnpSelectedNotes(patientId: string, program: RestorativeNursingPrograms, selectedNotes: string[], note: string) {
    this.rnpTreatmentsStore.update(patientId, entity => {
      const programIndex = this.getTreatmentItemIndex(entity.treatmentItems, program);
      if (programIndex >= 0) {
        return {
          ...entity,
          treatmentItems: [
            ...entity.treatmentItems.slice(0, programIndex),
            {
              ...entity.treatmentItems[programIndex],
              selectedNotes: selectedNotes,
              notes: note,
            },
            ...entity.treatmentItems.slice(programIndex + 1)
          ]
        }
      } else {
        return entity;
      }
    });
  }

  updateRnpNote(patientId: string, program: RestorativeNursingPrograms, note: string) {
    this.rnpTreatmentsStore.update(patientId, entity => {
      const programIndex = this.getTreatmentItemIndex(entity.treatmentItems, program);
      if (programIndex >= 0) {
        return {
          ...entity,
          treatmentItems: [
            ...entity.treatmentItems.slice(0, programIndex),
            {
              ...entity.treatmentItems[programIndex],
              notes: note,
            },
            ...entity.treatmentItems.slice(programIndex + 1)
          ]
        }
      } else {
        return entity;
      }
    });
  }

  private getTreatmentItemIndex(treatmentItems: RestorativeNursingTreatmentItem[], program: number): number {
    return treatmentItems.findIndex(treatment => treatment.program == program);
  }
}
