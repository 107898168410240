import { Component, ViewChild, AfterViewInit, Renderer2, Input, ElementRef } from '@angular/core';
import * as ko from 'knockout';
//import { Html } from 'devexpress-reporting/dx-web-document-viewer';

@Component({
    selector: 'tms-report-viewer',
    template:
    `
    <div #scripts></div>
    <div #control style="width:100%; height: 1000px">
      <div data-bind="dxReportViewer: $data"></div>
    </div>
    `,
})
export class TmsReportViewerComponent implements AfterViewInit {
  @Input()
  set reportUrl(reportUrl: string) {
    this._reportUrl = reportUrl;
    this.koReportUrl(reportUrl);
  }
  get reportUrl() {
    return this._reportUrl;
  }
  koReportUrl = ko.observable(null);
  _reportUrl;

  @ViewChild('scripts') scripts: ElementRef;
  @ViewChild('control') control: ElementRef;

  constructor(private renderer: Renderer2) { }

  ngAfterViewInit() {
    const reportUrl = this.koReportUrl,
        host = 'http://localhost:44399/',
        invokeAction = 'DXXRDV',
        container = this.renderer.createElement('div');

    // container.innerHTML = Html;
    this.renderer.appendChild(this.scripts.nativeElement, container);
    ko.applyBindings({
      reportUrl: reportUrl, // The URL of a report that is opened in the Document Viewer when the application starts.
      requestOptions: { // Options for processing requests from the Web Document Viewer.
        host: host, // URI of your backend project.
        invokeAction: invokeAction, // The URI path of the controller action that processes requests.
      },
    }, this.control.nativeElement);
  }
}
