import { Component, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteConstants } from '@app/core';
import { TmsRoutingDashboardComponent } from '@app/shared';


@Component({
    selector: 'tms-home',
    styleUrls: ['./home.component.scss'],
    templateUrl: './home.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent extends TmsRoutingDashboardComponent implements OnInit, OnDestroy {
    tabs: any[] = [
        {
            text: 'Home',
            route: 'announcements'
        },
        {
            text: 'Support',
            route: 'support',
        },
        {
            text: 'Knowledge Base',
            route: 'knowledgebase',
        },
        {
            text: 'Links',
            route: RouteConstants.HOME_QUICK_LINKS,
        }
    ];

    constructor(protected cdr: ChangeDetectorRef,
        protected route: ActivatedRoute,
        protected router: Router) {
        super(route, router, cdr);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}