import { Component, Input, Output, EventEmitter } from '@angular/core';

import { NarrativeBuilderOption, NBComponentBoolean } from '@app/model';
import { NarrativeBuilderUtils } from '@app/utils/narrative-builder.utils';

@Component({
  selector: 'tms-narrative-builder-checkbox',
  templateUrl: './narrative-builder-checkbox.component.html',
  styleUrls: ['./narrative-builder-checkbox.component.scss'],
})
export class NarrativeBuilderCheckboxComponent {
  @Input() alertUser?: boolean = false;
  @Input() optionValue: NarrativeBuilderOption;
  @Output() onValueChanged: EventEmitter<NBComponentBoolean> = new EventEmitter<NBComponentBoolean>();

  private overrideManualEdits: boolean = null;

  constructor() {}


  onChkBoxValueChanged(e) {
    if (e.value) {
      const nIndex = NarrativeBuilderUtils.randomNarrativeBuildOptionValueTextIndex(this.optionValue.values[0].optionValues);
      this.onValueChanged.emit({
          isChecked: e.value,
          optionValue: this.optionValue.values[0],
          optionValueTextIndex: nIndex,
          property: this.optionValue.property,
          overrideManualEdits: this.overrideManualEdits,
      });
    } else {
      this.onValueChanged.emit({ isChecked: e.value, property: this.optionValue.property, overrideManualEdits: this.overrideManualEdits, });
    }
  }

  onOverrideManualEdits(e: boolean) {
    this.overrideManualEdits = e;
  }
}
