import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ChargesService } from '@app/core';
import { Modality, TreatmentItem } from '@app/model';
import { TmsConfirmationModalComponent } from '@app/shared';
import { modalOptions } from '@app/utils';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tms-charge-modality-edit',
  templateUrl: './charge-modality-edit.component.html',
  styleUrls: [ './charge-modality-edit.component.scss' ]
})
export class ChargeModalityEditComponent implements OnInit{

      @Input() caseId: string = null;
      @Input() treatmentItem: TreatmentItem = null;
      
      isReadOnly: boolean = false;
      modalityStatement: string = null;
  
      @Input() dd1Value: string;
      @Input() dd2Value: string;
      @Input() dd3Value: string;
      @Input() dd4Value: string;
      @Input() dd5Value: string;
      @Input() dd6Value: string;
      @Input() dd7Value: string;
      @Input() dd8Value: string;
  
      @Input() txt1Value: string;
      @Input() txt2Value: string;
      @Input() txt3Value: string;
      @Input() txt4Value: string;
    
      title: string = "";
      iceHeatList = ['No supplemental thermal treatments', 'Ice', 'Heat'];
      eStimList = ['IFC', 'TENS', 'FES', 'NMES', 'HVPC'];
      attendedList = ['attended', 'unattended'];
      diathermyList = ['thermal', 'subthermal'];
      sideList = ['Right', 'Left', 'Bilateral', 'N/A'];
      legSide = ['right', 'left'];
      directionalList = ['anterior', 'posterior', 'dorsal', 'ventral', 'lateral', 'medial',
          'proximal', 'distal', 'ipsilateral',
          'contralateral', 'superficial', 'deep', 'inferior', 'superior'];
      bodyRegionsList = ['cervical region', 'shoulder', 'periscapular area', 'thoracic region',
          'lumbar region', 'upper arm', 'biceps', 'triceps', 'deltoid', 'forearm', 'elbow', 'wrist',
          'hand', 'finger(s)', 'thumb', 'lumbosacral region', 'gluteal area', 'hip', 'thigh', 'quadriceps',
          'hamstrings', 'groin', 'knee', 'shin', 'calf', 'ankle', 'foot'];
      ultrasoundModeList = ['continuous', 'pulsed: 20%', 'pulsed: 50%'];
      tractionAreaList = ['cervical', 'pelvic'];
      tractionPositionList = ['prone', 'supine'];
      frequencyList = ['intermittant', 'constant'];
  
      modalityEnum: typeof Modality = Modality;
      validStatement: boolean = false;
  
      constructor(private activeModal: NgbActiveModal,
        private cdr: ChangeDetectorRef,
        private chargesService: ChargesService,
        private modalService: NgbModal) {}
  
      ngOnInit(): void {
        this.title = this.treatmentItem.chargeCode + ' ' + this.treatmentItem.chargeCodeObj?.description;
         if (this.treatmentItem.modalityStatement) {
          this.isReadOnly = true;
          this.modalityStatement = this.treatmentItem.modalityStatement;
         }
      }
  
      resetModalityStatement() {
          const modalRef = this.modalService.open(TmsConfirmationModalComponent, { ...modalOptions });
          modalRef.componentInstance.title = 'Delete Modality Statement';
          modalRef.componentInstance.message = 'Are you sure you want to delete this modality statement? Previous statement cannot be retrieved after saving.';
          modalRef.result.then(() => {
              this.modalityStatement = null;
              this.isReadOnly = false;
              this.cdr.detectChanges();
          }, () => {});
      }

    onClearPressed(){
        this.dd1Value = null;
        this.dd2Value = null;
        this.dd3Value = null;
        this.dd4Value = null;
        this.dd5Value = null;
        this.dd6Value = null;
        this.dd7Value = null;
        this.dd8Value = null;
        this.txt1Value = null;
        this.txt2Value = null;
        this.txt3Value = null;
        this.txt4Value = null;
        this.cdr.detectChanges();
    }

    onClose() {
        this.activeModal.dismiss();
    }

    onSubmitPressed(){
        let array = [this.dd1Value, this.dd2Value, this.dd3Value, this.dd4Value, 
                    this.dd5Value, this.dd6Value, this.dd7Value, this.dd8Value, 
                    this.txt1Value, this.txt2Value, this.txt3Value, this.txt4Value];
        this.activeModal.close(array);
    }
}