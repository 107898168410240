import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, DoCheck, IterableDiffers, ChangeDetectorRef, OnInit } from '@angular/core';
import { User, WeeklyEmployeeAvailability } from '@app/model';
import { Observable } from 'rxjs';
import { DeepCopyUtils } from '@app/utils';
import { CacheManService } from '@app/core';

@Component({
  selector: 'tms-user-work-hours',
  templateUrl: './user-work-hours.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserWorkHoursComponent implements OnInit {
  @Input() user: User;
  @Output() updateUser: EventEmitter<User> = new EventEmitter();

  currentUser: User;
  currentUser$: Observable<User>;

  facilityWorkHours: WeeklyEmployeeAvailability[] = [];
  availabilityForm: WeeklyEmployeeAvailability;

  primaryFacilityId: string;

  constructor(private cacheManService: CacheManService, private cdr: ChangeDetectorRef) {
  }

  getFacilityName(facilityId: string) {
    return this.cacheManService.getFacilityName(facilityId);
  }

  ngOnInit() {
    this.currentUser = DeepCopyUtils.deepCopyObject(this.user);
    this.setFacilityWorkHours();
    this.cdr.detectChanges();
  }

  setFacilityWorkHours() {
    this.facilityWorkHours = [];

    // Facility Availability exists; check if any facilities added or removed from FacilityIds array
    if (this.currentUser.employee && this.currentUser.employee.facilityAvailability && this.currentUser.employee.facilityAvailability.length > 0) {
      this.primaryFacilityId = this.currentUser.facilityIds[0];
      this.currentUser.facilityIds.forEach(fac => {
       const availability = this.currentUser.employee.facilityAvailability.find(f => f.facilityId === fac)
        if (availability) {
          this.facilityWorkHours.push(availability);
        } else {
          const wh: WeeklyEmployeeAvailability = {
            facilityId: fac, mondayAvailableMinutes: 0, tuesdayAvailableMinutes: 0, wednesdayAvailableMinutes: 0,
            thursdayAvailableMinutes: 0, fridayAvailableMinutes: 0, saturdayAvailableMinutes: 0, sundayAvailableMinutes: 0,
          };
          this.facilityWorkHours.push(wh);
        }
      });
    } else {
      if (this.currentUser.employee && this.currentUser.facilityIds && this.currentUser.facilityIds.length > 0) {
        this.primaryFacilityId = this.currentUser.facilityIds[0];
        this.currentUser.facilityIds.forEach(fac => {
          const wh: WeeklyEmployeeAvailability = {
            facilityId: fac, mondayAvailableMinutes: 0, tuesdayAvailableMinutes: 0, wednesdayAvailableMinutes: 0,
            thursdayAvailableMinutes: 0, fridayAvailableMinutes: 0, saturdayAvailableMinutes: 0, sundayAvailableMinutes: 0,
          };
          this.facilityWorkHours.push(wh);
        });
      }
    }
  }

  onSubmit(e) {
    this.facilityWorkHours.forEach(w => {
      if (!w.sundayAvailableMinutes) {
        w.sundayAvailableMinutes = 0;
      }

      if (!w.mondayAvailableMinutes) {
        w.mondayAvailableMinutes = 0;
      }

      if (!w.tuesdayAvailableMinutes) {
        w.tuesdayAvailableMinutes = 0;
      }

      if (!w.wednesdayAvailableMinutes) {
        w.wednesdayAvailableMinutes = 0;
      }

      if (!w.thursdayAvailableMinutes) {
        w.thursdayAvailableMinutes = 0;
      }

      if (!w.fridayAvailableMinutes) {
        w.fridayAvailableMinutes = 0;
      }

      if (!w.saturdayAvailableMinutes) {
        w.saturdayAvailableMinutes = 0;
      }
    });

    this.currentUser.employee.facilityAvailability = this.facilityWorkHours;
    this.updateUser.emit(this.currentUser);
  }
}
