<dx-validation-group>
    <tms-modal [title]="title" (onSubmit)="onAddKnowledgeBaseItem($event)"
        (onCancel)="onCloseKnowledgeBaseItem()" (onClose)="onCloseKnowledgeBaseItem()">
        <div class="row">
            <div class="col-3">
                <tms-label text="Friendly Name"></tms-label>
            </div>
            <div class="col">
                <dx-text-box [(value)]="kbItemToAdd.name">
                </dx-text-box>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col-3">
                <tms-label text="Category" [isRequired]="true"></tms-label>
            </div>
            <div class="col">
                <dx-text-box [(value)]="kbItemToAdd.categoryName">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="Category is required">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col-3">
                <tms-label text="Departments" [isRequired]="true"></tms-label>
            </div>
            <div class="col">
                <dx-drop-down-box valueExpr="valueInt" displayExpr="text" [dataSource]="departments$ | async"
                [value]="kbItemToAdd.departments">
                <div *dxTemplate="let data of 'content'">
                    <dx-list [dataSource]="departments$ | async" [showSelectionControls]="true"
                        [selectionMode]="'multiple'" itemTemplate="listItem" keyExpr="valueInt"
                        [(selectedItemKeys)]="kbItemToAdd.departments" pageLoadMode="scrollBottom">
                        <div *dxTemplate="let item of 'listItem'">
                            {{item.text}}
                        </div>
                    </dx-list>
                </div>
                <dx-validator>
                    <dxi-validation-rule type="required" message="A department is required">
                    </dxi-validation-rule>
                </dx-validator>
            </dx-drop-down-box>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col-3">
                <tms-label text="Description" [isRequired]="false"></tms-label>
            </div>
            <div class="col">
                <dx-text-box [(value)]="kbItemToAdd.description">
                </dx-text-box>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col-3">
                <tms-label text="Primary Keywords" [isRequired]="false"></tms-label>
                (Type and hit enter to add primary keyword)
            </div>
            <div class="col">
                <dx-tag-box
                    [items]="kbItemToAdd.primaryKeywords"
                    [value]="kbItemToAdd.primaryKeywords"
                    [acceptCustomValue]="true"
                    (onCustomItemCreating)="onCustomItemCreatingPrimary($event)">
                </dx-tag-box>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col-3">
                <tms-label text="Keywords" [isRequired]="false"></tms-label>
                (Type and hit enter to add keyword)
            </div>
            <div class="col">
                <dx-tag-box
                    [items]="kbItemToAdd.keywords"
                    [value]="kbItemToAdd.keywords"
                    [acceptCustomValue]="true"
                    (onCustomItemCreating)="onCustomItemCreating($event)">
                </dx-tag-box>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col-3">
                <tms-label text="Is Active" [isRequired]="false"></tms-label>
            </div>
            <div class="col">
                <dx-check-box [(value)]="kbItemToAdd.isActive">
                </dx-check-box>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col-3">
                <tms-label text="Video?" [isRequired]="false"></tms-label>
            </div>
            <div class="col">
                <dx-check-box [(value)]="isVideo" (onValueChanged)="onIsVideoValueChanged($event)">
                </dx-check-box>
            </div>
        </div>
        <div *ngIf="isVideo" class="row tms-row-divider">
            <div class="col-3">
                <tms-label text="Video Source Url" [isRequired]="true"></tms-label>
            </div>
            <div class="col">
                <dx-text-box [(value)]="kbItemToAdd.videoSrc">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="A video source url is required">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
            </div>
        </div>
        <div *ngIf="!isVideo" class="row tms-row-divider">
            <div class="col-3"></div>
            <div class="col">
                <dx-file-uploader #fileuploader name="file" selectButtonText="Select file" labelText="" accept="*"
                    uploadMode="useForm">
                    <dx-validator>
                        <dxi-validation-rule type="custom" [validationCallback]="validateFile">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-file-uploader>
            </div>
        </div>
    </tms-modal>
</dx-validation-group>