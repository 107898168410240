import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { InterviewService } from './interview.service';
import { UrlConstantsService } from './url-constants.service';
import {
  InterviewAnswer,
  InterviewSession,
  OperationResponse,
  PagedResult,
  PatientScreen,
  ScreenInterviewRequest,
  SignatureRequest,
  eInterviewStatus,
  InterviewGroup,
  Dictionary,
  InterviewType,
} from '@app/model';
import { ScreensStore, ScreensQuery } from '../stores';

@Injectable({
  providedIn: 'root'
})
export class ScreensService {

  constructor(private httpClient: HttpClient,
    private interviewService: InterviewService,
    private urlConstantsService: UrlConstantsService,
    private screensStore: ScreensStore,
    private screensQuery: ScreensQuery) { }

  get screens$(): Observable<PatientScreen[]> {
    return this.screensQuery.selectAll();
  }

  getAllScreens(facilityId: string, completed: boolean, month: number, year: number): Observable<PatientScreen[]> {
    return this.httpClient.get<PagedResult<PatientScreen>>(`${this.urlConstantsService.SCREENS_URL}?facilityId=${facilityId}&completed=${completed}&month=${month}&year=${year}`)
      .pipe(map(pagedResults => pagedResults.items),
        tap(screens => {
          this.screensStore.set(screens);
        }));
  }

  getScreenById(interviewSessionId: string): Observable<InterviewSession> {
    return this.httpClient.get<OperationResponse<InterviewSession>>(`${this.urlConstantsService.SCREENS_URL}/${interviewSessionId}`)
      .pipe(map(response => response.data),
            tap(interviewSession => this.interviewService.setActiveInterviewSession(interviewSessionId, interviewSession.answers)))
  }

  createScreen(screenInterviewRequest: ScreenInterviewRequest) {   
    return this.httpClient.post<OperationResponse<InterviewSession>>(this.urlConstantsService.SCREENS_URL, screenInterviewRequest)
      .pipe(tap(response => {
        if (response.isSuccessful) {
          const interviewSession = response.data;
          this.screensStore.add({
            interviewSessionId: interviewSession.interviewSessionId,
            facilityId: interviewSession.facilityId,
            patientId: interviewSession.patientId,
            patientName: this.getScreenDetailsValue(interviewSession.answers, 'PatientName'),
            disciplines: this.getScreenDetailsValues(interviewSession.answers, 'Disciplines'),
            dueDate: this.getScreenDetailsValue(interviewSession.answers, 'DueDate'),
            assignedUserId: this.getScreenDetailsValue(interviewSession.answers, 'AssignedTherapistId'),
            assignedUserName: this.getScreenDetailsValue(interviewSession.answers, 'AssignedTherapistName'),
            interviewType: screenInterviewRequest.interviewType,
            status: interviewSession.status,
            documentRecordId: interviewSession.documentRecordId,
            screenId: '',
            screenCategories: [],
          })
        }
      }))
  }

  deleteScreen(screenId: string, interviewSessionId: string) {
    return this.httpClient.delete<OperationResponse<void>>(`${this.urlConstantsService.SCREENS_URL}/${screenId}/interview/${interviewSessionId}`)
      .pipe(tap(response => {
        if (response.isSuccessful) {
          this.screensStore.remove(interviewSessionId);
          this.interviewService.removeInterviewSession(interviewSessionId);
        }
      }))
  }

  getInterviewAnswers(interviewSessionId: string) {
    return this.interviewService.getInterviewAnswers$(interviewSessionId)
    .pipe(take(1))
    .subscribe(answers => {
      });
  }

  saveScreen(interviewSessionId: string, interviewSession: InterviewSession) {
      return this.httpClient.put<OperationResponse<InterviewSession>>(`${this.urlConstantsService.SCREENS_URL}/${interviewSessionId}`, interviewSession)
      .pipe(map(response => response.data),
            tap(response => {
              this.screensStore.update(interviewSessionId, entity => {
                return { 
                  ...entity,
                  status: response.status,
                }
              });
              this.interviewService.resetActiveInterview();
            }));
  }

  signScreen(interviewSessionId: string) {
    return this.interviewService.signInterview(interviewSessionId, {})
      .pipe(tap(response => {
        this.screensStore.update(interviewSessionId, entity => {
          return { 
            ...entity,
            status: eInterviewStatus.Signed,
          }
        });
      }));
  }

  updateScreenAssignedTherapist(interviewSessionId: string, assignedUserId: string, screenId: string) {
    return this.httpClient.put<OperationResponse<void>>(`${this.urlConstantsService.SCREENS_URL}/${interviewSessionId}/screeninfo`, { assignedUserId: assignedUserId, screenId: screenId })
      .pipe(tap(response => {
        if (response.isSuccessful) {
          this.screensStore.update(interviewSessionId, entity => {
            return {
              ...entity,
              assignedUserId: assignedUserId,
            }
          })
        }
      }))
  }

  updateScreenInterviewAnswer(interviewSessionId: string, interviewAnswer: InterviewAnswer) {
    this.interviewService.addInterviewQuestionAnswer(interviewSessionId, interviewAnswer);
  }

  removeGroupInterviewAnswers(interviewSessionId: string, group: InterviewGroup) {
    group.questions.forEach(question => this.interviewService.deleteInterviewQuestionAnswer(interviewSessionId, question.answer));
  }

  unsignScreen(interviewSessionId: string, signatureRequest: SignatureRequest = null) {
    return this.httpClient.put<OperationResponse<void>>(`${this.urlConstantsService.SCREENS_URL}/${interviewSessionId}/unsign`, { pinCode: '1234'})
      .pipe(tap(response => {
        if (response.isSuccessful) {
          this.screensStore.update(interviewSessionId, entity => {
            return {
              ...entity,
              status: eInterviewStatus.InProgress,
            } 
          })
        }
      }))
  }

  private getScreenDetailsValue(answers: InterviewAnswer[], shortName: string): any {
    const index = answers.findIndex(answer => answer.shortName === shortName);
    if (index >= 0) {
      return answers[index].value;
    } else {
      return null;
    }
  }

  private getScreenDetailsValues(answers: InterviewAnswer[], shortName: string): any[] {
    const index = answers.findIndex(answer => answer.shortName === shortName);
    if (index >= 0) {
      return answers[index].values;
    } else {
      return null;
    }
  }

  getScreenInterviewTypes(): Dictionary<string> {
    return {
      [InterviewType.Screen]: 'Risk Screen',
      [InterviewType.Wellness]: 'Wellness Screen',
      [InterviewType.Multidisciplinary]: 'Multidisciplinary Screen',
      [InterviewType.ALF]: 'ALF Screen',
    }
  }
}
