import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import {
  CdkStepperModule,
} from '@angular/cdk/stepper';

import {
  NbAccordionModule, // TODO: remove import if not using
  NbCardModule,
  NbTabsetModule,
} from '@nebular/theme';

import { MaterialModule } from './material.module';

import {
  DxButtonModule,
  DxCalendarModule,
  DxCheckBoxModule,
  DxDateBoxModule,
  DxDropDownButtonModule,
  DxFileUploaderModule,
  DxFormModule,
  DxDataGridModule,
  DxHtmlEditorModule,
  DxListModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxProgressBarModule,
  DxSelectBoxModule,
  DxSchedulerModule,
  DxScrollViewModule,
  DxSliderModule,
  DxTemplateModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxToastModule,
  DxRadioGroupModule,
  DxDropDownBoxModule,
  DxTabsModule,
  DxTagBoxModule,
  DxTooltipModule,
  DxLoadPanelModule,
  DxValidatorModule,
  DxValidationGroupModule,
  DxValidationSummaryModule,
} from 'devextreme-angular';

import {
  NgbCollapseModule,
 } from '@ng-bootstrap/ng-bootstrap';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { PageHeadingComponent } from '@app/shared/page-heading/page-heading.component';

import {
  InfoboxComponent,
  InfoboxItemComponent,
} from '@app/shared/infobox';

import {
  InterviewContainerComponent,
  InterviewGroupContainerComponent,
  InterviewModalComponent,
  InterviewQuestionBasicNarrativeComponent,
  InterviewQuestionCheckboxComponent,
  InterviewQuestionCheckboxListComponent,
  InterviewQuestionDateComponent,
  InterviewQuestionDateTimeComponent,
  InterviewQuestionDropDownCheckmarkListComponent,
  InterviewQuestionDropDownListComponent,
  InterviewQuestionRadioListComponent,
  InterviewQuestionComponent,
  InterviewQuestionContainerComponent,
  InterviewQuestionNativeDropDownComponent,
  InterviewQuestionNumericComponent,
  InterviewQuestionTextAreaComponent,
  InterviewQuestionTextboxComponent,
  InterviewQuestionLevelOfCareComponent,
  InterviewQuestionPillsComponent,
  InterviewQuestionQualityOfPerformanceComponent,
  InterviewQuestionTypeaheadComponent,
  InterviewQuestionYesNoComponent,
  InterviewQuestionICDCodeTypeAheadComponent,
  TabbedInterviewComponent,
} from '@app/shared/interviews';

import { LoaderComponent } from '@app/shared/loader/loader.component';
import { MeasurementSliderComponent } from '@app/shared/measurement-slider/measurement-slider.component';
import {
  NarrativeBuilderBaseDirective,
  NarrativeBuilderCheckboxComponent,
  NarrativeBuilderContainerComponent,
  NarrativeBuilderDropdownComponent,
  NarrativeBuilderTextboxComponent,
  NarrativeBuilderToggleButtonComponent,
} from '@app/shared/narrative-builder';

import { ReportGenerationModalComponent } from './report-generation-modal/report-generation-modal.component';
import { TmsTextboxComponent } from '@app/shared/tms-textbox/tms-textbox.component';
import { TmsGridComponent } from '@app/shared/tms-grid/tms-grid.component';
import { TmsModalComponent } from '@app/shared/tmsmodal/tmsmodal.component';
import { TmsToastComponent } from '@app/shared/tms-toast/tms-toast-component';
import { TmsReportViewerComponent } from '@app/shared/tms-report-viewer/tms-report-viewer.component';
import { ESignatureModalComponent } from '@app/shared/esignature/esignature-modal.component';
import { TmsConfirmationModalComponent } from './tms-confirmation-modal/tms-confirmation-modal.component';
import { TmsMessageModalComponent } from './tms-message-modal/tms-message-modal.component';
import { TmsCardComponent } from './tms-card/tms-card-component';
import { TmsCardHeaderComponent } from './tms-card/tms-card-header/tms-card-header.component';
import { TmsCardBodyComponent } from './tms-card/tms-card-body/tms-card-body.component';
import { TmsCardFooterComponent } from './tms-card/tms-card-footer/tms-card-footer.component';
import { TmsPageCardComponent } from './tms-card/tms-page-card/tms-page-card.component';
import { 
  TmsStepProgressItemComponent,
  TmsStepperComponent,
} from './tms-stepper';
import { TmsToggleButtonComponent } from './tms-toggle-button/tms-toggle-button.component';
import { TmsCollapsePanelComponent } from './tms-collapse-panel/tms-collapse-panel.component';
import { SupportingCodesModalComponent} from './supporting-codes-modal/supporting-codes-modal.component' ;
import { TmsDropdownButtonComponent } from './tms-dropdown-button/tms-dropdown-button.component';
import { TmsWarningMessageModalComponent } from './tms-warning-message-modal/tms-warning-message-modal.component';
import { TmsWarningModalComponent } from './tms-warning-modal/tms-warning-modal.component';
import { TmsLabelComponent } from './tms-label/tms-label.component';
import { TmsDocumentDashboardComponent } from './tms-document-dashboard/tms-document-dashboard.component';
import { TmsDocumentViewerModalComponent } from './tms-document-viewer-modal';
import { TmsDownloadButtonComponent } from './tms-download-button/tms-download-button.component';
import { TmsMultiDocumentsModalComponent } from './tms-multi-documents-modal/tms-multi-documents-modal.component';
import { TmsProgressBarModalComponent } from './tms-progress-bar-modal/tms-progress-bar-modal.component';
import { TmsRoutingDashboardComponent } from './tms-routing-dashboard/tms-routing-dashboard.component';
import { TmsSelectBoxComponent } from './tms-select-box/tms-select-box.component';
import { TmsTypeAheadDropdownComponent } from './tms-typeahead-dropdown/tms-typeahead-dropdown.component';
import { TmsVideoViewerModalComponent } from './tms-video-viewer-modal/tms-video-viewer-modal.component';
import { TmsTypeaheadTagboxComponent } from './tms-typeahead-tagbox/tms-typeahead-tagbox.component';
import { TmsTypeaheadTextboxComponent } from './tms-typeahead-textbox/tms-typeahead-textbox.component';
import { TmsDateBoxComponent } from './tms-date-box/tms-date-box.component';
import { TmsMatDialogComponent } from './tms-mat-dialog/tms-mat-dialog.component';
import { TmsMatConfirmationDialogComponent } from './tms-mat-confirmation-dialog/tms-mat-confirmation-dialog.component';


// Pipes
import { DateFormatPipe } from '@app/shared/pipes/dateFormat.pipe';
import { DataListItemPipe } from './pipes/data-list-item.pipe';
import { DataListStringListPipe } from './pipes/data-list-string-list.pipe';
import { StringListPipe } from './pipes/string-list.pipe';
import { removeWhitespacePipe } from './pipes/remove-whitespace-pipe';

// Directives
import { NarrativeAlertDirective } from '@app/shared/directives';
import { TmsSimpleStepperComponent } from './tms-simple-stepper/tms-simple-stepper.component';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { MonthYearPickerComponent } from './month-year-picker/month-year-picker.component';
import { SanitizeHtmlPipe } from './pipes/sanitize-html-pipe';
import { ScrollToTopButtonComponent } from './scroll-to-top-button/scroll-to-top-button.component';
import { ScrollToTopIdDirective } from './directives/scroll-to-top-id.directive';
import { TitleAttributeDirective } from './directives/title-attribute.directive';

import { SvgIconComponent } from '@ngneat/svg-icon';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { PatientTreatmentGroupStatementEditComponent } from '@app/pages/charges/dashboard/treatments-tab/patient-treatment/patient-treatment-group-statement/patient-treatment-group-statement-edit/patient-treatment-group-statement-edit.component';
import { ChargeTelehealthStatementEditComponent } from '@app/pages/charges/dashboard/patient-tab/charge-entry/charge-telehealth-statement/charge-telehealth-statement-edit/charge-telehealth-statement-edit.component';
import { PatientTreatmentConcurrentStatementEditComponent } from '@app/pages/charges/dashboard/treatments-tab/patient-treatment/patient-treatment-concurrent-statement/patient-treatment-concurrent-statement-edit/patient-treatment-concurrent-statement-edit.component';
import { ChargeModalityEditComponent } from '@app/pages/charges/dashboard/patient-tab/charge-entry/charge-modality/charge-modality-edit/charge-modality-edit.component';
import { PatientTreatmentCaregiverTrainingNonGroupStatementEdit } from '@app/pages/charges/dashboard/treatments-tab/patient-treatment/patient-treatment-caregiver-training-non-group-statement/non-group-statement-edit.component';
import { PatientTreatmentCaregiverTrainingGroupStatementEdit } from '@app/pages/charges/dashboard/treatments-tab/patient-treatment/patient-treatment-caregiver-training-group-statement/caregiver-group-statement-edit.component';




const TMS_COMPONENTS = [
  FileUploaderComponent,
  InterviewContainerComponent,
  InterviewGroupContainerComponent,
  InterviewModalComponent,
  InterviewQuestionBasicNarrativeComponent,
  InterviewQuestionDateComponent,
  InterviewQuestionDateTimeComponent,
  InterviewQuestionDropDownCheckmarkListComponent,
  InterviewQuestionDropDownListComponent,
  InterviewQuestionCheckboxComponent,
  SupportingCodesModalComponent,
  PatientTreatmentGroupStatementEditComponent,
  PatientTreatmentConcurrentStatementEditComponent,
  PatientTreatmentCaregiverTrainingNonGroupStatementEdit,
  PatientTreatmentCaregiverTrainingGroupStatementEdit,
  ChargeTelehealthStatementEditComponent,
  ChargeModalityEditComponent,
  InterviewQuestionCheckboxListComponent,
  InterviewQuestionRadioListComponent,
  InterviewQuestionComponent,
  InterviewQuestionContainerComponent,
  InterviewQuestionNativeDropDownComponent,
  InterviewQuestionNumericComponent,
  InterviewQuestionTextAreaComponent,
  InterviewQuestionTextboxComponent,
  InterviewQuestionLevelOfCareComponent,
  InterviewQuestionPillsComponent,
  InterviewQuestionQualityOfPerformanceComponent,
  InterviewQuestionTypeaheadComponent,
  InterviewQuestionYesNoComponent,
  InterviewQuestionICDCodeTypeAheadComponent,
  TabbedInterviewComponent,
  InfoboxComponent,
  InfoboxItemComponent,
  PageHeadingComponent,
  LoaderComponent,
  MeasurementSliderComponent,
  NarrativeBuilderContainerComponent,
  NarrativeBuilderCheckboxComponent,
  NarrativeBuilderDropdownComponent,
  NarrativeBuilderTextboxComponent,
  NarrativeBuilderToggleButtonComponent,
  ReportGenerationModalComponent,
  ScrollToTopButtonComponent,
  TmsTextboxComponent,
  TmsConfirmationModalComponent,
  TmsGridComponent,
  TmsMessageModalComponent,
  TmsModalComponent,
  TmsToastComponent,
  TmsReportViewerComponent,
  ESignatureModalComponent,
  TmsCardComponent,
  TmsCardHeaderComponent,
  TmsCardBodyComponent,
  TmsCardFooterComponent,
  TmsPageCardComponent,
  TmsStepProgressItemComponent,
  TmsSimpleStepperComponent,
  TmsStepperComponent,
  TmsToggleButtonComponent,
  TmsCollapsePanelComponent,
  TmsDropdownButtonComponent,
  TmsWarningMessageModalComponent,
  TmsWarningModalComponent,
  TmsLabelComponent,
  TmsDocumentDashboardComponent,
  TmsDocumentViewerModalComponent,
  TmsDownloadButtonComponent,
  TmsMultiDocumentsModalComponent,
  TmsTypeAheadDropdownComponent,
  TmsTypeaheadTagboxComponent,
  TmsTypeaheadTextboxComponent,
  TmsVideoViewerModalComponent,
  MonthYearPickerComponent,
  TmsProgressBarModalComponent,
  TmsSelectBoxComponent,
  TmsDateBoxComponent,
  TmsMatDialogComponent,
  TmsMatConfirmationDialogComponent,
];

const TMS_DIRECTIVES = [
  HasPermissionDirective,
  NarrativeAlertDirective,
  ScrollToTopIdDirective,
  TitleAttributeDirective,
];

const TMS_PIPES = [
  DateFormatPipe,
  DataListItemPipe,
  DataListStringListPipe,
  SanitizeHtmlPipe,
  StringListPipe,
  removeWhitespacePipe,
];

const DEVEXTREME_MODULES_EXPORTED = [
  DxButtonModule,
  DxCalendarModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDropDownBoxModule,
  DxDropDownButtonModule,
  DxFileUploaderModule,
  DxFormModule,
  DxHtmlEditorModule,
  DxListModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxProgressBarModule,
  DxSchedulerModule,
  DxSelectBoxModule,
  DxScrollViewModule,
  DxSliderModule,
  DxTabsModule,
  DxTagBoxModule,
  DxTemplateModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxToastModule,
  DxTooltipModule,
  DxRadioGroupModule,
  DxLoadPanelModule,
  DxValidatorModule,
  DxValidationGroupModule,
  DxValidationSummaryModule,
];

const DEVEXTREME_MODULES_IMPORTED = [
  ...DEVEXTREME_MODULES_EXPORTED,
  DxDateBoxModule
]

const NEBULAR_MODULES = [
  NbAccordionModule,
  NbCardModule,
  NbTabsetModule,
];

const BOOTSTRAP_MODULES = [
  NgbCollapseModule,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...DEVEXTREME_MODULES_IMPORTED,
    ...NEBULAR_MODULES,
    ...BOOTSTRAP_MODULES,
    PdfViewerModule,
    CdkStepperModule,
    MaterialModule,
    SvgIconComponent,
  ],
  declarations: [
    NarrativeBuilderBaseDirective, // Not exported 
    ...TMS_COMPONENTS,
    ...TMS_DIRECTIVES,
    ...TMS_PIPES,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...TMS_COMPONENTS,
    ...TMS_DIRECTIVES,
    ...TMS_PIPES,
    ...DEVEXTREME_MODULES_EXPORTED,
    ...NEBULAR_MODULES,
    ...BOOTSTRAP_MODULES,
    PdfViewerModule,
    CdkStepperModule,
    SvgIconComponent,
    MaterialModule,
  ],
})
export class SharedModule { }
