import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FacilitiesStore, FacilitiesState } from './facilities.store';

@Injectable({
  providedIn: 'root',
})
export class FacilitiesQuery extends QueryEntity<FacilitiesState> {

  constructor(protected store: FacilitiesStore) {
    super(store);
  }

}
