import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CasperDataEntry, FacilityCasperDataEntry, OperationResponse, PagedResult } from '@app/model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlConstantsService } from '.';

@Injectable({
  providedIn: 'root'
})
export class CasperDataEntryService {

  constructor(private http: HttpClient,
    private urlConstantsService: UrlConstantsService) { }

  getCasperDataEntriesByFacilityId(facilityId: string): Observable<FacilityCasperDataEntry> {
    return this.http.get<OperationResponse<FacilityCasperDataEntry>>(`${this.urlConstantsService.CASPER_URL}/facility/${facilityId}`)
      .pipe(map(response => response.data));
  }

  addCasperDataEntry(facilityId: string, casperDataEntry: CasperDataEntry): Observable<CasperDataEntry> {
    return this.http.post<OperationResponse<CasperDataEntry>>(`${this.urlConstantsService.CASPER_URL}/facility/${facilityId}`, casperDataEntry)
      .pipe(map(response => response.data));
  }

  editCasperDataEntry(facilityId: string, casperDataEntry: CasperDataEntry): Observable<CasperDataEntry> {
    return this.http.put<OperationResponse<CasperDataEntry>>(`${this.urlConstantsService.CASPER_URL}/facility/${facilityId}`, casperDataEntry)
      .pipe(map(response => response.data));
  }

  deleteCasperDataEntry(facilityId: string, casperDataEntryId: string): Observable<boolean> {
    return this.http.delete<OperationResponse<boolean>>(`${this.urlConstantsService.CASPER_URL}/facility/${facilityId}/${casperDataEntryId}`)
      .pipe(map(response => response.data));
  }

  getNationalAvgCasperDataEntries(): Observable<CasperDataEntry[]> {
    return this.http.get<PagedResult<CasperDataEntry>>(`${this.urlConstantsService.CASPER_URL}/nationalavg`)
      .pipe(map(response => response.items));
  }

  addNationalAvgCasperDataEntry(casperDataEntry: CasperDataEntry): Observable<CasperDataEntry> {
    return this.http.post<OperationResponse<CasperDataEntry>>(`${this.urlConstantsService.CASPER_URL}/nationalavg`, casperDataEntry)
      .pipe(map(response => response.data));
  }

  editNationalAvgCasperDataEntry(id: string, casperDataEntry: CasperDataEntry): Observable<CasperDataEntry> {
    return this.http.put<OperationResponse<CasperDataEntry>>(`${this.urlConstantsService.CASPER_URL}/nationalavg/${id}`, casperDataEntry)
      .pipe(map(response => response.data));
  }

  deleteNationalAvgCasperDataEntry(casperDataEntryId: string): Observable<boolean> {
    return this.http.delete<OperationResponse<boolean>>(`${this.urlConstantsService.CASPER_URL}/nationalavg/${casperDataEntryId}`)
      .pipe(map(response => response.data));
  }
}
