import { Component, Input, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { DxFileUploaderComponent } from 'devextreme-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as moment from 'moment';
import {
  CacheManService, CasesService, DataListConstants, FacilitiesService,
  SupportService, ToastMessageService, ExternalLinks
} from '@app/core';
import {
  Case,
  DataListItem, Dictionary, eSupportIssueCategories, eSupportIssueDepartment, eSupportIssueType, Facility, KeyValuePair,
  Patient, SupportIssue
} from '@app/model';
import { fileHasher$, DeepCopyUtils } from '@app/utils';
import { eSupportIssueUrgent } from '@app/model/enum/eSupportIssueUrgent';

@Component({
  selector: 'tms-support-add-ticket',
  templateUrl: './support-add-ticket.component.html',
  styleUrls: ['./support-add-ticket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupportAddTicketComponent implements OnInit {
  @Input() facilityId: string = null;
  @Input() patientId: string = null;
  @Input() caseId: string = null;
  @Input() supportIssueInit: SupportIssue = null;
  @ViewChild(DxFileUploaderComponent, { static: false }) fileuploader: DxFileUploaderComponent;

  cases: Case[] = [];
  categories: KeyValuePair<eSupportIssueCategories, string>[] = [];
  urgents$: Observable<DataListItem[]> = null;
  facilities: Facility[] = [];
  issueTypes$: Observable<DataListItem[]> = null;
  patients: Patient[] = [];
  subcategories: string[] = [];
  supportIssue: SupportIssue = {};

  private disciplinesDicionary: Dictionary<string> = null;
  private selectedFacilityId: string = '';
  private subCategories: KeyValuePair<eSupportIssueCategories, string[]>[] = [];

  constructor(private activeModal: NgbActiveModal,
    private cacheManService: CacheManService,
    private casesService: CasesService,
    private cdr: ChangeDetectorRef,
    private facilitiesService: FacilitiesService,
    private supportService: SupportService,
    private toastMessageService: ToastMessageService) { }

  ngOnInit() {
    if (this.supportIssueInit) {
      this.supportIssue = DeepCopyUtils.deepCopyObject(this.supportIssueInit);
    }

    this.urgents$ = this.cacheManService.getDataListItems$(DataListConstants.SUPPORT_ISSUE_URGENT);
    this.issueTypes$ = this.cacheManService.getDataListItems$(DataListConstants.SUPPORT_ISSUE_TYPE);
    this.cacheManService.getDataListValueTextDictionary$(DataListConstants.DISCIPLINES, 'shortName')
      .pipe(take(1))
      .subscribe(dictionary => {
        this.disciplinesDicionary = dictionary;
        if (this.facilityId) {
          this.selectedFacilityId = this.facilityId;
          this.getFacilityPatients(this.facilityId);
        }

        if (this.patientId) {
          this.getPatientCases(this.patientId);
        }
      });
    this.cacheManService.getUserFacilities()
      .pipe(take(1))
      .subscribe(facilities => this.facilities = facilities);
    this.categories = this.supportService.getSupportIssueCategories();
    this.subCategories = this.supportService.getSupportIssueSubCategories();

    this.cacheManService.getCurrentUser()
      .pipe(take(1))
      .subscribe(user => {
        this.supportIssue = {
          ...this.supportIssue,
          creator: {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            notificationEmail: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
          },
          facilityId: this.facilityId != null ? this.facilityId : null,
          patientId: this.patientId != null ? this.patientId : null,
          patientCaseId: this.caseId != null ? this.caseId : null,
          attachments: [],
          type: eSupportIssueType.SubmitARequestOrIncident,
          department: eSupportIssueDepartment.Ignite,
          urgent: eSupportIssueUrgent.No
        };
      });
  }

  onCancelTicket() {
    this.activeModal.dismiss();
  }

  onSubmitTicket(e) {
    const validResult = e.validationGroup.validate();
    if (validResult.isValid) {
      this.submitSupportTicket();
    }
  }

  onCategoryValueChanged(e) {
    const index = this.subCategories.findIndex(kvp => kvp.key == e.value);
    if (index >= 0) {
      this.subcategories = this.subCategories[index].value;
      this.cdr.detectChanges();
    }
  }

  onFacilityValueChanged(e) {
    this.selectedFacilityId = e.value;
    this.getFacilityPatients(e.value);
  }

  onPatientValueChanged(e) {
    this.getPatientCases(e.value);
  }

  validateFile = (params) => {
    if (this.fileuploader.value && this.fileuploader.value.length > 0) {
      const file = this.fileuploader.value[0];

      if (file.size > 130023424) {
        params.rule.isValid = false;
        params.rule.message = 'File cannot exceed 125 MB.';
        return false;
      } else {
        params.rule.isValid = true;
        return true;
      }
    } else {
      params.rule.isValid = true;
      return true;
    }
  }

  private getPatientCases(patientId: string) {
    this.casesService.getByPatientId(patientId)
      .subscribe(response => {
        this.cases = response.filter(value => value.facilityId == this.selectedFacilityId)
          .map((value) => {
            return {
              text: `${this.disciplinesDicionary[value.discipline]} ${moment(value.startDate).format('MM/DD/YYYY')}`,
              id: value.id,
            }
          });
        this.cdr.detectChanges();
      });
  }

  private getFacilityPatients(facilityId: string) {
    this.facilitiesService.getPatientsById(facilityId, true)
      .subscribe(response => {
        this.patients = response;
        this.cdr.detectChanges();
      })
  }

  private submitSupportTicket() {
    this.supportIssue.created = new Date();
    this.supportIssue.department = eSupportIssueDepartment.Ignite;
    this.supportService.createSupportIssue(this.supportIssue)
      .subscribe(response => {
        if (this.fileuploader.value && this.fileuploader.value.length > 0) {
          const file = this.fileuploader.value[0];
          fileHasher$(file).subscribe(result => {
            this.supportService.addSupportIssueAttachment(response.ticketNumber,
              {
                author: { ...this.supportIssue.creator },
                fileName: file.name,
                fileHash: result,
                fileSize: file.size,
                createdDate: new Date(),
              }, file).subscribe(() => { });
          });
        }

        this.toastMessageService.successNotification('Successfully created support ticket.')
        this.activeModal.close();
      }, () => this.toastMessageService.errorNotification('Unable to create support ticket'));
  }
}
