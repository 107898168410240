<dx-select-box [dataSource]="dataSourceCopy"
    [value]="valueCopy"
    [displayExpr]="displayExpr"
    [valueExpr]="valueExpr"
    (onValueChanged)="onSelectBoxValueChanged($event)">
    <dx-validator>
        <dxi-validation-rule type="custom" 
                [validationCallback]="selectBoxCallback" 
                [message]="requiredMessage">
        </dxi-validation-rule>
    </dx-validator>
</dx-select-box>

