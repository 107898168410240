
export class TimeClockModal {
    public static disabledClockButton_testable(clockActionProcessing: boolean, clockedIn: boolean, confirmChecked: boolean, clockButtonFacility: string) {
        var shouldBeDisabled = true;
        // If a clock action is processing we should disable the clock button
        if (clockActionProcessing) {
            shouldBeDisabled = true;
        }
        // If the user has acknowledged their time and they are clocked in, the button should be enabled
        else if (confirmChecked && clockedIn){
            shouldBeDisabled = false;
        }
        // If the use is not clocked in, and the clockButtonFacility is populated (not null and not an empty string),
        // the button should be enabled
        else if (!clockedIn && (clockButtonFacility !== null && clockButtonFacility !== '')) {
            shouldBeDisabled = false;
        }
        // Otherwise, disable the button
        else {
            shouldBeDisabled = true;
        }
        return shouldBeDisabled;
    }
}