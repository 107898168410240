<div class="row">
    <div class="col-12 tasks-header">
        Facility Action Items
    </div>
</div>
<hr>
<div class="row">
    <div class="col-12">
        <table class="task-grid">
            <tr class="header-item">
                <td class="header-item-text">Action Item</td>
                <td class="header-item-text">Due Date</td>
            </tr>
            <ng-container *ngFor="let actionItem of actionItems">
                <tr class="item-row">
                    <td class="action-item-text">
                        {{ actionItem.item }}
                    </td>
                    <td class="badge-item-cell">
                        <span *ngIf="actionItem.isDueToday" class="badge tasks-badge task-due-today">Due Today</span>
                        <span *ngIf="actionItem.isPastDue"
                            class="badge tasks-badge task-past-due">{{ actionItem.pastDueDays }} Days Past</span>
                        <span *ngIf="actionItem.isDueFuture" class="badge tasks-badge task-due-future">Due in
                            {{ actionItem.futureDueDays }} Days</span>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
</div>
<div class="row tms-section-divider">
    <div class="col-12 tasks-header">
        Personnel Action Items
    </div>
</div>
<hr>
<div class="row">
    <div class="col-12">
        <table class="task-grid">
            <tr class="header-item">
                <td class="header-item-text">Action Item</td>
                <td class="header-item-text">Due Date</td>
            </tr>
            <ng-container *ngFor="let task of tasks">
                <tr class="item-row">
                    <td class="action-item-text">
                        {{ task.item }}
                    </td>
                    <td class="badge-item-cell">
                        <span *ngIf="task.isDueToday" class="badge tasks-badge task-due-today">Due Today</span>
                        <span *ngIf="task.isPastDue"
                            class="badge tasks-badge task-past-due">{{ task.pastDueDays }} Days Past</span>
                        <span *ngIf="task.isDueFuture" class="badge tasks-badge task-due-future">Due in
                            {{ task.futureDueDays }} Days</span>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
</div>