import { Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { ErrorItem, OperationResponse } from '@app/model';

@Injectable({
  providedIn: 'root',
})
export class ToastMessageService {

  constructor() { }

  operationResponseNotification(response: OperationResponse<any>, displayTime: number = 3600) {
    if (response.isSuccessful) {
      if (response.message) {
        this.displayToast('success', response.message, displayTime);
      }
    } else {
      if (response.errors && response.errors.length > 0) {
        let errorMessage: string = '';
        response.errors.forEach(error => {
          errorMessage = errorMessage + error.text + '. ';
        });
        this.displayToast('error', errorMessage, displayTime);
      } else {
        this.displayToast('error', 'An error occurred.', displayTime);
      }
    }
  }

  successNotification(message: string, displayTime: number = 3600) {
      this.displayToast('success', message, displayTime);
  }

  errorNotification(message: string, displayTime: number = 3600) {
      this.displayToast('error', message, displayTime);
  }

  alertNotification(message: string, displayTime: number = 3600) {
      this.displayToast('info', message, displayTime);
  }

  private displayToast(type: string, message: string, displayTime: number) {
    notify(message, type, displayTime);
  }
}
