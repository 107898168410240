import {
    eDiscipline,
    NarrativeBuilderOption,
    NarrativeBuilderList,
    RecertDeficitsProperties,
    RecertOTDeficitProperties,
} from '@app/model';

import * as idb from './id-builder-utils';
import { NarrativeBuilderListUtils } from './narrative-builer-list.utils';

const RD: string = 'RD';
export function getRecertDeficitsNarrativeBuilder(discipline: eDiscipline): NarrativeBuilderList {
    if (discipline == eDiscipline.SpeechTherapy) {
        return getSTRecertDeficits();
    } else if (discipline == eDiscipline.OccupationalTherapy){
        return NarrativeBuilderListUtils.getNarrativeBuilderList(RD, RecertOTDeficitProperties);
    } else {
        return getOtherRecertDeficitsNB();
    }
}

function getOtherRecertDeficitsNB(): NarrativeBuilderList {
    const WT_BEAR_POTENTIAL = 'WT_BEAR_POTENTIAL';
    const wtBearBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RD, WT_BEAR_POTENTIAL),
        text: 'wt bearing potential',
        values: [
            {
                id: idb.getNBOptionValueId(RD, WT_BEAR_POTENTIAL),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RD, WT_BEAR_POTENTIAL, 1),
                        text: 'wt bearing potential',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertDeficitsProperties.WT_BEARING_POTENTIAL,
    }

    const POS_ACCUR = 'POS_ACCUR';
    const posAccBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RD, POS_ACCUR),
        text: 'positional accuracy',
        values: [
            {
                id: idb.getNBOptionValueId(RD, POS_ACCUR),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RD, POS_ACCUR, 1),
                        text: 'positional accuracy',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertDeficitsProperties.POSITIONAL_ACCUR,
    }

    const PRESS_RELEIF = 'PRESS_RELEIF';
    const pressReliefBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RD, PRESS_RELEIF),
        text: 'pressure relief',
        values: [
            {
                id: idb.getNBOptionValueId(RD, PRESS_RELEIF),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RD, PRESS_RELEIF, 1),
                        text: 'pressure relief',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertDeficitsProperties.PRESSURE_RELIEF,
    }

    const TRANSFER_SAFELY = 'TRANSFER_SAFELY';
    const transferSafelyBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RD, TRANSFER_SAFELY),
        text: 'transfer safety',
        values: [
            {
                id: idb.getNBOptionValueId(RD, TRANSFER_SAFELY),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RD, TRANSFER_SAFELY, 1),
                        text: 'transfer safety',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertDeficitsProperties.TRANSFER_SAFETY,
    }

    const SUSTAIN_POS = 'SUSTAIN_POS';
    const sustainPosBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RD, SUSTAIN_POS),
        text: 'sustain position',
        values: [
            {
                id: idb.getNBOptionValueId(RD, SUSTAIN_POS),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RD, SUSTAIN_POS, 1),
                        text: 'sustain position',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertDeficitsProperties.SUSTAIN_POS,
    }

    const TRANSFERS = 'TRANSFERS';
    const transfersBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RD, TRANSFERS),
        text: 'transfers',
        values: [
            {
                id: idb.getNBOptionValueId(RD, TRANSFERS),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RD, TRANSFERS, 1),
                        text: 'transfers',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertDeficitsProperties.TRANSFERS,
    }

    const SKIN_INTEGRITY = 'SKIN_INTEGRITY';
    const skinIntegrityBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RD, SKIN_INTEGRITY),
        text: 'skin integrity',
        values: [
            {
                id: idb.getNBOptionValueId(RD, SKIN_INTEGRITY),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RD, SKIN_INTEGRITY, 1),
                        text: 'skin integrity',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertDeficitsProperties.SKIN_INTEGRITY,
    }

    const GAIT = 'GAIT';
    const gaitBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RD, GAIT),
        text: 'gait',
        values: [
            {
                id: idb.getNBOptionValueId(RD, GAIT),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RD, GAIT, 1),
                        text: 'gait',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertDeficitsProperties.GAIT,
    }

    const BEDMOB = 'BEDMOB';
    const bedmobBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RD, BEDMOB),
        text: 'bed mob',
        values: [
            {
                id: idb.getNBOptionValueId(RD, BEDMOB),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RD, BEDMOB, 1),
                        text: 'bed mob',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertDeficitsProperties.BED_MOD,
    }

    const HYGIENE = 'HYGIENE';
    const hygieneBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RD, HYGIENE),
        text: 'hygiene',
        values: [
            {
                id: idb.getNBOptionValueId(RD, HYGIENE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RD, HYGIENE, 1),
                        text: 'hygiene',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertDeficitsProperties.HYGIENE,
    }

    const GAIT_SAFETY = 'GAIT_SAFETY';
    const gaitSafetyBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RD, GAIT_SAFETY),
        text: 'gait safety',
        values: [
            {
                id: idb.getNBOptionValueId(RD, GAIT_SAFETY),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RD, GAIT_SAFETY, 1),
                        text: 'gait safety',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: RecertDeficitsProperties.GAIT_SAFETY,
    }

    const nbl: NarrativeBuilderList = {
        options: {
            [RecertDeficitsProperties.WT_BEARING_POTENTIAL]: wtBearBuilderOption,
            [RecertDeficitsProperties.POSITIONAL_ACCUR]: posAccBuilderOption,
            [RecertDeficitsProperties.PRESSURE_RELIEF]: pressReliefBuilderOption,
            [RecertDeficitsProperties.TRANSFER_SAFETY]: transferSafelyBuilderOption,
            [RecertDeficitsProperties.SUSTAIN_POS]: sustainPosBuilderOption,
            [RecertDeficitsProperties.TRANSFERS]: transfersBuilderOption,
            [RecertDeficitsProperties.SKIN_INTEGRITY]: skinIntegrityBuilderOption,
            [RecertDeficitsProperties.GAIT]: gaitBuilderOption,
            [RecertDeficitsProperties.BED_MOD]: bedmobBuilderOption,
            [RecertDeficitsProperties.HYGIENE]: hygieneBuilderOption,
            [RecertDeficitsProperties.GAIT_SAFETY]: gaitSafetyBuilderOption,
        }
    }

    return nbl;
}

function getSTRecertDeficits(): NarrativeBuilderList {
    const nbl: NarrativeBuilderList = {
        options: {
            [RecertDeficitsProperties.EFFECT_OF_COUGH]: {
                id: RecertDeficitsProperties.EFFECT_OF_COUGH,
                text: 'Effectiveness of cough',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.EFFECT_OF_COUGH),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.EFFECT_OF_COUGH, 1),
                                text: 'Effectiveness of cough',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.EFFECT_OF_COUGH,
            },
            [RecertDeficitsProperties.MASTICATION]: {
                id: RecertDeficitsProperties.MASTICATION,
                text: 'Mastication',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.MASTICATION),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.MASTICATION, 1),
                                text: 'Mastication',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.MASTICATION,
            },
            [RecertDeficitsProperties.DIET_TOLERANCE]: {
                id: RecertDeficitsProperties.DIET_TOLERANCE,
                text: 'Diet tolerance',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.DIET_TOLERANCE),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.DIET_TOLERANCE, 1),
                                text: 'Diet tolerance',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.DIET_TOLERANCE,
            },
            [RecertDeficitsProperties.ORAL_INTAKE_SAFETY]: {
                id: RecertDeficitsProperties.ORAL_INTAKE_SAFETY,
                text: 'Oral intake safety',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.ORAL_INTAKE_SAFETY),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.ORAL_INTAKE_SAFETY, 1),
                                text: 'Oral intake safety',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.ORAL_INTAKE_SAFETY,
            },
            [RecertDeficitsProperties.SWALLOW_SAFETY]: {
                id: RecertDeficitsProperties.SWALLOW_SAFETY,
                text: 'Swallow safety',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.SWALLOW_SAFETY),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.SWALLOW_SAFETY, 1),
                                text: 'Swallow safety',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.SWALLOW_SAFETY,
            },
            [RecertDeficitsProperties.ASPIRATION_RISK]: {
                id: RecertDeficitsProperties.ASPIRATION_RISK,
                text: 'Aspiration risk',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.ASPIRATION_RISK),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.ASPIRATION_RISK, 1),
                                text: 'Aspiration risk',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.ASPIRATION_RISK,
            },
            [RecertDeficitsProperties.SWALLOW_COMP_STRATS]: {
                id: RecertDeficitsProperties.SWALLOW_COMP_STRATS,
                text: 'Swallow comp strats',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.SWALLOW_COMP_STRATS),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.SWALLOW_COMP_STRATS, 1),
                                text: 'Swallow comp strats',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.SWALLOW_COMP_STRATS,
            },
            [RecertDeficitsProperties.BOLUS_FORM]: {
                id: RecertDeficitsProperties.BOLUS_FORM,
                text: 'Bolus form/mastication',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.BOLUS_FORM),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.BOLUS_FORM, 1),
                                text: 'Bolus form/mastication',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.BOLUS_FORM,
            },
            [RecertDeficitsProperties.AIRWAY_PROTECT]: {
                id: RecertDeficitsProperties.AIRWAY_PROTECT,
                text: 'Airway protection',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.AIRWAY_PROTECT),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.AIRWAY_PROTECT, 1),
                                text: 'Airway protection',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.AIRWAY_PROTECT,
            },
            [RecertDeficitsProperties.I_SAFETY_MEALS]: {
                id: RecertDeficitsProperties.I_SAFETY_MEALS,
                text: '(I)/safety with meals',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.I_SAFETY_MEALS),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.I_SAFETY_MEALS, 1),
                                text: '(I)/safety with meals',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.I_SAFETY_MEALS,
            },
            [RecertDeficitsProperties.SWALLOW_INIT]: {
                id: RecertDeficitsProperties.SWALLOW_INIT,
                text: 'Swallow initiation',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.SWALLOW_INIT),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.SWALLOW_INIT, 1),
                                text: 'Swallow initiation',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.SWALLOW_INIT,
            },
            [RecertDeficitsProperties.ORAL_SENSATION]: {
                id: RecertDeficitsProperties.ORAL_SENSATION,
                text: 'Oral sensation',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.ORAL_SENSATION),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.ORAL_SENSATION, 1),
                                text: 'Oral sensation',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.ORAL_SENSATION,
            },
            [RecertDeficitsProperties.POCKETING]: {
                id: RecertDeficitsProperties.POCKETING,
                text: 'Pocketing',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.POCKETING),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.POCKETING, 1),
                                text: 'Pocketing',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.POCKETING,
            },
            [RecertDeficitsProperties.TONGUE_MOB_ROM]: {
                id: RecertDeficitsProperties.TONGUE_MOB_ROM,
                text: 'Tongue mob/ROM',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.TONGUE_MOB_ROM),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.TONGUE_MOB_ROM, 1),
                                text: 'Tongue mob/ROM',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.TONGUE_MOB_ROM,
            },
            [RecertDeficitsProperties.VC_CLOSURE]: {
                id: RecertDeficitsProperties.VC_CLOSURE,
                text: 'VC closure',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.VC_CLOSURE),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.VC_CLOSURE, 1),
                                text: 'VC closure',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.VC_CLOSURE,
            },
            [RecertDeficitsProperties.SPEECH_INTEL]: {
                id: RecertDeficitsProperties.SPEECH_INTEL,
                text: 'Speech intel',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.SPEECH_INTEL),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.SPEECH_INTEL, 1),
                                text: 'Speech intel',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.SPEECH_INTEL,
            },
            [RecertDeficitsProperties.THOUGHT_ORG]: {
                id: RecertDeficitsProperties.THOUGHT_ORG,
                text: 'Thought organization',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.THOUGHT_ORG),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.THOUGHT_ORG, 1),
                                text: 'Thought organization',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.THOUGHT_ORG,
            },
            [RecertDeficitsProperties.EXPRESSION]: {
                id: RecertDeficitsProperties.EXPRESSION,
                text: 'Expression',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.EXPRESSION),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.EXPRESSION, 1),
                                text: 'Expression',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.EXPRESSION,
            },
            [RecertDeficitsProperties.VERBAL_EXPRESSION]: {
                id: RecertDeficitsProperties.VERBAL_EXPRESSION,
                text: 'Verbal expression',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.VERBAL_EXPRESSION),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.VERBAL_EXPRESSION, 1),
                                text: 'Verbal expression',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.VERBAL_EXPRESSION,
            },
            [RecertDeficitsProperties.WORD_FINDING]: {
                id: RecertDeficitsProperties.WORD_FINDING,
                text: 'Word finding',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.WORD_FINDING),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.WORD_FINDING, 1),
                                text: 'Word finding',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.WORD_FINDING,
            },
            [RecertDeficitsProperties.QUALITY_OF_RESPONSE]: {
                id: RecertDeficitsProperties.QUALITY_OF_RESPONSE,
                text: 'Quality of response',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.QUALITY_OF_RESPONSE),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.QUALITY_OF_RESPONSE, 1),
                                text: 'Quality of response',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.QUALITY_OF_RESPONSE,
            },
            [RecertDeficitsProperties.COMMUNICATION]: {
                id: RecertDeficitsProperties.COMMUNICATION,
                text: 'Communication',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.COMMUNICATION),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.COMMUNICATION, 1),
                                text: 'Communication',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.COMMUNICATION,
            },
            [RecertDeficitsProperties.SAFETY_AWARENESS]: {
                id: RecertDeficitsProperties.SAFETY_AWARENESS,
                text: 'Safety awareness',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.SAFETY_AWARENESS),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.SAFETY_AWARENESS, 1),
                                text: 'Safety awareness',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.SAFETY_AWARENESS,
            },
            [RecertDeficitsProperties.COMPREHENSION]: {
                id: RecertDeficitsProperties.COMPREHENSION,
                text: 'Comprehension',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.COMPREHENSION),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.COMPREHENSION, 1),
                                text: 'Comprehension',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.COMPREHENSION,
            },
            [RecertDeficitsProperties.AUDITORY_COMPREHENSION]: {
                id: RecertDeficitsProperties.AUDITORY_COMPREHENSION,
                text: 'Auditory comprehension',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.AUDITORY_COMPREHENSION),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.AUDITORY_COMPREHENSION, 1),
                                text: 'Auditory comprehension',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.AUDITORY_COMPREHENSION,
            },
            [RecertDeficitsProperties.VOICE_MODULATION]: {
                id: RecertDeficitsProperties.VOICE_MODULATION,
                text: 'Voice modulation',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.VOICE_MODULATION),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.VOICE_MODULATION, 1),
                                text: 'Voice modulation',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.VOICE_MODULATION,
            },
            [RecertDeficitsProperties.DECISION_MAKING]: {
                id: RecertDeficitsProperties.DECISION_MAKING,
                text: 'Decision making',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.DECISION_MAKING),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.DECISION_MAKING, 1),
                                text: 'Decision making',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.DECISION_MAKING,
            },
            [RecertDeficitsProperties.REDUCE_CONFUSION]: {
                id: RecertDeficitsProperties.REDUCE_CONFUSION,
                text: 'Reduce confusion',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.REDUCE_CONFUSION),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.REDUCE_CONFUSION, 1),
                                text: 'Reduce confusion',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.REDUCE_CONFUSION,
            },
            [RecertDeficitsProperties.INDEPENDENCE]: {
                id: RecertDeficitsProperties.INDEPENDENCE,
                text: 'Independence',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.INDEPENDENCE),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.INDEPENDENCE, 1),
                                text: 'Independence',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.INDEPENDENCE,
            },
            [RecertDeficitsProperties.ORIENTATION]: {
                id: RecertDeficitsProperties.ORIENTATION,
                text: 'Orientation',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.ORIENTATION),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.ORIENTATION, 1),
                                text: 'Orientation',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.ORIENTATION,
            },
            [RecertDeficitsProperties.ENVIRONMENT]: {
                id: RecertDeficitsProperties.ENVIRONMENT,
                text: 'Environment',
                values: [
                    {
                        id: idb.getNBOptionValueId(RD, RecertDeficitsProperties.ENVIRONMENT),
                        optionValues: [
                            {
                                id: idb.getNBOptionValueTextId(RD, RecertDeficitsProperties.ENVIRONMENT, 1),
                                text: 'Environment',
                            }
                        ]
                    }
                ],
                selectedValue: null,
                property: RecertDeficitsProperties.ENVIRONMENT,
            },
        }
    }

    return nbl;
}