import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { DenialClaim } from '@app/model';

export interface DenialClaimState extends EntityState<DenialClaim>, ActiveState {}

const initialState: DenialClaimState = {
  active: null,
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'denial-claims' })
export class DenialClaimStore extends EntityStore<DenialClaimState> {

  constructor() {
    super(initialState);
  }

}