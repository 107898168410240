import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { CacheManService, DataListConstants } from '@app/core';
import { DataListItem, Facility, SignatureNeeded } from '@app/model';
import { modalOptions, modalXLargeOption, PatientClinicalDocTypeUtils } from '@app/utils';
import { TmsDocumentViewerModalComponent } from '../tms-document-viewer-modal';

interface SignatureNeededUI extends SignatureNeeded {
  errorSigning: boolean;
  signed: boolean;
}
 
@Component({
  selector: 'tms-multi-documents-modal',
  templateUrl: './tms-multi-documents-modal.component.html',
  styleUrls: ['./tms-multi-documents-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TmsMultiDocumentsModalComponent implements OnInit {
  @Input() pinCodeRequired: boolean = false;
  @Input() unsignedDocuments: SignatureNeeded[] = [];

  disciplines$: Observable<DataListItem[]> = null;
  facilities$: Observable<Facility[]> = null;
  unsignedDocumentsUI: SignatureNeededUI[] = [];

  constructor(private cdr: ChangeDetectorRef,
    private modalService: NgbModal, 
    private activeModal: NgbActiveModal,
    private cacheManService: CacheManService) { }

  ngOnInit() {
    this.disciplines$ = this.cacheManService.getDataListItems$(DataListConstants.DISCIPLINES);
    this.facilities$ = this.cacheManService.getUserFacilities();
    this.unsignedDocumentsUI = this.unsignedDocuments.map(document => { return { ...document, errorSigning: false, signed: null } });
  }

  calculatePatientName(rowData) {
    return rowData.patientLastName + ', ' + rowData.patientFirstName;
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  onSubmit(e) {
    this.activeModal.close();
  }

  onRowClicked(e) {
    if (!e.data.signed) {
      const modalRef = this.modalService.open(TmsDocumentViewerModalComponent, { ...modalOptions, ...modalXLargeOption });
      modalRef.componentInstance.documentRecordId = e.data.documentRecordId;
      modalRef.componentInstance.viewOnly = false;
      modalRef.componentInstance.pinCodeRequired = this.pinCodeRequired;
      modalRef.result.then(
        (onfullfilled) => {
          // update grid
          this.updateUnsignedGrid(onfullfilled);
        },
        (onrejected) => {
          this.updateUnsignedGrid(onrejected);
        }
      )
    }
  }

  calculateDocumentTypeName(data) {
    return PatientClinicalDocTypeUtils.enumToString(data.value);
  }

  onViewDocumentClicked(data: SignatureNeededUI) {
    if (data.signed) {
      const modalRef = this.modalService.open(TmsDocumentViewerModalComponent, { ...modalOptions, ...modalXLargeOption });
      modalRef.componentInstance.documentRecordId = data.documentRecordId;
    }
  }

  private updateUnsignedGrid(result) {
    const index = this.unsignedDocumentsUI.findIndex(document => document.documentRecordId == result.documentRecordId );
    if (index >= 0) {
      if (result.success) {
        this.unsignedDocumentsUI[index].signed = true;
        this.unsignedDocumentsUI[index].documentRecordId = result.newDocumentRecordId;
      } else {
        this.unsignedDocumentsUI[index].errorSigning = true;
      }
      this.cdr.detectChanges();
    }
  }
}
