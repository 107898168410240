import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { NarrativeBuilderOption, NarrativeBuilderOptionValue, NBComponentValue } from '@app/model';
import { NarrativeBuilderUtils } from '@app/utils/narrative-builder.utils';

@Component({
    selector: 'tms-narrative-builder-dropdown',
    templateUrl: './narrative-builder-dropdown.component.html',
    styleUrls: ['../narrative-builder.scss'],
})
export class NarrativeBuilderDropdownComponent implements OnInit {
    @Input() acceptCustomValue?: boolean = false;
    @Input() alertUser?: boolean = false;
    @Input() optionValue: NarrativeBuilderOption;
    @Input() width: string;
    @Output() onValueChanged: EventEmitter<NBComponentValue> = new EventEmitter<NBComponentValue>();

    nbValues: NarrativeBuilderOptionValue[] = []; // use separate property so we can add custom text to datasource
    selectedValue = null; // use separate property to set value instead of two way binding so we don't change optionValue reference
    private overrideManualEdits: boolean = null;

    constructor() {
    }

    ngOnInit() {
        this.nbValues = this.optionValue.values;
        if (this.optionValue.selectedValue) {
            const index = NarrativeBuilderUtils.findNarrativeBuilderOptionValueIndex(this.optionValue.selectedValue as string, this.optionValue.values)
            if (index >= 0) {
                this.selectedValue = this.optionValue.values[index];
            }
            if (index < 0 && this.acceptCustomValue) {
                // custom text
                const customOption: NarrativeBuilderOptionValue = {
                    id: 'CUSTOM',
                    text: this.optionValue.selectedValue as string,
                    optionValues: [
                        {
                            id: '',
                            text: this.optionValue.selectedValue as string,
                        }
                    ]
                }
                this.nbValues.push(customOption);
                this.selectedValue = customOption;
                this.onValueChanged.emit({
                    optionValues: [customOption],
                    optionValuesIndex: -1,
                    optionValueTextIndex: 0,
                    property: this.optionValue.property,
                    overrideManualEdits: this.overrideManualEdits,
                    customValue: true,
                })
            }
        }
    }

    onCustomItemCreating(e) {
        const customOption: NarrativeBuilderOptionValue = {
            id: 'CUSTOM',
            text: e.text,
            optionValues: [
                {
                    id: '',
                    text: e.text,
                }
            ]
        }
        e.customItem = customOption;
        this.onValueChanged.emit({
            optionValues: [customOption],
            optionValuesIndex: -1,
            optionValueTextIndex: 0,
            property: this.optionValue.property,
            overrideManualEdits: this.overrideManualEdits,
            customValue: true,
        })
    }

    onOverrideManualEdits(e: boolean) {
        this.overrideManualEdits = e;
    }

    onSelectBoxValueChanged($event) {
        const nbov = $event.selectedItem as NarrativeBuilderOptionValue;

        if (nbov && nbov.id === 'CUSTOM') {
            return;
        }

        // we're unselecting/removing from the ddl
        if (nbov == null) {
            this.onValueChanged.emit({
                optionValues: this.optionValue.values,
                optionValuesIndex: null,
                optionValueTextIndex: 0,
                property: this.optionValue.property,
                overrideManualEdits: this.overrideManualEdits,
            });
            return;
        }

        // nIndex will always be 0 since there is currently no randomization
        // const nIndex = NarrativeBuilderUtils.randomNarrativeBuildOptionValueTextIndex(nbov.optionValues);
        const nOptionValueIndex = NarrativeBuilderUtils.findNarrativeBuilderOptionValueIndex(nbov.id, this.optionValue.values);
        this.onValueChanged.emit({
            optionValues: this.optionValue.values,
            optionValuesIndex: nOptionValueIndex,
            optionValueTextIndex: 0,
            property: this.optionValue.property,
            overrideManualEdits: this.overrideManualEdits,
        });
    }
}
