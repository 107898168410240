import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Client } from '@app/model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClientsState extends EntityState<Client>, ActiveState {
    // can extend properties if needed
}

const initialState: ClientsState = {
    active: null,
    loading: false,
}

@Injectable({providedIn: 'root'})
@StoreConfig({ name: 'clients' })
export class ClientsStore extends EntityStore<ClientsState> {
    constructor() {
        super(initialState);
    }
}
