<div class="row">
    <div class="col-3">
        <dx-text-box placeholder="Search" mode="search" [(value)]="searchKey" valueChangeEvent="keyup"
        (onValueChanged)="searchValueChanged($event)" [showClearButton]="true"></dx-text-box>
    </div>
    <div class="col"></div>
</div>
<hr>
<div class="row tms-row-divider">
    <div class="col-12">
        <div *ngFor="let key of keys">
            <tms-collapse-panel [panelTitle]="key" [panelCollapsed]="searchKey === ''" class="doc-group-panel">
                <hr>
                <div *ngFor="let report of groupedItems[key]">
                    <div class="row tms-row-divider">
                        <div class="col-4">
                            <a href="javascript:void(0)" (click)="openDocumentItem(report)" class="report-title">{{ report.name }}</a>
                        </div>
                        <div *ngIf="showDescription" class="col">
                            {{ report.description }}
                        </div>
                        <div *ngIf="canEditItems" class="col-1">
                            <dx-button class="tms-edit-icon-button" icon="fas fa-edit" (onClick)="onEditDocDashboardItem(report)" hint="Edit">
                            </dx-button>
                            <dx-button class="tms-submit-button tms-edit-icon-button" icon="fas fa-trash" (onClick)="onDeleteDocDashboardItem(report)" hint="Delete">
                            </dx-button>
                        </div>
                    </div>
                </div>
            </tms-collapse-panel>
            <hr>
        </div>
    </div>
</div>
