import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from './url-constants.service';
import { OperationResponse, PagedResult, ScannedDocument } from '@app/model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScannedFilesService {

  constructor(private httpClient: HttpClient,
    private urlConstantsService: UrlConstantsService) { }

  getScannedItemsByFacilityId(facilityId: string): Observable<ScannedDocument[]> {
    return this.httpClient.get<PagedResult<ScannedDocument>>(`${this.urlConstantsService.SCANNED_ITEMS_URL}?facilityId=${facilityId}`)
      .pipe(map(response => response.items));
  }

  updateScannedDocument(scannedDocument: ScannedDocument): Observable<ScannedDocument> {
    return this.httpClient.put<OperationResponse<ScannedDocument>>(`${this.urlConstantsService.SCANNED_ITEMS_URL}/${scannedDocument.id}`, scannedDocument)
      .pipe(map(response => response.data));
  }

  deleteScannedDocument(scannedDocumentId: string) {
    return this.httpClient.delete<boolean>(`${this.urlConstantsService.SCANNED_ITEMS_URL}/${scannedDocumentId}`);
  }
}
