import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DocumentRecordsStore, DocumentRecordsState } from './document-records.store';

@Injectable({
  providedIn: 'root',
})
export class DocumentRecordsQuery extends QueryEntity<DocumentRecordsState> {

  constructor(protected store: DocumentRecordsStore) {
    super(store);
  }

}
