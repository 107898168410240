export class UserRoleConstants  {
    static ADMINISTRATOR: string = 'Admin';
    static AREA_MANAGER: string = 'AreaManager';
    static ASSISTANT_TCN: string = 'AssistantTCN';
    static CLINICAL_USER: string = 'ClinicalUser';
    static CODING_STAFF: string = 'CodingStaff';
    static CODING_SUPERVISOR: string = 'CodingSupervisor';
    static CODING_MANAGER: string = 'CodingManager';
    static DENIAL_MANAGEMENT_MANAGER: string = 'DenialManagementManager';
    static DENIAL_MANAGEMENT_STAFF: string =  'DenialManagementStaff';
    static DENIAL_MANAGEMENT_SUPERVISOR: string = 'DenialManagementSupervisor';
    static IGNITE_ADMIN_MANAGER: string = 'IgniteAdminManager';
    static IGNITE_ADMIN_STAFF: string = 'IgniteAdminStaff';
    static IGNITE_ADMIN_SUPERVISOR: string = 'IgniteAdminSupervisor';
    static RECRUITER: string = 'Recruiter';
    static REGIONAL_MANAGER: string = 'RegionalManager';
    static REHAB_COORDINATOR: string = 'RehabCoordinator';
    static RESTORATIVE_AIDE: string = 'RestorativeAide';
    static TCN: string = 'TCN';
    static TMC_BILLING_MANAGER: string = 'TMCBillingManager';
    static TMC_BILLING_STAFF: string = 'TMCBillingStaff';
    static TMC_BILLING_SUPERVISOR: string = 'TMCBillingSupervisor';
}