import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { CacheManService, DocumentRecordsService, KnowledgeBaseService, ToastMessageService, UIPermissionConstants } from '@app/core';
import { KnowledgeBaseItem } from '@app/model';
import { TmsVideoViewerModalComponent } from '@app/shared';
import { modalOptions, modalLargeOption, SortUtils } from '@app/utils';
import { KnowledgeBaseAddItemComponent } from './knowledge-base-add-item/knowledge-base-add-item.component';

@Component({
  selector: 'tms-knowledge-base',
  templateUrl: './knowledge-base.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnowledgeBaseComponent implements OnInit {
  items: KnowledgeBaseItem[] = null;
  canEditItems$: Observable<boolean> = null;
  topFiveKbItems: KnowledgeBaseItem[] = [];

  get canAddKnowledgeItem() {
    return UIPermissionConstants.KNOWLEDGE_BASE;
  }

  get searchByTerms(): string[] {
    return ['name', 'description', 'categoryName', 'keywordsString', 'primaryKeywordsString'];
  }

  constructor(private cacheManService: CacheManService,
    private cdr: ChangeDetectorRef,
    private documentRecordsService: DocumentRecordsService,
    private knowledgeBaseService: KnowledgeBaseService,
    private modalService: NgbModal,
    private toastMessageService: ToastMessageService) { }

  ngOnInit() {
    this.canEditItems$ = this.cacheManService.getUserHasPermission$(UIPermissionConstants.KNOWLEDGE_BASE);
    this.getKnowledgeBaseItems();
  }

  onAddItemClicked() {
    const modalRef = this.modalService.open(KnowledgeBaseAddItemComponent, { ...modalOptions});
    modalRef.result.then(() => this.getKnowledgeBaseItems(), () => {});
  }

  onItemEdit(e) {
    const modalRef = this.modalService.open(KnowledgeBaseAddItemComponent, { ...modalOptions});
    modalRef.componentInstance.kbItem = e;
    modalRef.result.then(() => this.getKnowledgeBaseItems(), () => {});
  }

  onItemDelete(e) {
    this.knowledgeBaseService.deleteKnowledgeBaseItem(e)
      .subscribe(() => {
        this.toastMessageService.successNotification('Successfully deleted knowledge base item');
        this.getKnowledgeBaseItems();
      }, error => this.toastMessageService.errorNotification('An error occurred when trying to delete knowledge base item.'));;
  }
 
  onItemValueChanged(e) {
    if (e.videoSrc) {
      const modalRef = this.modalService.open(TmsVideoViewerModalComponent, { ...modalOptions, ...modalLargeOption });
      modalRef.componentInstance.videoSrc = e.videoSrc;
    } else {
      this.documentRecordsService.openDocument(e.documentRecordId).subscribe(() => {}, error => {});
    }
  }

  private getKnowledgeBaseItems() {
    this.knowledgeBaseService.getKnowledgeBaseItems()
    .subscribe(items => {
      this.items = items;
      this.topFiveKbItems = SortUtils.orderByDateDesc(this.items, 'popularityCount').slice(0, 5);
      this.cdr.detectChanges();
    });
  }

}
