export enum AssessmentType {
    Unspecified = 0,
    FiveDay = 1,
    IPA = 2,
    FourteenDay = 3,
    ThirtyDay = 4,
    SixtyDay = 5,
    NinetyDay = 6,
    COT = 7,
    EOT = 8,
}