import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Dictionary, DocumentRecord, ElectronicChartRequest, ElectronicChartDashboard, ElectronicChartRecord, OperationResponse, KeyValuePair } from '@app/model';
import { QueryStringUtils } from '@app/utils';
import { DocumentRecordsService } from './document-records.service';
import { UrlConstantsService } from './url-constants.service';

@Injectable({
  providedIn: 'root'
})
export class ElectronicChartsService {

  constructor(private documentRecordsService: DocumentRecordsService,
    private httpClient: HttpClient,
    private urlConstantsService: UrlConstantsService) { }

  getElectronicCharts(request: ElectronicChartRequest): Observable<ElectronicChartDashboard> {
    return this.httpClient.get<OperationResponse<ElectronicChartDashboard>>(`${this.urlConstantsService.ELECTRONIC_CHARTS_URL}?${QueryStringUtils.queryStringBuilder(request)}`)
      .pipe(map(response => response.data));
  }

  getElectronicChartById(patientCaseId: string): Observable<ElectronicChartRecord> {
    return this.httpClient.get<OperationResponse<ElectronicChartRecord>>(`${this.urlConstantsService.ELECTRONIC_CHARTS_URL}/${patientCaseId}`)
    .pipe(map(response => response.data)); 
  }


  getElectronicChartsUploadSubCategories(): Dictionary<KeyValuePair<string, string>[]> {
    return {
      PhysicianOrders: [
        {
          key: 'EvaluationOrder',
          value: 'Evaluation Order',
        },
        {
          key: 'ClarificationOrder',
          value: 'Clarification Order',
        },
        {
          key: 'DischargeOrder',
          value: 'Discharge Order',
        },
        {
          key: 'OtherOrder',
          value: 'Other Order',
        },
      ],
      Certifications: [
        {
          key: '700Evaluation',
          value: '700/Evaluation',
        },
        {
          key: 'Recert701',
          value: 'Rec/Recertification/701',
        }
      ],
      Nursing: [
        {
          key: '5Day',
          value: '5 Day',
        },
        {
          key: '14Day',
          value: '14 Day',
        },
        {
          key: '30Day',
          value: '30 Day',
        },
        {
          key: '60Day',
          value: '60 Day',
        },
        {
          key: '90Day',
          value: '90 Day',
        },
        {
          key: 'OffCycleMDS',
          value: 'Off Cycle MDS',
        },
        {
          key: 'SkilledNursingNotes',
          value: 'Skilled Nursing Notes',
        },
        {
          key: 'PhysicianProgressNote',
          value: 'Physician Progress Note',
        },
      ],
      StandardizedTesting: [],
      EChartOther: [
        {
          key: 'Soft Chart Audit',
          value: 'Soft Chart Audit'
        },
        {
          key: 'Exercise Flow Sheets',
          value: 'Exercise Flow Sheets',
        },
        {
          key: 'Intake Forms',
          value: 'Intake Forms',
        },
        {
          key: 'Payer Change',
          value: 'Payer Change',
        },
        {
          key: 'Proof of Authorization',
          value: 'Proof of Authorization',
        },
        {
          key: 'Coding Packet',
          value: 'Coding Packet',
        },
        {
          key: 'Other',
          value: 'Other',
        },
      ],
    }
  }

  deleteEChartDocument(documentRecordId: string, patientId: string) {
    return this.documentRecordsService.deleteDocument(documentRecordId, patientId);
  }

  downloadEChartPacket(patientCaseId: string): Observable<string> {
    return this.httpClient.get<OperationResponse<any>>(`${this.urlConstantsService.ELECTRONIC_CHARTS_URL}/packet/${patientCaseId}`)
      .pipe(map(response => response.data.downloadUrl));
  }

  uploadEChartDocument(patientId: string, fileName: string, friendlyName: string, documentCategoryId: string, 
    documentDate: Date, patientCaseId: string, file: any, facilityId: string): Observable<DocumentRecord> {
      const documentRecord: DocumentRecord = {
        patientId: patientId,
        fileName: fileName,
        friendlyName: friendlyName,
        documentRecordCategoryId: documentCategoryId,
        documentDate: documentDate,
        patientCasedId: patientCaseId,
        facilityId: facilityId,
        isEChartFile: true,
      };
      return this.documentRecordsService.uploadDocumentRecord(documentRecord, file);
  }
}
