import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, interval, merge, Subscription } from 'rxjs';

const FIVE_MINUTES_IN_MS = 1000 * 60 * 5;

@Injectable({
  providedIn: 'root'
})
export class UserAutoLogoutService {
  // notifies if user is idle
  userIsIdle$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private lastUserActiveTime: number = null;
  // track user's activity
  private userActivity$: Subscription = null;
  // check user's activty 
  private userIdleTimer$: Subscription = null;

  constructor() { }

  cleanUp() {
    this.userActivity$.unsubscribe();
    this.userIdleTimer$.unsubscribe();
  }

  startUserIdleTimer(idleTimeMs: number = FIVE_MINUTES_IN_MS) {
    this.userActivity$ = merge(fromEvent(document, 'click'), 
          fromEvent(document, 'mousemove'),
          fromEvent(document, 'keydown'))
      .subscribe(event => {
          this.lastUserActiveTime = Date.now();
        });

    // check user's activity 
    this.userIdleTimer$ = interval(idleTimeMs/2)
      .subscribe(() => {
        const elapsedIdleTime = Date.now() - this.lastUserActiveTime;
        if (elapsedIdleTime > idleTimeMs) {
          // user is idle. 
          this.userIsIdle$.next(true);
        } else {
          this.userIsIdle$.next(false);
        } 
      });
  }

  resetUserIdleTimer() {
   this.lastUserActiveTime = Date.now();
  }

}
