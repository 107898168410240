import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'tms-loader',
    styleUrls: [ './loader.componet.scss'],
    template:
    `<div class="loader">Loading...</div>`,
})
export class LoaderComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}
