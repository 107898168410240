import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy, ViewChild, OnDestroy } from '@angular/core';
import { DxTextBoxComponent } from 'devextreme-angular';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { OnDestroyComponent } from '../on-destroy/on-destroy.component';
import { KeyEventUtils } from '@app/utils';

@Component({
  selector: 'tms-typeahead-textbox',
  templateUrl: './tms-typeahead-textbox.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TmsTypeaheadTextboxComponent extends OnDestroyComponent implements OnInit, OnDestroy {
  @Input() placeholderText?: string = '';
  @Input() required: boolean = false;
  @Input() requiredMessage: string = 'Value is required';
  @Input() timeout: number = 1000;
  @Input() value: string = null;

  @Output() onValueChanged: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('typeAheadTextBox') typeAheadTextBox: DxTextBoxComponent;

  private textChanged$: Subject<string> = new Subject<string>();

  constructor() {
    super();
  }

  ngOnInit() {
    this.textChanged$
    .pipe(takeUntil(this.onDestroy$),
          debounceTime(this.timeout),
          distinctUntilChanged(),
          filter(text => text.length >= 2 || text.length == 0))
    .subscribe(text => {
      this.onValueChanged.emit(text);
    });
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  onTypeAheadKeyUp(e) {
    let keyCode = e.event.keyCode;
    let validKey = KeyEventUtils.validLetterOrNumberKey(keyCode);

    // Prevent enter and other keys from interferring with the search
    if (!validKey) {
        return;
    }

    this.textChanged$.next(this.typeAheadTextBox.instance.option('text'));
  }

  onTypeAheadContentReady(e) {
    var clearButton = e.element.querySelector('.dx-icon-clear');
    if (clearButton) {
      clearButton.addEventListener('click', e => this.onValueChanged.emit(null));
    }
  }

  validateTypeAheadCallback = (e) => {
    if (this.required) {
      return !!e.value;
    } else {
      return true;
    }
  }

}
