import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'tms-page-heading',
  templateUrl: './page-heading.component.html',
  styleUrls: ['./page-heading.component.scss'],
})
export class PageHeadingComponent implements OnInit {

  @Input() title: string = '';
  @Input() showBackButton: boolean = false;
  @Input() backButtonText: string = 'Back';
  @Output() onBackButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  onBackButton() {
    this.onBackButtonClicked.emit();
  }

}
