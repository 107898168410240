import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { PagedResult, OperationResponse, UnlockFacilityRequest, UnlockTreatmentRequest } from '@app/model';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class UnlockTreatmentRequestService {
	constructor(private http: HttpClient,
		private urlConstantsService: UrlConstantsService) {
    }

    getUnlockTreatmentRequestAdmin(id: string, status: string, month: number, year: number) {
        return this.http.get<OperationResponse<UnlockTreatmentRequest[]>>(`${this.urlConstantsService.UNLOCK_TREATMENTS_URL}?facilityId=${id}&status=${status}&month=${month}&year=${year}`)
            .pipe(map(response => response.data));
    }
   
    approveTreatmentRequest(id: string, request: UnlockTreatmentRequest) {
        return this.http.post<OperationResponse<UnlockTreatmentRequest>>(`${this.urlConstantsService.UNLOCK_TREATMENTS_URL}/${id}/approve`, request)
            .pipe(map(response => response.data));
    }

    denyTreatmentRequest(id: string, request: UnlockTreatmentRequest) {
        return this.http.post<OperationResponse<UnlockTreatmentRequest>>(`${this.urlConstantsService.UNLOCK_TREATMENTS_URL}/${id}/deny`, request)
            .pipe(map(response => response.data));
    }

    getUnlockTreatmentRequest(id: string) {
        return this.http.get<OperationResponse<UnlockTreatmentRequest>>(`${this.urlConstantsService.UNLOCK_TREATMENTS_URL}/${id}`)
            .pipe(map(response => response.data));
    }

    addNewUnlockTreatmentRequest(request: UnlockTreatmentRequest) {
        return this.http.post<OperationResponse<UnlockTreatmentRequest>>(`${this.urlConstantsService.UNLOCK_TREATMENTS_URL}/add`, request)
            .pipe(map(response => response.data));
    }

    updateUnlockTreatmentRequest(id: string, request: UnlockTreatmentRequest) {
        return this.http.put<OperationResponse<UnlockTreatmentRequest>>(`${this.urlConstantsService.UNLOCK_TREATMENTS_URL}/edit/${id}`, request)
            .pipe(map(response => response.data));
    }

    deleteUnlockTreatmentRequest(id: string) {
        return this.http.delete<OperationResponse<boolean>>(`${this.urlConstantsService.UNLOCK_TREATMENTS_URL}/${id}`)
            .pipe(map(response => response.data));
    }

    getUnlockFacilityRequests() {
        return this.http.get<PagedResult<UnlockFacilityRequest>>(`${this.urlConstantsService.UNLOCK_TREATMENTS_URL}/admin`)
            .pipe(map(response => response.items));
    }

    addNewUnlockFacilityRequest(request: UnlockFacilityRequest): Observable<UnlockFacilityRequest> {
        return this.http.post<OperationResponse<UnlockFacilityRequest>>(`${this.urlConstantsService.UNLOCK_TREATMENTS_URL}/admin`, request)
            .pipe(map(response => response.data));
    }

    updateUnlockFacilityRequest(request: UnlockFacilityRequest): Observable<UnlockFacilityRequest> {
        return this.http.put<OperationResponse<UnlockFacilityRequest>>(`${this.urlConstantsService.UNLOCK_TREATMENTS_URL}/admin`, request)
            .pipe(map(response => response.data));
    }

    deleteUnlockFacilityRequest(id: string): Observable<boolean> {
        return this.http.delete<OperationResponse<boolean>>(`${this.urlConstantsService.UNLOCK_TREATMENTS_URL}/admin/${id}`)
            .pipe(map(response => response.data));
    }
}