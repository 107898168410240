import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlConstantsService } from './url-constants.service';
import {
  OperationResponse,
  CptOverride,
} from '@app/model';

@Injectable({
  providedIn: 'root',
})
export class CptOverrideService {

  constructor(private httpClient: HttpClient,
    private urlConstantsService: UrlConstantsService) { }

  getAllByFacility(facilityId: string): Observable<CptOverride[]> {
    return this.httpClient.get<OperationResponse<CptOverride[]>>(`${this.urlConstantsService.CPT_OVERRIDE_URL}?facilityId=${facilityId}`)
        .pipe(map(response => response.data));
  }

  getAllByClient(clientId: string): Observable<CptOverride[]> {
    return this.httpClient.get<OperationResponse<CptOverride[]>>(`${this.urlConstantsService.CPT_OVERRIDE_URL}?clientId=${clientId}`)
        .pipe(map(response => response.data));
  }

  getById(id: string): Observable<CptOverride> {
    return this.httpClient.get<OperationResponse<CptOverride>>(`${this.urlConstantsService.CPT_OVERRIDE_URL}/${id}`)
        .pipe(map(response => response.data));
  }

  createCptOverride(cptOverride: CptOverride) {
    return this.httpClient.post<OperationResponse<CptOverride>>(this.urlConstantsService.CPT_OVERRIDE_URL, cptOverride)
        .pipe(map(response => response.data));
  }

  updateCptOverride(cptOverride: CptOverride) {
    return this.httpClient.put<OperationResponse<CptOverride>>(`${this.urlConstantsService.CPT_OVERRIDE_URL}`, cptOverride)
        .pipe(map(response => response.data));
  }

  deleteCptOverride(id: string) {
    return this.httpClient.delete<OperationResponse<void>>(`${this.urlConstantsService.CPT_OVERRIDE_URL}/${id}`)
        .pipe(map(response => response.data));
  }
}
