import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { take, takeUntil, finalize } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { TimeClockEntry, Facility, TimeClockAction, eDiscipline, User, eDesignation } from '@app/model';
import { TimeClockEntryService } from '@app/core/services/time-clock-entry.service';
import * as moment from 'moment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OnDestroyComponent } from '@app/shared';
import { UIPermissionConstants, ToastMessageService, CacheManService, DataListConstants } from '@app/core';
import { TimeClockModal } from "./time-clock-modal";

interface DisciplineDesignationDisplay {
    text: string;
    id: string;
    discipline: eDiscipline;
    designation: eDesignation;
  }

@Component({
    selector: 'tms-time-clock-modal',
    templateUrl: './time-clock-modal.component.html',
    styleUrls: ['./time-clock-modal-component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeClockModalComponent extends OnDestroyComponent implements OnInit, OnDestroy {
    @Input() facilities: Facility[] = [];
    @Input() clockedIn: boolean = false;
    @Input() currentlyDriving: boolean = false;
    @Input() originFacility: string = null;
    @Input() transferFacility: string = null;
    @Input() defaultFacility: string = null;
    @Input() currentUser: User = null;

    @Output() timeclockEntryUpdated: EventEmitter<boolean> = new EventEmitter();

    defaultFacilityId: string;
    disciplineList: DisciplineDesignationDisplay[] = [];

    confirmChecked: boolean = false;
    clockButtonText: string;
    clockButtonFacility: string = '';
    clockActionProcessing: boolean = false;

    currentTime: string;
    mostRecentEntry: TimeClockEntry;

    filteredFacilities: Facility[] = [];

    currentUserDiscipline: string = null;
    selectedUserEcode: string = null;
    selectedUserDiscipline: eDiscipline = null;
    selectedUserDesignation: eDesignation = null;

    get timeclockTravalPermission() {
        return UIPermissionConstants.TIMECLOCK_TRAVEL;
    }

    constructor(private cacheManService: CacheManService,
        private timeClockEntryService: TimeClockEntryService,
        private toastMessageService: ToastMessageService,
        public activeModal: NgbActiveModal,
        private cdr: ChangeDetectorRef) {
            super();
         }

    ngOnInit() {
        if (this.currentlyDriving) {
            this.clockButtonText = 'Clock In';
            this.filteredFacilities.push(this.facilities.find(f => f.id === this.transferFacility));
            this.getServerTime();
        } else if (this.clockedIn) {
            this.clockButtonText = 'Clock Out';
            const originIndex = this.facilities.findIndex(f => f.id === this.originFacility);
            this.filteredFacilities = [...this.facilities];
            this.filteredFacilities.splice(originIndex, 1);
        } else {
            this.clockButtonText = 'Clock In';
            this.filteredFacilities = [...this.facilities];

            if (this.defaultFacility && this.defaultFacility !== null && this.defaultFacility !== '') {
                this.clockButtonFacility = this.defaultFacility;
            }

            this.getServerTime();
        }

        this.currentUserDiscipline = `${this.currentUser.employee?.discipline}/${this.currentUser.employee?.designation}`;
        this.selectedUserDiscipline = this.currentUser.employee?.discipline;
        this.selectedUserDesignation = this.currentUser.employee?.designation;
        // This should be placed in a method and tested
        this.selectedUserEcode = this.getEcodeByDisciplineAndDesignation(this.currentUser, this.selectedUserDiscipline, this.selectedUserDesignation)


        combineLatest([this.cacheManService.getDataListItems$(DataListConstants.DISCIPLINES),
            this.cacheManService.getDataListItems$(DataListConstants.DESIGNATIONS)])
            .pipe(take(1))
            .subscribe(([disciplines, designations]) => {
              if (this.currentUser.employee?.availableDisciplines) {
                this.disciplineList = this.currentUser.employee?.availableDisciplines.map(dd => {
                  const discipline = disciplines.find(d => d.valueInt == dd.discipline);
                  const designation = designations.find(d => d.valueInt == dd.designation);
                  const ddForDisplay: DisciplineDesignationDisplay = { 
                    text: `${discipline?.shortName}/${designation?.shortName}`,
                    id: `${discipline.value}/${designation.value}`, 
                    discipline: dd.discipline,
                    designation: dd.designation, 
                  }
                  return ddForDisplay;
                })
              }
            });
    }

    getEcodeByDisciplineAndDesignation(user: User, discipline: eDiscipline, designation: eDesignation){
        return user.employee?.availableDisciplines.find(d => 
            d.discipline == this.selectedUserDiscipline 
            && d.designation == this.selectedUserDesignation).ecode
    }

    getServerTime() {
        this.timeClockEntryService.getServerTimeUtc()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(serverTimeModel => {
            const serverTime = moment(serverTimeModel.serverTimeUtc);
            this.currentTime = serverTime.local().format("hh:mm A");
            this.cdr.detectChanges();
        });
    }

    disabledClockButton() {
        return TimeClockModal.disabledClockButton_testable(this.clockActionProcessing, this.clockedIn, this.confirmChecked, this.clockButtonFacility)
    }

    onSelectedFacilityChanged(e) {
        if (this.clockedIn && this.clockButtonFacility !== null && this.clockButtonFacility !== '') {
            this.clockButtonText = 'Transfer';
        }
    }

    onSelectedFacilityValueChanged(e) {
        if (this.clockedIn && (this.clockButtonFacility === null || this.clockButtonFacility === '')) {
            this.clockButtonText = 'Clock Out';
        }
    }

    onUserDisciplineDesignationChanged(e) {
        const disciplineDesignation = e.value.split('/');
        this.selectedUserDiscipline = +disciplineDesignation[0];
        this.selectedUserDesignation = +disciplineDesignation[1];
    }

    onClockButtonClicked(e) {
        this.clockActionProcessing = true;
        const validationResult = e.validationGroup.validate();

        if (!validationResult.isValid) {
            return;
        }

        let entry: TimeClockEntry = {};

        var timeString = moment().utc().local().format('MM/DD/YYYY h:mm:ss a');

        // Either clocking out or transfering
        if (this.currentlyDriving) {
            entry = { facilityId: this.clockButtonFacility, timeClockAction: TimeClockAction.ClockIn, clientLocalTimeStampString: timeString };
        }
        else if (this.clockedIn) {
            if (this.clockButtonFacility !== null && this.clockButtonFacility !== '') {
                entry = { facilityId: this.clockButtonFacility, timeClockAction: TimeClockAction.Driving, clientLocalTimeStampString: timeString };
            } else {
                entry = { facilityId: this.getCurrentOrDefaultFacilityId(), timeClockAction: TimeClockAction.ClockOut, clientLocalTimeStampString: timeString };
            }
        }
        else {
            entry = { facilityId: this.clockButtonFacility, timeClockAction: TimeClockAction.ClockIn, clientLocalTimeStampString: timeString };
        }

        this.selectedUserEcode = this.getEcodeByDisciplineAndDesignation(this.currentUser, this.selectedUserDiscipline, this.selectedUserDesignation)

        entry.eCode = this.selectedUserEcode;
        entry.discipline = this.selectedUserDiscipline;
        entry.designation = this.selectedUserDesignation;

        this.timeClockEntryService.createEntry(entry)
            .pipe(take(1), finalize(() =>
                {
                    this.clockActionProcessing = false
                    this.cdr.detectChanges()
                }))
            .subscribe(response => {
                if (response.isSuccessful) {
                    if ((this.selectedUserDiscipline && this.selectedUserDesignation) 
                        && ((this.selectedUserDiscipline != this.currentUser?.employee.discipline)
                        || (this.selectedUserDesignation != this.currentUser?.employee.designation))) {
                        this.cacheManService.updateCurrentUserDisciplineDesignation(this.selectedUserDiscipline, this.selectedUserDesignation, [entry]);
                        this.cacheManService.getDataListValueTextDictionary$(DataListConstants.DISCIPLINES, 'shortName')
                        .pipe(take(1))
                        .subscribe(disciplineDictionary => {
                            this.toastMessageService.successNotification(`You have successully switched and clocked in as discipline ${disciplineDictionary[this.selectedUserDiscipline]}`);
                        });
                    }
                    this.timeclockEntryUpdated.emit(true);
                    this.clockActionProcessing = false;
                } else {                  
                    this.timeclockEntryUpdated.emit(false);
                    if(response.errors && response.errors.length > 0) {
                        this.toastMessageService.errorNotification(response.errors[0].text);
                    }
                }
                () => {
                    this.clockActionProcessing = false;
                }
            });
    }

    getCurrentOrDefaultFacilityId() {
        let result = '';
        if (this.originFacility !== null && this.originFacility !== '') {
            result = this.originFacility;
        }
        else if (this.defaultFacility !== null && this.defaultFacility !== '') {
            result = this.defaultFacility;
        }
        else { 
            result = ''
        }
        return result
    }

    displayUserDiscipline(e) {
        return e != null ? `${e.discipline}/${e.designation}` : '';
    }

    ngOnDestroy() {
        super.onDestroy();
    }
}
