import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { CacheManService, DataListConstants } from '@app/core';
import {
  InterviewQuestion,
  DataListItem,
  InterviewAnswerField,
} from '@app/model';

@Component({
  selector: 'tms-question-qualityofperformance',
  styleUrls: [],
  template: `
    <tms-interview-question-drop-down-list
        [id]="id"
        [question]="question" 
        [dataListItems]="qualityOfPerformance" 
        [interviewAnswerField]="interviewAnswerField"
        [showClearButton]="true" 
        [disabled]="disabled"
        (onInterviewValueChanged)="onSelectedValue($event)">
    </tms-interview-question-drop-down-list>
  `,
})
export class InterviewQuestionQualityOfPerformanceComponent implements OnInit {
  @Input() question: InterviewQuestion;
  @Input() interviewAnswerField: InterviewAnswerField;
  @Input() disabled: boolean = false;
  @Input() id: string;
  @Output() onInterviewValueChanged: EventEmitter<string> = new EventEmitter<string>();

  qualityOfPerformance: DataListItem[] = [];

  constructor(private cacheManService: CacheManService) {
  }

  ngOnInit() {
    this.cacheManService.getDataListItems$(DataListConstants.QUALITY_OF_PERFORMANCE)
      .pipe(take(1))
      .subscribe(dataListItems => {
        if (this.question.customAnswerOptions) {
          this.qualityOfPerformance = [...dataListItems, ...this.question.customAnswerOptions];
        } else {
          this.qualityOfPerformance = dataListItems;
        }
      })
  }

  onSelectedValue($event) {
    this.onInterviewValueChanged.emit($event);
  }
}
