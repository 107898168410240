import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[scrollToTopId]'
})
export class ScrollToTopIdDirective {
  @Input() set scrollToTopId(id: string) {
    this.el.nativeElement.id = id ? id : ''; 
  }
  
  constructor(private el: ElementRef) {
  }

}
