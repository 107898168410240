export function getNBOptionValueTextId(narrativeTitle: string, option: string, index: number): string {
    return `${narrativeTitle}${option}FVO${index}`;
}

export function getNBOptionValueId(narrativeTitle: string, option: string): string {
    return `${narrativeTitle}${option}NFV`;
}

export function getNBOptionId(narrativeTitle: string, option: string): string {
    return `${narrativeTitle}${option}NF`;
}