<tms-modal [title]="title" (onSubmit)="onSubmitPressed()" (onCancel)="onClearPressed()" cancelButtonText="Clear" (onClose)="onClose()">
    <span>Patient participated in group intervention of </span>
    <dx-text-area [autoResizeEnabled]="true" [(value)]="txtNumberPeople" class="delivery-text-box">
        <dx-validator>
            <dxi-validation-rule type="required" message="Value is required">
            </dxi-validation-rule>
        </dx-validator>
    </dx-text-area><span> people utilizing </span>
    <dx-text-area [autoResizeEnabled]="true" [(value)]="txtImprovement" class="delivery-text-box">
        <dx-validator>
            <dxi-validation-rule type="required" message="Value is required">
            </dxi-validation-rule>
        </dx-validator>
    </dx-text-area><span> to improve </span> 
    <dx-text-area [autoResizeEnabled]="true" [(value)]="txtIntervention" class="intervention-text-box">
        <dx-validator>
            <dxi-validation-rule type="required" message="Value is required">
            </dxi-validation-rule>
        </dx-validator>
    </dx-text-area>.
</tms-modal>