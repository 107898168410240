import { Component, Input, OnDestroy, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { AnnouncementsService } from '@app/core';

import { OnDestroyComponent } from '@app/shared';

@Component({
  selector: 'tms-banner',
  styleUrls: ['./banner.component.scss'],
  templateUrl: './banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent extends OnDestroyComponent implements OnInit, OnDestroy {

  @Input() position = 'normal';

  showBanner: boolean = false;
  bannerText: string[] = [];

  constructor(private announcementsService: AnnouncementsService,
    private cdr: ChangeDetectorRef,) {
    super();
  }

  ngOnInit() {
    this.announcementsService.getBannerAnnouncements()
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(banners => {
      this.bannerText = [];
      if (banners && banners.length > 0) {
        this.showBanner = true;
        banners.forEach(b => {
          this.bannerText.push(b.text);
        });

        this.cdr.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy();
  }
}
