import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CacheManService, QuickLinksService, ToastMessageService, UIPermissionConstants } from '@app/core';
import { ePermissions, QuickLinkItem } from '@app/model';
import { modalOptionsMaterial } from '@app/utils';
import { Observable, of } from 'rxjs';
import { AddEditQuickLinkItemComponent } from './add-edit-quick-link-item/add-edit-quick-link-item.component';

@Component({
  selector: 'tms-quick-links',
  templateUrl: './quick-links.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickLinksComponent implements OnInit {
  items: QuickLinkItem[] = [];
  canEditItems$: Observable<boolean> = null;

  get canAddQuickLinkItem(): ePermissions {
    return UIPermissionConstants.LINK_EDITOR;
  }

  get searchByTerms(): string[] {
    return ['name', 'description', 'categoryName', 'keywordsString', 'primaryKeywordsString', 'link'];
  }

  constructor(private cacheManService: CacheManService,
    private cdr: ChangeDetectorRef,
    private matDialog: MatDialog,
    private quickLinksService: QuickLinksService,
    private toastMessageService: ToastMessageService) { }

  ngOnInit(): void {
    this.canEditItems$ = this.cacheManService.getUserHasPermission$(UIPermissionConstants.LINK_EDITOR);
    this.getQuickLinkItems();
  }

  onAddItemClicked() {
    const modalRef = this.matDialog.open(AddEditQuickLinkItemComponent, { ...modalOptionsMaterial});
    modalRef.afterClosed().subscribe(() => this.getQuickLinkItems(), () => {});
  }

  onItemEdit(e) {
    const modalRef = this.matDialog.open(AddEditQuickLinkItemComponent, { ...modalOptionsMaterial, data: e });
    modalRef.afterClosed().subscribe(() => this.getQuickLinkItems(), () => {});
  }

  onItemDelete(e) {
    this.quickLinksService.deleteQuickLinkItem(e)
      .subscribe(() => {
        this.toastMessageService.successNotification('Successfully deleted quick link');
        this.getQuickLinkItems();
      }, error => this.toastMessageService.errorNotification('An error occurred when trying to delete quick link.'));;
  }

  onItemValueChanged(e: QuickLinkItem) {
    window.open(e.link, "_blank");
  }

  private getQuickLinkItems() {
    this.quickLinksService.getQuickLinkItems()
      .subscribe(items => {
        this.items = items;
        this.cdr.detectChanges();
      });
  }

}
