import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { OperationResponse, SystemHealthDashboard } from '@app/model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class SystemHealthService {
    constructor(private http: HttpClient,
		private urlConstantsService: UrlConstantsService) {
    }

    get(): Observable<SystemHealthDashboard> {
      return this.http.get<SystemHealthDashboard>(`${this.urlConstantsService.SYSTEM_HEALTH_DASHBOARD_URL}`);
    }
}