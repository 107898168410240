import { TemplateObject } from '../../template-object';

export const REASON_FOR_REFERRAL_TEMPLATE: string = '{{#if Age}}{{Age}} year old {{/if}}{{Gender}}' +
'{{#if referredFollowing}} referred following recent {{referredFollowing}}{{/if}}' +
'{{#if dueTo}} due to {{dueTo}}{{/if}}' +
'{{#if resultIn}} resulting in {{resultIn}}{{/if}}' +
'{{#if stringList}}{{/if}}{{stringList}}';

export const ReasonForReferralProperties = {
    AGE: 'Age', // uppercase from server
    BED_MOB: 'bedmob',
    GAIT: 'gait',
    GENDER: 'Gender', // uppercase from server
    REFERRED_FOLLOWING: 'referredFollowing',
    POSITION: 'position',
    DUE_TO: 'dueTo',
    TRANSFER: 'transfer',
    RESULT_IN: 'resultIn',
    ROM: 'rom',
    SAFETY: 'safety',
    SKIN_HEALTH: 'skinhealth',
    // OT
    BATHING: 'bathing',
    DRESSING: 'dressing',
    FUNC_ADLS: 'funcADLS',
    FUNC_MOB: 'funcMob',
    FUNC_ROM: 'funcROM',
    GROOMING: 'grooming',
    HOME_MANAGEMENT: 'homeManagement',
    HOME_SAFETY: 'homeSafety',
    POSITIONING: 'positioning',
    SELF_FEED: 'selfFeed',
    // ST
    COGNITION: 'cogntion',
    DIET_TOLERANCE: 'dietTolerance',
    COMMUNICATION: 'communication',
    OM_FNX: 'omFnx',
    COMPREHENSION: 'comprehension',
    SAFETY_AWARE: 'safetyAware',
    SWALLOW_SAFETY: 'swallowSafety',
    SPEECH_INTELL: 'speechIntell',
    SWALLOW_FNX: 'swallowFnx',
}

export class ReasonForReferralTemplate implements TemplateObject {
    Age: string = '';
    bedmob: string = '';
    gait: string = '';
    Gender: string = '';
    stringList: string[] = [];
    referredFollowing: string = '';
    position: string = '';
    dueTo: string = '';
    transfer: string = '';
    resultIn: string = '';
    rom: string = '';
    safety: string = '';
    skinhealth: string = '';

    // OT
    bathing: string = '';
    dressing: string = '';
    funcADLS: string = '';
    funcMob: string = '';
    funcROM: string = '';
    grooming: string = '';
    homeManagement: string = '';
    homeSafety: string = '';
    positioning: string = '';
    selfFeed: string = '';

    // ST
    cognition: string = '';
    dietTolerance: string = '';
    communication: string = '';
    omFnx: string = '';
    comprehension: string = '';
    safetyAware: string = '';
    swallowSafety: string = '';
    speechIntell: string = '';
    swallowFnx: string = '';

    private toList = ['transfer', 'gait', 'bedmob', 'rom', 'safety', 'position', 'skinhealth',
                      ReasonForReferralProperties.BATHING,
                      ReasonForReferralProperties.DRESSING,
                      ReasonForReferralProperties.FUNC_ADLS,
                      ReasonForReferralProperties.FUNC_MOB,
                      ReasonForReferralProperties.FUNC_ROM,
                      ReasonForReferralProperties.GROOMING,
                      ReasonForReferralProperties.HOME_MANAGEMENT,
                      ReasonForReferralProperties.HOME_SAFETY,
                      ReasonForReferralProperties.POSITIONING,
                      ReasonForReferralProperties.SELF_FEED,
                      ReasonForReferralProperties.COGNITION,
                      ReasonForReferralProperties.DIET_TOLERANCE,
                      ReasonForReferralProperties.COMMUNICATION,
                      ReasonForReferralProperties.OM_FNX,
                      ReasonForReferralProperties.COMPREHENSION,
                      ReasonForReferralProperties.SAFETY_AWARE,
                      ReasonForReferralProperties.SWALLOW_SAFETY,
                      ReasonForReferralProperties.SPEECH_INTELL,
                      ReasonForReferralProperties.SWALLOW_FNX,
                    ];

    constructor() { }

    preCompileTemplateObj(): void {
        this.stringList = [];
        this.toList.forEach(value => {
            if (this[value]) {
                this.stringList.push(' ' + this[value]);
            }
        });
    }
}
