import { Component } from '@angular/core';

@Component({
        selector: 'tms-card',
        template:
                `
    <nb-card class="tms-card">
        <ng-content></ng-content>
    </nb-card>
    `,
})

export class TmsCardComponent {

}
