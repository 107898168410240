import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IntegrationPoint, IntegrationProvider, OperationResponse, PagedResult } from '@app/model';
import { UrlConstantsService } from './url-constants.service';

const INTEGRATION_POINT: string = 'integrationpoint';
const INTEGRATION_PROVIDER: string = 'integrationprovider';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {

  constructor(private httpClient: HttpClient,
    private urlConstantsService: UrlConstantsService) { }

  getIntegrationPoints(): Observable<IntegrationPoint[]> {
    return this.httpClient.get<PagedResult<IntegrationPoint>>(`${this.urlConstantsService.INTEGRATIONS_URL}/${INTEGRATION_POINT}`)
      .pipe(map(response => response.items));
  }

  addIntegrationPoint(integrationPoint: IntegrationPoint): Observable<IntegrationPoint> {
    return this.httpClient.post<OperationResponse<IntegrationPoint>>(`${this.urlConstantsService.INTEGRATIONS_URL}/${INTEGRATION_POINT}`, integrationPoint)
      .pipe(map(response => response.data));
  }

  updateIntegrationPoint(integrationPoint: IntegrationPoint): Observable<IntegrationPoint> {
    return this.httpClient.put<OperationResponse<IntegrationPoint>>(`${this.urlConstantsService.INTEGRATIONS_URL}/${INTEGRATION_POINT}/${integrationPoint.id}`, integrationPoint)
      .pipe(map(response => response.data));
  }

  deleteIntegrationPoint(id: string) {
    return this.httpClient.delete(`${this.urlConstantsService.INTEGRATIONS_URL}/${INTEGRATION_POINT}/${id}`);
  }

  getIntegrationProviders(): Observable<IntegrationProvider[]> {
    return this.httpClient.get<PagedResult<IntegrationProvider>>(`${this.urlConstantsService.INTEGRATIONS_URL}/${INTEGRATION_PROVIDER}`)
              .pipe(map(response => response.items));
  }

  addIntegrationProvider(integrationProvider: IntegrationProvider): Observable<IntegrationProvider> {
    return this.httpClient.post<OperationResponse<IntegrationProvider>>(`${this.urlConstantsService.INTEGRATIONS_URL}/${INTEGRATION_PROVIDER}`, integrationProvider)
      .pipe(map(response => response.data));
  }

  updateIntegrationProvider(integrationProvider: IntegrationProvider): Observable<IntegrationProvider> {
    return this.httpClient.put<OperationResponse<IntegrationProvider>>(`${this.urlConstantsService.INTEGRATIONS_URL}/${INTEGRATION_PROVIDER}/${integrationProvider.id}`, integrationProvider)
      .pipe(map(response => response.data));
  }

  deleteIntegrationProvider(id: string) {
    return this.httpClient.delete(`${this.urlConstantsService.INTEGRATIONS_URL}/${INTEGRATION_PROVIDER}/${id}`);
  }

}
