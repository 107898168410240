<div class="row">
    <div class="col-md-12">
        <div style="display:none;">
            <ng-content></ng-content>
        </div>
        <div style="background-color:white;">
            <nb-tabset>
                <nb-tab *ngFor="let page of session.template.pagesList" tabTitle="{{page.name}}">
                    <div>
                        <div *ngFor="let g of page.groups">
                            <div *ngFor="let q of g.questions">
                                <div class="form-group row">
                                    <label class="col-sm-3 form-control-label">{{q.questionText}}</label>
                                    <div class="col-sm-9">
                                        <tms-interview-question [question]="q">
                                        </tms-interview-question>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row" style="display:block;">
                            <div class="offset-sm-3 col-sm-9">
                                <button type="submit" class="btn btn-warning" (click)="showContextValues()">Show Context to Console
                                </button>
                            </div>
                        </div>
                    </div>
                </nb-tab>
            </nb-tabset>
            <div>
            </div>
        </div>
    </div>
</div>