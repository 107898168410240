<div class="modal-header tms-modal-header">
    <div class="page-heading-font">How can we help?</div>
    <dx-button class="tms-icon-button" icon="fas fa-times" (click)="onClose()" hint="Close"></dx-button>
</div>
<div class="modal-body">
    <dx-validation-group>
        <div class="row">
            <div class="col-3">
                <tms-label text="Department" [isRequired]="true"></tms-label>
            </div>
            <div class="col">
                <dx-select-box [dataSource]="departments$ | async" [(value)]="supportIssue.department"
                    displayExpr="text" valueExpr="valueInt" [disabled]="true">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="A department is required">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-select-box>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col-3">
                <tms-label text="Subject" [isRequired]="true"></tms-label>
            </div>
            <div class="col">
                <tms-typeahead-textbox [required]="true" (onValueChanged)="onSubjectValueChanged($event)">
                </tms-typeahead-textbox>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col-3">
                <tms-label text="Support Topics" [isRequired]="false"></tms-label>
            </div>
            <div class="col">
                <div *ngFor="let kbItem of knowledgeBaseItems">
                    <a href="javascript:void(0)" (click)="onKbItemClicked(kbItem)">{{ kbItem.name }}</a>
                </div>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col-3">
                <tms-label text="Still need help?" [isRequired]="false"></tms-label>
            </div>
            <div class="col">
                <dx-button text="No, I'm good" (onClick)="onClose()">
                </dx-button>
                <dx-button class="tms-primary-button tms-submit-button" (onClick)="createTicket($event)"
                    text="Yes, Create Ticket"></dx-button>
            </div>
        </div>
    </dx-validation-group>
</div>