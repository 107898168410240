import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SupportIssue } from '@app/model';

@Component({
  selector: 'tms-support-ticket-wizard',
  templateUrl: './support-ticket-wizard.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupportTicketWizardComponent implements OnInit {
  selectedStepIndex: number = 0;
  supportIssue: SupportIssue = null;

  constructor(private activeModalService: NgbActiveModal,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }

  onCreateTicketValueChanged(e) {
    if (e.createTicket) {
      this.selectedStepIndex = 1;
      this.supportIssue = e.supportIssue;
      this.cdr.detectChanges();
    } else {
      this.activeModalService.close();
    }
  }
}
