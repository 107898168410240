import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { OperationResponse, PreauthorizationRequest, DocumentRecord, Patient, PatientSlim, GetPreAuthPatientsRequest, GetPreAuthPatientEpisodes, PreauthorizationPatientEpisode, PreauthorizationRequestSlim, PreAuthRequestsQueryResponse, ePreauthorizationRequestStatus, PreauthorizationApprovalUiState } from '@app/model';
import { Observable, throwError, of } from 'rxjs';
import { map, tap, catchError, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { PreauthRequestsQuery, PreauthRequestsStore } from '../stores/insurance-verification';
import { DeepCopyUtils, ErrorMessageUtils } from '@app/utils';
import { EntityStateHistoryPlugin, EntityDirtyCheckPlugin } from '@datorama/akita';
import { CacheManService } from './cacheman.service';
import { DocumentCategoryConstants } from '../constants';
import { DocumentRecordsService } from './document-records.service';
import { GetPreAuthRequests } from '@app/model/insurance-verification/GetPreAuthRequests';
import { ReportsService } from './reports.service';

@Injectable({
    providedIn: 'root',
})
export class PreauthRequestService {
    collection: EntityStateHistoryPlugin;
    dirtyCheckCollection: EntityDirtyCheckPlugin;
    
    private _preauthApprovalUiState: PreauthorizationApprovalUiState = null;
    
    constructor(private cacheManService: CacheManService,
        private documentRecordsService: DocumentRecordsService,
        private http: HttpClient,
        private preauthRequestQuery: PreauthRequestsQuery,
        private preauthRequestStore: PreauthRequestsStore,
        private reportsService: ReportsService,
        private urlConstantsService: UrlConstantsService) {
    }

    getPreauthApprovalUiState(): PreauthorizationApprovalUiState {
        return this._preauthApprovalUiState;
    }

    setPreauthApprovalUiState(value: PreauthorizationApprovalUiState) {
        this._preauthApprovalUiState = value;
    }

    activeRequest$(): Observable<any>;
    activeRequest$(property: string): Observable<any>;
    activeRequest$<T>(property?: string): Observable<T>;
    activeRequest$<T>(property?: string): Observable<any> | Observable<T> {
        if (property) {
            return this.preauthRequestQuery.selectActive(entity => entity[property]);
        } else {
            return this.preauthRequestQuery.selectActive();
        }
    }

    cancelPreauthUpdates() {
        this.collection.jumpToPast(this.preauthRequestQuery.getActiveId(), 0);
        this.collection.clear(this.preauthRequestQuery.getActiveId());
        this.dirtyCheckCollection.reset();
      }

    getRequestById(id: string) {
        return this.http.get<OperationResponse<PreauthorizationRequest>>(`${this.urlConstantsService.PREAUTHORIZATION_REQUESTS_URL}/${id}`)
            .pipe(map(response => response.data),
                tap(request => {
                    this.collection = new EntityStateHistoryPlugin(this.preauthRequestQuery, { entityIds: id, maxAge: 20 });
                    this.preauthRequestStore.upsert(id, () => request);
                    this.preauthRequestStore.setActive(id);
                    this.dirtyCheckCollection = new EntityDirtyCheckPlugin(this.preauthRequestQuery);
                    this.dirtyCheckCollection.setHead();
                }));
    }

    getPatientsByFacility(facilityId: string): Observable<PatientSlim[]> {
        const request: GetPreAuthPatientsRequest = {
            facilityId: facilityId,
        };
        return this.http.put<OperationResponse<PatientSlim[]>>(`${this.urlConstantsService.INSURANCE_REQUESTS_URL}/patients`, request)
            .pipe(map(response => response.data));
    }

    getPatientCasesAndEpisodes(patientId: string, facilityId: string): Observable<PreauthorizationPatientEpisode[]> {
        const request: GetPreAuthPatientEpisodes = {
            patientId: patientId,
            facilityId: facilityId,
        };

        return this.http.put<OperationResponse<PreauthorizationPatientEpisode[]>>(`${this.urlConstantsService.INSURANCE_REQUESTS_URL}/episodesByPatientId`, request)
                    .pipe(map(response => response.data)); 
    }

    getPreauthorizationRequestByFacilityId(facilityId: string, statuses: ePreauthorizationRequestStatus[] = null): Observable<PreauthorizationRequestSlim[]> {
        const request: GetPreAuthRequests = {
            facilityId: facilityId,
            statuses: statuses,
        };

        return this.http.put<OperationResponse<PreAuthRequestsQueryResponse>>(`${this.urlConstantsService.INSURANCE_REQUESTS_URL}/requests`, request)
            .pipe(map(response => response.data.preauthRequestSlimDtos));
    }

    getPreauthorizationRequestsByPatientId(patientId: string) {
        const request: GetPreAuthRequests = {
            patientId: patientId,
        };

        return this.http.put<OperationResponse<PreAuthRequestsQueryResponse>>(`${this.urlConstantsService.INSURANCE_REQUESTS_URL}/requests`, request)
            .pipe(map(response => response.data.preauthRequestSlimDtos));
    }

    getPreauthorizationRequestById(preauthRequestId: string) {
        const request: GetPreAuthRequests = {
            preAuthRequestId: preauthRequestId,
        };

        return this.http.get<OperationResponse<PreAuthRequestsQueryResponse>>(`${this.urlConstantsService.INSURANCE_REQUESTS_URL}/${preauthRequestId}`)
            .pipe(map(response => response.data.preauthRequests.length > 0 ? response.data.preauthRequests[0] : null));
    }

    deletePreauthorizationRequest(preauthRequestId: string): Observable<boolean> {
        return this.http.delete<OperationResponse<boolean>>(`${this.urlConstantsService.INSURANCE_REQUESTS_URL}/${preauthRequestId}`)
            .pipe(map(response => response.data));
    }

    getUpdatedProofDocuments(id: string) {
        return this.http.get<OperationResponse<DocumentRecord[]>>(`${this.urlConstantsService.PREAUTHORIZATION_REQUESTS_URL}/${id}/proofdocuments`)
            .pipe(map(response => response.data),
                tap(data => {
                    this.preauthRequestStore.updateActive(entity => {
                        return {
                            ...entity,
                            proofOfAuthorizationDocumentRecords: DeepCopyUtils.deepCopyObject(data),
                        }
                    });
            }));
    }

    notifyClinic(requestId: string) {
        return this.http.post<OperationResponse<PreauthorizationRequest>>(`${this.urlConstantsService.PREAUTHORIZATION_REQUESTS_URL}/${requestId}/notify`, null)
            .pipe(map(response => response.data));
    }

    updateRequest() {
        if (this.preauthRequestQuery.getActiveId() && this.dirtyCheckCollection.someDirty()) {
            return this.http.put<OperationResponse<PreauthorizationRequest>>(`${this.urlConstantsService.PREAUTHORIZATION_REQUESTS_URL}/update`, this.preauthRequestQuery.getActive())
                .pipe(catchError(err => throwError(ErrorMessageUtils.getDisplayErrorMessage(err, 'An error occurred saving startup'))),
                    map(response => response.data),
                    tap(data => {
                        this.preauthRequestStore.updateActive(entity => {
                            return data;
                        });
                        this.collection = new EntityStateHistoryPlugin(this.preauthRequestQuery, { entityIds: this.preauthRequestQuery.getActiveId(), maxAge: 20 });
                        this.dirtyCheckCollection.setHead();
                    }));
        } else {
            return of(null);
        }
    }

    uploadProofOfPreauthorizationDocument(facilityId: string, patientId: string, patientEpisodeId: string, patientCaseId: string, fileName: string, file: any): Observable<DocumentRecord> {
        // get proof of preauthorization document category
        return this.cacheManService.getDocumentCategories$()
            .pipe(switchMap(documentCategories => {
                const proofOfPreauthCategory = documentCategories.find(c => c.shortName == DocumentCategoryConstants.PROOF_OF_AUTHORIZATION);
                const docRecord: DocumentRecord = {
                    fileName: fileName,
                    friendlyName: 'Proof of preauthorization',
                    documentRecordCategoryId: proofOfPreauthCategory.id,
                    fileHash: file,
                    facilityId: facilityId,
                    patientId: patientId,
                    patientCasedId: patientCaseId,
                    patientEpisodeId: patientEpisodeId,
                };
                return this.documentRecordsService.uploadDocumentRecord(docRecord, file);
            }))
    }

    createPreauthorizationRequest(preauthRequest: PreauthorizationRequest): Observable<PreauthorizationRequest> {
        return this.http.post<OperationResponse<PreauthorizationRequest>>(`${this.urlConstantsService.INSURANCE_REQUESTS_URL}`, preauthRequest)
            .pipe(map(response => response.data));
    }

    uploadPreauthorizationDocument(preauthRequestId: string, documentRecordCategoryShortName: string, files: any[]): Observable<void> {
        if (files.length == 0) {
            return of(null);
        }

        const formData = new FormData();
        formData.append('PreauthRequestId', preauthRequestId);
        formData.append('DocumentRecordCategoryShortName', documentRecordCategoryShortName);
        files.forEach(file => {
            formData.append('', file, file.name);
        });
        return this.http.post<OperationResponse<void>>(`${this.urlConstantsService.INSURANCE_REQUESTS_URL}/upload`, formData)
            .pipe(map(response => response.data));
    }

    savePreauthorizationRequestApproval(preauthRequest: PreauthorizationRequest): Observable<PreauthorizationRequest> {
        return this.http.put<OperationResponse<PreauthorizationRequest>>(`${this.urlConstantsService.INSURANCE_REQUESTS_URL}/${preauthRequest.id}/approval`, preauthRequest)
            .pipe(map(response => response.data));
    }

    updateActiveRequest<T>(property: string, object: T) {
        this.preauthRequestStore.updateActive(entity => {
            return {
                ...entity,
                [property]: DeepCopyUtils.deepCopyObject(object),
            }
        })
    }

    getPreauthorizationRequestReportById(id: string): Observable<string> {
        return this.reportsService.getPreauthorizationRequestReportById(id)
            .pipe(map(response => response.downloadUrl));
    }
}