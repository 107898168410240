import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { UserModule } from '@app/pages/user/user.module';

import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbThemeModule,
} from '@nebular/theme';

import {
  FooterComponent,
  HeaderComponent,
  LayoutComponent,
  BannerComponent,
} from './components';

const BASE_MODULES = [
  CommonModule,
  SharedModule,
  UserModule,
];

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbActionsModule,
  NbSidebarModule,
  NbSpinnerModule,
];

const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  LayoutComponent,
  BannerComponent,
];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot(
    {
      name: 'tms',
    },
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
];

@NgModule({
    imports: [
        ...BASE_MODULES,
        ...NB_MODULES,
    ],
    exports: [LayoutComponent, ...NB_MODULES],
    declarations: [...COMPONENTS]
})
export class LayoutModule {
  static forRoot(): ModuleWithProviders<LayoutModule> {
    return {
      ngModule: LayoutModule,
      providers: [...NB_THEME_PROVIDERS],
    };
  }
}
