import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { OperationResponse, ReportSchedule, PagedResult } from '@app/model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ReportScheduleService {
	constructor(private http: HttpClient,
		private urlConstantsService: UrlConstantsService) {
    }

    getAll(): Observable<ReportSchedule[]> {
		return this.http.get<PagedResult<ReportSchedule>>(`${this.urlConstantsService.REPORT_SCHEDULE_URL}`)
			.pipe(map(response => response.items));
    }
    
    getById(id: string) {
		return this.http.get<ReportSchedule>(`${this.urlConstantsService.REPORT_SCHEDULE_URL}/${id}`);
    }

    addReportSchedule(item: ReportSchedule) {
		return this.http.post<OperationResponse<ReportSchedule>>(`${this.urlConstantsService.REPORT_SCHEDULE_URL}`, item)
			.pipe(map(response => response.data));
    }

    updateReportSchedule(item: ReportSchedule) {
		return this.http.put<OperationResponse<ReportSchedule>>(`${this.urlConstantsService.REPORT_SCHEDULE_URL}/${item.id}`, item)
			.pipe(map(response => response.data));
    }

    deleteReportItem(id: string) {
		return this.http.delete<OperationResponse<boolean>>(`${this.urlConstantsService.REPORT_SCHEDULE_URL}/${id}`)
			.pipe(map(response => response.data));
    }

	sendReport(id: string) {
		return this.http.get<OperationResponse<void>>(`${this.urlConstantsService.REPORT_SCHEDULE_URL}/sendreport/${id}`)
			.pipe(map(response => response.data));
	}
}