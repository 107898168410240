import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { OperationResponse, ReportItem } from '@app/model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ReportItemService {
	constructor(private http: HttpClient,
		private urlConstantsService: UrlConstantsService) {
    }

    getAll(): Observable<ReportItem[]> {
		return this.http.get<OperationResponse<ReportItem[]>>(`${this.urlConstantsService.REPORT_ITEM_URL}`)
			.pipe(map(response => response.data));
    }
    
    getById(id: string) {
		return this.http.get<OperationResponse<ReportItem>>(`${this.urlConstantsService.REPORT_ITEM_URL}/${id}`)
			.pipe(map(response => response.data));
    }

    addReportItem(item: ReportItem) {
		return this.http.post<OperationResponse<ReportItem>>(`${this.urlConstantsService.REPORT_ITEM_URL}`, item)
			.pipe(map(response => response.data));
    }

    updateReportItem(item: ReportItem) {
		return this.http.put<OperationResponse<ReportItem>>(`${this.urlConstantsService.REPORT_ITEM_URL}`, item)
			.pipe(map(response => response.data));
    }

    deleteReportItem(id: string) {
		return this.http.delete<OperationResponse<boolean>>(`${this.urlConstantsService.REPORT_ITEM_URL}/${id}`)
			.pipe(map(response => response.data));
    }
}