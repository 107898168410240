
import { Component, ChangeDetectorRef,  ChangeDetectionStrategy, OnDestroy, OnInit } from '@angular/core';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { CacheManService, UsersService } from '@app/core';
import { ActionItem } from '@app/model';
import { OnDestroyComponent } from '@app/shared';
import * as moment from 'moment';

interface ActionItemDisplay {
    item?: string;
    dueDate?: Date;
    isPastDue?: boolean;
    pastDueDays?: number;
    isDueToday?: boolean;
    isDueFuture?: boolean;
    futureDueDays?: number;
}

@Component({
    selector: 'tms-tasks-action-items',
    templateUrl: './tasks.component.html',
    styleUrls: ['./tasks.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksActionItemsComponent extends OnDestroyComponent implements OnInit, OnDestroy {


    actionItems: ActionItemDisplay[] = [];
    // [{
    //     item: '(Aberdeen) Progress Note - Jane Doe', dueDate: '6/24/2019', isPastDue: false,
    //     pastDueDays: 0, isDueToday: false, isDueFuture: false, futureDueDays: 0
    // },
    // {
    //     item: '(Aberdeen) Progress Note - Jane Doe', dueDate: '6/28/2019', isPastDue: false,
    //     pastDueDays: 0, isDueToday: false, isDueFuture: false, futureDueDays: 0
    // }];

    tasks: ActionItemDisplay[] = [];

    constructor(private cacheManService: CacheManService,
        private cdr: ChangeDetectorRef,
        private usersService: UsersService) {
        super();
    }

    ngOnInit() {
        // this.cacheManService.getCurrentUser()
        //     .pipe(takeUntil(this.onDestroy$),
        //           filter(user => user != null),
        //           switchMap(user => {
        //               return this.usersService.getUserActionItems(user.id);
        //           }))
        //     .subscribe(responseActionItems => {
        //         responseActionItems.forEach(item => {
        //             const actionItemDisplay: ActionItemDisplay = {
        //                 item: item.actionText,
        //                 dueDate: item.dueDate,
        //             };
        //             const dueDate = moment(item.dueDate);
        //             const currentDate = moment(new Date());
        
        //             if (dueDate.isSame(currentDate, 'day')) {
        //                 actionItemDisplay.isDueToday = true;
        //             } else if (dueDate.isBefore(currentDate, 'day')) {
        //                 actionItemDisplay.isPastDue = true;
        //                 actionItemDisplay.pastDueDays = currentDate.diff(dueDate, 'day');
        //             } else {
        //                 actionItemDisplay.isDueFuture = true;
        //                 actionItemDisplay.futureDueDays = dueDate.diff(currentDate, 'day') + 1;
        //             }
        //             this.tasks.push(actionItemDisplay);
        //         });
        //         this.cdr.detectChanges();
        //     });   
    }

    ngOnDestroy() {
        super.onDestroy();
    }
}