export enum eDesignation
{
    Unspecified = 0,
    Pt = 1,
    // Assistant Designation
    Pta = 2,
    Ot = 3,
    // Assistant Designation
    Cota = 4,
    Slp = 5,
    Rt = 6,
    Ra = 7,
    Tech = 8,
    Rn = 9,
    Lpn = 10,
    Cna = 11,
}