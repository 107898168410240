export interface DenialClaimStageName {
    stage: number;
    name: string;
    shortName: string;
}

export const DenialClaimStageNames = [
    {
        stage: 1,
        name: 'Additional Development Review (ADR)',
        shortName: 'ADR',
    },
    {
        stage: 2,
        name: 'RAC Audit',
        shortName: 'RAC Audit',
    },

    {
        stage: 3,
        name: 'Redetermination',
        shortName: 'Redeter',
    },
    {
        stage: 4,
        name: 'Reconsideration',
        shortName: 'Reconsider',
    },
    {
        stage: 5,
        name: 'Administrative Law Judge',
        shortName: 'ALJ',
    },
    {
        stage: 6,
        name: 'Judiciary Court',
        shortName: 'Judiciary Court',
    },
];