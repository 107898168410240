<dx-tag-box #typeAheadTagBox
                width="100%" 
                [dataSource]="typeAheadDataSource" 
                [value]="value"
                [showClearButton]="true"
                (onValueChanged)="onTypeAheadValueChanged($event)"
                (onKeyUp)="onTypeAheadKeyUp($event)"
                [displayExpr]="displayExpr"
                [valueExpr]="valueExpr"
                [placeholder]="placeholderText"
                noDataText=""
                [disabled]="disabled"
                [wrapItemText]="true">
    <dx-validator>
        <dxi-validation-rule type="custom" 
                [validationCallback]="validateTypeAheadBoxCallback" 
                [message]="requiredMessage">
        </dxi-validation-rule>
    </dx-validator>
</dx-tag-box>
