import { Employee } from '@app/model/employee/Employee';
import { Address } from '../interfaces';
import { Physician } from './Physician';
import { HomeFacilityHistory } from './HomeFacilityHistory';
import { UserType } from './UserType';

export class User {
    id?: string;
    azureObjectId?: string;
    firstName?: string;
    lastName?: string;
    name?: string;
    phoneNumber?: string;
    faxNumber?: string;
    userType?: UserType;
    birthDateMonth?: number;
    birthDateDay?: number;
    combinedBirthDate?: Date;
    address?: Address;
    email?: string;
    employee?: Employee;
    physician?: Physician;
    pinCode?: string;
    updatedPinCode?: string;
    sensitivity?: number;
    facilityIds?: string[];
    userStatus?: number;
    permissions?: number[];
    permission?: number;
    homeFacilityHistory?: HomeFacilityHistory[];
    isActive?: boolean;
    autoLogoutSeconds?: number;
    invitationUrl?: string;
    lastActivity?: Date;
    hasAllFacilities?: boolean;

    constructor(id?: string, firstName?: string, lastName?: string, email?: string, employee: Employee = {},
        pinCode: string = '', sensitivity: number= 0) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.pinCode = pinCode;
        this.sensitivity = sensitivity;
        this.employee = employee;
    }
}
