import {Injectable} from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AdalInterceptor } from 'adal-angular4';
import { AuthService } from '@app/core/services/authentication/auth.service';

@Injectable()
export class AuthInterceptor extends AdalInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
    super(authService.adalService);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return super.intercept(req, next)
          .pipe(catchError(error => {
            if (!this.authService.isAuthenticated) {
              this.authService.login();
            }
            return throwError(error);
          }));
  }
}
