import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'tms-textbox',
    template:
    `
    <dx-text-box [id]="id" [width]="width" [(value)]="value" (onValueChanged)="onValueChanged($event)"></dx-text-box>
    `,
})
export class TmsTextboxComponent {
    @Input() value: string;
    @Input() width?: string = undefined;
    @Input() id?: string;
    @Output() valueChange = new EventEmitter<string>();

    onValueChanged(e) {
        this.valueChange.emit(this.value);
    }

}
