import { Component } from '@angular/core';

@Component({
    selector: 'tms-page-card',
    template:
        `
    <nb-card class="tms-page-card tms-card">
        <nb-card-body>
            <ng-content></ng-content>
        </nb-card-body>
    </nb-card>
    `,
})

export class TmsPageCardComponent {

}
