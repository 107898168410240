export class PatientPropertiesConstants {
    static ADDRESSES_PROP = 'addresses';
    static COGNITIVE_FUNCTIONAL_LEVELS = 'cognitiveFunctionalLevels'
    static EXTERNAL_IDENTIIERS_PROP = 'externalIdentifiers';
    static PATIENT_FACILITIES_PROP = 'facilities';
    static PATIENT_INQUIRIES_PROP = 'patientInquiries';
    static PATIENT_PAYER_IDENTIFIER_PROP = 'patientPayerIdentifier';
    static REFERRALS_PROP = 'referrals';
    static ROOM_NUMBER_HISTORY_PROP = 'roomNumberHistory';
    static SETTING_HISTORY_PROP = 'settingHistory';
    static MEDICAL_HISTORY_PROP = 'medicalHistory';
    static ADVERSE_EVENTS_PROP = 'adverseEvents';
    static PATIENT_NOTES_PROP = 'patientNotes';
    static PATIENT_DEMOGRAPHICS_PROP = 'patientDemographics';
    static PATIENT_RISKS_PROP = 'risks';
    static PATIENT_DIET_ORDERS_PROP = 'dietOrders';
}