import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PatientWeek } from '@app/model';

export interface PatientPlannerPatientsState extends EntityState<PatientWeek>, ActiveState {}

const initialState: PatientPlannerPatientsState = {
  active: null,
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'patient-planner-patients', idKey: 'compositeKey' })
export class PatientPlannerPatientsStore extends EntityStore<PatientPlannerPatientsState> {

  constructor() {
    super(initialState);
  }
}

