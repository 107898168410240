import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { PagedResult, OperationResponse, MorningMeeting } from '@app/model';

@Injectable({
	providedIn: 'root',
})
export class MorningMeetingsService {

    constructor(private http: HttpClient, private urlConstantsService: UrlConstantsService) { }

    getAllMorningMeetingItems() {
        return this.http.get<PagedResult<MorningMeeting>>(`${this.urlConstantsService.MORNING_MEETINGS_URL}`)
            .pipe(map(pageResult => pageResult.items));
    }

    createMorningMeeting(morningMeeting: MorningMeeting) {
        return this.http.post<OperationResponse<MorningMeeting>>(`${this.urlConstantsService.MORNING_MEETINGS_URL}`, morningMeeting)
            .pipe(map(response => response.data));
    }

    updateMorningMeeting(morningMeeting: MorningMeeting) {
        return this.http.put<OperationResponse<MorningMeeting>>(`${this.urlConstantsService.MORNING_MEETINGS_URL}/${morningMeeting.id}`, morningMeeting)
            .pipe(map(response => response.data));
    }

    deleteMorningMeeting(morningMeetingId: string) {
        return this.http.delete(`${this.urlConstantsService.MORNING_MEETINGS_URL}/${morningMeetingId}`);
    }
}
