import { ePatientClinicalDocumentType } from '@app/model';

// USE Datalist PatientClinicalDocumentType instead of this utility. 
export class PatientClinicalDocTypeUtils {
    static  enumToString(pcdType: ePatientClinicalDocumentType): string {
        switch (pcdType) {
            case ePatientClinicalDocumentType.EVALUATION:
                return 'Eval';
            case ePatientClinicalDocumentType.PROGRESS_NOTE:
                return 'PN';
            case ePatientClinicalDocumentType.RECERT:
                return 'Rec';
            case ePatientClinicalDocumentType.DISCHARGE_SUMMARY:
                return 'DC';
            default:
                return '';
        }
    }
}