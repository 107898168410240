import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import {
    ClinicalModule,
    DataList,
    Dictionary,
    DocumentCategory,
    EvaluationTemplate,
    Facility,
    Organization,
    TimeClockEntry,
    User,
 } from '@app/model';
import { eFeatureName } from '@app/model/enum/eFeatureName';

export interface CacheManState {
    clinicalModules: Dictionary<ClinicalModule>;
    dataLists: Dictionary<DataList>;
    documentCategories: DocumentCategory[];
    evaluationTemplates: EvaluationTemplate[];
    allFacilities: Facility[];
    facilities: Facility[];
    features: Dictionary<eFeatureName[]>;
    organization: Organization;
    timeEntries: TimeClockEntry[];
    user: User;
    impersonated: boolean;
    errorMessage: string;
    pendingMessages: number;
};

export const initialState: CacheManState = {
    clinicalModules: null,
    dataLists: null,
    documentCategories: [],
    evaluationTemplates: [],
    allFacilities: [],
    facilities: [],
    features: null,
    organization: null,
    timeEntries: [],
    user: null,
    impersonated: false,
    errorMessage: null,
    pendingMessages: 0,
};

@Injectable({
    providedIn: 'root',
})
@StoreConfig({ name: 'cacheman'})
export class CacheManStore extends Store<CacheManState> {
    constructor() {
        super(initialState);
    }
}
