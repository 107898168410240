<dx-validation-group name="addSupportTicketGroup">
    <tms-modal title="Create Ticket" submitButtonText="Create" (onSubmit)="onSubmitTicket($event)"
        (onCancel)="onCancelTicket()" (onClose)="onCancelTicket()">
        <div class="row tms-row-divider">
            <div class="col-3">
                <tms-label text="Urgent" [isRequired]="true"></tms-label>
            </div>
            <div class="col">
                <dx-select-box [dataSource]="urgents$ | async" [(value)]="supportIssue.urgent"
                    displayExpr="text" valueExpr="valueInt">
                </dx-select-box>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col-3">
                <tms-label text="Subject" [isRequired]="true"></tms-label>
            </div>
            <div class="col">
                <dx-text-box [(value)]="supportIssue.title">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="A subject is required">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
            </div>
        </div>
        <div *ngIf="supportIssue.department == 1">
            <div class="row tms-row-divider">
                <div class="col-3">
                    <tms-label text="Category" [isRequired]="true"></tms-label>
                </div>
                <div class="col">
                    <dx-select-box [dataSource]="categories" [(value)]="supportIssue.category" displayExpr="value"
                        valueExpr="key" (onValueChanged)="onCategoryValueChanged($event)">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="A category is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
            </div>
            <div class="row tms-row-divider">
                <div class="col-3">
                    <tms-label text="Subcategory"></tms-label>
                </div>
                <div class="col">
                    <dx-select-box [items]="subcategories" [(value)]="supportIssue.subcategory"
                        placeholder="Please select a category to get list of subcategories"></dx-select-box>
                </div>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col-3">
                <tms-label text="First Name" [isRequired]="true"></tms-label>
            </div>
            <div class="col">
                <dx-text-box [(value)]="supportIssue.creator.firstName">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="First Name is required">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col-3">
                <tms-label text="Last Name" [isRequired]="true"></tms-label>
            </div>
            <div class="col">
                <dx-text-box [(value)]="supportIssue.creator.lastName">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="Last Name is required">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
            </div>
        </div>
        <div *ngIf="supportIssue.department == 1">
            <div class="row tms-row-divider">
                <div class="col-3">
                    <tms-label text="Email for Updates" [isRequired]="true"></tms-label>
                </div>
                <div class="col">
                    <dx-text-box [(value)]="supportIssue.creator.notificationEmail">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Email for Updates is required"></dxi-validation-rule>
                            <dxi-validation-rule type="email" message="Email is invalid"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>
            <div class="row tms-row-divider">
                <div class="col-3">
                    <tms-label text="Facility" [isRequired]="true"></tms-label>
                </div>
                <div class="col">
                    <dx-select-box [dataSource]="facilities" [(value)]="supportIssue.facilityId" displayExpr="name"
                        valueExpr="id" (onValueChanged)="onFacilityValueChanged($event)">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="A facility is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
            </div>
            <div class="row tms-row-divider">
                <div class="col-3">
                    <tms-label text="Patient"></tms-label>
                </div>
                <div class="col">
                    <dx-select-box [dataSource]="patients" [(value)]="supportIssue.patientId" displayExpr="name"
                        valueExpr="id" placeholder="Please select a facility to get list of patients"
                        (onValueChanged)="onPatientValueChanged($event)"></dx-select-box>
                </div>
            </div>
            <div class="row tms-row-divider">
                <div class="col-3">
                    <tms-label text="Case"></tms-label>
                </div>
                <div class="col">
                    <dx-select-box [dataSource]="cases" [(value)]="supportIssue.patientCaseId" displayExpr="text"
                        valueExpr="id" placeholder="Please select a patient to get list of cases"></dx-select-box>
                </div>
            </div>
        </div>
        <div class="tms-row-divider">
            <div>
                <tms-label text="Description" [isRequired]="true"></tms-label>
            </div>
            <div>
                <dx-text-area [(value)]="supportIssue.description" [height]="90">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="A description is required">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-text-area>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col"></div>
            <div class="col-3">
                <dx-file-uploader #fileuploader name="file" selectButtonText="Select file" labelText=""
                    accept=".doc,.pdf,image/*" uploadMode="useForm">
                    <dx-validator>
                        <dxi-validation-rule type="custom" [validationCallback]="validateFile">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-file-uploader>
            </div>
        </div>
    </tms-modal>
</dx-validation-group>