import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PagedResult, UserRole, OperationResponse } from '@app/model';
import { UrlConstantsService } from './url-constants.service';

@Injectable({
  providedIn: 'root'
})
export class UserRolesService {
  private _userRoles: UserRole[] = null;

  constructor(private httpClient: HttpClient,
    private urlConstantsService: UrlConstantsService) { }

  getAllRoles(bForce: boolean = false): Observable<UserRole[]> {
    if (!this._userRoles || bForce) {
      return this.httpClient.get<PagedResult<UserRole>>(`${this.urlConstantsService.USER_ROLES_URL}`)
        .pipe(map(response => response.items), tap(roles => this._userRoles = roles));
    } else {
      return of(this._userRoles);
    }
  }

  addRole(role: UserRole): Observable<UserRole> {
    return this.httpClient.post<OperationResponse<UserRole>>(`${this.urlConstantsService.USER_ROLES_URL}`, role)
      .pipe(map(response => response.data));
  }

  updateRole(roleId: string, role: UserRole): Observable<UserRole> {
    return this.httpClient.put<OperationResponse<UserRole>>(`${this.urlConstantsService.USER_ROLES_URL}/${roleId}`, role)
      .pipe(map(response => response.data));
  }
}
