import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { InterviewQuestion, InterviewAnswerField } from '@app/model';

@Component({
  selector: 'tms-question-numeric',
  styleUrls: [],
  template: `
    <dx-number-box 
      [id]="id"
      [value]="interviewAnswerField.value" 
      (onValueChanged)="onNumberBoxValueChanged($event)"
      [disabled]="disabled" 
      [min]="minValue" [max]="maxValue" [step]="1">
    </dx-number-box>
  `,
})
export class InterviewQuestionNumericComponent implements OnInit {
    @Input() question: InterviewQuestion = null;
    @Input() interviewAnswerField: InterviewAnswerField = null;
    @Input() disabled: boolean = false;
    @Input() id: string;
    @Output() onInterviewValueChanged: EventEmitter<string> = new EventEmitter<string>();

    minValue: number = 0;
    maxValue: number = null;

    constructor() {
    }

    ngOnInit(): void {
      if (this.question?.validationOptions?.minValue != null || this.question?.validationOptions?.maxValue != null) {
        this.minValue = this.question.validationOptions.minValue;
        this.maxValue = this.question.validationOptions.maxValue;
      }   
    }

    onNumberBoxValueChanged($event) {
      this.onInterviewValueChanged.emit($event.value);
    }
}
