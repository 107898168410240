<dx-date-box [calendarOptions]="calendarOptions"
             [dateSerializationFormat]="dateSerializationFormat"
             [disabled]="disabled"
             [displayFormat]="displayFormat"
             [min]="min"
             [max]="max"
             [showClearButton]="showClearButton"
             [placeholder]="placeholder"
             [readOnly]="readOnly"
             [type]="type"
             [useMaskBehavior]="useMaskBehavior" 
             [value]="value"
             [width]="width" 
             [validationMessageMode]="validationMessageMode"
             (onKeyDown)="onKeyDown($event)"
            (onOpened)="onDateBoxOpened($event)"
            (onClosed)="onDateBoxClosed($event)"
             (onValueChanged)="onDateBoxValueChanged($event)">
    <dx-validator>
        <dxi-validation-rule *ngIf="isRequired" type="custom" [message]="isRequiredMessage" 
            [reevaluate]="true" [validationCallback]="isRequiredValidationCallback">
        </dxi-validation-rule>
        <dxi-validation-rule *ngIf="isCustomValidation" type="custom" [message]="isCustomValidationMessage" 
            [reevaluate]="reevaluate" [validationCallback]="validationCallback">
        </dxi-validation-rule>
    </dx-validator>
</dx-date-box>
