import { NarrativeBuilderOptionValueText, NarrativeBuilderOptionValue } from '@app/model';

export class NarrativeBuilderUtils {
    static randomNarrativeBuildOptionValueTextIndex(narrativeFieldValueOptions: NarrativeBuilderOptionValueText[]): number {
        return Math.floor(Math.random() * narrativeFieldValueOptions.length | 0);
    }

    static findNarrativeBuilderOptionValueIndex(narrativeBuilderOptionValueId: string, narrativeBuilderOptionValues: NarrativeBuilderOptionValue[]): number {
        return narrativeBuilderOptionValues.findIndex(value => value.id == narrativeBuilderOptionValueId);
    }
}
