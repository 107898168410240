import { Component, EventEmitter, Input, OnInit, ChangeDetectionStrategy, Output } from '@angular/core';
import { TmsStep, eTmsStepStage } from '@app/model';

@Component({
  selector: 'tms-step-progress-item',
  templateUrl: './tms-step-progress-item.component.html',
  styleUrls: ['./tms-step-progress-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TmsStepProgressItemComponent implements OnInit {
  @Input() selectedIndex: number;
  @Input() tmsStep: TmsStep;
  @Input() showProgressIcon: boolean = true;
  @Output() onStepProgressItemSelected: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  get completed() {
      return this.tmsStep.stage === eTmsStepStage.complete;
  }

  get incomplete() {
      return this.tmsStep.stage === eTmsStepStage.incomplete;
  }

  get selected() {
    if (this.tmsStep.subSteps && this.tmsStep.subSteps.length > 0) {
      return this.tmsStep.subSteps.findIndex(step => step.step === this.selectedIndex) >= 0;
    } else {
      return this.tmsStep.step === this.selectedIndex;
    }
  }

  ngOnInit() {
  }

  onItemClicked(e) {
    this.onStepProgressItemSelected.emit(e);     
  }

}
