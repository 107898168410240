import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OperationResponse, PagedResult, PowerBIEmbeddedParameters, PowerBIReportItem } from '@app/model';
import { UrlConstantsService } from './url-constants.service';

@Injectable({
  providedIn: 'root'
})
export class PowerBiService {

  constructor(private httpClient: HttpClient,
    private urlConstantsService: UrlConstantsService) { }

  getPowerBIReports(): Observable<PowerBIReportItem[]> {
    return this.httpClient.get<PagedResult<PowerBIReportItem>>(`${this.urlConstantsService.POWER_BI_REPORTS}`)
      .pipe(map(response => response.items));
  }

  getPowerBIReport(id: string): Observable<PowerBIEmbeddedParameters> {
    return this.httpClient.get<OperationResponse<PowerBIEmbeddedParameters>>(`${this.urlConstantsService.POWER_BI_REPORTS}/${id}/embed2`)
      .pipe(map(response => response.data));
  }
}
