import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentRecordsService, ToastMessageService } from '@app/core';
import { SignatureRequest } from '@app/model';
import { getPdfBytesBuffer, ErrorMessageUtils, modalOptions } from '@app/utils';
import { TmsProgressBarModalComponent } from '../tms-progress-bar-modal/tms-progress-bar-modal.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

const PDF_MIME_TYPE: string = 'application/pdf';

@Component({
  selector: 'tms-document-viewer-modal',
  templateUrl: './tms-document-viewer-modal.component.html',
  styleUrls: ['./tms-document-viewer-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TmsDocumentViewerModalComponent implements OnInit {
  @Input() canDownload: boolean = true;
  @Input() documentRecordId: string = null;
  @Input() downloadDocumentLink: string = null;
  @Input() fileData: string = null;
  @Input() pinCodeRequired: boolean = false;
  @Input() viewOnly: boolean = true;
  @Input() documentRecordIdsForSigning: string[] = null;
  @Input() mimeType: string = PDF_MIME_TYPE; // default to pdf type

  confirmChecked: boolean = false;
  downloadButtonText: string = 'Download';
  imageSrc: SafeUrl = null;
  pdfError: boolean = false;
  pdfSrc = null;
  pinCode: string = null;

  private fileName: string = null;

  get signDocumentButtonDisabled(): boolean {
    if (this.pinCodeRequired) {
      return !this.pinCode || !this.confirmChecked;
    } else {
      return !this.confirmChecked;
    }
  }


  constructor(private activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef,
    private documentRecordService: DocumentRecordsService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private toastMessageService: ToastMessageService) { }

  ngOnInit() {
    if (!this.fileData && this.documentRecordId) {
      this.documentRecordService.getDocument(this.documentRecordId)
        .subscribe(documentRecord => {
          if (documentRecord.fileName && documentRecord.fileName.indexOf('.doc') >= 0) {
            // word document
            this.onDownloadDocument(null);
            this.activeModal.dismiss();
          } else {
            this.pdfSrc = getPdfBytesBuffer(documentRecord.fileData);
          }
          this.fileName = documentRecord.fileName;
          this.cdr.detectChanges();
        });
    } else if (this.fileData) {
      if (this.mimeType == PDF_MIME_TYPE) {
        this.pdfSrc = getPdfBytesBuffer(this.fileData);
      } else {
        this.imageSrc = this.sanitizer.bypassSecurityTrustUrl(`data:${this.mimeType};base64,${this.fileData}`);
      }
      this.cdr.detectChanges();
    }
  }

  onClose() {
    this.activeModal.dismiss();
  }

  onCheckBoxValueChanged(e) {
    this.confirmChecked = e.value;
    this.cdr.detectChanges();
  }

  onPinCodeChanged(e) {
    this.pinCode = e.value;
    this.cdr.detectChanges();
  }

  onError(e) {
    console.error(e);
    if (e?.name == 'InvalidPDFException' || (this.fileName && this.fileName.indexOf('.xlsx') >= 0)) {
      this.pdfError = true;
      this.cdr.detectChanges();
    } else {
      this.toastMessageService.errorNotification('Unable to retrieve pdf document');
      this.activeModal.dismiss();
    }
  }

  onDownloadDocument(e) {
    if (!this.downloadDocumentLink && this.documentRecordId) {
      this.documentRecordService.getDocumentDownloadLink(this.documentRecordId)
        .subscribe(link => {
          window.open(link, "_blank");
        }),
        (error) => {
          this.toastMessageService.errorNotification("Error retrieving document download link.");
        };
    } else if (this.downloadDocumentLink) {
      window.open(this.downloadDocumentLink, "_blank");
    }
  }

  onSignDocument(e) {
    if (this.documentRecordIdsForSigning == null) {
      const signatureRequest: SignatureRequest = this.pinCodeRequired ? { pinCode: this.pinCode } : {};
      this.documentRecordService.signDocument(this.documentRecordId, signatureRequest)
        .subscribe(response => this.activeModal.close({ success: true, documentRecordId: this.documentRecordId, newDocumentRecordId: response.documentRecordId}),
          error => {
            console.error(error);
            this.toastMessageService.errorNotification(`Signing document failed. ${ErrorMessageUtils.getDisplayErrorMessage(error, '')}`);
            this.activeModal.close({ success: false, documentRecordId: this.documentRecordId });
          });
    } else {
      // batch signing
      const signRequests = this.documentRecordIdsForSigning.map(documentRecordId => {
        return this.documentRecordService.signDocument(documentRecordId, {});
      });

      const modalRef = this.modalService.open(TmsProgressBarModalComponent, { ...modalOptions });
      modalRef.componentInstance.arrayOfObservables = signRequests;
      modalRef.componentInstance.title = 'Signing Documents';
      modalRef.componentInstance.progressBarTitle = 'Signing Progress';
      modalRef.result.then(() => {
        this.activeModal.close({ success: true });
      }); 
    }
  }
}
