<dx-validation-group>
    <tms-modal [title]="title" (onSubmit)="onAddQuickLinkItem($event)"
        (onCancel)="onCloseQuickLinkItem()" (onClose)="onCloseQuickLinkItem()">
        <div class="row">
            <div class="col-3">
                <tms-label text="Name" [isRequired]="true"></tms-label>
            </div>
            <div class="col">
                <dx-text-box [(value)]="quickLinkItemCopy.name">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="Name is required">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col-3">
                <tms-label text="Category" [isRequired]="true"></tms-label>
            </div>
            <div class="col">
                <dx-text-box [(value)]="quickLinkItemCopy.categoryName">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="Category is required">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col-3">
                <tms-label text="Description" [isRequired]="true"></tms-label>
            </div>
            <div class="col">
                <dx-text-box [(value)]="quickLinkItemCopy.description">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="Description is required">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col-3">
                <tms-label text="Primary Keywords" [isRequired]="true"></tms-label>
                (Type and hit enter to add primary keyword)
            </div>
            <div class="col">
                <dx-tag-box
                    [items]="quickLinkItemCopy.primaryKeywords"
                    [value]="quickLinkItemCopy.primaryKeywords"
                    [acceptCustomValue]="true"
                    (onCustomItemCreating)="onCustomItemCreatingPrimary($event)">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="Primary keywords are required">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-tag-box>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col-3">
                <tms-label text="Keywords" [isRequired]="false"></tms-label>
                (Type and hit enter to add keyword)
            </div>
            <div class="col">
                <dx-tag-box
                    [items]="quickLinkItemCopy.keywords"
                    [value]="quickLinkItemCopy.keywords"
                    [acceptCustomValue]="true"
                    (onCustomItemCreating)="onCustomItemCreating($event)">
                </dx-tag-box>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col-3">
                <tms-label text="Link" [isRequired]="true"></tms-label>
            </div>
            <div class="col">
                <dx-text-box [(value)]="quickLinkItemCopy.link">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="A link is required">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col-3">
                <tms-label text="Is Active" [isRequired]="false"></tms-label>
            </div>
            <div class="col">
                <dx-check-box [(value)]="quickLinkItemCopy.isActive">
                </dx-check-box>
            </div>
        </div>
    </tms-modal>
</dx-validation-group>
