import { ChangeDetectorRef, Component, Input, OnInit, ChangeDetectionStrategy, ViewChild, Output, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DxValidationGroupComponent } from 'devextreme-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InterviewGroup, InterviewAnswer} from '@app/model';
import { DeepCopyUtils } from '@app/utils';

@Component({
  selector: 'tms-interview-modal',
  templateUrl: './interview-modal.component.html',
  styleUrls: ['./interview-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InterviewModalComponent implements OnInit {
  @Input() submitButtonText: string = 'Submit';
  @Input() cancelButtonText: string = 'Cancel';
  @Input() title: string;
  @Input() interviewGroupQuestions: InterviewGroup[];

  @Output() interviewSubmitted: EventEmitter<InterviewGroup[]> = new EventEmitter<InterviewGroup[]>();

  @ViewChild(DxValidationGroupComponent) validationGroup: DxValidationGroupComponent;

  buttonsDisabled: boolean = false;
  interviewGroupQuestionsCopy: InterviewGroup[] = [];
  interviewGroupQuestions$: Observable<InterviewGroup[]>;

  constructor(private activeModalService: NgbActiveModal,
              private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.interviewGroupQuestionsCopy = DeepCopyUtils.deepCopyObject(this.interviewGroupQuestions)
    this.interviewGroupQuestions$ = of(this.interviewGroupQuestionsCopy);
    this.cdr.detectChanges();
  }

  onAnswerChanged(e: InterviewAnswer) {
      const groupIndex = this.interviewGroupQuestionsCopy.findIndex(g => g.shortName === e.group);

      if (groupIndex > -1) {
         const questionChanged = this.interviewGroupQuestionsCopy[groupIndex].questions.find(q => q.shortName === e.shortName);
         if (questionChanged) {
             questionChanged.answer = e;
         }
      }
  }

  onSubmit() {
    this.interviewSubmitted.emit(this.interviewGroupQuestionsCopy);
  }

  onCancel() {
    this.activeModalService.close();
  }

  onClose() {
    this.activeModalService.dismiss();
  }
}
