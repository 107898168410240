
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UserComponent } from '@app/pages/user/user.component';
import { UserProfileComponent } from './profile/user-profile.component';
import { UserBasicProfileComponent } from './profile/basic-profile/user-basic-profile.component';
import { UserWorkHoursComponent } from './profile/work-hours/user-work-hours.component';
import { TimeClockComponent } from './time-clock/time-clock.component';
import { TimeClockModalComponent } from './time-clock/time-clock-modal/time-clock-modal.component';
import { UserImpersonateComponent } from './profile/user-impersonate/user-impersonate.component';
import { UserWorkHoursManagementComponent } from './work-hours-management/user-work-hours-management.component';
import { UserPermissionsComponent } from './profile/permissions/user-permissions.component';

const routes: Routes = [{
    path: '',
    component: UserComponent,
    children: [
      {
        path: 'profile',
        component: UserProfileComponent,
      },
      {
        path: 'work-hours-management',
        component: UserWorkHoursManagementComponent,
      },
    ],
}];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class UserRoutingModule {
}

export const routedComponents = [
    UserComponent,
    UserProfileComponent,
    UserBasicProfileComponent,
    UserImpersonateComponent,
    UserWorkHoursComponent,
    UserWorkHoursManagementComponent,
    UserPermissionsComponent,
    TimeClockComponent,
    TimeClockModalComponent,
];
