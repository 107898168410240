import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlConstantsService } from './url-constants.service';
import {
  OperationResponse,
  ExportFieldOverride,
} from '@app/model';

@Injectable({
  providedIn: 'root',
})
export class ExportFieldOverrideService {

  constructor(private httpClient: HttpClient,
    private urlConstantsService: UrlConstantsService) { }

  getAllByFacility(facilityId: string): Observable<ExportFieldOverride[]> {
    return this.httpClient.get<OperationResponse<ExportFieldOverride[]>>(`${this.urlConstantsService.EXPORT_FIELD_OVERRIDE_URL}/facility/${facilityId}`)
        .pipe(map(response => response.data));
  }
  getAllByFacilityAndFieldShortName(facilityId: string, fieldShortName: string): Observable<ExportFieldOverride[]> {
    return this.httpClient.get<OperationResponse<ExportFieldOverride[]>>(`${this.urlConstantsService.EXPORT_FIELD_OVERRIDE_URL}/facility/${facilityId}?fieldShortName=${fieldShortName}`)
        .pipe(map(response => response.data));
  }

  getAllByClientAndFieldShortName(clientId: string, fieldShortName: string): Observable<ExportFieldOverride[]> {
    return this.httpClient.get<OperationResponse<ExportFieldOverride[]>>(`${this.urlConstantsService.EXPORT_FIELD_OVERRIDE_URL}/client/${clientId}?fieldShortName=${fieldShortName}`)
        .pipe(map(response => response.data));
  }

  createExportFieldOverride(exportFieldOverride: ExportFieldOverride) {
    return this.httpClient.post<OperationResponse<ExportFieldOverride>>(this.urlConstantsService.EXPORT_FIELD_OVERRIDE_URL, exportFieldOverride)
        .pipe(map(response => response.data));
  }

  updateExportFieldOverride(exportFieldOverride: ExportFieldOverride) {
    return this.httpClient.put<OperationResponse<ExportFieldOverride>>(`${this.urlConstantsService.EXPORT_FIELD_OVERRIDE_URL}/${exportFieldOverride.id}`, exportFieldOverride)
        .pipe(map(response => response.data));
  }

  deleteExportFieldOverride(id: string) {
    return this.httpClient.delete<OperationResponse<void>>(`${this.urlConstantsService.EXPORT_FIELD_OVERRIDE_URL}/${id}`)
        .pipe(map(response => response.data));
  }
}
