import { eDesignation } from '@app/model';

export class DesignationUtils {
    static isTherapist(designation: eDesignation): boolean {
        switch (designation)
        {
            case eDesignation.Pt:
            case eDesignation.Ot:
            case eDesignation.Slp:
                return true;
        }
        return false;
    }

    static isAssistant(designation: eDesignation): boolean {
        switch(designation)
        {
            case eDesignation.Pta:
            case eDesignation.Cota:
                return true;
        }
        return false;
    }
}