import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { AdalService } from 'adal-angular4';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { resetStores } from "@datorama/akita";
import { CookieService } from 'ngx-cookie-service';

import { AppConfigService } from '../../../app-config.service';
import { CacheManService } from '../cacheman.service';
import { FacilitiesService } from '../facilities.service';
import { AppSettingEnvironmentConstants, RouteConstants } from '../../constants';
import { UrlConstantsService } from '../url-constants.service';
import { TmsMessageModalComponent } from '../../../shared/tms-message-modal';
import { modalOptions } from '@app/utils';

import {
  InitialSyncModel,
  KeyValuePair,
} from '@app/model';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
  private _isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _redirectUrl: string = `/${RouteConstants.HOME}`;

  constructor(private http: HttpClient,
              private modalService: NgbModal,
              private cacheMan: CacheManService,
              private urlConstantsService: UrlConstantsService,
              private appConfigService: AppConfigService,
              public adalService: AdalService,
              private facilitiesService: FacilitiesService,
              private router: Router,
              private cookieService: CookieService) {
  }

  get adalConfig() {
    const adalConfig = {
      clientId: this.appConfigService.appConfig.clientId,
      tenant: this.appConfigService.appConfig.tenant,
      redirectUri: this.urlBase,
      anonymousEndpoints: [
        this.urlConstantsService.ANNOUNCEMENTS_PUBLIC_URL,
        this.urlConstantsService.STATUS_URL,
      ],
      // postLogoutRedirectUri: this.urlBase + this.logoutUrlSuffix,
      popUp: true,
    }
    return adalConfig;
  }

  get isAuthenticated() {
    return this.adalService.userInfo && this.adalService.userInfo.authenticated;
  }

  get isAuthenticated$() {
    return this._isAuthenticated;
  }

  get logoutUrl() {
    return `https://login.microsoftonline.com/${this.adalConfig.tenant}/oauth2/logout`;
  }

  get redirectUrl() {
    return this._redirectUrl;
  }

  set redirectUrl(value: string) {
    if (value && value != '') {
      const hash = value.indexOf('#');
      const url = hash >= 0 ? value.substring(hash + 1) : `/${RouteConstants.HOME}`;
      this._redirectUrl = url;
    }
  }

  get userInfo() {
    return this.adalService.userInfo;
  }

  private get urlBase() {
    const href = window.location.href; 
    const loggedOut = href.indexOf('/public');
    const url = loggedOut >= 0 ? `${window.location.protocol}//${window.location.host}` : href;
    return url;
  }

  private get logoutUrlSuffix() {
      return '/#/home';
  }

  resetRedirectRoute() {
    this.redirectUrl = `/${RouteConstants.HOME}`;
  }

  getSessionStart() {
    // reset stores after user is authenticated
    resetStores();
    this.clearCookies();
    const baseAPIUrl = `${this.urlConstantsService.SESSIONS_URL}/start`;
    this.cacheMan.setCacheStoreLoading(true);
    this.http.get<InitialSyncModel>(baseAPIUrl, { observe: 'response' })
      .subscribe(response => {
        if (response.body.errorMessage) {
          this.resetRedirectRoute();
         const modalRef = this.modalService.open(TmsMessageModalComponent, modalOptions);
         modalRef.componentInstance.message = response.body.errorMessage;
         modalRef.result.then(result => {
          this.router.navigate(['./public'], { queryParams: { logout: true }});
         });
        } else {
          this.cacheMan.setSessionStartData(response.body);
          this.notifyUserAuthenticated(this.isAuthenticated);

          // check if we're in context of a facility route. If we are, an active facility may already be set
          // or will be set 
          if (!this.router.url.toLowerCase().includes(RouteConstants.FACILITY_ROOT)) {
            // set to user's primary facility 
            if (response.body.user && response.body.user.facilityIds && response.body.user.facilityIds.length > 0) {
              this.facilitiesService.activeFacilityId = response.body.user.facilityIds[0];
            }
          }
        }
      },
      error => this.cacheMan.setCacheStoreError(error),
      () => this.cacheMan.setCacheStoreLoading(false));
  }

  handleWindowCallback() {
    this.adalService.handleWindowCallback();
  }

  // called when page is refreshed or navigating to direct url 
  init(adalConfig) {
    this.adalService.init(adalConfig);
    this.redirectUrl = this.adalConfig.redirectUri; // to navigate to direct url 
    if (this.isAuthenticated) {
      if (this.appConfigService.appConfig.environment == null ||
        this.appConfigService.appConfig.environment.toLowerCase() === AppSettingEnvironmentConstants.DEVELOPMENT || this.appConfigService.appConfig.environment.toLowerCase() === AppSettingEnvironmentConstants.QA) {
        console.log(this.userInfo.token);
      }

      this.getSessionStart();
    }
  }

  login() {
    this.adalService.login();
  }

  loggedIn() {
    this.adalService.refreshDataFromCache();
    if (this.isAuthenticated) {
      this.getSessionStart();
    } else {
      console.log('User not authenticated inside callback');
      const modalRef = this.modalService.open(TmsMessageModalComponent, modalOptions);
      modalRef.componentInstance.message = 'You do not have permission to access this application.';
      modalRef.result.then(result => this.notifyUserAuthenticated(this.isAuthenticated));
    }
  }

  logout() {
    this.adalService.logOut();
    this.adalService.clearCache();
    this.adalService.refreshDataFromCache();
    this.notifyUserAuthenticated(false);
  }

  clearCookies() {
    if (this.cookieService.check('TmcUserImpersonationCookie')) {
      this.cookieService.delete('TmcUserImpersonationCookie', '/');
    }
  }

  cleanUp() {
    this.resetRedirectRoute();
    this.clearCookies();
  }

  getBearerTokenHeader(url: string): Observable<KeyValuePair<string, string>> {
    const resource = this.adalService.getResourceForEndpoint(url);
    return this.adalService.acquireToken(resource)
      .pipe(map(token => {
        return { key: 'Authorization', value: 'Bearer ' + token }
      }));
  }

  private notifyUserAuthenticated(isAuthenticated: boolean) {
    this._isAuthenticated.next(isAuthenticated);
  }

}
