<form id="profileForm" (ngSubmit)="onSubmit()">
    <dx-form id="profForm" #profForm [(formData)]="profileForm" [showColonAfterLabel]="true"
        [showValidationSummary]="true" validationGroup="profileFormData">
        <dxi-item dataField="firstName">
            <dxi-validation-rule type="required" message="First Name is required">
            </dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="lastName">
            <dxi-validation-rule type="required" message="Last Name is required">
            </dxi-validation-rule>
        </dxi-item>
        <dxi-item [label]="{ text: 'Primary Facility' }">
            <dx-select-box [dataSource]="primaryFacilitySource" [(value)]="selectedPrimaryFacility" displayExpr="name"
                valueExpr="id" [readOnly]="true">
            </dx-select-box>
        </dxi-item>
        <dxi-item dataField="employee.facilityIds" [label]="{ text: 'Other Facilities' }">
            <dx-tag-box [items]="otherFacilitySource" [(value)]="selectedFacilityValues" displayExpr="name"
                valueExpr="id" [hideSelectedItems]="true" [readOnly]="true">
            </dx-tag-box>
        </dxi-item>
        <dxi-item dataField="employee.discipline" [label]="{ text: 'Discipline' }" editorType="dxSelectBox"
            [editorOptions]="{ dataSource: disciplines, 
                              displayExpr: 'shortName',
                              valueExpr: 'valueInt',
                              readOnly: true }">
        </dxi-item>
        <dxi-item dataField="pinCode" [label]="{ text: 'Old PIN Code' }" [editorOptions]="{ mode: 'password' }">
            <dxi-validation-rule type="pattern" [pattern]="pinPattern" message="PIN must be 4 - 6 digits."></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="newPinCode" [editorOptions]="{ mode: 'password' }">
            <dxi-validation-rule type="pattern" [pattern]="pinPattern" message="PIN must be 4 - 6 digits."></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="confirmPinCode" [editorOptions]="{ mode: 'password' }">
            <dxi-validation-rule type="pattern" [pattern]="pinPattern" message="PIN must be 4 - 6 digits."></dxi-validation-rule>
            <dxi-validation-rule type="compare" [comparisonTarget]="pinComparison" message="New PIN and Confirm PIN do not match">
            </dxi-validation-rule>
        </dxi-item>
    </dx-form>
    <div class="tms-submit-group">
        <dx-button class="tms-primary-button" [useSubmitBehavior]="true" validationGroup="profileFormData" text="Save Profile">
        </dx-button>
    </div>
</form>