import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CloseOutsStore, CloseOutState } from './close-outs.store';

@Injectable({
  providedIn: 'root',
})
export class CloseOutsQuery extends QueryEntity<CloseOutState> {

  constructor(protected store: CloseOutsStore) {
    super(store);
  }

}