<form id="profileForm" (ngSubmit)="onSubmit($event)">
    <div *ngFor="let fw of facilityWorkHours; index as i" #workHoursForm>
        <nb-accordion>
            <nb-accordion-item [expanded]="fw.facilityId === primaryFacilityId">
                <nb-accordion-item-header>{{ getFacilityName(fw.facilityId) + ' Hours' }}</nb-accordion-item-header>
                <nb-accordion-item-body>
                    <dx-form id="availabilityForm" [(formData)]="facilityWorkHours[i]" [showColonAfterLabel]="true"
                        [showValidationSummary]="true" validationGroup="availabilityData">
                        <dxi-item dataField="sundayAvailableMinutes" [label]="{ text: 'Sunday Minutes' }"
                            editorType="dxNumberBox" [editorOptions]="{min: 0, step: 0}">
                        </dxi-item>
                        <dxi-item dataField="mondayAvailableMinutes" [label]="{ text: 'Monday Minutes' }"
                            editorType="dxNumberBox" [editorOptions]="{min: 0, step: 0}">
                        </dxi-item>
                        <dxi-item dataField="tuesdayAvailableMinutes" [label]="{ text: 'Tuesday Minutes' }"
                            editorType="dxNumberBox" [editorOptions]="{min: 0, step: 0}">
                        </dxi-item>
                        <dxi-item dataField="wednesdayAvailableMinutes" [label]="{ text: 'Wednesday Minutes' }"
                            editorType="dxNumberBox" [editorOptions]="{min: 0, step: 0}">
                        </dxi-item>
                        <dxi-item dataField="thursdayAvailableMinutes" [label]="{ text: 'Thursday Minutes' }"
                            editorType="dxNumberBox" [editorOptions]="{min: 0, step: 0}">
                        </dxi-item>
                        <dxi-item dataField="fridayAvailableMinutes" [label]="{ text: 'Friday Minutes' }"
                            editorType="dxNumberBox" [editorOptions]="{min: 0, step: 0}">
                        </dxi-item>
                        <dxi-item dataField="saturdayAvailableMinutes" [label]="{ text: 'Saturday Minutes' }"
                            editorType="dxNumberBox" [editorOptions]="{min: 0, step: 0}">
                        </dxi-item>
                    </dx-form>
                </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
        <hr>
    </div>
    <div class="tms-submit-group">
        <dx-button class="tms-primary-button" [useSubmitBehavior]="true" validationGroup="availabilityData"
            text="Save Availability"></dx-button>
    </div>
</form>