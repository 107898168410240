import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { OperationResponse, ExportProfile, ExportField, PagedResult, ExportProfileRun, ExportProfileRunRequest, ExportProfileScheduledRun } from '@app/model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class ExportProfilesService {
    constructor(private http: HttpClient,
		private urlConstantsService: UrlConstantsService) {
    }

    getAll(): Observable<ExportProfile[]> {
		  return this.http.get<PagedResult<ExportProfile>>(`${this.urlConstantsService.EXPORTS_PROFILE_URL}`)
			    .pipe(map(response => response.items));
    }
    
    getById(exportProfileId: string) {
      return this.http.get<ExportProfile>(`${this.urlConstantsService.EXPORTS_PROFILE_URL}/${exportProfileId}`);
    }

    addNew(profile: ExportProfile) {
      return this.http.post<OperationResponse<ExportProfile>>(`${this.urlConstantsService.EXPORTS_PROFILE_URL}`, profile)
            .pipe(map(response => response.data))
    }

    updateProfile(profile: ExportProfile) {
      return this.http.put<OperationResponse<ExportProfile>>(`${this.urlConstantsService.EXPORTS_PROFILE_URL}/${profile.id}`, profile)
          .pipe(map(response => response.data))
  }

    cloneProfile(id: string) {
      return this.http.get<OperationResponse<ExportProfile>>(`${this.urlConstantsService.EXPORTS_PROFILE_URL}/${id}/duplicate`)
        .pipe(map(response => response.data))
    }

    deleteProfile(id: string) {
      return this.http.delete(`${this.urlConstantsService.EXPORTS_PROFILE_URL}/${id}`);
    }

  // Exports fields
    getExportFields(category: number = null) {
      return this.http.get<PagedResult<ExportField>>(`${this.urlConstantsService.EXPORTS_URL}/fields?category=${category}`)
        .pipe(map(pageResult => pageResult.items));
    }

    // run export profile
    runExportProfile(exportRunProfile: ExportProfileRunRequest) {
      return this.http.post<boolean>(`${this.urlConstantsService.EXPORTS_URL}/run/`, exportRunProfile);
    }

    getAllExportProfileRuns(filterDate: Date, facilityId: string, exportProfileId: string) {
      filterDate = moment(filterDate).toDate();
      const month = (filterDate.getMonth() + 1);
      const year = filterDate.getFullYear();

      return this.http.get<PagedResult<ExportProfileRun>>(`${this.urlConstantsService.EXPORTS_URL}/exportruns?month=${month}&year=${year}&facilityId=${facilityId}&exportProfileId=${exportProfileId}`)
        .pipe(map(pageResult => pageResult.items));
    }

    verifyExportProfileRun(exportProfileRun: ExportProfileRun) {
      return this.http.put<boolean>(`${this.urlConstantsService.EXPORTS_URL}/exportruns/verification`, exportProfileRun);
    }

    deleteExportProfileRun(id: string) {
      return this.http.delete(`${this.urlConstantsService.EXPORTS_URL}/exportruns/${id}`);
    }

    // scheduled profile runs
    getAllScheduledRuns(): Observable<ExportProfileScheduledRun[]> {
      return this.http.get<PagedResult<ExportProfileScheduledRun>>(`${this.urlConstantsService.EXPORTS_PROFILE_SCHEDULED_RUN_URL}`)
        .pipe(map(response => response.items));
    }

    getScheduledRunById(scheduledRunId: string) {
      return this.http.get<ExportProfileScheduledRun>(`${this.urlConstantsService.EXPORTS_PROFILE_SCHEDULED_RUN_URL}/${scheduledRunId}`);
    }

    addNewScheduledRun(schedule: ExportProfileScheduledRun) {
      return this.http.post<OperationResponse<ExportProfileScheduledRun>>(`${this.urlConstantsService.EXPORTS_PROFILE_SCHEDULED_RUN_URL}`, schedule)
            .pipe(map(response => response.data))
    }

    updateScheduledRun(schedule: ExportProfileScheduledRun) {
      return this.http.put<OperationResponse<ExportProfileScheduledRun>>(`${this.urlConstantsService.EXPORTS_PROFILE_SCHEDULED_RUN_URL}/${schedule.id}`, schedule)
          .pipe(map(response => response.data))
    }

    deleteScheduledRun(id: string) {
      return this.http.delete(`${this.urlConstantsService.EXPORTS_PROFILE_SCHEDULED_RUN_URL}/${id}`);
    }
}
