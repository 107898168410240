import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlConstantsService } from './url-constants.service';
import { ClinicalMeasurement, GoalStatement, PagedResult, OperationResponse } from '@app/model';

@Injectable({
    providedIn: 'root'
  })
export class GoalStatementsService {
    constructor(private httpClient: HttpClient,
        private urlConstantsService: UrlConstantsService) {}

    getGoalStatements(): Observable<GoalStatement[]> {
        return this.httpClient.get<PagedResult<GoalStatement>>(`${this.urlConstantsService.GOAL_STATEMENTS_URL}`)
            .pipe(map(response => response.items));
    }

    getModuleMeasurements(moduleShortName: string): Observable<string[]> {
        return this.httpClient.get<PagedResult<string>>(`${this.urlConstantsService.GOAL_STATEMENTS_URL}/module/${moduleShortName}`)
            .pipe(map(response => response.items));
    }

    getMeasurement(moduleShortName: string, measurementShortName: string): Observable<ClinicalMeasurement> {
            return this.httpClient.get<OperationResponse<ClinicalMeasurement>>(`${this.urlConstantsService.GOAL_STATEMENTS_URL}/measurement/${moduleShortName}/${measurementShortName}`)
            .pipe(map(response => response.data));
    }

    addGoalStatement(goalStatement: GoalStatement): Observable<GoalStatement> {
        return this.httpClient.post<OperationResponse<GoalStatement>>(`${this.urlConstantsService.GOAL_STATEMENTS_URL}`, goalStatement)
            .pipe(map(response => response.data));
    }

    updateGoalStatement(goalStatement: GoalStatement): Observable<GoalStatement> {
        return this.httpClient.put<OperationResponse<GoalStatement>>(`${this.urlConstantsService.GOAL_STATEMENTS_URL}/${goalStatement.id}`, goalStatement)
            .pipe(map(response => response.data));
    }

    deleteGoalStatement(id: string) {
        return this.httpClient.delete(`${this.urlConstantsService.GOAL_STATEMENTS_URL}/${id}`);
    }
}