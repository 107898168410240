import {
    NarrativeBuilderOption,
    NarrativeBuilderList,
    PatientGoalsProperties,
} from '@app/model';
import { PatientGoalConstants } from '../../constants';
import * as idb from './id-builder-utils';

export function getPatientGoalsNarrativeBuilder(): NarrativeBuilderList {
    const PG = 'PG';

    const RTP = 'RTP'; // Return to PLOF
    const PGRTPBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(PG, RTP),
        text: 'Return to PLOF',
        values: [
            {
                id: idb.getNBOptionValueId(PG, RTP),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PG, RTP, 1),
                        text: 'Return to prior level'
                    },
                ],
            },
        ],
        selectedValue: null, 
        property: PatientGoalsProperties.RETURN_PLOF,
    };

    // Return Home
    const RH = 'RH';
    const PGReturnHomeBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(PG, RH),
        text: 'Return Home',
        values: [
            {
                id: idb.getNBOptionValueId(PG, RH),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PG, RH, 1),
                        text: 'Return home',
                    },
                ],
            },
        ],
        selectedValue: null,
        property: PatientGoalsProperties.RETURN_HOME,
    };

    // Self care
    const SC = 'SC';
    const PGSelfCareBuilderOptionValueText = {
        id: idb.getNBOptionValueTextId(PG, SC, 1),
        text: 'self care',
    };
    const PGSelfCareBuilderOptionValue = {
        id: idb.getNBOptionValueId(PG, SC),
        optionValues: [
            PGSelfCareBuilderOptionValueText,
        ],
    };
    const PGSelfCareBuilderOption: NarrativeBuilderOption = {
        id: 'PGSelfCareBO',
        text: '(I) Self-care',
        values: [
            PGSelfCareBuilderOptionValue,
        ],
        selectedValue: null,
        property: PatientGoalsProperties.SELF_CARE,
    };

    // Walk safely
    const WS = 'WS';
    const PGWalkSafelyBuilderOptionValueText = {
        id: idb.getNBOptionValueTextId(PG, WS, 1),
        text: 'walk safely',
    };
    const PGWalkSafelyBuilderOptionValue = {
        id: idb.getNBOptionValueId(PG, WS),
        optionValues: [
            PGWalkSafelyBuilderOptionValueText,
        ],
    };
    const PGWalkSafelyBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(PG, WS),
        text: 'Walk Safely',
        values: [
            PGWalkSafelyBuilderOptionValue,
        ],
        selectedValue: null, 
        property: PatientGoalsProperties.WALK_SAFELY,
    };

    // Nonverbal
    const NV = 'NV';
    const PGNonverbalBuilderOptionValueText = {
        id: idb.getNBOptionValueTextId(PG, NV, 1),
        text: 'nonverbal',
    };

    const PGNonverbalBuilderOptionValue = {
        id: idb.getNBOptionValueId(PG, NV),
        optionValues: [
            PGNonverbalBuilderOptionValueText,
        ],
    };
    const PGNonverbalBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(PG, NV),
        text: 'Nonverbal',
        values: [
            PGNonverbalBuilderOptionValue,
        ],
        selectedValue: null,
        property: PatientGoalsProperties.NONVERBAL,
    };

    // Unclear
    const UC = 'UC';
    const PGUnclearBuilderOptionValueText = {
        id: idb.getNBOptionValueTextId(PG, UC, 1),
        text: 'unclear',
    };
    const PGUnclearBuilderOptionValue = {
        id: idb.getNBOptionValueId(PG, UC),
        optionValues: [
            PGUnclearBuilderOptionValueText,
        ],
    };
    const PGUnclearBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(PG, UC),
        text: 'Unclear',
        values: [
            PGUnclearBuilderOptionValue,
        ],
        selectedValue: null,
        property: PatientGoalsProperties.UNCLEAR,
    };

    const patientGoalList: NarrativeBuilderList = {
        options: {
            [PatientGoalConstants.PLOF_OPTION]: PGRTPBuilderOption,
            [PatientGoalConstants.RETURN_HOME_OPTION]: PGReturnHomeBuilderOption,
            [PatientGoalConstants.SELF_CARE_OPTION]: PGSelfCareBuilderOption,
            [PatientGoalConstants.WALK_SAFELY_OPTION]: PGWalkSafelyBuilderOption,
            [PatientGoalConstants.NONVERBAL_OPTION]: PGNonverbalBuilderOption,
            [PatientGoalConstants.UNCLEAR_OPTION]: PGUnclearBuilderOption,
        },
    };

    return patientGoalList;
}