import { TemplateObject } from '../../template-object';

export const REHAB_POTENTIAL_TEMPLATE: string = `{{#if statedGoals}}{{statedGoals}}{{/if}}`;

export const RehabPotentialProperties = {
    STATED_GOALS: 'statedGoals',
}

export class RehabPotentialTemplate implements TemplateObject {
    statedGoals: string = '';

    constructor() {}

    preCompileTemplateObj(): void {}
}