import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DxValidationGroupComponent } from 'devextreme-angular';

@Component({
    selector: 'tms-modal',
    templateUrl: './tmsmodal.component.html',
    styleUrls: ['./tmsmodal.component.scss'],
})
export class TmsModalComponent implements OnInit {
    @Input() title: string = null;
    @Input() submitButtonText: string = 'Submit';
    @Input() cancelButtonText: string = 'Cancel';
    @Input() submitButtonDisabled: boolean = false;
    @Input() cancelButtonDisabled: boolean = false;
    @Input() submitButtonVisible: boolean = true;
    @Input() cancelButtonVisible: boolean = true;
    @Input() closeButtonVisible: boolean = true;
    @Input() validationGroup?: string = null;
    @Output() onSubmit: EventEmitter<Object> = new EventEmitter<Object>();
    @Output() onCancel: EventEmitter<Object> = new EventEmitter<Object>();
    @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
    @ViewChild(DxValidationGroupComponent) validationGroupTarget: DxValidationGroupComponent;
    constructor() { }

    ngOnInit() { }

    //  The upgrade to devextreme 22.2 broke validation. Before onClick button event would use default 
    //      validation group. In order for validation to occur, each validator needs to specify the validation group.
    //      Getting the validation group instance here and passing it up is a way around not having to go through
    //      and modify each validator. The onSubmit and onCancel are expected to pass a button event, so we set
    //      the validationGroup to the instance of this modal's validationGroup.
    submit(e) {
        const instance = this.validationGroupTarget.instance;
        this.onSubmit.emit({ validationGroup: instance });
    }

    cancel(e) {
        const instance = this.validationGroupTarget.instance;
        this.onCancel.emit({ validationGroup: instance });
    }

    close() {
        this.onClose.emit();
    }

}
