<div class="row">
    <div class="col-9">
        <div class="tms-field">
            <div class="tms-field-label">
                Select a user to impersonate:
            </div>
            <div class="tms-field-value">
                <tms-typeahead-dropdown [dataSource]="users" [displayExpr]="getEmployeeDisplayExpr" valueExpr="email"
                    placeholderText="Type to search for user"
                    [typeAheadValueChanged]="onTypeAheadValueChanged"
                    (onValueChanged)="onUserValueChanged($event)">
                </tms-typeahead-dropdown>
            </div>
        </div>
    </div>
    <div class="col-2">
        <dx-button class="tms-primary-button" text="Impersonate" [disabled]="isImpersonating$ | async" (onClick)="onImpersonateClicked()"></dx-button>
    </div>
</div>

