export class UserGreeting {
    static createGreeting(hour: number, name: string, additionalMessage?: string | null | undefined): string {
        let dayPeriod: string = this.hourToDayPeriod(hour);

        let greeting = `Good ${dayPeriod}, ${name}`;
        if (additionalMessage) {
            greeting += ` - ${additionalMessage}`;
        }
        return greeting;
    }

    private static hourToDayPeriod(hour: number) : string {
        let dayPeriod: string = "Day";

        if (hour >= 0 && hour < 12) {
            dayPeriod = "Morning";
        } else if (hour >= 12 && hour <= 17) {
            dayPeriod = "Afternoon";
        } else if (hour >= 17 && hour <= 24) {
            dayPeriod = "Evening";
        }

        return dayPeriod;
    }
}