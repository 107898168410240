export class ClinicalDocumentInterviewGroupConstants {
    static CONTRIBUTING_GROUP_SHORTNAME = 'ContributingFactors';
    static GOAL_IMPACT_SHORTNAME = 'GoalImpact';
    static NOTES_GROUP_SHORTNAME = 'Notes';
    static PRIMARY_GROUP_SHORTNAME = 'Primary';
    static ANCILLARY_GROUP_SHORTNAME = 'Ancillary';
    static DEFICIT_STRATEGY = "DeficitStrategy";
    static TREATMENT_STRATEGY = "TreatmentStrategy";
}

export class ClincialDocumentInterviewTabConstants {
    static DEFICIT_STRATEGY = 'DeficitStrategy';
}