import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuickLinksService } from '@app/core';
import { QuickLinkItem } from '@app/model';
import { DeepCopyUtils } from '@app/utils';

@Component({
  selector: 'tms-add-edit-quick-link-item',
  templateUrl: './add-edit-quick-link-item.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditQuickLinkItemComponent implements OnInit {
  quickLinkItemCopy: QuickLinkItem = {};
  title: string = '';

  private bEditMode: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) private data: QuickLinkItem,
    private matDialogRef: MatDialogRef<AddEditQuickLinkItemComponent>,
    private quickLinksService: QuickLinksService) { }

  ngOnInit(): void {
    if (this.data) {
      this.quickLinkItemCopy = DeepCopyUtils.deepCopyObject(this.data);
      this.bEditMode = true;
      this.title = 'Edit Quick Link Item';
    } else {
      this.bEditMode = false;
      this.title = 'Add Quick Link Item';
      this.quickLinkItemCopy.isActive = true;
      this.quickLinkItemCopy.link = 'http://';
    }

    if (!this.quickLinkItemCopy.keywords) {
      this.quickLinkItemCopy.keywords = [];
    }
    if (!this.quickLinkItemCopy.primaryKeywords) {
      this.quickLinkItemCopy.primaryKeywords = [];
    }
  }

  onCloseQuickLinkItem() {
    this.matDialogRef.close();
  }

  onAddQuickLinkItem(e) {
    const validationResult = e.validationGroup.validate();
    if (validationResult.isValid) {
      if (this.bEditMode) {
        this.quickLinksService.updateQuickLinkItem(this.quickLinkItemCopy)
          .subscribe(() => this.matDialogRef.close());
      } else {
        this.quickLinksService.addQuickLinkItem(this.quickLinkItemCopy)
          .subscribe(() => this.matDialogRef.close());
      }
    }
  }

  onCustomItemCreatingPrimary(args) {
    let newValue = args.text;
    this.quickLinkItemCopy.primaryKeywords.push(newValue);
    args.customItem = newValue;
  }

  onCustomItemCreating(args) {
    let newValue = args.text;
    this.quickLinkItemCopy.keywords.push(newValue);
    args.customItem = newValue;
  }

}
