import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, Output, EventEmitter, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnalyticsService, CacheManService, DataListConstants, DocumentRecordsService, SupportService } from '@app/core';
import { SupportIssue, DataListItem, KnowledgeBaseItem } from '@app/model';

interface PreSupportTicketEvent {
  createTicket: boolean;
  supportIssue?: SupportIssue;
}

@Component({
  selector: 'tms-pre-support-ticket',
  templateUrl: './pre-support-ticket.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreSupportTicketComponent implements OnInit {
  @Output() onCreateTicketValueChanged: EventEmitter<PreSupportTicketEvent> = new EventEmitter<PreSupportTicketEvent>();

  departments$: Observable<DataListItem[]> = null;
  knowledgeBaseItems: KnowledgeBaseItem[] = [];
  selectedSectionKeys = [];
  supportIssue: SupportIssue = null;
  
  constructor(private analyticsService: AnalyticsService,
    private cacheManService: CacheManService,
    private cdr: ChangeDetectorRef,
    private documentRecordsService: DocumentRecordsService,
    private supportService: SupportService) { }

  ngOnInit() {
    this.departments$ = this.cacheManService.getDataListItems$(DataListConstants.SUPPORT_ISSUE_DEPARTMENT).pipe(map(dlitems => dlitems.filter(dli => dli.value != '0')));
    this.supportIssue = {
      department: 1, // Hardcode to Ignite department 
      title: '',
    }
  }

  onClose() {
    this.onCreateTicketValueChanged.emit({ createTicket: false });
  }

  createTicket(e) {
    const validationResult = e.validationGroup.validate();
    if (validationResult.isValid) {
      this.analyticsService.trackEvent('Support Ticket - User clicks Create Ticket',
      {
        subject: this.supportIssue.title,
      });
    
      this.onCreateTicketValueChanged.emit({
        createTicket: true,
        supportIssue: this.supportIssue,
      });
    } 
  }

  onKbItemClicked(kbItem: KnowledgeBaseItem) {
    this.analyticsService.trackEvent('Support Ticket - User clicks Knowledge Base Item',
    {
      subject: this.supportIssue.title,
      kbItemName: kbItem.name,
      kbItemId: kbItem.id,
    });

    if (kbItem.videoSrc) {
      window.open(kbItem.videoSrc);
    } else {
      // documentRecordId 
      this.documentRecordsService.getDocumentDownloadLink(kbItem.documentRecordId)
        .subscribe(link => {
          window.open(link);
        });
    }
  }

  onSubjectValueChanged(e) {
    this.supportIssue.title = e;
    this.supportService.getKnowledgeBaseItemSuggestions(this.supportIssue)
      .subscribe(kbItems => {
        this.knowledgeBaseItems = kbItems;
        this.cdr.detectChanges();
      })
  }

}
