export enum PatientRiskType {
    Unspecified = 0,
    FallsWithoutInjury = 2,
    AdlLoss = 3,
    WoundRisk = 4,
    WeightLoss = 5,
    Incontinence = 6,
    IndependentMovement = 7,
    FallsWithMajorInjury = 8,
    NotAtRisk = 9,
}