import { AfterViewInit, Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, OnDestroy, ViewChild, DoCheck } from '@angular/core';
import { NbMenuItem, NbMenuComponent, NbMenuService, NbSidebarService } from '@nebular/theme';
import { zip } from 'rxjs';
import { filter, takeUntil, take, distinctUntilChanged } from 'rxjs/operators';
import { AppConfigService } from '../../../app-config.service';
import { AppSettingEnvironmentConstants, IgniteStatusService, LoadingService, MenuService, USER_AUTHENTICATION_SET, ACTIVE_FACILITY_SET, HOME_LINK_CLICKED, PERMISSIONS_SET } from '@app/core';
import { OnDestroyComponent } from '@app/shared';
import * as menu from '../pages-menu';
import { CacheManService, RouteConstants, UIPermissionConstants } from '@app/core';

// TODO: move layouts into the framework
@Component({
  selector: 'tms-layout',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './layout.component.html',
})
export class LayoutComponent extends OnDestroyComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(NbMenuComponent) nbMenu: NbMenuComponent;

  appVersion: string = null;
  appVersionExtended: string = null;
  bShowAppVersion: boolean = true;
  isAuthenticated: boolean = false;
  loading: boolean = false;
  menu: NbMenuItem[] = menu.MENU_ITEMS;

  constructor(private cdr: ChangeDetectorRef,
    private appConfigService: AppConfigService,
    private cacheManService: CacheManService,
    private igniteStatusService: IgniteStatusService,
    private loadingService: LoadingService,
    private menuService: MenuService,
    private nbMenuService: NbMenuService,
    private nbSideBarService: NbSidebarService) {
    super();
  }

  ngOnInit() {
    this.menuService.menu$
      .pipe(
        takeUntil(this.onDestroy$),
        filter(value => value.action === USER_AUTHENTICATION_SET))
      .subscribe(value => {
        this.isAuthenticated = value.data;
        this.cdr.detectChanges();
      });

    this.loadingService.loading$
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(loading => {
        this.loading = loading;
        this.cdr.detectChanges();
      });

    this.igniteStatusService.igniteVersion$
      .pipe(filter(version => version != null))
      .subscribe(version => {
        this.appVersionExtended = `v${version}, ${this.appConfigService.appConfig.Version}`;
        if (this.appConfigService.appConfig.environment && this.appConfigService.appConfig.environment.toLowerCase() === AppSettingEnvironmentConstants.PRODUCTION) {
          this.appVersion = `v${version}`;
        } else {
          this.appVersion = this.appVersionExtended;
        }
        this.cdr.detectChanges();
      });

    this.nbSideBarService.onToggle()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(toggled => {
        this.bShowAppVersion = !this.bShowAppVersion;
        this.cdr.detectChanges();
      });
  }

  ngAfterViewInit() {
    this.menuService.menu$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        if (value.action === USER_AUTHENTICATION_SET) {
          // Make sure hidden menu items are hidden if user doesn't close browser before signing in again
          if (this.menu) {
            this.menu[menu.SCHEDULING_MENU_INDEX].hidden = true;
            this.menu[menu.FACILITY_MENU_INDEX].hidden = true;
          }

        } else if (value.action === HOME_LINK_CLICKED) {
          this.nbMenuService.collapseAll();
        } else if (value.action === ACTIVE_FACILITY_SET) {
          // updates facilityId in link for menu items where faciliytId is required. 
          if (value.data !== '') {
                this.menu[menu.SCHEDULING_MENU_INDEX].children[menu.SCHEDULING_MENU_CHILD_PAT_PLANNER].link = `/${RouteConstants.PAGES_ROOT}/${RouteConstants.SCHEDULING_ROOT}/${value.data}/${RouteConstants.SCHEDULING_PATIENT}/${RouteConstants.SCHEDULING_PATIENT_PLANNER}`;
                this.menu[menu.SCHEDULING_MENU_INDEX].children[menu.SCHEDULING_MENU_REASSIGN].link = `/${RouteConstants.PAGES_ROOT}/${RouteConstants.SCHEDULING_ROOT}/${value.data}/${RouteConstants.SCHEDULING_REASSIGN}`;
                //this.menu[menu.SCHEDULING_MENU_INDEX].children[menu.SCHEDULING_MENU_CHILD_THER_PLANNER].link = `/${RouteConstants.PAGES_ROOT}/${RouteConstants.SCHEDULING_ROOT}/${value.data}/${RouteConstants.SCHEDULING_THERAPIST}/${RouteConstants.SCHEDULING_THERAPIST_PLANNER}`;
                this.menu[menu.SCHEDULING_MENU_INDEX].children[menu.SCHEDULING_MENU_CHILD_SEC_PLANNER].link = `/${RouteConstants.PAGES_ROOT}/${RouteConstants.SCHEDULING_ROOT}/${value.data}/${RouteConstants.SCHEDULING_SECONDARY}/${RouteConstants.SCHEDULING_SECONDARY_PLANNER}`;
                this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_CHARGES_MENU_INDEX].link = `${menu.FACILITY_MENU_CHARGES_LINK}${value.data}`;
                this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_SCREENS_MENU_INDEX].link = `${menu.FACILITY_LINK}/${value.data}/${menu.FACILITY_MENU_SCREENS_LINK}`; 
                this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_CHARTS_MENU_INDEX].link = `${menu.FACILITY_LINK}/${value.data}/${menu.FACILITY_MENU_CHARTS_LINK}`;
                this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_BATCH_PRINTING_MENU_INDEX].link = `${menu.FACILITY_LINK}/${value.data}/${RouteConstants.FACILITY_BATCH_PRINTING}`;
                this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_DASHBOARD_CHILD_MENU_INDEX].link = `${menu.FACILITY_LINK}/${value.data}/${menu.FACILITY_DASHBOARD_LINK}`;
                this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_PATIENT_TRACKING_MENU_INDEX].link = `${menu.FACILITY_LINK}/${value.data}/${menu.FACILITY_MENU_PATIENT_TRACKING_MENU_LINK}`;
                this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_CASE_ASSIGNMENTS_MENU_INDEX].link = `${menu.FACILITY_LINK}/${value.data}/${menu.FACILITY_MENU_CASE_ASSIGNMENTS_MENU_LINK}`;
                //this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_WEEKLY_UPDATE_MENU_INDEX].link = `${menu.FACILITY_LINK}/${value.data}/${menu.FACILITY_MENU_WEEKLY_UPDATE_MENU_LINK}`;
                this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_CENSUS_MENU_INDEX].link = `${menu.FACILITY_LINK}/${value.data}/${menu.FACILITY_MENU_CENSUS_MENU_LINK}`;
                this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_NON_COVERED_COST_PROJ__MENU_INDEX].link = `${menu.FACILITY_LINK}/${value.data}/${RouteConstants.FACILITY_NON_COVERED_COST_PROJ}`;
                this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_RESTORATIVE_NURSING_MENU_INDEX].children[menu.FACILITY_MENU_RESTORATIVE_NURSING_MENU_INDEX_DASHBOARD].link = `${menu.FACILITY_LINK}/${value.data}/${RouteConstants.FACILITY_RESTORATIVE_NURSING}`;
                this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_RESTORATIVE_NURSING_MENU_INDEX].children[menu.FACILITY_MENU_RESTORATIVE_NURSING_MENU_INDEX_CHARGES].link = `${menu.FACILITY_LINK}/${value.data}/${RouteConstants.FACILITY_RESTORATIVE_NURSING_CHARGES}`;
                this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_FUNCTION_BUILDERS_MENU_INDEX].children[menu.FACILITY_MENU_FUNCTION_BUILDERS_MENU_INDEX_DASHBOARD].link = `${menu.FACILITY_LINK}/${value.data}/${RouteConstants.FACILITY_FUNCTION_BUILDERS}`;
                this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_FUNCTION_BUILDERS_MENU_INDEX].children[menu.FACILITY_MENU_FUNCTION_BUILDERS_MENU_INDEX_CHARGES].link = `${menu.FACILITY_LINK}/${value.data}/${RouteConstants.FACILITY_FUNCTION_BUILDERS_CHARGES}`;
                this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_CASPER_MENU_INDEX].link = `${menu.FACILITY_LINK}/${value.data}/${RouteConstants.FACILITY_CASPER}`;
          }

        } else if (value.action === PERMISSIONS_SET) {
          zip(this.cacheManService.getUserHasPermission$(UIPermissionConstants.PATIENTS_VIEW),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.REPORTS_VIEW),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.FACILITY_ADMIN_VIEW),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.USERS_VIEW),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.PHYSICIANS_VIEW),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.CLIENTS_VIEW),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.INTERMEDIARY_VIEW),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.MASTERPAYERS_VIEW),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.ADMIN),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.PRACTITIONER_PORTAL_VIEW),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.DENIAL_MANAGEMENT_VIEW),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.COMPLIANCE_VIEW),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.PLANNER_VIEW),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.CHARGES_VIEW),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.SCREENS_VIEWS),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.ECHART_VIEW),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.FACILITY_VIEW),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.STARTUPS),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.CLIENT_PORTAL_VIEW),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.BILLING),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.EXPORTS),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.OPERATIONS_VIEW),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.PREMIER_CODING_VIEW),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.INSURANCE_VERIFICATION_VIEW),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.POWER_BI_REPORTS),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.CLINICAL_EXCELLENCE_ADMIN),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.MDS_UPLOAD),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.INTEGRATION_MANAGEMENT),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.BILLING_VIEW),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.HUMAN_RESOURCES),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.FUNCTION_BUILDERS),
              this.cacheManService.getUserHasPermission$(UIPermissionConstants.RESTORATIVE_NURSING))
                .pipe(take(1))
                .subscribe(([canViewPatients, canViewReports, canViewFacilityAdmin, canViewUsers,
                             canViewPhysicians, canViewClients, canViewIntermediary, canViewMasterPayers,
                             canViewAdmin, canViewPractitionerPortal, canDenialManageView, canComplianceView,
                             canViewPlanner, canViewCharges, canViewScreens, canViewECharts, canViewFacility, canViewStartups,
                             canViewClientPortal, canBillingDepartment, canViewExports, canViewOperationsDepartment, canViewPremierCodingDepartment,
                             canViewInsuranceVerificationDepartment, canViewPowerBiReports, canCEAdmin, canMdsUpload,
                             canIntegrationMgmt, canViewBillingDepartment, canViewHr, canViewFunctionBuilders, canViewRestorativeNursing]) => {
                  this.menu[menu.PATIENT_MENU_INDEX].hidden = !canViewPatients;
                  this.menu[menu.REPORTS_MENU_INDEX].hidden = !canViewReports;
                  this.menu[menu.PRACTITIONER_MENU_INDEX].hidden = !canViewPractitionerPortal;
                  this.menu[menu.CLIENT_PORTAL_MENU_INDEX].hidden = !canViewClientPortal;
                  this.menu[menu.DENIAL_MANAGEMENT_MENU_INDEX].hidden = !canDenialManageView;
                  this.menu[menu.COMPLIANCE_MENU_INDEX].hidden = !canComplianceView;
                  this.menu[menu.DENIALS_CLIENT_MENU_INDEX].hidden = !canViewClientPortal;

                  // Admin menu items
                  this.menu[menu.ADMIN_MENU_INDEX].hidden = !canViewAdmin;
                  this.menu[menu.ADMIN_MENU_INDEX].children[menu.ADMIN_CLIENTS_MENU_INDEX].children[menu.ADMIN_CLIENTS_MENU_FACILITY_INDEX].hidden = !canViewFacilityAdmin;
                  this.menu[menu.ADMIN_MENU_INDEX].children[menu.ADMIN_CLIENTS_MENU_INDEX].children[menu.ADMIN_CLIENTS_MENU_DASHBOARD_INDEX].hidden = !canViewClients;
                  this.menu[menu.ADMIN_MENU_INDEX].children[menu.ADMIN_CLIENTS_MENU_INDEX].children[menu.ADMIN_CLIENTS_FEATURE_FLAGS_INDEX].hidden = !canViewAdmin;
                  this.menu[menu.ADMIN_MENU_INDEX].children[menu.ADMIN_CLIENTS_MENU_INDEX].children[menu.ADMIN_CLIENTS_OFFERINGS_INDEX].hidden = !canViewAdmin;
                  this.menu[menu.ADMIN_MENU_INDEX].children[menu.ADMIN_CLIENTS_MENU_INDEX].children[menu.ADMIN_CLIENTS_REGIONS_INDEX].hidden = !canViewAdmin;

                  this.menu[menu.ADMIN_MENU_INDEX].children[menu.ADMIN_BILLING_MENU_INDEX].children[menu.ADMIN_BILLING_INTERMEDIARY_INDEX].hidden = !canViewIntermediary;
                  this.menu[menu.ADMIN_MENU_INDEX].children[menu.ADMIN_BILLING_MENU_INDEX].children[menu.ADMIN_BILLING_CPT_ADMIN_INDEX].hidden = !canViewAdmin;
                  this.menu[menu.ADMIN_MENU_INDEX].children[menu.ADMIN_BILLING_MENU_INDEX].children[menu.ADMIN_BILLING_MASTER_PAYER_INDEX].hidden = !canViewMasterPayers;

                  this.menu[menu.ADMIN_MENU_INDEX].children[menu.ADMIN_USER_MENU_INDEX].children[menu.ADMIN_USER_EMPLOYEE_MENU_INDEX].hidden = !canViewUsers;
                  this.menu[menu.ADMIN_MENU_INDEX].children[menu.ADMIN_USER_MENU_INDEX].children[menu.ADMIN_USER_PRACTITIONER_MENU_INDEX].hidden = !canViewPhysicians;
                  this.menu[menu.ADMIN_MENU_INDEX].children[menu.ADMIN_USER_MENU_INDEX].children[menu.ADMIN_USER_CLIENT_MENU_INDEX].hidden = !canViewAdmin;
                  this.menu[menu.ADMIN_MENU_INDEX].children[menu.ADMIN_USER_MENU_INDEX].children[menu.ADMIN_USER_ROLES_MENU_INDEX].hidden = !canViewAdmin;

                  this.menu[menu.ADMIN_MENU_INDEX].children[menu.ADMIN_COMMUNICATIONS_MENU_INDEX].hidden = !canViewAdmin;
                  this.menu[menu.ADMIN_MENU_INDEX].children[menu.ADMIN_MASS_UNLOCK_CHARGES_INDEX].hidden = !canViewAdmin;
                  this.menu[menu.ADMIN_MENU_INDEX].children[menu.ADMIN_REPORT_MENU_INDEX].hidden = !canViewAdmin;
                  this.menu[menu.ADMIN_MENU_INDEX].children[menu.ADMIN_SYSTEM_HEALTH_INDEX].hidden = !canViewAdmin;
                  
                  this.menu[menu.SCHEDULING_MENU_INDEX].hidden = !canViewPlanner;
                  this.menu[menu.SCHEDULING_MENU_INDEX].children[menu.SCHEDULING_MENU_CHILD_PAT_PLANNER].hidden = !canViewPlanner;
                  this.menu[menu.SCHEDULING_MENU_INDEX].children[menu.SCHEDULING_MENU_REASSIGN].hidden = !canViewPlanner;
                  this.menu[menu.SCHEDULING_MENU_INDEX].children[menu.SCHEDULING_MENU_CHILD_SEC_PLANNER].hidden = !canViewFunctionBuilders;
                 // this.menu[menu.SCHEDULING_MENU_INDEX].children[menu.SCHEDULING_MENU_CHILD_THER_PLANNER].hidden = !canViewPlanner;

                  // if any sub-menu items are viewable, then parent menu should be viewable
                  this.menu[menu.FACILITY_MENU_INDEX].hidden = !(canViewCharges || canViewScreens || canViewECharts || canViewFacility || canViewFunctionBuilders || canViewRestorativeNursing);
  
                  this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_CHARGES_MENU_INDEX].hidden = !canViewCharges;
                  this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_SCREENS_MENU_INDEX].hidden = !canViewScreens;
                  this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_CHARTS_MENU_INDEX].hidden = !canViewECharts;
                  this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_DASHBOARD_CHILD_MENU_INDEX].hidden = !canViewFacility;
                  this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_PATIENT_TRACKING_MENU_INDEX].hidden = !canViewFacility; // TODO: does this need its own permission???
                  this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_CASE_ASSIGNMENTS_MENU_INDEX].hidden = !canViewFacility;
                  this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_CENSUS_MENU_INDEX].hidden = !canViewFacility;
                  this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_CASPER_MENU_INDEX].hidden = !canViewFacility;
                  this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_FUNCTION_BUILDERS_MENU_INDEX].hidden = !canViewFunctionBuilders;
                  this.menu[menu.FACILITY_MENU_INDEX].children[menu.FACILITY_MENU_RESTORATIVE_NURSING_MENU_INDEX].hidden = !canViewRestorativeNursing;

                  this.menu[menu.BILLING_MENU_INDEX].hidden = !(canBillingDepartment || canViewExports || canViewBillingDepartment);
                  this.menu[menu.BILLING_MENU_INDEX].children[menu.BILLING_MENU_DASHBOARD_CHILD_MENU_INDEX].hidden = !canBillingDepartment;
                  this.menu[menu.BILLING_MENU_INDEX].children[menu.BILLING_MENU_EXPORT_CHILD_MENU_INDEX].hidden = !canViewExports;
                  this.menu[menu.BILLING_MENU_INDEX].children[menu.BILLING_MENU_INVOICES_CHILD_MENU_INDEX].hidden = !canViewBillingDepartment;


                  this.menu[menu.REPORTS_MENU_INDEX].children[menu.REPORTS_MENU_POWERBI_INDEX].hidden = !(canViewAdmin || canViewPowerBiReports);

                  this.menu[menu.OPERATIONS_MENU_INDEX].hidden = !canViewOperationsDepartment;
                  this.menu[menu.CODING_INTEGRITY_MENU_INDEX].hidden = !(canViewPremierCodingDepartment || canMdsUpload);
                  this.menu[menu.CODING_INTEGRITY_MENU_INDEX].children[menu.CODING_INTEGRITY_DASHBOARD_CHILD_MENU_INDEX].hidden = !canViewPremierCodingDepartment;
                  this.menu[menu.CODING_INTEGRITY_MENU_INDEX].children[menu.CODING_INTEGRITY_MDS_UPLOAD_INDEX].hidden = !canMdsUpload;
                  this.menu[menu.STARTUPS_MENU_INDEX].hidden = !canViewStartups;
                  this.menu[menu.INSURANCE_VERIFICATION_INDEX].hidden = !canViewInsuranceVerificationDepartment;
                  this.menu[menu.INTEGRATION_MGMT_INDEX].hidden = !canIntegrationMgmt;
                  this.menu[menu.HR_INDEX].hidden = !canViewHr;

                  // clinical excellence
                  this.menu[menu.CE_MENU_INDEX].hidden = !(canCEAdmin || canViewAdmin || canMdsUpload);
                })
        }

        // Hack to get nebular to select menu item based on url. We need selectFromUrl to be called by nebular
        /**
         * Nebular Menu Component: 
         *   onAddItem(data: { tag: string; items: NbMenuItem[] }) {
                 this.items.push(...data.items);
    
                  this.menuInternalService.prepareItems(this.items);
                  this.menuInternalService.selectFromUrl(this.items, this.tag, this.autoCollapse);
              }
          */
        if (this.nbMenu) {
          this.nbMenu.onAddItem({ tag: '', items: [] });
        }

        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  goToHome() {
    this.menuService.homeLinkClicked();
    this.nbMenuService.collapseAll();
    this.nbMenuService.navigateHome();
  }
}
