import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { CacheManService, DataListConstants, UsersService } from '@app/core';
import { eDiscipline, User } from '@app/model';
import { take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

interface DisciplineDesignationDisplay {
  text: string;
  id: eDiscipline;
}

@Component({
  selector: 'tms-user-select-discipline-modal',
  templateUrl: './user-select-discipline-modal.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSelectDisciplineModalComponent implements OnInit {
  @Input() user: User = null;
  disciplineList: DisciplineDesignationDisplay[] = [];
  selectedValue: eDiscipline = null;

  constructor(private activeModalService: NgbActiveModal,
    private cacheManService: CacheManService,
    private usersService: UsersService) { }

  ngOnInit(): void {
    combineLatest([this.cacheManService.getDataListItems$(DataListConstants.DISCIPLINES),
                  this.cacheManService.getDataListItems$(DataListConstants.DESIGNATIONS)])
                  .pipe(take(1))
                  .subscribe(([disciplines, designations]) => {
                    if (this.user.employee.availableDisciplines) {
                      this.disciplineList = this.user.employee.availableDisciplines.map(dd => {
                        const discipline = disciplines.find(d => d.valueInt == dd.discipline);
                        const designation = designations.find(d => d.valueInt == dd.designation);
                        const ddForDisplay: DisciplineDesignationDisplay = { 
                          text: `${discipline?.shortName}/${designation?.shortName}`, 
                          id: dd.discipline 
                        }
                        return ddForDisplay;
                      })
                    }
                  })
  }

  onSubmitSelection(e) {
    const validationResult = e.validationGroup.validate();
    if (validationResult.isValid) {
      this.usersService.updateDiscipline(this.user.id, this.selectedValue)
        .subscribe(() => this.activeModalService.close());
    }
  }

}
