import { ErrorItem } from '@app/model';
import { HttpErrorResponse } from '@angular/common/http';

export class ErrorMessageUtils {

    static getErrorMessages(error: HttpErrorResponse): ErrorItem[] {
        return error.error && error.error.errors ? error.error.errors : [];
    }

    static getDisplayErrorMessage(error: HttpErrorResponse, defaultError: string): string {
        if (error.error && error.error.errors && error.error.errors.length > 0) {
            if (error.error.errors.length === 1) {
                return error.error.errors[0].text;
            } else {
                let msg = '';
                error.error.errors.forEach(err => msg += `${err.text}\n`);
                return msg;
            }
        } else {
            return defaultError;
        }
    }
}