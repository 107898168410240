import { Component, Input, OnChanges, SimpleChanges, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { Directionality } from '@angular/cdk/bidi';

@Component({
  selector: 'tms-simple-stepper',
  templateUrl: './tms-simple-stepper.component.html',
  styleUrls: ['./tms-simple-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: CdkStepper,
    useExisting: TmsSimpleStepperComponent,
  }],
})
export class TmsSimpleStepperComponent extends CdkStepper implements OnInit, OnChanges {
  @Input() selectedStepIndex: number = 0;

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedStepIndex'].currentValue != changes['selectedStepIndex'].previousValue) {
      this.selectedIndex = changes['selectedStepIndex'].currentValue;
    }
  }
}
