<div class="modal-header">
    <h4 class="modal-title">Clock {{ clockedIn ? 'Out' : 'In' }}</h4>
    <dx-button class="tms-icon-button" icon="fas fa-times" (click)="activeModal.dismiss()" hint="Close"></dx-button>
</div>
<dx-validation-group>
    <div class="modal-body">
        <div *hasPermission="timeclockTravalPermission" class="row">
            <div class="col-12">
                <div *ngIf="clockedIn">
                    If traveling, choose facility to transfer
                </div>
                <dx-select-box [dataSource]="filteredFacilities" displayExpr="name" valueExpr="id" width="80%"
                    placeholder="Select Facility" [showClearButton]="true"
                    (onSelectionChanged)="onSelectedFacilityChanged($event)"
                    (onValueChanged)="onSelectedFacilityValueChanged($event)" [(value)]="clockButtonFacility">
                </dx-select-box>
                <div *ngIf="!clockedIn && currentUser.employee?.availableDisciplines.length > 0" class="tms-row-divider">
                    <div class="row tms-row-divider">
                        <div class="col-3">
                            Discipline/Designation:
                        </div>
                        <div class="col">
                            <dx-radio-group [dataSource]="disciplineList" [value]="currentUserDiscipline" displayExpr="text" valueExpr="id" layout="horizontal"
                                (onValueChanged)="onUserDisciplineDesignationChanged($event)">
                                <dx-validator>
                                    <dxi-validation-rule type="required" message="A discipline/designation is required">
                                    </dxi-validation-rule>
                                </dx-validator>
                            </dx-radio-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col-12">
                <div *ngIf="!clockedIn">
                    Current Time {{ currentTime }}
                </div>
                <div *ngIf="clockedIn">
                    <div class="row">
                        <div class="col-1">
                            <dx-check-box [(value)]="confirmChecked"></dx-check-box>
                        </div>
                        <div class="col-11 ">
                            <div class="confirm-text">
                                I hereby verify that all the hours I worked during the pay period specified on my time
                                card
                                are
                                completely and accurately stated on my time card. I also verify that I did not work
                                off-the-clock,
                                perform work during non-work hours, or work unauthorized overtime. I understand that my
                                time
                                card
                                must be accurate and that I must correct any errors before electronically signing and
                                submitting
                                it.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <dx-button [text]="clockButtonText" (onClick)="onClockButtonClicked($event)" class="tms-primary-button"
            [disabled]="disabledClockButton()">
        </dx-button>
    </div>
</dx-validation-group>