import { Dictionary } from '../interfaces';

export interface Script {
    name: string;
    async: boolean;
    src?: string;
    innerHtml?: string;
    loaded: boolean;
}

export const ScriptsDictionary: Dictionary<Script> = {
    USERLANE: {

        name: 'Userlane',
        async: true,
        src: 'https://cdn.userlane.com/userlane.js',
        loaded: false,
    }, 
    HOT_JAR_LOCAL: {
        name: 'Hotjar_Local',
        async: false,
        innerHtml: "(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:2022113,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');",
        loaded: false,
    },
    HOT_JAR_DEV: {
        name: 'Hotjar_Dev',
        async: false,
        innerHtml: "(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:1997769,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');",
        loaded: false,
    },
    HOT_JAR_PROD: {
        name: 'Hotjar_Prod',
        async: false,
        innerHtml: "(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:2022114,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');",
        loaded: false,
    }
};