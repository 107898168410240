import { Component, EventEmitter, Input, Output, OnInit, ChangeDetectorRef } from '@angular/core';
import { InterviewQuestion, InterviewAnswerField, IcdCodeAllowableType, IcdCode, CodingValueChangedEvent } from '@app/model';
import { IcdCodesService } from '@app/core';
import { take } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
    selector: 'tms-question-icd-code-type-ahead',
    styleUrls: [],
    template: `
    <tms-typeahead-dropdown [dataSource]="icdCodeDataSource"
        [id]="id"
        [value]="this.interviewAnswerField.value" 
        [displayExpr]="getIcdDisplayExpr"
        valueExpr="code"
        [typeAheadValueChanged]="icdCodeTypeAheadValueChanged"
        placeholderText="Type to search..."
        [disabled]="disabled"
        (onValueChanged)="onValueChanged($event)">
    </tms-typeahead-dropdown>
  `,
})
export class InterviewQuestionICDCodeTypeAheadComponent implements OnInit {
    @Input() question: InterviewQuestion;
    @Input() startOfCareDate: Date;
    @Input() interviewAnswerField: InterviewAnswerField;
    @Input() id: string;
    @Input() showComment: boolean = false;
    @Input() disabled: boolean = false;
    @Output() onInterviewValueChanged: EventEmitter<string> = new EventEmitter<string>();

    primeICDCodeDataSource: IcdCode[] = null;
    icdCodeDataSource: IcdCode[] = null;
    commentText: string = '';
    dropdownValue: string = '';

    constructor(private icdCodesService: IcdCodesService,
        private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        this.dropdownValue = this.interviewAnswerField.value;
        if (this.dropdownValue) {
            let socDate = moment(this.startOfCareDate).toDate();
            this.icdCodesService.getByCode(this.dropdownValue, socDate)
                .subscribe(icdcode => {
                    this.icdCodeDataSource = [icdcode];
                    this.cdr.detectChanges();
                });
        }
    }

    getIcdDisplayExpr(item: IcdCode) {
        if (item) {
            return `${item.code} - ${item.statement}`;
        }
    }

    icdCodeTypeAheadValueChanged = (e) => {
        let socDate = moment(this.startOfCareDate).toDate();
        return this.icdCodesService.searchIcdCodes(e, socDate, false, IcdCodeAllowableType.BothMedicalAndTreatment);
    }


    onValueChanged(e) {
        // value has been cleared, then get initial ddl values 
        if (e == null && this.dropdownValue != null) {
            if (!this.primeICDCodeDataSource) {
                let socDate = moment(this.startOfCareDate).toDate();
                this.icdCodesService.getAllIcdCodes(socDate, false, IcdCodeAllowableType.BothMedicalAndTreatment)
                    .subscribe(icdcodes => {
                        this.primeICDCodeDataSource = icdcodes;
                        this.icdCodeDataSource = icdcodes;
                        this.dropdownValue = null;
                        this.cdr.detectChanges();
                    });
            } else {
                this.icdCodeDataSource = this.primeICDCodeDataSource;
                this.cdr.detectChanges();
            }
            this.onInterviewValueChanged.emit(null);
        } else {
            if (e) {
                if (e.code) {
                    this.dropdownValue = e.code;
                    this.onInterviewValueChanged.emit(e.code);
                }
            }
        }
    }
}
