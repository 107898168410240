import { Injectable } from '@angular/core';
import { Enum } from '@app/model/interfaces/Enum';
import { CrosswalkType } from '@app/model';

@Injectable({
	providedIn: 'root',
})
export class EnumService {

    getCrosswalkType(): Enum[] {
        return [
            { text: 'Inclusive', id: CrosswalkType.Inclusive },
            { text: 'Exclusive', id: CrosswalkType.Exclusive },
        ];
    }

    getPtoRequestType(): Enum[] {
        return [
            { text: 'Pto', id: 0 },
            { text: 'Health', id: 1 },
        ];
    }
}
