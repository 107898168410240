<tms-modal [title]="title"
    [submitButtonVisible]="false"
    [cancelButtonVisible]="false"
    (onClose)="onCloseProgressBar()">
    <dx-progress-bar #progressBar
        id="progress-bar-status"
        width="100%"
        [class.complete]="currentValue == maxValue"
        [class.error]="isError"
        [min]="0"
        [max]="maxValue"
        [statusFormat]="format"
        [value]="currentValue">
    </dx-progress-bar>
    <div *ngIf="isError && currentValue == maxValue">
        <i class="fas fa-exclamation-circle warning-icon"></i>
        An error occurred while making request. Please see logs for more details. 
    </div>
</tms-modal>

