import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { OperationResponse, ReassignTherapist, eDiscipline } from '@app/model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ReassignTherapistService {
	constructor(private http: HttpClient,
		private urlConstantsService: UrlConstantsService) {
    }

    getAll(facilityId: string, discipline: eDiscipline): Observable<ReassignTherapist[]> {
		return this.http.get<OperationResponse<ReassignTherapist[]>>(`${this.urlConstantsService.REASSIGN_THERAPIST_URL}/${facilityId}?discipline=${discipline}`)
			.pipe(map(response => response.data));
    }

    updateTherapistsAssignment(facilityId: string, updatedList: ReassignTherapist[]) {
        return this.http.post<OperationResponse<void>>(`${this.urlConstantsService.REASSIGN_THERAPIST_URL}/${facilityId}/update`, updatedList)
            .pipe(map(response => response.data));
    }
}