import { Component, Input, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Observable, zip, of } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { eDesignation, eDiscipline, ePermissions, Facility, PatientSearchCriteria, User, UserSlim, UserType } from '@app/model';
import { FacilitiesService, RouteConstants, CacheManService, 
  UIPermissionConstants, AnalyticsService, JumpToNavigationService, ImpersonateService, DataListConstants, ToastMessageService } from '@app/core';
import { NbSidebarService } from '@nebular/theme';
import { OnDestroyComponent } from '@app/shared';
import { DxTooltipComponent } from 'devextreme-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SupportTicketWizardComponent } from '@app/home';
import { TimeClockComponent } from '@app/pages/user/time-clock/time-clock.component';
import { modalOptions, modalLargeOption, modalXLargeOption } from '@app/utils';
import { UserGreeting } from '@app/utils/user-greeting.utils';
import { TimeClockEntryService } from '@app/core/services/time-clock-entry.service';

@Component({
  selector: 'tms-header',
  styleUrls: ['./header.component.scss', './header.controls.component.scss'],
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends OnDestroyComponent implements OnInit, OnDestroy {
  @ViewChild('headerAdvancedSearchTooltip') advancedSearchTooltip: DxTooltipComponent;

  @Input() position = 'normal';

  activeFacilityId: string;
  facilities$: Observable<Facility[]>;
  patientSearchValue: string = '';
  user: User = null;
  patientSearchCriteria: PatientSearchCriteria = {};
  patientSearchPermissions: ePermissions;
  facilityViewPermission: ePermissions;
  timeClockViewPermission: ePermissions;
  isEmployee: boolean = true;
  isUserClockedIn: boolean = true;
  impersonatingUser: UserSlim = null;

  canViewPatientSearch: boolean = false;
  canViewFacility: boolean = false;
  jumpToNavItem = [];
  showSwitchDisciplineSelections: boolean = false;

  private bSelectBoxOpened: boolean = false;

  private discipline: string = "";

  get jumpToNavigationPermission () {
    return UIPermissionConstants.JUMP_TO_NAVIGATION;
  }

  get userName(): string {
    if (this.user && this.user.firstName && this.user.lastName) {
      return `${this.user.firstName} ${this.user.lastName}`;
    } else if (this.user && this.user.email) {
      return this.user.email;
    } else {
      return '';
    }
  }

  get disciplineDataList(): string {
    return DataListConstants.DISCIPLINES;
  }

  get designationDataList(): string {
    return DataListConstants.DESIGNATIONS;
  }

  get bShowUserDiscipline(): boolean {
    const availableDisciplines = this.user?.employee?.availableDisciplines;
    return availableDisciplines && availableDisciplines.length > 0;
  }

  protected greeting(additionalMessage: string | number): string {
    const hour: number = new Date().getHours();
    this.discipline = "";
    if (this.bShowUserDiscipline) {
        this.discipline = additionalMessage?.toString() ?? "";
    }
    return UserGreeting.createGreeting(hour, this.user.firstName, this.discipline?.toString());
  }

  constructor(private router: Router,
    private cacheManService: CacheManService,
    private facilitiesService: FacilitiesService,
    private sideBarService: NbSidebarService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    private jumpToNavService: JumpToNavigationService,
    private impersonateSerivce: ImpersonateService,
    private timeClockService: TimeClockEntryService,
    private toastMessageService: ToastMessageService) {
    super();
  }

  ngOnInit() {
    this.timeClockViewPermission = UIPermissionConstants.TIMECLOCK_VIEW;
    combineLatest([this.facilitiesService.activeFacilityId$,
      this.cacheManService.getCurrentUser(),
      this.impersonateSerivce.isImpersonatingMode$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([facilityId, user, isImpersonating]) => {
        this.user = user;
        this.activeFacilityId = facilityId;
        this.impersonatingUser = isImpersonating;

        if (this.user) {
          this.isEmployee = this.user.userType === UserType.Employee;
        }

        this.cdr.detectChanges();
      });
      this.cacheManService.getUserFacilities()
        .subscribe(facilities => {
          var userFacilities = this.user.permissions.includes(63) ? facilities : facilities.filter(f=> this.user.facilityIds.includes(f.id));
          this.facilities$ = of(userFacilities);
          if (userFacilities.length == 0){
            this.activeFacilityId = null;
          }
          this.activeFacilityId = !this.user.facilityIds.includes(this.activeFacilityId) && userFacilities.length > 0 ? this.user.facilityIds[0] : this.activeFacilityId;
          this.facilitiesService.activeFacilityId = this.activeFacilityId;
          this.cdr.detectChanges();
        });
      this.cacheManService.getIsUserClockedIn$()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(isClockedIn => {
          this.isUserClockedIn = isClockedIn;
          this.cdr.detectChanges();
        });

    // normally we'd use the *hasPermission directive, but for some reason html will not render with directive.
    zip(this.cacheManService.getUserHasPermission$(UIPermissionConstants.PATIENTS_VIEW),
      this.cacheManService.getUserHasPermission$(UIPermissionConstants.FACILITY_VIEW))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([patientView, facilityView]) => {
        this.canViewPatientSearch = patientView;
        this.canViewFacility = facilityView;
        this.cdr.detectChanges();
      });

      this.jumpToNavItem = this.jumpToNavService.jumpToNavItems;
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  toggleSideNav() {
    this.sideBarService.toggle(true, 'menu-sidebar');
  }

  goToProfile() {
    this.router.navigate(['pages/user/profile']);
  }

  goToWorkHoursManagement() {
    this.router.navigate(['pages/user/work-hours-management']);
  }

  onLogout() {
    this.router.navigate(['/public']);
  }

  onAddSupportTicket() {
    this.modalService.open(SupportTicketWizardComponent, { ...modalOptions, ...modalLargeOption});
  }

  onShowSwitchDiscipline() {
    this.showSwitchDisciplineSelections = true;
    this.cdr.detectChanges();
  }

  onHideSwitchDiscipline() {
    this.showSwitchDisciplineSelections = false;
  }

  onSwitchDiscipline(discipline: eDiscipline, designation: eDesignation, ecode: string) {
    this.timeClockService.switchDiscipline(discipline, designation, ecode, this.activeFacilityId)
      .subscribe(response => {
          this.cacheManService.getDataListValueTextDictionary$(DataListConstants.DISCIPLINES, 'shortName')
            .pipe(take(1))
            .subscribe(disciplineDictionary => {
              if (response.clockedInUtc == null && response.clockedOutUtc == null) {
                this.toastMessageService.successNotification(`You have successully switched to discipline ${disciplineDictionary[response.clockedInDiscipline]}`);
              } else {
                this.toastMessageService.successNotification(`You have successully switched and clocked in as discipline ${disciplineDictionary[response.clockedInDiscipline]}`);
              }
            });
      });
  }

  onSwitchDisciplineClicked(event) {
    event.stopImmediatePropagation();
  }

  onPatientSearchEnterKey(e) {
    if (this.patientSearchValue && this.patientSearchValue !== '') {
      this.advancedSearchTooltip.instance.hide();
      this.router.navigate([`/${RouteConstants.PAGES_ROOT}/${RouteConstants.PATIENT_ROOT}/${RouteConstants.PATIENT_SEARCH}`], 
      {
        queryParams: { 
          generic: this.patientSearchValue 
        } 
      });
    }
  }

  advancedSearch(e) {
    if (this.isAdvancedSearchEmpty()) {
      if (this.patientSearchValue && this.patientSearchValue !== '') {
        this.onPatientSearchEnterKey(e);
      }
    } else {
      this.advancedSearchTooltip.instance.hide();
      this.router.navigate([`/${RouteConstants.PAGES_ROOT}/${RouteConstants.PATIENT_ROOT}/${RouteConstants.PATIENT_SEARCH}`], 
      { 
        queryParams: { 
          fname: this.patientSearchCriteria.firstNameContains,
          lname: this.patientSearchCriteria.lastNameContains,
          dob: this.patientSearchCriteria.dateOfBirth,
          ssn: this.patientSearchCriteria.socialSecurityNumber,
          uid: this.patientSearchCriteria.uniqueId,
        } 
      });
    }
  }

  isAdvancedSearchEmpty() {
    if ((!this.patientSearchCriteria.firstNameContains || this.patientSearchCriteria.firstNameContains === '') &&
      (!this.patientSearchCriteria.lastNameContains || this.patientSearchCriteria.lastNameContains === '') &&
      (!this.patientSearchCriteria.dateOfBirth || this.patientSearchCriteria.dateOfBirth === null) &&
      (!this.patientSearchCriteria.socialSecurityNumber || this.patientSearchCriteria.socialSecurityNumber === '') &&
      (!this.patientSearchCriteria.uniqueId || this.patientSearchCriteria.uniqueId === '')) {
      return true;
    }
    return false;
  }

  onHiddenTooltip(e) {
    this.patientSearchCriteria = {};
  }

  onFacilityValueChanged(e) {
    if (this.bSelectBoxOpened) {
      if (e.value && e.value != this.facilitiesService.activeFacilityId) {
        this.bSelectBoxOpened = false;
        const facilityId = e.value;
        const oldFacilityId = this.facilitiesService.activeFacilityId;
        this.facilitiesService.activeFacilityId = facilityId;
        let currentURL = this.router.url;
        //Change facilityId in URL.
        currentURL = this.getUpdatedUrl(currentURL, "facility/", facilityId, oldFacilityId);
        //One page has a different layout than the rest. Make sure the facilityId gets changed in that scenario.
        currentURL = this.getUpdatedUrl(currentURL, "charges/dashboard/", facilityId, oldFacilityId);
        //The schedule urls also need to be updated.
        currentURL = this.getUpdatedUrl(currentURL, "schedule/", facilityId, oldFacilityId);

        //Done to allow a refresh on pages that wouldn't normally have one.
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentURL]);
        });
      }
    }
  }

  getUpdatedUrl(currentURL: string, route:string, facilityId:string, oldFacilityId: string):string{
    if(currentURL.includes(route + oldFacilityId)){
      currentURL = currentURL.replace(route + oldFacilityId, route + facilityId)
    }
    return currentURL;
  }

  onFacilityOpened(e) {
    this.bSelectBoxOpened = true;
    this.activeFacilityId = null;
  }

  onFacilityFocusOut(e) {
    if(this.activeFacilityId == null){
      this.activeFacilityId = this.facilitiesService.activeFacilityId;
    }
  }

  openTimeClockModal() {
    this.analyticsService.trackEvent('Time Clock Open');
    const modalRef = this.modalService.open(TimeClockComponent, { ...modalOptions, ...modalXLargeOption });
  }

  onJumpToNavValueChanged(e) {
    if (e.value) {
      this.router.navigate([e.value]);
    }
  }
}
