import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { ActionItem, PagedResult, User, OperationResponse, UserType, AuthorizedSignature, eDiscipline, UserResponse } from '@app/model';
import { SortUtils } from '@app/utils';
import { UserStore, UserQuery } from '../stores';
import { UserSlim } from '@app/model/user/UserSlim';
import { ToastMessageService } from './toast-message.service';
import { CacheManService } from './cacheman.service';
import { noLoadingSpinner } from '../interceptors';

@Injectable({
	providedIn: 'root',
})
export class UsersService {
    constructor(private cacheManService: CacheManService,
        private http: HttpClient,
        private userStore: UserStore,
        private userQuery: UserQuery,
        private toastMessageService: ToastMessageService,
        private urlConstantsService: UrlConstantsService) { }

    // Basic User
    getAll() {
        return this.http.get<PagedResult<User>>(`${this.urlConstantsService.USERS_URL}`)
            .pipe(map(usersPagedResult => usersPagedResult.items));
    }

    getAllSlim() {
        return this.http.get<PagedResult<UserSlim>>(`${this.urlConstantsService.USERS_URL}/slim`)
            .pipe(map(usersPagedResult => usersPagedResult.items));
    }

    getUserById(id: string) {
        return this.http.get<User>(`${this.urlConstantsService.USERS_URL}/${id}`);
    }
    
    getUserActivityFeed(id: string): Observable<ActionItem[]> {
        return this.http.get<PagedResult<ActionItem>>(`${this.urlConstantsService.USERS_URL}/${id}/activityFeed`)
            .pipe(map(response => response.items));
    }

    /**
     * Assigned Therapist by FaciiltyId
     */
    getByFacilityId(facilityId: string): Observable<User[]> {
        return this.http.get<PagedResult<User>>(`${this.urlConstantsService.USERS_URL}?facilityId=${facilityId}`).pipe(map(usersPagedResult => SortUtils.orderBy(usersPagedResult.items, 'lastName')));
    }

    getByFacilityIdSlim(facilityId: string): Observable<UserSlim[]> {
        return this.http.get<PagedResult<UserSlim>>(`${this.urlConstantsService.USERS_URL}/slim?userType=${UserType.Employee}&facilityId=${facilityId}`).pipe(map(usersPagedResult => usersPagedResult.items));
    }

    getAllNonDepartmentalByFacilitySlim(facilityId: string): Observable<UserSlim[]> {
        return this.http.get<PagedResult<UserSlim>>(`${this.urlConstantsService.USERS_URL}/nondepartmental?facilityId=${facilityId}`).pipe(map(response => response.items));
    }


    createUser(user: User) {
        return this.http.post<OperationResponse<User>>(`${this.urlConstantsService.USERS_URL}`, user)
            .pipe(map(response => response.data));
    }

    updateUser(user: User) {
        return this.http.put<OperationResponse<UserResponse>>(`${this.urlConstantsService.USERS_URL}/${user.id}`, user)
            .pipe(map(response => response.data.user));
    }

    deleteUser(id: string) {
        return this.http.delete<OperationResponse<boolean>>(`${this.urlConstantsService.USERS_URL}/${id}`)
            .pipe(map(response => response.data));
    }

    updateBasicProfile(user: User) {
        return this.http.put<OperationResponse<User>>(`${this.urlConstantsService.USERS_URL}/profile/${user.id}`, user)
        .pipe(map(response => response.data));
    }

    updateDiscipline(userId: string, discipline: eDiscipline): Observable<User> {
        return this.http.put<OperationResponse<User>>(`${this.urlConstantsService.USERS_URL}/${userId}/discipline/${discipline}`, null)
            .pipe(map(response => response.data),
                  tap(user => this.cacheManService.setCurrentUser(user)));
    }

    resendInvitationLink(userId: string): Observable<string> {
        return this.http.get<string>(`${this.urlConstantsService.USERS_URL}/${userId}/sendinvite`);
    }

    // User Type Employee
    activeEmployee$(property?: string): Observable<any> {
        if (property) {
            return this.userQuery.selectActive(entity => entity[property]);
        } else {
            return this.userQuery.selectActive();
        }
    }

    employeesSearch(searchText: string) {
        return this.http.get<User[]>(`${this.urlConstantsService.USERS_URL}/employees?searchText=${searchText}`);
    }

    allUsersSearch(searchText: string): Observable<User[]> {
        return this.http.get<User[]>(`${this.urlConstantsService.USERS_URL}/searchallusers?searchText=${searchText}`);
    }

    getActiveEmployee(): User {
        return this.userQuery.getActive();
    }

    getAllEmployeeSlim(userType: UserType = UserType.Employee, role: string = null, roles: string[] = null) {
        let roleString = ''
        if (role) {
            roleString = `&role=${role}`;
        }
        
        if (roles) {
            roleString = `&roles=${roles.join(',')}`;
        }

        return this.http.get<PagedResult<UserSlim>>(`${this.urlConstantsService.USERS_URL}/slim?userType=${userType}${roleString}`)
            .pipe(map(usersPagedResult => usersPagedResult.items));
    }

    getEmployeeById(id: string) {
        this.http.get<User>(`${this.urlConstantsService.USERS_URL}/${id}`)
            .subscribe(user => {
                this.userStore.upsert(id, entity => {
                    return {
                        ...user,
                    }
                });
                this.userStore.setActive(id);
            }, (error) => {
                this.toastMessageService.errorNotification('Error retrieving employee data.')
            });
    }

    updateEmployee(user: User) {
        this.http.put<OperationResponse<any>>(`${this.urlConstantsService.USERS_URL}/${user.id}`, user)
            .pipe(map(response => response.data))
            .subscribe(response => {
                this.userStore.upsert(user.id, entity => {
                    return {
                        ...response.user,
                        }
                    });
                    this.toastMessageService.successNotification('Employee successfully updated.');
                }, (error) => {
                    this.toastMessageService.errorNotification('Error updating employee.')
                });
    }

    // User Type Client
    createClientUser(user: User) {
        return this.http.post<OperationResponse<User>>(`${this.urlConstantsService.USERS_URL}/client`, user)
            .pipe(map(response => response.data));
    }

    // User Type Physician
    getAllPhysiciansSlim() {
        var physicianEnum: number = UserType.Physician;
        return this.http.get<PagedResult<UserSlim>>(`${this.urlConstantsService.USERS_URL}/slim?userType=${physicianEnum}`)
            .pipe(map(usersPagedResult => usersPagedResult.items));
    }

    getPhysicianById(id: string) {
        return this.http.get<User>(`${this.urlConstantsService.USERS_URL}/${id}`);
    }

    // Authorized Signers
    getAllAuthorizedSigners(id: string) {
        return this.http.get<PagedResult<AuthorizedSignature>>(`${this.urlConstantsService.USERS_URL}/authorizedsigners/${id}`)
            .pipe(map(pagedResult => pagedResult.items));
    }

    createAuthorizedSignature(signature: AuthorizedSignature) {
        return this.http.post<OperationResponse<AuthorizedSignature>>(`${this.urlConstantsService.USERS_URL}/authorizedsigners`, signature);
    }

    removeAuthorizedSignature(id: string) {
        return this.http.delete<OperationResponse<AuthorizedSignature>>(`${this.urlConstantsService.USERS_URL}/authorizedsigners/${id}`);
    }
}
