<dx-validation-group>
    <div class="tms-mat-dialog">
        <div mat-dialog-title> 
            <div class="row">
                <div class="col">
                    <div *ngIf="title" id="tms-mat-dialog-title" class="page-heading-font">{{title}}</div>
                </div>
                <div class="col float-right">
                    <dx-button class="tms-icon-button" icon="fas fa-times" (onClick)="onCloseClicked()" hint="Close"></dx-button>
                </div>
            </div>
        </div>
        <mat-dialog-content>
            <ng-content select="[tms-dialog-content]"></ng-content>
        </mat-dialog-content>
        <div class="ap-spacer"></div>
        <hr class="tms-mat-dialog-action-row-divider">
        <mat-dialog-actions align="start">
            <div class="row tms-mat-dialog-action-button-row">
                <div class="col">
                    <ng-content select="[tms-dialog-action-row]"></ng-content>
                </div>
                <div class="col float-right">
                    <dx-button id="tms-mat-close" class="tms-secondary-button" [disabled]="cancelButtonDisabled" [text]="cancelButtonText" (onClick)="onCancelClicked($event)"></dx-button>
                    <dx-button id="tms-mat-submit" class="tms-primary-button tms-submit-button" [disabled]="submitButtonDisabled" [text]="submitButtonText" (onClick)="onSubmitClicked($event)"></dx-button>
                </div>
            </div>
        </mat-dialog-actions>
    </div>
</dx-validation-group>