import { Component, EventEmitter, Input, Output} from '@angular/core';
import { InterviewQuestion, InterviewAnswerField } from '@app/model';

@Component({
  selector: 'tms-question-textbox',
  styleUrls: [],
  template: `
    <dx-text-box [value]="interviewAnswerField.value" (onValueChanged)="onTextBoxValueChanged($event)"></dx-text-box>
  `,
})
export class InterviewQuestionTextboxComponent {
    @Input() question: InterviewQuestion;
    @Input() interviewAnswerField: InterviewAnswerField;
    @Output() onInterviewValueChanged: EventEmitter<string> = new EventEmitter<string>();

    constructor() {

    }

    onTextBoxValueChanged($event) {
      this.onInterviewValueChanged.emit($event.value);
    }
}
