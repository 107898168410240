import { Component, Input, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxFileUploaderComponent } from 'devextreme-angular';
import { DataListConstants, SupportService, ToastMessageService } from '@app/core';
import { SupportIssue, SupportIssueAttachment, SupportIssueMessage } from '@app/model';
import { fileHasher$, modalOptions, modalXLargeOption, SortUtils } from '@app/utils';
import { TmsDocumentViewerModalComponent } from '@app/shared';

@Component({
  selector: 'tms-support-ticket-details',
  templateUrl: './support-ticket-details.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupportTicketDetailsComponent implements OnInit {
  @Input() id: string = null;
  @ViewChild(DxFileUploaderComponent) fileuploader: DxFileUploaderComponent;

  addComment: boolean = false;
  comment: string = '';
  supportIssueMessages: SupportIssueMessage[] = [];
  supportIssue: SupportIssue;
  title: string = '';

  imageSrc: any = null;

  get supportIssueStatusDataList(): string {
    return DataListConstants.SUPPORT_ISSUE_STATUS;
  }

  constructor(private activeModalService: NgbActiveModal,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private supportService: SupportService,
    private toastMessageService: ToastMessageService) { }

  ngOnInit() {
    this.supportService.getSupportTicketById(this.id)
      .subscribe(supportIssue => {
        this.supportIssue = supportIssue;
        this.title = `Support Issue: ${this.supportIssue.ticketNumber}`;
        this.supportIssueMessages = SortUtils.orderByDateAsc(this.supportIssue.messages, 'timeStamp');
      });
  }

  onAddComment() {
    this.addComment = true;
    this.cdr.detectChanges();
  }

  onCancelSupportIssueReply() {
    this.activeModalService.dismiss();
  }

  onSupportIssueReply(e) {
    const validResult = e.validationGroup.validate();

    if (validResult.isValid) {
      if (this.fileuploader.value && this.fileuploader.value.length > 0) {
        const file = this.fileuploader.value[0];
        fileHasher$(file).subscribe(result => {
          this.supportService.addSupportIssueAttachment(this.supportIssue.ticketNumber,
            {
              author: { ...this.supportIssue.creator },
              fileName: file.name,
              fileHash: result,
              fileSize: file.size,
              createdDate: new Date(),
            }, file).subscribe(() => { });
        });
      }

      const supportTicketMessage: SupportIssueMessage = {
        author: { ...this.supportIssue.creator },
        timeStamp: new Date(),
        body: this.comment,
      };
      this.supportService.addSupportIssueReplyMessage(this.supportIssue.ticketNumber, supportTicketMessage)
        .subscribe(() => {
          this.toastMessageService.successNotification('Succesfully added reply.')
          this.activeModalService.close();
        }, () => this.toastMessageService.errorNotification('An error occurred when trying to reply to support ticket.'));
    }

  }

  validateFile = (params) => {
    if (this.fileuploader.value && this.fileuploader.value.length > 0) {
      const file = this.fileuploader.value[0];

      if (file.size > 130023424) {
        params.rule.isValid = false;
        params.rule.message = 'File cannot exceed 125 MB.';
        return false;
      } else {
        params.rule.isValid = true;
        return true;
      }
    } else {
      params.rule.isValid = true;
      return true;
    }
  }

  onAttachmentClicked(attachment: SupportIssueAttachment) {
    this.cdr.detach();
    const modalRef = this.modalService.open(TmsDocumentViewerModalComponent, { ...modalOptions, ...modalXLargeOption });
    modalRef.componentInstance.fileData = attachment.fileData;
    modalRef.componentInstance.mimeType = attachment.mimeType;
    modalRef.componentInstance.canDownload = false;
    modalRef.closed.subscribe(() => this.cdr.reattach());
  }

}
