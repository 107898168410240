<div class="modal-header">
    <h4 class="modal-title">Time Clock</h4>
    <div style="float: right">
        <dx-button class="tms-icon-button" icon="fas fa-times" (click)="closeModal()" hint="Close"></dx-button>
    </div>
</div>
<div class="modal-body">
    <div *ngIf="facilities$ | async">
        <div class="row">
            <div class="col-12">
                <dx-data-grid [dataSource]="recentEntries$ | async" [columnAutoWidth]="true">
                    <dxi-column dataField="facilityId" caption="Location">
                        <dxo-lookup [dataSource]="facilities" valueExpr="id" displayExpr="name">
                        </dxo-lookup>
                    </dxi-column>
                    <dxi-column dataField="timeClockAction" caption="Pay Type">
                        <dxo-lookup [dataSource]="payType" valueExpr="id" displayExpr="name">
                        </dxo-lookup>
                    </dxi-column>
                    <dxi-column dataField="clockIn" caption="Time In" dataType='date' format="shortTime">
                    </dxi-column>
                    <dxi-column dataField="clockOut" caption="Time Out" dataType='date' format="shortTime">
                    </dxi-column>
                    <dxi-column dataField="discipline">
                        <dxo-lookup [dataSource]="disciplines$ | async" displayExpr="shortName" valueExpr="valueInt"></dxo-lookup>
                    </dxi-column>
                    <dxi-column dataField="designation">
                        <dxo-lookup [dataSource]="designations$ | async" displayExpr="shortName" valueExpr="valueInt"></dxo-lookup>
                    </dxi-column>
                    <dxi-column dataField="totalDurationInMinutes" caption="Hours"
                        [calculateDisplayValue]="calculateHours"></dxi-column>
                    <dxo-summary>
                        <dxi-total-item column="totalDurationInMinutes" summaryType="sum"
                            [customizeText]="calculateTotalHours">
                        </dxi-total-item>
                    </dxo-summary>
                </dx-data-grid>
            </div>
        </div>
        <div class="row tms-row-divider">
            <div class="col-1">
                Notes:
            </div>
            <div class="col-11">
                <dx-text-area [(value)]="timeClockNote" [height]="90">
                </dx-text-area>
            </div>
        </div>
        <div class="row tms-row-divider" *hasPermission="timeclockAddPermission">
            <div class="col-12">
                <div class="float-right">
                    <dx-button class="tms-secondary-button" text="Update Note"
                        (onClick)="updateNoteButtonClicked($event)"></dx-button>
                    <dx-button class="tms-primary-button tms-submit-button" [text]="clockButtonText"
                        (onClick)="clockButtonClicked($event)">
                    </dx-button>
                </div>
            </div>
        </div>
    </div>
</div>