import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { AuthService, CleanUpService } from '@app/core';
import { OnDestroyComponent } from '@app/shared';

@Component({
  selector: 'tms-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublicComponent extends OnDestroyComponent implements OnInit, OnDestroy {

  constructor(private authService: AuthService,
    private cleanUpService: CleanUpService,
    private router: Router) {
    super();
  }

  ngOnInit() {
    if (this.authService.isAuthenticated) {
      this.cleanUpService.cleanUp();
      this.authService.logout();
    }
    
    this.authService.isAuthenticated$
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.router.navigateByUrl(this.authService.redirectUrl);
      }
    });
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  onLoginClicked() {
    this.authService.login();
  }

}
