import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DenialClaimStore, DenialClaimState } from './denial-claim.store';

@Injectable({
  providedIn: 'root',
})
export class DenialClaimQuery extends QueryEntity<DenialClaimState> {

  constructor(protected store: DenialClaimStore) {
    super(store);
  }

}