import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'tms-toggle-button',
  templateUrl: './tms-toggle-button.component.html',
  styleUrls: ['./tms-toggle-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TmsToggleButtonComponent implements OnInit {
  @Input() hint: string = null;
  @Input() text: string = '';
  @Input() value: boolean = false;
  @Input() id?: string;

  // adding 'Change' enables two-way binding on @Input() value
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  onClicked() {
    this.value = !this.value;
    this.valueChange.emit(this.value);
  }
}
