import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { PagedResult, Client, Facility, Contract, CustomRateTable, OperationResponse, ClientSlim, DataListItem, DataList, eClientPaymentTerm, DefaultContractDetails } from '@app/model';
import { ClientsStore, ClientsQuery } from '../stores';
import { ToastMessageService } from './toast-message.service';
import { FacilitiesService } from './facilities.service';
import { ErrorMessageUtils } from '@app/utils';

const CONTRACT_PROP = 'contracts';
const FACILITIES_PROP = 'facilities';
const RATE_TABLE_PROP = 'rateTables';

@Injectable({
	providedIn: 'root',
})
export class ClientsService {
    constructor(private http: HttpClient,
        private clientsStore: ClientsStore,
        private clientQuery: ClientsQuery,
        private toastMessageService: ToastMessageService,
        private urlConstantsService: UrlConstantsService,
        private facilitiesService: FacilitiesService) { }

    getAll() {
        return this.http.get<PagedResult<Client>>(`${this.urlConstantsService.CLIENTS_URL}`)
            .pipe(map(usersPagedResult => usersPagedResult.items));
    }

    getAllSlim() {
        return this.http.get<PagedResult<ClientSlim>>(`${this.urlConstantsService.CLIENTS_URL}/slim`)
            .pipe(map(usersPagedResult => usersPagedResult.items));
    }

    getActiveClient(): Client {
        return this.clientQuery.getActive();
    }

    getActiveClient$(): Observable<Client> {
        return this.clientQuery.selectActive();
    }

    getById(id: string) {
        this.http.get<Client>(`${this.urlConstantsService.CLIENTS_URL}/${id}`)
            .subscribe(client => {
                this.clientsStore.upsert(id, entity => {
                    return {
                        ...client,
                    }
                });
                this.clientsStore.setActive(id);
            });
    }

    createClient(client: Client) {
        return this.http.post<OperationResponse<Client>>(`${this.urlConstantsService.CLIENTS_URL}`, client)
            .pipe(map(response => response.data));
    }

    updateClient(clientId: string, client: Client) {
        return this.http.put<OperationResponse<Client>>(`${this.urlConstantsService.CLIENTS_URL}/${clientId}`, client)
            .pipe(
                map(response => response.data),
                tap(client => {
                    this.clientsStore.update(client.id, entity => {
                        return {
                            ...entity,
                            name: client.name,
                        };
                    });
                }));
    }

    updateClientPaymentTerms(clientId: string, paymentTerm: eClientPaymentTerm): Observable<Client> {
        const request: DefaultContractDetails = {
            clientPaymentTerm: paymentTerm
        };

        return this.http.put<OperationResponse<Client>>(`${this.urlConstantsService.CLIENTS_URL}/${clientId}/defaultcontracts`, request)
            .pipe(map(response => response.data),
                  tap(client => {
                    this.clientsStore.update(client.id, entity => {
                        return {
                            ...entity,
                            defaultContracts: {
                                ...entity.defaultContracts,
                                clientPaymentTerm: client.defaultContracts.clientPaymentTerm,
                            }
                        }
                    });
                  }));

    }

    // Contracts
    getContracts(clientId: string) {
        this.http.get<PagedResult<Contract>>(`${this.urlConstantsService.CLIENTS_URL}/${clientId}/contracts`)
            .pipe(map(usersPagedResult => usersPagedResult.items))
            .subscribe(contracts => {
                this.clientsStore.upsert(clientId, entity => {
                    return {
                        contracts,
                    }
                });
            });
    }

    createContract(clientId: string, contract: Contract) {
        return this.http.post<OperationResponse<Contract>>(`${this.urlConstantsService.CLIENTS_URL}/${clientId}/contracts`, contract)
            .pipe(tap((response) => {
                this.insertArrayPropClientsStore(clientId, CONTRACT_PROP, response);
                this.toastMessageService.successNotification('Contract successfully created.');
            },
                (error) => {
                    this.toastMessageService.errorNotification(ErrorMessageUtils.getDisplayErrorMessage(error, 'An errored occurred creating contract'))
                }));
    }

    updateContract(clientId: string, contract: Contract) {
        return this.http.put<OperationResponse<Contract>>(`${this.urlConstantsService.CLIENTS_URL}/${clientId}/contracts`, contract)
            .pipe(tap((response) => {
                this.updateArrayPropClientsStore(clientId, CONTRACT_PROP, response);
                this.toastMessageService.successNotification('Contract successfully updated.');
            },
                (error) => {
                    this.toastMessageService.errorNotification(ErrorMessageUtils.getDisplayErrorMessage(error, 'An errored occurred updating contract'))
            }));
    }

    deleteContract(clientId: string, contractId: string) {
        return this.http.delete<void>(`${this.urlConstantsService.CLIENTS_URL}/${clientId}/contracts/${contractId}`)
            .pipe(tap((response) => {
                this.deleteArrayPropPatientStore(clientId, CONTRACT_PROP, contractId);
                this.toastMessageService.successNotification('Contract successfully delected.');
            },
                (error) => {
                    this.toastMessageService.errorNotification(ErrorMessageUtils.getDisplayErrorMessage(error, 'An errored occurred deleting contract'))
            }));
    }

    // Rate Tables
    getCustomRateTables(clientId: string) {
        this.http.get<PagedResult<CustomRateTable>>(`${this.urlConstantsService.RATE_TABLES_URL}/?clientId=${clientId}`)
            .pipe(map(usersPagedResult => usersPagedResult.items))
            .subscribe(rateTables => {
                this.clientsStore.upsert(clientId, entity => {
                    return {
                        rateTables,
                    }
                });
            });
    }

    createCustomRateTable(clientId: string, rateTable: CustomRateTable) {
        this.http.post<OperationResponse<CustomRateTable>>(`${this.urlConstantsService.RATE_TABLES_URL}`, rateTable)
            .subscribe((response) => {
                this.insertArrayPropClientsStore(clientId, RATE_TABLE_PROP, response);
                this.toastMessageService.successNotification('Custom rate table successfully created.');
            },
                (error) => {
                    this.toastMessageService.errorNotification('An errored occurred creating the custom rate table.')
                });
    }

    updateCustomRateTable(clientId: string, rateTable: CustomRateTable) {
        this.http.put<OperationResponse<CustomRateTable>>(`${this.urlConstantsService.RATE_TABLES_URL}`, rateTable)
            .subscribe((response) => {
                this.updateArrayPropClientsStore(clientId, RATE_TABLE_PROP, response);
                this.toastMessageService.successNotification('Custom rate table successfully updated.');
            },
                (error) => {
                    this.toastMessageService.errorNotification('An errored occurred updating the custom rate table.')
                });
    }

    getRugLevelRatesByPayerId(facilityId: string, payerId: string, referenceDate: Date): Observable<string[]> {
        return this.http.get<string[]>(`${this.urlConstantsService.RATE_TABLES_URL}/ruglevelrates?facilityId=${facilityId}&payerId=${payerId}&referenceDate=${referenceDate}`);
    }

    // facilities
    createFacility(clientId: string, facility: Facility) {
        this.facilitiesService.createFacility(facility)
            .subscribe(response => {
                this.insertArrayPropClientsStore(clientId, FACILITIES_PROP, { data: response, isSuccessful: true });
            }, (error) => this.toastMessageService.errorNotification('An errored occurred creating facility.'));
    }

    // Client Portal
    getPortalPatientDashboard(facilityId: string, isActive: boolean, isDischarged: boolean) {
        return this.http.get<PagedResult<Contract>>(`${this.urlConstantsService.CLIENTS_URL}/portal/dashboard?facilityId=${facilityId}&isActive=${isActive}&isDischarged=${isDischarged}`)
            .pipe(map(usersPagedResult => usersPagedResult.items))
    }

    // Clients Store
    resetStore() {
        this.clientsStore.remove();
    }

    private insertArrayPropClientsStore(clientId: string, propertyField: string, newPropertyValue: OperationResponse<any>): void {
        if (newPropertyValue.isSuccessful) {
            this.clientsStore.update(clientId, entity => {
                return {
                    ...entity,
                    [propertyField]: [
                        ...entity[propertyField],
                        newPropertyValue.data,
                    ],
                }
            });
        }
    }

    private updateArrayPropClientsStore(clientId: string, propertyField: string, newPropertyValue: OperationResponse<any>): void {
        if (newPropertyValue.isSuccessful) {
            this.clientsStore.update(clientId, entity => {
                const updateIndex = entity[propertyField].findIndex(item => item.id === newPropertyValue.data.id);
                return {
                    ...entity,
                    [propertyField]: [
                        ...entity[propertyField].slice(0, updateIndex),
                        newPropertyValue.data,
                        ...entity[propertyField].slice(updateIndex + 1),
                    ],
                }
            });
        }
    }

    private deleteArrayPropPatientStore(clientId: string, propertyField: string, deletedId: string): void {
        this.clientsStore.update(clientId, entity => {
            const deleteIndex = entity[propertyField].findIndex(item => item.id === deletedId);
            return {
                ...entity,
                [propertyField]: [
                    ...entity[propertyField].slice(0, deleteIndex),
                    ...entity[propertyField].slice(deleteIndex + 1),
                ],
            }
        });
    }
}
