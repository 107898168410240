import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, throwError } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { UrlConstantsService } from '@app/core/services/url-constants.service';

import {
    Case,
    OperationResponse,
    PagedResult,
    Patient,
    PatientDemographics,
    PatientSearchCriteria,
    PatientSimple,
    PatientRoomNumber,
    PatientSetting,
    Address,
    PatientUniqueIdentifier,
    PatientReferral,
    PatientNote,
    PatientPayer,
    PatientInquiry,
    PatientPayerIdentifier,
    PatientMedicalHistoryItem,
    PatientAdverseEvent,
    PatientFacilities,
    GenericSearch,
    Episode,
    PatientContactInfo,
    PatientCapUsed,
    ErrorItem,
    PatientRisk,
    PatientCognitiveFunctionalLevel,
    PatientDietOrder,
    PatientDietOrderResponse,
    PatientDietOrderCommand,
} from '@app/model';

import { PatientsStore } from '@app/pages/patient/store/patients.store';
import { PatientsQuery } from '@app/pages/patient/store/patients.query';
import { DocumentRecordsService } from '@app/core/services/document-records.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { PatientPropertiesConstants } from '../constants';
import { ErrorMessageUtils } from '@app/utils';

const ADDRESSES_PROP = 'addresses';
const CONTACT_INFO_PROP = 'contactInfo';
export const EXTERNAL_IDENTIIERS_PROP = 'externalIdentifiers';
export const PATIENT_FACILITIES_PROP = 'facilities';
export const PATIENT_INQUIRIES_PROP = 'patientInquiries';
const PATIENT_PAYER_IDENTIFIER_PROP = 'patientPayerIdentifier';
export const REFERRALS_PROP = 'referrals';
export const ROOM_NUMBER_HISTORY_PROP = 'roomNumberHistory';
export const SETTING_HISTORY_PROP = 'settingHistory';
const MEDICAL_HISTORY_PROP = 'medicalHistory';
const ADVERSE_EVENTS_PROP = 'adverseEvents';
export const PATIENT_NOTES_PROP = 'patientNotes';
export const PATIENT_CAP_USED_PROP = 'patientCapUsed';
export const PATIENT_CARE_EPISODES = 'patientCareEpisodes';
export const PATIENT_CARE_EPISODE_CASES = 'cases';
export const RISKS_PROP = 'risks';

interface PatientRequestStatus {
    isSuccessful: boolean;
    property: string;
    errorMessage?: string;
}

@Injectable({
	providedIn: 'root',
})
export class PatientsService {
    private patientRequestStatus$: Subject<PatientRequestStatus> = new Subject<PatientRequestStatus>();

    constructor(private http: HttpClient,
                private patientsStore: PatientsStore,
                private patientsQuery: PatientsQuery,
                private documentRecordsService: DocumentRecordsService,
                private toastMessageService: ToastMessageService,
                private urlConstantsService: UrlConstantsService) {
    }

    activePatient$(): Observable<any>;
    activePatient$(property: string): Observable<any>;
    activePatient$<T>(property?: string): Observable<T>;
    activePatient$<T>(property?: string): Observable<any> | Observable<T> {
        if (property) {
            return this.patientsQuery.selectActive(entity => entity[property]);
        } else {
            return this.patientsQuery.selectActive();
        }
    }

    createNewPatient(patientSimple: PatientSimple, force?: boolean) {
        const url = !!force ? `${this.urlConstantsService.PATIENTS_URL}?forceCreate=true` : this.urlConstantsService.PATIENTS_URL;
        return this.http.post(url, patientSimple);
    }

    getAll() {
        return this.http.get<Patient[]>(`${this.urlConstantsService.PATIENTS_URL}/slim`);
    }

    clearActivePatient() {
        this.patientsStore.setActive(null);
    }

    getActivePatient(): Patient {
        return this.patientsQuery.getActive();
    }

    getById(id: string) {
        this.http.get<Patient>(`${this.urlConstantsService.PATIENTS_URL}/${id}`)
            .subscribe(patient => {
                this.patientsStore.upsert(id, entity => {
                    return {
                        ...patient,
                        patientDemographics: {
                            ...patient.patientDemographics,
                            firstName: patient.firstName,
                            lastName: patient.lastName,
                        },
                    }
                });
                this.patientsStore.setActive(id);
            });
    }

    deletePatient(id: string, forceDelete: boolean = false) {
        return this.http.delete<OperationResponse<any>>(`${this.urlConstantsService.PATIENTS_URL}/${id}?forceDelete=${forceDelete}`);
    }

    // Patient Demographics
    getDemographics(patientId: string) {
        return this.http.get<PatientDemographics>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/demographics`);
    }

    createDemographics(patientId: string, demographics: PatientDemographics) {
        return this.http.post<PatientDemographics>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/demographics`, demographics);
    }

    updateDemographics(patientId: string, demographics: PatientDemographics) {
        return this.http.put<OperationResponse<PatientDemographics>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/demographics/update`, demographics)
            .pipe(tap(patientDemographics => {
                this.patientsStore.update(patientId, entity => {
                    return {
                        ...entity,
                        patientDemographics: {
                            ...patientDemographics.data,
                        },
                    }
                });
            }));
    }

    getFullSsn(patientId: string) {
        return this.http.get<OperationResponse<string>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/demographics/ssn`)
            .pipe(map(response => response.data));
    }

    // Patient Room Number
    getLatestRoomNumber(patientId: string) {
        return this.http.get<PatientRoomNumber>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/roomnumbers/latest`);
    }

    getRoomNumber(patientId: string, id: string) {
        return this.http.get<PatientRoomNumber>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/roomnumbers/${id}`);
    }

    getAllRoomNumbers(patientId: string) {
        return this.http.get<PagedResult<PatientRoomNumber>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/roomnumbers`);
    }

    createRoomNumber(patientId: string, roomNumber: PatientRoomNumber) {
        this.http.post<OperationResponse<PatientRoomNumber>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/roomnumbers`, roomNumber)
            .subscribe((response: OperationResponse<PatientRoomNumber>) => {
                this.insertArrayPropPatientStore(patientId, ROOM_NUMBER_HISTORY_PROP, response);
            }, error => this.patientRequestError(ROOM_NUMBER_HISTORY_PROP, error));
    }

    updateRoomNumber(patientId: string, roomNumber: PatientRoomNumber) {
        this.http.put<OperationResponse<PatientRoomNumber>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/roomnumbers`, roomNumber)
            .subscribe((response) => {
                this.updateArrayPropPatientStore(patientId, ROOM_NUMBER_HISTORY_PROP, response);
            }, error => this.patientRequestError(ROOM_NUMBER_HISTORY_PROP, error));
    }

    deleteRoomNumber(patientId: string, id: string) {
        return this.http.delete(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/roomnumbers/${id}`);
    }

    // Patient Setting
    getLatestSetting(patientId: string) {
        return this.http.get<PatientSetting>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/settings/latest`);
    }

    getSetting(patientId: string, id: string) {
        return this.http.get<PatientSetting>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/settings/${id}`);
    }

    getAllSettings(patientId: string) {
        return this.http.get<PagedResult<PatientSetting>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/settings`);
    }

    createSetting(patientId: string, setting: PatientSetting) {
        this.http.post<OperationResponse<PatientSetting>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/settings`, setting)
            .subscribe((response) => {
                this.insertArrayPropPatientStore(patientId, SETTING_HISTORY_PROP, response);
            }, error => this.patientRequestError(SETTING_HISTORY_PROP, error));
    }

    updateSetting(patientId: string, setting: PatientSetting) {
        this.http.put<OperationResponse<PatientSetting>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/settings`, setting)
            .subscribe((response) => {
                this.updateArrayPropPatientStore(patientId, SETTING_HISTORY_PROP, response);
            }, error => this.patientRequestError(SETTING_HISTORY_PROP, error));
    }

    deleteSetting(patientId: string, id: string) {
        return this.http.delete(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/settings/${id}`);
    }

    // Patient Address
    getLatestAddress(patientId: string) {
        return this.http.get<Address>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/addresses/latest`);
    }

    getAddress(patientId: string, id: string) {
        return this.http.get<Address>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/addresses/${id}`);
    }

    getAllAddresses(patientId: string) {
        return this.http.get<PagedResult<Address>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/addresses`);
    }

    createAddress(patientId: string, address: Address) {
        this.http.post<OperationResponse<Address>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/addresses`, address)
            .subscribe((response) => {
                this.insertArrayPropPatientStore(patientId, ADDRESSES_PROP, response);
            }, error => this.toastMessageService.errorNotification('Failed to add contact information.'));
    }

    updateAddress(patientId: string, address: Address) {
        this.http.put<OperationResponse<Address>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/addresses/updatelatest`, address)
            .subscribe((response) => {
                this.updateArrayPropPatientStore(patientId, ADDRESSES_PROP, response);
            }, error => this.toastMessageService.errorNotification('Failed to update contact information.'));
    }

    deleteAddress(patientId: string, id: string) {
        return this.http.delete(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/addresses/${id}`);
    }

    // Patient Contact Info
    getContactInfo(patientId: string) {
        return this.http.get<PatientContactInfo>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/contactInfo`);
    }

    updateContactInfo(patientId: string, contactInfo: PatientContactInfo) {
        this.http.put<OperationResponse<PatientContactInfo>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/contactInfo`, contactInfo)
            .subscribe(contactInfo => {
                this.patientsStore.update(patientId, entity => {
                    return {
                        ...entity,
                        contactInfo: {
                            ...contactInfo.data,
                        },
                    }
                });
            }, error => this.toastMessageService.errorNotification('Failed to update contact information.'))
    }

    // Patient Payer Identifier
    getPayerIdentifiers(patientId: string) {
        return this.http.get<PatientPayerIdentifier>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/payeridentifiers`);
    }

    updatePayerIdentifiers(patientId: string, identifiers: PatientPayerIdentifier): Observable<PatientPayerIdentifier> {
        return this.http.post<OperationResponse<PatientPayerIdentifier>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/payeridentifiers`, identifiers)
            .pipe(map(response => response.data),
                  tap(payerIdentifiers => {
                this.patientsStore.update(patientId, entity => {
                    return {
                        ...entity,
                        patientPayerIdentifier: {
                            hicn: payerIdentifiers.hicn,
                            medicareBeneficiaryIdentifier: payerIdentifiers.medicareBeneficiaryIdentifier,
                        },
                    }
                });
            }), catchError(error => {
                const errorMessage = ErrorMessageUtils.getDisplayErrorMessage(error, 'Failed to update patient payer identifiers.');
                return throwError(errorMessage);
            }));
    }

    // Patient Payer
    getLatestPayer(patientId: string) {
        return this.http.get<PatientPayer>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/payers/latest`);
    }

    getPayer(patientId: string, id: string) {
        return this.http.get<PatientPayer>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/payers/${id}`);
    }

    getAllPayers(patientId: string) {
        return this.http.get<PagedResult<PatientPayer>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/payers`);
    }

    createPayer(patientId: string, identifier: PatientPayer) {
        return this.http.post<PatientPayer>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/payers`, identifier);
    }

    updatePayer(patientId: string, identifier: PatientPayer) {
        return this.http.put<PatientPayer>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/payers`, identifier);
    }

    deletePayer(patientId: string, id: string) {
        return this.http.delete(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/payers/${id}`);
    }

    // Patient Facilities
    updatePatientFacilities(patientId: string, facilities: PatientFacilities) {
        this.http.put<OperationResponse<PatientFacilities>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/facilities`, facilities)
            .subscribe((response) => {
                this.updateArrayPropPatientStore(patientId, PATIENT_FACILITIES_PROP, response);
            }, error => this.patientRequestError(PATIENT_FACILITIES_PROP, error));
    }

    updatePatientPrimaryFacility(patientId: string, primaryFacilityId: string) {
        this.http.put<PatientFacilities>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/facilities/primary/${primaryFacilityId}`, {})
        .subscribe(response => {
            this.patientsStore.update(patientId, entity => {
                return {
                    ...entity,
                    patientFacilities: {
                        ...response,
                    },
                }
            });
        }, error => this.toastMessageService.errorNotification('Failed to update patient primary facility.'));
    }

    // Patient Unique Identifiers
    getLatestUniqueIdentifier(patientId: string) {
        return this.http.get<PatientUniqueIdentifier>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/identifiers/latest`);
    }

    getUniqueIdentifier(patientId: string, id: string) {
        return this.http.get<PatientUniqueIdentifier>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/identifiers/${id}`);
    }

    getAllUniqueIdentifiers(patientId: string) {
        return this.http.get<PagedResult<PatientUniqueIdentifier>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/identifiers`);
    }

    createUniqueIdentifier(patientId: string, identifier: PatientUniqueIdentifier) {
        this.http.post<OperationResponse<PatientUniqueIdentifier>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/identifiers`, identifier)
            .subscribe((response) => {
                this.insertArrayPropPatientStore(patientId, EXTERNAL_IDENTIIERS_PROP, response);
            }, error => this.patientRequestError(EXTERNAL_IDENTIIERS_PROP, error));
    }

    updateUniqueIdentifier(patientId: string, identifier: PatientUniqueIdentifier) {
        this.http.put<OperationResponse<PatientUniqueIdentifier>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/identifiers`, identifier)
            .subscribe((response) => {
                this.updateArrayPropPatientStore(patientId, EXTERNAL_IDENTIIERS_PROP, response);
            }, error => this.patientRequestError(EXTERNAL_IDENTIIERS_PROP, error));
    }

    deleteUniqueIdentifier(patientId: string, id: string) {
        this.http.delete<OperationResponse<void>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/identifiers/${id}`)
            .subscribe(response => {
                this.deleteArrayPropPatientStore(patientId, EXTERNAL_IDENTIIERS_PROP, id)
            }, error => this.patientRequestError(EXTERNAL_IDENTIIERS_PROP, error));
    }

    // Patient Referrals
    getLatestPatientReferral(patientId: string) {
        return this.http.get<PatientReferral>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/referrals/latest`);
    }

    getPatientReferral(patientId: string, id: string) {
        return this.http.get<PatientReferral>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/referrals/${id}`);
    }

    getAllPatientReferrals(patientId: string) {
        return this.http.get<PagedResult<PatientReferral>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/referrals`);
    }

    createPatientReferral(patientId: string, referral: PatientReferral) {
        this.http.post<OperationResponse<PatientReferral>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/referrals`, referral)
            .subscribe((response) => {
                this.insertArrayPropPatientStore(patientId, REFERRALS_PROP, response);
            }, error => this.patientRequestError(REFERRALS_PROP, error));
    }

    updatePatientReferral(patientId: string, referral: PatientReferral) {
        this.http.put<OperationResponse<PatientReferral>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/referrals`, referral)
            .subscribe((response) => {
                this.updateArrayPropPatientStore(patientId, REFERRALS_PROP, response);
            }, error => this.patientRequestError(REFERRALS_PROP, error));
    }

    deletePatientReferral(patientId: string, id: string) {
        return this.http.delete(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/referrals/${id}`);
    }

    // Patient Notes
    getLatestPatientNote(patientId: string) {
        return this.http.get<PatientNote>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/notes/latest`);
    }

    getPatientNote(patientId: string, id: string) {
        return this.http.get<PatientNote>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/notes/${id}`);
    }

    getAllPatientNotes(patientId: string) {
        return this.http.get<PagedResult<PatientNote>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/notes`);
    }

    createPatientNote(patientId: string, note: PatientNote) {
        this.http.post<OperationResponse<PatientNote>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/notes`, note)
            .subscribe((response) => {
                this.insertArrayPropPatientStore(patientId, PATIENT_NOTES_PROP, response);
            }, error => this.patientRequestError(PATIENT_NOTES_PROP, error));
    }

    updatePatientNote(patientId: string, note: PatientNote) {
        this.http.put<OperationResponse<PatientNote>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/notes`, note)
            .subscribe((response) => {
                this.updateArrayPropPatientStore(patientId, PATIENT_NOTES_PROP, response);
            }, error => this.patientRequestError(PATIENT_NOTES_PROP, error))
    }

    deletePatientNote(patientId: string, id: string) {
        return this.http.delete(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/notes/${id}`);
    }

    // Patient Cap Used
    createPatientCapUsed(patientId: string, capUsed: PatientCapUsed) {
        this.http.post<OperationResponse<PatientCapUsed>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/patientcapused`, capUsed)
            .subscribe((response) => {
                this.insertArrayPropPatientStore(patientId, PATIENT_CAP_USED_PROP, response);
            }, error => this.patientRequestError(PATIENT_CAP_USED_PROP, error));
    }

    updatePatientCapUsed(patientId: string, capUsed: PatientCapUsed) {
        this.http.put<OperationResponse<PatientNote>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/patientcapused`, capUsed)
            .subscribe((response) => {
                this.updateArrayPropPatientStore(patientId, PATIENT_CAP_USED_PROP, response);
            }, error => this.patientRequestError(PATIENT_CAP_USED_PROP, error))
    }

    // Patient Inquiries
    getLatestInquiry(patientId: string) {
        return this.http.get<PatientInquiry>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/inquiries/latest`);
    }

    getInquiry(patientId: string, id: string) {
        return this.http.get<PatientInquiry>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/inquiries/${id}`);
    }

    getAllInquiries(patientId: string) {
        return this.http.get<PagedResult<PatientInquiry>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/inquiries`);
    }

    createInquiry(patientId: string, inquiry: PatientInquiry) {
        this.http.post<OperationResponse<PatientInquiry>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/inquiries`, inquiry)
            .subscribe((response) => {
                this.insertArrayPropPatientStore(patientId, PATIENT_INQUIRIES_PROP, response);
            }, error => this.patientRequestError(PATIENT_INQUIRIES_PROP, error));
    }

    updateInquiry(patientId: string, inquiry: PatientInquiry) {
        this.http.put<OperationResponse<PatientInquiry>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/inquiries`, inquiry)
            .subscribe((response) => {
                this.updateArrayPropPatientStore(patientId, PATIENT_INQUIRIES_PROP, response);
            }, error => this.patientRequestError(PATIENT_INQUIRIES_PROP, error));
    }

    deleteInquiry(patientId: string, id: string) {
        return this.http.delete(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/inquiries/${id}`);
    }

    // Patient Medical Data
    createPatientMedicalHistory(patientId: string, medicalHistoryItem: PatientMedicalHistoryItem) {
        this.http.post<OperationResponse<PatientMedicalHistoryItem>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/medicalHistory`, medicalHistoryItem)
            .subscribe((response) => {
                this.insertArrayPropPatientStore(patientId, MEDICAL_HISTORY_PROP, response);
            });
    }

    updatePatientMedicalHistory(patientId: string, medicalHistoryItem: PatientMedicalHistoryItem) {
        this.http.put<OperationResponse<PatientMedicalHistoryItem>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/medicalHistory`, medicalHistoryItem)
            .subscribe((response) => {
                this.updateArrayPropPatientStore(patientId, MEDICAL_HISTORY_PROP, response);
            });
    }

    createPatientAdverseEvents(patientId: string, adverseEvent: PatientAdverseEvent) {
        this.http.post<OperationResponse<PatientAdverseEvent>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/adverseEvents`, adverseEvent)
            .subscribe((response) => {
                this.insertArrayPropPatientStore(patientId, ADVERSE_EVENTS_PROP, response);
            });
    }

    updatePatientAdverseEvents(patientId: string, adverseEvent: PatientAdverseEvent) {
        this.http.put<OperationResponse<PatientAdverseEvent>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/adverseEvents`, adverseEvent)
            .subscribe((response) => {
                this.updateArrayPropPatientStore(patientId, ADVERSE_EVENTS_PROP, response);
            });
    }

    createPatientRisk(patientId: string, risk: PatientRisk): Observable<PatientRisk> {
        return this.http.post<OperationResponse<PatientRisk>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/risks`, risk)
            .pipe(tap(response => this.insertArrayPropPatientStore(patientId, RISKS_PROP, response)),
                  map(response => response.data));
    }

    updatePatientRisk(patientId: string, risk: PatientRisk) {
        this.http.put<OperationResponse<PatientRisk>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/risks`, risk)
            .subscribe((response) => {
                this.updateArrayPropPatientStore(patientId, RISKS_PROP, response);
            });
    }

    // cognitive functional maintenance
    createPatientCognitivePlan(patientId: string, plan: PatientCognitiveFunctionalLevel): Observable<PatientCognitiveFunctionalLevel> {
        return this.http.post<OperationResponse<PatientCognitiveFunctionalLevel>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/cognitivefunctionallevels`, plan)
            .pipe(tap(response => this.insertArrayPropPatientStore(patientId, PatientPropertiesConstants.COGNITIVE_FUNCTIONAL_LEVELS, response)),
                  map(response => response.data));
    }

    updatePatientCognitivePlan(patientId: string, plan: PatientCognitiveFunctionalLevel): Observable<PatientCognitiveFunctionalLevel> {
        return this.http.put<OperationResponse<PatientCognitiveFunctionalLevel>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/cognitivefunctionallevels`, plan)
            .pipe(tap(response => this.updateArrayPropPatientStore(patientId, PatientPropertiesConstants.COGNITIVE_FUNCTIONAL_LEVELS, response)),
                  map(response => response.data));
    }

    // diet order
    createPatientDietOrder(patientId: string, dietOrder: PatientDietOrder): Observable<PatientDietOrderResponse> {
        const patientDietOrderCommand: PatientDietOrderCommand = {
            patientId: patientId,
            dietOrder: dietOrder,
        };
        return this.http.post<OperationResponse<PatientDietOrderResponse>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/dietorder`, patientDietOrderCommand)
            .pipe(tap(response => this.updateListPropPatientStore(patientId, PatientPropertiesConstants.PATIENT_DIET_ORDERS_PROP, { isSuccessful: response.isSuccessful, data: response.data.dietOrders })),
                 map(response => response.data));
    }

    updatePatientDietOrder(patientId: string, dietOrder: PatientDietOrder): Observable<PatientDietOrderResponse> {
        const patientDietOrderCommand: PatientDietOrderCommand = {
            patientId: patientId,
            dietOrder: dietOrder,
        };
        return this.http.put<OperationResponse<PatientDietOrderResponse>>(`${this.urlConstantsService.PATIENTS_URL}/${patientId}/dietorder`, patientDietOrderCommand)
            .pipe(tap(response => this.updateListPropPatientStore(patientId, PatientPropertiesConstants.PATIENT_DIET_ORDERS_PROP, { isSuccessful: response.isSuccessful, data: response.data.dietOrders })),
                  map(response => response.data));
    }


    // Patient Care Episodes
    createPatientCareEpisode(patientId: string, episodeResponse: OperationResponse<Episode>) {
        this.insertArrayPropPatientStore(patientId, PATIENT_CARE_EPISODES, episodeResponse);
    }

    updatePatientCareEpisode(patientId: string, episodeResponse: OperationResponse<Episode>) {
        this.updateArrayPropPatientStore(patientId, PATIENT_CARE_EPISODES, episodeResponse);
    }

    updatePatientCareEpisodes(patientId: string, episodeResponse: OperationResponse<Episode[]>) {
        this.updateListPropPatientStore(patientId, PATIENT_CARE_EPISODES, episodeResponse);
    }

    // Patient Episode Cases
    createPatientCareEpisodeCase(patientId: string, caseResponse: OperationResponse<Case>) {
        const activePatient = this.getActivePatient();
        if (activePatient) {
            const episodeIndex = activePatient.patientCareEpisodes.findIndex(episode => episode.id === caseResponse.data.episodeId);
            if (episodeIndex >= 0) {
                this.updatePatientCareEpisode(patientId, {
                    data: {
                        ...activePatient.patientCareEpisodes[episodeIndex],
                        cases: [
                            ...activePatient.patientCareEpisodes[episodeIndex].cases,
                            caseResponse.data,
                        ]   
                    },
                    isSuccessful: true,
                });
            }
        }
    }

    updatePatientCaseEpisodeCase(patientId: string, caseResponse: OperationResponse<Case>) {
        const activePatient = this.getActivePatient();
        const episodeIndex = activePatient.patientCareEpisodes.findIndex(episode => episode.id === caseResponse.data.episodeId);
        if (episodeIndex >= 0) {
            const caseIndex = activePatient.patientCareEpisodes[episodeIndex].cases.findIndex(episodeCase => episodeCase.id === caseResponse.data.id);
            if (caseIndex >= 0) {
                this.updatePatientCareEpisode(patientId, {
                    data: {
                        ...activePatient.patientCareEpisodes[episodeIndex],
                        cases: [
                            ...activePatient.patientCareEpisodes[episodeIndex].cases.slice(0, caseIndex),
                            caseResponse.data,
                            ...activePatient.patientCareEpisodes[episodeIndex].cases.slice(caseIndex + 1),
                        ]   
                    },
                    isSuccessful: true,
                });
            }
        }
    }

    getActivePatientCaseById(patientEpisodeId: string, patientCaseId: string): Case {
        const activePatient = this.getActivePatient();
        const episode = activePatient.patientCareEpisodes.find(episode => episode.id == patientEpisodeId);

        if (episode) {
            const patientCase = episode.cases.find(c => c.id == patientCaseId);

            if (patientCase) {
                return patientCase;
            }
        }

        return null;
    }

    // Patient Documents
    deleteDocument(patientId: string, documentId: string) {
        this.documentRecordsService.deletePatientDocument(patientId, documentId);
    }

    getAllDocuments(patientId: string) {
        this.documentRecordsService.getAllPatientDocuments(patientId);
    }

    getDocument(patientId: string, documentId: string) {
        this.documentRecordsService.getPatientDocument(patientId, documentId);
    }

    uploadDocument(patientId: string, fileName: string, friendlyName: string, documentRecordCategoryId: string, documentDate: Date, 
        file: any, episodeId: string = '', caseId: string = '', preauthorizationRequestId: string = '') {
        this.documentRecordsService.uploadPatientDocument(patientId, fileName, friendlyName, documentRecordCategoryId, documentDate, 
            file, episodeId, caseId, preauthorizationRequestId);
    }

    // Patient Search
    patientSearch(criteria: PatientSearchCriteria): Observable<Patient[]> {
        return this.http.post<PagedResult<Patient>>(`${this.urlConstantsService.PATIENTS_URL}/searchadvanced?pageSize=100`, criteria)
            .pipe(map(response => response.items));
    }

    patientGenericSearch(criteria: GenericSearch): Observable<Patient[]> {
        return this.http.post<PagedResult<Patient>>(`${this.urlConstantsService.PATIENTS_URL}/search?pageSize=100`, criteria)
            .pipe(map(response => response.items));
    }

    resetStore() {
        this.patientsStore.remove();
    }

    getPatientRequestStatusPromise(property: string, errorMessage?: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.patientRequestStatus$
                .pipe(filter(value => value.property === property))
                .subscribe(value => value.isSuccessful ? resolve(false) : value.errorMessage ? reject(value.errorMessage) : reject(errorMessage));
        });
    }

    private patientRequestError(property: string, error?: any) {
        const errorMessage = error != null ? ErrorMessageUtils.getDisplayErrorMessage(error, '') : null;
        this.patientRequestStatus$.next({ property: property, isSuccessful: false, errorMessage: errorMessage})
    }

    private insertArrayPropPatientStore(patientId: string, propertyField: string, newPropertyValue: OperationResponse<any>): void {
        if (newPropertyValue.isSuccessful) {
            this.patientsStore.update(patientId, entity => {
                return {
                    ...entity,
                    [propertyField]: [
                        ...entity[propertyField],
                        newPropertyValue.data,
                    ],
                }
            });
            this.patientRequestStatus$.next({ property: propertyField, isSuccessful: true});
        } else {
            this.patientRequestError(propertyField);
        }
    }

    private updateArrayPropPatientStore(patientId: string, propertyField: string, newPropertyValue: OperationResponse<any>): void {
        if (newPropertyValue.isSuccessful) {
            this.patientsStore.update(patientId, entity => {
                const updateIndex = entity[propertyField].findIndex(item => item.id === newPropertyValue.data.id);
                return {
                    ...entity,
                    [propertyField]: [
                        ...entity[propertyField].slice(0, updateIndex),
                        newPropertyValue.data,
                        ...entity[propertyField].slice(updateIndex + 1),
                    ],
                }
            });
            this.patientRequestStatus$.next({ property: propertyField, isSuccessful: true});
        } else {
            this.patientRequestError(propertyField);
        }
    }

    private deleteArrayPropPatientStore(patientId: string, propertyField: string, deletedId: string): void {
        this.patientsStore.update(patientId, entity => {
            const deleteIndex = entity[propertyField].findIndex(item => item.id === deletedId);
            return {
                ...entity,
                [propertyField]: [
                    ...entity[propertyField].slice(0, deleteIndex),
                    ...entity[propertyField].slice(deleteIndex + 1),
                ],
            }
        });
        this.patientRequestStatus$.next({ property: propertyField, isSuccessful: true});
    }

    private updateListPropPatientStore(patientId: string, propertyField: string, newPropertyValue: OperationResponse<any[]>): void {
        if (!!newPropertyValue.isSuccessful) {
            this.patientsStore.update(patientId, entity => {
                return {
                    ...entity,
                    [propertyField]: newPropertyValue.data,
                }
            });
        }
    }
}
