import { Injectable } from '@angular/core';
import { resetStores } from "@datorama/akita";
import { AnalyticsService } from './analytics.service';
import { AuthService } from './authentication';
import { EpisodesService } from './episodes.service'; 
import { FacilitiesService } from './facilities.service';
import { ImpersonateService } from './impersonate.service';
import { LocalStorageService } from './local-storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserAutoLogoutService } from './user-auto-logout.service';

// Responisible for removing/cleaning up 

@Injectable({
  providedIn: 'root'
})
export class CleanUpService {

  constructor(private analyticsService: AnalyticsService,
    private authService: AuthService,
    private episodesService: EpisodesService,
    private facilitiesService: FacilitiesService,
    private impersonateService: ImpersonateService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private userAutoLogoutService: UserAutoLogoutService) { }

  cleanUp() {
    this.analyticsService.reset();
    this.authService.cleanUp();
    this.episodesService.setMoveCase(null);
    this.facilitiesService.activeFacilityId = '';
    this.localStorageService.clearLocalStorage();
    this.impersonateService.cleanUp();
    this.userAutoLogoutService.cleanUp();
    resetStores();
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
  }
}
