import { ePermissions } from '@app/model';

export class UIPermissionConstants {
    static ALL: ePermissions = ePermissions.PatientsView | ePermissions.FacilityAdminAdd;   // TODO ad the rest

    static ADMIN: ePermissions = ePermissions.Admin;
    static ANNOUNCEMENTS_ADD: ePermissions = ePermissions.AnnouncementsAdd;
    static ANNOUNCEMENTS_UPDATE: ePermissions = ePermissions.AnnouncementsUpdate;
    static ANNOUNCMENTS_VIEW: ePermissions = ePermissions.AnnouncementsView;
    static BILLING: ePermissions = ePermissions.Billing;
    static BILLING_VIEW: ePermissions = ePermissions.BillingView;
    static CASE_ADD: ePermissions = ePermissions.CasesAdd;
    static CASE_DELETE: ePermissions = ePermissions.CasesDelete;
    static CASE_UPDATE: ePermissions = ePermissions.CasesUpdate;
    static CASE_VIEW: ePermissions = ePermissions.CasesView;
    static CHANGE_PAYER: ePermissions = ePermissions.ChangePayer;
    static CHANGE_PREVIOUS_PAYER: ePermissions = ePermissions.ChangePreviousPayer;
    static CHARGES_ADD: ePermissions = ePermissions.ChargesAdd;
    static CHARGES_DELETE: ePermissions = ePermissions.ChargesDelete;
    static CHARGES_UPDATE: ePermissions = ePermissions.ChargesUpdate;
    static CHARGES_VIEW: ePermissions = ePermissions.ChargesView;
    static CHARGES_VIEW_ALL_DISCIPLINES: ePermissions = ePermissions.CanViewChargesForAllDisciplines;
    static CLIENT_PORTAL_VIEW: ePermissions = ePermissions.ClientPortalView;
    static CLIENTS_ADD: ePermissions = ePermissions.ClientsAdd;
    static CLIENTS_UPDATE: ePermissions = ePermissions.ClientsUpdate;
    static CLIENTS_VIEW: ePermissions = ePermissions.ClientsView;
    static CLINICAL_DOCUMENT_VIEW: ePermissions = ePermissions.ClinicalDocumentView;
    static CLINICAL_DOCUMENT_EDIT: ePermissions = ePermissions.ClinicalDocumentEdit;
    static CLINICAL_EXCELLENCE_ADMIN: ePermissions = ePermissions.ClinicalExcellenceAdmin;
    static CODING_INTEGRITY_ASSIGNMENT: ePermissions = ePermissions.CodingIntegrityAssignment;
    static CODING_INTEGRITY_UPDATE: ePermissions = ePermissions.CodingIntegrityUpdate;
    static COMPLIANCE_VIEW: ePermissions = ePermissions.ComplianceView;
    static CONTRACTS_ADD: ePermissions = ePermissions.ContractsAdd;
    static CONTRACTS_UPDATE: ePermissions = ePermissions.ContractsUpdate;
    static CONTRACTS_VIEW: ePermissions = ePermissions.ContractsView;
    static DENIAL_MANAGEMENT_VIEW: ePermissions = ePermissions.DenialManagementView;
    static ECHART_ADD: ePermissions = ePermissions.EChartsAdd;
    static ECHART_DELETE: ePermissions = ePermissions.EChartsDelete;
    static ECHART_UPDATE: ePermissions = ePermissions.EChartsUpdate;
    static ECHART_VIEW: ePermissions = ePermissions.EChartsView;
    static EPISODE_ADD: ePermissions = ePermissions.EpisodesAdd;
    static EPISODE_UPDATE: ePermissions = ePermissions.EpisodesUpdate;
    static EPISODE_DELETE: ePermissions = ePermissions.EpisodesDelete;
    static EPISODE_VIEW: ePermissions = ePermissions.EpisodesView;
    static EXPORTS: ePermissions = ePermissions.Exports;
    static FACILITY_VIEW: ePermissions = ePermissions.FacilityView;
    static FACILITY_ADMIN_VIEW: ePermissions = ePermissions.FacilityAdminView;
    static FACILITY_ADMIN_ADD: ePermissions = ePermissions.FacilityAdminAdd;
    static FACILITY_ADMIN_UPDATE: ePermissions = ePermissions.FacilityAdminUpdate;
    static FACILITY_ADMIN_DELETE: ePermissions = ePermissions.FacilityAdminDelete;
    static FUNCTION_BUILDERS: ePermissions = ePermissions.FunctionBuilders;
    static HUMAN_RESOURCES: ePermissions = ePermissions.HumanResources;
    static INTEGRATION_MANAGEMENT: ePermissions = ePermissions.IntegrationManagement;
    static INSURANCE_VERIFICATION_VIEW: ePermissions = ePermissions.InsuranceVerification;
    static INTERMEDIARY_ADD: ePermissions = ePermissions.IntermediaryAdd;
    static INTERMEDIARY_UPDATE: ePermissions = ePermissions.IntermediaryUpdate;
    static INTERMEDIARY_VIEW: ePermissions = ePermissions.IntermediaryView;
    static INTERVIEW_ADD: ePermissions = ePermissions.InterviewAdd;
    static INTERVIEW_UPDATE: ePermissions = ePermissions.InterviewUpdate;
    static INTERVIEW_VIEW: ePermissions = ePermissions.InterviewView;
    static INTERVIEW_DELETE: ePermissions = ePermissions.InterviewDelete;
    static JUMP_TO_NAVIGATION: ePermissions = ePermissions.JumpToNavigation;
    static KNOWLEDGE_BASE: ePermissions = ePermissions.KnowledgeBase;
    static LINK_EDITOR: ePermissions = ePermissions.LinkEditor;
    static MASTERPAYERS_ADD: ePermissions = ePermissions.MasterPayersAdd;
    static MASTERPAYERS_UPDATE: ePermissions = ePermissions.MasterPayersUpdate;
    static MASTERPAYERS_VIEW: ePermissions = ePermissions.MasterPayersView;
    static MDS_UPLOAD: ePermissions = ePermissions.MdsUpload;
    static OPERATIONS_VIEW: ePermissions = ePermissions.Operations;
    static PATIENTS_ADD: ePermissions = ePermissions.PatientsAdd;
    static PATIENTS_DELETE: ePermissions = ePermissions.PatientsDelete;
    static PATIENTS_UPDATE: ePermissions = ePermissions.PatientsUpdate;
    static PATIENTS_VIEW: ePermissions = ePermissions.PatientsView;
    static PATIENT_SEARCH: ePermissions = ePermissions.PatientsView;
    static PHYSICIANS_ADD: ePermissions = ePermissions.PhysiciansAdd;
    static PHYSICIANS_UPDATE: ePermissions = ePermissions.PhysiciansUpdate;
    static PHYSICIANS_VIEW: ePermissions = ePermissions.PhysiciansView;
    static PLANNER_VIEW: ePermissions = ePermissions.PlannerView;
    static PLANNER_UPDATE: ePermissions = ePermissions.PlannerUpdate;
    static POWER_BI_REPORTS: ePermissions = ePermissions.PowerBIReports;
    static PRACTITIONER_PORTAL_VIEW: ePermissions = ePermissions.PractitionerPortalView;
    static PREMIER_CODING_VIEW: ePermissions = ePermissions.PremierCoding;
    static PREMIER_CODING_UPLOAD_DOCUMENTS: ePermissions = ePermissions.PremierCodingUploadDocuments;
    static REPORTS_VIEW: ePermissions = ePermissions.ReportsView;
    static RESTORATIVE_NURSING: ePermissions = ePermissions.RestorativeNursing;
    static SCREENS_ADD: ePermissions = ePermissions.ScreensAdd;
    static SCREENS_DELETE: ePermissions = ePermissions.ScreensDelete;
    static SCREENS_UPDATE: ePermissions = ePermissions.ScreensUpdate;
    static SCREENS_COMPLETE: ePermissions = ePermissions.ScreensComplete;
    static SCREENS_VIEWS: ePermissions = ePermissions.ScreensView;
    static STARTUPS: ePermissions = ePermissions.Startups;
    static TIMECLOCK_ADD: ePermissions = ePermissions.TimeclockAdd;
    static TIMECLOCK_VIEW: ePermissions = ePermissions.TimeclockView;
    static TIMECLOCK_TRAVEL: ePermissions = ePermissions.TimeclockTravel;
    static UNLOCK_TREATMENT_REQUEST_VIEW: ePermissions = ePermissions.UnlockTreatmentRequestView;
    static USERS_ADD: ePermissions = ePermissions.UsersAdd;
    static USERS_UPDATE: ePermissions = ePermissions.UsersUpdate;
    static USERS_VIEW: ePermissions = ePermissions.UsersView;
    static ICD_ADMIN: ePermissions = ePermissions.IcdAdmin;

    static get permissionsBasedOnClockedIn(): ePermissions[] {
        return [
            ePermissions.CasesAdd,
            ePermissions.CasesDelete,
            ePermissions.CasesUpdate,
            ePermissions.ChargesAdd,
            ePermissions.ChargesUpdate,
            ePermissions.ChargesDelete,
            ePermissions.ClinicalDocumentEdit,
            ePermissions.EChartsAdd,
            ePermissions.EChartsDelete,
            ePermissions.EChartsUpdate,
            ePermissions.EpisodesAdd,
            ePermissions.EpisodesDelete,
            ePermissions.EpisodesUpdate,
            ePermissions.InterviewAdd,
            ePermissions.InterviewDelete,
            ePermissions.InterviewUpdate,           
            ePermissions.PatientsUpdate,
            ePermissions.ScreensAdd,
            ePermissions.ScreensDelete,
            ePermissions.ScreensUpdate,
        ]
    }
}
