import { Injectable } from '@angular/core';
import {
    eDiscipline,
    NarrativeBuilderOption,
    NarrativeBuilderOptionValue,
    NarrativeBuilderList,
    PlofTemplateProperties,
    DataListItem,
    Dictionary,
} from '@app/model';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataListConstants } from '../constants';
import { CacheManService } from './cacheman.service';
import { HandlebarService } from './handlebars.service';
import {
    getPatientGoalsNarrativeBuilder,
    getPrognosticIndicatorsNarrativeBuilder,
    getReasonForReferralNarrativeBuilder,
    getRecertDeficitsNarrativeBuilder, 
    getRecertInterventionsNarrativeBuilder,
    getRecertPrecautionsNarrativeBuilder,
    getRehabPotentialNarrativeBuilder,
} from './narrative-builder';
import { getNBOptionId } from './narrative-builder/id-builder-utils';


const PLOF = 'PLOF';

@Injectable({
    providedIn: 'root',
})
export class NarrativeBuilderService {

    constructor(private cachemanService: CacheManService,
                private handleBarService: HandlebarService) {}

    generateNarrative(templateName: string, obj: any): string {
        return this.handleBarService.generateNarrative(templateName, obj);
    }

    getReasonForReferralNBL(discipline: number): NarrativeBuilderList {
        return getReasonForReferralNarrativeBuilder(this.cachemanService, discipline);
    }

    getPLOFNarrativeList$(discipline: eDiscipline): Observable<NarrativeBuilderList> {
        return combineLatest([
            this.cachemanService.getDataListItems$(DataListConstants.EVALUTATION_PLOF_ADAPTIVE_EQUIPMENT),
            this.cachemanService.getDataListItems$(DataListConstants.EVALUATION_PLOF_ASSISTANCE),
            this.cachemanService.getDataListItems$(DataListConstants.EVALUATION_PLOF_ASSISTIVE_DEVICE),
            this.cachemanService.getDataListItems$(DataListConstants.EVALUATION_PLOF_SETTING),
            this.cachemanService.getDataListItems$(DataListConstants.EVALUATION_PLOF_HOME_OPTIONS),
            this.cachemanService.getDataListItems$(DataListConstants.EVALUATION_PLOF_CARE_LEVEL),
            this.cachemanService.getDataListItems$(DataListConstants.EVALUATION_PLOF_IMPAIRMENT),
            this.cachemanService.getDataListItems$(DataListConstants.EVALUATION_PLOF_DIET),
            this.cachemanService.getDataListItems$(DataListConstants.EVALUATION_PLOF_LIQUID)])
            .pipe(map(([adaptiveEquipmentDL, assistanceDL, assistiveDeviceDL, settingDL, homeOptionsDL, careLevelDL,
                        impairmentDL, dietDL, liquidDL]) => {
                const adaptiveEquipOptions = this.getNarrativeBuiilderOptionValues(adaptiveEquipmentDL);
                const assistanceOptions = this.getNarrativeBuiilderOptionValues(assistanceDL);
                const assistiveDeviceOptions = this.getNarrativeBuiilderOptionValues(assistiveDeviceDL);
                const settingOptions = this.getNarrativeBuiilderOptionValues(settingDL);
                const homeOptions = this.getNarrativeBuiilderOptionValues(homeOptionsDL);
                const careLevelOptions = this.getNarrativeBuiilderOptionValues(careLevelDL);
                const impairmentOptions = this.getNarrativeBuiilderOptionValues(impairmentDL);
                const dietOptions = this.getNarrativeBuiilderOptionValues(dietDL);
                const liquidOptions = this.getNarrativeBuiilderOptionValues(liquidDL);

                // get discipline specific plof options
                let disciplinePlofOptions: Dictionary<NarrativeBuilderOption> = null;
                switch(discipline) {
                    case eDiscipline.PhysicalTherapy:
                        disciplinePlofOptions = this.getPlofPtNarrativeBuilderList(assistanceOptions, assistiveDeviceOptions, homeOptions);
                        break;
                    case eDiscipline.OccupationalTherapy:
                        disciplinePlofOptions = this.getPlofOtNarrativeBuilderList(assistanceOptions, adaptiveEquipOptions, homeOptions);
                        break;
                    case eDiscipline.SpeechTherapy:
                        disciplinePlofOptions = this.getPlofStNarrativeBuilderList(impairmentOptions, dietOptions, liquidOptions);
                        break;
                }

                const RFRPLOFNarrativeList: NarrativeBuilderList = {
                    options: {
                        ...disciplinePlofOptions,
                        [PlofTemplateProperties.SETTING]: {
                            id: getNBOptionId(PLOF, PlofTemplateProperties.SETTING),
                            text: 'Setting',
                            values: settingOptions,
                            selectedValue: null,
                            property: PlofTemplateProperties.SETTING,
                        },
                        [PlofTemplateProperties.CARE_LEVEL]: {
                            id: getNBOptionId(PLOF, PlofTemplateProperties.CARE_LEVEL),
                            text: 'Care Level',
                            values: careLevelOptions,
                            selectedValue: null,
                            property: PlofTemplateProperties.CARE_LEVEL,
                        },
                    },
                }

                return RFRPLOFNarrativeList;
            }))
    }

    private getPlofPtNarrativeBuilderList(assistanceOptions: NarrativeBuilderOptionValue[], assistiveDeviceOptions: NarrativeBuilderOptionValue[], homeOptions: NarrativeBuilderOptionValue[]): Dictionary<NarrativeBuilderOption> {
        const nbOptionsDict: Dictionary<NarrativeBuilderOption> = {
            [PlofTemplateProperties.GAIT]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.GAIT),
                text: 'Gait',
                values: assistanceOptions,
                selectedValue: null,
                property: PlofTemplateProperties.GAIT,
            },
            [PlofTemplateProperties.GAIT_DISTANCE]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.GAIT_DISTANCE),
                text: 'Gait distance',
                selectedValue: null,
                property: PlofTemplateProperties.GAIT_DISTANCE,
            },
            [PlofTemplateProperties.BED_MOBILITIY]: {
                id: 'PLOFBedMobOptions',
                text: 'Bed Mobility',
                values: assistanceOptions,
                selectedValue: null,
                property: PlofTemplateProperties.BED_MOBILITIY,
            },
            [PlofTemplateProperties.AD]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.AD),
                text: 'Assistive Device',
                values: assistiveDeviceOptions,
                selectedValue: null,
                property: PlofTemplateProperties.AD,
            },
            [PlofTemplateProperties.TRANSFERS]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.TRANSFERS),
                text: 'Transfers',
                values: assistanceOptions,
                selectedValue: null,
                property: PlofTemplateProperties.TRANSFERS,
            },
            [PlofTemplateProperties.HOME_OPTIONS]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.HOME_OPTIONS),
                text: 'Home Options',
                values: homeOptions,
                selectedValue: null,
                property: PlofTemplateProperties.HOME_OPTIONS,
            },
        };

        return nbOptionsDict;
    }

    private getPlofOtNarrativeBuilderList(assistanceOptions: NarrativeBuilderOptionValue[], adaptiveEquipmentOptions: NarrativeBuilderOptionValue[], homeOptions: NarrativeBuilderOptionValue[]): Dictionary<NarrativeBuilderOption> {
        const nbOptionsDict: Dictionary<NarrativeBuilderOption> = {
            [PlofTemplateProperties.UB_DRESSING]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.UB_DRESSING),
                text: 'Upper body dressing',
                values: assistanceOptions,
                selectedValue: null,
                property: PlofTemplateProperties.UB_DRESSING,
            },
            [PlofTemplateProperties.LB_DRESSING]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.LB_DRESSING),
                text: 'Lower body dressing',
                values: assistanceOptions,
                selectedValue: null,
                property: PlofTemplateProperties.LB_DRESSING,
            },
            [PlofTemplateProperties.SELF_POS]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.SELF_POS),
                text: 'Self-positioning',
                values: assistanceOptions,
                selectedValue: null,
                property: PlofTemplateProperties.SELF_POS,
            },
            [PlofTemplateProperties.SELF_FEED]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.SELF_FEED),
                text: 'Self-feeding',
                values: assistanceOptions,
                selectedValue: null,
                property: PlofTemplateProperties.SELF_FEED,
            },
            [PlofTemplateProperties.TOILET]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.TOILET),
                text: 'Toileting',
                values: assistanceOptions,
                selectedValue: null,
                property: PlofTemplateProperties.TOILET,
            },
            [PlofTemplateProperties.BATH]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.BATH),
                text: 'Bathing',
                values: assistanceOptions,
                selectedValue: null,
                property: PlofTemplateProperties.BATH,
            },
            [PlofTemplateProperties.CLOSET_MGMT]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.CLOSET_MGMT),
                text: 'Closet Management',
                values: assistanceOptions,
                selectedValue: null,
                property: PlofTemplateProperties.CLOSET_MGMT,
            },
            [PlofTemplateProperties.ADAPT_EQUIP]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.ADAPT_EQUIP),
                text: 'Adaptive Equipment',
                values: adaptiveEquipmentOptions,
                selectedValue: null,
                property: PlofTemplateProperties.ADAPT_EQUIP,
            },
            [PlofTemplateProperties.IADLS]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.IADLS),
                text: 'iADLs',
                values: assistanceOptions,
                selectedValue: null,
                property: PlofTemplateProperties.IADLS,
            },
            [PlofTemplateProperties.HOME_OPTIONS]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.HOME_OPTIONS),
                text: 'Home Options',
                values: homeOptions,
                selectedValue: null,
                property: PlofTemplateProperties.HOME_OPTIONS,
            },
        };

        return nbOptionsDict;
    }

    private getPlofStNarrativeBuilderList(impairmentOptions: NarrativeBuilderOptionValue[], dietOptions: NarrativeBuilderOptionValue[], liquidOptions: NarrativeBuilderOptionValue[]): Dictionary<NarrativeBuilderOption> {
        const nbOptionsDict: Dictionary<NarrativeBuilderOption> = {
            [PlofTemplateProperties.SWALLOW]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.SWALLOW),
                text: 'Swallowing function',
                values: impairmentOptions,
                selectedValue: null,
                property: PlofTemplateProperties.SWALLOW,
            },
            [PlofTemplateProperties.SPEECH]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.SPEECH),
                text: 'Speech intelligibility',
                values: impairmentOptions,
                selectedValue: null,
                property: PlofTemplateProperties.SPEECH,
            },
            [PlofTemplateProperties.EXPRESSIVE]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.EXPRESSIVE),
                text: 'Expressive language skills',
                values: impairmentOptions,
                selectedValue: null,
                property: PlofTemplateProperties.EXPRESSIVE,
            },
            [PlofTemplateProperties.RECEPTIVE]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.RECEPTIVE),
                text: 'Receptive language skills',
                values: impairmentOptions,
                selectedValue: null,
                property: PlofTemplateProperties.RECEPTIVE,
            },
            [PlofTemplateProperties.COGNITIVE]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.COGNITIVE),
                text: 'Cognitive tasks',
                values: impairmentOptions,
                selectedValue: null,
                property: PlofTemplateProperties.COGNITIVE,
            },
            [PlofTemplateProperties.DIET]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.DIET),
                text: 'Diet',
                values: dietOptions,
                selectedValue: null,
                property: PlofTemplateProperties.DIET,
            },
            [PlofTemplateProperties.LIQUIDS]: {
                id: getNBOptionId(PLOF, PlofTemplateProperties.LIQUIDS),
                text: 'Liquids',
                values: liquidOptions,
                selectedValue: null,
                property: PlofTemplateProperties.LIQUIDS,
            },
        };

        return nbOptionsDict;
    }


    private getNarrativeBuiilderOptionValues(dataList: DataListItem[]): NarrativeBuilderOptionValue[] {
        return dataList.map(dli => {
            return {
                id: dli.value,
                text: dli.text,
                optionValues: [
                    {
                        id: dli.value,
                        text: dli.text
                    }
                ]
            }
        });
    }

    getPatientGoalsNarrativeBuilderList(): NarrativeBuilderList {
        return getPatientGoalsNarrativeBuilder();
    }

    getPrognosticIndicatorsNBL(): NarrativeBuilderList {
        return getPrognosticIndicatorsNarrativeBuilder();
    }

    getRecertDeficitsNBL(discipline: eDiscipline): NarrativeBuilderList {
        return getRecertDeficitsNarrativeBuilder(discipline);
    }

    getRecertInterventionsNBL(discipline: eDiscipline): NarrativeBuilderList {
        return getRecertInterventionsNarrativeBuilder(discipline);
    }

    getRecertPrecautionsNBL(): NarrativeBuilderList {
        return getRecertPrecautionsNarrativeBuilder();
    }

    getRehabPotentialNBL(): NarrativeBuilderList {
        return getRehabPotentialNarrativeBuilder();
    }
}
