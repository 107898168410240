import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TreatmentItem } from '@app/model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tms-caregiver-group-statement-edit.component',
  templateUrl: './caregiver-group-statement-edit.component.html'
})
export class PatientTreatmentCaregiverTrainingGroupStatementEdit implements OnInit{
    constructor(private activeModal: NgbActiveModal,
        private cdr: ChangeDetectorRef
      ) { }

    ngOnInit(): void {
        this.title = this.treatmentItem.chargeCode + " " + this.treatmentItem.chargeCodeObj.description;
    }
    
    @Input() txtAreaOfEducation: string;
    @Input() txtTrainingCompleted: string;
    @Input() txtPatientCount: string;
    @Input() txtCaregivers: string;
    @Input() txtDate: string;
    @Input() txtRepresentative: string;
    @Input() treatmentItem: TreatmentItem;

    title: string = "";

    onClearPressed(){
        this.txtAreaOfEducation = null;
        this.txtTrainingCompleted = null;
        this.txtPatientCount = null;
        this.txtCaregivers = null;
        this.txtDate = null;
        this.txtRepresentative = null;
        this.cdr.detectChanges();
    }

    onClose() {
        this.activeModal.dismiss();
    }

    onSubmitPressed(){
        if(this.txtAreaOfEducation && this.txtTrainingCompleted && this.txtPatientCount && this.txtCaregivers && this.txtDate && this.txtRepresentative){
            let array = [this.txtAreaOfEducation, this.txtTrainingCompleted, this.txtPatientCount, this.txtCaregivers, this.txtDate, this.txtRepresentative];
            this.activeModal.close(array);
        }
    }
}