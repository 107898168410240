import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';

@Injectable({
  providedIn: 'root'
})
export class FacilityDashboardService {

    private _activeTabIndex: number = null;
    private _activeTabIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private httpClient: HttpClient, 
              private urlConstantsService: UrlConstantsService) { }

              
    get activeTabIndex(): number {
        if (this._activeTabIndex && this._activeTabIndex !== null) {
            return this._activeTabIndex;
        } else {
            return 0;
        }
    }

    set activeTabIndex(value: number) {
        this._activeTabIndex = value;
        this._activeTabIndex$.next(this._activeTabIndex);
    }

    get activeTabIndex$(): Observable<number> {
        return this._activeTabIndex$.asObservable();
    }
}
