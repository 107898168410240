import { take, map } from 'rxjs/operators';
import {
    eDiscipline,
    NarrativeBuilderOption,
    NarrativeBuilderOptionValue,
    NarrativeBuilderOptionValueText,
    NarrativeBuilderList,
    ReasonForReferralProperties,
} from '@app/model';

import { CacheManService } from '../cacheman.service';
import { DataListConstants, ReasonForReferralConstants } from '../../constants';
import * as idb from './id-builder-utils';

// Reason for Referral example
// NarrativeBuilderList => Reason for Referral
//    1. NarrativeBuilderOption => 'Referred following recent' (dropdown)
//       1.a. NarrativeBuilderOptionValue => 'Surgery' (option on dropdown)
//           1.a.1. NarrativeBuilderOptionValueText => 'Surgery' (possible narrative)
//           1.a.2. NarrativeBuilderOptionValueText => 'Hospital procedure' (possible narrative)
//       2.a. NarrativeBuilderOptionValue => 'Fall' (option on dropdown)
//           2.a.1. NarrativeBuilderOptionValueText => 'Fall' (possible narrative)
//           2.a.2. NarrativeBuilderOptionValueText => 'Bad fall' (possible narrative)
//    2. NarrativeBuilderOption => 'due to' (text box)
//       1.a. NarrativeBuilderOptionValueText => ''
//    3. NarrativeBuilderOption => 'Resulting in' (dropdown)
//       3.a. NarrativeBuilderOptionValue => 'Option1' (option on dropdown)
//           3.a.1. NarrativeBuilderOptionValueText => 'Value1' (possible narrative)
//           3.a.2. NarrativeBuilderOptionValueText => 'Value2' (possible narrative)
//       3.b. NarrativeBuilderOptionValue => 'Option2' (option on dropdown)
//           3.b.1. NarrativeBuilderOptionValueText => 'Value1' (possible narrative)
//           3.b.2. NarrativeBuilderOptionValueText => 'Value2' (possible narrative)
//    4. NarrativeBuilderOption => 'Transfer' (check box)
//       4.a. NarrativeBuilderOptionValue => '' (no text because check box)
//          4.a.1. NarrativeBuilderOptionValueText => 'transfer' (possible narrative)
//          4.a.2. NarrativeBuilderOptionValueText => 'movement' (possible narrative)
//    5. NarrativeBuilderOption => 'Gait' (check box)
//       5.a. NarrativeBuilderOptionValue => ''
//          5.a.1. NarrativeBuilderOptionValueText => 'gait'

const RFR: string = 'RFR';
export function getReasonForReferralNarrativeBuilder(cachemanService: CacheManService, discipline: number): NarrativeBuilderList {
    
    const RFRAgeBuilderOption: NarrativeBuilderOption = {
        id: ReasonForReferralConstants.AGE_OPTION,
        text: 'Age',
        values: [],
        selectedValue: null,
        property: ReasonForReferralProperties.AGE,
    };

    const RFRDueToBuilderOption: NarrativeBuilderOption = {
        id: ReasonForReferralConstants.AGE_OPTION,
        text: 'due to',
        values: [],
        selectedValue: null,
        property: ReasonForReferralProperties.DUE_TO,
    };

    const GENDER = 'Gender';
    const RFRGenderBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RFR, GENDER),
        text: 'Gender',
        values: [],
        selectedValue: null,
        property: ReasonForReferralProperties.GENDER,
    };
    cachemanService.getDataListItems$(DataListConstants.GENDER)
        .pipe(take(1), map(items => items.filter(item => item.value !== '0')))
        .subscribe(datalists => {
            datalists.forEach(datalist => {
                RFRGenderBuilderOption.values.push({
                    id: datalist.value, // special case. When initializing reason for referral, gender is value from datalist 
                    text: datalist.text,
                    optionValues: [
                        {
                            id: datalist.value,
                            text: datalist.text,
                            value: datalist.value,
                        }
                    ]
                })
            })
        });

    // Referred following recent dropdown
    const RECENT = 'Recent';
    const RFRRecentBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RFR, RECENT),
        text: 'Referred following recent',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, 'ChangeInStatus'),
                text: 'Change in status',
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, 'ChangeInStatus', 1),
                        text: 'change in status'
                    },
                ]
            },
            {
                id: idb.getNBOptionValueId(RFR, 'Fall'),
                text: 'Fall',
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, 'Fall', 1),
                        text: 'fall'
                    },
                ]
            },
            {
                id: idb.getNBOptionValueId(RFR, 'Hospitalization'),
                text: 'Hospitalization',
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, 'Hospitalization', 1),
                        text: 'hospitalization'
                    },
                ]
            },
            {
                id: idb.getNBOptionValueId(RFR, 'Illness'),
                text: 'Illness',
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, 'Illness', 1),
                        text: 'illness'
                    },
                ]
            }
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.REFERRED_FOLLOWING,
    };

    // Resulting In
    const RESULTIN = 'ResultIn';
    const RFRResultInBO: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RFR, RESULTIN),
        text: 'Resulting in',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, 'FunctionalDecline'),
                text: 'A functional decline in',
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, 'FunctionalDecline', 1),
                        text: 'a functional decline in',
                    }
                ],
            },
            {
                id: idb.getNBOptionValueId(RFR, 'potential'),
                text: 'A potential for improvement in',
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, 'potential', 1),
                        text: 'a potential for improvement in',
                    }
                ],
            },
            {
                id: idb.getNBOptionValueId(RFR, 'improvement'),
                text: 'An improvement in',
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, 'improvement', 1),
                        text: 'an improvement in',
                    }
                ],
            },
            {
                id: idb.getNBOptionValueId(RFR, 'maintenance'),
                text: 'A need for skilled maintenance',
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, 'maintenance', 1),
                        text: "a need for skilled maintenance.  Due to the patient's clinical condition and complexity of required services, a skilled therapist or assistant is needed to assist the patient in maintaining their current level of function or preventing further decline in",
                    }
                ],
            }
        ],
        property: ReasonForReferralProperties.RESULT_IN,
    };

    let disciplineOptions = {};
    switch (discipline) {
        case eDiscipline.PhysicalTherapy: {
            disciplineOptions = getPTOptions();
            break;
        }
        case eDiscipline.OccupationalTherapy: {
            disciplineOptions = getOTOptions();
            break;
        }
        case eDiscipline.SpeechTherapy: {
            disciplineOptions = getSTOptions();
            break;
        }
    }

    const RFRNarrativeBuilderList: NarrativeBuilderList = {
        options: {
            ...disciplineOptions,
            [ReasonForReferralConstants.AGE_OPTION]: RFRAgeBuilderOption,
            [ReasonForReferralConstants.DUE_TO_OPTION]: RFRDueToBuilderOption,
            [ReasonForReferralConstants.GENDER_OPTION]: RFRGenderBuilderOption,
            [ReasonForReferralConstants.RECENT_OPTION]: RFRRecentBuilderOption,
            [ReasonForReferralConstants.RESULTING_IN_OPTION]: RFRResultInBO,
        },
    };

    return RFRNarrativeBuilderList;
}

function getPTOptions() {
    const RFRTransferOV1: NarrativeBuilderOptionValueText = {
        id: 'RFRTransferFVO1',
        text: 'transfer',
    };
    const RFRTransferOVS: NarrativeBuilderOptionValue = {
        id: 'RFRTransferNFV',
        optionValues: [
            RFRTransferOV1,
        ],
    };
    const RFRTransferBO: NarrativeBuilderOption = {
        id: 'RFRTransferNF',
        text: 'Transfer',
        values: [
            RFRTransferOVS,
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.TRANSFER,
    };

    const RFRGaitOV1: NarrativeBuilderOptionValueText = {
        id: 'RFRGaitFVO1',
        text: 'gait',
    };
    const RFRGaitOVS: NarrativeBuilderOptionValue = {
        id: 'RFRGaitNFV',
        optionValues: [
            RFRGaitOV1,
        ],
    };
    const RFRGaitBO: NarrativeBuilderOption = {
        id: 'RFRGaitNF',
        text: 'Gait',
        values: [
            RFRGaitOVS,
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.GAIT,
    };

    const RFRBedMobOV1: NarrativeBuilderOptionValueText = {
        id: 'RFRBedModFVO1',
        text: 'bed mobility',
    };
    const RFRBedMobOVS: NarrativeBuilderOptionValue = {
        id: 'RFRBedMobNFV',
        optionValues: [
            RFRBedMobOV1,
        ],
    };
    const RFRBedMobBO: NarrativeBuilderOption = {
        id: 'RFRBedMobNF',
        text: 'Bed Mobility',
        values: [
            RFRBedMobOVS,
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.BED_MOB,
    };

    const ROM: string = 'ROM';
    const RFRROMOV1: NarrativeBuilderOptionValueText = {
        id: idb.getNBOptionValueTextId(RFR, ROM, 1),
        text: 'functional range of motion (ROM)',
    };
    const RFRROMOOVS: NarrativeBuilderOptionValue = {
        id: idb.getNBOptionValueId(RFR, ROM),
        optionValues: [
            RFRROMOV1,
        ],
    };
    const RFRROMBO: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RFR, ROM),
        text: 'Functional Range of Motion (ROM)',
        values: [
            RFRROMOOVS,
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.ROM,
    };

    const SAFETY: string = 'SAFETY';
    const RFRSAFETY: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RFR, SAFETY),
        text: 'Safety',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, SAFETY),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, SAFETY, 1),
                        text: 'safety',
                    },
                ]
            }
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.SAFETY,
    };

    const POSITION: string = 'POS';
    const RFRPOS: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RFR, POSITION),
        text: 'Positioning',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, POSITION),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, POSITION, 1),
                        text: 'positioning',
                    },
                ]
            }
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.POSITION,
    };

    const SKIN_HEALTH: string = 'SH';
    const RFRSkinHealth: NarrativeBuilderOption = {
        id: idb.getNBOptionId(RFR, SKIN_HEALTH),
        text: 'Skin Health',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, SKIN_HEALTH),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, SKIN_HEALTH, 1),
                        text: 'skin health',
                    },
                ]
            }
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.SKIN_HEALTH,
    };

    return {
        [ReasonForReferralConstants.TRANSFER_OPTION]: RFRTransferBO,
        [ReasonForReferralConstants.GAIT_OPTION]: RFRGaitBO,
        [ReasonForReferralConstants.BED_MOB_OPTION]: RFRBedMobBO,
        [ReasonForReferralConstants.ROM_OPTION]: RFRROMBO,
        [ReasonForReferralConstants.SAFETY_OPTION]: RFRSAFETY,
        [ReasonForReferralConstants.POSITION_OPTION]: RFRPOS,
        [ReasonForReferralConstants.SKIN_HEALTH_OPTION]: RFRSkinHealth,
    }
}

function getOTOptions() {
    const BATHING = 'BA';
    const bathing: NarrativeBuilderOption = {
        id: ReasonForReferralConstants.BATHING_OPTION,
        text: 'Bathing',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, BATHING),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, BATHING, 1),
                        text: 'bathing',
                    },
                ]
            },
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.BATHING,
    };

    const DRESSING = 'DRESS'; 
    const dressing: NarrativeBuilderOption = {
        id: ReasonForReferralConstants.DRESSING_OPTION,
        text: 'Dressing',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, DRESSING),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, DRESSING, 1),
                        text: 'dressing',
                    },
                ]
            },
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.DRESSING,
    };

    const FUNC_ADLS = 'FUNC_ADLS';
    const funcADLS: NarrativeBuilderOption = {
        id: ReasonForReferralConstants.FUNC_ADLS_OPTION,
        text: 'Functional ADLs',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, FUNC_ADLS),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, FUNC_ADLS, 1),
                        text: 'functional ADL performance',
                    },
                ]
            },
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.FUNC_ADLS,
    };

    const FUNC_MOB = 'FUNC_MOB';
    const funcMob: NarrativeBuilderOption = {
        id: ReasonForReferralConstants.FUNC_MOB_OPTION,
        text: 'Functional mobility',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, FUNC_MOB),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, FUNC_MOB, 1),
                        text: 'functional mobility',
                    },
                ]
            },
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.FUNC_MOB,
    };

    const FUNC_ROM = 'FUNC_ROM';
    const funcROM: NarrativeBuilderOption = {
        id: ReasonForReferralConstants.FUNC_ROM_OPTION,
        text: 'Functional ROM',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, FUNC_ROM),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, FUNC_ROM, 1),
                        text: 'functional range of motion',
                    },
                ]
            },
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.FUNC_ROM,
    };

    const GROOMING = 'GROOMING';
    const grooming: NarrativeBuilderOption = {
        id: ReasonForReferralConstants.GROOMING_OPTION,
        text: 'Grooming',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, GROOMING),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, GROOMING, 1),
                        text: 'grooming',
                    },
                ]
            },
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.GROOMING,
    };

    const HOME_MANAGE = 'HOME_MANAGE';
    const homeManage: NarrativeBuilderOption = {
        id: ReasonForReferralConstants.HOME_MANAGEMENT_OPTION,
        text: 'Home Management',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, HOME_MANAGE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, HOME_MANAGE, 1),
                        text: 'home management',
                    },
                ]
            },
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.HOME_MANAGEMENT,
    };

    const HOME_SAFETY = 'HOME_SAFETY';
    const homeSafety: NarrativeBuilderOption = {
        id: ReasonForReferralConstants.HOME_SAFELY_OPTION,
        text: 'Home Safety',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, HOME_SAFETY),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, HOME_SAFETY, 1),
                        text: 'home safety',
                    },
                ]
            },
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.HOME_SAFETY,
    };

    const POSITIONING = 'POSITIONING';
    const positioning: NarrativeBuilderOption = {
        id: ReasonForReferralConstants.POSITIONING_OPTION,
        text: 'Positioning',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, POSITIONING),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, POSITIONING, 1),
                        text: 'positioning',
                    },
                ]
            },
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.POSITIONING,
    };

    const SELF_FEED = 'SELF_FEED'
    const selfFeed: NarrativeBuilderOption = {
        id: ReasonForReferralConstants.SELF_FEED_OPTION,
        text: 'Self Feeding',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, SELF_FEED),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, SELF_FEED, 1),
                        text: 'self feeding',
                    },
                ]
            },
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.SELF_FEED,
    };

    return {
        [ReasonForReferralConstants.BATHING_OPTION]: bathing,
        [ReasonForReferralConstants.DRESSING_OPTION]: dressing,
        [ReasonForReferralConstants.FUNC_ADLS_OPTION]: funcADLS,
        [ReasonForReferralConstants.FUNC_MOB_OPTION]: funcMob,
        [ReasonForReferralConstants.FUNC_ROM_OPTION]: funcROM,
        [ReasonForReferralConstants.GROOMING_OPTION]: grooming,
        [ReasonForReferralConstants.HOME_MANAGEMENT_OPTION]: homeManage,
        [ReasonForReferralConstants.HOME_SAFELY_OPTION]: homeSafety,
        [ReasonForReferralConstants.POSITIONING_OPTION]: positioning,
        [ReasonForReferralConstants.SELF_FEED_OPTION]: selfFeed,
    }
}

function getSTOptions() {
    const cognition: NarrativeBuilderOption = {
        id: ReasonForReferralConstants.COGNITION_OPTION,
        text: 'Cognition',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, ReasonForReferralConstants.COGNITION_OPTION),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, ReasonForReferralConstants.COGNITION_OPTION, 1),
                        text: 'cognitive skills',
                    },
                ]
            },
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.COGNITION,
    }

    const dietTolerance: NarrativeBuilderOption = {
        id: ReasonForReferralConstants.DIET_TOLERANCE_OPTION,
        text: 'Diet Tolerance',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, ReasonForReferralConstants.DIET_TOLERANCE_OPTION),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, ReasonForReferralConstants.DIET_TOLERANCE_OPTION, 1),
                        text: 'diet tolerance',
                    },
                ]
            },
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.DIET_TOLERANCE,
    }

    const communication: NarrativeBuilderOption = {
        id: ReasonForReferralConstants.COMMUNICATION_OPTION,
        text: 'Communication',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, ReasonForReferralConstants.COMMUNICATION_OPTION),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, ReasonForReferralConstants.COMMUNICATION_OPTION, 1),
                        text: 'expressive language',
                    },
                ]
            },
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.COMMUNICATION,
    };

    const omFnx: NarrativeBuilderOption = {
        id: ReasonForReferralConstants.OM_FNX_OPTION,
        text: 'OM Fnx',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, ReasonForReferralConstants.OM_FNX_OPTION),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, ReasonForReferralConstants.OM_FNX_OPTION, 1),
                        text: 'oral motor function',
                    },
                ]
            },
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.OM_FNX,
    };

    const comprehension: NarrativeBuilderOption = {
        id: ReasonForReferralConstants.COMPREHENSION_OPTION,
        text: 'Comprehension',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, ReasonForReferralConstants.COMPREHENSION_OPTION),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, ReasonForReferralConstants.COMPREHENSION_OPTION, 1),
                        text: 'receptive language',
                    },
                ]
            },
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.COMPREHENSION,
    };

    const safetyAware: NarrativeBuilderOption = {
        id: ReasonForReferralConstants.SAFETY_AWARE_OPTION,
        text: 'Safety Awareness',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, ReasonForReferralConstants.SAFETY_AWARE_OPTION),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, ReasonForReferralConstants.SAFETY_AWARE_OPTION, 1),
                        text: 'safety awareness',
                    },
                ]
            },
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.SAFETY_AWARE,
    };

    const speechIntell: NarrativeBuilderOption = {
        id: ReasonForReferralConstants.SPEECH_INTELL_OPTION,
        text: 'Speech Intelligibility',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, ReasonForReferralConstants.SPEECH_INTELL_OPTION),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, ReasonForReferralConstants.SPEECH_INTELL_OPTION, 1),
                        text: 'speech intelligibility',
                    },
                ]
            },
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.SPEECH_INTELL,
    };

    const swallowFnx: NarrativeBuilderOption = {
        id: ReasonForReferralConstants.SWALLOW_FNX_OPTION,
        text: 'Swallow Fnx',
        values: [
            {
                id: idb.getNBOptionValueId(RFR, ReasonForReferralConstants.SWALLOW_FNX_OPTION),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(RFR, ReasonForReferralConstants.SWALLOW_FNX_OPTION, 1),
                        text: 'swallow function',
                    },
                ]
            },
        ],
        selectedValue: null,
        property: ReasonForReferralProperties.SWALLOW_FNX,
    };

    return {
        [ReasonForReferralConstants.COGNITION_OPTION]: cognition,
        [ReasonForReferralConstants.DIET_TOLERANCE_OPTION]: dietTolerance,
        [ReasonForReferralConstants.COMMUNICATION_OPTION]: communication,
        [ReasonForReferralConstants.OM_FNX_OPTION]: omFnx,
        [ReasonForReferralConstants.COMPREHENSION_OPTION]: comprehension,
        [ReasonForReferralConstants.SAFETY_AWARE_OPTION]: safetyAware,
        [ReasonForReferralConstants.SPEECH_INTELL_OPTION]: speechIntell,
        [ReasonForReferralConstants.SWALLOW_FNX_OPTION]: swallowFnx,
    }
}