import { Injectable } from '@angular/core';


const ASSETS_DATA = 'assets/data/';
const APP_SETTINGS_PHP = 'appSettings.php';
const APP_SETTINGS_JSON = 'appSettings.json';

@Injectable()
export class AppConfigService {
    private _appConfig = null;

    constructor() { }

    loadAppConfig() {
        return new Promise((resolve, reject) => {
            this.getRequest(`${ASSETS_DATA}${APP_SETTINGS_PHP}`)
                .then(
                    (phpAppSettings: any) => {
                        this.getRequest(`${ASSETS_DATA}${APP_SETTINGS_JSON}`)
                            .then(
                                (jsonAppSettings: any) => {
                                    console.log('Merging settings from server and appSettings.json')
                                    resolve({
                                        ...jsonAppSettings,
                                        ...phpAppSettings,
                                    });
                                }, (rejected) => {
                                    console.log(rejected);
                                    reject();
                                });
                    },
                    (rejected) => {
                        console.log('Response from PHP was: ' + rejected);
                        console.log('App will use default settings from appSettings.json');
                        this.getRequest(`${ASSETS_DATA}${APP_SETTINGS_JSON}`)
                            .then(
                                (jsonAppSettings) => resolve(jsonAppSettings),
                                (rejected) => {
                                    console.log(rejected);
                                    reject();
                                }
                            )
                    })
        });
    }

    private getRequest(url: string) {
        return new Promise((resolve, reject) => {
            const request = new XMLHttpRequest();
            request.onreadystatechange = function () {
                if (this.readyState == 4) {
                    if (this.status == 200) {
                        if (this.response == null) {
                            reject();
                        } else {
                            resolve(this.response);
                        }
                    } else {
                        reject(this.status + ': ' + this.statusText);
                    }
                }
            }
            request.responseType = 'json';
            request.open('GET', url, true);
            request.send();
        });
    }

    get appConfig() {
        return this._appConfig;
    }

    set appConfig(value) {
        this._appConfig = value;
    }
}
