import { Component } from '@angular/core';

@Component({
  selector: 'tms-question-container',
  styleUrls: [],
  template: `
    <div>
      <ng-content></ng-content>
    </div>
  `,
})
export class InterviewQuestionContainerComponent {
}
