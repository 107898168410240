import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { PagedResult, OperationResponse, Intermediary, Invoice, InvoiceAdjustment, InvoiceAdjustmentInfo, AssignedBiller, InvoiceModifiedCharge } from '@app/model';
import { Observable } from 'rxjs';
import { noLoadingSpinner } from '../interceptors/http-context-tokens';
import { ErrorMessageUtils } from '@app/utils/error-message.utils';
import { ToastMessageService } from './toast-message.service';

@Injectable({
	providedIn: 'root',
})
export class InvoicesService {
    
    constructor(private http: HttpClient, private urlConstantsService: UrlConstantsService, private toastMessageService: ToastMessageService) {}

    getAll(status: number, month: number, year: number) {
        return this.http.get<PagedResult<Invoice>>(`${this.urlConstantsService.INVOICES_URL}?status=${status}&month=${month}&year=${year}`)
            .pipe(map(pagedResult => pagedResult.items));
    }

    getById(id: string) {
        return this.http.get<OperationResponse<Invoice>>(`${this.urlConstantsService.INVOICES_URL}/${id}`)
        .pipe(map(response => response.data));
    }

    regenerateInvoice(id: string) {
        return this.http.put<OperationResponse<string>>(`${this.urlConstantsService.INVOICES_URL}/${id}/regenerate`, id, { context: noLoadingSpinner() })
        .pipe(tap((_response) => {
            this.toastMessageService.successNotification('The invoice has been successfully refreshed.');
        },
            (error) => {
                this.toastMessageService.errorNotification(ErrorMessageUtils.getDisplayErrorMessage(error, 'The invoice refresh has failed.'))
            }));
    }

    closeInvoice(invoice: Invoice) {
        return this.http.put<OperationResponse<Invoice>>(`${this.urlConstantsService.INVOICES_URL}/${invoice.id}/close`, invoice);
    }

    closeInvoices(invoiceIds: string[]) {
        return this.http.put<OperationResponse<void>>(`${this.urlConstantsService.INVOICES_URL}/close`, invoiceIds);
    }

    deleteInvoice(id: string) {
        return this.http.delete<Invoice>(`${this.urlConstantsService.INVOICES_URL}/${id}`);
    }

    // Adjustments
    getPriorAdjustments(invoiceId: string, month: number, year: number) {
        return this.http.get<PagedResult<InvoiceAdjustment>>(`${this.urlConstantsService.INVOICES_URL}/${invoiceId}/priorAdjustments?month=${month}&year=${year}`)
            .pipe(map(pagedResult => pagedResult.items));
    }

    createAdjustment(id: string, adjustment: InvoiceAdjustment) {
        return this.http.post<OperationResponse<InvoiceAdjustment>>(`${this.urlConstantsService.INVOICES_URL}/${id}/adjustment`, adjustment)
        .pipe(map(response => response.data));
    }

    updateAdjustment(id: string, adjustment: InvoiceAdjustment) {
        return this.http.put<OperationResponse<InvoiceAdjustment>>(`${this.urlConstantsService.INVOICES_URL}/${id}/adjustment`, adjustment)
        .pipe(map(response => response.data));
    }

    deleteAdjustment(id: string, adjustmentId: string) {
        return this.http.delete<InvoiceAdjustment>(`${this.urlConstantsService.INVOICES_URL}/${id}/adjustment/${adjustmentId}`);
    }

    // Modified Charges/Reconciler 
    getModifiedCharges(month: number, year: number, facilityId: string, homeHealthAgencyId: string, userId: string): Observable<InvoiceModifiedCharge[]> {
        let queryString = facilityId ? `&facilityId=${facilityId}` : '';
        queryString += userId ? `&userId=${userId}` : '';
        return this.http.get<PagedResult<InvoiceModifiedCharge>>(`${this.urlConstantsService.INVOICES_URL}/modifiedCharges?month=${month}&year=${year}${queryString}`)
                        .pipe(map(pagedResult => pagedResult.items));
    }

    approveAdjustments(modifiedCharges: InvoiceModifiedCharge[]): Observable<void> {
        return this.http.put<OperationResponse<void>>(`${this.urlConstantsService.INVOICES_URL}/approveAdjustments`, modifiedCharges)
            .pipe(map(response => response.data));
    }

    rejectAdjustments(modifiedCharges: InvoiceModifiedCharge[]): Observable<void> {
        return this.http.put<OperationResponse<void>>(`${this.urlConstantsService.INVOICES_URL}/rejectAdjustments`, modifiedCharges)
            .pipe(map(response => response.data));
    }

    pendAdjustments(modifiedCharges: InvoiceModifiedCharge[]): Observable<void> {
        return this.http.put<OperationResponse<void>>(`${this.urlConstantsService.INVOICES_URL}/pendAdjustments`, modifiedCharges)
            .pipe(map(response => response.data));
    }

    // Assigned Biller
    getAssignedBillers() {
        return this.http.get<PagedResult<AssignedBiller>>(`${this.urlConstantsService.INVOICES_URL}/assignedBillers`)
            .pipe(map(pagedResult => pagedResult.items));
    }

    unassignBiller(biller: AssignedBiller) {
        return this.http.put<OperationResponse<AssignedBiller>>(`${this.urlConstantsService.INVOICES_URL}/unassignBiller`, biller)
        .pipe(map(response => response.data));
    }
}
