import { eDesignation, ePatientClinicalDocumentType, Payer, PayerClassification } from "@app/model";
import { DesignationUtils } from "./designation.utils";

export class AttestationUtils {
    static EVALUTION_THERAPIST: string = 'I attest that based on my experience and training and considering the patient’s current functional status, the anticipated level of intervention is medically necessary and consistent with accepted standards of clinical practice required to achieve the documented therapeutic goals.';

    static PROGRESS_NOTE_THERAPIST: string = 'I have reviewed all objective findings and agree to the accuracy of this information. I actively participated in the assessment of this patient and verify the appropriate development of the ongoing Plan of Care based on compliance with the Medicare Benefit Policy Manual guidelines.';
    static PROGRESS_NOTE_THERAPIST_PAYER_B: string = 'I have reviewed and agree to all objective findings. I authored and completed the assessment of the patient and ongoing plan of care development in compliance with the Medicare Benefit Policy Manual';
    static PROGRESS_NOTE_ASSISTANT: string = 'I verify that my assistance with the data collection and objective findings recorded within this document are compliant with the Medicare Benefit Policy Manual guidelines.';

    static RECERT_THERAPIST: string = 'I attest that based on my experience and training and considering the patient’s current functional status, the anticipated level of intervention is medically necessary and consistent with accepted standards of clinical practice required to achieve the documented therapeutic goals.';

    static DISCHARGE_THERAPIST: string = 'I verify that the services provided prior to discharge continued to require the skills of a therapist, and services were provided and/or supervised by a clinician. I have reviewed all of the treatment rendered prior to discharge and agree to the patient’s discharge status.';
    static DISCHARGE_ASSISTANT: string = 'I verify that my assistance with the data collection and objective findings recorded within this document are compliant with the Medicare Benefit Policy Manual guidelines.';

    static getAttestationStatement(designation: eDesignation, documentType: ePatientClinicalDocumentType, payer: Payer): string {
        const isTherapist = DesignationUtils.isTherapist(designation);

        switch (documentType) {
            case ePatientClinicalDocumentType.EVALUATION:
                return this.EVALUTION_THERAPIST;

            case ePatientClinicalDocumentType.PROGRESS_NOTE:
                if (isTherapist) {
                    if (payer.classification == PayerClassification.B && payer.ruleSet.isMedicareGuidelinesFollowed) {
                        return this.PROGRESS_NOTE_THERAPIST_PAYER_B;
                    } else {
                        return this.PROGRESS_NOTE_THERAPIST;
                    }
                } else {
                    return this.PROGRESS_NOTE_ASSISTANT;
                }

            case ePatientClinicalDocumentType.RECERT:
                return this.RECERT_THERAPIST;

            case ePatientClinicalDocumentType.DISCHARGE_SUMMARY:
                if (isTherapist) {
                    return this.DISCHARGE_THERAPIST;
                } else {
                    return this.DISCHARGE_ASSISTANT;
                }

            default:
                return '';
        }
    }
}