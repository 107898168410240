import { Component, EventEmitter, Output, Input, OnInit, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DataListItem, InterviewQuestion, InterviewAnswerField } from '@app/model';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'tms-interview-question-native-drop-down',
  templateUrl: './interview-question-native-drop-down.component.html',
  styleUrls: ['./interview-question-native-drop-down.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InterviewQuestionNativeDropDownComponent implements OnInit {
  @Input() question: InterviewQuestion = null;
  @Input() dataListItems: DataListItem[] = [];
  @Input() interviewAnswerField: InterviewAnswerField = null;
  @Input() showClearButton: boolean = false;
  @Input() disabled: boolean = true;
  @Output() onInterviewValueChanged: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;

  selectedValue: DataListItem = null;
  nativeddlcontrol = new UntypedFormControl();

  private allOptions$: BehaviorSubject<DataListItem[]> = new BehaviorSubject<DataListItem[]>(null);

  get filteredOptions(): Observable<DataListItem[]> {
    return this.allOptions$.asObservable();
  }

  constructor() { }

  ngOnInit() {
    this.selectedValue = this.dataListItems.find(ddl => ddl.value === this.interviewAnswerField?.value);
    this.nativeddlcontrol.setValue(this.selectedValue);
    this.allOptions$.next(this.dataListItems);

    this.nativeddlcontrol.valueChanges
      .pipe(startWith(''))
      .subscribe(value => {
      const filterValue = typeof value === 'string' ? value : value?.text;
      if (filterValue) {
         this.allOptions$.next(this.filter(filterValue));
      } else {
        this.allOptions$.next(this.dataListItems);
      }
    });
  }

  private filter(value: string): DataListItem[] {
    const filterValue = value.toLowerCase();
    return this.dataListItems.filter(option => option.text.toLowerCase().includes(filterValue));
  }

  displayFn(ddi: DataListItem): string {
    return ddi ? ddi.text : '';
  }

  onClearValue() {
    this.selectedValue = null;
    this.nativeddlcontrol.setValue(null);
    this.onInterviewValueChanged.emit(null);
  }

  onSelectedValue($event) {
    if ($event.option) {
      this.selectedValue = $event.option.value;
      this.onInterviewValueChanged.emit($event.option.value.value);
    }
  }

  onInputClicked() {
    if (!this.trigger.panelOpen) {
      this.trigger.openPanel();
    }
  }

  onInputKeyUp = (e: KeyboardEvent) => {
    if ((e.key != 'Enter') && (e.key != 'Tab')) {
      return;
    }

    const inputValue = this.nativeddlcontrol.value;

    if (inputValue == null) {
      return;
    }

    const filterValue = typeof inputValue === 'string' ? inputValue : inputValue?.text;
    
    // check if value is valid
    const selectedItem = this.dataListItems.find(ddi => ddi.text.toLowerCase() == filterValue.toLowerCase());

    if (selectedItem) {
      this.nativeddlcontrol.setValue(selectedItem);
      this.selectedValue = selectedItem;
      this.onInterviewValueChanged.emit(selectedItem.value);
      this.trigger.closePanel();
    } else {
      this.nativeddlcontrol.setErrors({ 'no-match-found': true });
    }
  }

}