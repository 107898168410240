import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { DxDataGridComponent } from 'devextreme-angular';
import { CacheManService, DataListConstants, SupportService, ExternalLinks } from '@app/core';
import { DataListItem, eSupportIssueStatus, SupportIssue } from '@app/model';
import { SupportTicketWizardComponent } from './support-ticket-wizard/support-ticket-wizard.component';
import { SupportTicketDetailsComponent } from './support-ticket-details/support-ticket-details.component';
import { modalOptions, modalLargeOption, SortUtils } from '@app/utils';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'tms-support',
  templateUrl: './support.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupportComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  supportIssueDepartments: DataListItem[] = [];
  supportIssueStatus: DataListItem[] = [];
  supportTicketFilter: string[] = ['Open', 'Closed', 'All'];
  supportIssues: SupportIssue[] = [];

  constructor(private cacheManService: CacheManService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private supportService: SupportService) { }

  ngOnInit() {
    combineLatest([this.cacheManService.getDataListItems$(DataListConstants.SUPPORT_ISSUE_DEPARTMENT),
      this.cacheManService.getDataListItems$(DataListConstants.SUPPORT_ISSUE_STATUS)])
      .pipe(take(1))
      .subscribe(([departments, statuses]) => {
        this.supportIssueDepartments = departments;
        this.supportIssueStatus = statuses;
        this.cdr.detectChanges();
      });
    this.getSupportTickets();
    this.showOpenSupportTickets();
  }

  onAddNewTicket() {
    const modalRef = this.modalService.open(SupportTicketWizardComponent, { ...modalOptions, ...modalLargeOption });
    modalRef.result.then(() => this.getSupportTickets());
  }

  onSupportIssueRowPrepared = (e) => {
    if (e.rowType != 'header' && this.isRowUnread(e.data)) {
      e.rowElement.style['font-weight'] = 'bold';
    }
  }

  onSupportTicketFilterChanged(e) {
    if (e.value == 'All') {
      this.showAllSupportTickets();
    } else if (e.value == 'Open') {
      this.showOpenSupportTickets();
    } else if (e.value == 'Closed') {
      this.showClosedSupportTickets();
    }
  }

  onTicketDetailsClicked(e) {
    this.supportService.updateSupportIssueReadMessageStatus(e.id).subscribe(() => {});
    const modalRef = this.modalService.open(SupportTicketDetailsComponent, { ...modalOptions, ...modalLargeOption});
    modalRef.componentInstance.id = e.id;
    modalRef.result.then(() => this.getSupportTickets(), () => this.getSupportTickets());
  }

  calculateSupportIssueStatusValue = (rowData: SupportIssue) => {
    if (!!rowData.pendingMessage) {
      return 'Pending Message';
    } else {
      return this.supportIssueStatus.find(status => status.valueInt === rowData.status)?.text;
    }
  }

  private isRowUnread(rowData: SupportIssue) {
    return !!rowData.pendingMessage;
  }

  private showAllSupportTickets() {
    this.dataGrid.instance.clearFilter();
  }

  private showOpenSupportTickets(){
    this.dataGrid.instance.filter(['status', '<', eSupportIssueStatus.Closed])
  }

  private showClosedSupportTickets() {
    this.dataGrid.instance.filter(['status', '=', eSupportIssueStatus.Closed])
  }

  private getSupportTickets() {
    this.supportService.getAllSupportTickets()
    .subscribe(response => {
      this.supportIssues = SortUtils.orderByDateDesc(response.issues, 'modified');
      this.cdr.detectChanges();
    });
  }
}