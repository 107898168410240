import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientsService } from './clients.service';
import { PayersService } from './payers.service';
import { UrlConstantsService } from './url-constants.service';
import { Client, Payer, InternalAlertCriteriaUi, eFilterOperator, 
  InternalAlert, OperationResponse, PagedResult } from '@app/model';


@Injectable({
  providedIn: 'root'
})
export class InternalAlertsService {

  constructor(private httpClient: HttpClient,
    private payersService: PayersService,
    private urlConstantsService: UrlConstantsService) { }

  getInternalAlerts(): Observable<InternalAlert[]> {
    return this.httpClient.get<PagedResult<InternalAlert>>(`${this.urlConstantsService.INTERNAL_ALERTS_URL}`)
      .pipe(map(response => response.items));
  }

  addInternalAlert(internalAlert: InternalAlert): Observable<InternalAlert> {
    return this.httpClient.post<OperationResponse<InternalAlert>>(`${this.urlConstantsService.INTERNAL_ALERTS_URL}`, internalAlert)
      .pipe(map(response => response.data));

  }

  editInteralAlert(internalAlertId: string, internalAlert: InternalAlert): Observable<InternalAlert> {
    return this.httpClient.put<OperationResponse<InternalAlert>>(`${this.urlConstantsService.INTERNAL_ALERTS_URL}/${internalAlertId}`, internalAlert)
      .pipe(map(response => response.data));
  }

  getPayerIdsForClient(client: Client): Observable<Payer[]> {
    return this.payersService.getAll()
      .pipe(map(payers => {
        const payerIds = client.contracts.map(contract => contract.payerDetails.payerId);
        return payers.filter(payer => payerIds.findIndex(payerId => payerId == payer.id) >= 0);
      }));
  }

  getInternalAlertCriterias(): InternalAlertCriteriaUi[] {
    return [
      {
        internalAlertConditionShortName: 'dollarsPerEpisode',
        filterOperator: eFilterOperator.GreaterThan,
        name: '$ per episode',
        isSelected: false,
        valueOnRight: true,
      },
      {
        internalAlertConditionShortName: 'dollarsPerDay',
        filterOperator: eFilterOperator.GreaterThan,
        name: '$ per day',
        isSelected: false,
        valueOnRight: true,
      },
      {
        internalAlertConditionShortName: 'dollarsPerDiscPerDay',
        filterOperator: eFilterOperator.GreaterThan,
        name: '$ per discipline per day',
        isSelected: false,
        valueOnRight: true,
      },
      {
        internalAlertConditionShortName: 'unitsPerEpisode',
        filterOperator: eFilterOperator.GreaterThan,
        name: 'units per episode',
        isSelected: false,
        valueOnRight: false,
      },
      {
        internalAlertConditionShortName: 'unitsPerDay',
        filterOperator: eFilterOperator.GreaterThan,
        name: 'units per day',
        isSelected: false,
        valueOnRight: false,
      },
      {
        internalAlertConditionShortName: 'minutesPerEpisode',
        filterOperator: eFilterOperator.GreaterThan,
        name: 'minutes per episode',
        isSelected: false,
        valueOnRight: false,
      },
      {
        internalAlertConditionShortName: 'minutesPerDisc',
        filterOperator: eFilterOperator.GreaterThan,
        name: 'minutes per discipline',
        isSelected: false,
        valueOnRight: false,
      },
      {
        internalAlertConditionShortName: 'minutesPerDay',
        filterOperator: eFilterOperator.GreaterThan,
        name: 'minutes per day',
        isSelected: false,
        valueOnRight: false,
      },
      {
        internalAlertConditionShortName: 'totalDayPerEpisode',
        filterOperator: eFilterOperator.GreaterThan,
        name: 'total days per episode',
        isSelected: false,
        valueOnRight: false,
      },
    ]
  }
}
