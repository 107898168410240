import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';

@Component({
    selector: 'tms-toast',
    template: `<dx-toast
        #toast
        type="custom"
        [(visible)]="isVisible"
        [closeOnClick]="closeOnClick"
        [contentTemplate]="contentTemplate">
        <div *dxTemplate="let data of 'custom'">
            <div class="dx-toast-custom">
            <span class="{{ iconName }} dx-toast-icon dx-toast-custom-icon"></span>
            <span class="dx-toast-message" [innerHTML]="message"></span>
            </div>
        </div>
    </dx-toast>`,
})
export class TmsToastComponent implements OnInit {
    @Input() message: string;
    @Input() icon: ToastIcon = ToastIcon.Comment;
    @Input() closeOnClick: boolean;

    isVisible: boolean = false;
    contentTemplate: string = 'custom';
    displayTime: number;
    iconName: string;

    ngOnInit() {
        if (this.icon === ToastIcon.Comment) {
            this.iconName = 'dx-icon-comment';
        } else if (this.icon === ToastIcon.Message) {
            this.iconName = 'dx-icon-message';
        }
    }

    setDisplayTime() {
        this.displayTime = this.closeOnClick ? 1800 : 600;
    }

    successNotification() {
        this.setDisplayTime();
        notify(this.message, 'success', this.displayTime);
    }

    errorNotification() {
        this.setDisplayTime();
        notify(this.message, 'error', this.displayTime);
    }

    alertNotification() {
        this.setDisplayTime();
        notify(this.message, 'info', this.displayTime);
    }

    customNotification() {
        this.setDisplayTime();
        this.isVisible = true;
        notify(this.message, 'custom', this.displayTime);
    }
}

export enum ToastIcon {
    Comment = 1,
    Message = 2,
}

