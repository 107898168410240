import { MatDialogConfig } from '@angular/material/dialog';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export const modalOptions: NgbModalOptions = {
    backdrop: 'static',
    centered: true,
    keyboard: false,
}

export const modalFullScreenOption: NgbModalOptions = {
    windowClass: 'modal-full-screen',
}

export const modalLargeOption: NgbModalOptions = {
    size: 'lg',
}

export const modalSmallOption: NgbModalOptions = {
    size: 'sm',
}

export const modalXLargeOption: NgbModalOptions = {
    size: 'xl',
}

export const modalHighZIndex: NgbModalOptions = {
    windowClass: 'modal-z-index',
}

export const modalOptionsMaterial: MatDialogConfig = {
    hasBackdrop: true,
    disableClose: true,
    panelClass: ['modal', 'mat-dialog'],
}

export const modalMediumOptionMaterial: MatDialogConfig = {
    minWidth: '40vw',
    maxWidth: '50vw',
 }

export const modalLargeOptionMaterial: MatDialogConfig = {
   minWidth: '60vw',
   maxWidth: '70vw',
   maxHeight: '70vh',
}

export const modalXLargeOptionMaterial: MatDialogConfig = {
    width: '90vw',
    height: '90vh',
 }