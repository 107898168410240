import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ImpersonateService, ToastMessageService, UsersService } from '@app/core';
import { Observable } from 'rxjs';
import { UserSlim } from '@app/model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tms-user-impersonate',
  templateUrl: './user-impersonate.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserImpersonateComponent implements OnInit {
  isImpersonating$: Observable<boolean> = null;
  users: UserSlim[] = [];
  
  private selectedUser: UserSlim = null;

  constructor(private impersonateService: ImpersonateService,
    private toastMessageService: ToastMessageService,
    private usersService: UsersService) {}

  ngOnInit(): void {
    this.isImpersonating$ = this.impersonateService.isImpersonatingMode$.pipe(map(impersonate => impersonate !== null));
  }

  getEmployeeDisplayExpr(item: UserSlim) {
    if (item) {
      return `${item.name} (${item.ecode})`;
    }
  }

  onTypeAheadValueChanged = (e) => {
    return this.usersService.allUsersSearch(e);
  }

  onUserValueChanged(e) {
    this.selectedUser = e;
  }

  onImpersonateClicked() {
    if (this.selectedUser) {
      this.impersonateService.setImpersonatingMode(this.selectedUser)
        .subscribe(() => {}, error => this.toastMessageService.errorNotification(error));
    }
  }

}
