import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { CacheManService, DataListConstants } from '@app/core';
import { InterviewQuestion, DataListItem, InterviewAnswerField } from '@app/model';

@Component({
  selector: 'tms-question-levelofcare',
  styleUrls: [],
  template: `
    <tms-interview-question-drop-down-list
        [question]="question"
        [dataListItems]="levelOfCareData"
        [interviewAnswerField]="interviewAnswerField"
        [showClearButton]="true"
        [disabled]="disabled"
        (onInterviewValueChanged)="onSelectedValue($event)">
    </tms-interview-question-drop-down-list>
  `,
})
export class InterviewQuestionLevelOfCareComponent implements OnInit {
    @Input() disabled: boolean = false;
    @Input() question: InterviewQuestion;
    @Input() interviewAnswerField: InterviewAnswerField;
    @Output() onInterviewValueChanged: EventEmitter<string> = new EventEmitter<string>();

    levelOfCareData: DataListItem[] = [];
    constructor(private cacheManService: CacheManService) {
    }

    ngOnInit() {
      this.cacheManService.getDataListItems$(DataListConstants.LEVEL_OF_CARE)
        .pipe(take(1))
        .subscribe(dataListItems => {
          if (this.question.customAnswerOptions) {
            this.levelOfCareData = [...dataListItems, ...this.question.customAnswerOptions];
          } else {
            this.levelOfCareData = dataListItems;
          }
        })
    }

    onSelectedValue($event) {
      this.onInterviewValueChanged.emit($event);
    }
}
