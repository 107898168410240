import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'tms-label',
  templateUrl: './tms-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TmsLabelComponent implements OnInit {
  @Input() isRequired: boolean = false;
  @Input() text: string;
  constructor() { }

  ngOnInit() {
  }

}
