import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Startup } from '@app/model';

export interface StartupsState extends EntityState<Startup>, ActiveState {}

const initialState: StartupsState = {
  active: null,
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'startups' })
export class StartupsStore extends EntityStore<StartupsState> {

  constructor() {
    super(initialState);
  }

}