export class DocumentCategoryConstants {
    static CLINICAL_DOCUMENTS: string = 'Clin';
    static DENIAL: string = 'Denial';
    static DENIAL_NOTICE: string = 'DenialNotice';
    static ECHARTS: string = 'ECharts';
    static INTERMEDIARIES: string = 'DenialIntermediaries';
    static KNOWLEDGE_BASE: string = 'KB';
    static OTHER_PREAUTHORIZATION_DOCUMENT: string = 'OtherPreauth';
    static PATIENT_DOCUMENTS: string = 'Pt';
    static PATIENT_PACKET: string = 'Packet';
    static PREAUTHORIZATION_REQUEST: string = 'Preauth';
    static PREAUTHORIZATION_OBTAINED_APPROVAL: string = 'PreauthObtainedApproval';
    static PREAUTH_PENDING: string = 'PreauthPending';
    static PREAUTH_UNDER_MEDICAL_REVIEW: string = 'PreauthUnderMedicalReview';
    static PREAUTH_DENIED: string = 'PreauthDenied';
    static PREAUTHORIZATION_FACILITY_ADMIN: string = 'PreauthFacilityAdmin';
    static PROOF_OF_AUTHORIZATION: string = 'ProofAuth';
    static REPORTS_ONE_TIME: string = 'Report';
    static STARTUP: string = 'Startup';
}