<div class="row">
    <div [ngClass]="showComment ? 'col-6' : 'col-12'">
        <div [ngSwitch]="questionType">
            <tms-question-textbox [id]="id" *ngSwitchCase="2" [question]="question" [interviewAnswerField]="interviewAnswerField"
                (onInterviewValueChanged)="onValueChanged($event)">
            </tms-question-textbox>
            <tms-question-textarea [id]="id" *ngSwitchCase="3" [question]="question" [interviewAnswerField]="interviewAnswerField"
                (onInterviewValueChanged)="onValueChanged($event)">
            </tms-question-textarea>
            <tms-question-numeric [id]="id" *ngSwitchCase="4" [question]="question" [interviewAnswerField]="interviewAnswerField"
                [disabled]="disabled" (onInterviewValueChanged)="onValueChanged($event)">
            </tms-question-numeric>
            <tms-question-date [id]="id" *ngSwitchCase="7" [question]="question" [interviewAnswerField]="interviewAnswerField"
                (onInterviewValueChanged)="onValueChanged($event)" [disabled]="disabled">
            </tms-question-date>
            <tms-question-textbox [id]="id" *ngSwitchCase="8" [question]="question" [interviewAnswerField]="interviewAnswerField"
                (onInterviewValueChanged)="onValueChanged($event)">
            </tms-question-textbox>
            <tms-question-datetime [id]="id" *ngSwitchCase="9" [question]="question" [interviewAnswerField]="interviewAnswerField"
                [disabled]="disabled" (onInterviewValueChanged)="onValueChanged($event)">
            </tms-question-datetime>
            <tms-question-checkbox [id]="id" *ngSwitchCase="13" [question]="question" [disabled]="disabled"
                [interviewAnswerField]="interviewAnswerField" (onInterviewValueChanged)="onValueChanged($event)">
            </tms-question-checkbox>
            <tms-question-checkboxlist [id]="id" *ngSwitchCase="14" [question]="question" [dataListItems]="dataListItems"
                [interviewAnswerField]="interviewAnswerField" [orientation]="orientation" [disabled]="disabled"
                (onInterviewSelectionChanged)="onSelectionChanged($event)">
            </tms-question-checkboxlist>
            <tms-question-radiolist [id]="id" *ngSwitchCase="15" [question]="question" [dataListItems]="dataListItems"
                [interviewAnswerField]="interviewAnswerField" [orientation]="orientation" [disabled]="disabled"
                (onInterviewRadioListValueChanged)="onValueChanged($event)">
            </tms-question-radiolist>
            <tms-question-yes-no [id]="id" *ngSwitchCase="16" [question]="question" [interviewAnswerField]="interviewAnswerField"
                [disabled]="disabled" (onInterviewValueChanged)="onValueChanged($event)">
            </tms-question-yes-no>
            <tms-question-basic-narrative [id]="id" *ngSwitchCase="22" [question]="question" [dataListItems]="dataListItems"
                [interviewAnswerField]="interviewAnswerField"
                (onBasicNarrativeChanged)="onBasicNarrativeChanged($event)"></tms-question-basic-narrative>
            <tms-question-levelofcare *ngSwitchCase="24" [question]="question"
                [interviewAnswerField]="interviewAnswerField" [disabled]="disabled" (onInterviewValueChanged)="onValueChanged($event)">
            </tms-question-levelofcare>
            <tms-question-qualityofperformance [id]="id" *ngSwitchCase="25" [question]="question"
                [interviewAnswerField]="interviewAnswerField" [disabled]="disabled" (onInterviewValueChanged)="onValueChanged($event)">
            </tms-question-qualityofperformance>
            <tms-interview-question-drop-down-list [id]="id" *ngSwitchCase="26" [question]="question"
                [dataListItems]="dataListItems" [interviewAnswerField]="interviewAnswerField" [disabled]="disabled"
                (onInterviewValueChanged)="onValueChanged($event)"></tms-interview-question-drop-down-list>
            <tms-interview-question-pills [id]="id" *ngSwitchCase="27" [question]="question" [dataListItems]="dataListItems"
                [interviewAnswerField]="interviewAnswerField"
                (onInterviewSelectionChanged)="onSelectionChanged($event)">
            </tms-interview-question-pills>
            <tms-question-icd-code-type-ahead [id]="id" *ngSwitchCase="28" [question]="question" [disabled]="disabled"
                [startOfCareDate]="startOfCareDate" [interviewAnswerField]="interviewAnswerField"
                (onInterviewValueChanged)="onValueChanged($event)">
            </tms-question-icd-code-type-ahead>
            <tms-interview-question-native-drop-down *ngSwitchCase="29" [question]="question"
                [dataListItems]="dataListItems" [interviewAnswerField]="interviewAnswerField" [disabled]="disabled"
                (onInterviewValueChanged)="onValueChanged($event)">
            </tms-interview-question-native-drop-down>
            <tms-interview-question-drop-down-checkmark-list [id]="id" *ngSwitchCase="30" [question]="question" [dataListItems]="dataListItems"
                [interviewAnswerField]="interviewAnswerField" [disabled]="disabled"
                (onInterviewSelectionChanged)="onSelectionChanged($event)" [selectionMode] = "'multiple'">
            </tms-interview-question-drop-down-checkmark-list>
            <tms-interview-question-pills [id]="id" *ngSwitchCase="31" [question]="question" [dataListItems]="dataListItems"
                [interviewAnswerField]="interviewAnswerField" [compact]="true"
                (onInterviewSelectionChanged)="onSelectionChanged($event)">
            </tms-interview-question-pills>
            <tms-interview-question-drop-down-checkmark-list [id]="id" *ngSwitchCase="32" [question]="question" [dataListItems]="dataListItems"
                [interviewAnswerField]="interviewAnswerField" [disabled]="disabled"
                (onInterviewSelectionChanged)="onSelectionChanged($event)" [selectionMode] = "'all'">
            </tms-interview-question-drop-down-checkmark-list>
        </div>
    </div>
    <div class="col-6" *ngIf="showComment">
        <dx-text-area [(value)]="commentText" (onValueChanged)="onTextAreaValueChanged($event)" placeholder="Notes" [disabled]="disabled">
        </dx-text-area>
    </div>
</div>
<div *ngIf="showAdditionalText">
    {{ additionalText }}
</div>