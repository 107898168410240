<div class="row">
    <div class="col-6 announcements-header">
        Announcements
    </div>
    <div class="col-6">
        <div class="float-right">
            <tms-date-box placeholder="Search By Date" width="200"
                (onValueChanged)="announcementDateValueChanged($event)" [showClearButton]="true"></tms-date-box>
        </div>
    </div>
</div>
<hr>
<div class="row">
    <div class="col">
        <div>
            <div *ngFor="let announcement of filteredAnnouncements">
                <div class="row announcement-container">
                    <div class="col-9">
                        <div class="row">
                            <div class="col-12 tms-row-divider announcement-title">
                                {{ announcement.title }}
                            </div>
                        </div>
                        <div class="row tms-row-divider">
                            <div class="col-12 announcement-text" [innerHTML]="announcement.text | sanitizeHtml">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="announcement.imageUrl" class="col">
                        <div class="float-right">
                            <a href="javascript:void(0);"
                            (click)="openImageModal(content, announcement.imageUrl, announcement.title)">
                            <img [src]="announcement.imageUrl" class="announcements-thumbnail" />
                        </a>
                        </div>
                    </div>
                </div>
                <hr>
            </div>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h3>{{ modalTitle }}</h3>
        <dx-button class="tms-icon-button" icon="fas fa-times" (click)="modal.close()" hint="Close"></dx-button>
    </div>
    <div class="modal-body">
        <div>
            <img [src]="modalImageUrl" class="announcements-img-modal" />
        </div>
    </div>
</ng-template>