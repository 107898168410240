import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OnDestroyComponent } from '@app/shared';
import * as workerInterval from 'worker-timers';

@Component({
  selector: 'tms-user-auto-logout-modal',
  templateUrl: './user-auto-logout-modal.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAutoLogoutModalComponent extends OnDestroyComponent implements OnInit {
  @Input() userIdleCountDownMilliseconds: number = null;
  autoLogoutCountdown: string = null;

  private userIdleCounter: number = 0;
  private setIntervalId: number = null;

  constructor(private activeModalService: NgbActiveModal,
    private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.userIdleCounter = this.userIdleCountDownMilliseconds / 1000;
    this.setIntervalId = workerInterval.setInterval(() => {
      --this.userIdleCounter;
      if (this.userIdleCounter === 0) {
        this.onNoClicked();
      }

      const remainingMins = Math.floor(this.userIdleCounter / 60);
      const remainingSecs = this.userIdleCounter - remainingMins * 60;
      this.autoLogoutCountdown = `${remainingMins.toString().padStart(2, '0')}:${remainingSecs.toString().padStart(2, '0')}`;
      this.cdr.detectChanges();
    }, 1000)
  }

  onYesClicked() {
    workerInterval.clearInterval(this.setIntervalId);
    this.activeModalService.close();
  }

  onNoClicked() {
    workerInterval.clearInterval(this.setIntervalId);
    this.activeModalService.dismiss();
  }

}
