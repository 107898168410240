<tms-modal [title]="title" (onSubmit)="onSubmitPressed()" (onCancel)="onClearPressed()" cancelButtonText="Clear" (onClose)="onClose()">
    <div [ngSwitch]="treatmentItem.chargeCodeObj.globalCptCodeSettings.modalityType">
        <div *ngSwitchCase="modalityEnum.IceHeat">
            <div class="row">
                <div class="col">
                    Ice/Heat <dx-select-box [items]="iceHeatList" [(value)]="dd1Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
                <div class="col">
                    Minutes <dx-text-box [(value)]="txt1Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="modalityEnum.Estim">
            <div class="row">
                <div class="col">
                    Estim <dx-select-box [items]="eStimList" [(value)]="dd1Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
                <div class="col">
                    Minutes <dx-text-box [(value)]="txt1Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
                <div class="col">
                    Attended <dx-select-box [items]="attendedList" [(value)]="dd2Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    Intensity <dx-text-box [(value)]="txt2Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
                <div class="col">
                    With <dx-select-box [items]="iceHeatList" [(value)]="dd3Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="modalityEnum.ShortWaveDiathermy">
            <div class="row">
                <div class="col">
                    Shortwave Diathermy <dx-select-box [items]="diathermyList" [(value)]="dd1Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
                <div class="col">
                    Minutes <dx-text-box [(value)]="txt1Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
                <div class="col">
                    Pulse Rate <dx-text-box [(value)]="txt2Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
                <div class="col">
                    Pulse Duration <dx-text-box [(value)]="txt3Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
                <div class="col">
                    Watts <dx-text-box [(value)]="txt4Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>
            <div class="row tms-row-divider">
                <div class="col">
                    Location <dx-select-box [items]="sideList" [(value)]="dd2Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
                <div class="col">
                    <br>
                    <dx-select-box [items]="directionalList" [(value)]="dd3Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
                <div class="col">
                    <br>
                    <dx-select-box [items]="bodyRegionsList" [(value)]="dd4Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="modalityEnum.Traction">
            <div class="row">
                <div class="col">
                    Traction <dx-select-box [items]="tractionAreaList" [(value)]="dd1Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
                <div class="col">
                    Pounds <dx-text-box [(value)]="txt1Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    Frequency <dx-select-box [items]="frequencyList" [(value)]="dd2Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
                <div class="col">
                    Position <dx-select-box [items]="tractionPositionList" [(value)]="dd3Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="modalityEnum.Ionto">
            <div class="row">
                <div class="col">
                    CC <dx-text-box [(value)]="txt1Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="modalityEnum.Infrared">
            <div class="row">
                <div class="col">
                    Minutes <dx-text-box [(value)]="txt1Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="modalityEnum.Whirlpool">
            <div class="row">
                <div class="col">
                    Minutes <dx-text-box [(value)]="txt1Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
                <div class="col">
                    Degrees <dx-text-box [(value)]="txt2Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="modalityEnum.Paraffin">
            <div class="row">
                <div class="col">
                    Minutes <dx-text-box [(value)]="txt1Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="modalityEnum.Ultrasound">
            <div class="row">
                <div class="col">
                    Mode <dx-select-box [items]="ultrasoundModeList" [(value)]="dd1Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
                <div class="col">
                    w/cm2 <dx-text-box [(value)]="txt1Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    Minutes <dx-text-box [(value)]="txt2Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
                <div class="col">
                    Mhz <dx-text-box [(value)]="txt3Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    Location <dx-select-box [items]="sideList" [(value)]="dd2Value"></dx-select-box>
                </div>
                <div class="col">
                    <br>
                    <dx-select-box [items]="directionalList" [(value)]="dd3Value"></dx-select-box>
                </div>
                <div class="col">
                    <br>
                    <dx-select-box [items]="bodyRegionsList" [(value)]="dd4Value"></dx-select-box>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="modalityEnum.PTNS">
            <div class="row">
                <div class="col">
                    Treatment Number <dx-text-box [(value)]="txt1Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
                <div class="col">
                    Leg <dx-select-box [items]="legSide" [(value)]="dd1Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    Setting <dx-text-box [(value)]="txt2Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
                <div class="col">
                    Minutes <dx-text-box [(value)]="txt3Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    Patient Experienced <dx-text-box [(value)]="txt4Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box> since last treatment.
                </div>
            </div>
        </div>
        <div *ngSwitchCase="modalityEnum.Ultraviolet">
            <div class="row">
                <div class="col">
                    Minutes <dx-text-box [(value)]="txt1Value">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Value is required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>
        </div>
    </div>
</tms-modal>
