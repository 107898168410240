import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlConstantsService } from './url-constants.service';
import {
  OperationResponse,
  WeeklyUpdate,
  GeneratedReportResponse,
} from '@app/model';

@Injectable({
  providedIn: 'root',
})
export class WeeklyUpdateService {

  constructor(private httpClient: HttpClient,
    private urlConstantsService: UrlConstantsService) { }

  getAllWeeklyUpdates(facilityId: string, status: string = 'Scheduled'): Observable<WeeklyUpdate[]> {
    return this.httpClient.get<OperationResponse<WeeklyUpdate[]>>(`${this.urlConstantsService.WEEKLY_UPDATE_URL}?facilityId=${facilityId}&status=${status}`)
        .pipe(map(response => response.data));
  }

  getWeeklyUpdateById(id: string): Observable<WeeklyUpdate> {
    return this.httpClient.get<OperationResponse<WeeklyUpdate>>(`${this.urlConstantsService.WEEKLY_UPDATE_URL}/${id}`)
      .pipe(map(response => response.data));
  }

  getWeeklyUpdateReport(facilityId: string, weeklyUpdateId: string) {
    return this.httpClient.get<OperationResponse<GeneratedReportResponse>>(`${this.urlConstantsService.WEEKLY_UPDATE_URL}/report?facilityId=${facilityId}&weeklyUpdateId=${weeklyUpdateId}`)
      .pipe(map(response => response.data));
  }

  createWeeklyUpdate(weeklyUpdate: WeeklyUpdate) {
    return this.httpClient.post<OperationResponse<WeeklyUpdate>>(this.urlConstantsService.WEEKLY_UPDATE_URL, weeklyUpdate)
        .pipe(map(response => response.data));
  }

  updateWeeklyUpdate(weeklyUpdate: WeeklyUpdate) {
    return this.httpClient.put<OperationResponse<WeeklyUpdate>>(`${this.urlConstantsService.WEEKLY_UPDATE_URL}/${weeklyUpdate.id}`, weeklyUpdate)
        .pipe(map(response => response.data));
  }

  deleteWeeklyUpdate(id: string) {
    return this.httpClient.delete<OperationResponse<void>>(`${this.urlConstantsService.WEEKLY_UPDATE_URL}/${id}`)
        .pipe(map(response => response.data));
  }
}
