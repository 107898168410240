export enum eDocumentTabStepIndex {
    EVALUATION_INFO = 0,
    MODULES,
    OTHER_NOTES,
    OUTCOMES,
    ST_OUTCOMES,
    PLANNED_DC,
    PATIENT_COMPLEXITY,
    PLAN_OF_CARE,
    EXTENT_OF_PROGRESS,
    RECERT_INFO,
    DAILY_NOTES,
    DISCHARGE_INFO,
    FINALIZE,
    GOAL_STATEMENT,
    LEGACY,
}

export const EVALUATION_STEPS: eDocumentTabStepIndex[] = [
    eDocumentTabStepIndex.EVALUATION_INFO,
    eDocumentTabStepIndex.MODULES,
    eDocumentTabStepIndex.OTHER_NOTES,
    eDocumentTabStepIndex.OUTCOMES,
    eDocumentTabStepIndex.ST_OUTCOMES,
    eDocumentTabStepIndex.PLANNED_DC,
    eDocumentTabStepIndex.PATIENT_COMPLEXITY,
    eDocumentTabStepIndex.PLAN_OF_CARE,
    eDocumentTabStepIndex.GOAL_STATEMENT,
    eDocumentTabStepIndex.FINALIZE,
];

export const RECERT_STEPS: eDocumentTabStepIndex[] = [
    eDocumentTabStepIndex.RECERT_INFO,
    eDocumentTabStepIndex.LEGACY,
    eDocumentTabStepIndex.MODULES,
    eDocumentTabStepIndex.OTHER_NOTES,
    eDocumentTabStepIndex.EXTENT_OF_PROGRESS,
    eDocumentTabStepIndex.PLAN_OF_CARE,
    eDocumentTabStepIndex.GOAL_STATEMENT,
    eDocumentTabStepIndex.FINALIZE,
];

export const PROGRESS_NOTE_STEPS: eDocumentTabStepIndex[] = [
    eDocumentTabStepIndex.LEGACY,
    eDocumentTabStepIndex.MODULES,
    eDocumentTabStepIndex.DAILY_NOTES,
    eDocumentTabStepIndex.OTHER_NOTES,
    eDocumentTabStepIndex.EXTENT_OF_PROGRESS,
    eDocumentTabStepIndex.GOAL_STATEMENT,
    eDocumentTabStepIndex.FINALIZE,
];

export const DISCHARGE_SUMMARY_STEPS: eDocumentTabStepIndex[] = [
    eDocumentTabStepIndex.DISCHARGE_INFO,
    eDocumentTabStepIndex.LEGACY,
    eDocumentTabStepIndex.MODULES,
    eDocumentTabStepIndex.DAILY_NOTES,
    eDocumentTabStepIndex.OTHER_NOTES,
    eDocumentTabStepIndex.EXTENT_OF_PROGRESS,
    eDocumentTabStepIndex.OUTCOMES,
    eDocumentTabStepIndex.ST_OUTCOMES,
    eDocumentTabStepIndex.GOAL_STATEMENT,
    eDocumentTabStepIndex.FINALIZE,
];