import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface TmsMatConfirmationDialog {
  actionButtonVisible?: boolean;
  actionText?: string;
  cancelButtonVisible?: boolean;
  cancelText?: string;
  message?: string;
  showWarningIcon?: boolean;
  showDeleteWarningIcon?: boolean;
  title?: string;
}

@Component({
  selector: 'tms-mat-confirmation-dialog',
  templateUrl: './tms-mat-confirmation-dialog.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TmsMatConfirmationDialogComponent implements OnInit {
  actionButtonVisible: boolean = true;
  actionText: string = 'Yes';
  cancelButtonVisible: boolean = true;
  cancelText: string = 'No';
  message: string;
  showDeleteWarningIcon: boolean = false;
  showWarningIcon: boolean = false;
  title: string = null;

  constructor(private dialogRef: MatDialogRef<TmsMatConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: TmsMatConfirmationDialog) { }

  ngOnInit(): void {
    this.actionButtonVisible = this.data.actionButtonVisible != null ? this.data.actionButtonVisible : this.actionButtonVisible;
    this.actionText = this.data.actionText ?? this.actionText;
    this.cancelButtonVisible = this.data.cancelButtonVisible != null ? this.data.cancelButtonVisible : this.cancelButtonVisible;
    this.cancelText = this.data.cancelText ?? this.cancelText;
    this.message = this.data.message ?? this.message;
    this.showDeleteWarningIcon = this.data.showDeleteWarningIcon ?? this.showDeleteWarningIcon;
    this.showWarningIcon = this.data.showWarningIcon ?? this.showWarningIcon;
    this.title = this.data.title ?? this.title;
  }

  onYesClicked() {
    this.dialogRef.close(true);
  }

  onNoClicked() {
    this.dialogRef.close(false);
  }

}
