import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { OperationResponse, Hospital } from '@app/model';

@Injectable({
	providedIn: 'root',
})
export class HospitalService {
    constructor(private httpClient: HttpClient,
        private urlConstantsService: UrlConstantsService) {
    }

    searchHospital(searchText: string) {
        return this.httpClient.get<OperationResponse<Hospital[]>>(`${this.urlConstantsService.HOSPITALS_URL}/search?searchText=${searchText}`)
            .pipe(map(response => response.data));
    }

    getById(id: string) {
        return this.httpClient.get<OperationResponse<Hospital>>(`${this.urlConstantsService.HOSPITALS_URL}/${id}`)
            .pipe(map(response => response.data));
    }
}
