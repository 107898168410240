import { Injectable } from '@angular/core';
import { RouteConstants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class JumpToNavigationService {

  get jumpToNavItems() {
    return [
      {
        'key': 'Billing Admin',
        'items': [
          {
            text: 'Intermediary',
            route: this.createAdminRoute([RouteConstants.ADMIN_INTERMEDIARY_DASHBOARD])
          },
          {
            text: 'Charge Code Admin',
            route: this.createAdminRoute([RouteConstants.ADMIN_CHARGE_CODES_DASHBOARD]),
          },
          {
            text: 'Master Payers',
            route: this.createAdminRoute([RouteConstants.ADMIN_MASTER_PAYER_DASHBOARD]),
          },
        ]
      },
      {
        'key': 'Client Admin',
        'items': [
          {
            text: 'Dashboard',
            route: this.createAdminRoute([RouteConstants.CLIENT_ROOT, RouteConstants.CLIENT_DASHBOARD]),
          },
          {
            text: 'Facility Admin',
            route: this.createAdminRoute([RouteConstants.ADMIN_FACILITY_ROOT, RouteConstants.ADMIN_FACILITY_DASHBOARD])
          },
          {
            text: 'Offerings',
            route: this.createAdminRoute([RouteConstants.ADMIN_OFFERINGS]),
          },
          {
            text: 'Regions & Areas',
            route: this.createAdminRoute([RouteConstants.ADMIN_REGION_AREA_DASHBOARD]),
          },
        ]
      },
      {
        'key': 'Communications',
        'items': [
          {
            text: 'Dashboard',
            route: this.createAdminRoute([RouteConstants.ADMIN_COMMUNICATIONS, RouteConstants.ADMIN_COMMUNICATION_DASHBOARD])
          }, {
            text: 'Morning Meetings',
            route: this.createAdminRoute([RouteConstants.ADMIN_COMMUNICATIONS, RouteConstants.ADMIN_COMMUNICATION_MORNING_MEETING])
          }
        ]
      },
      {
        'key': 'Mass Unlock Charges',
        'items': [
          {
            text: 'Dashboard',
            route: this.createAdminRoute([RouteConstants.ADMIN_MASS_UNLOCK_DASHBOARD]),
          }
        ]
      },
      {
        'key': 'Report Scheduler',
        'items': [
          {
            text: 'Dashboard',
            route: this.createAdminRoute([RouteConstants.ADMIN_REPORT_SCHEDULER]),
          }
        ]
      },
      {
        'key': 'System Health',
        'items': [
          {
            text: 'Dashboard',
            route: this.createAdminRoute([RouteConstants.ADMIN_SYSTEM_HEALTH]),
          },
        ]
      },
      {
        'key': 'User Admin',
        'items': [
          {
            text: 'Employee Admin',
            route: this.createAdminRoute([RouteConstants.ADMIN_EMPLOYEE_ROOT, RouteConstants.ADMIN_EMPLOYEE_DASHBOARD])
          },
          {
            text: 'Practitioner Admin',
            route: this.createAdminRoute([RouteConstants.ADMIN_PHYSICIAN_ROOT, RouteConstants.ADMIN_PHYSICIAN_DASHBOARD])
          },
          {
            text: 'Client User Admin',
            route: this.createAdminRoute([RouteConstants.ADMIN_CLIENT_DASHBOARD]),
          },
          {
            text: 'Roles & Permissions',
            route: this.createAdminRoute([RouteConstants.ADMIN_ROLES]),
          }
        ]
      }
    ];
  }

  constructor() { }

  private createAdminRoute(routes: string[]): string {
    return `${RouteConstants.PAGES_ROOT}/${RouteConstants.ADMIN_ROOT}/${routes.join('/')}`;
  }


}
