import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Offering, OperationResponse } from '@app/model';
import { UrlConstantsService } from './url-constants.service';

@Injectable({
  providedIn: 'root'
})
export class OfferingsService {

  constructor(private httpClient: HttpClient, 
              private urlConstantsService: UrlConstantsService) { }

  createOffering(offering: Offering) {
    return this.httpClient.post<OperationResponse<Offering>>(`${this.urlConstantsService.OFFERINGS_URL}`, offering);
  }

  getOffering(id: string) {
    return this.httpClient.get<Offering>(`${this.urlConstantsService.OFFERINGS_URL}/${id}`);
  }

  updateOffering(id: string, offering: Offering) {
    return this.httpClient.put<OperationResponse<Offering>>(`${this.urlConstantsService.OFFERINGS_URL}/${id}`, offering);
  }

  getAllOfferings(): Observable<Offering[]> {
    return this.httpClient.get<Offering[]>(`${this.urlConstantsService.OFFERINGS_URL}`);
  }
}
