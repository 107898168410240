<dx-text-box #typeAheadTextBox
                width="100%"
                [placeholder]="placeholderText"
                [value]="value"
                [showClearButton]="true"
                (onKeyUp)="onTypeAheadKeyUp($event)"
                (onContentReady)="onTypeAheadContentReady($event)">
    <dx-validator>
        <dxi-validation-rule type="custom" 
                [validationCallback]="validateTypeAheadCallback" 
                [message]="requiredMessage">
        </dxi-validation-rule>
    </dx-validator>
</dx-text-box>
