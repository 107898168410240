import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ScreensStore, ScreensState } from './screens.store';
import { PatientScreen } from '@app/model';

@Injectable({providedIn: 'root'})
export class ScreensQuery extends QueryEntity<ScreensState, PatientScreen>{
    constructor(protected store: ScreensStore) {
        super(store);
    }
}