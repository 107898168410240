import { Directive, OnInit, Input, OnDestroy, TemplateRef, 
  ViewContainerRef, EmbeddedViewRef, ChangeDetectorRef } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CacheManService, UIPermissionConstants } from '@app/core';
import { ePermissions } from '@app/model';
import { OnDestroyComponent } from '../on-destroy/on-destroy.component';

@Directive({
  selector: '[hasPermission]',
})
export class HasPermissionDirective extends OnDestroyComponent implements OnInit, OnDestroy {
  embeddedViewRef: EmbeddedViewRef<any> = null;

  @Input() set hasPermission(claimType: ePermissions) {
    combineLatest(this.cachemanService.getUserHasPermission$(claimType),
      this.cachemanService.getIsUserClockedIn$())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([permissions, isUserClockedIn]) => {
        if (permissions) {
            if (UIPermissionConstants.permissionsBasedOnClockedIn.findIndex(permission => permission === claimType) >= 0) {
              // check if user is clocked in
                if (isUserClockedIn) {
                  this.createEmbeddedView();
                } else {
                  // remove template from DOM
                  this.clearEmbeddedView();
                }
            } else {
              this.createEmbeddedView();
            }
        } else {
          this.clearEmbeddedView();
        }
      })
  }

  constructor(private cdr: ChangeDetectorRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cachemanService: CacheManService) {
    super();
  }

  ngOnInit() { }

  ngOnDestroy() {
    super.onDestroy();
  }

  private createEmbeddedView(): void {
    if (!this.embeddedViewRef) {
      // Add template to Dom
      this.embeddedViewRef = this.viewContainer.createEmbeddedView(this.templateRef);
      this.cdr.markForCheck();
    }
  }

  private clearEmbeddedView(): void {
    this.viewContainer.clear();
    this.embeddedViewRef = null;
  }
}
