export enum eSupportIssueStatus {
    /// <summary>
    ///     Indicates a support issue has been created but not yet transitioned to an awaiting status
    /// </summary>
    Created = 0,

    /// <summary>
    ///     Awaiting a reply from a support representative
    /// </summary>
    AwaitingSupport = 1,

    /// <summary>
    ///     Awaiting a reply from the user
    /// </summary>
    AwaitingUser = 2,

    /// <summary>
    ///     Awaiting engineering/development
    /// </summary>
    AwaitingEngineering = 3,

    ToDo = 4,

    InProgress = 5,
    /// <summary>
    ///     Issue is on hold
    /// </summary>
    OnHold = 8,

    /// <summary>
    ///     Issue is closed
    /// </summary>
    Closed = 9
}