import { TemplateObject, templateObjToStringListUtil } from '../template-object';

export const RecertPrecautionsProperties = {
    NO_CONTRAINDICATIONS: 'noContrain',
    NO_PRECAUTIONS: 'noPrecautions',
    HIP_PRECAUTIONS: 'hipPrecautions',
    FALL_RISK: 'fallRisk',
    NWB_R_LE: 'nwbRLE',
    NWB_L_LE: 'nwbLLE',
    TTWB_R_LE: 'ttwbRLE',
    TTWB_L_LE: 'ttwbLLE',
    PWB_R_LE: 'pwbRLE',
    PWB_L_LE: 'pwbLLE',
    PWB_L_UE: 'pwbLUE',
    PWB_R_UE: 'pwbRUE',
    WBAT_R_LE: 'wbatRLE',
    WBAT_L_LE: 'wbatLLE',
    NWB_R_UE: 'nwbRUE',
    NWB_L_UE: 'nwbLUE',
    SWALLOWING_PRECAUTIONS: 'swallowingPre',
    FEED_A: 'feedA',
    WEIGHT_LOSS_RISK: 'weightLossRisk',
    DEHYDRATION_RISK: 'dehydrRisk',
    ASPIRATION_RISK: 'aspirRisk',
    REFLUX: 'reflux',
    O2_PRN: 'o2PRN',
    O2_ALL_TIMES: 'o2AllTimes',
    SPLINT_W_ACTITIVIES: 'splintWAct',
    PACEMAKER: 'pacemaker',
    SEIZURE: 'seizure',
    DEFIBRILLATOR: 'defibrillator',
    STERNAL_PRECAUTIONS: 'sternalPrecautions',
    SPINAL_PRECAUTIONS: 'spinalPrecautions',
    ORTHOSTATIC_HYPO: 'orthostaticHypotension', 
    DIALYSIS: 'dialysis',
    WOUNDS: 'wounds',
}

export const RECERT_PRECAUTIONS_TEMPLATE: string = `{{#if stringList}}{{/if}}{{stringList}}`;

export class RecertPrecautionsTemplate implements TemplateObject {
    noContrain: string = '';
    noPrecautions: string = '';
    hipPrecautions: string = '';
    fallRisk: string = '';
    nwbRLE: string = '';
    nwbLLE: string = '';
    ttwbRLE: string = '';
    ttwbLLE: string = '';
    pwbRLE: string = '';
    pwbLLE: string = '';
    pwbLUE: string = '';
    pwbRUE: string = '';
    wbatRLE: string = '';
    wbatLLE: string = '';
    nwbRUE: string = '';
    nwbLUE: string = '';
    swallowingPre: string = '';
    feedA: string = '';
    weightLossRisk: string = '';
    dehydrRisk: string = '';
    aspirRisk: string = '';
    reflux: string = '';
    o2PRN: string = '';
    o2AllTimes: string = '';
    splintWAct: string = '';
    pacemaker: string = '';
    seizure: string = '';
    defibrillator: string = '';
    sternalPrecautions: string = '';
    spinalPrecautions: string = '';
    orthostaticHypotension: string = '';
    dialysis: string = '';
    wounds: string = '';
    
    stringList: string[] = [];

    private toList = Object.values(RecertPrecautionsProperties);

    preCompileTemplateObj(): void {
        this.stringList = templateObjToStringListUtil(this, this.toList);
    }
}