<div class="row no-gutters" [ngClass]="{'progress-item-not-selected': incomplete }">
  <div class="col-1" *ngIf="showProgressIcon">
    <i class="progress-icon fa-check-circle" [ngClass]="{ 'far': !completed , 'progress-icon-inprogress': !completed,  'fas': completed, 'progress-icon-completed': completed }"></i>
  </div>
  <div class="col" [ngClass]="{'progress-item-selected': selected, 'progress-item-not-selected': !selected }">
      <tms-collapse-panel *ngIf="tmsStep.subSteps && tmsStep.subSteps.length > 0; else stepAnchor" class="step-label" [panelTitle]="tmsStep.name" [panelCollapsed]="!selected">
        <div *ngFor="let subStep of tmsStep.subSteps" class="step-sub-label">
          <a href="javascript:void(0)" class="step-label" [ngClass]="{'progress-item-not-selected': selectedIndex != subStep.step }" (click)="onItemClicked(subStep.step)">{{subStep.name}}</a>
        </div>  
      </tms-collapse-panel>
      <ng-template #stepAnchor>
          <a href="javascript:void(0)" class="step-label" (click)="onItemClicked(tmsStep.step)">{{tmsStep.name}}</a>
      </ng-template>
  </div>
</div>