import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import {
    AdmissionInterviewRequest,
    Dictionary,
    InterviewAnswer,
    InterviewCloneResponse,
    InterviewRequest,
    InterviewSession,
    PagedResult,
    AddToInterview,
    OperationResponse,
    InterviewStoreAnswer,
    SignatureRequest,
    SignatureResponse,
    InterviewAnswerRequest,
 } from '@app/model';
 import { InterviewsQuery, InterviewsStore } from '../stores';
import { noLoadingSpinner } from '../interceptors';

@Injectable({
	providedIn: 'root',
})
export class InterviewService {
    private _interviewSession$: BehaviorSubject<InterviewSession> = new BehaviorSubject<InterviewSession>(null);
    
    get interviewSession$(): Observable<InterviewSession> {
        return this._interviewSession$.asObservable();
    }

    constructor(private http: HttpClient,
                private interviewQuery: InterviewsQuery,
                private interviewStore: InterviewsStore,
                private urlConstantsService: UrlConstantsService) {
    }

    get activeInterview(): InterviewStoreAnswer {
        return this.interviewQuery.getActive();
    }

    get activeInterview$(): Observable<InterviewStoreAnswer> {
        return this.interviewQuery.selectActive();
    }

    getActiveInterviews(patientId: string) {
        return this.http.get<PagedResult<InterviewSession>>(`${this.urlConstantsService.INTERVIEWS_URL}?patientId=${patientId}`)
            .pipe(map(interviewPagedResult => interviewPagedResult.items));
    }

    startAdmissionInterview(admissionInterviewRequest: AdmissionInterviewRequest) {
        return this.http.post<OperationResponse<InterviewSession>>(`${this.urlConstantsService.INTERVIEWS_URL}/start/admission`, admissionInterviewRequest)
            .pipe(map(response => response.data));
    }

    startInterview(interviewRequest: InterviewRequest) {
        return this.http.post<OperationResponse<InterviewSession>>(`${this.urlConstantsService.INTERVIEWS_URL}`, interviewRequest)
            .pipe(map(response => response.data));
    }

    resumeInterview(interviewSessionId: string) {
        return this.http.get<OperationResponse<InterviewSession>>(`${this.urlConstantsService.INTERVIEWS_URL}/${interviewSessionId}`, {})
            .pipe(map(response => response.data));
    }

    getInterviewByAnswers(interviewSessionId: string, interviewAnswerRequest: InterviewAnswerRequest) {
        return this.http.put<OperationResponse<InterviewSession>>(`${this.urlConstantsService.INTERVIEWS_URL}/updateByAnswers/${interviewSessionId}`, interviewAnswerRequest)
            .pipe(tap(response => {
                this.updateActiveInterviewSessionAnswers(response.data.answers);
                this._interviewSession$.next(response.data);
            }));
    }

    saveInterview(interviewSessionId: string, interviewSession: InterviewSession) {
        return this.http.put<OperationResponse<InterviewSession>>(`${this.urlConstantsService.INTERVIEWS_URL}/${interviewSessionId}`, interviewSession)
            .pipe(map(response => response.data));
    }

    signInterview(interviewSessionId: string, signatureRequest: SignatureRequest): Observable<SignatureResponse> {
        return this.http.post<OperationResponse<SignatureResponse>>(`${this.urlConstantsService.INTERVIEWS_URL}/${interviewSessionId}/sign`, signatureRequest, { context: noLoadingSpinner() })
            .pipe(map(response => response.data));
    }

    unsignInterview(interviewSessionId: string, signatureRequest: SignatureRequest) {
        return this.http.post<OperationResponse<InterviewSession>>(`${this.urlConstantsService.INTERVIEWS_URL}/${interviewSessionId}/unsign`, signatureRequest);
    }

    deleteInterview(interviewSessionId: string) {
        return this.http.delete<OperationResponse<InterviewSession>>(`${this.urlConstantsService.INTERVIEWS_URL}/${interviewSessionId}`);
    }

    regenerateInterview(interviewSessionId: string, patientCaseId: string, documentDate: Date): Observable<void> {
        const regenerateRequest = { interviewSessionId, patientCaseId, documentDate };
        return this.http.put<OperationResponse<void>>(`${this.urlConstantsService.INTERVIEWS_URL}/${interviewSessionId}/regenerate`, regenerateRequest)
            .pipe(map(response => response.data));
    }

    regenerateAdmissionDocument(admissionId: string): Observable<void> {
        const regenerateRequest = { admissionId };
        return this.http.put<OperationResponse<void>>(`${this.urlConstantsService.INTERVIEWS_URL}/${admissionId}/regenerate/admission`, regenerateRequest)
            .pipe(map(response => response.data));
    }

    cloneInterview(interviewSessionId: string) {
        return this.http.get<OperationResponse<InterviewCloneResponse>>(`${this.urlConstantsService.INTERVIEWS_URL}/${interviewSessionId}/clone`)
            .pipe(map(response => response.data));
    }

    addInterviewGroup(interviewSessionId: string, addToInterview: AddToInterview) {
        return this.http.post<InterviewSession>(`${this.urlConstantsService.INTERVIEWS_URL}/${interviewSessionId}/add`, addToInterview)
            .pipe(map(response => response));
    }

    syncInterview(item: any) {
        return null;
    }

    addInterviewQuestionAnswer(interviewSessionId: string, interviewAnswer: InterviewAnswer) {
        this.interviewStore.upsert(interviewSessionId, (entity) => {
            const interviewStoreAnswer = entity as InterviewStoreAnswer;
            return {
                ...entity,
                interviewAnswers: {
                    ...interviewStoreAnswer.interviewAnswers,
                    [this.getInterviewAnswerDictionaryKey(interviewAnswer)]: {
                        ...interviewAnswer,
                    },
                },
            }
        });
    }

    deleteInterviewQuestionAnswer(interviewSessionId: string, interviewAnswer: InterviewAnswer) {
        const currentAnswers = this.interviewQuery.getActive().interviewAnswers;
        const newAnswers = { ...currentAnswers };
        const answerkey = this.getInterviewAnswerDictionaryKey(interviewAnswer);
        if (currentAnswers[answerkey]) {
            delete newAnswers[answerkey];
            this.interviewStore.update(interviewSessionId, entity => {
                return {
                    ...entity,
                    interviewAnswers: newAnswers,
                };
            });
        }
    }

    setActiveInterviewSession(interviewSessionId: string, answers: InterviewAnswer[]) {
        const answersMap: Dictionary<InterviewAnswer> = {};
        answers.forEach(answer => {
            answersMap[this.getInterviewAnswerDictionaryKey(answer)] = answer;
        });
        this.interviewStore.add({
            id: interviewSessionId,
            interviewAnswers: answersMap,
        });
        this.interviewStore.setActive(interviewSessionId);
    }

    updateActiveInterviewSessionAnswers(answers: InterviewAnswer[]) {
        const answersMap: Dictionary<InterviewAnswer> = {};
        answers.forEach(answer => {
            answersMap[this.getInterviewAnswerDictionaryKey(answer)] = answer;
        });

        this.interviewStore.updateActive(entity => {
            return {
                ...entity,
                interviewAnswers: answersMap,
            }
        })
    }

    removeInterviewSession(interviewSessionId: string) {
        this.interviewStore.remove(interviewSessionId);
    }

    resetActiveInterview() {
        this.interviewStore.setActive(null);
    }

    getInterviewAnswers(interviewSessionId: string): InterviewAnswer[] {
        const storeAnswers = this.interviewQuery.getEntity(interviewSessionId).interviewAnswers
        const answers = [];
        Object.keys(storeAnswers).forEach(key => answers.push(storeAnswers[key]));
        return answers;
    }

    getInterviewAnswers$(interviewSessionId: string): Observable<InterviewAnswer[]> {
        return of(this.getInterviewAnswers(interviewSessionId));
    }

    getInterviewAnswer(answer: InterviewAnswer): InterviewAnswer {
        return this.activeInterview.interviewAnswers[this.getInterviewAnswerDictionaryKey(answer)];
    }

    private getInterviewAnswerDictionaryKey(answer: InterviewAnswer): string {
        let k: string = '';
        k += answer.page;
        k += !!answer.tab ? `-${answer.tab}` : '';
        k += !!answer.group ? `-${answer.group}` : '';
        k += `-${answer.shortName}`;
        return k; 
    }
}
