import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Company, County, OperationResponse, PagedResult } from '@app/model';
import { UrlConstantsService } from './url-constants.service';

@Injectable({
  providedIn: 'root'
})
export class CountyService {

  constructor(private http: HttpClient, private urlConstantsService: UrlConstantsService) { }

  getCountyByState(state: string): Observable<County[]> {
    return this.http.get<OperationResponse<County[]>>(`${this.urlConstantsService.COUNTY_URL}?stateAbbreviation=${state}`)
      .pipe(map(response => response.data));
    }

    createCounty(county: County) {
        return this.http.post<OperationResponse<County>>(`${this.urlConstantsService.COUNTY_URL}`, county)
            .pipe(map(response => response.data));
    }

    updateCounty(county: County) {
        return this.http.put<OperationResponse<County>>(`${this.urlConstantsService.COUNTY_URL}/${county.id}`, county)
            .pipe(map(response => response.data));
    }

    deleteCounty(countyId: string) {
        return this.http.delete<OperationResponse<County>>(`${this.urlConstantsService.COUNTY_URL}/${countyId}`);
    }

    getByCountyId(countyId: string) {
        return this.http.get<OperationResponse<County>>(`${this.urlConstantsService.COUNTY_URL}/${countyId}`)
            .pipe(map(response => response.data));
    }
}
