import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    exports: [
      MatAutocompleteModule,
      MatDialogModule,
      MatInputModule,
      MatMenuModule,
      MatSelectModule,
    ]
  })
export class MaterialModule {}