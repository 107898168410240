import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { CloseOut, CloseOutSlim, OperationResponse, PagedResult, CloseOutTeamMember, CloseOutNote, CloseOutEquipment } from '@app/model';
import { ErrorMessageUtils } from '@app/utils';
import { CacheManService } from './cacheman.service';
import { UrlConstantsService } from './url-constants.service';
import { CloseOutsQuery, CloseOutsStore } from '../stores/close-outs';

@Injectable({
  providedIn: 'root'
})
export class CloseOutsService {

  constructor(private cacheManService: CacheManService,
    private closeOutsQuery: CloseOutsQuery,
    private closeOutsStore: CloseOutsStore,
    private httpClient: HttpClient,
    private urlConstantsService: UrlConstantsService) { }

  activeStartup$(): Observable<CloseOut> {
      return this.closeOutsQuery.selectActive();
  }

  getCloseOuts(isArchived: boolean): Observable<CloseOutSlim[]> {
    return this.httpClient.get<PagedResult<CloseOutSlim>>(`${this.urlConstantsService.CLOSEOUTS_URL}?isArchived=${isArchived}`)
      .pipe(map(response => response.items));
  }

  getCloseOutById(id: string): Observable<CloseOut> {
    return this.httpClient.get<CloseOut>(`${this.urlConstantsService.CLOSEOUTS_URL}/${id}`)
      .pipe(tap(startup => {
        this.closeOutsStore.upsert(id, () => startup);
        this.closeOutsStore.setActive(id);
      }))
  }

  updateCloseOut(): Observable<CloseOut> {
    if (this.closeOutsQuery.getActiveId()) {
      return this.httpClient.put<OperationResponse<CloseOut>>(`${this.urlConstantsService.CLOSEOUTS_URL}/${this.closeOutsQuery.getActiveId()}`, this.closeOutsQuery.getActive())
        .pipe(catchError(err => throwError(ErrorMessageUtils.getDisplayErrorMessage(err, 'An error occurred while saving close out.'))),
          map(response => response.data))
    } else {
      return of(null);
    }
  }

  addCloseOutTeamMember(member: CloseOutTeamMember) {
    this.closeOutsStore.updateActive(entity => {
      return {
        ...entity,
        teamMembers: [
          ...entity.teamMembers,
          member,
        ],
      };
    });
  }

  editCloseOutTeamMember(member: CloseOutTeamMember) {
    this.closeOutsStore.updateActive(entity => {
      const memberIndex = entity.teamMembers.findIndex(tm => tm.id == member.id);
      if (memberIndex >= 0) {
        return {
          ...entity,
          teamMembers: [
            ...entity.teamMembers.slice(0, memberIndex),
            member,
            ...entity.teamMembers.slice(memberIndex + 1),
          ],
        };
      }
    });
  }

  addCloseOutNote(note: string): Observable<CloseOut> {
    return this.cacheManService.getCurrentUser()
      .pipe(switchMap(user => {
        this.closeOutsStore.updateActive(entity => {
          return {
            ...entity,
            notes: [
              ...entity.notes,
              {
                note: note,
                updatedOn: new Date(),
                updatedOnUtc: new Date(),
                updatedByUserId: user?.id,
                updatedByUserName: user ? `${user?.lastName}, ${user?.firstName}` : '',
              },
            ],
          };
        });
        return this.updateCloseOut();
      }));
  }

  editCloseOutNote(id: string, note: string): Observable<CloseOut>  {
    return this.cacheManService.getCurrentUser()
      .pipe(switchMap(user => {
        this.closeOutsStore.updateActive(entity => {
          const memberIndex = entity.notes.findIndex(tm => tm.id == id);
          if (memberIndex >= 0) {
            return {
              ...entity,
              notes: [
                ...entity.notes.slice(0, memberIndex),
                {
                  note: note,
                  updatedOn: new Date(),
                  updatedByUserId: user?.id,
                  updatedByUserName: user ? `${user?.lastName}, ${user?.firstName}` : '',
                },
                ...entity.notes.slice(memberIndex + 1),
              ],
            };
          }
        });
        return this.updateCloseOut();
      }));
  }

  deleteCloseOutNote(id: string): Observable<CloseOut>  {
    this.closeOutsStore.updateActive(entity => {
      const memberIndex = entity.notes.findIndex(tm => tm.id == id);
      if (memberIndex >= 0) {
        return {
          ...entity,
          notes: [
            ...entity.notes.slice(0, memberIndex),
            ...entity.notes.slice(memberIndex + 1),
          ],
        };
      }
    });
    return this.updateCloseOut();
  }

  addCloseOutEquipment(newEquipment: CloseOutEquipment) {
    this.closeOutsStore.updateActive(entity => {
      return {
        ...entity,
        equipment: [
          ...entity.equipment,
          {
            id: newEquipment.id,
            model: newEquipment.model,
            serialNumber: newEquipment.serialNumber,
            inventoryNumber: newEquipment.inventoryNumber,
            description: newEquipment.description,
          },
        ],
      };
    });
  }

  editCloseOutEquipment(id: string, updateEquipment: CloseOutEquipment) {
    this.closeOutsStore.updateActive(entity => {
          const memberIndex = entity.equipment.findIndex(tm => tm.id == id);
          if (memberIndex >= 0) {
            return {
              ...entity,
              equipment: [
                ...entity.equipment.slice(0, memberIndex),
                {
                  id: updateEquipment.id,
                  model: updateEquipment.model,
                  serialNumber: updateEquipment.serialNumber,
                  inventoryNumber: updateEquipment.inventoryNumber,
                  description: updateEquipment.description,
                },
                ...entity.equipment.slice(memberIndex + 1),
              ],
            };
          }
        });
  }

  deleteCloseOutEquipment(id: string) {
    this.closeOutsStore.updateActive(entity => {
      const memberIndex = entity.equipment.findIndex(tm => tm.id == id);
      if (memberIndex >= 0) {
        return {
          ...entity,
          equipment: [
            ...entity.equipment.slice(0, memberIndex),
            ...entity.equipment.slice(memberIndex + 1),
          ],
        };
      }
    });
  }

  updateActiveCloseOut(closeOut: CloseOut) {
    this.cacheManService.getCurrentUser()
      .pipe(take(1))
      .subscribe(user => {
        this.closeOutsStore.updateActive(entity => {
          return {
            ...closeOut,
            updatedByUserId: user?.id,
            updatedByUserName: user ? `${user?.lastName}, ${user?.firstName} ` : '',
            modifiedOn: new Date(),
          }
        });
      })
  }
}
