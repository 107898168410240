import { Component, EventEmitter, Input, Output} from '@angular/core';
import { InterviewQuestion, InterviewAnswerField } from '@app/model';

@Component({
  selector: 'tms-question-datetime',
  styleUrls: [],
  template: `
  <tms-date-box [value]="interviewAnswerField.value"
               [disabled]="disabled" 
               type="datetime"
              (onValueChanged)="onDateTimeValueChanged($event)"
              [reevaluate]="true" [isCustomValidation]="true" isCustomValidationMessage="Value is required" [validationCallback]="onValidationCallback">         
  </tms-date-box>
  `,
})
export class InterviewQuestionDateTimeComponent {
    @Input() question: InterviewQuestion;
    @Input() interviewAnswerField: InterviewAnswerField;
    @Input() disabled: boolean = false;
    @Output() onInterviewValueChanged: EventEmitter<string> = new EventEmitter<string>();

    constructor() {
    }

    onDateTimeValueChanged($event) {
      this.onInterviewValueChanged.emit($event.value);
    }

    onValidationCallback = (options) => {
      if (this.question.validationOptions && this.question.validationOptions.isRequired) {
        return options.value != null;
      } else {
        return true;
      }
    }
}
