import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataListItem, InterviewQuestion, InterviewAnswerField } from '@app/model';

interface BasicNarrativeChanges {
  dataListItems: DataListItem[];
  narrative: string;
}

@Component({
  selector: 'tms-question-basic-narrative',
  templateUrl: './interview-question-basic-narrative.component.html',
  styleUrls: ['./interview-question-basic-narrative.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InterviewQuestionBasicNarrativeComponent implements OnInit {
  @Input() dataListItems: DataListItem[];
  @Input() question: InterviewQuestion;
  @Input() id: string;
  @Input() interviewAnswerField: InterviewAnswerField;
  @Output() onBasicNarrativeChanged: EventEmitter<BasicNarrativeChanges> = new EventEmitter<BasicNarrativeChanges>();
  
  narrative: string = '';

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.interviewAnswerField && this.interviewAnswerField.value) {
      this.narrative = this.interviewAnswerField.value;
    }
  }

  onSelectionChanged(e: DataListItem[]) {
    this.narrative = '';
    e.forEach((option) => {
      this.narrative = this.generateNarrative(option, this.narrative);
    });
    this.cdr.detectChanges();
    this.onBasicNarrativeChanged.emit({ dataListItems: e, narrative: this.narrative });
  }

  onNbcManualChange(e) {
  }

  private generateNarrative(dataListItem: DataListItem, narrative: string = ''): string {
      if (narrative !== '') {
        narrative += ', ';
      }
      narrative += dataListItem.text;
      return narrative;
  }
}
