import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';
import { InterviewQuestion, DataListItem, InterviewAnswerField } from '@app/model';
import { DeepCopyUtils } from '@app/utils';

@Component({
  selector: 'tms-interview-question-drop-down-checkmark-list',
  templateUrl: './interview-question-drop-down-checkmark-list.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InterviewQuestionDropDownCheckmarkListComponent implements OnInit {
  @Input() question: InterviewQuestion;
  @Input() dataListItems: DataListItem[] = [];
  @Input() interviewAnswerField: InterviewAnswerField;
  @Input() disabled: boolean = false;
  @Input() selectionMode: string = 'multiple';
  @Output() onInterviewSelectionChanged: EventEmitter<string[]> = new EventEmitter<string[]>();

  selectedItems: string[] = [];

  constructor() { }

  ngOnInit(): void {
    this.selectedItems = DeepCopyUtils.deepCopyObject(this.interviewAnswerField.values);
  }

  onSelectionChanged($event) {
    this.onInterviewSelectionChanged.emit(this.selectedItems);
  }

}
