import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NarrativeBuilderOption, NBComponentText } from '@app/model';

@Component({
    selector: 'tms-narrative-builder-textbox',
    templateUrl: './narrative-builder-textbox.component.html',
    styleUrls: ['../narrative-builder.scss'],
})
export class NarrativeBuilderTextboxComponent implements OnInit {
    @Input() alertUser?: boolean = false;
    @Input() optionValue: NarrativeBuilderOption;
    @Output() onValueChanged: EventEmitter<NBComponentText> = new EventEmitter<NBComponentText>();

    value: string = '';
    private overrideManualEdits: boolean = null;

    ngOnInit() {
        if (this.optionValue.selectedValue) {
            this.value = this.optionValue.selectedValue as string;
            this.onValueChanged.emit({property: this.optionValue.property, value: this.value, overrideManualEdits: this.overrideManualEdits,});
        }
    }

    onTextBoxValueChanged($event) {
        this.onValueChanged.emit({property: this.optionValue.property, value: $event, overrideManualEdits: this.overrideManualEdits,});
    }

    onOverrideManualEdits(e: boolean) {
        this.overrideManualEdits = e;
    }
}
