import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import {
    Facility,
    FacilityCaseload,
    FacilityDashboard,
    FacilityDistance,
    FacilityDocuments,
    FacilityHome,
    FacilityUserSignaturesNeeded,
    OperationResponse,
    PagedResult,
    Patient,
    SignedDocuments,
    UnlockTreatmentRequest,
    FacilityDashboardInfo,
    PatientRoomNumber,
    GenericSearch,
    PatientSlim,
    CensusEntryModel,
    CensusRiskSelection,
} from '@app/model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CacheManService } from './cacheman.service';
import { FacilitiesQuery, FacilitiesStore } from '../stores';
import { LocalStorageService } from './local-storage.service';
import { LocalStorageConstants } from '../constants/index';
import { noLoadingSpinner } from '../interceptors';

@Injectable({
	providedIn: 'root',
})
export class FacilitiesService {
    private _activeFacilityDashboard: FacilityDashboard = null;
    private _activeFacilityDashboard$: BehaviorSubject<FacilityDashboard> = new BehaviorSubject<FacilityDashboard>(null);
    private _activeFacilityId: string = null;
    private _activeFacilityId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private _numOfCoSigns$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(private http: HttpClient, 
                private urlConstantsService: UrlConstantsService, 
                private cacheManService: CacheManService,
                private facilitiesQuery: FacilitiesQuery,
                private facilitiesStore: FacilitiesStore,
                private localStorageService: LocalStorageService) {}

    get activeFacilityDashboard(): FacilityDashboard {
        return this._activeFacilityDashboard;
    }

    set activeFacilityDashboard(value: FacilityDashboard) {
        this._activeFacilityDashboard = value;
        this._activeFacilityDashboard$.next(this._activeFacilityDashboard);
    }

    get activeFacilityDashboard$(): Observable<FacilityDashboard> {
        return this._activeFacilityDashboard$.asObservable();
    }

    get activeFacilityId(): string {
        if (this._activeFacilityId && this._activeFacilityId !== null) {
            return this._activeFacilityId;
        } else {
            return this.localStorageService.getItem(LocalStorageConstants.ACTIVE_FACILITY_ID);
        }
    }

    set activeFacilityId(value: string) {
        this._activeFacilityId = value;
        this.localStorageService.setItem(LocalStorageConstants.ACTIVE_FACILITY_ID, this._activeFacilityId);
        this._activeFacilityId$.next(this._activeFacilityId);
    }

    get activeFacilityId$(): Observable<string> {
        return this._activeFacilityId$.asObservable();
    }

    get numOfCoSigns$(): Observable<number> {
        return this._numOfCoSigns$.asObservable();
    }


    getAll() {
        return this.http.get<PagedResult<Facility>>(`${this.urlConstantsService.FACILITIES_URL}`)
            .pipe(map(facilityPagedResult => facilityPagedResult.items));
    }

    getAllUserFacilities() {
        return this.http.get<PagedResult<Facility>>(`${this.urlConstantsService.FACILITIES_URL}/userfacilities`)
            .pipe(map(facilityPagedResult => facilityPagedResult.items));
    }

    getAllManagerFacilities() {
        return this.http.get<PagedResult<Facility>>(`${this.urlConstantsService.FACILITIES_URL}/managerfacilities`)
            .pipe(map(facilityPagedResult => facilityPagedResult.items));
    }

    getById(id: string): Observable<Facility> {
        return this.http.get<Facility>(`${this.urlConstantsService.FACILITIES_URL}/${id}`);
    }

    getPatientsById(id: string, showAll: boolean = false, includesPreviousPatients: boolean = false): Observable<PatientSlim[]> {
        return this.http.get<PagedResult<PatientSlim>>(`${this.urlConstantsService.FACILITIES_URL}/${id}/patients?showAll=${showAll}&includesPreviousPatients=${includesPreviousPatients}`)
            .pipe(map(patientsPagedResult => patientsPagedResult.items))
    }

    // Facility Admin page
    createFacility(facility: Facility) {
        return this.http.post<OperationResponse<Facility>>(`${this.urlConstantsService.FACILITIES_URL}`, facility)
            .pipe(
                map(response => response.data),
                tap(response => {
                    this.cacheManService.addNewFacility({ id: response.id, name: response.name, ipAddresses: response.ipAddresses });
                }));
    }

    updateFacility(facility: Facility, nameChanged: Boolean = false) {
        return this.http.put<OperationResponse<Facility>>(`${this.urlConstantsService.FACILITIES_URL}/${facility.id}`, facility)
        .pipe(
            map(response => response.data),
            tap(response => {
                if (nameChanged) {
                    this.cacheManService.updateFacility({ id: response.id, name: response.name, ipAddresses: response.ipAddresses });
                }
            }));
    }

    // Dashboard
    getFacilityDashboardInfo(facilityId: string): Observable<FacilityDashboardInfo> {
        return this.http.get<FacilityDashboardInfo>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/dashboard/info`)
            .pipe(tap(response => this._numOfCoSigns$.next(response.pendingSignaturesCount)))
    }

    getFacilityDashboard(id: string): void {
        this.http.get<FacilityDashboard>(`${this.urlConstantsService.FACILITIES_URL}/${id}/dashboard`)
            .subscribe(fd => {
                this.activeFacilityDashboard = fd;
                this.activeFacilityId = id;
            });
    }

    getFacilityDashboardDocuments(id: string, month?: number, year?: number) {
        this.http.get<FacilityDocuments>(`${this.urlConstantsService.FACILITIES_URL}/${id}/dashboard/documents?month=${month}&year=${year}`)
            .subscribe(fd => {
                this.activeFacilityDashboard = {
                    ...this.activeFacilityDashboard,
                    documents: fd,
                };
            })
    }

    getFacilityDashboardCaseload(id: string, month?: number, year?: number) {
        this.http.get<FacilityCaseload>(`${this.urlConstantsService.FACILITIES_URL}/${id}/dashboard/caseload?month=${month}&year=${year}`)
            .subscribe(fc => {
                this.activeFacilityDashboard = {
                    ...this.activeFacilityDashboard,
                    caseload: fc,
                };
            })
    }

    getFacilityDashboardHome(id: string, day?: number, month?: number, year?: number) {
        this.http.get<FacilityHome>(`${this.urlConstantsService.FACILITIES_URL}/${id}/dashboard/home?day=${day}&month=${month}&year=${year}`)
            .subscribe(fh => {
                this.activeFacilityDashboard = {
                    ...this.activeFacilityDashboard,
                    home: fh,
                };
            });
    }

    getFacilityDashboardSignatures(id: string): Observable<FacilityUserSignaturesNeeded> {
        return this.http.get<FacilityUserSignaturesNeeded>(`${this.urlConstantsService.FACILITIES_URL}/${id}/dashboard/pendingdocuments`)
            .pipe(tap(response => this._numOfCoSigns$.next(response.coSignatures.length + response.coAuthors.length)));
    }

    getFacilityDashboardUnlockTreatmentRequests(id: string, status: string) {
        return this.http.get<OperationResponse<UnlockTreatmentRequest[]>>(`${this.urlConstantsService.FACILITIES_URL}/${id}/dashboard/unlocktreatmentsrequest?status=${status}`)
            .pipe(map(response => response.data));
    }

    // Facility Travel Distance
    getFacilityTravelDistance() {
        return this.http.get<PagedResult<FacilityDistance>>(`${this.urlConstantsService.FACILITIES_URL}/traveldistances`)
            .pipe(map(facilityPagedResult => facilityPagedResult.items));
    }

    addFacilityTravelDistance(distance: FacilityDistance) {
        return this.http.post<OperationResponse<FacilityDistance[]>>(`${this.urlConstantsService.FACILITIES_URL}/traveldistances`, distance)
            .pipe(map(response => response.data));
    }

    updateFacilityTravelDistance(distance: FacilityDistance) {
        return this.http.put<OperationResponse<FacilityDistance[]>>(`${this.urlConstantsService.FACILITIES_URL}/traveldistances`, distance)
            .pipe(map(response => response.data));
        
    }

    deleteFacilityTravelDistance(distance: FacilityDistance) {
        return this.http.delete<OperationResponse<FacilityDistance[]>>(`${this.urlConstantsService.FACILITIES_URL}/${distance.sourceFacilityId}/traveldistances/${distance.id}`)
            .pipe(map(response => response.data));
    }

    getSignedDocuments(facilityId: string, month?: number, year?: number): Observable<SignedDocuments> {
        return this.http.get<SignedDocuments>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/dashboard/signeddocuments?month=${month}&year=${year}`);
    }

    // Census Page
    getFacilityCensus(facilityId: string) {
        return this.http.get<PagedResult<CensusEntryModel>>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/census`, { context: noLoadingSpinner() })
        .pipe(map(facilityPagedResult => facilityPagedResult.items));
    }

    getFacilityCensusSearch(facilityId: string, criteria: GenericSearch) {
        return this.http.post<PagedResult<Patient>>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/census/search`, criteria)
        .pipe(map(facilityPagedResult => facilityPagedResult.items));
    }

    downloadFacilityCensusFile(facilityId: string, censusItems: CensusEntryModel[]) {
        return this.http.post<OperationResponse<any>>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/census/download`, censusItems)
        .pipe(map(response => response.data));
    }

    addCensusResident(facilityId: string, patientId: string, room: CensusEntryModel) {
        return this.http.post<boolean>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/census/add/${patientId}`, room);
    }

    updateCensusResident(facilityId: string, patientId: string, room: CensusEntryModel) {
        return this.http.put<boolean>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/census/update/${patientId}`, room, { context: noLoadingSpinner() });
    }

    updateCensusResidentRisk(facilityId: string, patientId: string, selection: CensusRiskSelection) {
        return this.http.put<boolean>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/census/risk/${patientId}`, selection, { context: noLoadingSpinner() });
    }

    deleteCensusResident(facilityId: string, patientId: string, roomNumberId: string) {
        return this.http.delete<boolean>(`${this.urlConstantsService.FACILITIES_URL}/${facilityId}/census/${patientId}/${roomNumberId}`);
    }
}
