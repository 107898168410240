import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tms-message-modal',
  templateUrl: './tms-message-modal.component.html',
  styleUrls: ['./tms-message-modal.component.css'],
})
export class TmsMessageModalComponent {
  @Input() message: string;

  constructor(public activeModal: NgbActiveModal) { }

  onOkClicked() {
    this.activeModal.close();
  }

}
