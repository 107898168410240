import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { DataListItem, InterviewQuestion, InterviewAnswerField } from '@app/model';

interface InterviewQuestionPill {
  text: string;
  selected: boolean;
  hint: string;
}

@Component({
  selector: 'tms-interview-question-pills',
  templateUrl: './interview-question-pills.component.html',
})
export class InterviewQuestionPillsComponent implements OnInit {
  @Input() question: InterviewQuestion;
  @Input() dataListItems: DataListItem[] = [];
  @Input() interviewAnswerField: InterviewAnswerField;
  @Input() compact: boolean = false;
  @Output() onInterviewSelectionChanged: EventEmitter<string[]> = new EventEmitter<string[]>();

  selectedItems: InterviewQuestionPill[] = [];

  constructor() { }

  ngOnInit() {
    this.selectedItems = this.dataListItems.map(dli =>{  return { text: dli.text, selected: false, hint: dli.hint } })
    if (this.interviewAnswerField && this.interviewAnswerField.values) {
      this.interviewAnswerField.values.forEach(value => {
        const index = this.dataListItems.findIndex(dataitem => dataitem.value === value);
        if (index >= 0) {
          this.selectedItems[index].selected = true;
        }
      });
    }
  }

  onValueChanged($event) {
    this.onInterviewSelectionChanged.emit(this.getSelectedItems());
  }

  private getSelectedItems(): string[] {
    const selectedItems = [];
    this.selectedItems.forEach((item, index) => {
      if (item.selected) {
        selectedItems.push(this.dataListItems[index].value);
      }
    })
    return selectedItems;
  }

}
