import { Injectable } from '@angular/core';
import { Observable, Observer } from "rxjs";
import { Script } from '@app/model';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {

  private scripts: Script[] = [];

  public load(script: Script): Observable<Script> {
    return new Observable<Script>((observer: Observer<Script>) => {
      var existingScript = this.scripts.find(s => s.name == script.name);

      // Complete if already loaded
      if (existingScript && existingScript.loaded) {
        observer.next(existingScript);
        observer.complete();
      }
      else {
        // Add the script
        this.scripts = [...this.scripts, script];

        // Load the script
        let scriptElement = document.createElement("script");
        scriptElement.type = "text/javascript";
        scriptElement.async = script.async;
        if (script.src) {
          scriptElement.src = script.src;
        }
        if (script.innerHtml) {
          scriptElement.innerHTML = script.innerHtml;
        }

        scriptElement.onload = () => {
          script.loaded = true;
          observer.next(script);
          observer.complete();
        };

        scriptElement.onerror = (error: any) => {
          observer.error("Couldn't load script " + script.src);
        };

        document.getElementsByTagName('body')[0].appendChild(scriptElement);
      }
    });
  }
}


