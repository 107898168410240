import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'removeWhitespace'
})
export class removeWhitespacePipe implements PipeTransform {

  transform(text: string): any {
    return text.replace(/\s+/g, '');
  }
}