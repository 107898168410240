import { Injectable } from "@angular/core";
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Treatment } from "@app/model";

export interface TreatmentsState extends EntityState<Treatment>, ActiveState {}

const initialState: TreatmentsState = {
    active: null,
}

@Injectable({providedIn: 'root'})
@StoreConfig({ name: 'treatments', idKey: 'patientCaseId'})
export class TreatmentsStore extends EntityStore<TreatmentsState> {
    constructor() {
        super(initialState);
    }
}