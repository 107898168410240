import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Facility } from '@app/model';

export interface FacilitiesState extends EntityState<Facility>, ActiveState {}

const initialState: FacilitiesState = {
  active: null,
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'facilities' })
export class FacilitiesStore extends EntityStore<FacilitiesState> {

  constructor() {
    super(initialState);
  }

}
