import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FacilitiesAdminStore, FacilitiesAdminState } from './facilities-admin-store';

@Injectable({
  providedIn: 'root',
})
export class FacilitiesAdminQuery extends QueryEntity<FacilitiesAdminState> {

  constructor(protected store: FacilitiesAdminStore) {
    super(store);
  }

}
