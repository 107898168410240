import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PreauthorizationRequest } from '@app/model';

export interface PreauthRequestsState extends EntityState<PreauthorizationRequest>, ActiveState {}

const initialState: PreauthRequestsState = {
  active: null,
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'preauthrequests' })
export class PreauthRequestsStore extends EntityStore<PreauthRequestsState> {

  constructor() {
    super(initialState);
  }

}