import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PreauthRequestsStore, PreauthRequestsState } from './preauth-requests.store';

@Injectable({
  providedIn: 'root',
})
export class PreauthRequestsQuery extends QueryEntity<PreauthRequestsState> {

  constructor(protected store: PreauthRequestsStore) {
    super(store);
  }

}