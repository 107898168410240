export class QueryStringUtils {
    static queryStringBuilder(queryStrings: any): string {
        let queryString: string = '';
        Object.keys(queryStrings)
            .filter(key => queryStrings[key] !== null)
            .forEach((key, index) => {
            if (index > 0) {
                queryString += '&';
            }
            queryString += `${key}=${queryStrings[key]}`;
        });
        return queryString;
    }
}