import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DataListItem, InterviewQuestion, InterviewAnswerField } from '@app/model';

@Component({
  selector: 'tms-interview-question-typeahead',
  templateUrl: './interview-question-typeahead.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InterviewQuestionTypeaheadComponent implements OnInit {
  @Input() question: InterviewQuestion;
  @Input() questionType: number;
  @Input() dataListItems: DataListItem[] = [];
  @Input() interviewAnswerField: InterviewAnswerField;
  @Input() disabled: boolean = false;
  @Output() onInterviewValueChanged: EventEmitter<string> = new EventEmitter<string>();


  constructor() { }

  ngOnInit() {
  }

  getDisplayExpr(item): string {
    // add display expr based on questionType
    return '';
  }

  getValueExpr(item): string {
    // add value expr based on questionType
    return '';
  }

  typeAheadValueChanged = (e): Observable<any[]> => {
    // add type ahead func based on questionType
    return of([]);
  }

  onTypeAheadValueChanged(e) {
    // TODO: there may be extra work to get value in correct format
    this.onInterviewValueChanged.emit(e);
  }

}
