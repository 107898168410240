import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { PatientPlannerSummaryStore, PatientPlannerSummaryState } from './patient-planner-summary.store';

@Injectable({ providedIn: 'root' })
export class PatientPlannerSummaryQuery extends Query<PatientPlannerSummaryState> {

  constructor(protected store: PatientPlannerSummaryStore) {
    super(store);
  }

}
