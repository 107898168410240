<nb-layout [center]="'center-column'" [nbSpinner]="loading" nbSpinnerSize="giant" nbSpinnerMessage="Please wait..." windowMode>
    <nb-layout-header subheader>
        <div class="header-container">
            <div>
                <tms-header *ngIf="isAuthenticated" [position]="'normal'"></tms-header>
            </div>
            <div class="banner">
                <tms-banner *ngIf="isAuthenticated" [position]="'normal'"></tms-banner>
            </div>
        </div>
    </nb-layout-header>
    <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive [end]="false" [containerFixed]="true">
        <div (click)="goToHome()" class="sidebar-logo">
            <img src="assets/images/ignite-logo.png" class="expanded-logo">
            <img src="assets/images/Logo-fire.png" class="collapsed-logo">
        </div>
        <nb-menu *ngIf="isAuthenticated" [items]="menu" autoCollapse></nb-menu>
        <div *ngIf="bShowAppVersion" class="app-version">
            <span *ngIf="appVersion" title="{{ appVersionExtended }}"> {{ appVersion }} </span>
        </div>
    </nb-sidebar>
    <nb-layout-column class="main-content">
        <ng-content select="router-outlet"></ng-content>
    </nb-layout-column>
</nb-layout>