<div class="row" style="cursor: pointer;" (click)="onPanelClick()">
    <div class="col">
        <div *ngIf="customPanelTitle; else default">
            <ng-content select="[panel-title]"></ng-content>
        </div>
        <ng-template #default>
            <span style="font-weight: bold;" id="panelTitle">{{ panelTitle }} </span><span *ngIf="isRequired"
                class="required-mark">*</span>
        </ng-template>
    </div>
    <div class="col-1" style="text-align: right;margin-right: 10px;">
        <i class="fas {{ panelCollapsed ? 'fa-plus' : 'fa-minus' }}" style="cursor: pointer;"></i>
    </div>
</div>
<div [ngbCollapse]="panelCollapsed">
    <ng-content></ng-content>
</div>