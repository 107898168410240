import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UrlConstantsService } from '@app/core/services/url-constants.service';
import { PagedResult, OperationResponse, Announcement } from '@app/model';
import { ParallelHasher } from 'ts-md5/dist/parallel_hasher';
import { ErrorMessageUtils } from '@app/utils';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class AnnouncementsService {

    private _imageUrl: string;
    private _imageUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private http: HttpClient, private urlConstantsService: UrlConstantsService) { }

    // Image Url
    get imageUrl(): string {
        return this._imageUrl;
    }

    get imageUrl$(): Observable<string> {
        return this._imageUrl$.asObservable();
    }

    set imageUrl(imageUrl: string) {
        this._imageUrl = imageUrl;
        this._imageUrl$.next(this._imageUrl);
    }

    getAnonymousAnnouncements() {
        return this.http.get<PagedResult<Announcement>>(`${this.urlConstantsService.ANNOUNCEMENTS_PUBLIC_URL}`)
            .pipe(map(pageResult => pageResult.items));
    }

    getUserAnnouncements() {
        return this.http.get<PagedResult<Announcement>>(`${this.urlConstantsService.ANNOUNCEMENTS_URL}/user`)
            .pipe(map(pageResult => pageResult.items));
    }

    getBannerAnnouncements() {
        return this.http.get<PagedResult<Announcement>>(`${this.urlConstantsService.ANNOUNCEMENTS_URL}/banner`)
            .pipe(map(pageResult => pageResult.items));
    }

    getAllAnnouncements(startDate: Date, endDate: Date) {
        const startDateFormatted = moment(startDate).format('L');
        const endDateFormatted= moment(endDate).format('L');
        return this.http.get<PagedResult<Announcement>>(`${this.urlConstantsService.ANNOUNCEMENTS_URL}?startDate=${startDateFormatted}&endDate=${endDateFormatted}`)
            .pipe(map(pageResult => pageResult.items));
    }

    createAnnouncement(announcement: Announcement) {
        return this.http.post<OperationResponse<Announcement>>(`${this.urlConstantsService.ANNOUNCEMENTS_URL}`, announcement)
            .pipe(map(response => response.data));
    }

    updateAnnouncement(announcement: Announcement) {
        return this.http.put<OperationResponse<Announcement>>(`${this.urlConstantsService.ANNOUNCEMENTS_URL}/${announcement.id}`, announcement)
            .pipe(map(response => response.data));
    }

    updateAnnouncementActiveStatus(id: string, isActive: boolean): Observable<boolean> {
        return this.http.put<OperationResponse<boolean>>(`${this.urlConstantsService.ANNOUNCEMENTS_URL}/${id}/active`, isActive)
            .pipe(map(response => response.data));
    }

    deleteAnnouncement(announcementId: string) {
        return this.http.delete(`${this.urlConstantsService.ANNOUNCEMENTS_URL}/${announcementId}`);
    }

    uploadImage(fileName: string, file: any) {
        const hasher = new ParallelHasher('assets/js/ts-md5/md5_worker.js');
        hasher.hash(file)
          .then(result => {
            const formData = new FormData();
            formData.append('Filename', fileName);
            formData.append('FileHash', result);
            formData.append('', file, fileName);
            this.http.post<OperationResponse<string>>(`${this.urlConstantsService.ANNOUNCEMENTS_URL}/uploadImage`, formData)
                .pipe(map(response => response.data))
                .subscribe((response) => {
                this.imageUrl = response;
                console.log(response);
              }, (error) => {
                let msg = ErrorMessageUtils.getDisplayErrorMessage(error, 'Error uploading file');
              });
          });
      }

    // hashFile(file: any) {
    //     const hasher = new ParallelHasher('assets/js/ts-md5/md5_worker.js');
    //     hasher.hash(file)
    //         .then(result => {
    //             this.fileHash = result;
    //         });
    // }
}
