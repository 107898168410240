import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Input, Output, SimpleChanges, ViewChild, OnChanges, OnDestroy } from '@angular/core';
import { LoadingService } from '@app/core';
import { DeepCopyUtils, KeyEventUtils } from '@app/utils';
import { DxTagBoxComponent } from 'devextreme-angular';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { OnDestroyComponent } from '..';

@Component({
  selector: 'tms-typeahead-tagbox',
  templateUrl: './tms-typeahead-tagbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TmsTypeaheadTagboxComponent extends OnDestroyComponent implements OnInit, OnChanges, OnDestroy {
  @Input() dataSource?: any[] = null; // the initial datasource to use
  @Input() displayExpr: string | Function = null;
  @Input() placeholderText?: string = 'Select';
  @Input() required: boolean = false;
  @Input() requiredMessage: string = 'Value is required';
  @Input() timeout?: number = 1000;
  @Input() value: any[] = [];
  @Input() valueExpr: string | Function = null;
  @Input() width?: string = "100";
  @Input() typeAheadValueChanged: (typeAheadText: string) => Observable<any[]> = null;
  @Input() disabled = false;

  @Output() onValueChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('typeAheadTagBox') typeAheadTagBox: DxTagBoxComponent;
  
  typeAheadDataSource: any[] = [];

  private selectedItems: any[] = [];
  private textChanged$: Subject<string> = new Subject<string>();
  
  constructor(private cdr: ChangeDetectorRef,
              private loadingService: LoadingService) {
    super();
  }

  ngOnInit() {
    if (this.dataSource) {
      this.typeAheadDataSource = DeepCopyUtils.deepCopyObject(this.dataSource);
    }

    this.textChanged$
      .pipe(takeUntil(this.onDestroy$),
            debounceTime(this.timeout),
            distinctUntilChanged(),
            filter(text => text.length >= 2),
            switchMap(text => this.typeAheadValueChanged(text)))
      .subscribe(list => {
        this.typeAheadDataSource = list;
        this.cdr.detectChanges();

        if (this.loadingService.loading) {
          this.loadingService.setLoading(false);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataSource'] && changes['dataSource'].currentValue != changes['dataSource'].previousValue) {
      this.typeAheadDataSource = DeepCopyUtils.deepCopyObject(changes['dataSource'].currentValue);
    }
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  onTypeAheadKeyUp(e) {
    let keyCode = e.event.keyCode;
    let validKey = KeyEventUtils.validLetterOrNumberKey(keyCode);

    // Prevent enter and other keys from interferring with the search
    if (!validKey) {
        return;
    }

    this.textChanged$.next(this.typeAheadTagBox.instance.option('text'));
  }

  onTypeAheadValueChanged(e) {
    this.onValueChanged.emit(e.component.option('value'));
  }

  validateTypeAheadBoxCallback = (e) => {
    if (this.required) {
      return !!e.value;
    } else {
      return true;
    }
  }

}
