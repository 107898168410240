import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[titleAttribute]'
})
export class TitleAttributeDirective implements OnInit {
  @Input() set titleAttribute(text: string) {
    if (text != null) {
      this.renderer.setAttribute(this.el.nativeElement.parentElement, 'title', text);
    }
  }

  constructor(private el: ElementRef, private renderer: Renderer2) { 
  }

  ngOnInit() {
  }


}
