import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { StartupsStore, StartupsState } from './startups.store';

@Injectable({
  providedIn: 'root',
})
export class StartupsQuery extends QueryEntity<StartupsState> {

  constructor(protected store: StartupsStore) {
    super(store);
  }

}