import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from './authentication/auth.service';
import { CacheManService } from './cacheman.service';
import { FacilitiesService } from './facilities.service';
import { DynamicMenu } from '@app/model';

export const ACTIVE_FACILITY_SET = 'ActiveFacilitySet';
export const USER_AUTHENTICATION_SET = 'UserAuthenticationSet';
export const HOME_LINK_CLICKED = 'HomeLinkClicked';
export const PATIENT_DASHBOARD_LINK_CLICKED = 'PatientDashboardLinkClicked';
export const PERMISSIONS_SET = 'PermissionsSet';
export const BILLING_ADMIN_LINK_CLICKED = 'BillingAdminLinkClicked';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private _menu$: BehaviorSubject<DynamicMenu> = new BehaviorSubject<DynamicMenu>(null);

  constructor(private facilitiesService: FacilitiesService,
    private authService: AuthService,
    private cacheManService: CacheManService) {
    this.facilitiesService.activeFacilityId$
      .pipe(filter(id => id !== null))
      .subscribe(id => {
        const dynamicMenu = { action: ACTIVE_FACILITY_SET, data: id };
        this._menu$.next(dynamicMenu)
      });

    this.authService.isAuthenticated$
      .subscribe(isAuthenticated => {
        this._menu$.next({
          action: USER_AUTHENTICATION_SET,
          data: isAuthenticated,
        });
      });

    this.cacheManService.getUserPermissions$()
      .pipe(filter(permissions => permissions != null))
      .subscribe(permissions => {
        this._menu$.next({
          action: PERMISSIONS_SET,
          data: permissions,
        })
      })
  }

  get menu$(): Observable<DynamicMenu> {
    return this._menu$;
  }

  homeLinkClicked() {
    this._menu$.next({
      action: HOME_LINK_CLICKED,
      data: true,
    });
  }

  patientDashboardLinkClicked() {
    this._menu$.next({
      action: PATIENT_DASHBOARD_LINK_CLICKED,
      data: true,
    });
  }

  billingAdminLinkClicked() {
    this._menu$.next({
      action: BILLING_ADMIN_LINK_CLICKED,
      data: true,
    });
  }
}
