import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { InterviewAnswerField, InterviewQuestion } from '@app/model';

@Component({
  selector: 'tms-question-checkbox',
  styleUrls: [],
  template: `
    <dx-check-box [id]="id" [value]="answerValue" (onValueChanged)="onCheckBoxValueChanged($event)" [text]="answerText" [disabled]="disabled"></dx-check-box>
  `,
})
export class InterviewQuestionCheckboxComponent implements OnInit {
    @Input() disabled: boolean = false;
    @Input() question: InterviewQuestion;
    @Input() interviewAnswerField: InterviewAnswerField;
    @Input() id: string;
    @Output() onInterviewValueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    answerValue: boolean = false;
    answerText: string = '';

    constructor() {}

    ngOnInit() {
      if (this.interviewAnswerField) {
        this.answerValue = this.interviewAnswerField.value === 'true' ? true : false;
      }
    }

    onCheckBoxValueChanged($event) {
      this.onInterviewValueChanged.emit($event.value);
    }
}
