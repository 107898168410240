export enum eNonTreatmentCategory {
    Unspecified = 0,
    CarePlans = 1,
    ComplianceSurveyExit = 2,
    HighRiskSafety = 3,
    Marketing = 4,
    MedicarePDPM = 5,
    QA = 6,
    RestorativeMeeting = 7,
    StandUpMorningMeeting = 8,
    WeeklyTherapyUpdate = 9,
    WoundRounds = 10,
    InService = 11,
    TripleCheck = 12,
    WellnessClass = 13,
    ProgramManagerTime = 14,
    HomeHealthOrientation = 15,
}