import {
  Component, EventEmitter, Output, Input, OnInit,
  OnChanges, ChangeDetectorRef, ChangeDetectionStrategy, SimpleChanges, ViewChild, ElementRef,
  AfterViewChecked
} from '@angular/core';
import { CdkStepper, StepperSelectionEvent } from '@angular/cdk/stepper';
import { Directionality } from '@angular/cdk/bidi';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DxScrollViewComponent } from 'devextreme-angular';

import { Dictionary, TmsStepGroup, eTmsStepperClosedEvent } from '@app/model';

interface StepInfo {
  group: number;
  stepIndex?: number;
}

interface TmsStepperEvent {
  group: number;
  stepperEvent: StepperSelectionEvent;
  stepIndex: number;
}

interface SelectionGroupChangingEvent {
  selectionGroupChangingItem: eSelectionGroupChangingItem;
  selectedIndex?: number;
}

enum eSelectionGroupChangingItem {
  next = 0,
  previous,
  selectedIndex,
}

// To add a stepper header, use select directive
// <div stepper-header-content></div>
// or 
// <div stepper-header-progress-region></div>
// 
// see module-wizard.component.html for an example.

@Component({
  selector: 'tms-stepper',
  templateUrl: './tms-stepper.component.html',
  styleUrls: ['./tms-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: CdkStepper,
    useExisting: TmsStepperComponent,
  }],
})
export class TmsStepperComponent extends CdkStepper implements OnInit, OnChanges {
  @Input() tmsStepGroup: TmsStepGroup[];
  @Input() nextButtonDisabled: boolean = false; // does next button depend on previous answers
  @Input() submitButtonDisabled: boolean = false;
  @Input() nextButtonText: string = 'Next';
  @Input() submitButtonText: string = 'Submit';
  @Input() showDoneForNowButton: boolean = true;
  @Input() showSubmit: boolean = false;
  @Input() startButtonText: string = 'Start';
  @Input() selectedStepIndex: number = 0;
  @Input() notifyOnSelectionGroupChange: boolean = false;
  @Input() showProgressIcon: boolean = true;
  @Output() onSelectionGroupChanging: EventEmitter<Subject<boolean>> = new EventEmitter<Subject<boolean>>(); // use when validation is needed before proceeding to next step
  @Output() onSelectionGroupChange: EventEmitter<TmsStepperEvent> = new EventEmitter<TmsStepperEvent>();
  @Output() onStepperClosed: EventEmitter<eTmsStepperClosedEvent> = new EventEmitter<eTmsStepperClosedEvent>();
  @Output() onCurrentScrollOffset: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('stepContent') stepContent: DxScrollViewComponent;
  nextValue: string = '';

  totalSteps: number = 0;

  selectionGroupChangingSubject$: Subject<boolean> = new Subject<boolean>();

  private _groupStepList: Dictionary<StepInfo> = {}; // { [subStepIndex/stepIndex]: stepInfo }
  private selectionGroupChangingEvent: SelectionGroupChangingEvent = null;
  private _showPreviousButton: boolean = true;

  get disableNextButton(): boolean {
    if (this.nextButtonDisabled) {
      if (!this.selected.completed) {
        return this.selected.optional != null && !this.selected.optional;
      }
    } else {
      return false;
    }
  }

  get showPreviousButton(): boolean {
    return this._showPreviousButton && this.selectedIndex > 0; 
  }

  constructor(dir: Directionality, private cdr: ChangeDetectorRef, elementRef: ElementRef<HTMLElement>) {
    super(dir, cdr, elementRef);
  }

  ngOnInit() {
    this.createTmsStepGroupMap();
    this.selectedIndex = this.selectedStepIndex;
    this.nextValue = this.selectedIndex == 0 ? this.startButtonText : this.nextButtonText;
    this.selectionChange
      .pipe(takeUntil(this._destroyed))
      .subscribe((stepperEvent: StepperSelectionEvent) => {
        this.stepContent.instance.scrollTo({ left: 0, top: 0 });
        this.nextValue = (stepperEvent.selectedIndex === 0) ? this.startButtonText : this.nextButtonText;
        this.onSelectionGroupChange.emit({
          group: this._groupStepList[stepperEvent.selectedIndex].group,
          stepperEvent: stepperEvent,
          stepIndex: this._groupStepList[stepperEvent.selectedIndex].stepIndex,
        });
        this.setPreviousButton(stepperEvent.selectedIndex);
        this.cdr.detectChanges();
      });

    this.selectionGroupChangingSubject$
      .pipe(takeUntil(this._destroyed))
      .subscribe(b => {
        if (b) {
          switch (this.selectionGroupChangingEvent.selectionGroupChangingItem) {
            case eSelectionGroupChangingItem.next:
              this.next();
              break;
            case eSelectionGroupChangingItem.previous:
              this.previous();
              break;
            case eSelectionGroupChangingItem.selectedIndex:
              this.selectedIndex = this.selectionGroupChangingEvent.selectedIndex;
              break;
          }
        }
      })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedStepIndex']) {
      this.selectedIndex = changes['selectedStepIndex'].currentValue;
    }
    if (changes['tmsStepGroup']) {
      this.createTmsStepGroupMap();
    }
  }

  ngAfterViewInit() {
    this.setPreviousButton(this.selectedIndex);
    this.cdr.detectChanges();
    super.ngAfterViewInit();
  }

  scrollToLocation(location: any) {
    this.stepContent.instance.scrollTo(location);
  }

  onCancelClicked(): void {
    this.onStepperClosed.emit(eTmsStepperClosedEvent.CANCELLED);
  }

  onPreviousClicked(): void {
    if (this.notifyOnSelectionGroupChange) {
      this.selectionGroupChangingEvent = { selectionGroupChangingItem: eSelectionGroupChangingItem.previous };
      this.onSelectionGroupChanging.emit(this.selectionGroupChangingSubject$);
    } else {
      this.previous();
    }
  }

  onNextClicked(): void {
    if (this.notifyOnSelectionGroupChange) {
      this.selectionGroupChangingEvent = { selectionGroupChangingItem: eSelectionGroupChangingItem.next };
      this.onSelectionGroupChanging.emit(this.selectionGroupChangingSubject$);
    } else {
      this.next();
    }
  }

  onSaveProgressClicked(): void {
    this.onStepperClosed.emit(eTmsStepperClosedEvent.SAVE_PROGRESS);
  }

  onScroll(e): void {
    this.onCurrentScrollOffset.emit(e.scrollOffset);
  }

  onSubmitClicked(): void {
    this.onStepperClosed.emit(eTmsStepperClosedEvent.SUBMITTED);
  }

  onStepProgressItemSelected(e): void {
    if (this.notifyOnSelectionGroupChange) {
      this.selectionGroupChangingEvent = { selectionGroupChangingItem: eSelectionGroupChangingItem.selectedIndex, selectedIndex: e};
      this.onSelectionGroupChanging.emit(this.selectionGroupChangingSubject$);
    } else {
      this.selectedIndex = e;
    }
  }

  private createTmsStepGroupMap() {
    this.tmsStepGroup.forEach((stepGroup, groupIndex) => {
      stepGroup.steps.forEach((step, stepIndex) => {
        if (step.subSteps && step.subSteps.length > 0) {
          step.subSteps.forEach(subStep => this._groupStepList[subStep.step] = { group: groupIndex, stepIndex: stepIndex });
        } else {
          this._groupStepList[step.step] = { group: groupIndex, stepIndex: stepIndex };
        }
      });
    });
    this.totalSteps = this.tmsStepGroup.reduce((accum, current) => accum + current.steps.reduce((acc, curr) => (curr.subSteps && curr.subSteps.length > 0) ? acc + curr.subSteps.length : acc += 1, 0), 0);
  }

  private setPreviousButton(index: number) {
    if (index > 0) {
      this._showPreviousButton = this.steps.find((item, i) => i === (index - 1)).editable;
    }
  }

}
