import {
    NarrativeBuilderOption,
    NarrativeBuilderList,
    PrognosticIndicatorsProperties,
} from '@app/model';
import { PrognosticIndicatorsConstants } from '../../constants/narrative-builder.constants';

import * as idb from './id-builder-utils';

export function getPrognosticIndicatorsNarrativeBuilder(): NarrativeBuilderList {
    const PI: string = 'PI';

    const FOLLOW_ROUTINE = 'FollowRoutine';
    const PIFollowRoutineBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(PI, FOLLOW_ROUTINE),
        text: 'Follow Routine',
        values: [
            {
                id: idb.getNBOptionValueId(PI, FOLLOW_ROUTINE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, FOLLOW_ROUTINE, 1),
                        text: 'Able to follow routines',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.FOLLOW_ROUTINE,
    };

    const IMITATE = 'IMITATE';
    const ImitateBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(PI, IMITATE),
        text: 'Imitate',
        values: [
            {
                id: idb.getNBOptionValueId(PI, IMITATE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, IMITATE, 1),
                        text: 'Able to imitate',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.IMITATE,
    };

    const SELF_MONTIOR = 'SELF_MONTIOR';
    const SelfMontiorBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(PI, SELF_MONTIOR),
        text: 'Self-monitor & correct',
        values: [
            {
                id: idb.getNBOptionValueId(PI, SELF_MONTIOR),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, SELF_MONTIOR, 1),
                        text: 'Able to self-montior and correct',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.SELF_MONITOR,
    };

    const AO4 = 'AO4';
    const AO4MontiorBuilderOption: NarrativeBuilderOption = {
        id: idb.getNBOptionId(PI, AO4),
        text: 'A/0 x 4',
        values: [
            {
                id: idb.getNBOptionValueId(PI, AO4),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, AO4, 1),
                        text: 'Alert and oriented x 4',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.AO4,
    };

    const HIGHER_FX = PrognosticIndicatorsConstants.HIGHER_FX_LEVEL_OPTION;
    const HigherFxMontiorBuilderOption: NarrativeBuilderOption = {
        id: HIGHER_FX,
        text: 'Higher Fx\'al Level',
        values: [
            {
                id: idb.getNBOptionValueId(PI, HIGHER_FX),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, HIGHER_FX, 1),
                        text: 'Demonstrated higher functional level compared to current condition',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.HIGHER_FX_LEVEL,
    };

    const IMP_MED = PrognosticIndicatorsConstants.IMP_MED_COND_OPTION;
    const ImpMedMontiorBuilderOption: NarrativeBuilderOption = {
        id: IMP_MED,
        text: 'Imp. med. cond.',
        values: [
            {
                id: idb.getNBOptionValueId(PI, IMP_MED),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, IMP_MED, 1),
                        text: 'Improving medical condition',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.IMP_MED_COND,
    };

    const AMB_PRIOR = PrognosticIndicatorsConstants.AMB_PRIOR_OPTION;
    const AmbPriorMontiorBuilderOption: NarrativeBuilderOption = {
        id: AMB_PRIOR,
        text: '(I) Amb Prior',
        values: [
            {
                id: idb.getNBOptionValueId(PI, AMB_PRIOR),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, AMB_PRIOR, 1),
                        text: 'Independent in prior ambulation ability',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.AMB_PRIOR,
    };

    const IN_PLOF = PrognosticIndicatorsConstants.IN_PLOF_OPTION;
    const InPLOFBuilderOption: NarrativeBuilderOption = {
        id: IN_PLOF,
        text: '(I) in PLOF',
        values: [
            {
                id: idb.getNBOptionValueId(PI, IN_PLOF),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, IN_PLOF, 1),
                        text: 'Independent in prior level of function',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.IN_PLOF,
    };

    const MOT_HOME = PrognosticIndicatorsConstants.MOT_HOME_OPTION;
    const motHomeBuilderOption: NarrativeBuilderOption = {
        id: MOT_HOME,
        text: 'Mot.Home',
        values: [
            {
                id: idb.getNBOptionValueId(PI, MOT_HOME),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, MOT_HOME, 1),
                        text: 'Motivated to return to personal home.',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.MOT_HOME,
    };

    const MOT_RETURN_PLOF = PrognosticIndicatorsConstants.MOT_RETURN_PLOF;
    const motReturnPLOFBuilderOption: NarrativeBuilderOption = {
        id: MOT_RETURN_PLOF,
        text: 'Mot.return PLOF',
        values: [
            {
                id: idb.getNBOptionValueId(PI, MOT_RETURN_PLOF),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, MOT_RETURN_PLOF, 1),
                        text: 'Motivated to return to prior level of function.',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.MOT_RETURN_PLOF,
    };

    const EVAL_NO_SKILLED = PrognosticIndicatorsConstants.EVAL_ONLY_NO_SKILLED_OPTION;
    const evalOnlyNoSkilledBuilderOption: NarrativeBuilderOption = {
        id: EVAL_NO_SKILLED,
        text: 'Eval Only: No skilled [PT, OT, ST] needs',
        values: [
            {
                id: idb.getNBOptionValueId(PI, EVAL_NO_SKILLED),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, EVAL_NO_SKILLED, 1),
                        text: 'No skilled [PT, OT, or ST] needs at this time. Patient is at baseline functional level',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.EVAL_ONLY_NO_SKILLED,
    };

    const ORIENTED_TO = PrognosticIndicatorsConstants.ORIENTED_TO_OPTION;
    const orientedToBuilderOption: NarrativeBuilderOption = {
        id: ORIENTED_TO,
        text: 'Oriented to..(self, place, date, time) [choose which are appropriate]',
        values: [
            {
                id: idb.getNBOptionValueId(PI, ORIENTED_TO),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, ORIENTED_TO, 1),
                        text: 'Oriented to',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.ORIENTED_TO,
    };

    const FOLLOW_COMMANDS = PrognosticIndicatorsConstants.FOLLOW_COMMANDS_OPTION;
    const followCommandsBuilderOption: NarrativeBuilderOption = {
        id: FOLLOW_COMMANDS,
        text: 'Follow S & C Commands',
        values: [
            {
                id: idb.getNBOptionValueId(PI, FOLLOW_COMMANDS),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, FOLLOW_COMMANDS, 1),
                        text: 'Patient able to follow simple and some complex commands',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.FOLLOW_COMMANDS,
    };

    const MOT_TO_PART = PrognosticIndicatorsConstants.MOT_TO_PART_OPTION;
    const motToPartBuilderOption: NarrativeBuilderOption = {
        id: MOT_TO_PART,
        text: 'Mot. to part.',
        values: [
            {
                id: idb.getNBOptionValueId(PI, MOT_TO_PART),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, MOT_TO_PART, 1),
                        text: 'Patient motivated to participate',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.MOT_TO_PART,
    };

    const DELAY_TO_COMMAND = PrognosticIndicatorsConstants.DELAY_TO_COMMAND_OPTION;
    const delayToCommandBuilderOption: NarrativeBuilderOption = {
        id: DELAY_TO_COMMAND,
        text: 'Delay to command',
        values: [
            {
                id: idb.getNBOptionValueId(PI, DELAY_TO_COMMAND),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, DELAY_TO_COMMAND, 1),
                        text: 'Patient with slight delayed reaction to commands',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.DELAY_TO_COMMAND,
    };

    const PREV_THERAPY = PrognosticIndicatorsConstants.PREV_THERAPY_OPTION;
    const prevTherapyBuilderOption: NarrativeBuilderOption = {
        id: PREV_THERAPY,
        text: '(+) prev therapy',
        values: [
            {
                id: idb.getNBOptionValueId(PI, PREV_THERAPY),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, PREV_THERAPY, 1),
                        text: 'Positive results from previous therapy',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.PREV_THERAPY,
    };

    const EVAL_ONLY_RECENT_ONSET = PrognosticIndicatorsConstants.EVAL_ONLY_RECENT_ONSET_OPTION;
    const evalOnlyRecentOnsetBuilderOption: NarrativeBuilderOption = {
        id: EVAL_ONLY_RECENT_ONSET,
        text: 'Eval Only: Recent onset [insert med dx]',
        values: [
            {
                id: idb.getNBOptionValueId(PI, EVAL_ONLY_RECENT_ONSET),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, EVAL_ONLY_RECENT_ONSET, 1),
                        text: 'Recent onset of [insert medical diagnosis] resulting in severe cogntive and physical deficits',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.EVAL_ONLY_RECENT_ONSET,
    };

    const EVAL_ONLY_NOT_RESPONSIVE = PrognosticIndicatorsConstants.EVAL_ONLY_NOT_RESPONSIVE_OPTION;
    const evalOnlyNotResponsiveBuilderOption: NarrativeBuilderOption = {
        id: EVAL_ONLY_NOT_RESPONSIVE,
        text: 'Eval Only: Not responsive & not cooperative',
        values: [
            {
                id: idb.getNBOptionValueId(PI, EVAL_ONLY_NOT_RESPONSIVE),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, EVAL_ONLY_NOT_RESPONSIVE, 1),
                        text: 'Resident is not responsive to stimuli & unable to cooperate with [PT, OT, or ST] plan of care',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.EVAL_ONLY_NOT_RESPONSIVE,
    };

    const RESP_CUEING = PrognosticIndicatorsConstants.RESP_CUEING_OPTION;
    const respCueingBuilderOption: NarrativeBuilderOption = {
        id: RESP_CUEING,
        text: 'Resp. Cueing',
        values: [
            {
                id: idb.getNBOptionValueId(PI, RESP_CUEING),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, RESP_CUEING, 1),
                        text: 'Responsive to cueing',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.RESP_CUEING,
    };

    const RESP_ENV_STIMULI = PrognosticIndicatorsConstants.RESP_ENV_STIMULI_OPTION;
    const respEnvStimuliBuilderOption: NarrativeBuilderOption = {
        id: RESP_ENV_STIMULI,
        text: 'Resp. to env. stimuli',
        values: [
            {
                id: idb.getNBOptionValueId(PI, RESP_ENV_STIMULI),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, RESP_ENV_STIMULI, 1),
                        text: 'Responsive to environmental stimuli',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.RESP_ENV_STIMULI,
    };

    const STABLE_MED_COND = PrognosticIndicatorsConstants.STABLE_MED_COND_OPTION;
    const stableMedBuilderOption: NarrativeBuilderOption = {
        id: STABLE_MED_COND,
        text: 'Stable Med Con.',
        values: [
            {
                id: idb.getNBOptionValueId(PI, STABLE_MED_COND),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, STABLE_MED_COND, 1),
                        text: 'Stable Medical Condition',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.STABLE_MED_COND,
    };

    const FAMILY_SUPPORT = PrognosticIndicatorsConstants.FAMILY_SUPPORT_OPTION;
    const familySupportBuilderOption: NarrativeBuilderOption = {
        id: FAMILY_SUPPORT,
        text: 'Family Support',
        values: [
            {
                id: idb.getNBOptionValueId(PI, FAMILY_SUPPORT),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, FAMILY_SUPPORT, 1),
                        text: 'Strong family support',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.FAMILY_SUPPORT,
    };

    const SUPPORT_SIG_OTHER = PrognosticIndicatorsConstants.SUPPORT_SIG_OTHER_OPTION;
    const supportSigBuilderOption: NarrativeBuilderOption = {
        id: SUPPORT_SIG_OTHER,
        text: 'Support-Sig.Other',
        values: [
            {
                id: idb.getNBOptionValueId(PI, FAMILY_SUPPORT),
                optionValues: [
                    {
                        id: idb.getNBOptionValueTextId(PI, FAMILY_SUPPORT, 1),
                        text: 'Strong support from significant other',
                    }
                ],
            },
        ],
        selectedValue: null, 
        property: PrognosticIndicatorsProperties.FAMILY_SUPPORT,
    };


    const nbl: NarrativeBuilderList = {
        options: {
            [PrognosticIndicatorsConstants.AMB_PRIOR_OPTION]: AmbPriorMontiorBuilderOption,
            [PrognosticIndicatorsConstants.AO4_OPTION]: AO4MontiorBuilderOption,
            [PrognosticIndicatorsConstants.DELAY_TO_COMMAND_OPTION]: delayToCommandBuilderOption,
            [PrognosticIndicatorsConstants.EVAL_ONLY_NO_SKILLED_OPTION]: evalOnlyNoSkilledBuilderOption,
            [PrognosticIndicatorsConstants.EVAL_ONLY_NOT_RESPONSIVE_OPTION]: evalOnlyNotResponsiveBuilderOption,
            [PrognosticIndicatorsConstants.EVAL_ONLY_RECENT_ONSET_OPTION]: evalOnlyRecentOnsetBuilderOption,
            [PrognosticIndicatorsConstants.FAMILY_SUPPORT_OPTION]: familySupportBuilderOption,
            [PrognosticIndicatorsConstants.FOLLOW_COMMANDS_OPTION]: followCommandsBuilderOption,
            [PrognosticIndicatorsConstants.FOLLOW_ROUTINE_OPTION]: PIFollowRoutineBuilderOption,
            [PrognosticIndicatorsConstants.HIGHER_FX_LEVEL_OPTION]: HigherFxMontiorBuilderOption,
            [PrognosticIndicatorsConstants.IMP_MED_COND_OPTION]: ImpMedMontiorBuilderOption,
            [PrognosticIndicatorsConstants.IMITATE_ROUTINE_OPTION]: ImitateBuilderOption,
            [PrognosticIndicatorsConstants.IN_PLOF_OPTION]: InPLOFBuilderOption,
            [PrognosticIndicatorsConstants.MOT_HOME_OPTION]: motHomeBuilderOption,
            [PrognosticIndicatorsConstants.MOT_RETURN_PLOF]: motReturnPLOFBuilderOption,
            [PrognosticIndicatorsConstants.MOT_TO_PART_OPTION]: motToPartBuilderOption,
            [PrognosticIndicatorsConstants.ORIENTED_TO_OPTION]: orientedToBuilderOption,
            [PrognosticIndicatorsConstants.PREV_THERAPY_OPTION]: prevTherapyBuilderOption,
            [PrognosticIndicatorsConstants.RESP_CUEING_OPTION]: respCueingBuilderOption,
            [PrognosticIndicatorsConstants.RESP_ENV_STIMULI_OPTION]: respEnvStimuliBuilderOption,
            [PrognosticIndicatorsConstants.SELF_MONITOR_OPTION]: SelfMontiorBuilderOption,
            [PrognosticIndicatorsConstants.STABLE_MED_COND_OPTION]: stableMedBuilderOption,
            [PrognosticIndicatorsConstants.SUPPORT_SIG_OTHER_OPTION]: supportSigBuilderOption,
        },
    };

    return nbl;
}