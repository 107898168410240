<form>
  <mat-form-field appearance="outline" class="tms-native-drop-down">
      <input matInput id="ddl-input" [formControl]="nativeddlcontrol" [matAutocomplete]="auto" placeholder="Select..." [readOnly]="disabled" title="{{ selectedValue?.text }}" 
        (click)="onInputClicked()" (keyup)="onInputKeyUp($event)">
      <mat-error *ngIf="nativeddlcontrol.invalid">No match found</mat-error>
      <button *ngIf="selectedValue" class="tms-icon-button tms-native-drop-down-clear" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="onClearValue()">
        <i class="fas fa-times"></i>
      </button>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelectedValue($event)" (optionActivated)="onSelectedValue($event)">
        <mat-option *ngFor="let item of filteredOptions | async" [value]="item" title="{{item.text}}">
          {{ item.text }}
        </mat-option>
      </mat-autocomplete>
  </mat-form-field>
</form>
