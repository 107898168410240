import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { NarrativeBuilderOption, NarrativeBuilderOptionValue, NBComponentBoolean } from '@app/model';
import { NarrativeBuilderUtils } from '@app/utils/narrative-builder.utils';

@Component({
  selector: 'tms-narrative-builder-toggle-button',
  templateUrl: './narrative-builder-toggle-button.component.html',
  styleUrls: ['./narrative-builder-toggle-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NarrativeBuilderToggleButtonComponent implements OnInit {
  @Input() alertUser?: boolean = false;
  @Input() optionValue: NarrativeBuilderOption;
  @Output() onValueChanged: EventEmitter<NBComponentBoolean> = new EventEmitter<NBComponentBoolean>();

  value: boolean = false;
  overrideManualEdits: boolean = null;

  get hint(): string {
    return this.optionValue?.values?.[0]?.optionValues?.[0]?.text ?? "";
  }

  constructor() { }

  ngOnInit() {
    if (this.optionValue.selectedValue) {
      this.value = true;
      this.onValueChanged.emit({
        isChecked: this.value,
        optionValue: this.optionValue.values[0],
        optionValueTextIndex: 0,
        overrideManualEdits: this.overrideManualEdits,
        property: this.optionValue.property,
    });
    }
  }

  onOverrideManualEdits(e: boolean) {
    this.overrideManualEdits = e;
  }

  valueChange(e: boolean) {
    if (e) {
      // nIndex will always be 0 since there is not randomization
      // const nIndex = NarrativeBuilderUtils.randomNarrativeBuildOptionValueTextIndex(this.optionValue.values[0].optionValues);
      this.onValueChanged.emit({
          isChecked: e,
          optionValue: this.optionValue.values[0],
          optionValueTextIndex: 0,
          overrideManualEdits: this.overrideManualEdits,
          property: this.optionValue.property,
      });
    } else {
      this.onValueChanged.emit({ isChecked: e, property: this.optionValue.property, overrideManualEdits: this.overrideManualEdits });
    }
  }

}
