import { Injectable } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { AppInsightsService } from './app-insights.service';
import { CacheManService } from './cacheman.service';
import { MixpanelService } from './mixpanel.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private appInsightService: AppInsightsService,
    private mixpanelService: MixpanelService,
    private cacheManService: CacheManService) {
  }

  init() {
    this.cacheManService.getCurrentUser()
    .pipe(filter(user => user != null))
    .subscribe(user => {
      if (user.email) {
        this.appInsightService.setUserContext(user.email);
        this.mixpanelService.init(user.email);
      }
    })
  }

  reset() {
    this.mixpanelService.reset();
  }


  trackEvent(eventName: string, eventData: any = {}) {
    this.appInsightService.logEvent(eventName);
    this.mixpanelService.track(eventName, eventData);
  }

  trackPage(page: string) {
    this.appInsightService.logPageView(page);
  }
}
