import { eDiscipline } from "@app/model";

export class DisplayStringUtils {
    static getDisciplineShortName(discipline: eDiscipline): string {
        switch(discipline) {
            case eDiscipline.PhysicalTherapy:
                return 'PT';
            case eDiscipline.OccupationalTherapy: 
                return 'OT';
            case eDiscipline.SpeechTherapy:
                return 'ST';
            case eDiscipline.RespiratoryTherapy:
                return 'RT';
            default:
                return '';
        }
    }
}