import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'tms-scroll-to-top-button',
  templateUrl: './scroll-to-top-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollToTopButtonComponent implements OnInit {
  @Input() topElementName: string = null;
  @Input() verticalAlign: string = 'end'; // options are start, center, end, nearest
  constructor() { }

  ngOnInit(): void {
  }

  onScrollToTop() {
    if (this.topElementName) {
      const topElement = document.getElementById(this.topElementName);
      if (topElement) {
        const scrollParams: ScrollIntoViewOptions = {
          behavior: 'smooth',
          block: (this.verticalAlign as ScrollLogicalPosition),
        };
        topElement.scrollIntoView(scrollParams);
      }
    }
  }

}
