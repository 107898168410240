import { Injectable } from '@angular/core';
import * as HandleBars from 'handlebars/dist/cjs/handlebars';
import { TemplatesConstants } from '@app/core/constants/templates.constants';
import {
    PATIENT_GOALS_TEMPLATE,
    PLOF_TEMPLATE,
    PROGNOSTIC_INDICATORS_TEMPLATE,
    REASON_FOR_REFERRAL_TEMPLATE,
    RECERT_DEFICITS_TEMPLATE,
    RECERT_INTERVENTIONS_TEMPLATE,
    RECERT_PRECAUTIONS_TEMPLATE,
    REHAB_POTENTIAL_TEMPLATE,
} from '@app/model';

@Injectable({
    providedIn: 'root',
})
export class HandlebarService {
    private sourceTemplates = {};
    private templates = {
        [TemplatesConstants.PATIENT_GOALS]: null,
        [TemplatesConstants.PLOF]: null,
        [TemplatesConstants.PROGNOSTIC_INDICATORS]: null,
        [TemplatesConstants.REASON_FOR_REFERRAL]: null,
        [TemplatesConstants.RECERT_DEFICITS]: null,
        [TemplatesConstants.RECERT_INTERVENTIONS]: null,
        [TemplatesConstants.RECERT_PRECAUTIONS]: null,
        [TemplatesConstants.REHAB_POTENTIAL]: null,
    };

    constructor() {
        this.registerSourceTemplates();
    }

    // Should only be called from NarrativeBuilderService
    generateNarrative(templateName: string, obj: any): string {
        const template = this.getTemplate(templateName);
        return template(obj);
    }

    private getTemplate(templateName: string) {
        if (this.sourceTemplates[templateName] === undefined) {
            console.log(`Source template ${templateName} is not defined in HandlebarService source templates map.`);
            return;
        }
        const sourceTemplate = this.sourceTemplates[templateName];
        if (this.templates[templateName] === undefined) {
            console.log(`Template ${templateName} is not defined in HandlebarService templates map.`);
            return;
        }
        if (this.templates[templateName] === null) {
            this.templates[templateName] = HandleBars.compile(sourceTemplate);
        }
        return this.templates[templateName];
    }

    private registerSourceTemplates() {
        this.sourceTemplates = {
            [TemplatesConstants.PATIENT_GOALS]: PATIENT_GOALS_TEMPLATE,
            [TemplatesConstants.PLOF]: PLOF_TEMPLATE,
            [TemplatesConstants.PROGNOSTIC_INDICATORS]: PROGNOSTIC_INDICATORS_TEMPLATE,
            [TemplatesConstants.REASON_FOR_REFERRAL]: REASON_FOR_REFERRAL_TEMPLATE,
            [TemplatesConstants.RECERT_DEFICITS]: RECERT_DEFICITS_TEMPLATE,
            [TemplatesConstants.RECERT_INTERVENTIONS]: RECERT_INTERVENTIONS_TEMPLATE,
            [TemplatesConstants.RECERT_PRECAUTIONS]: RECERT_PRECAUTIONS_TEMPLATE,
            [TemplatesConstants.REHAB_POTENTIAL]: REHAB_POTENTIAL_TEMPLATE,
        };
    }
}
