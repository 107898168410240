<tms-modal title="Find Supporting Code" (onSubmit)="onDoneButtonPressed()" (onClose)="onClose()" submitButtonText="Done" [cancelButtonVisible]="false">
    <div>
        <div class="row" style="padding-top: 5px;padding-bottom: 7px;background-color: #CDCDCD;">
            <div class="col-2">
                <strong>Discipline: {{selectedDiscipline == 1 ? "PT" : selectedDiscipline == 2 ? "OT" : "ST"}}</strong>
            </div>
        </div>
        <div class="row" style="padding-top: 15px;padding-bottom: 20px;">
            <div class="col-2">
                <strong style="padding-bottom: 2px;">CPT Code:</strong>
                <dx-select-box [dataSource]="cptList"
                (onValueChanged)="onCptCodeSelected($event)" [(value)]="currentCode"></dx-select-box>
            </div>
        </div>
        <div class="row">
            <dx-data-grid name="grid" id="gridContainer" [dataSource]="icdCodes"  (onRowPrepared)="onRowPrepared($event)" (onToolbarPreparing)="onToolbarPreparing($event)">
                <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
                <dxo-search-panel [visible]="true" width="400"></dxo-search-panel>
                <dxi-column dataField="code" width="100"></dxi-column>
                <dxi-column dataField="statement"></dxi-column>
                <dxi-column type="buttons">
                    <dx-button class="tms-primary-button" (click)="addIcdCodeButtonClicked($event)" hint="Add Icd Code" icon="fas fa-plus"></dx-button>
                </dxi-column>
                <dxi-column caption="" cellTemplate="supportCellTemplate" width="50">
                    <div *dxTemplate="let data of 'supportCellTemplate'">
                        <dx-button class="tms-primary-button" (click)="addIcdCodeButtonClicked(data.data)" hint="Add Icd Code" icon="fas fa-plus"></dx-button>
                    </div>
                </dxi-column>
            </dx-data-grid>
        </div>
        
    </div>
</tms-modal>