export class StartupPropertiesConstants {
    static ADMIN: string = 'admin';
    static BILLING: string = 'billing';
    static CLIENT_INFO: string = 'clientInfo';
    static DISTRIBUTION: string = 'distributees';
    static DOCUMENTS: string = 'documents';
    static FACILITY_DETAILS: string = 'facilityDetails';
    static HR: string = 'hr';
    static IT: string = 'it';
    static SOFTWARE: string = 'software';
    static STAFFING: string = 'staffing';
    static STARTUP_INFO: string = 'startupInfo';
}