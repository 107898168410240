import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { filter, take, takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { ePermissions, User } from '@app/model';
import { ToastMessageService, UsersService, CacheManService } from '@app/core';
import { OnDestroyComponent } from '@app/shared';
import { DeepCopyUtils, ErrorMessageUtils } from '@app/utils';

@Component({
    selector: 'tms-user-profile',
    templateUrl: './user-profile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent extends OnDestroyComponent implements OnInit, OnDestroy {
    activeTabIndex: number = 0;
    user: User;
    userCopy: User;
    userTabs = [
        {
            text: 'Basic Profile',
        },
        {
            text: 'Work Hours',
        },
    ];

    constructor(private usersService: UsersService,
                private cacheManService: CacheManService,
                private toastMessageService: ToastMessageService,
                private cdr: ChangeDetectorRef) {
                    super();
                 }

    ngOnInit() {
       combineLatest([this.cacheManService.getCurrentUser(), this.cacheManService.getUserHasPermission$(ePermissions.Admin)])
            .pipe(filter(user => user != null), takeUntil(this.onDestroy$))
            .subscribe(([user, hasPermission]) => {
                this.user = user;
                if (hasPermission) {
                    const impersonateIndex = this.userTabs.findIndex(tab => tab.text === 'Impersonate');
                    if (impersonateIndex < 0) {
                        this.userTabs.push({text: 'Impersonate'});
                    }
                } else {
                    const impersonateIndex = this.userTabs.findIndex(tab => tab.text === 'Impersonate');
                    if (impersonateIndex >= 0) {
                        this.userTabs = this.userTabs.slice(0, impersonateIndex)
                    }
                    this.activeTabIndex = 0;
                }
                this.cdr.detectChanges();
            });;
    }

    updateUser(user: User) {
        this.usersService.updateBasicProfile(user)
        .pipe(take(1))
        .subscribe((response) => {
          this.user = DeepCopyUtils.deepCopyObject(response);
          this.cacheManService.setCurrentUser(this.user);
          this.cacheManService.setUserPermissions(this.user.permissions);
          this.cdr.detectChanges();
          this.toastMessageService.successNotification('Succesfully updated.')
        },
        (error) => {
            let msg = ErrorMessageUtils.getDisplayErrorMessage(error, 'Error updating user profile');
            this.toastMessageService.errorNotification(msg);
        });
    }

    ngOnDestroy() {
        super.onDestroy();
    }
}
