import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { CanDeactivateComponent } from '@app/shared';

@Injectable({
    providedIn: 'root',
})
export class CanDeactivateGuard  implements CanDeactivate<CanDeactivateComponent> {
    constructor() {}
    canDeactivate(component: CanDeactivateComponent) {
        return component.canDeactivate ? component.canDeactivate(false) : true;
    }        
}