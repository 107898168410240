<dx-validation-group name="supportTicketDetails">
    <tms-modal [title]="title" submitButtonText="Reply" cancelButtonText="Close" [submitButtonDisabled]="!addComment"
        (onSubmit)="onSupportIssueReply($event)" (onCancel)="onCancelSupportIssueReply()" (onClose)="onCancelSupportIssueReply()">
        <div class="tms-field">
            <div class="tms-field-label">
                <span style="font-weight: bold;">Status:</span>
            </div>
            <div class="tms-field-value">
                {{ supportIssue.status | dataListItem: supportIssueStatusDataList | async }}
            </div>
        </div>
        <div class="tms-field tms-row-divider">
            <div class="tms-field-label">
                <span style="font-weight: bold;">Description:</span>
            </div>
            <div class="tms-field-value">
                {{ supportIssue.description }}
            </div>
        </div>
        <div *ngFor="let message of supportIssueMessages; index as i">
            <div [ngClass]="{'tms-row-divider': i > 0}">
                <div>
                    {{ message?.timeStamp | date: 'EEEE, MMMM d, y, h:mm:ss a' }} - {{ message?.author?.name }}
                </div>
                <dx-text-area [value]="message.body" [readOnly]="true" height="75px"></dx-text-area>
            </div>
        </div>
        <div *ngFor="let attachment of supportIssue.attachments; index as i" [ngClass]="{ 'tms-row-divider': i == 0 }">
            <a href="javascript:void(0)" (click)="onAttachmentClicked(attachment)" style="cursor: pointer;">{{ attachment.fileName }}</a>
        </div>
        <dx-button class="tms-row-divider tms-primary-button" text="Add Comment" (onClick)="onAddComment()"></dx-button>
        <dx-text-area class="tms-row-divider" [visible]="addComment" [(value)]="comment">
            <dx-validator>
                <dxi-validation-rule type="required" message="Please add a comment">
                </dxi-validation-rule>
            </dx-validator>
        </dx-text-area>
        <div *ngIf="addComment" class="row tms-row-divider">
            <div class="col"></div>
            <div class="col-3">
                <dx-file-uploader #fileuploader name="file" selectButtonText="Select file" labelText="" accept="image/*"
                    uploadMode="useForm">
                    <dx-validator>
                        <dxi-validation-rule type="custom" [validationCallback]="validateFile">
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-file-uploader>
            </div>
        </div>
    </tms-modal>
</dx-validation-group>