import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ePermissions } from '@app/model';
import { CacheManService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate, CanLoad {
  constructor(private cacheManService: CacheManService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkPermissions(route.data.permissions);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.checkPermissions(route.data.permissions);
  }

  private checkPermissions(routePermissions: ePermissions): Observable<boolean> {
    return this.cacheManService.getUserHasPermission$(routePermissions).pipe(take(1));
  }
}
