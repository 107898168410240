import { Injectable } from '@angular/core';
import { RouteConstants } from '../constants/route.constants';
import { FacilitiesService } from './facilities.service';

@Injectable({
    providedIn: 'root'
})
export class RedirectService {

    constructor(private facilityService: FacilitiesService) { }

    getFacilityDashboardDocumentsUrl(facilityDashboardTab: string): string {
        if (!facilityDashboardTab) {
            facilityDashboardTab = RouteConstants.FACILITY_HOME;
        }

        const activeFacilityId = this.facilityService.activeFacilityId;
        const redirectUrl = `/${RouteConstants.PAGES_ROOT}/${RouteConstants.FACILITY_ROOT}/${activeFacilityId}/${RouteConstants.FACILITY_DASHBOARD}/${facilityDashboardTab}`;
        return redirectUrl;
    }

    getFacililtyRestorativeNursingUrl(): string {
        const activeFacilityId = this.facilityService.activeFacilityId;
        const redirectUrl = `/${RouteConstants.PAGES_ROOT}/${RouteConstants.FACILITY_ROOT}/${activeFacilityId}/${RouteConstants.FACILITY_RESTORATIVE_NURSING}`;
        return redirectUrl;
    }

    getFacilityFunctionBuilderUrl(): string {
        const activeFacilityId = this.facilityService.activeFacilityId;
        const redirectUrl = `/${RouteConstants.PAGES_ROOT}/${RouteConstants.FACILITY_ROOT}/${activeFacilityId}/${RouteConstants.FACILITY_FUNCTION_BUILDERS}`;
        return redirectUrl;
    }
}
