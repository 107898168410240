import { Component, EventEmitter, Input, Output, OnInit, OnChanges } from '@angular/core';
import { DataListItem, InterviewQuestion, InterviewAnswerField } from '@app/model';

@Component({
    selector: 'tms-question-radiolist',
    styleUrls: [],
    template: `
    <dx-radio-group [items]="dataListItems" [layout]="orientation.toLowerCase()" [disabled]="disabled"
    [(value)]="radioListValue" itemTemplate="listItem" (onValueChanged)="onValueChanged($event)"
    displayExpr="text" valueExpr="value">
        <div *dxTemplate="let item of 'listItem'">
            {{item.text}}
        </div>
    </dx-radio-group>
  `,
})
export class InterviewQuestionRadioListComponent implements OnInit, OnChanges {
    @Input() question: InterviewQuestion;
    @Input() dataListItems: DataListItem[] = [];
    @Input() orientation: string = 'Vertical';
    @Input() interviewAnswerField: InterviewAnswerField;
    @Input() disabled: boolean = false;
    @Output() onInterviewRadioListValueChanged: EventEmitter<string[]> = new EventEmitter<string[]>();

    radioListValue: string;

    constructor() { }

    ngOnInit() {
    }

    ngOnChanges() {
        this.radioListValue = this.interviewAnswerField.value;
    }

    onValueChanged($event) {
        this.onInterviewRadioListValueChanged.emit($event.value);
    }
}
