<div class="page-heading page-heading-container">
    <div>
        <dx-button *ngIf="showBackButton" [text]="backButtonText" icon="fas fa-chevron-left" class="tms-secondary-button tms-back-icon-button"
            (onClick)="onBackButton()">
        </dx-button>
    </div>
    <div class="page-heading-title">{{ title }}</div>
    <div>
        <ng-content></ng-content>
    </div>
</div>