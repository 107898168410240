export enum eClinicalMeasurementTimeFrame {
    Unspecified = 0,

    /// <summary>
    ///     Present Day, represented by the TimeStamp of the measurement
    /// </summary>
    PresentValue = 1,

    /// <summary>
    ///     Prior Level of Function before care
    /// </summary>
    PriorLevel = 2,

    /// <summary>
    ///     Short Term Goal, a period defined in the Evaluation
    /// </summary>
    ShortTermGoal = 3,

    /// <summary>
    ///     Long Term Goal, a period defined in the Evaluation
    /// </summary>
    LongTermGoal = 4
}